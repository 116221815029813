import { dbKeysHdmAm } from "./enum";
import { randomisedFacebookAds } from "../../../utils/strategyCreationFacebook";
import {
  defaultAdsets,
  adsetsObjectDb,
  defaultAdsetsInInterest,
  adsetsObjFunc,
} from "./enum";

const INITIAL_STATE = {
  brandId: "",
  onboardingInfo: dbKeysHdmAm.onboardingInfo,
  accessSharing: dbKeysHdmAm.accessSharing,
  auditInfo: dbKeysHdmAm.auditInfo,
  accountAnalysis: dbKeysHdmAm.accountAnalysis,
  proposalStatus: dbKeysHdmAm.proposalStatus,
  fbAdsStrategyInfo: dbKeysHdmAm.fbAdsStrategyInfo,
  strategyIterations: dbKeysHdmAm.strategyIterations,
  fbAdsAccountId: dbKeysHdmAm.fbAdsAccountId,
  fbAdsAccountName: "",	
  proposalStatus: "PENDING",
  deletedKeywords:[]
};

const updateArray = (array, facebookAds) => {
  const updatedArray = [];
  array.forEach((ad) => {
    updatedArray.push({
      ...ad,
      facebookAds: randomisedFacebookAds(facebookAds),
    });
  });
  return updatedArray;
};

const updateAudience = (array, audeinces, type) => {
  const updateArray = [];
  const filteredAudience = [];
  if (type == "TOF") {
    audeinces.forEach((audeince) => {
      // if (["WEBSITE", "CUSTOM", "FB_EVENT"].includes(audeince.subtype)) {
        if (["WEBSITE"].includes(audeince.subtype)) {
        filteredAudience.push(audeince);
      }
    });
  } else if (type === "MOF") {
    audeinces.forEach((audeince) => {
      if (
        // ["IG_BUSINESS", "FB_EVENT", "WEBSITE", "ENGAGEMENT"].includes(
          ["WEBSITE"].includes(
          audeince.subtype
        )
      ) {
        filteredAudience.push(audeince);
      }
    });
  } else if (type === "BOF") {
    audeinces.forEach((audeince) => {
      // if (["WEBSITE", "CUSTOM", "FB_EVENT"].includes(audeince.subtype)) {
        if (["WEBSITE"].includes(audeince.subtype)) {
        filteredAudience.push(audeince);
      }
    });
  }
  if (filteredAudience.length <= 3) {
    let count = filteredAudience.length;
    for (let i = count + 1; i <= 3; i++) {
      filteredAudience.push({
        id: "",
        name: "custom",
        minReach: "",
        maxReach: "",
        subType: "",
      });
    }
  }
  array.forEach((ad, index) => {
    if (index === 0) {
      updateArray.push({
        ...ad,
        ...{ audience: filteredAudience[index], minRange: "1", maxRange: "3" },
      });
    } else if (index === 1) {
      updateArray.push({
        ...ad,
        ...{ audience: filteredAudience[index], minRange: "4", maxRange: "7" },
      });
    } else if (index === 2) {
      updateArray.push({
        ...ad,
        ...{ audience: filteredAudience[index], minRange: "8", maxRange: "10" },
      });
    }
  });
  return updateArray;
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_WHOLE_HDM_AM_DETAILS":
      return { ...state, ...action.payload };
    case "UPDATE_VALUE_ACCOUNT_ANALYSIS_HDM_AM":
      const tempState = JSON.parse(JSON.stringify({...state}));
      tempState["accountAnalysis"][action.payload.key] = action.payload.value;
      return tempState;
    case "ASSIGN_FIRST_TIME_STRATEGY_CREATION_VALUE":
      const tempState2 = JSON.parse(JSON.stringify(({ ...state })));
      tempState2["fbAdsStrategyInfo"]["isFirstTimeStrategyCreation"] = true;
      //adding default adsets
      tempState2["fbAdsStrategyInfo"]["topOfTheFunnelLookalike"]["adsets"] =
        defaultAdsets();
      tempState2["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"] =
        defaultAdsetsInInterest(action.payload.keywordsFacebook);
      tempState2["fbAdsStrategyInfo"]["middleOfTheFunnel"]["adsets"] =
        defaultAdsets();
      tempState2["fbAdsStrategyInfo"]["bottomOfTheFunnel"]["adsets"] =
        defaultAdsets();

      //adding default random facebook ads in each adset
      const arr1 = updateArray(
        tempState2["fbAdsStrategyInfo"]["topOfTheFunnelLookalike"]["adsets"],
        action.payload.facebookAds
      );
      const arr2 = updateArray(
        tempState2["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"],
        action.payload.facebookAds
      );
      const arr3 = updateArray(
        tempState2["fbAdsStrategyInfo"]["middleOfTheFunnel"]["adsets"],
        action.payload.facebookAds
      );
      const arr4 = updateArray(
        tempState2["fbAdsStrategyInfo"]["bottomOfTheFunnel"]["adsets"],
        action.payload.facebookAds
      );
      tempState2["fbAdsStrategyInfo"]["topOfTheFunnelLookalike"]["adsets"] =
        arr1;
      tempState2["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"] = arr2;
      tempState2["fbAdsStrategyInfo"]["middleOfTheFunnel"]["adsets"] = arr3;
      tempState2["fbAdsStrategyInfo"]["bottomOfTheFunnel"]["adsets"] = arr4;

      //adding audience data
      const arr5 = updateAudience(
        tempState2["fbAdsStrategyInfo"]["topOfTheFunnelLookalike"]["adsets"],
        action.payload.audiences,
        "TOF"
      );
      // const arr6 = updateAudience(
      //   tempState2["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"],
      //   action.payload.audiences,
      //   "TOF"
      // );
      const arr7 = updateAudience(
        tempState2["fbAdsStrategyInfo"]["middleOfTheFunnel"]["adsets"],
        action.payload.audiences,
        "MOF"
      );
      const arr8 = updateAudience(
        tempState2["fbAdsStrategyInfo"]["bottomOfTheFunnel"]["adsets"],
        action.payload.audiences,
        "BOF"
      );
      tempState2["fbAdsStrategyInfo"]["topOfTheFunnelLookalike"]["adsets"] =
        arr5;
      // tempState2["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"] = arr6;
      tempState2["fbAdsStrategyInfo"]["middleOfTheFunnel"]["adsets"] = arr7;
      tempState2["fbAdsStrategyInfo"]["bottomOfTheFunnel"]["adsets"] = arr8;
      return tempState2;
    case "ADD_NEW_ADSET_HDM_AM":
      const tempState3 = JSON.parse(JSON.stringify(({ ...state })));
      const { outerKey, facebookAds } = action.payload;
      const tempArray3 = [
        ...tempState3["fbAdsStrategyInfo"][outerKey]["adsets"],
      ];
      tempArray3.push({
        ...adsetsObjectDb,
        ...{
          facebookAds: randomisedFacebookAds(facebookAds),
          maxRange: "3",
          minRange: "1",
        },
      });
      tempState3["fbAdsStrategyInfo"][outerKey]["adsets"] = tempArray3;
      return tempState3;
    case "DELETE_ADSET_HDM_AM":
      const tempState4 = JSON.parse(JSON.stringify(({ ...state })));
      const tempArray4 = [
        ...tempState4["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"],
      ];
      const listOfDeletedKeyword=[...state.deletedKeywords];
      if(tempArray4[action.payload.index].keywords.length !== 0 && !listOfDeletedKeyword.includes(tempArray4[action.payload.index].adsetName)){
        listOfDeletedKeyword.push(tempArray4[action.payload.index].adsetName);
      }
      tempState4["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"] =
        tempArray4.filter((adset, index) => index !== action.payload.index);
      tempState4["deletedKeywords"]=listOfDeletedKeyword;
      return tempState4;
    case "UPDATE_ADSET_DROPDOWN_VALUE":
      const tempState5 = JSON.parse(JSON.stringify(({ ...state })));
      tempState5["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][
        action.payload.index
      ]["audience"]["name"] = action.payload.name;
      tempState5["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][
        action.payload.index
      ]["audience"]["minReach"] = action.payload.minReach;
      tempState5["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][
        action.payload.index
      ]["audience"]["maxReach"] = action.payload.maxReach;
      tempState5["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][
        action.payload.index
      ]["audience"]["subType"] = action.payload.subType;
      return tempState5;
    case "UPDATE_ADSET_RANGE_VALUE":
      const tempState6 = JSON.parse(JSON.stringify(({ ...state })));
      tempState6["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][
        action.payload.index
      ][action.payload.type] = action.payload.value;
      return tempState6;
    case "ADD_TO_THIS_ADSET_HDM":
      const tempState7 = JSON.parse(JSON.stringify(({ ...state })));
      const tempArr5 = [
        ...tempState7["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][
          action.payload.index
        ]["facebookAds"],
      ];
      let isThisAdAlreadyThere = false;
      const adsTemp = action.payload.ads;
      tempArr5.forEach((ad) => {
        if (
          ad.description === adsTemp.description &&
          ad.headline === adsTemp.headline &&
          ad.imageUrl === adsTemp.imageUrl &&
          ad.primaryText === adsTemp.primaryText
        ) {
          isThisAdAlreadyThere = true;
        }
      });
      !isThisAdAlreadyThere && tempArr5.push(action.payload.ads);
      tempState7["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][
        action.payload.index
      ]["facebookAds"] = tempArr5;
      return tempState7;
    case "ADD_TO_ALL_ADSET_HDM":
      const tempState8 = JSON.parse(JSON.stringify(({ ...state })));
      const tempArr6 = [
        ...tempState8["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"],
      ];
      const adsTemp2 = action.payload.ads;
      tempArr6.forEach((ad, index) => {
        const tempArr = [...ad.facebookAds];
        let isThisAdAlreadyThere = false;
        tempArr.forEach((ad1) => {
          if (
            ad1.description === adsTemp2.description &&
            ad1.headline === adsTemp2.headline &&
            ad1.imageUrl === adsTemp2.imageUrl &&
            ad1.primaryText === adsTemp2.primaryText
          ) {
            isThisAdAlreadyThere = true;
          }
        });
        !isThisAdAlreadyThere && tempArr.push(action.payload.ads);
        tempArr6[index]["facebookAds"] = tempArr;
      });
      tempState8["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"] =
        tempArr6;
      return tempState8;
    case "DELETE_SPECIFIC_ADS_HDM":
      const tempState9 = JSON.parse(JSON.stringify(({ ...state })));
      const tempArr7 = [
        ...tempState9["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"],
      ];
      tempArr7[action.payload.index]["facebookAds"] = tempArr7[
        action.payload.index
      ]["facebookAds"].filter(
        (ad, index) => index !== action.payload.removedIndex
      );
      tempState9["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][
        action.payload.index
      ]["facebookAds"] = tempArr7[action.payload.index]["facebookAds"];
      return tempState9;
    case "EDIT_SPECIFIC_ADS_HDM":
      const tempState10 = JSON.parse(JSON.stringify(({ ...state })));
      const tempArr8 = [
        ...tempState10["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"],
      ];
      tempArr8[action.payload.index]["facebookAds"][action.payload.editIndex] =
        action.payload.ad;
      tempState10["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"] =
        tempArr8;
      return tempState10;
    case "ADD_ADSET_INTEREST_HDM":
      const tempState12 = JSON.parse(JSON.stringify(({ ...state })));
      const { adsets, keywords } = action.payload;
      const tempArr15 = [
        ...tempState12["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"],
      ];
      let maxIndex = 0;
      let flag=false;
      adsets.forEach((adset) => {
        keywords.forEach((keyword, index) => {
          if (keyword.adsetName === adset.adsetName) {
            maxIndex = Math.max(maxIndex, index);
          }
        });
      });
      if (tempArr15.length === 0 && keywords.length !== 0) {
        tempArr15.push(
          adsetsObjFunc(keywords[0]["keywords"], keywords[0]["adsetName"])
        );
        flag=true;
      } else if (
        maxIndex + 1 === keywords.length &&
        tempArr15.length !== keywords.length
      ) {
        let index=0;
        for(let i=0;i<keywords.length;i++){
          let isThere=false;
          for(let j=0;j<tempArr15.length;j++){
            if(tempArr15[j].adsetName === keywords[i].adsetName){
               isThere=true;
               break;
            }
          }
          if(!isThere){
            index=i;
            break;
          }
        }
        flag=true;
        tempArr15.push(
          adsetsObjFunc(keywords[index]["keywords"], keywords[index]["adsetName"])
        );
      } else if (
        tempArr15.length !== keywords.length &&
        maxIndex + 1 < keywords.length
      ) {
        for(let i=0;i<keywords.length;i++){
          if(state.deletedKeywords.includes(keywords[i].adsetName)){
            maxIndex=Math.max(maxIndex,i);
          }
        }
        keywords.length !== 0 &&
          tempArr15.push(
            adsetsObjFunc(
              keywords[maxIndex + 1]["keywords"],
              keywords[maxIndex + 1]["adsetName"]
            )
          );
          flag=true;
      }
      if(flag){
        tempState12["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"] = tempArr15;
        tempState12["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"][tempArr15.length - 1]["facebookAds"] = randomisedFacebookAds(action.payload.facebookAds);
      }
      return tempState12;
    case "CLEAR_ALL_ADS_HDM":
      const tempState13 = JSON.parse(JSON.stringify(({ ...state })));
      const tempArr16 =
        tempState13["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"];
      tempArr16[action.payload.index]["facebookAds"] = [];
      return tempState13;
    case "UPDATE_FACEBOOK_ACCOUNT_ID_HDM":
      const tempState14 = JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(({ ...state })))));
      tempState14["fbAdsAccountId"] = action.payload.accountId;
      tempState14["fbAdsAccountName"] = action.payload.accountName;
      return tempState14;
    case "ADD_KEYWORD_IN_ADSET_HDM":
      const tempState15 = JSON.parse(JSON.stringify(({ ...state })));
      const keywordsTemp = [
        ...tempState15["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"][
          action.payload.index
        ]["keywords"],
      ];
      if (!keywordsTemp.includes(action.payload.value.name)) {
        keywordsTemp.push({
          name: action.payload.value.name,
          id: action.payload.value.id,
        });
      } else {
        const indexSearch = keywordsTemp.findIndex(
          (val) => val.name === action.payload.value.name
        );
        keywordsTemp.splice(indexSearch, 1);
      }
      tempState15["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"][
        action.payload.index
      ]["keywords"] = keywordsTemp;
      return tempState15;
    case "DELETE_KEYWORD_IN_ADSET_HDM":
      const tempState16 = JSON.parse(JSON.stringify(({ ...state })));
      const keywordsTemp2 = [
        ...tempState16["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"][
          action.payload.index
        ]["keywords"],
      ];
      const indexDel = keywordsTemp2.findIndex(
        (val) => val === action.payload.value
      );
      indexDel !== -1 && keywordsTemp2.splice(indexDel, 1);
      tempState16["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["adsets"][
        action.payload.index
      ]["keywords"] = keywordsTemp2;
      return tempState16;
    case "UPDATE_BUDGET_FUNNEL_HDM":
      const tempState17 = JSON.parse(JSON.stringify({...state}));
      tempState17["fbAdsStrategyInfo"][action.payload.outerKey]["budget"] = action.payload.value;
      return tempState17;
    case "UPDATE_STATUS_HDM_AM":
      const tempState18 = JSON.parse(JSON.stringify({...state}));
      tempState18[action.payload.outerKey]["status"] = action.payload.status;
      return tempState18;
    case "UPDATE_ADSET_NAME_HDM":
      const tempState19 = JSON.parse(JSON.stringify(({ ...state })));
      tempState19["fbAdsStrategyInfo"][action.payload.outerKey]["adsets"][action.payload.index]["adsetName"]=action.payload.value;
      return tempState19;
    case "ADD_TO_THIS_AND_ALL_ADSET":
      const tempState20= JSON.parse(JSON.stringify({...state}));
      const addToThisAdsetList=action.payload.addToThisAdsetList;
      const addToAllAdsetList=action.payload.addToAllAdsetList;
      const currentAdsetNew=action.payload.currentAdset;
      const outerKeyNew=action.payload.outerKey;
      const adsetsNew={...tempState20["fbAdsStrategyInfo"][outerKeyNew]["adsets"][currentAdsetNew]};
      const allAdests=[...tempState20["fbAdsStrategyInfo"][outerKeyNew]["adsets"]];
      const fbAdsList1=[];
      //add to this adset
      Object.entries(addToThisAdsetList).forEach(([key,value]) => {
        let isAdAlreadyThere=false;
        adsetsNew["facebookAds"].forEach((ad)=>{
            if((ad.uid && ad.uid === key) || (ad.description === value.description &&
            ad.headline === value.headline &&
            ad.imageUrl === value.imageUrl &&
            ad.primaryText === value.primaryText)){
              isAdAlreadyThere=true;
            }
        });
        if(!isAdAlreadyThere){
          fbAdsList1.push({...value,...{uid:key}});
        }
      });
      tempState20["fbAdsStrategyInfo"][outerKeyNew]["adsets"][currentAdsetNew]["facebookAds"]=[...adsetsNew["facebookAds"],...fbAdsList1];
      //add to all adset
      allAdests.forEach((adset,index) => {
        const fbAds=[];
        Object.entries(addToAllAdsetList).forEach(([key,value],index) => {
          let isAdAlreadyThere=false;
          adset["facebookAds"].forEach((ad)=>{
            if((ad.uid && ad.uid === key) || (ad.description === value.description &&
              ad.headline === value.headline &&
              ad.imageUrl === value.imageUrl &&
              ad.primaryText === value.primaryText)){
              isAdAlreadyThere=true;
            }
          });
          if(!isAdAlreadyThere){
            fbAds.push({...value,...{uid:key}});
          }
        });
        allAdests[index]["facebookAds"]=[...fbAds,...allAdests[index]["facebookAds"]];
      });
      tempState20["fbAdsStrategyInfo"][outerKeyNew]["adsets"]=allAdests;
      return tempState20;
    case "UPDATE_AUDIT_ACCOUNT_HDM_AM":
      const tempState25=JSON.parse(JSON.stringify({...state}));
      tempState25["auditInfo"]["accountAuditInfo"][action.payload.key]=action.payload.value;
      return tempState25;
    
    case "UPDATE_ACCESS_SHARING_HDM_AM":
      const { infoType, key, key2, key3, value } = action.payload;
      const updatePersonalInfo5 = JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(({ ...state })))));
      updatePersonalInfo5[infoType][key][key2][key3] = value;
      // updatePersonalInfo5[infoType][key][key2]["status"] = action.payload
      //   ?.isComplete
      //   ? "VERIFYING_COMPLETE_ACCESS"
      //   : "VERIFYING";
      return updatePersonalInfo5;
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
