import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { button_bg_corner as corner } from "../../assets/icons/common/common";
import { Edit as EDITICON } from "../../assets/icons/common/common";
import { Edit_white as editWhiteICON } from "../../assets/icons/common/common";
import { accept_white as acceptWhiteICON } from "../../assets/icons/common/common";
import { accept_blue as acceptBlueICON } from "../../assets/icons/common/common";
import clearICON from "../../assets/icons/clear_ads_icon.svg";
import { clear_ads_icon_hover as clearICONHover } from "../../assets/icons/icon";
import { camera as Camera } from "../../assets/icons/icon";

const PrimaryButton = (props) => {
  const CustomButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: "14px",
    padding: props.icon
      ? "10px 25px 10px 45px"
      : props.type == "corner"
      ? "11px 25px"
      : "10px 24px",
    border: props.type == "corner" ? "0px solid" : "1px solid",
    lineHeight: "18px",
    background: props.disabled
      ? "#0869FB"
      : props.type == "light"
      ? "#EAF2FF"
      : props.type == "bordered"
      ? "#ffffff"
      : props.type == "errorLight"
      ? "#FFF0F0"
      : props.type == "corner"
      ? "#0869FB url(" + corner + ") no-repeat 0 0"
      : "#0869FB",
    borderColor:
      props.type == "bordered"
        ? "#0869FB"
        : props.type == "light"
        ? "#F2F7FF"
        : props.type == "errorLight"
        ? "#FFF0F0"
        : "#0869FB",
    borderRadius: "8px",
    color:
      props.type == "bordered" || props.type == "light"
        ? "#0869FB"
        : props.type == "errorLight"
        ? "#E84B47"
        : "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 600,

    width: props.width ? props.width : "",
    ":disabled": {
      background: "#0869FB",
      opacity: "0.3",
      color: "#fff",
    },
    "&:hover": {
      color:
        props.type == "bordered"
          ? "#FFFFFF"
          : props.type == "light"
          ? "#F2F7FF"
          : props.type == "errorLight"
          ? "#FFF0F0"
          : "#FFFFFF",
      borderColor:
        props.type == "light"
          ? "#0869FB"
          : props.type == "errorLight"
          ? "#E84B47"
          : "#0869FB",

      background: props.type == "errorLight" ? "#E84B47" : "",

      "&::before": {
        background:
          props.icon == "edit"
            ? "url(" + editWhiteICON + ") no-repeat center center"
            : props.icon == "approve"
            ? "url(" + acceptWhiteICON + ") no-repeat center center"
            : props.icon == "clear"
            ? "url(" + clearICONHover + ") no-repeat center center"
            : `''`,
      },
    },
    "&:before": {
      content: `""`,
      position: "absolute",
      left: "15px",
      top: "50%",
      transform: "translate(0,-50%)",
      background:
        props.icon == "edit"
          ? "url(" + EDITICON + ") no-repeat center center"
          : props.icon == "approve"
          ? "url(" + acceptBlueICON + ") no-repeat center center"
          : props.icon == "clear"
          ? "url(" + clearICON + ") no-repeat center center"
          : props.icon == "camera"
          ? "url(" + Camera + ") no-repeat center center"
          : `''`,
      width: "20px",
      height: "20px",
      "&:hover": {},
    },
  });

  return (
    <CustomButton variant="contained" {...props}>
      {props.title}
    </CustomButton>
  );
};

export default PrimaryButton;
