import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { campaign_publish_modal_icon as infoIcon } from '../../assets/icons/campaign-approval/campaign_approval';
import readyGif from '../../assets/icons/proposal/ready.gif';
import PrimaryButton from '../ButtonPrimary/Buttons';
const ConfirmDialogModal = ({ title="Home", formSubm, setFormSubm, publishHandler }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 584,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 3.5,
    paddingTop: '3rem',
    // height: '380px',
    borderRadius: '15px',
    outline: 'none',
  };
  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Close
          onClick={() => setFormSubm(!formSubm)}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            gap: '0.5rem',
          }}
        >
          <div>
            <img src={readyGif} alt='' width={64} height={64} />
          </div>
          <div style={{ marginTop: '1.5rem' }}>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 24,
              }}
            >
              Your changes have been saved!
            </Typography>
          </div>
          <div
            style={{
              margin: '2rem 0rem',
              display: 'flex',
              gap: '1.5rem',
              justifyContent: 'center',
              paddingBottom: '1rem',
            }}
          >
            <PrimaryButton title={title} width={145} onClick={publishHandler} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmDialogModal;
