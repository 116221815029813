import { useState, useEffect } from 'react';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { useTheme, styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import InputBase from '@mui/material/InputBase';
import { no_preview_icon as noIreviewIcon } from '../../../assets/icons/common/common';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@mui/material/Input';
import { useDispatch, useSelector } from 'react-redux';
import {
  getKeywordsReducer,
  updateBudgets,
  updateKeywordsStatusReducer,
  updateKeywordStatusReducer,
  editKeywordStatusReducer,
  updateSelectedBrandKeywords,
  updateUnselectedBrandKeywords,
  updateRemovedBrandKeywords,
  updateSelectedBrandHeadlines,
  updateUnselectedBrandHeadlines,
  updateRemovedBrandHeadlines,
  updateSelectedBrandDescriptions,
  updateUnselectedBrandDescriptions,
  updateRemovedBrandDescriptions,
  updateAffectedBrandKeywords,
} from '../../../api/accountManager/targetAI/slice';
import {
  generateResourceReducer,
  getResourcesReducer,
  updateResourceReducer,
  updateAllResourceData,
  updateResourcesStatusReducer,
  editResourceStatusReducer,
} from '../../../api/accountManager/creativeAI/slice';
import { updateFormReducer } from '../../../api/accountManager/forms/slice';
import {
  accountManagerIds,
  accountManagerIdsHDM,
  BACKEND_STATUS,
  componentWithIndexHDMAm,
  ldComponentWithIndexAm,
} from '../../../utils/accountManagerUtil';
import GoToNextCompoButton from '../GoToNextCompoButton';
import useInterval from '../../../hooks/useInterval';
import {
  goBackAmCompo,
  goToReviewQuestionierAM,
  goToSpecificCompoClientAm,
} from '../../../api/accountManager/componentTracking/action';
import GenerateModal from '../GeneratingadsModal/generatingadsmodal';
import GeneratingStrategyPage from '../GeneratingStrategyPage';
import {
  goBackHDMAmCompo,
  goToSpecificCompoHDMAM,
} from '../../../api/HDMAM/componentTracking/action';
import useGoogleCampaignBudget from '../useGoogleCampaignBudget';
import TourTooltip from '../../Common/TourTooltip';
import InfoTooltipForBudget from '../../TooltipIndicators/InfoTooltipForBudget';
import InfoTip from '../../Common/InfoTip';

const StrategyInput = ({ agencyType = 'LD' , type}) => {
  const dispatch = useDispatch();
  const [clusterName, setClusterName] = useState('Cluster_1');
  const [headlineResourceId, setHeadlineResourceId] = useState(null);
  const [descriptionResourceId, setDescriptionResourceId] = useState(null);
  const [clusterNumber, setClusterNumber] = useState(0);
  const [budget, setBudget] = useState('');
  const [keywordValue, setKeywordValue] = useState('');
  const [generateKeyword, setGenerateKeyWord] = useState(false);
  const [brandResourceId, setBrandResourceId] = useState(null);
  const [isGeneratingModal, setIsGeneratingModal] = useState(false);
  const campaigns = useSelector((state) => state.targetAI.campaigns);
  const selectedKeywords = useSelector(
    (state) => state.targetAI.selectedBrandKeywords
  );
  const unselectedKeywords = useSelector(
    (state) => state.targetAI.unselectedBrandKeywords
  );
  const removedKeywords = useSelector(
    (state) => state.targetAI.removedBrandKeywords
  );
  const { brandCampaignBudget } = useGoogleCampaignBudget({ agencyType });
  const brandBudget = useSelector((state) => state.targetAI.BrandBudget);
  const form = useSelector((store) =>
    agencyType === 'HDM' ? store.hdmFormAm : store.form.form
  );
  const generated = useSelector((state) => state.creativeAI.generated);
  const resourceIds = useSelector((state) => state.creativeAI.resourceIds);
  const resources = useSelector((state) => state.creativeAI.brandedResources);

  const [selectedTab, setSelectedTab] = useState('HEADLINE');
  const selectedHeadlines = useSelector(
    (state) => state.targetAI.selectedBrandHeadlines
  );
  const nonSelectedHeadlines = useSelector(
    (state) => state.targetAI.unselectedBrandHeadlines
  );
  const removedHeadlines = useSelector(
    (state) => state.targetAI.removedBrandHeadlines
  );
  const selectedDescription = useSelector(
    (state) => state.targetAI.selectedBrandDescription
  );
  const nonSelectedDescription = useSelector(
    (state) => state.targetAI.unselectedBrandDescription
  );
  const removedDescription = useSelector(
    (state) => state.targetAI.removedBrandDescription
  );

  const affectedKeywords = useSelector(
    (state) => state.targetAI.affectedBrandKeywords
  );
  const [generateHeadline, setGenerateHeadline] = useState(false);
  const [generateDesc, setGenerateDesc] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedKeyValue, setSelectedKeyValue] = useState('');
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedKeyType, setSelectedKeyType] = useState(null);

  const [selectedHeadValue, setSelectedHeadValue] = useState('');
  const [selectedHead, setSelectedHead] = useState(null);
  const [headType, setHeadType] = useState(null);

  const [selectedDescValue, setSelectedDescValue] = useState('');
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [descType, setDescType] = useState(null);

  useEffect(() => {
    dispatch(getKeywordsReducer());
  }, []);
  useEffect(() => {
    if (campaigns.length) {
      const brandCluster = campaigns.find(
        (campaign) => campaign.campaignName === 'branded_keyword'
      ).adGroupClusters;
      setClusterName(brandCluster[clusterNumber].clusterName);
      if (!selectedKeywords.length && !unselectedKeywords.length) {
        const [selected, unselected, removed] = [[], [], []];
        brandCluster[clusterNumber].keywords.map((keyword) => {
          if (keyword.status === 'SELECTED') {
            selected.push(keyword.keyword);
          } else if (keyword.status === 'NOT_SELECTED') {
            unselected.push(keyword.keyword);
          } else {
            removed.push(keyword.keyword);
          }
        });
        dispatch(updateSelectedBrandKeywords(selected));
        dispatch(updateUnselectedBrandKeywords(unselected));
        dispatch(updateRemovedBrandKeywords(removed));
        dispatch(updateAffectedBrandKeywords({ 0: [] }));
      }
      // const valid = [...selected, ...unselected];

      // if (!removed.length && !selected.length) {
      //   dispatch(
      //     updateKeywordsStatusReducer({
      //       keywords: valid.slice(0, 13),
      //       campaignName: "branded_keyword",
      //       clusterName: brandCluster[clusterNumber].clusterName,
      //       status: "SELECTED",
      //     })
      //   );
      // }
    }
  }, [campaigns]);

  useEffect(() => {
    if ((resources || []).length) {
      const campaignResource = resources.filter(
        (resource) => resource.campaignType === 'branded_keyword'
      );
      let [selectedHead, unselectedHead, removedHead] = [{}, {}, {}];
      let [selectedDesc, unselectedDesc, removedDesc] = [{}, {}, {}];

      selectedHead[clusterNumber] = [];
      unselectedHead[clusterNumber] = [];
      removedHead[clusterNumber] = [];
      selectedDesc[clusterNumber] = [];
      unselectedDesc[clusterNumber] = [];
      removedDesc[clusterNumber] = [];
      const clusterResource = campaignResource.filter(
        (resource) => resource.adgroupName === clusterName
      );

      clusterResource.map((resource) => {
        if (resource.resourceType === 'HEADLINE') {
          setHeadlineResourceId(resource.resourcesId);
          (resource.resources || []).map((keyword) => {
            if (keyword.status === 'SELECTED') {
              selectedHead[clusterNumber].push(keyword);
            } else if (keyword.status === 'NOT_SELECTED') {
              unselectedHead[clusterNumber].push(keyword);
            } else {
              removedHead[clusterNumber].push(keyword);
            }
          });
        }
        if (resource.resourceType === 'DESCRIPTION') {
          setDescriptionResourceId(resource.resourcesId);
          (resource.resources || []).map((keyword) => {
            if (keyword.status === 'SELECTED') {
              selectedDesc[clusterNumber].push(keyword);
            } else if (keyword.status === 'NOT_SELECTED') {
              unselectedDesc[clusterNumber].push(keyword);
            } else {
              removedDesc[clusterNumber].push(keyword);
            }
          });
        }
      });

      if (!(selectedHead[clusterNumber] || []).length) {
        delete selectedHead[clusterNumber];
      }
      if (!(unselectedHead[clusterNumber] || []).length) {
        delete unselectedHead[clusterNumber];
      }
      if (!(removedHead[clusterNumber] || []).length) {
        delete removedHead[clusterNumber];
      }
      if (!(selectedDesc[clusterNumber] || []).length) {
        delete selectedDesc[clusterNumber];
      }
      if (!(unselectedDesc[clusterNumber] || []).length) {
        delete unselectedDesc[clusterNumber];
      }
      if (!(removedDesc[clusterNumber] || []).length) {
        delete removedDesc[clusterNumber];
      }

      selectedHead = { ...selectedHead, ...selectedHeadlines };
      unselectedHead = { ...unselectedHead, ...nonSelectedHeadlines };
      removedHead = { ...removedHead, ...removedHeadlines };

      selectedDesc = { ...selectedDesc, ...selectedDescription };
      unselectedDesc = { ...unselectedDesc, ...nonSelectedDescription };
      removedDesc = { ...removedDesc, ...removedDescription };
      dispatch(updateUnselectedBrandHeadlines(unselectedHead));
      dispatch(updateUnselectedBrandDescriptions(unselectedDesc));
      dispatch(updateRemovedBrandHeadlines(removedHead));
      dispatch(updateRemovedBrandDescriptions(removedDesc));
      dispatch(updateSelectedBrandHeadlines(selectedHead));
      dispatch(updateSelectedBrandDescriptions(selectedDesc));
    }
  }, [resources]);

  useEffect(() => {
    if (form) {
      if (form?.googleSearchAdsStrategyInfo?.brandCampaign?.budget === '0') {
        dispatch(
          updateBudgets({
            BrandBudget: '',
          })
        );
      } else {
        dispatch(
          updateBudgets({
            BrandBudget:
              parseInt(
                form?.googleSearchAdsStrategyInfo?.brandCampaign?.budget
              ) || brandCampaignBudget,
          })
        );
      }
    }
  }, [form, brandCampaignBudget]);

  useEffect(() => {
    if (generated) {
      dispatch(
        generateResourceReducer({
          campaignType: 'branded_keyword',
          interestKeywords: selectedKeywords,
          adgroupName: clusterName,
          resourceType: 'DESCRIPTION',
        })
      );
    } else {
      setIsGeneratingModal(false);
    }
  }, [generated]);

  // useEffect(() => {
  //   if (!(resources || []).length) {
  //     dispatch(
  //       generateResourceReducer({
  //         campaignType: "branded_keyword",
  //         interestKeywords: selectedKeywords,
  //         adgroupName: clusterName,
  //         resourceType: "HEADLINE",
  //       })
  //     );
  //   }
  // }, [selectedKeywords]);

  useEffect(() => {
    if (
      selectedTab === 'DESCRIPTION' &&
      !(selectedDescription[clusterNumber] || []).length
    ) {
      let res = resources.find((resource) => {
        return (
          resource.campaignType === 'branded_keyword' &&
          resource.adgroupName === clusterName &&
          resource.resourceType === 'DESCRIPTION'
        );
      });
      dispatch(
        updateResourcesStatusReducer({
          resourceId: res?.resourcesId,
          resourceValues:
            nonSelectedDescription[clusterNumber] ||
            []?.slice(0, 4).map((resource) => resource.value),
          status: 'SELECTED',
        })
      );
    }
  }, [selectedTab]);

  const changeBrandBudget = (e) => {
    dispatch(
      updateBudgets({
        BrandBudget: e.target.value || '',
      })
    );
  };
  useEffect(() => {
    if (brandBudget) {
      setBudget(brandBudget);
    } else {
      setBudget('');
    }
  }, [brandBudget]);

  useEffect(() => {
    if (!isEdit) {
      setSelectedKey(null);
      setSelectedKeyType(null);
      setSelectedKeyValue('');
      setSelectedHeadValue('');
      setSelectedHead(null);
      setHeadType(null);
      setDescType(null);
      setSelectedDescValue('');
      setSelectedDesc(null);
    }
  }, [isEdit]);

  const addKeyword = (keyword, keywordToBeRemoved, currentState) => {
    let tempSelectedKeyword = [...selectedKeywords];
    let tempUnselectedKeyword = [...unselectedKeywords];
    let tempRemovedKeyword = [...removedKeywords];
    let tempAffectedKeywords = [...(affectedKeywords[clusterNumber] || [])];
    if (isEdit && keywordToBeRemoved) {
      const keywordInAffected = tempAffectedKeywords.find(
        (keyword) =>
          keyword.keyword === keywordToBeRemoved &&
          keyword.campaignName === 'branded_keyword' &&
          keyword.clusterName === clusterName
      );
      if (keywordInAffected) {
        tempAffectedKeywords.splice(
          tempAffectedKeywords.indexOf(keywordInAffected),
          1
        );
      }
      tempAffectedKeywords.push({
        keyword: keywordToBeRemoved,
        status: 'REMOVED',
        campaignName: 'branded_keyword',
        clusterName: clusterName,
      });
      if (currentState === 'SELECTED') {
        tempSelectedKeyword.splice(
          tempSelectedKeyword.indexOf(keywordToBeRemoved),
          1
        );
      } else {
        tempUnselectedKeyword.splice(
          tempUnselectedKeyword.indexOf(keywordToBeRemoved),
          1
        );
        dispatch(updateUnselectedBrandKeywords(tempUnselectedKeyword));
      }
      dispatch(updateSelectedBrandKeywords([keyword, ...tempSelectedKeyword]));
      dispatch(
        updateRemovedBrandKeywords([keywordToBeRemoved, ...tempRemovedKeyword])
      );
      setSelectedKey(null);
      setSelectedKeyValue('');
      setSelectedKeyType(null);
    } else {
      dispatch(updateSelectedBrandKeywords([keyword, ...tempSelectedKeyword]));
    }
    tempAffectedKeywords.push({
      keyword: keyword,
      status: 'SELECTED',
      campaignName: 'branded_keyword',
      clusterName: clusterName,
    });
    dispatch(
      updateAffectedBrandKeywords({
        ...affectedKeywords,
        [clusterNumber]: tempAffectedKeywords,
      })
    );

    setGenerateKeyWord(false);
    setKeywordValue('');
  };
  const updateKeyword = (keyword, status) => {
    let tempAffectedKeywords = [...(affectedKeywords[clusterNumber] || [])];
    const keywordInAffected = tempAffectedKeywords.find(
      (keywordAffected) =>
        keywordAffected.keyword === keyword &&
        keywordAffected.campaignName === 'branded_keyword' &&
        keywordAffected.clusterName === clusterName
    );
    if (keywordInAffected) {
      tempAffectedKeywords.splice(
        tempAffectedKeywords.indexOf(keywordInAffected),
        1
      );
    }
    let tempSelectedKeyword = [...selectedKeywords];
    let tempUnselectedKeyword = [...unselectedKeywords];
    if (status === 'SELECTED') {
      tempUnselectedKeyword.splice(tempUnselectedKeyword.indexOf(keyword), 1);
      dispatch(updateSelectedBrandKeywords([keyword, ...tempSelectedKeyword]));
      dispatch(updateUnselectedBrandKeywords(tempUnselectedKeyword));
      tempAffectedKeywords.push({
        keyword: keyword,
        status: 'SELECTED',
        campaignName: 'branded_keyword',
        clusterName: clusterName,
      });
    }
    if (status === 'NOT_SELECTED') {
      tempSelectedKeyword.splice(tempSelectedKeyword.indexOf(keyword), 1);
      dispatch(updateSelectedBrandKeywords(tempSelectedKeyword));
      dispatch(
        updateUnselectedBrandKeywords([keyword, ...tempUnselectedKeyword])
      );
      tempAffectedKeywords.push({
        keyword: keyword,
        status: 'NOT_SELECTED',
        campaignName: 'branded_keyword',
        clusterName: clusterName,
      });
    }
    dispatch(
      updateAffectedBrandKeywords({
        ...affectedKeywords,
        [clusterNumber]: tempAffectedKeywords,
      })
    );
  };

  const removeKeyword = (keyword) => {
    let tempAffectedKeywords = [...(affectedKeywords[clusterNumber] || [])];
    const keywordInAffected = tempAffectedKeywords.find(
      (keywordAffected) =>
        keywordAffected.keyword === keyword &&
        keywordAffected.campaignName === 'branded_keyword' &&
        keywordAffected.clusterName === clusterName
    );
    if (keywordInAffected) {
      tempAffectedKeywords.splice(
        tempAffectedKeywords.indexOf(keywordInAffected),
        1
      );
    }
    let tempSelectedKeyword = [...selectedKeywords];
    let tempUnselectedKeyword = [...unselectedKeywords];
    if (tempSelectedKeyword.indexOf(keyword) !== -1) {
      tempSelectedKeyword.splice(tempSelectedKeyword.indexOf(keyword), 1);
      dispatch(updateSelectedBrandKeywords(tempSelectedKeyword));
    } else {
      tempUnselectedKeyword.splice(tempUnselectedKeyword.indexOf(keyword), 1);
      dispatch(updateUnselectedBrandKeywords(tempUnselectedKeyword));
    }
    dispatch(updateRemovedBrandKeywords([keyword, ...removedKeywords]));
    tempAffectedKeywords.push({
      keyword: keyword,
      status: 'REMOVED',
      campaignName: 'branded_keyword',
      clusterName: clusterName,
    });
    dispatch(
      updateAffectedBrandKeywords({
        ...affectedKeywords,
        [clusterNumber]: tempAffectedKeywords,
      })
    );
  };

  const generateAdsHandler = () => {
    setIsGeneratingModal(true);
    dispatch(
      updateAllResourceData({
        brandedResources: [],
      })
    );

    let tempSelectedHeadlines = { ...selectedHeadlines };
    delete tempSelectedHeadlines[clusterNumber];
    let tempNonSelectedHeadlines = { ...nonSelectedHeadlines };
    delete tempNonSelectedHeadlines[clusterNumber];
    let tempRemovedHeadlines = { ...removedHeadlines };
    delete tempRemovedHeadlines[clusterNumber];
    let tempSelectedDescription = { ...selectedDescription };
    delete tempSelectedDescription[clusterNumber];
    let tempNonSelectedDescription = { ...nonSelectedDescription };
    delete tempNonSelectedDescription[clusterNumber];
    let tempRemovedDescription = { ...removedDescription };
    delete tempRemovedDescription[clusterNumber];

    dispatch(
      updateSelectedBrandHeadlines({
        ...tempSelectedHeadlines,
      })
    );
    dispatch(
      updateUnselectedBrandHeadlines({
        ...tempNonSelectedHeadlines,
      })
    );
    dispatch(updateRemovedBrandHeadlines({ ...tempRemovedHeadlines }));
    dispatch(
      updateSelectedBrandDescriptions({
        ...tempSelectedDescription,
      })
    );
    dispatch(
      updateUnselectedBrandDescriptions({
        ...tempNonSelectedDescription,
      })
    );
    dispatch(
      updateRemovedBrandDescriptions({
        ...tempRemovedDescription,
      })
    );
    const items = affectedKeywords[clusterNumber] || [];
    dispatch(editKeywordStatusReducer({ items }));
    dispatch(
      generateResourceReducer({
        campaignType: 'branded_keyword',
        interestKeywords: selectedKeywords,
        adgroupName: clusterName,
        resourceType: 'HEADLINE',
      })
    );
  };

  const onClickHandler = () => {
    const items = affectedKeywords[clusterNumber] || [];
    dispatch(editKeywordStatusReducer({ items }));
    console.log(
      'after',
      [...(selectedHeadlines[clusterNumber] || [])].map((head) => {
        console.log('in map');
      })
    );
    const resourceItems = [];
    [...(selectedHeadlines[clusterNumber] || [])].map((headline) => {
      resourceItems.push({
        resourcesId: headlineResourceId,
        resourceValue: headline.value,
        resourceStatus: 'SELECTED',
      });
    });
    [...(nonSelectedHeadlines[clusterNumber] || [])].map((headline) => {
      resourceItems.push({
        resourcesId: headlineResourceId,
        resourceValue: headline.value,
        resourceStatus: 'NOT_SELECTED',
      });
    });
    [...(removedHeadlines[clusterNumber] || [])].map((headline) => {
      resourceItems.push({
        resourcesId: headlineResourceId,
        resourceValue: headline.value,
        resourceStatus: 'REMOVED',
      });
    });
    [...(selectedDescription[clusterNumber] || [])].map((description) => {
      resourceItems.push({
        resourcesId: descriptionResourceId,
        resourceValue: description.value,
        resourceStatus: 'SELECTED',
      });
    });
    [...(nonSelectedDescription[clusterNumber] || [])].map((description) => {
      resourceItems.push({
        resourcesId: descriptionResourceId,
        resourceValue: description.value,
        resourceStatus: 'NOT_SELECTED',
      });
    });
    [...(removedDescription[clusterNumber] || [])].map((description) => {
      resourceItems.push({
        resourcesId: descriptionResourceId,
        resourceValue: description.value,
        resourceStatus: 'REMOVED',
      });
    });
    dispatch(
      editResourceStatusReducer({
        items: resourceItems,
      })
    );
    console.log('after resource');
    dispatch(
      updateFormReducer({
        googleSearchAdsStrategyInfo: {
          ...form?.googleSearchAdsStrategyInfo,
          brandCampaign: {
            status: BACKEND_STATUS.COMPLETED,
            budget: budget || '0',
            adsets: [
              {
                name: clusterName,
                keywords: selectedKeywords,
                headlines: [...(selectedHeadlines[clusterNumber] || [])].map(
                  (headline) => {
                    return {
                      value: headline.value,
                      category: headline.category,
                    };
                  }
                ),
                descriptions: [
                  ...(selectedDescription[clusterNumber] || []),
                ].map((desc) => {
                  return {
                    value: desc.value,
                    category: desc.category,
                  };
                }),
              },
            ],
          },
        },
      })
    );
  };

  const removeResource = (resource) => {
    console.log('@@@@@@@@@', resource);
    const tempSelectedResource =
      selectedTab === 'HEADLINE'
        ? [...(selectedHeadlines[clusterNumber] || [])]
        : [...(selectedDescription[clusterNumber] || [])];
    const tempUnselectedResource =
      selectedTab === 'HEADLINE'
        ? [...(nonSelectedHeadlines[clusterNumber] || [])]
        : [...(nonSelectedDescription[clusterNumber] || [])];
    if ((tempSelectedResource || []).indexOf(resource) !== -1) {
      tempSelectedResource.splice(tempSelectedResource.indexOf(resource), 1);
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateSelectedBrandHeadlines({
              ...selectedHeadlines,
              [clusterNumber]: tempSelectedResource,
            })
          )
        : dispatch(
            updateSelectedBrandDescriptions({
              ...selectedDescription,
              [clusterNumber]: tempSelectedResource,
            })
          );
    } else {
      tempUnselectedResource.splice(
        tempUnselectedResource.indexOf(resource),
        1
      );
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateUnselectedBrandHeadlines({
              ...nonSelectedHeadlines,
              [clusterNumber]: tempUnselectedResource,
            })
          )
        : dispatch(
            updateUnselectedBrandDescriptions({
              ...nonSelectedDescription,
              [clusterNumber]: tempUnselectedResource,
            })
          );
    }

    selectedTab === 'HEADLINE'
      ? dispatch(
          updateRemovedBrandHeadlines({
            [clusterNumber]: [
              resource,
              ...(removedHeadlines[clusterNumber] || []),
            ],
          })
        )
      : dispatch(
          updateRemovedBrandDescriptions({
            ...removedDescription,
            [clusterNumber]: [
              resource,
              ...(removedDescription[clusterNumber] || []),
            ],
          })
        );
  };

  const updateResource = (resource, status) => {
    const tempSelectedResource =
      selectedTab === 'HEADLINE'
        ? [...(selectedHeadlines[clusterNumber] || [])]
        : [...(selectedDescription[clusterNumber] || [])];
    const tempUnselectedResource =
      selectedTab === 'HEADLINE'
        ? [...(nonSelectedHeadlines[clusterNumber] || [])]
        : [...(nonSelectedDescription[clusterNumber] || [])];
    if (status) {
      tempSelectedResource.splice(tempSelectedResource.indexOf(resource), 1);
      if (selectedTab === 'HEADLINE') {
        dispatch(
          updateSelectedBrandHeadlines({
            ...selectedHeadlines,
            [clusterNumber]: tempSelectedResource,
          })
        );
        dispatch(
          updateUnselectedBrandHeadlines({
            ...nonSelectedHeadlines,
            [clusterNumber]: [
              resource,
              ...(nonSelectedHeadlines[clusterNumber] || []),
            ],
          })
        );
      } else {
        dispatch(
          updateSelectedBrandDescriptions({
            ...selectedDescription,
            [clusterNumber]: tempSelectedResource,
          })
        );
        dispatch(
          updateUnselectedBrandDescriptions({
            ...nonSelectedDescription,
            [clusterNumber]: [
              resource,
              ...(nonSelectedDescription[clusterNumber] || []),
            ],
          })
        );
      }
    } else {
      tempUnselectedResource.splice(
        tempUnselectedResource.indexOf(resource),
        1
      );

      if (selectedTab === 'HEADLINE') {
        dispatch(
          updateSelectedBrandHeadlines({
            ...selectedHeadlines,
            [clusterNumber]: [
              resource,
              ...(selectedHeadlines[clusterNumber] || []),
            ],
          })
        );
        dispatch(
          updateUnselectedBrandHeadlines({
            ...nonSelectedHeadlines,
            [clusterNumber]: tempUnselectedResource,
          })
        );
      } else {
        dispatch(
          updateSelectedBrandDescriptions({
            ...selectedDescription,
            [clusterNumber]: [
              resource,
              ...(selectedDescription[clusterNumber] || []),
            ],
          })
        );
        dispatch(
          updateUnselectedBrandDescriptions({
            ...nonSelectedDescription,
            [clusterNumber]: tempUnselectedResource,
          })
        );
      }
    }
  };
  const addResource = (resourceVal, resourceToBeRemoved, currentState) => {
    const tempSelectedResource =
      selectedTab === 'HEADLINE'
        ? [...(selectedHeadlines[clusterNumber] || [])]
        : [...(selectedDescription[clusterNumber] || [])];
    const tempUnselectedResource =
      selectedTab === 'HEADLINE'
        ? [...(nonSelectedHeadlines[clusterNumber] || [])]
        : [...(nonSelectedDescription[clusterNumber] || [])];
    if (isEdit && resourceToBeRemoved) {
      if (currentState === 'SELECTED') {
        tempSelectedResource.splice(
          tempSelectedResource.indexOf(resourceToBeRemoved),
          1
        );
      } else {
        tempUnselectedResource.splice(
          tempUnselectedResource.indexOf(resourceToBeRemoved),
          1
        );
        selectedTab === 'HEADLINE'
          ? dispatch(
              updateUnselectedBrandHeadlines({
                ...nonSelectedHeadlines,
                [clusterNumber]: tempUnselectedResource,
              })
            )
          : dispatch(
              updateUnselectedBrandDescriptions({
                ...nonSelectedDescription,
                [clusterNumber]: tempUnselectedResource,
              })
            );
      }
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateSelectedBrandHeadlines({
              ...selectedHeadlines,
              [clusterNumber]: [
                { value: resourceVal, category: 'custom', status: 'SELECTED' },
                tempSelectedResource,
              ],
            })
          )
        : dispatch(
            updateSelectedBrandDescriptions({
              ...selectedDescription,
              [clusterNumber]: [
                { value: resourceVal, category: 'custom', status: 'SELECTED' },
                ...tempSelectedResource,
              ],
            })
          );
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateRemovedBrandHeadlines({
              ...removedHeadlines,
              [clusterNumber]: [
                resourceToBeRemoved,
                ...(removedHeadlines[clusterNumber] || []),
              ],
            })
          )
        : dispatch(
            updateRemovedBrandDescriptions({
              ...removedDescription,
              [clusterNumber]: [
                resourceToBeRemoved,
                ...(removedDescription[clusterNumber] || []),
              ],
            })
          );
    } else {
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateSelectedBrandHeadlines({
              ...selectedHeadlines,
              [clusterNumber]: [
                { value: resourceVal, category: 'custom', status: 'SELECTED' },
                ...(selectedHeadlines[clusterNumber] || []),
              ],
            })
          )
        : dispatch(
            updateSelectedBrandDescriptions({
              ...selectedDescription,
              [clusterNumber]: [
                { value: resourceVal, category: 'custom', status: 'SELECTED' },
                ...(selectedDescription[clusterNumber] || []),
              ],
            })
          );
    }
    selectedTab === 'HEADLINE'
      ? setGenerateHeadline(false)
      : setGenerateDesc(false);
    setKeywordValue('');
  };

  const toNotCallGetResource = () => {
    let stop = true;
    const currentAdsetResources = resources.filter(
      (resource) => resource.adgroupName === clusterName
    );
    currentAdsetResources.forEach((resource) => {
      if (!resource.resources) {
        stop = false;
      }
    });
    return stop;
  };

  useInterval(
    () => {
      dispatch(getResourcesReducer());
    },
    form.googleAdsCronStatus !== 'DONE' ||
      (resources && resources.length && toNotCallGetResource())
      ? null
      : 5 * 1000
  );
  const textStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  };
  const budgetTextStyle = {
    ...textStyle,
    paddingRight:'5px',
  };
  return form.googleAdsCronStatus !== 'DONE' ? (
    
    <GeneratingStrategyPage
      onClickHandler={() => {
        if (agencyType === 'LD') {
          window.location.href = '/brands';
        } else {
          dispatch(goToSpecificCompoHDMAM(0));
        }
      }}
    />
  ) : (
    
    <div className='intro_strategy_main'>
      <div className='strategy_row_layout'>
        <div className='strategy_google_row_main'>
          <div className='strategy_input_header'>
            <div className='flex-center-space strategy_input_header_row'>
            <div className='flex-center-space input_strategy_form' data-tut={type} >
            
                <label style={budgetTextStyle}>Daily Budget : (in $) </label>
                <InfoTip infotext='15% of the total budget allocated for Search Campaigns.' />
                <Input
                  value={budget}
                  onChange={(e) => changeBrandBudget(e)}
                  className='budget_unput'
                  inputProps={{ 'aria-label': 'description' }}
                /> 
              </div>
              <div className='input_strategy_actions'>
                <div>
                  <button
                    className={`switch_actions_strategy preview_icon ${
                      !isEdit ? 'active_icon' : ''
                    } `}
                    onClick={() => setIsEdit(false)}
                  ></button>
                  <button
                    className={`switch_actions_strategy edit_icon ${
                      isEdit ? 'active_icon' : ''
                    }`}
                    onClick={() => setIsEdit(true)}
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div className='strategy_accordion_layout'>
            {/* <h2 className="strategy_input_title">Ad sets</h2> */}
            <div className='strategy_row_input'>
              <div className='strategy_column_input cluster_column_input'>
                <div className='input_column_header'>
                  <h3>{clusterName}</h3>
                  <p>
                    Use this cluster to choose your top keywords to present in
                    your client strategy. You can hit ‘x’ to remove and
                    regenerate a new ‘top keyword’
                  </p>
                </div>
                <div className='strategy_list_row_input'>
                  {selectedKeywords.map((keyword, idx) => {
                    return (
                      <div
                        className={
                          'flex-center-space strategy_list_item selected'
                        }
                        key={idx}
                        onClick={() => {
                          if (isEdit) {
                            setSelectedKey(idx);
                            setSelectedKeyValue(keyword);
                            setSelectedKeyType('SELECTED');
                          } else {
                            updateKeyword(keyword, 'NOT_SELECTED');
                          }
                        }}
                      >
                        <div>
                          {isEdit ? (
                            <Input
                              value={
                                idx === selectedKey &&
                                selectedKeyType === 'SELECTED'
                                  ? selectedKeyValue
                                  : keyword
                              }
                              className='cluster_edit_input'
                              style={{
                                caretColor: selectedKey ? '' : 'transparent',
                              }}
                              onChange={(e) =>
                                setSelectedKeyValue(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (
                                  e.key === 'Enter' &&
                                  isEdit &&
                                  selectedKey == idx &&
                                  selectedKeyType === 'SELECTED'
                                ) {
                                  addKeyword(
                                    selectedKeyValue,
                                    keyword,
                                    'SELECTED'
                                  );
                                }
                              }}
                              inputProps={{ 'aria-label': 'description' }}
                            />
                          ) : (
                            <p>{keyword}</p>
                          )}
                        </div>
                        {/* <span>1</span> */}
                        <a
                          className='clear_button'
                          onClick={(e) => {
                            removeKeyword(keyword);
                            e.stopPropagation();
                          }}
                        ></a>
                      </div>
                    );
                  })}
                  {selectedKeywords.length < 50 &&
                    unselectedKeywords
                      .slice(0, 50 - selectedKeywords.length)
                      .map((keyword, idx) => {
                        return (
                          <div
                            className={'flex-center-space strategy_list_item'}
                            key={idx}
                            onClick={() => {
                              if (isEdit) {
                                setSelectedKey(idx);
                                setSelectedKeyValue(keyword);
                                setSelectedKeyType('NOT_SELECTED');
                              } else {
                                updateKeyword(keyword, 'SELECTED');
                              }
                            }}
                          >
                            <div>
                              {isEdit ? (
                                <Input
                                  placeholder='Enter Headline Here'
                                  value={
                                    idx === selectedKey &&
                                    selectedKeyType === 'NOT_SELECTED'
                                      ? selectedKeyValue
                                      : keyword
                                  }
                                  className='cluster_edit_input'
                                  onChange={(e) =>
                                    setSelectedKeyValue(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === 'Enter' &&
                                      isEdit &&
                                      selectedKey == idx
                                    ) {
                                      addKeyword(
                                        selectedKeyValue,
                                        keyword,
                                        'NOT_SELECTED'
                                      );
                                    }
                                  }}
                                  inputProps={{ 'aria-label': 'description' }}
                                />
                              ) : (
                                <p>{keyword}</p>
                              )}
                            </div>
                            {/* <span>1</span> */}
                            <a
                              className='clear_button'
                              onClick={(e) => {
                                removeKeyword(keyword);
                                e.stopPropagation();
                              }}
                            ></a>
                          </div>
                        );
                      })}
                  {/* <div className="flex-center-space strategy_list_item">
                    <p>Data Analyst</p>
                    <span>1</span>
                    <a className="clear_button"></a>
                  </div> */}
                  {generateKeyword && (
                    <div className='flex-center-space strategy_list_item edit_cluster_row'>
                      <Input
                        value={keywordValue}
                        onChange={(e) => setKeywordValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            addKeyword(keywordValue);
                          }
                        }}
                        className='cluster_edit_input'
                        inputProps={{ 'aria-label': 'description' }}
                      />
                      {/* <span>1</span> */}
                      <a className='clear_button'></a>
                    </div>
                  )}
                </div>
                <div className='flex-center-space strategy_input_list_footer'>
                  <a
                    href='javascript:;'
                    className='link_footer'
                    onClick={() => setGenerateKeyWord(true)}
                  >
                    + Add Keyword
                  </a>
                  <PrimaryButton
                    title='Generate Ads'
                    onClick={generateAdsHandler}
                  />
                </div>
              </div>
              <div className='strategy_column_input'>
                <div className='input_column_header'>
                  <h3>Ad level</h3>
                  {selectedTab !== 'PREVIEW' && (
                    <p>{` Choose your top ${
                      selectedTab === 'HEADLINE' ? 'headlines' : 'descriptions'
                    }  to present in your client strategy.You can hit ‘x’ to remove and regenerate a new ‘top ${
                      selectedTab === 'HEADLINE' ? 'headlines' : 'descriptions'
                    }`}</p>
                  )}
                </div>
                <Tabs
                  onSelect={(index) => {
                    setSelectedTab(
                      ['HEADLINE', 'DESCRIPTION', 'PREVIEW'][index]
                    );
                  }}
                >
                  <div className='custom_tab_header test'>
                    <TabList>
                      {['HEADLINE', 'DESCRIPTION', 'PREVIEW'].map(
                        (head, idx) => {
                          return (
                            <Tab
                              key={idx}
                              setClusterNumber={setSelectedTab}
                              number={head}
                            >
                              {head}
                            </Tab>
                          );
                        }
                      )}
                    </TabList>
                  </div>
                  <TabPanel>
                    <div className={`tabpanel_strategy`}>
                      {(selectedHeadlines[clusterNumber] || []).map(
                        (headline, idx) => {
                          return (
                            <div className='headerline_row_strategy' key={idx}>
                              <div
                                className='headline_column_strategy selected '
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (isEdit) {
                                    setSelectedHead(idx);
                                    setSelectedHeadValue(headline.value);
                                    setHeadType('SELECTED');
                                  } else {
                                    updateResource(headline, 'NOT_SELECTED');
                                  }
                                }}
                              >
                                {/* <input className="cluster_edit_input" type="text" value={headline} readOnly/> */}
                                {/* <Input placeholder="Enter Headline Here" value={headline} readOnly={!isEdit} className="cluster_edit_input" inputProps={{ 'aria-label': 'description' }} /> */}
                                {isEdit ? (
                                  <Input
                                    placeholder='Enter Headline Here'
                                    value={
                                      isEdit &&
                                      idx === selectedHead &&
                                      headType === 'SELECTED'
                                        ? selectedHeadValue
                                        : headline.value
                                    }
                                    readOnly={!isEdit}
                                    className='cluster_edit_input'
                                    onChange={(e) =>
                                      setSelectedHeadValue(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === 'Enter' &&
                                        isEdit &&
                                        selectedHead == idx &&
                                        headType === 'SELECTED'
                                      ) {
                                        addResource(
                                          selectedHeadValue,
                                          headline,
                                          'SELECTED'
                                        );
                                        setSelectedHead(null);
                                        setSelectedHeadValue('');
                                        setHeadType(null);
                                      }
                                    }}
                                    inputProps={{
                                      'aria-label': 'description',
                                      maxLength: 30,
                                    }}
                                  />
                                ) : (
                                  <h4>{headline.value}</h4>
                                )}
                                <div className='headline_keyword_details'>
                                  <p>{headline.category}</p>
                                  <span>
                                    {idx === selectedHead &&
                                    headType === 'SELECTED'
                                      ? selectedHeadValue.length
                                      : (headline.value || '').length}
                                    /30
                                  </span>
                                </div>
                              </div>
                              <div className='headline_column_actions'>
                                <button
                                  className='actions_headline_strategy clear_icon'
                                  onClick={() => removeResource(headline)}
                                ></button>
                              </div>
                            </div>
                          );
                        }
                      )}

                      {(selectedHeadlines[clusterNumber] || []).length < 30 &&
                        (nonSelectedHeadlines[clusterNumber] || [])
                          .slice(
                            0,
                            30 - (selectedHeadlines[clusterNumber] || []).length
                          )
                          .map((headline, idx) => {
                            return (
                              <div
                                className='headerline_row_strategy'
                                key={idx}
                              >
                                <div
                                  className='headline_column_strategy'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    if (isEdit) {
                                      setSelectedHead(idx);
                                      setSelectedHeadValue(headline.value);
                                      setHeadType('NOT_SELECTED');
                                    } else {
                                      updateResource(headline);
                                    }
                                  }}
                                >
                                  {isEdit ? (
                                    <Input
                                      placeholder='Enter Headline Here'
                                      value={
                                        isEdit &&
                                        idx === selectedHead &&
                                        headType === 'NOT_SELECTED'
                                          ? selectedHeadValue
                                          : headline.value
                                      }
                                      readOnly={!isEdit}
                                      className='cluster_edit_input'
                                      onChange={(e) =>
                                        setSelectedHeadValue(e.target.value)
                                      }
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === 'Enter' &&
                                          isEdit &&
                                          selectedHead == idx &&
                                          headType === 'NOT_SELECTED'
                                        ) {
                                          addResource(
                                            selectedHeadValue,
                                            headline
                                          );
                                          setSelectedHead(null);
                                          setSelectedHeadValue('');
                                          setHeadType(null);
                                        }
                                      }}
                                      inputProps={{
                                        'aria-label': 'description',
                                        maxLength: 30,
                                      }}
                                    />
                                  ) : (
                                    <h4>{headline.value}</h4>
                                  )}
                                  <div className='headline_keyword_details'>
                                    <p>{headline.category}</p>
                                    <span>
                                      {idx === selectedHead &&
                                      headType === 'NOT_SELECTED'
                                        ? selectedHeadValue.length
                                        : headline.value.length}
                                      /30
                                    </span>
                                  </div>
                                </div>
                                <div className='headline_column_actions'>
                                  <button
                                    className='actions_headline_strategy clear_icon'
                                    onClick={() => removeResource(headline)}
                                  ></button>
                                </div>
                              </div>
                            );
                          })}

                      {generateHeadline && (
                        <div className='headerline_row_strategy'>
                          <div className='headline_column_strategy'>
                            <Input
                              placeholder='Enter Headline Here'
                              value={keywordValue}
                              onChange={(e) => setKeywordValue(e.target.value)}
                              className='cluster_edit_input'
                              inputProps={{
                                'aria-label': 'description',
                                color: '#444',
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  addResource(keywordValue);
                                }
                              }}
                            />
                            <div className='headline_keyword_details'>
                              <p>Custom</p>
                              <span>{keywordValue.length}/30</span>
                            </div>
                          </div>
                          <div className='headline_column_actions'>
                            <button
                              className='actions_headline_strategy clear_icon'
                              onClick={() => {
                                setKeywordValue('');
                                setGenerateHeadline(false);
                              }}
                            ></button>
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className={`tabpanel_strategy`}>
                      {(selectedDescription[clusterNumber] || []).map(
                        (desc, idx) => {
                          return (
                            <div className='headerline_row_strategy ' key={idx}>
                              <div
                                className='headline_column_strategy selected '
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (isEdit) {
                                    setSelectedDesc(idx);
                                    setSelectedDescValue(desc.value);
                                    setDescType('SELECTED');
                                  } else {
                                    updateResource(desc, 'NOT_SELECTED');
                                  }
                                }}
                              >
                                {isEdit ? (
                                  <Input
                                    value={
                                      isEdit &&
                                      idx === selectedDesc &&
                                      descType === 'SELECTED'
                                        ? selectedDescValue
                                        : desc.value
                                    }
                                    readOnly={!isEdit}
                                    className='cluster_edit_input'
                                    onChange={(e) =>
                                      setSelectedDescValue(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === 'Enter' &&
                                        isEdit &&
                                        selectedDesc == idx &&
                                        descType === 'SELECTED'
                                      ) {
                                        addResource(
                                          selectedDescValue,
                                          desc,
                                          'SELECTED'
                                        );
                                        setSelectedDesc(null);
                                        setSelectedDescValue('');
                                        setDescType(null);
                                      }
                                    }}
                                    inputProps={{ 'aria-label': 'description' }}
                                  />
                                ) : (
                                  <h4>{desc.value}</h4>
                                )}
                                <div className='headline_keyword_details'>
                                  <p>{desc.category || ''}</p>
                                  <span>
                                    {idx === selectedHead &&
                                    headType === 'SELECTED'
                                      ? selectedDescValue.length
                                      : desc.value.length}
                                    /90
                                  </span>
                                </div>
                              </div>
                              <div className='headline_column_actions'>
                                <button
                                  className='actions_headline_strategy clear_icon'
                                  onClick={() => removeResource(desc)}
                                ></button>
                              </div>
                            </div>
                          );
                        }
                      )}

                      {(
                        ((selectedDescription[clusterNumber] || []).length <
                          30 &&
                          nonSelectedDescription[clusterNumber]) ||
                        []
                      )
                        .slice(
                          0,
                          30 - (selectedDescription[clusterNumber] || []).length
                        )
                        .map((desc, idx) => {
                          return (
                            <div className='headerline_row_strategy' key={idx}>
                              <div
                                className='headline_column_strategy'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (isEdit) {
                                    setSelectedDesc(idx);
                                    setSelectedDescValue(desc.value);
                                    setDescType('NOT_SELECTED');
                                  } else {
                                    updateResource(desc);
                                  }
                                }}
                              >
                                {isEdit ? (
                                  <Input
                                    value={
                                      isEdit &&
                                      idx === selectedDesc &&
                                      descType === 'NOT_SELECTED'
                                        ? selectedDescValue
                                        : desc.value
                                    }
                                    readOnly={!isEdit}
                                    className='cluster_edit_input'
                                    onChange={(e) =>
                                      setSelectedDescValue(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === 'Enter' &&
                                        isEdit &&
                                        selectedDesc == idx &&
                                        descType === 'NOT_SELECTED'
                                      ) {
                                        addResource(selectedDescValue, desc);
                                        setSelectedDesc(null);
                                        setSelectedDescValue('');
                                        setDescType(null);
                                      }
                                    }}
                                    inputProps={{ 'aria-label': 'description' }}
                                  />
                                ) : (
                                  <h4>{desc.value}</h4>
                                )}
                                <div className='headline_keyword_details'>
                                  <p>{desc.category}</p>
                                  <span>
                                    {idx === selectedHead &&
                                    headType === 'NOT_SELECTED'
                                      ? selectedDescValue.length
                                      : (desc.value || '').length}
                                    /90
                                  </span>
                                </div>
                              </div>
                              <div className='headline_column_actions'>
                                <button
                                  className='actions_headline_strategy clear_icon'
                                  onClick={() => removeResource(desc)}
                                ></button>
                              </div>
                            </div>
                          );
                        })}

                      {generateDesc && (
                        <div className='headerline_row_strategy'>
                          <div className='headline_column_strategy'>
                            <Input
                              placeholder='Enter Description Here'
                              value={keywordValue}
                              onChange={(e) => setKeywordValue(e.target.value)}
                              className='cluster_edit_input'
                              inputProps={{ 'aria-label': 'description' }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  addResource(keywordValue);
                                }
                              }}
                            />
                            <div className='headline_keyword_details'>
                              <p>Custom</p>
                              <span>{keywordValue.length}/90</span>
                            </div>
                          </div>
                          <div className='headline_column_actions'>
                            <button
                              className='actions_headline_strategy clear_icon'
                              onClick={() => {
                                setKeywordValue('');
                                setGenerateDesc(false);
                              }}
                            ></button>
                          </div>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className={`tabpanel_strategy`}>
                      <div className='strategy_campaign_preview'>
                        <p className='campaign_preview_title'>
                          {selectedHeadlines[clusterNumber]
                            ? selectedHeadlines[clusterNumber]
                                .slice(0, 3)
                                .map((head) => head.value)
                                .join(' - ')
                            : ''}
                        </p>
                        <p className='campaign_preview_link'>
                          {form?.onboardingInfo?.personalInfo?.companyWebsite}
                        </p>
                        <p className='campaign_preview_desc'>
                          {selectedDescription[clusterNumber]
                            ? selectedDescription[clusterNumber]
                                .slice(0, 1)
                                .map((desc) => desc.value)
                                .join(' - ')
                            : ''}
                        </p>
                      </div>
                      <div className='strategy_campaign_preview'>
                        <p className='campaign_preview_title'>
                          {selectedHeadlines[clusterNumber]
                            ? selectedHeadlines[clusterNumber]
                                .slice(3, 6)
                                .map((head) => head.value)
                                .join(' - ')
                            : ''}
                        </p>
                        <p className='campaign_preview_link'>
                          {form?.onboardingInfo?.personalInfo?.companyWebsite}
                        </p>
                        <p className='campaign_preview_desc'>
                          {selectedDescription[clusterNumber]
                            ? selectedDescription[clusterNumber]
                                .slice(1, 2)
                                .map((desc) => desc.value)
                                .join(' - ')
                            : ''}
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
                {selectedTab !== 'PREVIEW' && (
                  <div className='flex-center-center strategy_input_list_footer'>
                    <a
                      href='javascript:;'
                      className='link_footer'
                      onClick={() => {
                        if (selectedTab === 'HEADLINE') {
                          setGenerateHeadline(true);
                        } else {
                          setGenerateDesc(true);
                        }
                      }}
                    >
                      + Add{' '}
                      {selectedTab === 'HEADLINE' ? 'Headline' : 'Description'}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='preview_footer_row'>
        <div
          className='footer_content'
          style={{ justifyContent: 'space-between' }}
        >
          <button
            className='back'
            onClick={() => {
              if (agencyType === 'LD') {
                dispatch(
                  goToSpecificCompoClientAm(
                    ldComponentWithIndexAm[
                      accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY
                    ]
                  )
                );
              } else {
                dispatch(
                  goToSpecificCompoHDMAM(
                    componentWithIndexHDMAm[
                      accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY
                    ]
                  )
                );
              }
            }}
          >
            BACK
          </button>
          <GoToNextCompoButton
            title='Confirm'
            onClickHandler={onClickHandler}
            agencyType={agencyType}
          />
        </div>
      </div>

      {isGeneratingModal && <GenerateModal />}
    </div>
  );
};
export default StrategyInput;
