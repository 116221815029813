import { useState } from 'react';
import { Typography } from '@mui/material';
import React from 'react';

import {pencil as pencilIcon} from '../../../assets/icons/common/common';
import TextField from '@material-ui/core/TextField';
import {checked_icon  as checkedIcon} from '../../../assets/icons/common/common';
const OptionalAdOns = (props) => {
  const [addLPPriceEdit, setaddLPPriceEdit] = useState(false);
  const [addPUPriceEdit, setaddPUPriceEdit] = useState(false);
  const [addSBPriceEdit, setaddSBPriceEdit] = useState(false);
  const [callRailPriceEdit, setCallRailPriceEdit] = useState(false);
  const [hotjarPriceEdit, setHotjarPriceEdit] = useState(false);
  return (
    <>
      <ul
        style={{
          listStyleType: 'none',
          paddingLeft: 0,
        }}
      >
        <li
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <div style={{ display: 'flex', gap: '0.75rem' }}>
            <img src={checkedIcon} alt='' />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12.85,
                color: '#170F49',
              }}
            >
              Additional Landing Pages
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '2.2rem', gap: '1.5rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Quantity
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.decreaseLPQuantityHandler}
                >
                  -
                </span>
                <span
                  style={{
                    padding: '0 0.8rem',
                    margin: '0 0.25rem',
                    border: '0.5px solid #E1E1E1',
                    borderRadius: 4,
                  }}
                >
                  {props.additionalLPQuantity}
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.increaseLPQuantityHandler}
                >
                  +
                </span>
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Price
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                $
                {addLPPriceEdit ? (
                  <TextField
                    inputProps={{ style: { width: '35px', padding: 0 } }}
                    value={props.additionalLPPrice}
                    onChange={(e) =>
                      props.setAdditionalLPPrice(
                        e.target.value)
                    }
                  />
                ) : (
                  props.additionalLPPrice
                )}
                {/* &nbsp;&nbsp;
                <img
                  src={pencilIcon}
                  alt=''
                  onClick={() => setaddLPPriceEdit(!addLPPriceEdit)}
                /> */}
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <div style={{ display: 'flex', gap: '0.75rem' }}>
            <img src={checkedIcon} alt='' />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12.85,
                color: '#170F49',
              }}
            >
              Additional Pop Up
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '2.2rem', gap: '1.5rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Quantity
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.decreasePUQuantityHandler}
                >
                  -
                </span>
                <span
                  style={{
                    padding: '0 0.8rem',
                    margin: '0 0.25rem',
                    border: '0.5px solid #E1E1E1',
                    borderRadius: 4,
                  }}
                >
                  {props.additionalPUQuantity}
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.increasePUQuantityHandler}
                >
                  +
                </span>
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Price
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                $
                {addPUPriceEdit ? (
                  <TextField
                    inputProps={{ style: { width: '35px', padding: 0 } }}
                    value={props.additionalPUPrice}
                    onChange={(e) =>
                      props.setAdditionalPUPrice(
                        e.target.value)
                    }
                  />
                ) : (
                  props.additionalPUPrice
                )}
                {/* &nbsp;&nbsp;
                <img
                  src={pencilIcon}
                  alt=''
                  onClick={() => setaddPUPriceEdit(!addPUPriceEdit)}
                /> */}
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <div style={{ display: 'flex', gap: '0.75rem' }}>
            <img src={checkedIcon} alt='' />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12.85,
                color: '#170F49',
              }}
            >
              Additional Sticky Bar
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '2.2rem', gap: '1.5rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Quantity
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.decreaseSBQuantityHandler}
                >
                  -
                </span>
                <span
                  style={{
                    padding: '0 0.8rem',
                    margin: '0 0.25rem',
                    border: '0.5px solid #E1E1E1',
                    borderRadius: 4,
                  }}
                >
                  {props.additionalSBQuantity}
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.increaseSBQuantityHandler}
                >
                  +
                </span>
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Price
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                $
                {addSBPriceEdit ? (
                  <TextField
                    inputProps={{ style: { width: '35px', padding: 0 } }}
                    value={props.additionalSBPrice}
                    onChange={(e) =>
                      props.setAdditionalSBPrice(
                        e.target.value)
                    }
                  />
                ) : (
                  props.additionalSBPrice
                )}
                {/* &nbsp;&nbsp;
                <img
                  src={pencilIcon}
                  alt=''
                  onClick={() => setaddSBPriceEdit(!addSBPriceEdit)}
                /> */}
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <div style={{ display: 'flex', gap: '0.75rem' }}>
            <img src={checkedIcon} alt='' />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12.85,
                color: '#170F49',
              }}
            >
              Call Rail
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '2.2rem', gap: '1.5rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Quantity
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.decreaseCallRailQuantityHandler}
                >
                  -
                </span>
                <span
                  style={{
                    padding: '0 0.8rem',
                    margin: '0 0.25rem',
                    border: '0.5px solid #E1E1E1',
                    borderRadius: 4,
                  }}
                >
                  {props.callRailQuantity}
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.increaseCallRailQuantityHandler}
                >
                  +
                </span>
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Price
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                $
                {callRailPriceEdit ? (
                  <TextField
                    inputProps={{ style: { width: '35px', padding: 0 } }}
                    value={props.callRailPrice}
                    onChange={(e) =>
                      props.setCallRailPrice(e.target.value )
                    }
                  />
                ) : (
                  props.callRailPrice
                )}
                {/* &nbsp;&nbsp;
                <img
                  src={pencilIcon}
                  alt=''
                  onClick={() => setCallRailPriceEdit(!callRailPriceEdit)}
                /> */}
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <div style={{ display: 'flex', gap: '0.75rem' }}>
            <img src={checkedIcon} alt='' />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12.85,
                color: '#170F49',
              }}
            >
              Hotjar (heatmaps, user recordings)
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '2.2rem', gap: '1.5rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Quantity
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.decreaseHotjarQuantityHandler}
                >
                  -
                </span>
                <span
                  style={{
                    padding: '0 0.8rem',
                    margin: '0 0.25rem',
                    border: '0.5px solid #E1E1E1',
                    borderRadius: 4,
                  }}
                >
                  {props.hotjarQuantity}
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={props.increaseHotjarQuantityHandler}
                >
                  +
                </span>
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Price
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#170F49',
                }}
              >
                $
                {hotjarPriceEdit ? (
                  <TextField
                    inputProps={{ style: { width: '35px', padding: 0 } }}
                    value={props.hotjarPrice}
                    onChange={(e) =>
                      props.setHotjarPrice(e.target.value )
                    }
                  />
                ) : (
                  props.hotjarPrice
                )}
                {/* &nbsp;&nbsp;
                <img
                  src={pencilIcon}
                  alt=''
                  onClick={() => setHotjarPriceEdit(!hotjarPriceEdit)}
                /> */}
              </Typography>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default OptionalAdOns;
