import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  RadioGroup,
} from '@mui/material';
import { useDispatch } from 'react-redux';

const values = [
  'Less than high school degree',
  'High school diploma or equivilant',
  'Some college',
  'Associate Degree',
  'Bachelor Degree',
  'Graduate Degree',
];
const HighestDegreeCheckBox = ({
  value,
  objKey,
  infoType,
  outerKey,
  error,
  errorText,
}) => {
  const dispatch = useDispatch();
  return (
    <FormControl variant='standard'>
      <Select
        inputProps={{ 'aria-label': 'Without label' }}
        style={{ width: 600 }}
        placeholder='Choose your answer here...'
        multiple
        renderValue={(selected) => selected.join(',')}
        value={
          value.slice(1).length === 0 ? ['Choose Answers...'] : value.slice(1)
        }
      >
        <MenuItem disabled value='Choose your answer here'>
          <em>Choose your answer here</em>
        </MenuItem>
        <>
          {values.map((value1, index) => (
            <MenuItem value={value1} key={index}>
              <FormControlLabel
                value={value1}
                control={<Checkbox />}
                label={value1}
                onChange={(e) => {
                  dispatch({
                    type: 'GET_VALUE_MULTIPLE_CHECKBOX_DROPDOWN',
                    payload: {
                      infoType: infoType,
                      key: objKey,
                      value: e.target.value,
                      outerKey: outerKey,
                    },
                  });
                }}
                checked={value.includes(value1) ? true : false}
              />
            </MenuItem>
          ))}
        </>
      </Select>
      {error && <p style={{ color: 'red' }}>{errorText}</p>}
    </FormControl>
  );
};

export default HighestDegreeCheckBox;
