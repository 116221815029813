import {
  accountManagerIdsHDM,
  componentWithIndexHDMAm,
} from "../../../utils/accountManagerUtil";
import { HDMHeaderTabs, listOfIdsHDM } from "../../../utils/navbarUtil";

const INITIAL_STATE = {
  currentCompAm: 0,
  currentLeftSidebarAm: listOfIdsHDM.SELECTBRAND,
  currentHeaderTabAm: null,
  lastActiveCompAm: 0,
  clientView: false,
};

export const updateNavAM = (currentComp) => {
  if (
    currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.SELECT_BRAND]
  ) {
    return listOfIdsHDM.SELECTBRAND;
  } else if (
    currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.FORM_PREVIEW]
  ) {
    return listOfIdsHDM.onboardingInfo;
  } else if (
    currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.ACCESS_SHARING]
  ) {
    return listOfIdsHDM.accessSharing;
  } else if (
    currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ONBOARDING] &&
    currentComp < componentWithIndexHDMAm[accountManagerIdsHDM.AUDIT_GOOGLE_ADS]
  ) {
    return listOfIdsHDM.ONBOARDINGOC;
  } else if (
    currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.AUDIT_GOOGLE_ADS] &&
    currentComp < componentWithIndexHDMAm[accountManagerIdsHDM.HDM_FACEBOOK_ACCOUNTS]
  ) {
    return listOfIdsHDM.AUDIT_FOR_GOOGLE;
  } else if (
    currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.HDM_FACEBOOK_ACCOUNTS] &&
    currentComp < componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_GOOGLE]
  ) {
    return listOfIdsHDM.AUDIT;
  } else if (
    currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_GOOGLE] &&
    currentComp < componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY]
  ) {
    return listOfIdsHDM.AccountAnalysis;
  } // else if (
    // currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_BRAND_CAMPAIGN] &&
    // currentComp < componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE]
  // ) // {
    // strategy creation google
    else if (
    currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY] &&
    currentComp <= componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_3]
    ) {
    return listOfIdsHDM.strategyInput;
    } else if (
    currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE] &&
    currentComp < componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOAL]
  ) {
    return listOfIdsHDM.StrategyCreationFacebook;
  } else if (
    currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOAL] &&
    currentComp <= componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_CHANGES_SUGGESTED]
  ) {
    return listOfIdsHDM.STRATEGY_DECK;
  } else if (
    currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_PAGE]
  ) {
    return listOfIdsHDM.CampaignCreationGoogle;
  } else if (
    currentComp >= componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_FB] &&
    currentComp <= componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ADSET_LEVEL]
  ) {
    return listOfIdsHDM.CampaignCreationFB;
  } else if (
    currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_PREVIEW_PAGE]
  ) {
    return listOfIdsHDM.CampaignPreviewGoogle;
  } else if (
    currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_PREVIEW_FB]
  ) {
    return listOfIdsHDM.CampaignPreviewFB;
  } else {
    return null;
  }
};

export const updateHeaderAM = (currentComp) => {
  if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ONBOARDING]) {
    return HDMHeaderTabs["ONBOARDING"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ONBOARDING_REVIEW]) {
    return HDMHeaderTabs["REVIEWBRIEFINGDOCUMENT"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ONBOARDING_COMMUNICATION]) {
    return HDMHeaderTabs["EXPECTATIONS"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.AUDIT_GOOGLE_ADS]) {
    return HDMHeaderTabs["GOOGLE_ADS_AUDIT"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.AUDIT_REVIEW_POINTS]) {
    return HDMHeaderTabs["GOOGLE_ADS_AUDIT"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_ACCOUNT]) {
    return HDMHeaderTabs["ACCOUNT"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_CAMPAIGN]) {
    return HDMHeaderTabs["CAMPAIGN"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_ADSET]) {
    return HDMHeaderTabs["ADSET"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_AD]) {
    return HDMHeaderTabs["AD"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_GOOGLE]) {
    return HDMHeaderTabs["GOOGLE"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_FACEBOOK]) {
    return HDMHeaderTabs["FACEBOOK"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_WEBSITE]) {
    return HDMHeaderTabs["WEBSITE"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_CREATIVES]) {
    return HDMHeaderTabs["CREATIVES"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_COMPETITOR]) {
    return HDMHeaderTabs["COMPETITIOR"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_BRAND_CAMPAIGN]) {
    return HDMHeaderTabs["BRANDCAMPAIGN"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_COMPETITIER_CAMPAIGN]) {
    return HDMHeaderTabs["COMPETITORCAMPAIGN"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_SERVICE_CAMPAIGN]) {
    return HDMHeaderTabs["SERVICECAMPAIGN"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_1]) {
    return HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_1];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_2]) {
    return HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_2];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_3]) {
    return HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_3];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE]) {
    return HDMHeaderTabs["LOOKALIKE"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_INTEREST]) {
    return HDMHeaderTabs["INTEREST"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_MOF]) {
    return HDMHeaderTabs["MOF"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_BOF]) {
    return HDMHeaderTabs["BOF"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOAL]) {
    return HDMHeaderTabs["GOAL"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_ANALYSIS]) {
    return HDMHeaderTabs["ANALYSIS"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOOGLE_STRATEGY]) {
    return HDMHeaderTabs["GOOGLESTRATEGY"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_FB_STRATEGY]) {
    return HDMHeaderTabs["FBSTRATEGY"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_OUR_PLAN]) {
    return HDMHeaderTabs["OURPLAN"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_BEST_PRACTICES]) {
    return HDMHeaderTabs["BESTPRACTICES"];
  } else if (currentComp === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_CHANGES_SUGGESTED]) {
    return HDMHeaderTabs["CHANGESSUGGESTED"];
  } else {
    return null;
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_CURRENT_COMPONENT_HDMAM":
      let comp = state.currentCompAm;
      console.log("comp :>> ", comp);
      return {
        ...state,
        currentCompAm: comp + 1,
        currentHeaderTabAm: updateHeaderAM(comp + 1),
        currentLeftSidebarAm: updateNavAM(comp + 1),
      };
    case "GO_BACK_AM_COMPO":
      let currentComp = state.currentCompAm;
      currentComp -= 1;
      return {
        ...state,
        currentCompAm: currentComp,
        currentHeaderTabAm: updateHeaderAM(currentComp),
        currentLeftSidebarAm: updateNavAM(currentComp),
      };
    case "GO_TO_SPECIFIC_COMPO_HDMAM":
      const currentComp1 = action.payload;
      return {
        ...state,
        lastActiveCompAm: state.currentCompAm,
        currentCompAm: currentComp1,
        currentHeaderTabAm: updateHeaderAM(currentComp1),
        currentLeftSidebarAm: updateNavAM(currentComp1),
      };
    case "UPDATE_TAB_HDMAM":
      const currentComp2 = action.payload;
      return {
        ...state,
        currentCompAm: currentComp2,
        currentHeaderTabAm: updateHeaderAM(currentComp2),
        currentLeftSidebarAm: updateNavAM(currentComp2),
      };
    case "UPDATE_SIDE_BAR_HDMAM":
      const currentComp3 = action.payload;
      return {
        ...state,
        currentCompAm: currentComp3,
        currentHeaderTabAm: updateHeaderAM(currentComp3),
        currentLeftSidebarAm: updateNavAM(currentComp3),
        clientView: false,
      };
    case "REVIEW_QUESTIONIER_AM":
      return { ...state, ...action.payload };

    case "GO_BACK_REVIEW_QUESTIONIER_AM": {
      const currentComp4 = action.payload;
      return {
        ...state,
        currentCompAm: currentComp4.currentComp,
        currentHeaderTabAm: updateHeaderAM(currentComp4.currentComp),
        currentHeaderTabAm: updateNavAM(currentComp4.currentComp),
        lastActiveComp: 0,
      };
    }
    case "OPEN_HDM_CLIENT_VIEW":
      return {
        ...state,
        clientView: true,
        currentLeftSidebarAm: listOfIdsHDM.CLIENTANDACCESS,
      };
    case "CLOSE_HDM_CLIENT_VIEW":
      return {
        ...state,
        clientView: false,
        currentLeftSidebarAm: listOfIdsHDM.onboardingInfo,
      };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
