import { put, takeLatest, takeEvery} from 'redux-saga/effects';
import { api } from '../../index';
import { udpateResourceIds, generateResourceReducer, updateResourcesData, getResourcesReducer, updateResourceReducer,updatedGenerated, updateResourcesStatusReducer, updateAllResourceData, editResourceStatusReducer} from './slice';
import { getLocalStorage } from '../../localStorage/localStorage';


function* generateResource(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/creative-resources/google-seach-ads/${formId}`;
  const { campaignType, interestKeywords, adgroupName, resourceType } = action.payload;
  const body = {
    campaignType, interestKeywords, adgroupName, resourceType
  }
  const creativeResponse = yield api.post(url, body);
  yield put(udpateResourceIds({[campaignType]: creativeResponse.data.resourcesId}));
  yield put(updatedGenerated());
}

function* getResources(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const creativeResponse = yield api.get(`/creative-resources/google-seach-ads/${formId}`);
  console.log("!!!!!!!!!!!!!!!!!!!!!!", creativeResponse);
  yield put(updateResourcesData(creativeResponse.data.values));
  const brandedResources = creativeResponse.data.values.filter(resource => resource.campaignType === "branded_keyword");
  const competitorResources = creativeResponse.data.values.filter(resource => resource.campaignType === "competitors_keyword");
  const serviceResources = creativeResponse.data.values.filter(resource => resource.campaignType === "service_keyword");
  yield put(updateAllResourceData({brandedResources, competitorResources, serviceResources}));
}

function* updateResourceStatus(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const {resourceId, resourceValue, status} = action.payload;
  const url = `/creative-resources/${formId}/${resourceId}`;
  const body = {
    resourceValue,
    status
  }
  const creativeResponse = yield api.put(url, body);
  if(creativeResponse.data.success){
    yield put(getResourcesReducer())
  }
}

function* editResourceStatus(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const {items} = action.payload;
  const url = `/creative-resources/google-seach-ads/${formId}`;
  yield api.put(url, {items});
}

function* updateResourcesStatus(action) {
  const { resourceId, resourceValues, status } = action.payload;
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  yield* resourceValues.map(function* (resourceValue) {
    const url = `/creative-resources/${formId}/${resourceId}`;
    const body = {
      resourceValue,
      status
    }
    yield api.put(url, body);
  })
  yield put(getResourcesReducer())

}

export default function* creativeAISaga() {
  yield takeLatest(generateResourceReducer.type, generateResource);
  yield takeLatest(getResourcesReducer.type, getResources);
  yield takeEvery(updateResourceReducer.type, updateResourceStatus);
  yield takeLatest(updateResourcesStatusReducer.type, updateResourcesStatus);
  yield takeLatest(editResourceStatusReducer.type, editResourceStatus);

}