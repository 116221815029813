import React, { useState } from 'react';
import GoToNextCompoButton from '../../ButtonPrimary/GoToNextCompoButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  MenuList,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { AB_TEST_SECTION_ENUM } from '../../../api/creativeApproval/enums';

const ABTestAddModal = ({ setModal, handleAddNewTest }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    // height: '800px',
    borderRadius: '15px',
    outline: 'none',
  };
  const textFieldStyle = {
    background: '#FFFFFF',
    border: '1px solid #DDDDDD',
    borderRadius: 8,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262626',
  };
  const [testName, setTestName] = useState('');
  const [testStatus, setTestStatus] = useState('Not Started');
  const handleChange = (event) => {
    setTestStatus(event.target.value);
  };
  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='abtest-add-modal'
    >
      <Box sx={style}>
        <Close
          onClick={() => setModal(false)}
          style={{
            position: 'absolute',
            top: '1.65rem',
            right: '1.5rem',
            cursor: 'pointer',
            background: '#F2F2F2',
            borderRadius: 4,
          }}
        />
        <div
        //   style={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     height: '100%',
        //     gap: '0.5rem',
        //   }}
        >
          <div
            style={{
              padding: '0 0 1rem 0',
              borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
              width: '100%',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 20,
                color: '#1C1E21',
              }}
            >
              Add New A/B Test Option
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1.5rem',
              gap: '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                  color: '#1C1E21',
                }}
              >
                Test Name
              </Typography>
              <TextField
                id='outlined-basic'
                variant='outlined'
                // value={utmSource}
                onChange={(e) => setTestName(e.target.value)}
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                inputProps={
                  {
                    // readOnly: true
                  }
                }
                style={textFieldStyle}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                  color: '#1C1E21',
                }}
              >
                Test Section
              </Typography>

              <Select
                displayEmpty
                variant='outlined'
                value={testStatus}
                onChange={handleChange}
                style={{
                  background: '#FFFFFF',
                  border: '1px solid #F3F3F3',
                  borderRadius: 6,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                  outline: 'none',
                  boxShadow: 'none',
                  margin: '8px 0 18px 0',
                }}
                className='ab-tests-test-section'
              >
                <MenuItem
                  value={AB_TEST_SECTION_ENUM.MESSAGING_TESTS}
                  style={{
                    padding: '12px 0',
                  }}
                  className='abtest-type-value'
                >
                  {AB_TEST_SECTION_ENUM.MESSAGING_TESTS}
                </MenuItem>
                <MenuItem
                  value={AB_TEST_SECTION_ENUM.FORM_TESTS}
                  style={{
                    padding: '12px 0',
                  }}
                  className='abtest-type-value'
                >
                  {AB_TEST_SECTION_ENUM.FORM_TESTS}
                </MenuItem>
                <MenuItem
                  value={AB_TEST_SECTION_ENUM.ADDITIONAL_TESTS}
                  style={{
                    padding: '12px 0',
                  }}
                  className='abtest-type-value'
                >
                  {AB_TEST_SECTION_ENUM.ADDITIONAL_TESTS}
                </MenuItem>
              </Select>
            </div>
          </div>
          <div
            style={{
              //   margin: '2rem 0',
              paddingTop: '2rem',
              display: 'flex',
              gap: '1.5rem',
              justifyContent: 'end',
            }}
          >
            {/* onClick={() => setModal(false)} */}
            <Button
              style={{
                width: '81px',
                height: '40px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                textTransform: 'capitalize',
                background: '#0869FB',
              }}
              onClick={() => {
                handleAddNewTest({ section: testStatus, question: testName });
                setModal(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ABTestAddModal;
