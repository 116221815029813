import { useDispatch, useSelector } from 'react-redux';
import CommonGoogleAdsAccount from './CommonGoogleAdsAccount';
import { generateKeywordsReducer } from "../../api/accountManager/targetAI/slice";
import { useCallback } from 'react';
import { updateCompoReduxHDMAM } from "../../api/HDMAM/componentTracking/action";

export default function HDMGoogleAdsAccount() {
  const dispatch = useDispatch();

  const form = useSelector((store) =>  store.hdmFormAm);

  const onGotInsights = useCallback(() => {
    dispatch(generateKeywordsReducer());
  }, [dispatch]);

  const onHaveInsights = useCallback(() => {
    dispatch(updateCompoReduxHDMAM());
  }, [dispatch]);
  
  return (
    <CommonGoogleAdsAccount
      form={form}
      // onGotInsights={onGotInsights}
      onHaveInsights={onHaveInsights}
    />
  )
}