import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getHDMClientDetailsByFormId,
  updateHDMClientDetails,
} from "../../../../api/HDMClient/action";
import { updateHDMCompoReduxClient,goBackHDMClientCompo } from "../../../../api/HDMClient/componentTracking/action";
import { setLocalStorage } from "../../../../api/localStorage/localStorage";
import {box_icon  as BOX} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {bulb_icon as BULB} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {facebook_icon as FACEBOOK} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {footer_icon as THANKS} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {gift_icon as GIFT} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {message_icon as MSG} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {monitor_icon as SCREEN} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {next_icon as NEXT} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {pen_icon as PEN} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {person_icon as PERSON} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {phone_icon as PHONE} from "../../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {updateStatusProposalHdm} from "../../../../api/HDMAM/form/saga";
import "./communication.css";


const CommunicationHDM = () => {
  const dispatch = useDispatch();
  const hdmClient=useSelector(state => state.hdmClient);
  console.log("HDM",hdmClient)
  return (
    <div>
      <div className="proposal_layout proposal_flex_top ">
        <div className="preview_head">
          <h4>ONBOARDING DOCUMENT</h4>
          <h2>Horizons Digital Marketing </h2>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="back_button">
          {" "}
          <Button style={{ color: "#0869FB" }} onClick={()=>dispatch(goBackHDMClientCompo())}>BACK </Button>
        </div>
        <div className="footer_content">
          <Button
            variant="contained"
            disabled={hdmClient?.proposalStatus === "COMPLETED" ? true : false}
            onClick={() => {
              updateStatusProposalHdm(hdmClient,"COMPLETED").then((res)=>{
                dispatch(getHDMClientDetailsByFormId());
                dispatch(updateHDMCompoReduxClient());
              });
            }}
          >
            PROCEED
          </Button>
        </div>
      </div>
      <div className="hero123"></div>
      <div className="scroll_items">
        <div className="communication_title">
          <div className="commmunication_head">
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={MSG}
                alt=""
              />{" "}
              Communication & Reporting
            </h3>
          </div>
          <div className="communication_content">
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col-sm-6"
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 1.5398px 39px rgba(20, 20, 43, 0.04)",
                  borderRadius: 24,
                  width: "100%",
                  height: 285,
                  margin: "10px 40px",
                }}
              >
                {" "}
                <div
                  style={{
                    background: "#F5F9FF",
                    borderRadius: "24px 24px 0px 0px",
                    height: 62.39,
                    color: "#170F49",
                    padding: 20,
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  {" "}
                  From Us
                </div>
                <div>
                  <ul className="ul-custom">
                    <li style={{ marginBottom: 35 }}> Weekly Reporting</li>
                    <li style={{ marginBottom: 35 }}>
                      2 monthly meetings (1 strategy call + 1 touch base call)
                    </li>
                    <li>Availability as needed through AM calendar</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-sm-6"
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 1.5398px 39px rgba(20, 20, 43, 0.04)",
                  borderRadius: 24,
                  height: 285,
                  width: "100%",
                  margin: "10px 40px",
                }}
              >
                <div
                  style={{
                    background: "#F5F9FF",
                    borderRadius: "24px 24px 0px 0px",
                    height: 62.39,
                    color: "#170F49",
                    padding: 20,
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  {" "}
                  From You
                </div>
                <div>
                  <ul>
                    <li style={{ marginBottom: 30 }}>
                      {" "}
                      We are a team here… Let us know of any changes to website,
                      product availability etc.
                    </li>
                    <li style={{ marginBottom: 30 }}>
                      Timely responses to emails
                    </li>
                    <li style={{ marginBottom: 30 }}>
                      1 week notice for new sales/content
                    </li>
                    <li>What is the best way to contact you?</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="what_we_need_title">
          <div className="what_we_need_head">
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={PERSON}
                alt=""
              />{" "}
              What We Need From You
            </h3>
          </div>
          <div className="what_we_need_content">
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={PHONE} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Consistent Creatives
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Ut suscipit mi cursus hendrerit ac nuncol leo pellentesque
                  mauris mattis.
                </div>
              </div>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={BULB} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Notifying Changes
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Turpis consectetur feugiat nullam egestas rhoncus amet quis
                  hac enim neque.
                </div>
              </div>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={SCREEN} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Timely Communication
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Ipsum at quis dui ridiculus blandit dolor arcu penatibus. Diam
                  nunc sit et nunc.
                </div>
              </div>
            </div>
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={PEN} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Fast Action
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Porta sit viverra ultricies at blandit dui. Nibh at sed et
                  pellentesque eget.
                </div>
              </div>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={BOX} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Sounds Fair?
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Ac in magna integer consectetur faucibus vitae volutpat lectus
                  sit aliquet enim.
                </div>
              </div>
              <div className="col" style={{ width: "100%" }}></div>
            </div>
          </div>
        </div>

        <div className="facebook_title">
          <div className="facebook_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={FACEBOOK}
                alt=""
              />{" "}
              Facebook Tracking Post iOS Changes
            </h3>
          </div>
          <div className="facebook_content">
            <div className="row" style={{ display: "flex" }}>
              <div className="col" style={{ width: "100%" }}>
                <div
                  style={{
                    background: "#FFFFFF",
                    border: "0.7699px solid #EFF0F7",
                    height: 146,
                    boxShadow: "0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08)",
                    borderRadius: 10.5055,
                    padding: 22,
                    margin: 20,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#000000",
                      marginBottom: 7,
                    }}
                  >
                    Delayed Reporting
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 11,
                      color: "#6E6C83",
                    }}
                  >
                    Realtime reporting is no longer supported. It can take up to
                    3 days for a purchase to be reported in Ads manager.
                  </span>
                </div>
              </div>
              <div className="col" style={{ width: "100%" }}>
                <div
                  style={{
                    background: "#FFFFFF",
                    border: "0.7699px solid #EFF0F7",
                    height: 146,
                    boxShadow: "0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08)",
                    borderRadius: 10.5055,
                    padding: 22,
                    margin: 20,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#000000",
                      marginBottom: 7,
                    }}
                  >
                    Estimated Results
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 11,
                      color: "#6E6C83",
                    }}
                  >
                    Facebook is using statistical modelling to account for
                    conversions from iOS 14 users. The numbers reported in
                    Facebook are not real.
                  </span>
                </div>
              </div>
              <div className="col" style={{ width: "100%" }}>
                <div
                  style={{
                    background: "#FFFFFF",
                    border: "0.7699px solid #EFF0F7",
                    height: 146,
                    boxShadow: "0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08)",
                    borderRadius: 10.5055,
                    padding: 22,
                    margin: 20,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#000000",
                      marginBottom: 7,
                    }}
                  >
                    Loss of Attribution
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 11,
                      color: "#6E6C83",
                    }}
                  >
                    Realtime reporting is no longer supported. It can take up to
                    3 days for a purchase to be reported in Ads manager.
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="row" style={{ display: "flex" }}>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    01
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    Blended ROAS
                  </span>
                </div>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    02
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    UTMs
                  </span>
                </div>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    03
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    Correlations
                  </span>
                </div>
              </div>
              <div className="row" style={{ display: "flex" }}>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    04
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    On Platform Statistics
                  </span>
                </div>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    05
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    Overall Revenues
                  </span>
                </div>
                <div
                  className="col-sm-4"
                  style={{ width: "100%", margin: "25px 25px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bonus_title">
          <div className="bonus_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={GIFT}
                alt=""
              />{" "}
              Bonus
            </h3>
          </div>
          <div className="bonus_content">
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              >
                <div
                  style={{
                    textAlign: "center",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px 10px 0px 0px",
                    height: 60,
                    paddingTop: 20,
                  }}
                >
                  1 referral = $300/month
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#F5F9FF",
                    border: "1px solid #0869FB",
                    borderRadius: "0px 0px 10px 10px",
                    height: 60,
                    color: "#0869FB",
                    paddingTop: 20,
                  }}
                >
                  $3,600/year
                </div>
              </div>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              >
                <div
                  style={{
                    textAlign: "center",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px 10px 0px 0px",
                    height: 60,
                    paddingTop: 20,
                  }}
                >
                  2 referrals = $600/month
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#F5F9FF",
                    border: "1px solid #0869FB",
                    borderRadius: "0px 0px 10px 10px",
                    height: 60,
                    color: "#0869FB",
                    paddingTop: 20,
                  }}
                >
                  $7,200/year
                </div>
              </div>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              >
                <div
                  style={{
                    textAlign: "center",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px 10px 0px 0px",
                    height: 60,
                    paddingTop: 20,
                  }}
                >
                  5 referrals = $1500/month
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#F5F9FF",
                    border: "1px solid #0869FB",
                    borderRadius: "0px 0px 10px 10px",
                    height: 60,
                    color: "#0869FB",
                    paddingTop: 20,
                  }}
                >
                  $18,000/year
                </div>
              </div>
            </div>
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              >
                <div
                  style={{
                    textAlign: "center",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px 10px 0px 0px",
                    height: 60,
                    paddingTop: 20,
                  }}
                >
                  20 referrals = $6000/month
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#F5F9FF",
                    border: "1px solid #0869FB",
                    borderRadius: "0px 0px 10px 10px",
                    height: 60,
                    color: "#0869FB",
                    paddingTop: 20,
                  }}
                >
                  $72,000/year
                </div>
              </div>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              ></div>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              ></div>
            </div>
          </div>
        </div>
        <div classname="nextSteps_title">
          <div className="nextSteps_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={NEXT}
                alt=""
              />{" "}
              Next Steps
            </h3>
          </div>
          <div className="nextSteps_content">
            <Grid container xs={12}>
              <Grid item xs={3}>
                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      01
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Internal Strategy Call
                  </span>
                </div>

                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      02
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Historical Analysis
                  </span>
                </div>

                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      03
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Market Research
                  </span>
                </div>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid item xs={3}>
                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      04
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Campaign set-up
                  </span>
                </div>

                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      05
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Strategy Call & Ad approval
                  </span>
                </div>
                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      06
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Go LIVE
                    <div style={{ color: "#6D6D6D", fontSize: 12 }}>
                      (target is 10 business days from today)
                    </div>
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="strategy12" style={{ height: 300, padding: 100 }}>
          <div
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 28,
              textAlign: "center",
              color: "#686868",
            }}
          >
            Let's Book Our
          </div>
          <div
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 60,
              textAlign: "center",
              color: "#000000",
            }}
          >
            Strategy Call
          </div>
        </div>
        <div classname="footer-bar">
          <img src={THANKS} style={{ width: "100%", height: 780 }} />
        </div>
      </div>
    </div>
  );
};
export default CommunicationHDM;
