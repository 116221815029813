import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Font,
} from '@react-pdf/renderer';
import './generatePdf.css';
import linear from '../../assets/images/linear.png';
import ladental from '../../assets/images/ladental.jpeg';
import { PDFViewer } from '@react-pdf/renderer';
import usimage from '../../assets/images/usimage.jpeg';
import ciscoimage from '../../assets/images/ciscoimage.jpeg';
import wirelessimage from '../../assets/images/wirelessimage.jpeg';
import award1 from '../../assets/images/award1.png';
import award2 from '../../assets/images/award2.png';
import award3 from '../../assets/images/award3.png';
import award4 from '../../assets/images/award4.png';
import award5 from '../../assets/images/award5.png';
import award6 from '../../assets/images/award6.png';
import tickIcon from '../../assets/images/tickIcon.png';
import croTimelineImage from '../../assets/images/cro-timeline.png';
import googleScope from '../../assets/images/google-scope.png';
import facebookScope from '../../assets/images/facebook-scope.png';
import croScope from '../../assets/images/cro-scope.png';
import adDesign from '../../assets/images/ad-design.png';
import customiseReportImg from '../../assets/images/customise-report.png';
import cosmeticImg from '../../assets/images/cosmetic1.jpeg';
import dentalImg from '../../assets/images/dental.jpeg';
import medImg from '../../assets/images/med.jpeg';
import chiropactorImg from '../../assets/images/chiropactor.jpeg';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
  },
  section1: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#1E1E48',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  section2: {
    width: '90vw',
    height: '100vh',
    backgroundColor: '#ffff',
    margin: '0px auto',
    position: 'relative',
  },
  marginTop: {
    marginTop: '5px',
  },
  fontSize: {
    fontSize: '12px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxBorder: {
    border: '1px solid #DDDDDD',
    width: '160px',
    height: '100px',
  },
  boxBorder1: {
    border: '1px solid #DDDDDD',
    width: '160px',
    height: '80px',
  },
});

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 'bold',
    },
  ],
});

const styles2 = StyleSheet.create({
  boldFont: {
    fontWeight: 'bold',
  },
});

const GeneratePdf = ({
  proposal,
  signature,
  companyLogo,
  insights,
  mappedIndustry,
  listofAdsSelected,
  publishedDate,
}) => {
  console.log(
    '%%%%%%%%%%%%%%%%%%%',
    proposal,
    signature,
    companyLogo,
    insights,
    mappedIndustry,
    listofAdsSelected
  );
  return (
    <Document>
      {/*section1*/}
      <Page size='A4'>
        <View style={styles.section1}>
          <Image
            src={linear}
            style={{
              maxWidth: '100px',
              maxHeight: '150px',
              verticalAlign: 'middle',
              marginTop: '30vh',
            }}
          />
          <Text
            style={{ fontWeight: 700, fontSize: '20px', marginTop: '20px' }}
          >
            PPC & CRO Growth Proposal
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '30vh',
              color: '#656565',
              fontSize: '10px',
              flexGrow: '1',
            }}
          >
            <Text style={styles.marginTop}>
              {proposal?.companyInfo.name} | {publishedDate}
            </Text>
            <Text style={styles.marginTop}>
              Proposal prepared for:{' '}
              {proposal?.onboardingInfo?.personalInfo?.primaryContactName} |{' '}
              {proposal?.brandId}
            </Text>
            <Text style={styles.marginTop}>
              Proposal prepared by: {proposal?.companyInfo.proposerName}
            </Text>
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
          </View>
        </View>
      </Page>
      {/*section2*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>
          <View style={{ marginTop: '30px' }}>
            <Text style={{ fontSize: '16px' }}>Company Overview</Text>
          </View>
          <View style={styles.center}>
            <Image
              src={companyLogo ? companyLogo : ''}
              style={{
                maxWidth: '120px',
                maxHeight: '170px',
                verticalAlign: 'middle',
                marginTop: '40px',
              }}
            />
          </View>
          <View
            style={{ marginTop: '30px', ...styles.center, color: '#656565' }}
          >
            <Text style={{ fontSize: '14px' }}>
              Hey {proposal.companyInfo.fullName}! We're about to grow{' '}
              {proposal.companyInfo.name} by driving high-quality traffic
            </Text>
            <Text style={{ fontSize: '14px', marginTop: '5px' }}>
              to landing pages that are optimized to convert.
            </Text>
          </View>
          <View
            style={{ marginTop: '30px', ...styles.center, color: '#656565' }}
          >
            <Text style={{ fontSize: '11px' }}>
              First, we outline some of your challenges and major improvement
              opportunities. Then we present a custom
            </Text>
            <Text style={{ fontSize: '11px', marginTop: '5px' }}>
              strategy to improve performance and profitability. Check it out!
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              ...styles.center,
              marginTop: '30px',
            }}
          >
            <View style={{ ...styles.boxBorder1, padding: '10px' }}>
              <Text style={{ fontSize: '16px', marginTop: '10px' }}>TIME</Text>
              <Text style={{ fontSize: '8px', margin: '0px' }}>
                5 years in business
              </Text>
            </View>
            <View style={{ ...styles.boxBorder1, padding: '10px' }}>
              <Text style={{ fontSize: '16px', marginTop: '10px' }}>
                CLIENTS
              </Text>
              <Text style={{ fontSize: '8px', margin: '0px' }}>
                500+ projects
              </Text>
            </View>
            <View style={{ ...styles.boxBorder1, padding: '10px' }}>
              <Text style={{ fontSize: '16px', marginTop: '10px' }}>
                PEOPLE
              </Text>
              <Text style={{ fontSize: '8px', margin: '0px' }}>
                Team of 300+ experts
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              ...styles.center,
            }}
          >
            <View style={{ ...styles.boxBorder, padding: '10px' }}>
              <Image src={usimage} style={{ height: 'auto' }} />
            </View>
            <View style={{ ...styles.boxBorder, padding: '10px' }}>
              <Image src={ciscoimage} style={{ height: 'auto' }} />
            </View>
            <View style={{ ...styles.boxBorder, padding: '10px' }}>
              <Image src={wirelessimage} style={{ height: 'auto' }} />
            </View>
          </View>
          <View style={{ marginTop: '30px' }}>
            <Text style={{ fontSize: '16px' }}>What Sets Linear Apart</Text>
          </View>
          <View>
            <Text
              style={{
                marginTop: '20px',
                color: '#656565',
                fontSize: '14px',
                fontWeight: 'bolder',
              }}
            >
              Low Client / Account Manager Ratio
            </Text>
            <Text
              style={{
                marginTop: '20px',
                color: '#656565',
                fontSize: '9px',
                fontWeight: 'bolder',
                lineHeight: '2px',
              }}
            >
              Your account manager never works with more than six clients at a
              time. This means your account gets plenty of attention–30+ hours
              of dedicated focus every month, with additional time from our
              design team.
            </Text>
          </View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>1 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section3*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>

          <View>
            <Text
              style={{
                marginTop: '20px',
                color: '#656565',
                fontSize: '14px',
                fontWeight: 'bolder',
              }}
            >
              Highly Experienced Team
            </Text>
            <Text
              style={{
                marginTop: '10px',
                color: '#656565',
                fontSize: '9px',
                fontWeight: 'bolder',
                lineHeight: '2px',
              }}
            >
              You’ll never work with newbies. All of our designers and account
              managers have an average of 5.5 years of experience, and we
              require a minimum of 3. Our in-house course and weekly team-wide
              training ensure we approach your accounts with up-to-date industry
              knowledge.
            </Text>
          </View>
          <View>
            <Text
              style={{
                marginTop: '20px',
                color: '#656565',
                fontSize: '14px',
                fontWeight: 'bolder',
              }}
            >
              Focused Service Offering
            </Text>
            <Text
              style={{
                marginTop: '10px',
                color: '#656565',
                fontSize: '9px',
                fontWeight: 'bolder',
                lineHeight: '2px',
              }}
            >
              Our mission is to be the best performing PPC/CRO agency in the
              world. That can only happen with extreme focus. That’s why we only
              offer pay-per-click management and landing page optimization. By
              placing all of our focus on these two services we’re able to offer
              an unmatched level of execution.
            </Text>
          </View>
          <View>
            <Text
              style={{
                marginTop: '20px',
                color: '#656565',
                fontSize: '14px',
                fontWeight: 'bolder',
              }}
            >
              Original Strategies & Tactics
            </Text>
            <Text
              style={{
                marginTop: '10px',
                color: '#656565',
                fontSize: '9px',
                fontWeight: 'bolder',
                lineHeight: '2px',
              }}
            >
              Boilerplate tactics don’t always work. That’s why we’ve spent
              countless hours and millions in ad spend to uncover and define the
              best PPC and CRO strategies for our clients
            </Text>
          </View>
          <View>
            <Text
              style={{
                marginTop: '20px',
                color: '#656565',
                fontSize: '14px',
                fontWeight: 'bolder',
              }}
            >
              Clear Communication Standard
            </Text>
            <Text
              style={{
                marginTop: '10px',
                color: '#656565',
                fontSize: '9px',
                fontWeight: 'bolder',
                lineHeight: '2px',
              }}
            >
              You’ll speak directly to the experts who manage your ad accounts
              and design your landing pages. No middleman. That means you get
              seamless, first-hand updates at every stage of your campaign.
            </Text>
          </View>

          <View style={{ marginTop: '20px' }}>
            <Text style={{ fontSize: '16px' }}>Awards & Recognition</Text>
          </View>
          <View
            style={{
              ...styles.center,
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: '30px',
            }}
          >
            <View>
              <Image src={award1} style={{ width: '100px', height: '100px' }} />
            </View>
            <View>
              <Image src={award2} style={{ width: '100px', height: '100px' }} />
            </View>
            <View>
              <Image
                src={award3}
                style={{ width: '100px', maxHeight: '100px' }}
              />
            </View>
          </View>
          <View
            style={{
              ...styles.center,
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: '30px',
            }}
          >
            <View>
              <Image src={award4} style={{ width: '100px', height: '100px' }} />
            </View>
            <View>
              <Image src={award5} style={{ width: '100px', height: '100px' }} />
            </View>
            <View>
              <Image
                src={award6}
                style={{ width: '100px', maxHeight: '100px' }}
              />
            </View>
          </View>
          <View style={{ marginTop: '20px' }}>
            <Text style={{ fontSize: '14px' }}>Our Goal</Text>
            <Text
              style={{ fontSize: '10px', lineHeight: '2px', marginTop: '10px' }}
            >
              Our first priority is to define how we’ll measure success
              together. That starts by agreeing on one clear and realistic goal
              tied to a specific metric. By focusing all our efforts on one
              primary goal, we can improve what matters most to you - right now.
            </Text>
          </View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>2 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section4*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>
          <View
            style={{
              marginTop: '30px',
              textAlign: 'center',
              color: '#656565',
              fontSize: '13px',
            }}
          >
            <Text style={{ marginBottom: '0px' }}>24 Conversions Monthly</Text>
            <Text style={{ marginTop: '10px', marginBottom: '10px' }}>
              at {proposal.companyInfo.targetCpa} Cost/Conv.
            </Text>
          </View>
          <View style={{ marginTop: '10px', ...styles.center }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
                width: '70%',
              }}
            >
              <Text style={{ width: '50%' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Lower cost per acquisition
              </Text>
              <Text style={{ width: '50%' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Increase conversions volume
              </Text>
            </View>
          </View>
          <View style={{ marginTop: '20px', ...styles.center }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
                width: '70%',
              }}
            >
              <Text style={{ width: '50%' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Increase conversion rates
              </Text>
              <Text style={{ width: '50%' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Improve conversion quality
              </Text>
            </View>
          </View>
          <View style={{ marginTop: '20px', ...styles.center }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '10px',
                width: '70%',
              }}
            >
              <Text style={{ width: '50%' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Optimize return on ad spend
              </Text>
              <Text style={{ width: '50%' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Reduce wasted ad spend
              </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '10px',
              border: '1px solid #DDDDDD',
              width: '70%',
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '10px',
            }}
          >
            <View style={{ textAlign: 'center', fontStyle: 'italic' }}>
              <Text style={{ lineHeight: '2px' }}>
                The goals above represent a few key metrics we can improve. We
                establish
              </Text>
              <Text>
                your first goal and target date after your onboarding call.
              </Text>
            </View>
          </View>

          {proposal?.companyInfo.googleAdsManaged && (
            <View>
              <View style={{ marginTop: '30px', fontSize: '13px' }}>
                <Text>Opportunities Overview</Text>
              </View>
              <View
                style={{
                  marginTop: '20px',
                  color: '#656565',
                  fontSize: '13px',
                }}
              >
                <Text>Google Ads Performance Issues</Text>
              </View>
              <View
                style={{
                  marginTop: '10px',
                  color: '#656565',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '2px' }}>
                  The existing campaigns are not properly structured. Campaigns
                  are too broad and ad groups contain too many keywords. Ads are
                  not fully built out, extensions are not granular, and no bid
                  adjustments have been applied. We will correct all of these
                  issues and restructure the account to use specific intent
                  campaigns.
                </Text>
              </View>
              {insights?.map((summary, index) => (
                <View
                  style={{
                    marginTop: '10px',
                    color: '#656565',
                    fontSize: '10px',
                  }}
                >
                  <Text style={{ marginBottom: '5px' }}>
                    <Text
                      style={{ color: '#F00', fontSize: '14px', margin: 0 }}
                    >
                      ×
                    </Text>{' '}
                    {summary}
                  </Text>
                </View>
              ))}
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>3 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section 5*/}
      {proposal?.companyInfo.googleAdsManaged && (
        <Page size='A4'>
          <View style={styles.section2}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>
            <View style={{ marginTop: '30px', fontSize: '13px' }}>
              <Text>Google Ads Starting Plan</Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '13px' }}
            >
              <Text>1. Google Ads</Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                We'll structure your account with the perfect amount of
                granularity using a technique called Specific Intent Campaigns.
                Then we'll link your Google Analytics account and import
                audiences for{' '}
                <Text style={{ color: '#6AC0FC' }}>
                  RLSA (retargeting lists for search ads)
                </Text>
                . We'll also add the most relevant interests in observation mode
                so that we can make bid adjustments on the most/least valuable
                segments. Finally, we'll send traffic from these campaigns to
                landing pages that are optimized to convert.
              </Text>
            </View>
            <View
              style={{
                marginTop: '20px',
                color: '#656565',
                fontSize: '11px',
                fontWeight: 'bold',
              }}
            >
              <Text>Campaigns we'll create:</Text>
            </View>
            <View
              style={{ marginTop: '15px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Branded campaign - bidding on your branded terms to prevent
                competitors from taking your spot and capture searches generated
                from brand awareness campaigns
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Competitor campaign - bidding on your competitor's brand terms
                to create awareness and steal market share.
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Services campaigns (US) - bidding on high intent keywords like
                invisalign, dental implants, veneers, etc.
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Broad experimental campaigns - Testing RLSA + Broad Match, and
                DSA campaigns to find new search terms.
              </Text>
            </View>

            <View style={{ marginTop: '20px', fontSize: '13px' }}>
              <Text>Google Ads Timeline</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '20px',
              }}
            >
              <Image
                src={croTimelineImage}
                style={{ width: '250px', marginLeft: '30px' }}
              />
              <View
                style={{
                  marginTop: '30px',
                  marginLeft: '20px',
                  lineHeight: '1.5px',
                }}
              >
                <View style={{ fontSize: '14px' }}>
                  <Text>Understand What Your Ads </Text>
                  <Text>Team Is Working On</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>Wondering what happens next? This</Text>
                  <Text>timeline highlights the milestones</Text>
                  <Text>you can expect us to complete in the</Text>
                  <Text>first three months together.</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>After kick-off, we share a detailed</Text>
                  <Text>Asana project so you can follow </Text>
                  <Text>our progress in real-time.</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>4 / 20</Text>
            </View>
          </View>
        </Page>
      )}
      {/*section 6*/}
      {proposal?.companyInfo.googleAdsManaged && (
        <Page size='A4'>
          <View style={{ ...styles.section2 }}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>

            {/*table*/}

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
                marginTop: '50px',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#ACDCFC',
                  padding: '12px',
                }}
              >
                <Text>Month1</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#7AC7FB',
                  padding: '12px',
                }}
              >
                <Text>Month2</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#58BAF9',
                  padding: '12px',
                }}
              >
                <Text>Month3</Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Keyword + Competitive Research Strategy
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Search Ad Optimizations
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Search Ad Optimizations + Bid adjustments
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>Set up Goals</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>Keyword Expansion</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Keyword Expansion + DSA Campaigns
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Fix broken conversion tracking
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Negative Keyword Additions
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Negative Keywords + Placements
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Campaign Structure + Setup
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Location-based bid adjustments
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Time of Day, Day of Week optimization
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Audience Observations
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Optimize bidding strategies
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Campaign Experiments
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>New ad copy</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Audience-based adjustments
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Update Negative Keyword Lists
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Updated Ad Extensions
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Improve low impression share
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '11px',
                  fontSize: '11px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
            </View>
            {/*end*/}

            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>5 / 20</Text>
            </View>
          </View>
        </Page>
      )}
      {/*section 7 */}
      {proposal?.companyInfo.googleAdsManaged && (
        <Page size='A4'>
          <View style={styles.section2}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>
            <View style={{ marginTop: '30px', fontSize: '13px' }}>
              <Text>Google Ads Scope Of Work</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '40px',
              }}
            >
              <Image
                src={googleScope}
                style={{ width: '250px', marginLeft: '30px' }}
              />
              <View
                style={{
                  marginTop: '50px',
                  marginLeft: '20px',
                  lineHeight: '1.5px',
                }}
              >
                <View style={{ fontSize: '14px' }}>
                  <Text>Google Ads + Microsoft</Text>
                  <Text>Ads</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>We’ll fine-tune and improve your PPC</Text>
                  <Text>performance by performing the tasks</Text>
                  <Text>below as often as every week or</Text>
                  <Text>when needed</Text>
                </View>
              </View>
            </View>
            <View style={{ width: '100%' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  marginTop: '50px',
                  fontSize: '12px',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    backgroundColor: '#E6E6E6',
                    padding: '5px',
                    textAlign: 'center',
                  }}
                >
                  <Text>Description</Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    backgroundColor: '#E6E6E6',
                    padding: '5px',
                    textAlign: 'center',
                  }}
                >
                  <Text>Details</Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Google Ads Account Build/Setup/Optimization
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.googleAdsInfo.accountOperations
                      .length !== 0
                      ? proposal?.sowPricingInfo?.googleAdsInfo.accountOperations.toString()
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Display Ad Campaigns
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.googleAdsInfo.displayAdCampaigns
                      .length !== 0
                      ? proposal?.sowPricingInfo?.googleAdsInfo
                          .displayAdCampaigns
                      : 'None'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Shopping Ad Campaigns
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.googleAdsInfo.shoppingAdCampaigns
                      .length !== 0
                      ? proposal?.sowPricingInfo?.googleAdsInfo
                          .shoppingAdCampaigns
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Local Service Ad Campaigns
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.googleAdsInfo
                      .localServiceAdCampaigns.length !== 0
                      ? proposal?.sowPricingInfo?.googleAdsInfo
                          .localServiceAdCampaigns
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Video Ad Campaigns{' '}
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.googleAdsInfo.videoAdCampaigns
                      .length !== 0
                      ? proposal?.sowPricingInfo?.googleAdsInfo.videoAdCampaigns
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Call Tracking (CallRail){' '}
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '11px',
                    fontSize: '11px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.googleAdsInfo
                      .callTrackingAdCampaigns.length !== 0
                      ? proposal?.sowPricingInfo?.googleAdsInfo
                          .callTrackingAdCampaigns
                      : 'No'}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>6 / 20</Text>
            </View>
          </View>
        </Page>
      )}
      {/*end of google ads*/}

      {/* section 8 facebook-ads*/}
      {proposal?.companyInfo.fbAdsManaged && (
        <Page size='A4'>
          <View style={styles.section2}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>
            <View style={{ marginTop: '50px', fontSize: '13px' }}>
              <Text>Facebook Ads Starting Plan</Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '13px' }}
            >
              <Text>1. Facebook Ads</Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                We'll create Facebook Ad campaigns, new audiences, write copy,
                and design creative.
              </Text>
            </View>
            <View
              style={{
                marginTop: '20px',
                color: '#656565',
                fontSize: '11px',
                fontWeight: 'bold',
              }}
            >
              <Text>Additional Strategies & Tactics we'll use:</Text>
            </View>
            <View
              style={{ marginTop: '15px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px', margin: '0' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Write new ad copy and design new creative
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px', margin: '0' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Test different ad formats (carousel, single image, video, etc.)
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '1px', margin: '0' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Test Lifetime budgets v. Daily budgets
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '1px', margin: '0' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Fix limited custom audiences and create new saved audiences to
                test against LALs
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '1px', margin: '0' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Create a campaign to drive warmer traffic to collect email
                addresses for retargeting campaigns
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '1px', margin: '0' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Implement successful retargeting campaigns
              </Text>
            </View>

            <View style={{ marginTop: '30px', fontSize: '13px' }}>
              <Text>Facebook Ads Timeline</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '20px',
              }}
            >
              <Image
                src={croTimelineImage}
                style={{ width: '240px', marginLeft: '30px' }}
              />
              <View
                style={{
                  marginTop: '50px',
                  marginLeft: '30px',
                  lineHeight: '1.5px',
                }}
              >
                <View style={{ fontSize: '14px' }}>
                  <Text>Understand What Your Ads</Text>
                  <Text>Team Is Working On</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>Wondering what happens next? This </Text>
                  <Text>timeline highlights the milestones</Text>
                  <Text>you can expect us to complete in</Text>
                  <Text>the first three months together.</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>After kick-off, we share a detailed</Text>
                  <Text>Asana project so you can follow</Text>
                  <Text>our progress in real-time.</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>7 / 20</Text>
            </View>
          </View>
        </Page>
      )}
      {/*section 9 */}
      {proposal?.companyInfo.fbAdsManaged && (
        <Page size='A4'>
          <View style={{ ...styles.section2 }}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>

            {/*table*/}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
                marginTop: '50px',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#ACDCFC',
                  padding: '10px',
                }}
              >
                <Text>Month1</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#7AC7FB',
                  padding: '10px',
                }}
              >
                <Text>Month2</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#58BAF9',
                  padding: '10px',
                }}
              >
                <Text>Month3</Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>Campaign Strategy</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Multivariate Ad Optimizations
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Ad Testing + Optimizations
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  New Ad Copy + Designs
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Audience Testing + Optimizations
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Audience Removals and Updates
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Restructured Campaigns
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Set up automated events
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  New Ad Copy + Creative
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>Audience Creation</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Custom Conversion Creation
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Lifetime Budget v. Daily Budget
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}></Text>
              </View>
            </View>

            <View style={{ marginTop: '40px', fontSize: '13px' }}>
              <Text>Facebook Ads Scope Of Work</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '30px',
              }}
            >
              <View
                style={{
                  marginTop: '50px',
                  marginLeft: '30px',
                  marginRight: '40px',
                  lineHeight: '1.5px',
                }}
              >
                <View style={{ fontSize: '14px' }}>
                  <Text>Winning Formula</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text style={{ textAlign: 'left' }}>
                    We’ll create granular campaigns
                  </Text>
                  <Text style={{ textAlign: 'left' }}>
                    with different targeting
                  </Text>
                  <Text style={{ textAlign: 'left' }}>
                    criteria to isolate all
                  </Text>
                  <Text style={{ textAlign: 'left' }}>
                    variables and see what drives
                  </Text>
                  <Text style={{ textAlign: 'left' }}>
                    the growth we need. Social
                  </Text>
                  <Text style={{ textAlign: 'left' }}>
                    {' '}
                    networks are great for{' '}
                  </Text>
                  <Text style={{ textAlign: 'left' }}>
                    directly targeting your ideal
                  </Text>
                  <Text style={{ textAlign: 'left' }}>customers.</Text>
                </View>
              </View>
              <Image
                src={facebookScope}
                style={{ width: '250px', marginLeft: '40px' }}
              />
            </View>
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>8 / 20</Text>
            </View>
          </View>
        </Page>
      )}

      {/*section 10 */}
      {(proposal?.companyInfo.fbAdsManaged ||
        proposal?.companyInfo.implementCro) && (
        <Page size='A4'>
          <View style={styles.section2}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>
            {proposal?.companyInfo.fbAdsManaged && (
              <View style={{ width: '100%' }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    margin: '0px auto',
                    marginTop: '50px',
                    fontSize: '12px',
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      backgroundColor: '#E6E6E6',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Description</Text>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      backgroundColor: '#E6E6E6',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Details</Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    margin: '0px auto',
                    border: '1px solid #DDDDDD',
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      Facebook Ads New Account Build/Setup/Optimization
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      {proposal?.sowPricingInfo?.facebookAdsInfo
                        .accountOperations.length !== 0
                        ? proposal?.sowPricingInfo?.facebookAdsInfo.accountOperations.toString()
                        : 'No'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    margin: '0px auto',
                    border: '1px solid #DDDDDD',
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      FB/IG Ad Campaigns
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      {proposal?.sowPricingInfo?.facebookAdsInfo.fbIgAdCampaigns
                        .length !== 0
                        ? proposal?.sowPricingInfo?.facebookAdsInfo
                            .fbIgAdCampaigns
                        : 'No'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    margin: '0px auto',
                    border: '1px solid #DDDDDD',
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      New Audience Creation/Segmentation
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      {proposal?.sowPricingInfo?.facebookAdsInfo
                        .newAudienceCreation.length !== 0
                        ? proposal?.sowPricingInfo?.facebookAdsInfo
                            .newAudienceCreation
                        : 'No'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    margin: '0px auto',
                    border: '1px solid #DDDDDD',
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>Custom Events</Text>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      {proposal?.sowPricingInfo?.facebookAdsInfo.customEvents
                        .length !== 0
                        ? proposal?.sowPricingInfo?.facebookAdsInfo.customEvents
                        : 'No'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    margin: '0px auto',
                    border: '1px solid #DDDDDD',
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>Automated Rules</Text>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      {proposal?.sowPricingInfo?.facebookAdsInfo.automatedRules
                        .length !== 0
                        ? proposal?.sowPricingInfo?.facebookAdsInfo
                            .automatedRules
                        : 'No'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%',
                    margin: '0px auto',
                    border: '1px solid #DDDDDD',
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      UTM Tracking Templates
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      padding: '10px',
                      fontSize: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text style={{ lineHeight: '1.5px' }}>
                      {proposal?.sowPricingInfo?.facebookAdsInfo
                        .utmTrackingTemplates.length !== 0
                        ? proposal?.sowPricingInfo?.facebookAdsInfo
                            .utmTrackingTemplates
                        : 'No'}
                    </Text>
                  </View>
                </View>
              </View>
            )}
            {proposal?.companyInfo.implementCro && (
              <>
                <View style={{ marginTop: '40px', fontSize: '13px' }}>
                  <Text>CRO Starting Plan</Text>
                </View>
                <View
                  style={{
                    marginTop: '30px',
                    color: '#656565',
                    fontSize: '13px',
                  }}
                >
                  <Text>1. Landing Page Design + Optimization</Text>
                </View>
                <View
                  style={{
                    marginTop: '30px',
                    color: '#656565',
                    fontSize: '10px',
                  }}
                >
                  <Text style={{ lineHeight: '2px' }}>
                    We'll create landing pages to match the design and messaging
                    of your ads then we'll use A/B testing, multivariate
                    testing, and smart traffic to improve conversions
                    exponentially.
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: '30px',
                    color: '#656565',
                    fontSize: '11px',
                    fontWeight: 'bold',
                  }}
                >
                  <Text>Landing Page strategies & tactics we'll use:</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    color: '#656565',
                    fontSize: '10px',
                  }}
                >
                  <Text style={{ lineHeight: '4px' }}>
                    <Image
                      src={tickIcon}
                      style={{ width: '10px', height: '8px' }}
                    />{' '}
                    High tempo testing to always focus on having a variant
                    running alongside all landing page tests.
                  </Text>
                  <Text style={{ lineHeight: '4px' }}>
                    <Image
                      src={tickIcon}
                      style={{ width: '10px', height: '8px' }}
                    />{' '}
                    Test two-step pages and drive direct conversions
                  </Text>
                  <Text style={{ lineHeight: '4px' }}>
                    <Image
                      src={tickIcon}
                      style={{ width: '10px', height: '8px' }}
                    />{' '}
                    Review heatmaps and user recordings to constantly improve
                    conversion rates
                  </Text>
                  <Text style={{ lineHeight: '4px' }}>
                    <Image
                      src={tickIcon}
                      style={{ width: '10px', height: '8px' }}
                    />{' '}
                    Dynamic text replacement for personalization and geographic
                    granularity testing
                  </Text>
                </View>
              </>
            )}
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>9 / 20</Text>
            </View>
          </View>
        </Page>
      )}
      {/*end of facebook*/}

      {/*cro*/}
      {/*section 11 */}
      {proposal.companyInfo.implementCro && (
        <Page size='A4'>
          <View style={styles.section2}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>
            <View
              style={{
                marginTop: '30px',
                color: '#656565',
                fontSize: '13px',
                marginTop: '40px',
              }}
            >
              <Text>2. Smart Pop-Ups</Text>
            </View>
            <View
              style={{ marginTop: '30px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                Pop-ups can improve site-wide conversions even from non-paid
                traffic sources. We'll design, write, and constantly test time
                and activity-based smart pop-ups to lower bounce rates and boost
                conversions.
              </Text>
            </View>
            <View
              style={{
                marginTop: '30px',
                color: '#656565',
                fontSize: '11px',
                fontWeight: 'bold',
              }}
            >
              <Text>How we'll use pop-ups:</Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                Scroll depth - when a visitor scrolls a certain percentage on a
                page
              </Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                Time delay - when a visitor visits a page for a specific amount
                of time
              </Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                Retargeting - when a visitor has been to the page multiple times
              </Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                Exit Intent - before the visitor exits the page
              </Text>
            </View>
            <View
              style={{
                marginTop: '30px',
                color: '#656565',
                fontSize: '13px',
                marginTop: '40px',
              }}
            >
              <Text>3. Google Analytics + Micro Conversions</Text>
            </View>
            <View
              style={{ marginTop: '30px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                We'll optimize your Google Analytics account by segmenting
                traffic sources, fixing conversion tracking, and implementing
                micro-conversion tracking.
              </Text>
            </View>
            <View
              style={{
                marginTop: '30px',
                color: '#656565',
                fontSize: '11px',
                fontWeight: 'bold',
              }}
            >
              <Text>Micro-conversions we'll setup:</Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                {`Session Duration > 2 Minutes`}
              </Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                {`Session Duration > 10 Seconds`}
              </Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                Viewed 3+ Pages
              </Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                {`Landing Page Visitors`}
              </Text>
            </View>
            <View
              style={{ marginTop: '20px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />
                Button Click Events
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>10 / 20</Text>
            </View>
          </View>
        </Page>
      )}
      {/*section 12 */}
      {proposal.companyInfo.implementCro && (
        <Page size='A4'>
          <View style={{ ...styles.section2 }}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>
            <View
              style={{
                marginTop: '30px',
                color: '#656565',
                fontSize: '11px',
                fontWeight: 'bold',
              }}
            >
              <Text>Additional Optimizations we'll perform:</Text>
            </View>
            <View
              style={{ marginTop: '15px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                IP Filtering
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Optimize data retention settings
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Channel Segmentation + Brand Terms
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Macro Conversion Tracking
              </Text>
            </View>
            <View
              style={{ marginTop: '10px', color: '#656565', fontSize: '10px' }}
            >
              <Text style={{ lineHeight: '2px' }}>
                <Image
                  src={tickIcon}
                  style={{ width: '10px', height: '8px' }}
                />{' '}
                Google Ads Linking + Conversion imports
              </Text>
            </View>

            <View style={{ marginTop: '30px', fontSize: '13px' }}>
              <Text>CRO Timeline</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '40px',
              }}
            >
              <Image
                src={croTimelineImage}
                style={{ width: '250px', marginLeft: '30px' }}
              />
              <View
                style={{
                  marginTop: '50px',
                  marginLeft: '30px',
                  lineHeight: '1.5px',
                }}
              >
                <View style={{ fontSize: '14px' }}>
                  <Text>Understand What Your CRO </Text>
                  <Text>Team Is Working On</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>Wondering what happens</Text>
                  <Text>next? This timeline highlights</Text>
                  <Text>the milestones you can</Text>
                  <Text>expect us to complete in the</Text>
                  <Text>first three months together.</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>After kick-off, we share a</Text>
                  <Text>detailed Asana project so you</Text>
                  <Text>can follow our progress in</Text>
                  <Text>real-time.</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>11 / 20</Text>
            </View>
          </View>
        </Page>
      )}
      {/*section 13*/}
      {proposal.companyInfo.implementCro && (
        <Page size='A4'>
          <View style={{ ...styles.section2 }}>
            <View
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
              <Text style={styles.fontSize}>{publishedDate}</Text>
            </View>

            {/*table*/}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
                marginTop: '60px',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#ACDCFC',
                  padding: '10px',
                }}
              >
                <Text>Month1</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#7AC7FB',
                  padding: '10px',
                }}
              >
                <Text>Month2</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#58BAF9',
                  padding: '10px',
                }}
              >
                <Text>Month3</Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Brand + Offer Research
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>CRO Testing begins</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Landing/Web Page Optimizations
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Conversion Path Strategy
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>New pop-ups created</Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Landing Page CRO Audit + Ice Sheet created for all LPs and
                  Pop-ups
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Creative review for first ad sets
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Landing/Web Page Optimizations
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Biweekly A/B Testing cadence (For highvolume pages)
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                margin: '0px auto',
              }}
            >
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Invisalign Landing Page + Confirmation launch
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Creative review for additional ad-sets depending on
                  optimizations
                </Text>
              </View>
              <View
                style={{
                  width: '33%',
                  backgroundColor: '#EFEFEF',
                  padding: '10px',
                  fontSize: '10px',
                }}
              >
                <Text style={{ lineHeight: '1.5px' }}>
                  Smart Traffic Tests launched (For lowvolume pages)
                </Text>
              </View>
            </View>

            <View style={{ marginTop: '30px', fontSize: '13px' }}>
              <Text>CRO Scope Of Work</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '30px',
              }}
            >
              <Image
                src={croScope}
                style={{ width: '250px', marginLeft: '30px' }}
              />
              <View
                style={{
                  marginTop: '50px',
                  marginLeft: '30px',
                  lineHeight: '1.5px',
                }}
              >
                <View style={{ fontSize: '14px' }}>
                  <Text>Scientific Landing Page</Text>
                  <Text>Design & Testing</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>We’ll make the most of your paid</Text>
                  <Text>traffic by pairing your campaigns</Text>
                  <Text>with high-performing landing</Text>
                  <Text>pages. After, we run tests on</Text>
                  <Text>pages, pop-ups, and banners.</Text>
                  <Text>Our process will identify areas of</Text>
                  <Text>improvement, make incremental</Text>
                  <Text>changes, and grow conversions.</Text>
                </View>
                <View
                  style={{
                    marginTop: '20px',
                    fontSize: '11px',
                    lineHeight: '1.5px',
                  }}
                >
                  <Text>See the itemized work in the</Text>
                  <Text>following table.</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                position: 'absolute',
                bottom: 0,
                fontSize: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                minWidth: '110vw',
                color: '#ffff',
                backgroundColor: '#1E1E48',
                marginLeft: '-10vw',
                padding: '20px',
              }}
            >
              <Text>
                Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
                luke@lineardesign.com
              </Text>
              <Text style={{ transform: 'translateX(20px)' }}>12 / 20</Text>
            </View>
          </View>
        </Page>
      )}
      {/*section 14*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>
          {proposal.companyInfo.implementCro && (
            <View style={{ width: '100%' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  marginTop: '40px',
                  fontSize: '12px',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    backgroundColor: '#E6E6E6',
                    padding: '5px',
                    textAlign: 'center',
                  }}
                >
                  <Text>Description</Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    backgroundColor: '#E6E6E6',
                    padding: '5px',
                    textAlign: 'center',
                  }}
                >
                  <Text>Details</Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Landing Page Design & Testing
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal.sowPricingInfo.croInfo.landingPageDesign
                      .length !== 0
                      ? proposal?.sowPricingInfo?.croInfo.landingPageDesign
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Confirmation/Thank you page{' '}
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.croInfo.thankYouPage.length !== 0
                      ? proposal?.sowPricingInfo?.croInfo.thankYouPage
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Wedge Method Design{' '}
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.croInfo.wedgeMethodDesign
                      .length !== 0
                      ? proposal?.sowPricingInfo?.croInfo.wedgeMethodDesign
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Pop-up Design & Testing{' '}
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.croInfo.popDesign.length !== 0
                      ? proposal?.sowPricingInfo?.croInfo.popDesign
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Sticky Bar Design & Testing{' '}
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.croInfo.stickyBarDesign
                      .length !== 0
                      ? proposal?.sowPricingInfo?.croInfo.stickyBarDesign
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    Heatmapping (Hotjar)
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.croInfo.heatmapping
                      ? 'Yes'
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    User Recordings (Hotjar)
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.croInfo.userRecordings
                      ? 'Yes'
                      : 'No'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  margin: '0px auto',
                  border: '1px solid #DDDDDD',
                }}
              >
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    User Surveys (Hotjar)
                  </Text>
                </View>
                <View
                  style={{
                    width: '50%',
                    padding: '10px',
                    fontSize: '10px',
                    textAlign: 'center',
                  }}
                >
                  <Text style={{ lineHeight: '1.5px' }}>
                    {proposal?.sowPricingInfo?.croInfo.userSurveys
                      ? 'Yes'
                      : 'No'}
                  </Text>
                </View>
              </View>
            </View>
          )}
          <View style={{ marginTop: '40px', fontSize: '13px' }}>
            <Text>Ad Design + Strategy</Text>
          </View>
          <View
            style={{ display: 'flex', flexDirection: 'row', marginTop: '30px' }}
          >
            <Image
              src={adDesign}
              style={{ width: '250px', marginLeft: '30px' }}
            />
            <View
              style={{
                marginTop: '50px',
                marginLeft: '30px',
                lineHeight: '1.5px',
              }}
            >
              <View style={{ fontSize: '14px' }}>
                <Text>Professional Ad Design</Text>
                <Text>for Every Platform</Text>
              </View>
              <View
                style={{
                  marginTop: '20px',
                  fontSize: '11px',
                  lineHeight: '1.5px',
                }}
              >
                <Text>Our team of designers will build</Text>
                <Text>new ad creative for your paid</Text>
                <Text>social and display campaigns. You</Text>
                <Text>get access to comment on and</Text>
                <Text>approve designs before ads go</Text>
                <Text>live.</Text>
              </View>
              <View
                style={{
                  marginTop: '20px',
                  fontSize: '11px',
                  lineHeight: '1.5px',
                }}
              >
                <Text>
                  Check out our{' '}
                  <Text style={{ color: '#70C3FC' }}>Dribbble</Text> to see
                </Text>
                <Text>following table.</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>13 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section 15*/}

      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>

          <View style={{ marginTop: '90px', fontSize: '13px' }}>
            <Text>Customized Reporting</Text>
          </View>
          <View
            style={{ marginTop: '20px', fontSize: '13px', textAlign: 'center' }}
          >
            <Text>Custom Reporting, Transparent Results</Text>
          </View>
          <View
            style={{ marginTop: '20px', fontSize: '10px', textAlign: 'center' }}
          >
            <Text style={{ lineHeight: '2px' }}>
              You’ll stay up-to-date on the progress we make towards your goal
              with weekly updates, bi-weekly calls, and an in-depth report
              delivered every month. We’ll also create and share a dashboard
              showing you the most important performance metrics, on the fly.
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
            }}
          >
            <Image src={customiseReportImg} style={{ width: '250px' }} />
          </View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>14 / 20</Text>
          </View>
        </View>
      </Page>

      {/*section 16*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>
          <View style={{ marginTop: '30px', fontSize: '13px' }}>
            <Text>Case Studies</Text>
          </View>
          <View
            style={{ marginTop: '20px', fontSize: '13px', textAlign: 'center' }}
          >
            <Text>We’ve Done Amazing Work For Companies Like Yours</Text>
          </View>
          <View
            style={{
              marginTop: '10px',
              fontSize: '13px',
              textAlign: 'center',
              fontSize: '10px',
              lineHeight: '2px',
            }}
          >
            <Text style={{ marginBottom: 0 }}>
              Check out the impressive results Linear delivers for our clients
              in the examples below. Visit our{' '}
              <Link
                src={'https://lineardesign.com/case-studies/'}
                target={'_blank'}
              >
                <Text style={{ color: 'rgb(112, 195, 252)' }}>
                  {'case studies page'}
                </Text>
              </Link>{' '}
              to see more.
            </Text>
          </View>

          <View
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            {mappedIndustry
              .get(proposal.companyInfo.industry)
              ?.links.map((industry, index) => (
                <View
                  style={{
                    width: '45%',
                    fontSize: '12px',
                    marginTop: '10px',
                    position: 'relative',
                  }}
                >
                  <Image
                    src={
                      mappedIndustry.get(proposal.companyInfo.industry).imgs[
                        index
                      ]
                    }
                    style={{
                      width: '100%',
                      height: '240px',
                      objectFit: 'cover',
                    }}
                  />
                  <Text>Type:B2C</Text>
                  <Text>
                    Industry:{' '}
                    {
                      mappedIndustry.get(proposal.companyInfo.industry).title[
                        index
                      ]
                    }
                  </Text>
                  <Link
                    href={industry}
                    style={{
                      transform: 'translate(-50%,-80px)',
                      marginLeft: '50%',
                      textDecoration: 'none',
                      backgroundColor: '#FEFEFD',
                      color: 'black',
                      padding: '10px',
                      textAlign: 'center',
                    }}
                  >
                    <Text>View Case Study</Text>
                  </Link>
                </View>
              ))}
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>15 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section 17*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>
          <View style={{ marginTop: '50px' }}>
            <Text style={{ fontSize: '14px' }}>
              Select Your Marketing Package
            </Text>
          </View>
          <View style={{ marginTop: '30px' }}>
            <Text style={{ fontSize: '16px' }}>3-month plan</Text>
          </View>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              fontSize: '12px',
              lineHeight: '2px',
            }}
          >
            <View></View>
            <View>
              <Text>
                $
                {(proposal?.sowPricingInfo?.clientPricing?.basePricePerMonth ||
                  0) * 3}
              </Text>
              <Text>
                <View>
                  <Text>
                    $
                    {proposal?.sowPricingInfo?.clientPricing?.basePricePerMonth}
                    x 3 months
                  </Text>
                </View>
              </Text>
              {proposal?.sowPricingInfo?.clientPricing?.addonServices?.map(
                (price) =>
                  price.quantity !== '0' && (
                    <Text>
                      {price.service +
                        ` (${price.quantity},$${price.quantity * price.price})`}
                    </Text>
                  )
              )}
            </View>
          </View>
          <View style={{ lineHeight: '2px', fontSize: '16px' }}>
            <Text>{listofAdsSelected}</Text>
          </View>
          <View style={{ marginTop: '20px', fontSize: '12px' }}>
            <Text>Includes Creative Assets</Text>
          </View>
          <View
            style={{ marginTop: '20px', fontSize: '10px', lineHeight: '2px' }}
          >
            <Text>Ongoing conversion rate</Text>
            <Text>optimization for key webpages,</Text>
            <Text>pop-ups, and ad creative. Complete</Text>
            <Text>management of your campaigns</Text>
            <Text>across Google Ads, Microsoft Ads,</Text>
            <Text>and Facebook Ads.</Text>
          </View>
          <View
            style={{
              marginTop: '10px',
              fontSize: '10px',
              lineHeight: '3px',
              marginLeft: '10px',
            }}
          >
            <Text>* Dedicated account manager</Text>
            <Text>* Overseen by Director of PPC</Text>
            <Text>* Dedicated CRO designer</Text>
            <Text>* Overseen by Director of CRO</Text>
            <Text>* Production designer</Text>
            <Text>* Bi-weekly calls</Text>
          </View>
          <View style={{ marginTop: '20px', fontSize: '13px' }}>
            <Text>Total</Text>
          </View>
          <View
            style={{
              backgroundColor: 'rgb(112, 195, 252)',
              padding: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
              color: '#fff',
            }}
          >
            <View></View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '12px',
              }}
            >
              <View style={{ marginRight: '20px' }}>
                <Text>Total:</Text>
              </View>
              <View>
                <Text>
                  $
                  {(proposal?.sowPricingInfo?.clientPricing
                    ?.basePricePerMonth || 0) * 3}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>16 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section 18*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>

          <View style={{ marginTop: '30px', fontSize: '13px' }}>
            <Text>Pricing Notes</Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Payment Guarantee:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              The total monthly services fee is payable monthly in advance. The
              first month's payment is due immediately upon signing of this
              Agreement. The person who signs his agreement, manually or
              digitally for LA Dental, personally guarantees payment and
              performance by the LA Dental and will pay all unpaid amounts upon
              demand, including court costs and attorneys fees to collect
              balances due. Late accounts could cause project work to be
              discontinued until the account is brought current.
            </Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Payment Authorization:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              You authorize Linear Magnitude, Inc., or its assignee(s), to make
              electronic fund transfers from your bank account or charges to
              your credit card account in the amounts identified as your total
              and per month payments, plus any applicable taxes, and including
              all past due amounts, processing fees, service fees, set up fees,
              or amounts which may accumulate in arrears, according to the terms
              above and the conditions of this Agreement.
            </Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Google AdWords and Facebook Charges:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              By working with us you agree to the current terms of service of{' '}
              <Text style={{ color: 'rgb(112, 195, 252)' }}>
                Google AdWords
              </Text>{' '}
              and <Text style={{ color: 'rgb(112, 195, 252)' }}>Facebook</Text>.
              Any traffic received through these accounts will be paid by direct
              credit card billing for your accounts. Your monthly traffic budget
              will not flow through Linear Magnitude, Inc.
            </Text>
          </View>

          <View style={{ marginTop: '30px', fontSize: '13px' }}>
            <Text>Terms & Conditions</Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Term:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              The initial term of this Agreement starts on the day this
              Agreement is signed and continues for three (3) months. After the
              initial term, this Agreement will automatically continue for
              additional one (1) month periods, and either party may terminate
              this Agreement at the end of any term by giving at least thirty
              (30) days prior written notice. If terminated, this Agreement ends
              on the last day of the term.
            </Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Warranty:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              Linear Magnitude, Inc makes no warranty, express, implied or
              statutory, with respect to the services provided hereunder,
              including without limitation any implied warranty of reliability,
              usefulness, merchantability, fitness for a particular purpose,
              noninfringement, or those arising from the course of performance,
              dealing, usage or trade. By signing this agreement, you
              acknowledge that Linear Magnitude, Inc neither owns nor governs
              the actions of any search engine or social media platform. You
              also acknowledge that Linear Magnitude, Inc does not guarantee or
              promise conversions, clicks, impressions, or any other specific
              measures of performance.
            </Text>
          </View>

          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Missed Deadlines / Rush Work:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              A schedule will be developed and agreed upon by Linear Magnitude,
              Inc and LA Dental early in the process. This schedule will reflect
              normal turnaround times during business hours to complete the
              project on schedule. In the event scheduled deadlines for delivery
              of information, materials, copy or approvals are missed, Linear
              Magnitude, Inc. will notify LA Dental and, if necessary, provide
              an estimate for any rush surcharges required to meet the scheduled
              delivery date.
            </Text>
          </View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>17 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section 19*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>

          <View
            style={{
              marginTop: '40px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Ownership / Intellectual Property:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              All products relating to work performed by Linear Magnitude, Inc.
              under this engagement will remain Linear Magnitude, Inc. property
              until payment for services is received in full. Upon receipt of
              payment in full, all rights to final deliverables will be
              automatically transferred to LA Dental without the necessity of
              further action. Working files and creative concepts not accepted
              or purchased will remain Linear Magnitude, Inc. property. You also
              acknowledge that Linear Magnitude, Inc reserves the right to use
              work samples in online and print portfolios.
            </Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Trademarks and Copyrighted Material:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              LA Dental warrants that they have the right to use any applicable
              trademarks or copyrighted material used in connection with this
              service. LA Dental must be the copyright holder for all materials
              supplied to Linear Magnitude, Inc, or have permission to use the
              materials from the copyright holder. Linear Magnitude, Inc. is not
              responsible for any copyright violations. As allowed by law, LA
              Dental shall indemnify the designer against all claims and
              expenses, including reasonable attorney’s fees, due to uses for
              which no release was requested in writing or for the uses that
              exceed the authority granted by the release.
            </Text>
          </View>

          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Limitation of liability and dispute resolution:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              LA Dental shall be responsible for final approval of all content,
              agrees to sign-off on final design prior to posting and shall not
              hold Linear Magnitude, Inc. responsible for any content errors
              contained therein.
            </Text>
            <Text style={{ marginTop: '10px' }}>
              LA Dental agrees that it shall not hold Linear Magnitude, Inc. or
              its agents or employees liable for any incidental or consequential
              damages that arise from the failure to perform any aspect of the
              project in a timely manner, or that result from causes reasonably
              beyond the control of Linear Magnitude, Inc. or its agents or
              employees. In no event shall Linear Magnitude, Inc., its officers,
              directors, employees, agents, and all third party service
              providers, be liable to LA Dental or any other person or entity
              for any direct, indirect, incidental, special, punitive, or
              consequential damages whatsoever, including, without limitation,
              loss of profits or interruption of business.
            </Text>
            <Text style={{ marginTop: '10px' }}>
              In addition, you specifically acknowledge and agree that in no
              event shall Linear Magnitude, Inc.'s total aggregate liability
              exceed the total amount paid by you for the particular services
              that are the subject of the cause of action.
            </Text>
            <Text style={{ marginTop: '10px' }}>
              The foregoing limitation of liability shall apply to the fullest
              extent permitted by law, and shall survive any termination or
              expiration of this Agreement.
            </Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Default:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              As used herein, “default” shall mean any of the following: (i) LA
              Dental’s failure to pay, on or before the due date the monthly
              fees described in the payment section above; (ii) LA Dental’s
              violation of trademarks and/or copyrights; and/or (iii) any breach
              by LA Dental of any other provision of this Agreement or of any
              statute or regulation, where such breach continues for ten days
              following written notice thereof from Linear Magnitude, Inc. to LA
              Dental.
            </Text>
            <Text style={{ marginTop: '10px' }}>
              Upon any default, in addition to any and all other remedies
              available under law to Linear Magnitude, Inc., Linear Magnitude,
              Inc. may at its option restrict access by LA Dental and/or the
              public to all or any part of the website. Following LA Dental’s
              cure of such default and written assurance to Linear Magnitude,
              Inc. that such default will not recur, Linear Magnitude, Inc.
              shall restore full access to the website.
            </Text>
          </View>

          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>18 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section 20*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>

          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Governing Law:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              This Agreement shall be governed by and construed in accordance
              with the law of the state of Utah. Any action, claim and
              proceeding brought by either party hereunder shall be commenced
              exclusively in the federal or state courts located in the state of
              Utah, and the prevailing party in any such action, claim or
              proceeding shall be entitled to recover from the other party all
              court costs, legal fees and expenses incurred by the prevailing
              party in such action, claim or proceeding.
            </Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>General:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              If any provision of this Agreement is held unenforceable, the
              remaining portions shall remain in full force and effect. This
              Agreement represents the entire Agreement between the parties and
              expressly replaces, supersedes and cancels any prior oral or
              written Agreement or communications on this subject matter. LA
              Dental acknowledges that it is not entering into this Agreement on
              the basis of any representations not expressly contained herein.
              Other than as specified herein, this Agreement may only be
              supplemented, modified or waived in writing signed by all parties.
              No additional or conflicting term in any other document used by LA
              Dental will have any legal effect. The waiver by Linear Magnitude,
              Inc. of any performance by LA Dental, or of any of Linear
              Magnitude Inc.’s rights hereunder, shall not be, and shall not be
              deemed to be, a waiver of any subsequent obligation of
              performance, default or right of the same or similar kind. LA
              Dental may not assign this Agreement or any of its rights or
              obligations hereunder to any third party or entity, and this
              Agreement may not be involuntarily assigned or assigned by
              operation of law, without the prior written consent of Linear
              Magnitude, Inc., which consent shall not be unreasonably withheld
              or delayed. Linear Magnitude Inc. shall not be responsible for any
              failure to perform due to unforeseen circumstances or to causes
              beyond Linear Magnitude Inc.'s control. The provisions hereof
              shall be binding upon and inure to the benefit of the parties,
              their successors and permitted assigns.
            </Text>
          </View>
          <View
            style={{
              marginTop: '20px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>General:</Text>
          </View>
          <View
            style={{ marginTop: '10px', fontSize: '10px', lineHeight: '1.5px' }}
          >
            <Text>
              If any provision of this Agreement is held unenforceable, the
              remaining portions shall remain in full force and effect. This
              Agreement represents the entire Agreement between the parties and
              expressly replaces, supersedes and cancels any prior oral or
              written Agreement or communications on this subject matter. LA
              Dental acknowledges that it is not entering into this Agreement on
              the basis of any representations not expressly contained herein.
              Other than as specified herein, this Agreement may only be
              supplemented, modified or waived in writing signed by all parties.
              No additional or conflicting term in any other document used by LA
              Dental will have any legal effect. The waiver by Linear Magnitude,
              Inc. of any performance by LA Dental, or of any of Linear
              Magnitude Inc.’s rights hereunder, shall not be, and shall not be
              deemed to be, a waiver of any subsequent obligation of
              performance, default or right of the same or similar kind. LA
              Dental may not assign this Agreement or any of its rights or
              obligations hereunder to any third party or entity, and this
              Agreement may not be involuntarily assigned or assigned by
              operation of law, without the prior written consent of Linear
              Magnitude, Inc., which consent shall not be unreasonably withheld
              or delayed. Linear Magnitude Inc. shall not be responsible for any
              failure to perform due to unforeseen circumstances or to causes
              beyond Linear Magnitude Inc.'s control. The provisions hereof
              shall be binding upon and inure to the benefit of the parties,
              their successors and permitted assigns.
            </Text>
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>19 / 20</Text>
          </View>
        </View>
      </Page>

      {/*section 21*/}
      <Page size='A4'>
        <View style={styles.section2}>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
            <Text style={styles.fontSize}>{publishedDate}</Text>
          </View>
          <View
            style={{
              marginTop: '60px',
              fontSize: '11px',
              fontWeight: 'bolder',
            }}
          >
            <Text>Next Steps</Text>
          </View>
          <View
            style={{ marginTop: '15px', fontSize: '10px', lineHeight: '1.8px' }}
          >
            <Text>
              1. You can respond at the top of this proposal with questions or
              comments.
            </Text>
          </View>
          <View
            style={{ marginTop: '15px', fontSize: '10px', lineHeight: '1.8px' }}
          >
            <Text>
              2. If any revisions are necessary, please add to the "Request more
              information" section at the top of the screen and we will revise
              the proposal.
            </Text>
          </View>
          <View
            style={{ marginTop: '15px', fontSize: '10px', lineHeight: '1.8px' }}
          >
            <Text>
              3. When the project is awarded, please hit "accept" at the top of
              the screen. At that point, you will be directed to make your first
              payment using our{' '}
              <Link href='https://lineardesign.com/pay/'>
                <Text style={{ color: 'rgb(112, 195, 252)' }}>
                  online payment portal
                </Text>
              </Link>
              .
            </Text>
          </View>
          <View
            style={{ marginTop: '15px', fontSize: '10px', lineHeight: '1.8px' }}
          >
            <Text>
              4. Feel free to download the PDF version as well to keep for your
              records.
            </Text>
          </View>
          <View
            style={{ marginTop: '15px', fontSize: '10px', lineHeight: '1.8px' }}
          >
            <Text>
              We look forward to working with you Arezoo! If you have any
              questions, please don’t hesitate to ask
            </Text>
          </View>
          <View
            style={{ marginTop: '30px', fontSize: '10px', lineHeight: '1.8px' }}
          >
            <Text>Thank you!</Text>
          </View>
          <View
            style={{ marginTop: '30px', fontSize: '10px', lineHeight: '1.8px' }}
          >
            <Text>
              <Image
                src={signature ? signature : ''}
                style={{ width: '60px', height: '60px' }}
              />
            </Text>
          </View>
          <View
            style={{ marginTop: '80px', fontSize: '10px', lineHeight: '1.8px' }}
          >
            <Text>{proposal?.companyInfo.proposerName}, Founder/CEO</Text>
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              minWidth: '110vw',
              color: '#ffff',
              backgroundColor: '#1E1E48',
              marginLeft: '-10vw',
              padding: '20px',
            }}
          >
            <Text>
              Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA |
              luke@lineardesign.com
            </Text>
            <Text style={{ transform: 'translateX(20px)' }}>20 / 20</Text>
          </View>
        </View>
      </Page>
      {/*section 32*/}
      {/*}
        <Page size="A4">
            <View style={styles.section2}>
                <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "10px" }}>
                    <Text style={styles.fontSize}>PPC & CRO Growth Proposal</Text>
                    <Text style={styles.fontSize}>October 07, 2022</Text>
                </View>
                <View style={{ marginTop: "60px", fontSize: "14px", fontWeight: "bolder" }}>
                    <Text>The following option(s) have been accepted:</Text>
                </View>
                <View style={{ marginTop: "30px", fontSize: "14px", fontWeight: "bolder", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                    <View>
                        <Text>3-Month Three Landing Pages</Text>
                    </View>
                    <View>
                        <Text style={{ fontWeight: "bold" }}>$10,365.00</Text>
                    </View>
                </View>
                <View style={{ marginTop: "15px", fontSize: "12px", fontWeight: "bolder", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                    <View>
                        <Text>Total:</Text>
                    </View>
                    <View>
                        <Text style={{ fontWeight: "bold" }}>$10,365.00</Text>
                    </View>
                </View>
                <View style={{ marginTop: "30px", fontSize: "14px", fontWeight: "bolder" }}>
                    <Text>This proposal is signed on Oct 11, 2022 by Arezoo Nasiry</Text>
                </View>
                <View style={{ marginTop: "20px", fontSize: "14px", fontWeight: "bolder" }}>
                    <Text>Arezoo Nasiry</Text>
                </View>
                <View
                    style={{
                        position: "absolute",
                        bottom: 0,
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        minWidth: "110vw",
                        color: "#ffff",
                        backgroundColor: "#1E1E48",
                        marginLeft: "-10vw",
                        padding: "20px"
                    }}
                >
                    <Text>Aiquire Inc | 625 W Adams St Chicago,IL 60661,USA | luke@lineardesign.com</Text>
                    <Text style={{ transform: "translateX(20px)" }}>31 / 33</Text>
                </View>
            </View>
        </Page>*/}
    </Document>
  );
};

export default GeneratePdf;
