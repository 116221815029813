import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import usimage from '../../../assets/images/usimage.jpeg';
import ciscoimage from '../../../assets/images/ciscoimage.jpeg';
import wirelessimage from '../../../assets/images/wirelessimage.jpeg';
import award1 from '../../../assets/images/award1.png';
import award2 from '../../../assets/images/award2.png';
import award3 from '../../../assets/images/award3.png';
import award4 from '../../../assets/images/award4.png';
import award5 from '../../../assets/images/award5.png';
import award6 from '../../../assets/images/award6.png';
import croTimeline from '../../../assets/images/cro-timeline.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import croScopeImage from '../../../assets/images/cro-scope.png';
import adDesignImage from '../../../assets/images/ad-design.png';
import customiseReportImage from '../../../assets/images/customise-report.png';
import googleScope from '../../../assets/images/google-scope.png';
import facebookScopeImage from '../../../assets/images/facebook-scope.png';
import ProposalPublishedModal from '../../modals/ProposalPublishedModal';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import {
  publishProposalReducer,
  getFormByFormIdReducer,
} from '../../../api/accountManager/forms/slice';
import { PDFDownloadLink } from '@react-pdf/renderer';
import GeneratePdf from '../../Common/GeneratePdf';
import mappedIndustry from '../../ClientDashboard/Proposal/mappedIndutrsy';
import moment from 'moment';
import { goBackAmCompo } from '../../../api/accountManager/componentTracking/action';

import { getImageUrl } from '../../../api/fileHandling/saga';
import LDGeneratePdf from '../../Common/LDGeneratePdf';
import NewOverview from './NewOverview';
import { getTimeframeData } from '../../../utils/LDProposalUtils';
import { PROPOSAL_STATE } from '../../../utils/accountManagerUtil';
import {
  accountManagerIds,
  ldComponentWithIndexAm,
} from '../../../utils/accountManagerUtil';
import { goToSpecificCompoClientAm } from '../../../api/accountManager/componentTracking/action';
import { AccountBalance } from '@mui/icons-material';
const Overview = () => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form.form);
  const [links, setLinks] = useState({
    companyLogo: null,
    signature: null,
  });
  const [formSubm, setFormSubm] = useState(false);
  const [clientIntroData, setClientIntroData] = useState(form || {});
  const insights = useSelector((state) => state.googleCustomers);
  const [listofAdsSelected, setListsOfAdsSelected] = useState('');
  const [timeframeData, setTimeFrameData] = useState({});
  const sowPricingInfo = form?.sowPricingInfo?.clientPricing?.addonServices;
  const platforms = form.onboardingInfo.platformInfo.platforms.map(
    (platform) => platform.platform
  );
  console.log(platforms);
  useEffect(() => {
    dispatch(getFormByFormIdReducer());
  }, []);

  useEffect(() => {
    if (form) {
      setTimeFrameData(getTimeframeData(form.sowPricingInfo.clientPricing));
      setClientIntroData(form);
      if (form?.companyInfo.companyLogo && form?.companyInfo.signature) {
        Promise.allSettled([
          getImageUrl(form.companyInfo.companyLogo),
          getImageUrl(form.companyInfo.signature),
        ]).then((res) => {
          setLinks({
            companyLogo:
              res[0].status === 'fulfilled' &&
              URL.createObjectURL(res[0].value),
            signature:
              res[1].status === 'fulfilled' &&
              URL.createObjectURL(res[1]?.value),
          });
        });
      }
    }
  }, [form]);

  useEffect(() => {
    setClientIntroData({ ...clientIntroData, ...links });
  }, [links]);
  useEffect(() => {
    let str = '';
    if (clientIntroData.companyInfo.googleAdsManaged) {
      str += 'Google Ads + ';
    }
    if (clientIntroData.companyInfo.fbAdsManaged) {
      str += 'Facebook Ads + ';
    }
    if (clientIntroData.companyInfo.implementCro) {
      str += 'CRO';
    }
    setListsOfAdsSelected(str);
  }, []);
  return (
    <div id='preview_proposal' className='proposal_layout proposal_flex_top '>
      <NewOverview proposal={form} timeframeData={timeframeData} />
      <div className='preview_footer_row'>
        <div className='footer_con' style={{ justifyContent: 'space-between' }}>
          {/* To be dispatched based on condition of platform  */}
          <button
            className='back'
            onClick={() =>
              platforms.length === 1 && platforms[0] === 'google-ads'
                ? dispatch(
                    goToSpecificCompoClientAm(
                      ldComponentWithIndexAm[accountManagerIds.AUDIT_REVIEW_POINTS]
                    )
                  )
                : dispatch(goBackAmCompo())
            }
          >
            BACK
          </button>
          <PrimaryButton
            title='PUBLISH'
            disabled={
              form?.proposalIterations &&
              [
                PROPOSAL_STATE.APPROVED,
                PROPOSAL_STATE.CHANGES_APPLIED,
                PROPOSAL_STATE.GENERATED,
              ].includes(
                form?.proposalIterations[form?.proposalIterations?.length - 1]
                  ?.state
              )
            }
            onClick={() => {
              dispatch(publishProposalReducer());
              setFormSubm(true);
            }}
          />
        </div>

        {formSubm && (
          <div>
            <ProposalPublishedModal
              formSubm={clientIntroData}
              setFormSubm={setFormSubm}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default Overview;
