import React from 'react';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateClientDetailsReducer } from '../../../api/client/action';
import { mappedReduxType } from '../../../api/client/enum';
import './textfield.css';
// companyWebsite
const InputTextField = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      <TextField
        // id='standard-basic'
        error={props.error}
        placeholder={
          props.placeholder ? props.placeholder : 'Type your answer here...'
        }
        value={props.value}
        variant={props.variant ? props.variant : 'standard'}
        style={{
          width: props.width,
          height: props.height ? props.height : '',
          animation: props.bounce && `${props.bounceAnimation} 0.9s both`,
          // '&::-webkit-input-placeholder': { color: 'blue' },
        }}
        disabled={props?.isDisabled ? props?.isDisabled : false}
        multiline={props.multiline}
        className={props.error ? 'bounce-top' : ''}
        name='input'
        size={props.size ? props.size : ''}
        onChange={(e)=>dispatch({type:props.reduxType,payload:{value:e.target.value,infoType:props.infoType,key:props.objType,isOther:true,quest:props.quest}})}
        inputProps={{
          style: { paddingBottom: 10, color: props.error ? '#800000' : '' },
        }}
      />
    </>
  );
};

export default InputTextField;
