import { useDispatch, useSelector } from 'react-redux';
import CommonAuditAccount from './CommonAuditAccount';
import { useCallback } from 'react';
import { getAlreadyAuditHdm } from '../../../api/HDMAM/audit/saga';
import { accountManagerIdsHDM, componentWithIndexHDMAm, isPlatformSelectedHDM } from '../../../utils/accountManagerUtil';
import { goToSpecificCompoHDMAM } from '../../../api/HDMAM/componentTracking/action';
import { onNextHDM } from '../GoToNextCompoButton';

export default function HDMAuditAccount() {
  const dispatch = useDispatch();

  const form = useSelector((state) => state.hdmFormAm);

  const onFBAccountIdExists = useCallback(() => {
    return getAlreadyAuditHdm("HDM").then((res) => {
      if (res.length !== 0) {
        const account = res.find(
          (account) => account.accountId === form?.fbAdsAccountId
        );
        dispatch({
          type: "GET_AUDIT_DETAILS_HDM",
          payload: account
            ? account.performanceData
            : res[res.length - 1].performanceData,
        });
      }
    });
  }, [dispatch, form?.fbAdsAccountId]);

  const onBack = () => {
    if (isPlatformSelectedHDM(form, "google-ads") !== -1) {
      dispatch(
        goToSpecificCompoHDMAM(
          componentWithIndexHDMAm[accountManagerIdsHDM.AUDIT_GOOGLE_ADS]
        )
      );
    } else {
      dispatch(
        goToSpecificCompoHDMAM(
          componentWithIndexHDMAm[
            accountManagerIdsHDM.HDM_ONBOARDING_COMMUNICATION
          ]
        )
      );
    }
  }

  const spends =
    (
      (form?.onboardingInfo?.clientAccess?.platforms || []).find(
        (platform) => platform.platform === "facebook-ads"
      ) || {}
    ).spends || 0;

    const onAuditAccountChange = useCallback(() => {
      dispatch(goToSpecificCompoHDMAM(8))
    }, [dispatch]);

  return (
    <CommonAuditAccount
      form={form}
      onFBAccountIdExists={onFBAccountIdExists}
      onBack={onBack}
      spends={spends}
      onAuditAccountChange={onAuditAccountChange}
      onNext={() => onNextHDM(dispatch)}
    />
  )
}