import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import './campaign-preview.css';
import CampaignTypePreview from './CampaignTypePreview';
import PrimaryButton from '../ClientDashboard/ButtonPrimary/Buttons';
import { useSelector } from 'react-redux';

const CampaignPreview = ({agencyType='LD'}) => {
  const [brandCampaignInfo, setBrandCampaignInfo] = useState({});
  const [competitorCampaignInfo, setCompetitorCampaignInfo] = useState({});
  const [serviceCampaignInfo, setServiceCampaignInfo] = useState({});
  const form = useSelector(state => agencyType === 'LD'? state.client : state.hdmClient);

  useEffect(() => {
    if(form.googleSearchAdsGeneratedCampaignsInfo) {
      setBrandCampaignInfo(form.googleSearchAdsGeneratedCampaignsInfo.find(campaign =>  campaign.campaignType === 'BRAND') || {})
      setCompetitorCampaignInfo(form.googleSearchAdsGeneratedCampaignsInfo.find(campaign =>  campaign.campaignType === 'COMPETITOR') || {})
      setServiceCampaignInfo(form.googleSearchAdsGeneratedCampaignsInfo.find(campaign =>  campaign.campaignType === 'SERVICE') || {})
    }
  }, [form])
  return (
    <div className='campaign-preview-container'>
      <div className='campaign-preview-header'>
        <Typography className='campaign-preview-header__title'>
          Campaign Preview
        </Typography>
        <Typography className='campaign-preview-header__description'>
          Create campaigns here and publish them to be run
        </Typography>
      </div>
      <CampaignTypePreview
        title='Brand Campaign'
        campaignID={brandCampaignInfo.campaignId || ''}
        campaignName={form?.campaignInfo?.brandCampaign?.name || 'Brand Campaign'}
        campaignURL='https://ads.google.com/'
      />
      <CampaignTypePreview
        title='Competitor Campaign'
        campaignID={competitorCampaignInfo.campaignId || ''}
        campaignName={form?.campaignInfo?.competitorCampaign?.name || 'Competitor Campaign'}
        campaignURL='https://ads.google.com/'
      />
      <CampaignTypePreview
        title='Services Campaign'
        campaignID={serviceCampaignInfo.campaignId || ''}
        campaignName={form?.campaignInfo?.serviceCampaign?.name ||'Services Campaign'}
        campaignURL='https://ads.google.com/'
      />
      <div className='preview_footer_row'>
        <div className='footer_con'>
          {/* <button className='back'>Back</button> */}
          <PrimaryButton title='Home' onClick={() => {
            window.location.href = '/brands';
          }}/>
        </div>
      </div>
    </div>
  );
};

export default CampaignPreview;
