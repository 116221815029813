import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../api/auth/actions";
import { arrow_down_icon as arrowDown } from "../../../assets/icons/icon";
import { log_out as logOutIcon } from "../../../assets/icons/icon";
import { pixis_icon as pixisIcon } from "../../../assets/icons/icon";
import { profile_icon as profileIcon } from "../../../assets/icons/icon";
import { redirect_new_icon as redirectIcon } from "../../../assets/icons/icon";
import backgroundImage from "../../../assets/images/leftsidepanelbg.svg";
import "./navigationSteps.css";

import { updateSideBarHDMAM } from "../../../api/HDMAM/componentTracking/action";
import { HDMAccountManager as HDMAccountSideNavIcon } from "../../../assets/icons/HDMAccount/HDMAccount";
import {
  accountManagerIdsHDM,
  BACKEND_STATUS,
  componentWithIndexHDMAm,
  getFBCampaignObject,
  getGoogleCampaignObject,
  isPlatformSelectedHDM,
  leftBarStatus,
  PROPOSAL_STATE,
} from "../../../utils/accountManagerUtil";
import { leftSidebarHDMAMWithCurrentCompIds } from "../../../utils/navbarUtil";
import { updateWholeFormDataHdmAm } from "../../../api/HDMAM/form/action";
import { goToSpecificCompoHDMAM } from "../../../api/HDMAM/componentTracking/action";
import { switchRedirect } from "../../../api/analytics/action";
import { resetData } from "../../../api/channels/action";
import { resetGoogleCustomerState as resetState } from "../../../api/accountManager/googleAdsCustomer/slice";
import { checkForAccess } from "../../../utils/hdmUtil";
import { dbStatus } from "../../ClientDashboard/HomePage/HomePage";
import {useAuth0} from "@auth0/auth0-react";
import { showAnalyticsDashboard } from "../../../utils/clientDashboardIdsUtil";

const NavigationSteps = ({ navigateSteps, active }) => {
  console.log("in navigation step");
  const currentComp = useSelector(
    (state) => state.hdmAMCurrentComp.currentCompAm
  );
  const formHdm = useSelector((state) => state.hdmFormAm);
  const [brandName, setBrandName] = useState("");
  const [profilePopUp, setProfilePopUp] = useState(false);
  const [navigateStepsWithStatus, setNavigateStepsWithStatus] = useState([]);
  const [loginEmail, setLoginEmail] = useState();
  const [loginName, setLoginName] = useState("");
  // const completedNavbarList = navigateSteps?.map((nav, index) =>
  //   currentNavIndex - 1 >= index ? true : false
  // );
  const [completedNavbarObj, setCompletedNavBarObj] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setLoginName(localStorage.getItem("amName"));
    setLoginEmail(localStorage.getItem("sub"));
  }, []);

  useEffect(() => {
    if (formHdm) {
      setBrandName(formHdm.brandId);
      const tempArr = [];
      let campaignsObject = null;
      let googleCampaignObj = {};
      if (formHdm?.fbAdsGeneratedCampaignsInfo) {
        const result = getFBCampaignObject(
          formHdm?.fbAdsGeneratedCampaignsInfo
        );
        campaignsObject = result[0];
      }
      if (formHdm?.googleSearchAdsGeneratedCampaignsInfo) {
        const result = getGoogleCampaignObject(
          formHdm.googleSearchAdsGeneratedCampaignsInfo
        );
        googleCampaignObj = result[0];
      }
      navigateSteps.forEach((nav, index) => {
        //Client Onboarding Inputs
        if (index === 0) {
          tempArr.push({
            status:
              formHdm?.onboardingInfo?.status === "COMPLETED"
                ? "COMPLETED"
                : "CURRENT",
            text:
              formHdm?.onboardingInfo?.status === "COMPLETED"
                ? leftBarStatus.completed
                : leftBarStatus.ongoing,
            name: nav.name,
            imgIcon: nav.imgIcon,
            id: nav.id,
          });
        }
        //access sharing
        if (
          (checkForAccess(formHdm) === dbStatus.READ_ACCESS ||
            checkForAccess(formHdm) === dbStatus.COMPLETE_ACCESS) &&
          formHdm?.accessSharing?.status === "COMPLETED" &&
          index === 1
        ) {
          tempArr.push({
            status: "COMPLETED",
            text: leftBarStatus.completed,
            name: nav.name,
            imgIcon: nav.imgIcon,
            id: nav.id,
          });
        } else if (
          formHdm?.onboardingInfo?.status === "COMPLETED" &&
          index === 1
        ) {
          tempArr.push({
            status: "CURRENT",
            text: leftBarStatus.ongoing,
            name: nav.name,
            imgIcon: nav.imgIcon,
            id: nav.id,
          });
        }
        //onboarding doc
        if (index === 2) {
          if (
            formHdm?.proposalStatus === "COMPLETED" ||
            formHdm?.proposalStatus === "ONGOING"
          ) {
            tempArr.push({
              status: "COMPLETED",
              text: leftBarStatus.completed,
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else if (
            (checkForAccess(formHdm) === dbStatus.READ_ACCESS ||
              checkForAccess(formHdm) === dbStatus.COMPLETE_ACCESS) &&
            formHdm?.accessSharing?.status === "COMPLETED"
          ) {
            tempArr.push({
              status: "CURRENT",
              text: leftBarStatus.ongoing,
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        // audit for google
        if (index === 3) {
          if (isPlatformSelectedHDM(formHdm, "google-ads") !== -1) {
            tempArr.push({
              status:
                formHdm?.auditInfo?.status === "COMPLETED"
                  ? "COMPLETED"
                  : formHdm?.auditInfo?.status === "ONGOING"
                  ? "CURRENT"
                  : "INCOMPLETED",
              text:
                formHdm?.auditInfo?.status === "COMPLETED"
                  ? leftBarStatus.completed
                  : formHdm?.auditInfo?.status === "ONGOING"
                  ? leftBarStatus.ongoing
                  : leftBarStatus.toBeUnlocked,
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        //audit for facebook
        if (index === 4) {
          if (isPlatformSelectedHDM(formHdm, "facebook-ads") !== -1) {
            if (
              formHdm?.fbAdsAccountId &&
              formHdm?.accountAnalysis?.status !== "PENDING"
            ) {
              tempArr.push({
                status: "COMPLETED",
                text: "Completed",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else if (
              formHdm?.proposalStatus === "COMPLETED" ||
              formHdm?.proposalStatus === "ONGOING"
            ) {
              tempArr.push({
                status: "CURRENT",
                text: "Ongoing",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else {
              tempArr.push({
                status: "INCOMPLETED",
                text: leftBarStatus.toBeUnlocked,
                imgIcon: nav.imgIcon,
                name: nav.name,
                id: nav.id,
              });
            }
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        //account analysis
        if (index === 5) {
          if (formHdm.accountAnalysis?.status === "COMPLETED") {
            tempArr.push({
              status: "COMPLETED",
              text: "Completed",
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else if (formHdm?.accountAnalysis?.status === "ONGOING") {
            tempArr.push({
              status: "CURRENT",
              text: "Ongoing",
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        // strategy creation google
        if (index === 6) {
          if (
            isPlatformSelectedHDM(formHdm, "google-ads") !== -1 &&
            formHdm?.googleSearchAdsStrategyInfo?.status === "COMPLETED"
          ) {
            tempArr.push({
              status: "COMPLETED",
              text: "Completed",
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else if (
            isPlatformSelectedHDM(formHdm, "google-ads") !== -1 &&
            formHdm?.auditInfo?.status === "COMPLETED"
          ) {
            tempArr.push({
              status: "CURRENT",
              text: "Ongoing",
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        //strategy creation facebook
        if (index === 7) {
          if (isPlatformSelectedHDM(formHdm, "facebook-ads") !== -1) {
            if (formHdm?.fbAdsStrategyInfo?.status === "COMPLETED") {
              tempArr.push({
                status: "COMPLETED",
                text: "Completed",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else if (
              formHdm?.fbAdsAccountId &&
              formHdm?.accountAnalysis?.status !== "PENDING"
            ) {
              tempArr.push({
                status: "CURRENT",
                text: "Ongoing",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else {
              tempArr.push({
                status: "INCOMPLETED",
                text: leftBarStatus.toBeUnlocked,
                imgIcon: nav.imgIcon,
                name: nav.name,
                id: nav.id,
              });
            }
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        //strategy deck
        if (index === 8) {
          if (
            (
              (formHdm?.strategyIterations &&
                formHdm?.strategyIterations[
                  formHdm?.strategyIterations.length - 1
                ]) ||
              {}
            ).state === PROPOSAL_STATE.APPROVED
          ) {
            tempArr.push({
              status: "COMPLETED",
              text: "Completed",
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else if (isPlatformSelectedHDM(formHdm, "facebook-ads") !== -1) {
            if (formHdm?.fbAdsStrategyInfo?.status === "COMPLETED") {
              tempArr.push({
                status: "CURRENT",
                text: "Ongoing",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else {
              tempArr.push({
                status: "INCOMPLETED",
                text: leftBarStatus.toBeUnlocked,
                imgIcon: nav.imgIcon,
                name: nav.name,
                id: nav.id,
              });
            }
          } else if (isPlatformSelectedHDM(formHdm, "google-ads") !== -1) {
            if (formHdm?.googleSearchAdsStrategyInfo?.status === "COMPLETED") {
              tempArr.push({
                status: "CURRENT",
                text: "Ongoing",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else {
              tempArr.push({
                status: "INCOMPLETED",
                text: leftBarStatus.toBeUnlocked,
                imgIcon: nav.imgIcon,
                name: nav.name,
                id: nav.id,
              });
            }
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        // campaign creation google
        if (index === 9) {
          if (isPlatformSelectedHDM(formHdm, "google-ads") !== -1) {
            if (
              googleCampaignObj.brandCampaign &&
              googleCampaignObj.competitorCampaign &&
              googleCampaignObj.serviceCampaign
            ) {
              tempArr.push({
                status: "COMPLETED",
                text: "Completed",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else if (
              (
                (formHdm?.strategyIterations &&
                  formHdm?.strategyIterations[
                    formHdm?.strategyIterations.length - 1
                  ]) ||
                {}
              ).state === PROPOSAL_STATE.APPROVED
            ) {
              tempArr.push({
                status: "CURRENT",
                text: "Ongoing",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else {
              tempArr.push({
                status: "INCOMPLETED",
                text: leftBarStatus.toBeUnlocked,
                imgIcon: nav.imgIcon,
                name: nav.name,
                id: nav.id,
              });
            }
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }

        // campaign creation facebook

        if (index === 10) {
          console.log("in index 10", campaignsObject);
          if (isPlatformSelectedHDM(formHdm, "facebook-ads") !== -1) {
            if (
              campaignsObject &&
              campaignsObject.lookalike &&
              campaignsObject.interest &&
              campaignsObject.mof &&
              campaignsObject.bof
            ) {
              tempArr.push({
                status: "COMPLETED",
                text: "Completed",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else if (
              (
                (formHdm?.strategyIterations &&
                  formHdm?.strategyIterations[
                    formHdm?.strategyIterations.length - 1
                  ]) ||
                {}
              ).state === PROPOSAL_STATE.APPROVED
            ) {
              tempArr.push({
                status: "CURRENT",
                text: "Ongoing",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else {
              tempArr.push({
                status: "INCOMPLETED",
                text: leftBarStatus.toBeUnlocked,
                imgIcon: nav.imgIcon,
                name: nav.name,
                id: nav.id,
              });
            }
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        // campaign preview google
        if (index === 11) {
          if (isPlatformSelectedHDM(formHdm, "google-ads") !== -1) {
            if (
              googleCampaignObj.brandCampaign &&
              googleCampaignObj.competitorCampaign &&
              googleCampaignObj.serviceCampaign
            ) {
              tempArr.push({
                status: "COMPLETED",
                text: "Completed",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else {
              tempArr.push({
                status: "INCOMPLETED",
                text: leftBarStatus.toBeUnlocked,
                imgIcon: nav.imgIcon,
                name: nav.name,
                id: nav.id,
              });
            }
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }

        //campaign preview facebook
        if (index === 12) {
          if (isPlatformSelectedHDM(formHdm, "facebook-ads") !== -1) {
            if (
              campaignsObject &&
              campaignsObject.lookalike &&
              campaignsObject.interest &&
              campaignsObject.mof &&
              campaignsObject.bof
            ) {
              tempArr.push({
                status: "COMPLETED",
                text: "Completed",
                name: nav.name,
                imgIcon: nav.imgIcon,
                id: nav.id,
              });
            } else {
              tempArr.push({
                status: "INCOMPLETED",
                text: leftBarStatus.toBeUnlocked,
                imgIcon: nav.imgIcon,
                name: nav.name,
                id: nav.id,
              });
            }
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              imgIcon: nav.imgIcon,
              name: nav.name,
              id: nav.id,
            });
          }
        }
        //Analytics dashboard
        if (index === 13) {
          if (showAnalyticsDashboard(formHdm)) {
            tempArr.push({
              status: "COMPLETED",
              text: "",
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          } else {
            tempArr.push({
              status: "INCOMPLETED",
              text: leftBarStatus.toBeUnlocked,
              name: nav.name,
              imgIcon: nav.imgIcon,
              id: nav.id,
            });
          }
        }
      });
      setNavigateStepsWithStatus(tempArr);
    }
  }, [formHdm]);
  const {logout:logoutAuth0} = useAuth0();
  const logoutFromUser = () => {
    logoutAuth0({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } });
    dispatch(logout());
  };
  const onClickHandler = (sideOption) => {
    dispatch(
      updateSideBarHDMAM(leftSidebarHDMAMWithCurrentCompIds[sideOption])
    );
  };
  useEffect(() => {
    dispatch(updateWholeFormDataHdmAm());
  }, []);

  return (
    <Box
      style={{
        background: `url(${backgroundImage}),white`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPositionY: -38,
        height: "100vh",
      }}
    >
      <div className="sidebar_layout amlayout">
        <div className="logoContainer">
          <img src={pixisIcon} alt="" />
          <Typography
            variant="h4"
            component="h3"
            style={{
              color: "#0869FB",
              marginTop: 10,
            }}
          >
            Onboarding
          </Typography>
          {/* <h2>Automations</h2> */}
        </div>
        <div className="steps">
          <div className="steps_inner">
            {active === "SELECT BRAND" ? (
              <img src={HDMAccountSideNavIcon} className="sideNavImg" />
            ) : (
              navigateStepsWithStatus?.map((step, key) => (
                <div className="stepHelper" key={key}>
                  <div
                    className="step"
                    id={step.id}
                    style={{
                      pointerEvents:
                        step.status === "INCOMPLETED" ? "none" : "all",
                    }}
                    onClick={() => {
                      if (step.id === "AnalyticDashboard") {
                        if (
                          window.location.pathname !== "/analytic-dashboard"
                        ) {
                          window.location.href = "/analytic-dashboard";
                        }
                      } else {
                        if (
                          isPlatformSelectedHDM(formHdm, "facebook-ads") !==
                            -1 &&
                          formHdm?.fbAdsAccountId &&
                          formHdm?.accountAnalysis?.status !== "PENDING" &&
                          step.id === "AUDIT"
                        ) {
                          dispatch(goToSpecificCompoHDMAM(componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_ACCOUNT]));
                        } else {
                          onClickHandler(step.id);
                        }
                        if (
                          window.location.pathname === "/analytic-dashboard"
                        ) {
                          dispatch(switchRedirect());
                          setTimeout(() => {
                            window.location.href = "/hdmaccountmanager";
                          }, 500);
                        }
                      }
                    }}
                  >
                    <div
                      className={
                        step.status === "COMPLETED"
                          ? "question-logo completedQuest"
                          : step.status === "CURRENT"
                          ? "question-logo selectedBackground"
                          : "question-logo"
                      }
                    >
                      <span className="left_nav_icon"></span>
                    </div>
                    <div className="step-description">
                      <Typography
                        style={{
                          marginBottom: 0,
                          fontSize: "14px",
                          color:
                            step.status === "CURRENT" ||
                            step.status === "COMPLETED"
                              ? "#000000"
                              : "#adadad",
                        }}
                      >
                        {step.name}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: 0,
                          fontSize: 12,
                          color: "#aeaeae",
                        }}
                      >
                        {step.text}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        {profilePopUp && (
          <div
            style={{
              width: 200,
              textAlign: "center",
              height: "auto",
              position: "absolute",
              bottom: "5rem",
              right: "0rem",
              backgroundColor: "white",
              // border: '1px solid #d3d3d3',
              zIndex: 1,
              cursor: "pointer",
              background: "#FFFFFF",
              border: "0.35px solid #E9E9E9",
              boxShadow: "0px 7px 80px rgba(0, 0, 0, 0.05)",
              borderRadius: "8px 8px 0px 8px",
              padding: "0.6rem",
            }}
          >
            <p
              style={{
                borderBottom: "1px solid #EFEFEF",
                paddingBottom: "1rem",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                gap: 8,
                marginBottom: 0,
              }}
            >
              Client : {brandName || "Brand Name"}{" "}
            </p>
            <p
              style={{
                borderBottom: "1px solid #EFEFEF",
                paddingBottom: "1rem",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                gap: 8,
                marginBottom: 0,
              }}
              onClick={() => {
                dispatch(goToSpecificCompoHDMAM(0));
                dispatch(resetData());
                dispatch(resetState());
                localStorage.removeItem("amSelectedFormId");
                localStorage.removeItem("amSelectedBrandId");
                if (window.location.pathname === "/analytic-dashboard") {
                  setTimeout(() => {
                    window.location.href = "/hdmaccountmanager";
                  }, 500);
                }
              }}
            >
              Brand Page
              <div
                style={{
                  width: 20,
                  height: 20,
                }}
              >
                <img src={redirectIcon} alt="" width={16} height={16} />
              </div>
            </p>
            <p
              style={{
                paddingBottom: "1rem",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                gap: 8,
                marginBottom: 0,
              }}
              onClick={() => logoutFromUser()}
            >
              {loginName}
              <div
                style={{
                  width: 20,
                  height: 20,
                }}
              >
                <img src={logOutIcon} alt="" width={18} height={18} />
              </div>
            </p>
          </div>
        )}
        <div className="navigation_footer">
          <div className="nav-footer-icons">
            <div className="account_manger_detials">
              <p>{loginName}</p>
              <p>{loginEmail}</p>
            </div>
            <div
              className="footer-icon profile-icon"
              onClick={() => setProfilePopUp(!profilePopUp)}
            >
              <img src={profileIcon} alt="" />
              <img src={arrowDown} alt="" className="arrow-img" />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default NavigationSteps;
