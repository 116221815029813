import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connectAccount, sendAuthCode } from '../../../api/channels/action';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import PmaxLoader from '../../PMaxStrategyCreation/PmaxLoader';
import { AdsList, ChannelType } from '../channel.js';
import {
  GET_FB_ACCOUNTS_CHANNEL_NULL_DATA,
  GET_GOOGLE_ACCOUNTS_CHANNEL_NULL_DATA,
  useDeleteFBChannel,
  useDeleteGoogleChannel,
  useGetFBAccountsChannel,
  useGetFbAuthInfo,
  useGetGoogleAccountsChannel,
  useGetGoogleAuthInfo,
} from '../../../services/connectAccounts';
import checkedIcon from '../../../assets/icons/checked-icon.svg';
import { useSearchParams } from 'react-router-dom';
const DataAnalytics = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    channel: AdsList[0].type
  });
 const currentChannel = AdsList.find(item => item.type === searchParams.get("channel")) || AdsList[0];
  const dispatch = useDispatch();
  const [accountSearchTerm, setAccountSearchTerm] = useState('');

  const { data: googleAuthInfo, isFetching: isGoogleAuthInfoFetching } =
    useGetGoogleAuthInfo();

  const { data: fbAuthInfo, isFetching: isFbAuthInfoFetching } =
    useGetFbAuthInfo();

  const isGoogleAccountAuthenticated = !!googleAuthInfo?.email;
  const isFBAccountAuthenticated = !!fbAuthInfo?.email;

  const {
    data: googleCustomers = GET_GOOGLE_ACCOUNTS_CHANNEL_NULL_DATA,
    isFetching: isGoogleCustomersLoading,
  } = useGetGoogleAccountsChannel({
    enabled: isGoogleAccountAuthenticated,
  });

  const {
    data: fbCustomers = GET_FB_ACCOUNTS_CHANNEL_NULL_DATA,
    isFetching: isFBCustomersLoading,
  } = useGetFBAccountsChannel({
    enabled: isFBAccountAuthenticated,
  });

  const { mutate: deleteGoogleChannel, isLoading: isGoogleChannelDeleting } =
    useDeleteGoogleChannel({
      enabled: isGoogleAccountAuthenticated,
    });

  const { mutate: deleteFBChannel, isLoading: isFBChannelDeleting } =
    useDeleteFBChannel({
      enabled: isFBAccountAuthenticated,
    });

  const isAccountAuthenticated = (type) =>
    ({
      [ChannelType.GoogleAds]: isGoogleAccountAuthenticated,
      [ChannelType.FacebookAds]: isFBAccountAuthenticated,
    }[type]);

  const isGoogleAccountLoading = false;
  const isFBAccountLoading = false;
  const isLoading =
    isGoogleCustomersLoading ||
    isFBCustomersLoading ||
    isGoogleAccountLoading ||
    isFBAccountLoading ||
    isGoogleChannelDeleting ||
    isFBChannelDeleting ||
    isGoogleAuthInfoFetching ||
    isFbAuthInfoFetching;
  const isAccountsAndChannelsLoading =
    isGoogleCustomersLoading ||
    isFBCustomersLoading ||
    isGoogleAccountLoading ||
    isFBAccountLoading ||
    isGoogleAuthInfoFetching ||
    isFbAuthInfoFetching;
  const isRemoving = isGoogleChannelDeleting || isFBChannelDeleting;

  //send auth code google ads
  useEffect(() => {
    const authCode = window.location.href
      .split('?code=')[1]
      ?.split('&scope=')[0];
    if (authCode) {
      dispatch(sendAuthCode(ChannelType.GoogleAds, authCode));
    }
  }, []);

  //remove account
  const removeAccount = (type) => () => {
    return {
      [ChannelType.GoogleAds]: deleteGoogleChannel,
      [ChannelType.FacebookAds]: deleteFBChannel,
    }[type]();
  };

  const numberOfAuthChannel = () => {
    return [isGoogleAccountAuthenticated, isFBAccountAuthenticated].filter(
      Boolean
    ).length;
  };

  const filteredGoogleAccounts = useMemo(() => {
    if (currentChannel.type !== ChannelType.GoogleAds) return [];
    return !accountSearchTerm
      ? googleCustomers.customers
      : googleCustomers.customers.filter((item) => {
          return (
            item.customerName
              ?.toLowerCase()
              .includes(accountSearchTerm.toLowerCase()) ||
            item.customerId.toString().includes(accountSearchTerm.toString())
          );
        });
  }, [accountSearchTerm, currentChannel, googleCustomers]);

  const filteredFBAccounts = useMemo(() => {
    if (currentChannel.type !== ChannelType.FacebookAds) return [];
    return !accountSearchTerm
      ? fbCustomers.accounts
      : fbCustomers.accounts.filter((item) => {
          return (
            item.name
              ?.toLowerCase()
              .includes(accountSearchTerm.toLowerCase()) ||
            item.accountId.toString().includes(accountSearchTerm.toString())
          );
        });
  }, [accountSearchTerm, currentChannel, fbCustomers]);

  const authInfo = {
    [ChannelType.GoogleAds]: googleAuthInfo,
    [ChannelType.FacebookAds]: fbAuthInfo,
  }[currentChannel.type];

  return (
    <div
      style={{ padding: '24px 0px', margin: '0 24px', background: '#F5F5F5' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          style={{
            border: '1px solid black',
            color: 'black',
            padding: '5px 15px',
          }}
          onClick={() => {
            window.location.href = '/admin';
          }}
        >
          <ArrowBackIosIcon style={{ width: '20px', height: '10px' }} /> BACK
        </Button>
        <Button
          style={{ transform: 'translateX(-20px)', color: '#7308FB' }}
          onClick={() => {
            localStorage.clear();
            window.location.href = '/';
          }}
        >
          Logout {'  '} <LogoutIcon />
        </Button>
      </div>

      <Card sx={{ boxShadow: 3, mb: 2, marginTop: 3, variant: 'outlined' }}>
        <Grid
          container
          spacing={0}
          alignItems='center'
          justifyContent='center'
          style={{ padding: 20 }}
        >
          <Grid item sm={12} lg={12}>
            <Grid
              container
              spacing={2}
              justifyContent='flex-start'
              alignItems='stretch'
            >
              <Grid item xs={8} align='left' marginBottom={3}>
                <Typography variant='h5'>Manage data sources</Typography>
              </Grid>
            </Grid>

            <Grid container justifyContent='flex-start' alignItems='stretch'>
              <Grid item xs={6} align='left'>
                <Typography variant='subtitle1'>Total sources used</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={6}>
                <Typography variant='h6'>{numberOfAuthChannel()}</Typography>
              </Grid>
              {/* <Grid xs={6}>
                <Typography
                  style={{
                    color: "#999999",
                    textAlign: "right",
                    fontSize: "10px",
                  }}
                  variant="subtitle1"
                >
                  Connected Data Sources
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} align='right'>
            <Divider sx={{ borderBottomWidth: 6, background: 'black' }} />
          </Grid>
        </Grid>
      </Card>
      <Grid
        container
        // xs={12}
        style={{ marginTop: '1rem', alignItems: 'stretch' }}
        gap={'10px'}
      >
        <Grid
          xs={3.5}
          sx={{ boxShadow: 3 }}
          style={{
            background: '#FFF',
            flexGrow: '1',
            flexBasis: '0',
            height: 'calc(100vh - 305px)',
          }}
        >
          <Card sx={{ boxShadow: 0 }} style={{ height: '100%' }}>
            <Typography
              style={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: "flex-start",
                padding: '10px 0',
                marginLeft: '17px',
                color: 'rgba(0, 0, 0, 0.3)',
                fontWeight: '550',
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              Ads Channels (2)
            </Typography>

            <Divider />
            {AdsList.map((item, index) => (
              <>
                <List
                  style={{
                    backgroundColor:
                      item.type === currentChannel.type ? '#FAF9F6' : '#ffff',
                  }}
                  key={index}
                >
                  <ListItemButton
                    selected={currentChannel.type === item.type}
                    onClick={() => {
                      setSearchParams({channel: item.type});
                      setAccountSearchTerm('');
                    }}
                    disabled={item.hasArrived ? false : true}
                  >
                    <ListItemIcon>
                      <img src={item.logo} alt='' />
                    </ListItemIcon>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        lineHeight: '17px',
                        fontWeight: '500',
                        width: '200px',
                      }}
                    >
                      {item.name}
                    </Typography>
                    {isAccountAuthenticated(item.type) && (
                      <div
                        style={{
                          minWidth: 96,
                          height: 24,
                          backgroundColor: '#E8F9F6',
                          padding: '4px 8px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 4,
                          borderRadius: 6,
                        }}
                      >
                        <img
                          src={checkedIcon}
                          alt='connected'
                          width={12}
                          height={12}
                        />
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: 12,
                            lineHeight: '20px',
                            color: '#1ABFA1',
                          }}
                        >
                          Connected
                        </Typography>
                      </div>
                    )}
                  </ListItemButton>
                </List>
                <Divider />
              </>
            ))}
            <Divider />
          </Card>
        </Grid>

        <Grid
          xs={3.5}
          sx={{ boxShadow: 3 }}
          style={{ background: '#FFF', height: 'calc(100vh - 305px)' }}
        >
          {!isAccountAuthenticated(currentChannel.type) ? (
            <Card
              sx={{ boxShadow: 0 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              {/* <TextField
                placeholder='Accounts'
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon sx={{ marginLeft: '15px' }} />
                    </InputAdornment>
                  ),
                }}
                type='search'
                variant='standard'
                sx={{
                  width: '100%',
                  '.MuiInput-root': { height: '40px' },
                  '.MuiInput-root:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                  },
                  '.MuiInput-input': { marginLeft: '15px', marginTop: '2px' },
                  '.css-c63i49-MuiInputBase-input-MuiInput-input': {
                    fontSize: '14px',
                    fontFamily: 'Inter',
                    fontWeight: '600',
                    lineHeight: '17px',
                  },
                }}
              /> */}
              <Typography
                style={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  lineHeight: '17px',
                  paddingLeft: '15px',
                  paddingTop: '10px',
                  paddingBottom: '9px',
                  color: 'rgba(0, 0, 0, 0.3)',
                }}
              >
                Connected Account
              </Typography>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  // flexDirection: "column",
                  alignItems: 'center',
                  // justifyContent: "space-evenly",
                  flex: '1',
                  flexFlow: 'column',
                  // gap: "3rem",
                }}
              >
                <img
                  style={{ marginTop: '2rem', marginBottom: '2rem' }}
                  src={currentChannel.logo}
                  alt=''
                />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '16px',
                    color: '#000000',
                    marginBottom: '1rem',
                  }}
                >
                  {currentChannel.name}
                </Typography>
                <Typography
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: 'rgba(0, 0, 0, 0.5)',
                    marginBottom: '1rem',
                  }}
                >
                  Connect your account
                </Typography>
                <PrimaryButton
                  title='Connect an Account'
                  onClick={() => {
                    dispatch(connectAccount(currentChannel.type));
                  }}
                ></PrimaryButton>
              </div>
            </Card>
          ) : (
            <Card
              sx={{ boxShadow: 0 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Typography
                style={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  lineHeight: '17px',
                  paddingLeft: '15px',
                  paddingTop: '10px',
                  paddingBottom: '9px',
                  color: 'rgba(0, 0, 0, 0.3)',
                }}
              >
                Connected Account
              </Typography>
              <Divider />
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '63px',
                    width: '100%',
                    paddingLeft: '15px',
                    paddingTop: '10px',
                    paddingBottom: '11px',
                    paddingRight: '20px',
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '16.96px',
                        marginBottom: '9px',
                      }}
                    >
                      {authInfo?.name}{' '}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '16px',
                        color: '#737880',
                      }}
                    >
                      {authInfo?.email} {''}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      style={{
                        fontSize: 11,
                        color: 'red',
                        marginLeft: 'auto',
                        marginTop: '2px',
                      }}
                      onClick={removeAccount(currentChannel.type)}
                    >
                      REMOVE {''}
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          )}
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            height: 'calc(100vh - 305px)',
          }}
        >
          <Card
            sx={{
              boxShadow: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              placeholder='List of Accounts Connected'
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon sx={{ marginLeft: '15px' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setAccountSearchTerm(e.target.value)}
              type='search'
              variant='standard'
              sx={{
                width: '100%',
                '.MuiInput-root': { height: '40px' },
                '.MuiInput-root:before': {
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                },
                '.MuiInput-input': { marginLeft: '15px', marginTop: '2px' },
                '.css-c63i49-MuiInputBase-input-MuiInput-input': {
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: '600',
                  lineHeight: '17px',
                },
              }}
            />
            <Grid
              container
              style={{
                overflowY: 'auto',
                width: '100%',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              spacing={0.5}
            >
              <div />
              {currentChannel.type === ChannelType.GoogleAds &&
                filteredGoogleAccounts.map((account) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '65px',
                      width: '100%',
                      paddingLeft: '24px',
                      paddingTop: '14px',
                      paddingBottom: '15px',
                      paddingRight: '24px',
                      borderBottom: '1px solid #f1f1f1',
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '16.96px',
                        }}
                      >
                        {account.customerName}
                        {''}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '16px',
                          color: '#737880',
                        }}
                      >
                        MCC : {account.loginCustomerName}
                        {''}
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '16.96px',
                      }}
                    >
                      Account ID: {account.customerId} {''}
                    </Typography>
                  </div>
                ))}

              {currentChannel.type === ChannelType.FacebookAds &&
                filteredFBAccounts.map((account) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '65px',
                      width: '100%',
                      paddingLeft: '24px',
                      paddingTop: '14px',
                      paddingBottom: '15px',
                      paddingRight: '24px',
                      borderBottom: '1px solid #f1f1f1',
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '16.96px',
                        }}
                      >
                        {account.name}
                        {''}
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '16.96px',
                      }}
                    >
                      Account ID: {account.accountId} {''}
                    </Typography>
                  </div>
                ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {isLoading && (
        <PmaxLoader
          description={
            isAccountsAndChannelsLoading
              ? 'Please wait while we fetch your accounts'
              : isRemoving
              ? 'Please wait while the accounts are being removed'
              : ''
          }
        />
      )}
    </div>
  );
};
export default DataAnalytics;
