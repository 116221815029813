import "./googleanalysis.css";
import GOOGLE from "../../../../../assets/icons/socialmedia/g-icon.svg";
// import { ReactComponent as ReactLogo } from "../../../../assets/icons/socialmedia/google.svg";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography } from "@mui/material";
import GoToNextCompoButton from "../../../GoToNextCompoButton";
import { goBackHDMAmCompo } from "../../../../../api/HDMAM/componentTracking/action";
import { useEffect } from "react";
import {updateStatusHdmAm} from "../../../../../api/HDMAM/form/action";

const GOOGLEANALYSIS = ({googleVal}) => {
  const dispatch = useDispatch();
  const hdm=useSelector(state => state.hdmFormAm);
  useEffect(()=>{
    if(hdm?.accountAnalysis){
      const status=hdm.accountAnalysis.status;
      status === "PENDING" && dispatch(updateStatusHdmAm({outerKey:"accountAnalysis",status:"ONGOING"}));
    }
  },[hdm]);
  return (
    <div style={{ width: "100%" }}>
      <div className="google_bk">
        <div
          style={{
            width: "56.84px",
            height: "56.84px",
            background: "#FFFFFF",
            boxShadow: "0px 13.3214px 17.7619px rgba(27, 33, 40, 0.08)",
            borderRadius: "10.6571px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={GOOGLE} alt="" />
        </div>

        <Typography
          style={{
            fontFamily: "Lato",
            fontSize: "35.52px",
            fontWeight: "700",
            lineHeight: "46.18px",
            color: "#000000",
            marginLeft: "14.21px",
          }}
        >
          {" "}
          Analysis Google
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {/* <TextField placeholder='Please add text here...' id="outlined-multiline-static" InputProps={{style:{
                            height:'186px',
                            width:'1047px',
                            padding:'0px'
                            
                        }}}/> */}
        <TextField
          className="text-field"
          multiline
          rows={5}
          placeholder="Please add text here..."
          InputProps={{
            style: {
              height: "186px",
              width: "1047px",
              border: "1px solid #F0F0F0",
              background: "#F0F0F0",
            },
          }}
          value={googleVal}
          onChange={(e)=>dispatch({type:"UPDATE_VALUE_ACCOUNT_ANALYSIS_HDM_AM",payload:{key:"google",value:e.target.value}})}
        />
      </div>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button className="back" onClick={()=>dispatch(goBackHDMAmCompo())}>BACK</button>
          <GoToNextCompoButton title="Next" />
        </div>
      </div>
    </div>
  );
};
export default GOOGLEANALYSIS;
