import { Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import googleIcon from "./assets/google-icon.svg";
import googleSearchView from "./assets/google-search-view.svg";
import googlePmaxView from "./assets/google-pmax-view.svg";
import googleDisplayView from "./assets/google-display-view.svg";
import "./google-campaign-selector.scss";
import UnlockButton from "./StatusButton/UnlockButton";
import GeneratingButton from "./StatusButton/GeneratingButton";
import CompletedButton from "./StatusButton/CompletedButton";
import OngoingButton from "./StatusButton/OngoingButton";

import { GOOGLE_CAMPAIGN_SELECTOR_STAGES } from "../../constants/LDConstants";
import BudgetModal from "../modals/BudgetModal";
const GoogleCampaignSelector = ({
  stage = GOOGLE_CAMPAIGN_SELECTOR_STAGES.Strategy,
  pmaxOngoingButton,
  pmaxGeneratingButton,
  pmaxCompletedButton,
  pmaxClickHandler,
  searchOngoingButton,
  searchGeneratingButton,
  searchCompletedButton,
  searchClickHandler,
  searchUnlockButton,
  pmaxUnlockButton,
  disableConfirm=() => true,
  handleConfirm=() => {}
}) => {
  const renderButtonBasedOnSearchStatus = () => {
    if (searchGeneratingButton) return <GeneratingButton />;
    else if (searchOngoingButton) {
      // dispatch(getsearchStrategies());
      return <OngoingButton />;
    } else if (searchCompletedButton) return <CompletedButton />;
    else if (searchUnlockButton) 
      return <UnlockButton />;
    else
      <UnlockButton />
  };
  const [isBudgetModal, setBudgetModal] = useState(true);


  const renderButtonBasedOnStatusPMax = () => {
    if (pmaxGeneratingButton) return <GeneratingButton />;
    else if (pmaxOngoingButton) {
      // dispatch(getPmaxStrategies());
      return <OngoingButton />;
    } else if (pmaxCompletedButton) return <CompletedButton />;
    else if (pmaxUnlockButton)
      return <UnlockButton />;
      else
        return <UnlockButton />;
  };

  return (
    <>
      <div className="google-campaign-selector-container">
        <div
          className="google-campaign-selector__header"
          style={{
            marginTop: 32,
            marginBottom: 98,
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 25,
              lineHeight: "62px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "#170F49",
            }}
          >
            Google ads campaign
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "26px",
              textAlign: "center",
              color: "#6F6C90",
            }}
          >
            {`Please select the type of campaign you'd like to create a ${stage} for.`}
          </Typography>
        </div>
        <div className="google-campaign-selector__cards">
          <div className="google-campaign-selector__card"
            onClick={searchClickHandler}
          >
            <div className="google-campaign-selector__header">
              <div className="google-campaign-selector__image">
                <img src={googleIcon} alt="google-icon" />
              </div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "24px",
                  color: "#2E3044",
                }}
              >
                Google Search
              </Typography>
            </div>
            <div className="google-campaign-selector__image-view">
              <img src={googleSearchView} alt="google-search" />
            </div>
            {/* <CompletedButton /> */}
            {renderButtonBasedOnSearchStatus()}
          </div>
          <div
            className="google-campaign-selector__card"
            onClick={pmaxClickHandler}
          >
            <div className="google-campaign-selector__header">
              <div className="google-campaign-selector__image">
                <img src={googleIcon} alt="google-icon" />
              </div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "24px",
                  color: "#2E3044",
                }}
              >
                Google Performance Max
              </Typography>
            </div>
            <div className="google-campaign-selector__image-view">
              <img
                src={googlePmaxView}
                alt="google-search"
                style={{
                  width: 135,
                  height: 109,
                }}
              />
            </div>
            {renderButtonBasedOnStatusPMax()}
          </div>
          <div className="google-campaign-selector__card">
            <div className="google-campaign-selector__header">
              <div className="google-campaign-selector__image">
                <img src={googleIcon} alt="google-icon" />
              </div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "24px",
                  color: "#2E3044",
                }}
              >
                Google Display
              </Typography>
            </div>
            <div className="google-campaign-selector__image-view">
              <img
                src={googleDisplayView}
                alt="google-search"
                style={{
                  width: 135,
                  height: 109,
                }}
              />
            </div>
            <div
              style={{
                width: 177,
                height: 53,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: "20px",
                  color: "#0869FB",
                }}
              >
                Coming Soon
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="footer_con" style={{ justifyContent: "end" }}>
          <Button
            disabled={disableConfirm()}
            style={{
              width: "109px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textTransform: "capitalize",
              background: "#0869FB",
              opacity: disableConfirm() ? 0.4 : 1
            }}
            onClick={() => handleConfirm()}
          >
            Confirm
          </Button>
        </div>

      </div>
      {isBudgetModal && <BudgetModal/>}
    </>
  );
};

export default GoogleCampaignSelector;
