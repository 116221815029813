import { useDispatch, useSelector } from 'react-redux';
import CommonAuditAd from './CommonAuditAd';
import { updateFormReducer } from '../../../api/accountManager/forms/slice';
import { BACKEND_STATUS, isPlatformSelectedHDM } from '../../../utils/accountManagerUtil';
import { onNextHDM } from '../GoToNextCompoButton';
import { goBackHDMAmCompo } from '../../../api/HDMAM/componentTracking/action';

export default function HDMAuditAd() {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.hdmFormAm);
  const onboardingInfo = useSelector((state) => state.hdmClient.onboardingInfo);

  const onClick = () => {
    dispatch(updateFormReducer({
      accountAnalysis: {
        ...form.accountAnalysis,
        status: BACKEND_STATUS.ONGOING,
      },
    }))
  }

  const nextDisable = () => {
    return isPlatformSelectedHDM(form, 'google-ads') !== -1 && form.auditInfo.status !== BACKEND_STATUS.COMPLETED
  };

  const onBack = () => {
    dispatch(goBackHDMAmCompo());
  };
  
  return (
    <CommonAuditAd
      onboardingInfo={onboardingInfo}
      onClick={onClick}
      nextDisable={nextDisable}
      onNext={() => onNextHDM(dispatch)}
      onBack={onBack}
    />
  )
}