import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import {Auth0Provider} from "@auth0/auth0-react";
import Hotjar from '@hotjar/browser';

const siteId = process.env.REACT_APP_HOTJAR_SITE_ID;
const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

Hotjar.init(siteId, hotjarVersion);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        // domain='auth-dev.pixis.ai'
        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        // clientId='5MhttAq18ee57q5JVcQLwrdgjg9KQfWO'
        authorizationParams={{
            redirect_uri: window.location.origin,
            // redirect_uri: 'http://localhost:3000',
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            // audience: 'https://cross-platform-dev.us.auth0.com/api/v2/',
            scope: process.env.REACT_APP_AUTH0_SCOPE
            // scope: "read:current_user update:current_user_metadata"
        }}>
        <Provider store={store}>
            <PersistGate loading={<>Loading...</>} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </Auth0Provider>
);

reportWebVitals();
