import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openHDMClientView } from "../../../api/HDMAM/componentTracking/action";
import {
  goBackHDMAmCompo,
  updateCompoReduxHDMAM,
} from "../../../api/HDMAM/componentTracking/action";
import { updateQuestionier } from "../../../api/HDMClient/action";
import { goToSpecificCompoHDMClient } from "../../../api/HDMClient/componentTracking/action";
import { getHDMAMClientDetailsByFormId } from "../../../api/HDMClient/saga";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import {
  DB_KEY_MAP_ABOUT_BUSSINESS,
  DB_KEY_MAP_BRAND_MESSAGING,
  DB_KEY_MAP_CLIENT_ACCESS,
  DB_KEY_MAP_CREATIVE_MONTAGE,
  DB_KEY_MAP_FACEBOOK_TARGETING,
  DB_KEY_MAP_GOOGLE_TARGETING,
} from "../../../utils/dbKeyMapping";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import "./HDMPreview.css";

const QuestionierPreviewAM = () => {
  const dispatch = useDispatch();
  const clientAccess = useSelector(
    (state) => state.hdmClient.onboardingInfo.clientAccess
  );
  const brandMessaging = useSelector(
    (state) => state.hdmClient.onboardingInfo.brandMessaging
  );
  const facebookTargeting = useSelector(
    (state) => state.hdmClient.onboardingInfo.facebookTargeting
  );
  const googleTargeting = useSelector(
    (state) => state.hdmClient.onboardingInfo.googleTargeting
  );
  const aboutBusiness = useSelector(
    (state) => state.hdmClient.onboardingInfo.aboutBusiness
  );
  const creativeAndMontageAds = useSelector(
    (state) => state.hdmClient.onboardingInfo.creativeAndMontageAds
  );
  const platforms=clientAccess?.platforms;

  const mappedKey = {
    keyCompetitors: "name",
    advertisingLocations: "name",
    activitiesInterest: "name",
    platforms: "platform",
  };

  const desiredData = (key, value) => {
    if(key === "keyCompetitors"){
      let compNameValue = "";
      value.map((key, index) => {
        compNameValue += "(" + key.name + " => " + key.website + ")" + ",";
      });
      return compNameValue.slice(0, compNameValue.length - 1);
    }
    if (key === "productandservice") {
      let str = "";
      value.forEach(({ name, description }, index) => {
        if(name.length !== 0 || description.length !== 0){
           str += "(name=>" + name + ",description=>" + description + "),";
        }
      });
      return str.length !== 0 ? str.substring(0,str.length-1) : "";
    }
    const tempArr = value.map((infoType) => {
      if (typeof infoType === "string") {
        return infoType;
      }
      return infoType[mappedKey[key]];
    });
    return tempArr.join(",");
  };

  useEffect(() => {
    if (getLocalStorage("amSelectedFormId")) {
      getHDMAMClientDetailsByFormId(getLocalStorage("amSelectedFormId")).then(
        (res) => {
          dispatch(updateQuestionier("UPDATE_WHOLE_HDM_CLIENT_FORMID", res));
          dispatch({
            type: "UPDATE_WHOLE_HDM_AM_DETAILS",
            payload: res,
          });
          dispatch({
            type: "UPDATE_WHOLE_HDM_CLIENT_FORMID",
            payload: res,
          });
        }
      );
    }
  }, []);

  const getMinMaxAgeRange = (fbTargetingData = {}) => {
    return `${fbTargetingData?.minAgeRange}-${fbTargetingData?.maxAgeRange}`;
  }

  const strVal = (str,key) => {
    if(["averageOrderValue","cogsPerAov","shippingCostPerAov","breakEvenPoint","fbCamaignBudget",
          "googleCamaignBudget","tiktokCamaignBudget","monthlyRevenue","monthlyRevenueGoal1Year",
          "monthlyRevenueGoal3Months","monthlyRevenueGoal6Months"].includes(key)){
      return "$"+str;
    }else{
      return str;
    }
  }
  console.log("platforms",platforms)
  return (
    <div
    // style={{ width: '98%', MaxHeight: '98vh', background: 'white', borderRadius:8 ,overflow:'auto'}}
    >
      <div className="top_head">
        {/* <div className="edit">
          <button className="btn"></button>
        </div> */}

        <div className="resp">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <PrimaryButton
              title="Edit Responses"
              onClick={() => {
                dispatch(openHDMClientView());
                dispatch(goToSpecificCompoHDMClient(1));
              }}
              icon="edit"
              type="bordered"
            />
          </div>

          <h3 className="preview_heading">PREVIEW</h3>
          <h1 className="preview_header2">CLIENT ONBOARDING FORM</h1>
          <span
            style={{
              color: "#6F6C90",
              textAlign: "center",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "30px",
              color: "#6F6C90",
            }}
          >
            Please review your responses carefully, you will not be able to edit
            them later.{" "}
          </span>
        </div>
      </div>
      <div className="">
        <div className="accordian">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight={600}>Client & Access</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(clientAccess ? clientAccess : {})?.map(
                  ([key, value], index) => {
                    return (
                      DB_KEY_MAP_CLIENT_ACCESS[key] && (
                        <div className="tag">
                          <div className="content">
                            <span>{DB_KEY_MAP_CLIENT_ACCESS[key]} : </span>
                            {typeof value != "boolean" ? (
                              <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value?.length !== 0 ? value : ""}</a>
                            ) : (
                              <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? "Yes" : "NO"}</a>
                            )}
                          </div>
                        </div>
                      )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Brand / Messaging</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(brandMessaging ? brandMessaging : {})?.map(
                  ([key, value], index) => {
                    return (
                      DB_KEY_MAP_BRAND_MESSAGING[key] && (
                        <div className="tag">
                          <div className="content">
                            <span>{DB_KEY_MAP_BRAND_MESSAGING[key]} : </span>
                            {/* href={"/client/" + key} */}
                            <a>
                              {Array.isArray(value) ? (
                                <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</a>
                              ) : typeof value != "boolean" ? (
                                <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value?.length !== 0 ? value : ""}</a>
                              ) : (
                                <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? "Yes" : "NO"}</a>
                              )}
                            </a>{" "}
                          </div>
                        </div>
                      )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {platforms?.findIndex(platform => platform.platform === "facebook-ads") !== -1 && <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Facebook Targeting</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(facebookTargeting ? facebookTargeting : {})?.map(
                  ([key, value], index) => {
                    return typeof value == 'boolean' &&
                      key.toLowerCase() !== 'status' ? (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_MAP_FACEBOOK_TARGETING[key]} :</span>
                          <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'No'}</a>
                        </div>
                      </div>
                    ) : (
                      key.toLowerCase() !== 'status' &&
                        DB_KEY_MAP_FACEBOOK_TARGETING[key] && (
                          <div className='tag'>
                            <div className='content'>
                              <span>{DB_KEY_MAP_FACEBOOK_TARGETING[key]} : </span>
                              {/* href={"/client/" + key} */}
                              <a>
                                {key === "minAgeRange" ? getMinMaxAgeRange(facebookTargeting) :Array.isArray(value) ? (
                                  <span style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</span>
                                ) : typeof value != 'boolean' ? (
                                  <a  style={{ color: "#619BF3",fontWeight:"bolder" }}>
                                    {value?.length !== 0
                                      ? strVal(value, key)
                                      : ''}
                                  </a>
                                ) : (
                                  <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'NO'}</a>
                                )}
                              </a>
                            </div>
                          </div>
                        )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>}
        {platforms?.findIndex(platform => platform.platform === "google-ads") !== -1 && <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Google Targeting</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(googleTargeting ? googleTargeting : {})?.map(
                  ([key, value], index) => {
                    return typeof value == 'boolean' &&
                      key.toLowerCase() !== 'status' ? (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_MAP_GOOGLE_TARGETING[key]} :</span>
                          <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'No'}</a>
                        </div>
                      </div>
                    ) : (
                      key.toLowerCase() !== 'status' &&
                        DB_KEY_MAP_GOOGLE_TARGETING[key] && (
                          <div className='tag'>
                            <div className='content'>
                              <span>{DB_KEY_MAP_GOOGLE_TARGETING[key]} : </span>
                              {/* href={"/client/" + key} */}
                              <a>
                                {Array.isArray(value) ? (
                                  <span style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</span>
                                ) : typeof value != 'boolean' ? (
                                  <a  style={{ color: "#619BF3",fontWeight:"bolder" }}>
                                    {value?.length !== 0
                                      ? strVal(value, key)
                                      : ''}
                                  </a>
                                ) : (
                                  <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'NO'}</a>
                                )}
                              </a>
                            </div>
                          </div>
                        )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>}
        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>About Your Business</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(aboutBusiness ? aboutBusiness : {})?.map(
                  ([key, value], index) => {
                    return (
                      DB_KEY_MAP_ABOUT_BUSSINESS[key] && (
                        <div className="tag">
                          <div className="content">
                            <span>{DB_KEY_MAP_ABOUT_BUSSINESS[key]} : </span>
                            {/* href={"/client/" + key} */}
                            {Array.isArray(value) ? (
                              <span style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</span>
                            ) : typeof value != "boolean" ? (
                              <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value?.length !== 0 ? strVal(value,key) : ""}</a>
                            ) : (
                              <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? "Yes" : "NO"}</a>
                            )}
                          </div>
                        </div>
                      )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Creative & Montage Ad</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(
                  creativeAndMontageAds ? creativeAndMontageAds : {}
                )?.map(([key, value], index) => {
                  return (
                    DB_KEY_MAP_CREATIVE_MONTAGE[key] && (
                      <div className="tag">
                        <div className="content">
                          <span>{DB_KEY_MAP_CREATIVE_MONTAGE[key]} : </span>
                          {/* href={"/client/" + key} */}
                          {Array.isArray(value) ? (
                            <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</a>
                          ) : typeof value != "boolean" ? (
                            <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value?.length !== 0 ? value : ""}</a>
                          ) : (
                            <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? "Yes" : "NO"}</a>
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button
            className="back"
            onClick={() => {
              dispatch(goBackHDMAmCompo());
            }}
          >
            BACK
          </button>
          <PrimaryButton
            title="Next"
            disabled={
              creativeAndMontageAds &&
              !creativeAndMontageAds.status === "COMPLETED"
            }
            onClick={() => {
              dispatch(updateCompoReduxHDMAM());
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default QuestionierPreviewAM;
