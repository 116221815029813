import { useDispatch, useSelector } from 'react-redux';
import {
  BACKEND_STATUS,
  accountManagerIdsHDM,
  componentWithIndexHDMAm
} from "../../../utils/accountManagerUtil";
import CommonReviewPoints from './CommonReviewPoints';
import {
  goToSpecificCompoHDMAM,
  updateCompoReduxHDMAM,
} from "../../../api/HDMAM/componentTracking/action";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";

export default function HDMReviewPoints() {
  const dispatch = useDispatch()
  
  const form = useSelector((store) => store.hdmFormAm);

  const onClick = () => {
    if (
      (form?.onboardingInfo?.clientAccess?.platforms || []).find(
        (pf) => pf.platform === "facebook-ads"
      )
    ) {
      if (form?.fbAdsAccountId) {
        dispatch(
          goToSpecificCompoHDMAM(
            componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_ACCOUNT]
          )
        );
      } else {
        dispatch(updateCompoReduxHDMAM());
      }
    } else {
      dispatch(
        updateFormReducer({
          accountAnalysis: {
            ...form.accountAnalysis,
            status: BACKEND_STATUS.ONGOING,
          },
        })
      );
      dispatch(
        goToSpecificCompoHDMAM(
          componentWithIndexHDMAm[
            accountManagerIdsHDM.ACCOUNT_ANNALYSIS_GOOGLE
          ]
        )
      );
    }
  }

  return (
    <CommonReviewPoints
      form={form}
      onClick={onClick}
    />
  )
}