import PrimaryButton from '../ButtonPrimary/Buttons';
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import readyGif from "../../assets/images/infoIcon.svg";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 580,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  height: '340px',
  borderRadius: '15px',
};

const IntakeFormSure = ({ modal, setModal,publish }) => {
  return (
    <Modal
      open={modal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
          <div className="status_popup_layout">
            <div style={{transform:"translateY(10px)"}}><img src={readyGif} /></div>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{transform:"translateY(50px)"}}>
              Are you sure you want to Proceed?
            </Typography>
            <div className="action_popup" style={{transform:"translateY(80px)"}}>
              <PrimaryButton title='Go Back' type="bordered" onClick={()=>setModal(!modal)}/>
              <PrimaryButton title='PUBLISH' onClick={()=>publish()}/>
            </div>
            <a className="close_button" onClick={()=>setModal(!modal)}><span></span></a>
          </div>
        </Box>
    </Modal>
  );
};

export default IntakeFormSure;
