import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";

import {
  goBackHDMAmCompo,
  goToSpecificCompoHDMAM
} from "../../../api/HDMAM/componentTracking/action";
import PrimaryButton from "../../ButtonPrimary/Buttons";

import { getChangesSuggestedObj } from "../../../utils/accountManagerUtil";

const SuggestChangesHDMDeck = () => {
  const dispatch = useDispatch();
  const [suggestChanges, setSuggestChanges] = useState({});
  const form = useSelector((store) => store.hdmFormAm);

  useEffect(() => {
    if (form) {
      setSuggestChanges(getChangesSuggestedObj(form.strategyIterations));
    }
  }, [form]);

  return (
    <div
      style={{
        marginTop: "2rem",
        marginLeft: "2rem",
      }}
    >
      <Typography
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 20,
          color: "#242731",
          marginBottom: "2rem",
        }}
      >
        Suggested Changes
      </Typography>

      <div
        style={{
          // display: "flex",
          alignItems: "center",
          gap: "3rem",
          marginBottom: "2rem",
        }}
      >
        {Object.keys(suggestChanges).map((change, idx) => {
          console.log(suggestChanges, idx, change);
          return (
            <div key={idx}>
              <Typography>{change}</Typography>
              <Typography
                style={{
                  background: "#FAFAFA",
                  borderRadius: 8,
                  padding: "0.5rem",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  width: 800,
                }}
              >
                {suggestChanges[change]}
              </Typography>
            </div>
          );
        })}
      </div>
      <div className="preview_footer">
        <button
          className="back"
          onClick={() => {
            dispatch(goBackHDMAmCompo());
          }}
        >
          BACK
        </button>
        <PrimaryButton
          onClick={() => {
            dispatch(
              goToSpecificCompoHDMAM(24)
            );
          }}
          title={`Go BACK to Strategy Deck`}
        />
      </div>
    </div>
  );
};

export default SuggestChangesHDMDeck;
