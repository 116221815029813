export const clientObjKeys = {
  onboardingInfo: {
    status: 'PENDING',
    personalInfo: {
      status: 'PENDING',
      company: '',
      industry: '',
      companyEmail: '',
      companyWebsite: '',
      companyAddress: '',
      companyMailingAddress: '',
      companyPhone: '',
      primaryContactName: '',
      primaryContactRole: '',
      primaryContactNumber: '',
      primaryContactEmail: '',
    },
    platformInfo: {
      status: 'PENDING',
      crm: [''],
      callTracking: '',
      leadSource: [''],
      competitors: [
        {
          name: '',
          website: '',
        },
      ],
      platforms: [
        /*{
                "platform": "",
                "spends": 0,
                "cpa": 0,
                "currency": ""
              }*/
      ],
      brandDescription: '',
    },
    growthGoal: {
      status: 'PENDING',
      goals: '',
      notes: '',
    },
    facebookTargeting: {
      status: 'PENDING',
      locationOptions: [],
      interestsOptions: [],
      minAgeRange: 0,
      maxAgeRange: 0,
      gender: '',
      advertisingLocations: [],
      activitiesInterest: [],
      excludes: '',
      formalCustomerPersonas: [],
      otherCharacteristics: '',
    },
    aboutBusiness: {
      status: 'PENDING',
      brandsInterests: ['', '', '', ''],
    },
  },
  intakeInfo: {
    status: 'PENDING',
    productServiceInfo: {
      status: 'PENDING',
      leadEmailAddress: '',
      descriptions: [
        {
          name: '',
          description: '',
        },
      ],
      brandDescription: '',
      appRelatedService: true,
      profitMargin: '',
      avgClosingPercent: '',
      sessionality: '',
      targetCpa: 0,
      brandServiceKeywords: [''],
    },
    audiences: {
      status: 'PENDING',
      minAge: '',
      maxAge: '',
      location: [
        {
          city: '',
          zipCode: '',
        },
      ],
      gender: '',
      advertiseLocation: '',
      maritalStatus: [''],
      customerCollar: '',
      activitiesInterest: '',
      excludeAudiences: '',
      customerIncomeRange: [''],
      geoTargets: [],
      locationOptions: [],
      customerEducation: [''],
      customerEmployment: [''],
      formalCustomerPersona: [],
    },
    brandAssets: {
      status: 'PENDING',
      brandingStyleGuide: [],
      companyLogo: '',
      companyClientLogo: '',
      companyClientWebsite: '',
      awards: [],
      productImages: [],
      brandingMaterials: [],
    },
    customerTestimonial: {
      status: 'PENDING',
      testimonial1: [],
      testimonial2: [],
      testimonial3: [],
    },
    preferences: {
      status: 'PENDING',
      monthlyAdSpend: 0,
      leadPreferences: '',
      trackingNumberDisplay: '',
      handleDepletedAccountBalance: '',
      competitors: [
        {
          name: '',
          website: '',
        },
      ],
    },
  },
  accessSharing: {
    status: 'PENDING',
    updatedOn: '2023-03-06T17:14:43.218Z',
    completedOn: '2023-03-06T17:14:43.218Z',
    fbAccess: {
      page: {
        status: 'VERIFYING',
        accountId: '',
      },
      adAccount: {
        status: 'VERIFYING',
        accountId: '',
      },
      catalog: {
        status: 'VERIFYING',
        accountId: '',
      },
      pixel: {
        status: 'VERIFYING',
        accountId: '',
      },
    },
    googleAccess: {
      googleAnalytics: {
        status: 'VERIFYING',
        accountId: '',
      },
      googleAdAccount: {
        status: 'VERIFYING',
        accountId: '',
      },
      googleMerchant: {
        status: 'VERIFYING',
        accountId: '',
      },
      googleAndShopifyLinked: {
        status: 'VERIFYING',
        accountId: '',
      },
    },
    tiktokAccess: {
      tiktokAdsManager: {
        status: 'VERIFYING',
        accountId: '',
      },
      tiktokPixel: {
        status: 'VERIFYING',
        accountId: '',
      },
    },
    shopifyAccess: {
      fbSalesChannel: {
        status: 'VERIFYING',
        accountId: 'string',
      },
      tiktokSalesChannel: {
        status: 'VERIFYING',
        accountId: '',
      },
    },
  },
  companyInfo: {
    status: 'PENDING',
    name: '',
    industry: '',
    clientId: '',
    mobileNumber: '',
    fullName: '',
    targetCpa: '',
    companyLogo: '',
    proposerName: '',
    signature: '',
    googleAdsManaged: false,
    fbAdsManaged: false,
    implementCro: true,
  },
  sowPricingInfo: {
    status: 'PENDING',
    googleAdsInfo: {
      status: 'PENDING',
      accountOperations: ['Build'],
      displayAdCampaigns: '',
      shoppingAdCampaigns: '',
      localServiceAdCampaigns: '',
      videoAdCampaigns: '',
      callTrackingAdCampaigns: '',
    },
    facebookAdsInfo: {
      status: 'PENDING',
      accountOperations: ['Build'],
      fbIgAdCampaigns: '',
      newAudienceCreation: '',
      customEvents: '',
      automatedRules: '',
      utmTrackingTemplates: '',
    },
    croInfo: {
      status: 'PENDING',
      landingPageDesign: '',
      thankYouPage: '',
      wedgeMethodDesign: '',
      popDesign: '',
      stickyBarDesign: '',
      heatmapping: true,
      userRecordings: true,
      userSurveys: true,
    },
    clientPricing: {
      status: 'PENDING',
      adSpendRange: {
        minSpend: 0,
        maxSpend: 0,
      },
      currency: '',
      basePricePerMonth: 0,
      basePercentPerMonth: 0,
      baseServices: [
        {
          service: '',
          quantity: '',
        },
      ],
      addonServices: [
        {
          service: '',
          quantity: '',
          price: 0,
        },
      ],
    },
  },
  proposalIterations: [],
  googleSearchAdsStrategyInfo: {
    status: 'PENDING',
    brandCampaign: {
      status: 'PENDING',
      budget: '',
      adsets: [
        {
          name: '',
          keywords: [''],
          headlines: [],
          descriptions: [],
        },
      ],
    },
    competitorCampaign: {
      status: 'PENDING',
      budget: '',
      adsets: [
        {
          name: '',
          keywords: [''],
          headlines: [],
          descriptions: [],
        },
      ],
    },
    serviceCampaign: {
      status: 'PENDING',
      budget: '',
      adsets: [
        {
          name: '',
          keywords: [''],
          headlines: [],
          descriptions: [],
        },
      ],
    },
  },
  fbAdsStrategyInfo: {
    status: 'PENDING',
    topOfTheFunnelLookalike: {
      status: 'PENDING',
      budget: 0,
      adsets: [],
    },
    topOfTheFunnelInterest: {
      status: 'PENDING',
      budget: 0,
      adsets: [],
    },
    middleOfTheFunnel: {
      status: 'PENDING',
      budget: 0,
      adsets: [],
    },
    bottomOfTheFunnel: {
      status: 'PENDING',
      budget: 0,
      adsets: [],
    },
  },
  campaignInfo: {
    status: 'PENDING',
    brandCampaign: {
      status: 'PENDING',
      name: '',
      objective: '',
      campaignType: '',
      bidding: '',
      locations: [],
      budget: 0,
      conversionGoals: [],
      waysToReachConversionGoals: [],
      networks: [],
      languages: [],
      finalUrl: '',
      trackingTemplate: '',
      adsets: [],
    },
    competitorCampaign: {
      status: 'PENDING',
      name: '',
      objective: '',
      campaignType: '',
      bidding: '',
      locations: [],
      budget: 0,
      conversionGoals: [],
      waysToReachConversionGoals: [],
      networks: [],
      languages: [],
      finalUrl: '',
      trackingTemplate: '',
      adsets: [],
    },
    serviceCampaign: {
      status: 'PENDING',
      name: '',
      objective: '',
      campaignType: '',
      bidding: '',
      locations: [],
      budget: 0,
      conversionGoals: [],
      waysToReachConversionGoals: [],
      networks: [],
      languages: [],
      finalUrl: '',
      trackingTemplate: '',
      adsets: [],
    },
  },
  strategyIterations: [],
  gAdsCustomerId: '',
  onboardingFormCompleted: true,
  published: true,
  clientGoogleAdsInfo: {
    customerId: '',
    customerName: '',
    loginCustomerId: '',
    loginCustomerName: '',
  },
  auditInfo: {
    accountAuditInfo: {
      currentMonthlyRevenue: '',
      spend: '',
      historicalAvgPlatformRoas: '',
      historicalAvgBlendedRoas: '',
    },
  },
};

export const GET_PERSONAL_INFO_CLIENT = 'GET_PERSONAL_INFO';
export const GET_PLATFORM_INFO_CLIENT = 'GET_PLATFORM_INFO';
export const GET_GROWTH_GOAL_CLIENT = 'GET_GROWTH_GOAL';
export const GET_PRODUCT_INFO_INTAKE_FORM = 'GET_PRODUCT_INFO_INTAKE_FORM';
export const GET_PREFERENCES_INTAKE_FORM = 'GET_PREFERENCES_INTAKE_FORM';
export const GET_AUDIENCE_INTAKE_FORM = 'GET_AUDIENCE_INTAKE_FORM';
export const GET_AUDIENCE_BRAND_ASSETS_FORM = 'GET_AUDIENCE_BRAND_ASSETS_FORM';

export const mappedReduxType = {
  ['personalInfo']: GET_PERSONAL_INFO_CLIENT,
  ['platformInfo']: GET_PLATFORM_INFO_CLIENT,
  ['growthGoal']: GET_GROWTH_GOAL_CLIENT,
  ['productServiceInfo']: GET_PRODUCT_INFO_INTAKE_FORM,
  ['preferences']: GET_PREFERENCES_INTAKE_FORM,
  ['audiences']: GET_AUDIENCE_INTAKE_FORM,
  ['brandAssets']: GET_AUDIENCE_BRAND_ASSETS_FORM,
};

export const SAGA_UPDATE_CLIENT_DETAILS = 'SAGA_UPDATE_CLIENT_DETAILS';
export const GET_CLIENT_DETAILS_BY_FORM_ID = 'GET_CLIENT_DETAILS_BY_FORM_ID';
export const UPDATE_WHOLE_CLIENT_FORMID = 'UPDATE_WHOLE_CLIENT_FORMID';
export const ADD_NEW_FIELD_IN_PLATFORM_COMPETITORS =
  'ADD_NEW_FIELD_IN_PLATFORM_COMPETITORS';
export const UPDATE_ARRAY_PLATFORMS_CHECKBOX =
  'UPDATE_ARRAY_PLATFORMS_CHECKBOX';
export const SET_VALUE_IN_PLATFORMS_CHECKBOX =
  'SET_VALUE_IN_PLATFORMS_CHECKBOX';

export const CLIENT_STATUS_SAVE_DB = {
  [10]: {
    parentKey: 'onboardingInfo',
    childKey: 'personalInfo',
  },
  [16]: {
    parentKey: 'onboardingInfo',
    childKey: 'platformInfo',
  },
  [18]: {
    parentKey: 'onboardingInfo',
    childKey: 'growthGoal',
  },
  [31]: {
    parentKey: 'intakeInfo',
    childKey: 'productServiceInfo',
  },
  [36]: {
    parentKey: 'intakeInfo',
    childKey: 'preferences',
  },
  [48]: {
    parentKey: 'intakeInfo',
    childKey: 'audiences',
  },
  [55]: {
    parentKey: 'intakeInfo',
    childKey: 'brandAssets',
  },
  [58]: {
    parentKey: 'intakeInfo',
    childKey: 'customerTestimonial',
  },
};

export const CITY_LOCATION_AUTOSUGGESTION_INPUT =
  'CITY_LOCATION_AUTOSUGGESTION_INPUT';
export const UPDATE_CITY_SUGGESTIONS = 'UPDATE_CITY_SUGGESTIONS';
export const LOCATION_ADDED_TO_FORM = 'LOCATION_ADDED_TO_FORM';
