import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../api";

const GET_GOOGLE_AUTH_INFO = ["google-auth/info"];
const GET_GOOGLE_AUTH_INF_NULL_DATA = null;
export const useGetGoogleAuthInfo = (options={}) => {
  return useQuery(GET_GOOGLE_AUTH_INFO, async () => {
    const response = await api.get("/google-auth/authenticated-account");
    return response.data; 
  }, {
    ...options,
    retry: false,
  });
}

const GET_FB_AUTH_INFO = ["fb-auth/info"];
const GET_FB_AUTH_INFO_NULL_DATA = null;
export const useGetFbAuthInfo = (options={}) => {
  return useQuery(GET_FB_AUTH_INFO, async () => {
    const response = await api.get("/facebook-auth/authenticated-account ");
    return response.data; 
  }, {
    ...options,
    retry: false,
  });
}

const GET_GOOGLE_ACCOUNTS_CHANNEL_QUERY_KEY = ["google-accounts/get"];
export const GET_GOOGLE_ACCOUNTS_CHANNEL_NULL_DATA = { customers: [] };
export const useGetGoogleAccountsChannel = (options = {}) => {
  return useQuery(
    GET_GOOGLE_ACCOUNTS_CHANNEL_QUERY_KEY,
    async () => {
      const response = await api.get("/g-ads/customers");
      return response.data;
    },
    {
      ...options,
      retry: false,
    }
  );
};

const GET_FB_ACCOUNTS_CHANNEL_QUERY_KEY = ["fb-accounts/get"];
export const GET_FB_ACCOUNTS_CHANNEL_NULL_DATA = { accounts: [] };
export const useGetFBAccountsChannel = (options = {}) => {
  return useQuery(
    GET_FB_ACCOUNTS_CHANNEL_QUERY_KEY,
    async () => {
      const response = await api.get("/facebook-ads/ad-accounts");
      return response.data;
    },
    {
      ...options,
      retry: false,
    }
  );
};

export const useDeleteGoogleChannel = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const response = await api.delete("/google-auth/authenticated-account");
      return response.data;
    },
    {
      ...options,
      onSuccess(...args) {
        queryClient.setQueryData(GET_GOOGLE_ACCOUNTS_CHANNEL_QUERY_KEY, GET_GOOGLE_ACCOUNTS_CHANNEL_NULL_DATA);
        queryClient.setQueryData(GET_GOOGLE_AUTH_INFO, GET_GOOGLE_AUTH_INF_NULL_DATA);
        options.onSuccess?.(...args);
      },
    }
  );
};

export const useDeleteFBChannel = (options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const response = await api.delete("/facebook-auth/authenticated-account");
      return response.data;
    },
    {
      ...options,
      onSuccess(...args) {
        queryClient.setQueryData(GET_FB_ACCOUNTS_CHANNEL_QUERY_KEY, GET_FB_ACCOUNTS_CHANNEL_NULL_DATA);
        queryClient.setQueryData(GET_FB_AUTH_INFO, GET_FB_AUTH_INFO_NULL_DATA);
        options.onSuccess?.(...args);
      },
    }
  );
};
