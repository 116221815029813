import React, { useState, useEffect } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Font,
  Svg,
} from '@react-pdf/renderer';
import page2 from '../../assets/images/LDProposal_images/page2.jpg';
import page1 from '../../assets/images/LDProposal_images/page1.jpg';
import page3 from '../../assets/images/LDProposal_images/page3.jpg';
import page4 from '../../assets/images/LDProposal_images/page4.jpg';
import page5 from '../../assets/images/LDProposal_images/page5.jpg';
import page6 from '../../assets/images/LDProposal_images/page6.jpg';
import page8 from '../../assets/images/LDProposal_images/page8.jpg';
import page9 from '../../assets/images/LDProposal_images/page9.jpg';
import page10 from '../../assets/images/LDProposal_images/page10.jpg';
import page11 from '../../assets/images/LDProposal_images/page11.jpg';
import page12 from '../../assets/images/LDProposal_images/page12.jpg';
import page13 from '../../assets/images/LDProposal_images/page13.jpg';
import page14 from '../../assets/images/LDProposal_images/page14.jpg';
import page15 from '../../assets/images/LDProposal_images/page15.jpg';
import page16 from '../../assets/images/LDProposal_images/page16.jpg';
import page17 from '../../assets/images/LDProposal_images/page17.jpg';
import page18 from '../../assets/images/LDProposal_images/page18.jpg';
import starter from '../../assets/images/LDProposal_images/starter.png';
import growth from '../../assets/images/LDProposal_images/growth.png';
import pro from '../../assets/images/LDProposal_images/pro.png';
import premium from '../../assets/images/LDProposal_images/premium.png';
import close from '../../assets/images/LDProposal_images/Ic/Close.png'
// import mLightFont from '../../assets/fonts/Montserrat-Light.ttf';
// import mRegularFont from '../../assets/fonts/Montserrat-Regular.ttf';
// import mMediumItalic from '../../assets/fonts/Montserrat-MediumItalic.ttf';
// import mMediumFont from '../../assets/fonts/Montserrat-Medium.ttf';
// import mSemiBoldFont from '../../assets/fonts/Montserrat-SemiBold.ttf';
// import mBoldFont from '../../assets/fonts/Montserrat-Bold.ttf';
// import mExtraBoldFont from '../../assets/fonts/Montserrat-Black.ttf';
// import StarLeft from '../../assets/images/LDProposal_images/star_left.png';
// import StarRight from '../../assets/images/LDProposal_images/star_right.png';
// import Penji from '../../assets/images/LDProposal_images/penji.jpeg';
// import BrightBox from '../../assets/images/LDProposal_images/brightbox.jpeg';
// import PenjiTag from '../../assets/images/LDProposal_images/penji_tag.png';
// import BrightTag from '../../assets/images/LDProposal_images/brightbox_tag.png';
// import OrderMark from '../../assets/images/LDProposal_images/ordermark.jpeg';
// import OrderTag from '../../assets/images/LDProposal_images/ordermark_tag.png';
// import GAds from '../../assets/images/LDProposal_images/google_ads.png';
// import Asana from '../../assets/images/LDProposal_images/asana.png';
// import People from '../../assets/images/LDProposal_images/expectation.png';
// import Speaker from '../../assets/images/LDProposal_images/speaker.png';
// import PPC from '../../assets/images/LDProposal_images/ppc_tag.png';
// import Network from '../../assets/images/LDProposal_images/networkppc_tag.png';
// import Contract from '../../assets/images/LDProposal_images/contractppc_tag.png';
// import SSL from '../../assets/images/LDProposal_images/spaceshipstar_left.png';
// import SSR from '../../assets/images/LDProposal_images/spaceshipstar_right.png';
// import UFO from '../../assets/images/LDProposal_images/spaceship.png';
// import ThankYou from '../../assets/images/LDProposal_images/thankyou_star.png';
// import Swydo from '../../assets/images/LDProposal_images/swydo.png';
// import Strategy from '../../assets/images/LDProposal_images/strategy.png';
// import Bing from '../../assets/images/LDProposal_images/paidsearch.png';
// import Social from '../../assets/images/LDProposal_images/paidsocial.png';
// import ArrowDown from '../../assets/images/LDProposal_images/arrow_down.png';
// import { Green_Checked_Icon as checkedIcon } from '../../assets/icons/common/common';
// import ArrowCurve from '../../assets/images/LDProposal_images/arrowcurve.png';
// import checkedTimeframeIcon from '../../assets/images/LDProposal_images/Ic/Close.png';
// import crossTimeframeIcon from '../../assets/images/LDProposal_images/Ic/Ic/Close.png';
// import { socialMediaDesignMonthsCount } from '../../utils/LDProposalUtils';
const styles = StyleSheet.create({
  pagebackground: {
    backgroundColor: '#0D1157',
  },
  thankyouMargin: {
    margin: 'auto',
  },
  tableShadow: {
    borderRadius: '50%',
    backgroundColor: 'radial-gradient(96% 96% at 70.4% 31.2%, #16009C 0%, rgba(0, 2, 16, 0) 100%)',
    boxShadow: '0px 4.11675px 4.11675px rgba(0, 0, 0, 0.25), 0px 68.0027px 1360.05px #2300F8, inset 0px 34.0013px 170.007px rgba(255, 255, 255, 0.58)',
    filter: 'blur(170.007px)'
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  row1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems : 'center',
    height : '18%',
    width : '100%',
    borderBottom: '1px solid gray'
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems : 'center',
    height : '25%',
    width: '100%',
    borderBottom: '1px solid gray'
  },
  bold: {
    fontWeight: 'bold',
  },
  header : {
    paddingBottom : '10px'
  }
  // So Declarative and unDRY 👌


  // projectScopeView: {
  //   paddingTop: 32,
  //   paddingBottom: 15,
  //   paddingLeft: 74,
  //   paddingRight: 74,
  // },
  // ppcPlanView: {
  //   paddingTop: 32,
  //   paddingBottom: 15,
  //   paddingLeft: 74,
  //   paddingRight: 74,
  // },
  // pageNumber: {
  //   fontSize: 12,
  //   color: 'grey',
  // },
  // bullet: {
  //   height: '100%',
  //   marginRight: 8,
  // },
  // leftRow: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   fontSize: 13,
  //   fontFamily: 'OpenSans',
  //   color: '#272727',
  //   letterSpacing: -0.13,
  //   lineHeight: 1.4,
  //   marginBottom: 6,
  // },
  // croLeftRow: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   fontSize: 13,
  //   fontFamily: 'OpenSans',
  //   color: '#272727',
  //   letterSpacing: -0.13,
  //   lineHeight: 1.4,
  //   marginBottom: 16,
  //   width: 210,
  // },
  // timeFrameView: {
  //   paddingTop: 32,
  //   paddingHorizontal: 28,
  // },
  // timeFrameHeadlineContainer: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   borderBottom: '1.5px solid #333333',
  //   paddingBottom: 3,
  // },
  // timeFrameTextHeading: {
  //   color: '#333333',
  //   fontFamily: 'Montserrat',
  //   fontWeight: 700,
  //   fontSize: 22,
  // },
  // timeFrameDataIndication: {
  //   fontSize: 8,
  //   fontFamily: 'Montserrat',
  //   fontWeight: 600,
  //   color: '#333333',
  // },
});

Font.register({
  family: 'Lato, sans-serif',
  fonts: [
    {
      // src: mLightFont,
      format: 'normal',
      fontWeight: 700,
    },
  ]
});
//     {
//       src: mRegularFont,
//       format: 'truetype',
//       fontWeight: 400,
//     },
//     {
//       src: mMediumItalic,
//       format: 'truetype',
//       fontWeight: 500,
//       fontStyle: 'italic',
//     },
//     {
//       src: mMediumFont,
//       format: 'truetype',
//       fontWeight: 500,
//     },
//     {
//       src: mSemiBoldFont,
//       format: 'truetype',
//       fontWeight: 600,
//     },
//     {
//       src: mBoldFont,
//       format: 'truetype',
//       fontWeight: 700,
//     },
//     {
//       src: mExtraBoldFont,
//       format: 'truetype',
//       fontWeight: 800,
//     },
//   ],
// });

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
      fontWeight: '700',
      fontStyle: 'normal',
    },
  ],
});

// const Starter = () => {
//   return <svg width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M6.55255 0.9375H26.0902L21.5632 7.13237H13.3431L6.55255 0.9375Z" fill="#7C89AC" />
//     <path d="M16.5595 6.89411L11.5559 2.72448L15.0107 0.9375H17.7508L21.8013 2.72448L16.5595 6.89411Z" fill="#BCC7E8" />
//     <path d="M6.54979 0.9375L3.80975 2.36709L7.62198 6.89411L11.3151 2.60535L6.54979 0.9375Z" fill="#BCC7E8" />
//     <path d="M26.0893 0.9375L29.1868 2.36709L25.3745 6.89411L21.6814 2.60535L26.0893 0.9375Z" fill="#BCC7E8" />
//     <path d="M7.62646 6.89404H16.5614V27.7422L7.62646 6.89404Z" fill="#7C89AC" />
//     <path d="M25.377 6.89404H16.5612L16.4421 27.7422L25.377 6.89404Z" fill="#54638B" />
//     <path d="M0 6.89404L16.5592 27.7422L7.62425 6.89404H0Z" fill="#95A3CB" />
//     <path d="M33 6.89404L16.4408 27.7422L25.3757 6.89404H33Z" fill="#95A3CB" />
//     <path d="M7.62446 6.89421H0L3.81223 2.36719L7.62446 6.89421Z" fill="#95A3CB" />
//     <path d="M25.3755 6.89421H33L29.1878 2.36719L25.3755 6.89421Z" fill="#95A3CB" />
//     <path d="M16.4422 6.89423H7.62646L11.3196 2.60547L16.4422 6.89423Z" fill="#95A3CB" />
//     <path d="M16.5612 6.89423H25.377L21.6839 2.60547L16.5612 6.89423Z" fill="#95A3CB" />
//   </svg>
// }

const LDGeneratePdf = ({
  proposal,
  dateOfProposal,
  mappedIndustry,
  selectedPricing,
  baseService,
  mappedAddons,
  totalAddonPrice,
  timeframeData,
}) => {
  // console.log(mappedIndustry);
  const data = [
    { name: 'Ad Spend Range' },
    { name: 'Monthly Fee' },
    { name: 'Number of Accounts per seat' },
    { name: 'Accounts Manager Expertise' },
    { name: 'Support Turn Around Time' },
    { name: 'Online Training Environmen' },
  ]


  return (
    <Document>
      {/* Intro Page  */}
      <Page style={styles.introView} size='A4' orientation='landscape'>
        {/* <View style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <View style={{
            width: '50%',
            paddingLeft: '25px'
          }}>
            <Image src={pixislogo} style={{
              width: '100%'
            }} />
            <Image src={afterlogoImage} style={{
              width: '100%'
            }} />
          </View>
          <View style={{
            position: 'relative',
            width: '60%'
          }}>
            <Image src={logosideimages} style={{
              position: 'absolute',
              bottom: '-50%',
              right: '0px',
              // zIndex: '1',
              width: '100%',
              height: '80%'
            }} />
          </View>
        </View> */}
        <Image src={page1} style={{ width: '100%', height: '100%' }} />
      </Page>

      <Page size='A4' orientation='landscape'>
        <Image src={page2} style={{ width: '100%', height: '100%' }} />
      </Page>

      <Page size='A4' orientation='landscape'>
        <Image src={page3} style={{ width: '100%' , height: '100%'}} />
      </Page>

      <Page size='A4' orientation='landscape'>
        <Image src={page4} style={{ width: '100%', height: '100%' }} />
      </Page>

      <Page size='A4' orientation='landscape'>
        <Image src={page5} style={{ width: '100%', height: '100%' }} />
      </Page>

      <Page size='A4' orientation='landscape'>
        <Image src={page6} style={{ width: '100%', height: '100%' }} />
      </Page>


      <Page size='A4' orientation='landscape'>
        <View style={{ width: '100%', height: '100%', backgroundColor: '#0D1157', position: 'relative' }}>
          <View style={{ display: 'flex', justifyContent: 'center ', width: '100%' }}>
            <Text style={{
              color: '#22CAFF',
              fontSize: '30px',
              width: '100%',
              textAlign: 'center',
              marginTop: '35px',
              marginBottom: '50px'
            }}>Pricing</Text></View>

          <View style={{ width: '100%', height: 'calc(100% - 125px)', paddingBottom: '69px', paddingLeft: '30px', paddingRight: '30px', zIndex: '1' }}>

            {/* table */}

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={{
                  width: '20%',
                }}></Text>
                <View style={{ width: '20%', display: 'flex', alignItems: 'center', flexDirection: 'row' , justifyContent : 'center'}}>
                  <Text style={{
                    fontWeight: '900',
                    fontSize: '14px',
                    color: '#91ACFA',
                    marginRight : '5px'
                  }}>Starter
                  </Text>
                  <Image src={starter} style={{ width: "20px", height: "15px", marginRight: '5px' }}/>
                  <Image src={close} style={{ width: "10px", height: "10px" }} />

                </View>
                <View style={{ width: '25%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                  <Text style={{
                    fontWeight: '900',
                    fontSize: '14px',
                    color: '#F9DA97',
                    marginRight: '5px'
                  }}>Growth</Text>
                  <Image src={growth} style={{ width: "20px", height: "15px", marginRight: '5px' }} />
                  <Image src={close} style={{ width: "10px", height: "10px" }} />

                </View>
                <View style={{ width: '20%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                  <Text style={{
                    fontWeight: '900',
                    fontSize: '14px',
                    color: '#0587FF',
                    marginRight: '5px'
                  }}>Pro</Text>
                  <Image src={pro} style={{ width: "20px", height: "15px", marginRight: '5px' }} />
                  <Image src={close} style={{ width: "10px", height: "10px" }} />
                </View>
                <View style={{ width: '20%', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                  <Text style={{
                    fontWeight: '900',
                    fontSize: '14px',
                    color: '#42D2FF',
                    marginRight: '5px'
                  }}>Premium</Text>
                  <Image src={premium} style={{ width: "20px", height: "15px", marginRight: '5px' }} />
                  <Image src={close} style={{ width: "10px", height: "10px" }} />

                </View>
              </View>
              <View style={{ width: '100%', height: '93%', background: 'rgba(6, 20, 35, 0.33)', border: '3px solid #3292D1', borderRadius: '12px', overflow: 'hidden', padding: '10px' }}>
               
                  <View style={styles.row1}>
                  <View style={{ width: '20%' }}>
                    <Text style={{
                      fontWeight: '500',
                      fontSize: '12px',
                      color: '#18DEE6',
                      marginLeft : '10px'
                    }}>Ad Spend Range</Text>
                    </View>
                  <View style={{ width: '20%'}}>
                    <Text style={{
                      textAlign: 'center',
                      fontWeight: '500',
                      fontSize: '12px',
                      color: '#F8F8F8',
                      
                    }}>0-10K</Text></View>
                  <View style={{ width: '20%'}}>
                    <Text style={{
                      textAlign: 'center',
                      fontWeight: '500',
                      fontSize: '12px',
                      color: '#F8F8F8',
                      
                    }}>10 - 25K</Text>
                    </View>
                  <View style={{ width: '20%' }}>
                    <Text style={{
                      textAlign: 'center',
                      fontWeight: '500',
                      fontSize: '12px',
                      color: '#F8F8F8',
                      
                    }}>25-110K</Text>
                    </View>
                  <View style={{ width: '20%'}}>
                    <Text style={{
                      textAlign: 'center',
                      fontWeight: '500',
                      fontSize: '12px',
                      color: '#F8F8F8',
                      
                    }}>75-150K</Text>
                    </View>
                  
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '18%',}}>
                  <View style={{ width: '20%' }}>
                  <Text style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#18DEE6',
                      marginLeft: '10px'
                  }}>Monthly Fee</Text>
                  </View>
                  <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                    
                  }}>$700 or 10%</Text>
                  </View>
                  <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                    
                  }}>$3500 or 10%</Text>
                  </View>
                  <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                    
                  }}>$5250 or 10%</Text>
                  </View>
                  <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                    
                  }}>$12,500 or 10%</Text>
                  </View>

                </View>
               <View style={{ width: '100%',height : '63%', backgroundColor: 'rgba(64, 228, 255, 0.15)', borderRadius: "16px", overflow: 'hidden', paddingBottom: '5px' }}>
                <View style={styles.row2}>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#18DEE6',
                    width: '120px',
                    marginLeft: '10px'
                  }}>Number of Accounts per seat</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                    marginRight : '15px'
                  }}>Shared till the datapoint requirement is met</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                       marginRight: '15px'
                  }}>Share with minimum 2 and maximum 6 others</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                    marginRight: '15px'
                  }}>Share with minimum 1 and maximum 4 others</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                        
                  }}>NA</Text>
                  </View>

                </View>
                <View style={styles.row2}>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#18DEE6',
                    width : '100px',
                    marginLeft: '10px'
                  }}>Accounts Manager Expertise</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                    
                  }}>Specialist</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                        
                  }}>Specialist</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                        
                  }}>Expert</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                        
                  }}>Expert</Text>
                  </View>

                </View>
                <View style={styles.row2}>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#18DEE6',
                    width: '120px',
                    marginLeft: '10px'
                  }}>Support Turn Around Time</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                      
                  }}>3 business days</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                      
                  }}>2 business days</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                      
                  }}>1 business day</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                      
                  }}>6 working hours</Text>
                  </View>

                </View>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '25%',
                  }}>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#18DEE6',
                    width: '90px',
                    marginLeft: '10px'
                  }}>Online Training Environment</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                      
                  }}>Central</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                      
                  }}>Central</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                      
                  }}>Hybrid</Text>
                  </View>
                    <View style={{ width: '20%' }}>
                  <Text style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#F8F8F8',
                      
                  }}>Dedicated</Text>
                  </View>

                </View>
             </View>
              </View>
            </View>

            {/* table End */}

          </View>

        </View>
      </Page >

      <Page Page size='A4' orientation='landscape' >
        <Image src={page8} style={{ width: '100%', height: '100%' }} />
      </Page >

      <Page Page style={styles.introView} size='A4' orientation='landscape' >
        <Image src={page9} style={{ width: '100%', height: '100%' }} />
      </Page >

      <Page Page size='A4' orientation='landscape' >
        <Image src={page10} style={{ width: '100%', height: '100%' }} />
      </Page >

      <Page size='A4' orientation='landscape'>
        <Image src={page11} style={{ width: '100%', height: '100%' }} />
      </Page>

      <Page size='A4' orientation='landscape'>
        <Image src={page12} style={{ width: '100%', height: '100%' }} />
      </Page>

      <Page size='A4' orientation='landscape'>
        <Image src={page13} style={{ width: '100%', height: '100%' }} />
      </Page>
      <Page size='A4' orientation='landscape'>
        <Image src={page14} style={{ width: '100%', height: '100%' }} />
      </Page>
      <Page size='A4' style={styles.pagebackground} orientation='landscape'>
        <Image src={page15} style={{ width: '100%', height: '100%' }} />
      </Page>
      <Page size='A4' style={styles.pagebackground} orientation='landscape'>
        <Image src={page16} style={{ width: '100%', height: '100%' }} />
      </Page>
      <Page size='A4' style={styles.pagebackground} orientation='landscape'>
        <Image src={page17} style={{ width: '100%', height: '100%' }} />
      </Page>
      <Page size='A4' orientation='landscape' >
        <Image src={page18} style={{ width: "100%", height: '100%' }} />
      </Page>
    </Document >
  );
};

export default LDGeneratePdf;
