import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useDispatch, useSelector } from "react-redux";
import {
  goBackReviewQuestionierAM,
  goToReviewQuestionierAM,
} from "../../../api/accountManager/componentTracking/action";
import { editicon as EDITICON } from "../../../assets/icons/icon";
import { headerTabsAccountManager, listOfIds } from "../../../utils/navbarUtil";
import PrimaryButton from "../ButtonPrimary/Buttons";
import "../../ClientDashboard/Questions/Preview.css";
import { DB_KEY_MAP_COMPANY } from "../../../utils/dbKeyMapping";

const QuestionierPreview = () => {
  const dispatch = useDispatch();
  const companyInform = useSelector(
    (state) => state.form?.form?.companyInfo || {}
  );
  const lastActiveComp = useSelector(
    (state) => state.amCurrentComp.lastActiveCompAm
  );
  const mappedKey = {
    competitors: "name",
    platforms: "platform",
  };

  return (
    <div
      style={{
        width: "98%",
        MaxHeight: "98vh",
        background: "white",
        borderRadius: 8,
        overflow: "auto",
      }}
    >
      <div className="top_head">
        <div className="edit">
          <button className="btn"></button>
        </div>

        <div className="resp">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <PrimaryButton
              title="Edit Responses"
              onClick={() => {
                dispatch(
                  goToReviewQuestionierAM({
                    currentCompAm: lastActiveComp,
                    currentLeftSidebarAm: listOfIds.proposalInputs,
                    currentHeaderTabAm:
                      headerTabsAccountManager["ONBOARDING FORM"],
                    lastActiveCompAm: 0,
                  })
                );
              }}
              icon="edit"
              type="bordered"
            />
          </div>

          <h3 className="preview_heading">PREVIEW</h3>
          <h1 className="preview_header2">VIEW AND EDIT YOUR RESPONSES</h1>
          <span
            style={{
              color: "#6F6C90",
              textAlign: "center",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "30px",
              color: "#6F6C90",
            }}
          >
            Please review your responses carefully, you will not be able to edit
            them later.{" "}
          </span>
        </div>
      </div>
      <div className="parent">
        <div className="accordian">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight={600}>Company Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(companyInform || {})?.map(
                  ([key, value], index) => {
                    return (
                      DB_KEY_MAP_COMPANY[key] && (
                        <div className="tag">
                          <div className="content">
                            <span>{DB_KEY_MAP_COMPANY[key]} : </span>
                            <a>{"" + value}</a>{" "}
                          </div>
                        </div>
                      )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button
            className="back"
            onClick={() => {
              dispatch(
                goBackReviewQuestionierAM({
                  currentComp: lastActiveComp,
                })
              );
            }}
          >
            BACK
          </button>
          <PrimaryButton title="PUBLISH" disabled />
        </div>
      </div>
    </div>
  );
};
export default QuestionierPreview;
