export const formStatus = {
    completed: "COMPLETED",
    approved: "APPROVED",
    generated: "GENERATED",
    changes_suggested: "CHANGES_SUGGESTED",
    changes_applied: "CHANGES_APPLIED",
    verifying: "VERIFYING",
    verifiying_complete_access: "VERIFYING_COMPLETE_ACCESS",
    read_access: "READ_ACCESS",
    complete_access: "COMPLETE_ACCESS",
    access_revoke: "ACCESS_REVOKED"
}

export const platformAccessKey = {
    GOOGLE_ACCESS : 'googleAccess',
    FACEBOOK_ACCESS: 'fbAccess',
    TIKTOK_ACCESS: 'tiktokAccess',
    SHOPIFY_ACCESS: 'shopifyAccess'
}
export const haveAccess = (accessSharing = {}, platformAccess) => {
    const accessObj = accessSharing[platformAccess];
    if(platformAccess === platformAccessKey.FACEBOOK_ACCESS && accessObj){
        return [formStatus.read_access, formStatus.verifiying_complete_access, formStatus.complete_access].includes(accessObj.adAccount.status)
    }
    if(platformAccess === platformAccessKey.GOOGLE_ACCESS && accessObj){
        return [formStatus.read_access, formStatus.verifiying_complete_access, formStatus.complete_access].includes(accessObj.googleAdAccount.status)
    }
    return false;
  }