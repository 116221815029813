import { useDispatch, useSelector } from "react-redux";
import { mappedComponentWithIndexHDM } from "../../utils/accountManagerUtil";
import { mappedComponentWithIndex } from "../../utils/HDMClientDashboardIdsUtil";
import {
  HDMAccountLeftSideBar,
  HDMHeaderTabs,
  headerNavbarHDMAM,
  listOfIds,
  listOfIdsHDM,
} from "../../utils/navbarUtil";
import "../HDMClientDashboard/HDMClientDashboard.css";
import HDMRenderCurrentComponent from "../HDMClientDashboard/HDMRenderCurrentComponent";
import RenderCurrentComponent from "./HDMAMRenderCurrentComponent";
import HeaderNavSteps from "./HeaderNavSteps/index";
import NavigationSteps from "./NavigationSteps/index";

const HDMAccountManager = () => {
  const dispatch = useDispatch();
  const amCurrentCompoToRender = useSelector(
    (state) => state.hdmAMCurrentComp.currentCompAm
  );
  const currentLeftSidebar = useSelector(
    (state) => state.hdmAMCurrentComp.currentLeftSidebarAm
  );
  const currentHeaderTab = useSelector(
    (state) => state.hdmAMCurrentComp.currentHeaderTabAm
  );
  const clientCurrentCompoToRender = useSelector(
    (state) => state.hdmClientCurrentComp.currentComp
  );
  const currentClientHeaderTab = useSelector(
    (state) => state.hdmClientCurrentComp.currentHeaderTab
  );
  const clientView = useSelector((state) => state.hdmAMCurrentComp.clientView);
  const hdAmForm = useSelector((state) => state.hdmFormAm);

  const getHeadBarsForStrategyCreationGoogle = (currHeadTab) => {
    if (
      [
        HDMHeaderTabs["BRANDCAMPAIGN"],
        HDMHeaderTabs["COMPETITORCAMPAIGN"],
        HDMHeaderTabs["SERVICECAMPAIGN"],
      ].includes(currHeadTab)
    ) {
      return listOfIdsHDM.STRATEGY_INPUT_SEARCH;
    }
    if (
      [
        HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_1],
        HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_2],
        HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_3],
      ].includes(currHeadTab)
    ) {
      return listOfIdsHDM.STRATEGY_INPUT_PMAX;
    }
  };

  console.log(
    "CURRENT HEADER TAB:",
    getHeadBarsForStrategyCreationGoogle(currentHeaderTab)
  );

  console.log("currentLeftSideBar", currentLeftSidebar);
  console.log(
    "headerNavBarHDM",
    Object.values(headerNavbarHDMAM[currentLeftSidebar] || {})
  );

  return (
    <main
      class={`${mappedComponentWithIndexHDM.get(
        amCurrentCompoToRender
      )} main_layout accountManger`}
    >
      <section className="nav_section">
        <NavigationSteps
          navigateSteps={HDMAccountLeftSideBar}
          active={currentLeftSidebar}
        />
      </section>
      <section
        style={{
          paddingBottom:
            [listOfIdsHDM.CampaignCreationGoogle, listOfIdsHDM.CampaignCreationFB].includes(
              currentLeftSidebar
            ) || clientView
              ? "0px"
              : "74px",
        }}
        className={`${
          currentLeftSidebar &&
          (clientView ? currentClientHeaderTab : currentHeaderTab) &&
          Object.values(headerNavbarHDMAM[currentLeftSidebar] || {}).length
            ? "top_nav_item"
            : ""
        } right_layout`}
      >
        <div className={`right_layout_inner`}>
          {currentLeftSidebar &&
            (clientView ? currentClientHeaderTab : currentHeaderTab) &&
            !(
              currentLeftSidebar === listOfIdsHDM.StrategyCreationGoogle &&
              hdAmForm?.googleAdsCronStatus !== "DONE"
            ) &&
            Object.values(headerNavbarHDMAM[currentLeftSidebar] || {})
              .length !== 0 && (
              <div className="top_nav_section">
                <div className="top_nav_inner">
                  <div
                    style={{
                      width: "100%",
                      height: "64px",
                      display: "flex",
                    }}
                  >
                    <HeaderNavSteps
                      headerNavList={
                        currentLeftSidebar === listOfIdsHDM.strategyInput
                          ? headerNavbarHDMAM[listOfIdsHDM.strategyInput][
                              getHeadBarsForStrategyCreationGoogle(
                                currentHeaderTab
                              )
                            ]
                          : headerNavbarHDMAM[currentLeftSidebar]
                      }
                      active={
                        clientView ? currentClientHeaderTab : currentHeaderTab
                      }
                      clientView={clientView}
                      clientCurrentCompoToRender={clientCurrentCompoToRender}
                    />
                  </div>
                </div>
              </div>
            )}
          <div
            className={`${
              clientView && clientCurrentCompoToRender <= 59
                ? "hdm-questionnaire"
                : ""
            } right_main_section`}
          >
            {clientView ? (
              <HDMRenderCurrentComponent
                currentCompoent={mappedComponentWithIndex.get(
                  clientCurrentCompoToRender
                )}
              />
            ) : (
              <RenderCurrentComponent
                currentCompoent={mappedComponentWithIndexHDM.get(
                  amCurrentCompoToRender
                )}
                hdAmForm={hdAmForm}
              />
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
export default HDMAccountManager;
