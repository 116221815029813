import { ready as readyGif } from '../../../assets/icons/proposal/proposal';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 584,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '0px 32px',
  height: '303px',
  borderRadius: '15px',
};
const ReferalSuccessModal = ({ email, isOpen, closeModal, setInviteEmail }) => {
  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Close
          onClick={() => {
            setInviteEmail('');
            closeModal(false);
          }}
          style={{
            position: 'absolute',
            top: '0.8rem',
            right: '0.8rem',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            gap: '0.5rem',
          }}
        >
          <div>
            <img src={readyGif} alt='' width={144} height={144} />
          </div>
          <div>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Invite Shared
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 16,
                color: '#6F6C90',
                textAlign: 'center',
              }}
            >
              An invite has been successfully shared to{' '}
              <span style={{ color: 'rgba(8, 105, 251, 1)' }}>{email}</span>
            </Typography>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ReferalSuccessModal;
