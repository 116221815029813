import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import noIreviewIcon from "../../../assets/icons/common/no_preview_icon.svg";
import { Tabs, useTabState, Panel } from "../../CustomTab/customTab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import crossIcon from "../../../components/PMaxStrategyCreation/pmaxIcons/cross-icon.svg";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import {
  getFormByFormIdReducer,
  updateFormReducer,
} from "../../../api/accountManager/forms/slice";
import { approveDeckApi } from "../../../api/client/saga";
import StrategyDeckApproval from "../../modals/StrategyDeckApproval";
import PEOPLE from "../../AccountManager/StrategyDeck/Vector.svg";
import Person from "../../AccountManager/StrategyDeck/person.svg";
import CAMERA from "../../../assets/icons/pmax_campaign.svg";
import { Typography } from "@mui/material";
import PMaxStrategyDeck from "../../AccountManager/StrategyDeck/PmaxStrategyDeck";
import moment from "moment";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import { api } from "../../../api";
import {
  BACKEND_STATUS,
  CRON_STATUS_BACKEND,
  isPlatformSelected,
  isPlatformSelectedHDM,
} from "../../../utils/accountManagerUtil";
import { createPmaxCampaign } from "../../../api/pmaxCampaign/slice";
import { PMaxCampaignName } from "../../../constants/LDConstants";
import { LDcomponentWithIndexClient, clientDashboardIds as clientDashboardIdsLD } from "../../../utils/clientDashboardIdsUtil";
import { goToSpecificCompoHDMClient } from "../../../api/HDMClient/componentTracking/action";
import { clientDashboardIds, componentWithIndexHDMClient } from "../../../utils/HDMClientDashboardIdsUtil";

const cn = (...args) => args.filter(Boolean).join(" ");

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button className={cn("tab", isActive && "active")} onClick={onClick}>
      {children}
    </button>
  );
};

const StrategyDeckGoogle = ({ agencyType = "LD" }) => {
  const dispatch = useDispatch();
  const [modalApprove, setModalApprove] = useState(false);
  const [totalBudget, setTotalBudget] = useState(0);
  const [brandCampaign, setBrandCampaign] = useState({});
  const [competitorCampaign, setCompetitorCampaign] = useState({});
  const [serviceCampaign, setServiceCampaign] = useState({});
  const publishedDate = (date) => moment(date).format("YYYY-MM-DD");
  const [clientForm, setClientForm] = useState({
    pmaxCAICronStatus: "PENDING",
    pmaxTAICronStatus: "PENDING",
    googlePmaxStrategy: { status: "ONGOING" },
  });
  const [strategyId1, setStrategyId1] = useState("");
  const [strategyId2, setStrategyId2] = useState("");
  const [strategyId3, setStrategyId3] = useState("");
  const [strategyCampaign1, setStrategyCamapign1] = useState([]);
  const [strategyCampaign2, setStrategyCamapign2] = useState([]);
  const [strategyCampaign3, setStrategyCamapign3] = useState([]);
  const [budgetCampaign1, setBudgetCampaign1] = useState(0);
  const [budgetCampaign2, setBudgetCampaign2] = useState(0);
  const [budgetCampaign3, setBudgetCampaign3] = useState(0);

  const [pmaxStrategy1, setPmaxStrategy1] = useState({});
  const [pmaxStrategy2, setPmaxStrategy2] = useState({});
  const [pmaxStrategy3, setPmaxStrategy3] = useState({});

  const strategyIterations = useSelector(
    (state) => state.client.strategyIterations
  );

  console.log("client side strategy deck");

  const form = useSelector((store) =>
    agencyType === "HDM" ? store.hdmFormAm : store.form.form
  );
  useEffect(() => {
    dispatch(getFormByFormIdReducer());
  }, []);

  useEffect(() => {
    const formId = getLocalStorage("formId")
      ? getLocalStorage("formId")
      : getLocalStorage("amSelectedFormId");
    const getClientFormURI = `/client-forms/${formId}`;
    const response = api.get(getClientFormURI);

    response
      .then((res) => {
        const form = res.data;
        setClientForm(form);
      })
      .catch((err) => console.log("ERROR:", err));
  }, []);

  useEffect(() => {
    const formId = getLocalStorage("formId")
      ? getLocalStorage("formId")
      : getLocalStorage("amSelectedFormId");
    const getStrategiesURI = `/client-forms/${formId}/pmax-strategy`;
    const campaign1 = [];
    const campaign2 = [];
    const campaign3 = [];
    api
      .get(getStrategiesURI)
      .then((response) => {
        const { strategies } = response.data;

        strategies.forEach((strategy) => {
          if (strategy.name === "campaign1" || strategy.name === "Campaign1") {
            setStrategyId1(strategy.id);
            setPmaxStrategy1(strategy);
            setBudgetCampaign1(strategy?.budget || 0);
            strategy.assets.forEach((asset) => campaign1.push({ ...asset }));
          } else if (strategy.name === "campaign2") {
            setStrategyId2(strategy.id);
            setPmaxStrategy2(strategy);
            setBudgetCampaign2(strategy?.budget || 0);
            strategy.assets.forEach((asset) => campaign2.push({ ...asset }));
          } else {
            setStrategyId3(strategy.id);
            setPmaxStrategy3(strategy);
            setBudgetCampaign3(strategy?.budget || 0);
            strategy.assets.forEach((asset) => campaign3.push({ ...asset }));
          }
        });
        if (campaign1.length) setStrategyCamapign1(campaign1);
        if (campaign2.length) setStrategyCamapign2(campaign2);
        if (campaign3.length) setStrategyCamapign3(campaign3);
      })
      .catch((err) => console.log("Error:", err?.response?.data));
  }, []);

  useEffect(() => {
    if (form) {
      const strategyInfo = form?.googleSearchAdsStrategyInfo;
      const brandCampaignInfo = strategyInfo?.brandCampaign;
      const competitorCampaignInfo = strategyInfo?.competitorCampaign;
      const serviceCampaignInfo = strategyInfo?.serviceCampaign;

      setBrandCampaign(brandCampaignInfo || {});
      setCompetitorCampaign(competitorCampaignInfo || {});
      setServiceCampaign(serviceCampaignInfo || {});
    }
  }, [form]);

  useEffect(() => {
    setTotalBudget(
      parseInt(brandCampaign?.budget || "0") +
        parseInt(competitorCampaign?.budget || "0") +
        parseInt(serviceCampaign?.budget || "0") +
        budgetCampaign1 +
        budgetCampaign2 +
        budgetCampaign3
    );
  }, [
    brandCampaign,
    competitorCampaign,
    serviceCampaign,
    budgetCampaign1,
    budgetCampaign2,
    budgetCampaign3,
  ]);

  const removeKeyword = (keyword, campaignType, adgroup) => {
    let campaign = {};
    if (campaignType === "brandCampaign") {
      campaign = { ...brandCampaign };
    } else if (campaignType === "competitorCampaign") {
      campaign = { ...competitorCampaign };
    } else {
      campaign = { ...serviceCampaign };
    }

    let adsets = [...campaign.adsets];
    let adgroupChanged = adsets.find((adset) => adset.name === adgroup);
    const removalIndex = adsets.indexOf(adgroupChanged);
    adsets.splice(removalIndex, 1);
    const keywords = [...adgroupChanged.keywords];

    keywords.splice(keywords.indexOf(keyword), 1);
    adgroupChanged = { ...adgroupChanged, keywords };
    adsets.splice(removalIndex, 0, adgroupChanged);

    if (campaignType === "brandCampaign") {
      setBrandCampaign({
        ...form.brandCampaign,
        adsets,
      });
      // dispatch(
      //   updateFormReducer({
      //     strategyInfo: {
      //       ...form.strategyInfo,
      //       brandCampaign: {
      //         ...form.brandCampaign,
      //         adsets,
      //       },
      //     },
      //   })
      // );
    } else if (campaignType === "competitorCampaign") {
      setCompetitorCampaign({
        ...form.competitorCampaign,
        adsets,
      });
      // dispatch(
      //   updateFormReducer({
      //     strategyInfo: {
      //       ...form.strategyInfo,
      //       competitorCampaign: {
      //         ...form.competitorCampaign,
      //         adsets,
      //       },
      //     },
      //   })
      // );
    } else {
      setServiceCampaign({
        ...form.serviceCampaign,
        adsets,
      });
      // dispatch(
      //   updateFormReducer({
      //     strategyInfo: {
      //       ...form.strategyInfo,
      //       serviceCampaign: {
      //         ...form.serviceCampaign,
      //         adsets,
      //       },
      //     },
      //   })
      // );
    }
  };

  const removeResource = (resource, campaignType, resourceType, adgroup) => {
    console.log("@@@@@@");
    let campaign = {};
    if (campaignType === "brandCampaign") {
      campaign = { ...brandCampaign };
    } else if (campaignType === "competitorCampaign") {
      campaign = { ...competitorCampaign };
    } else {
      campaign = { ...serviceCampaign };
    }

    let adsets = [...campaign.adsets];
    let adgroupChanged = adsets.find((adset) => adset.name === adgroup);
    adsets.splice(adsets.indexOf(adgroupChanged), 1);
    let removalIndex = -1;
    if (resourceType === "headlines") {
      const headlines = [...adgroupChanged.headlines];
      removalIndex = headlines.indexOf(resource);
      headlines.splice(removalIndex, 1);
      adgroupChanged = { ...adgroupChanged, headlines };
    } else {
      const descriptions = [...adgroupChanged.descriptions];
      removalIndex = descriptions.indexOf(resource);
      descriptions.splice(removalIndex, 1);
      adgroupChanged = { ...adgroupChanged, descriptions };
    }

    adsets.splice(removalIndex, 0, adgroupChanged);
    if (campaignType === "brandCampaign") {
      setBrandCampaign({
        ...form.brandCampaign,
        adsets,
      });
    } else if (campaignType === "competitorCampaign") {
      setCompetitorCampaign({
        ...form.competitorCampaign,
        adsets,
      });
    } else {
      setServiceCampaign({
        ...form.serviceCampaign,
        adsets,
      });
    }
  };

  const handleEditAssetGroup = (campName, assetId, index, value = "NA") => {
    let newCampaign = [];
    if (campName === "campaign1") newCampaign = strategyCampaign1.slice();
    else if (campName === "campaign2") newCampaign = strategyCampaign2.slice();
    else newCampaign = strategyCampaign3.slice();

    const foundIndex = newCampaign.findIndex(({ id }) => assetId === id);
    if (foundIndex === -1) return;
    else {
      switch (value) {
        case "Headline":
          newCampaign[foundIndex].headlines.splice(index, 1);
          break;
        case "Long Headline":
          newCampaign[foundIndex].longHeadlines.splice(index, 1);
          break;
        case "Description":
          newCampaign[foundIndex].descriptions.splice(index, 1);
          break;
        case "Image":
          newCampaign[foundIndex].imageUrls.splice(index, 1);
          break;
        case "Logo":
          newCampaign[foundIndex].logoUrls.splice(index, 1);
          break;
        case "Vidoes":
          newCampaign[foundIndex].ytVideosMetadata.splice(index, 1);
          break;
        case "Keywords":
          const keywords = newCampaign[foundIndex].segment.keywords.slice();
          keywords.splice(index, 1);
          newCampaign[foundIndex].segment = {
            ...newCampaign[foundIndex].segment,
            keywords,
          };
          break;
        default:
          console.log("wrong value");
      }

      if (campName === "campaign1") setStrategyCamapign1(newCampaign);
      else if (campName === "campaign2") setStrategyCamapign2(newCampaign);
      else setStrategyCamapign3(newCampaign);
    }
  };

  console.log("SD1:", strategyCampaign1);

  const approveDeck = (toApprove) => {
    const formId = getLocalStorage("formId")
      ? getLocalStorage("formId")
      : getLocalStorage("amSelectedFormId");
    
    if(form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED && form?.googleAdsCronStatus === CRON_STATUS_BACKEND.DONE){
      dispatch(
        updateFormReducer({
          strategyInfo: {
            ...form.strategyInfo,
            brandCampaign: {
              ...brandCampaign,
            },
            competitorCampaign: {
              ...competitorCampaign,
            },
            serviceCampaign: {
              ...serviceCampaign,
            },
          },
        })
      );
    }


    if (
      [BACKEND_STATUS.COMPLETED].includes(
        clientForm?.googlePmaxStrategy?.status
      ) &&
      clientForm.pmaxCAICronStatus === CRON_STATUS_BACKEND.DONE &&
      clientForm.pmaxTAICronStatus === CRON_STATUS_BACKEND.DONE
    ) {
      const putStrategy1URI = `/client-forms/${formId}/pmax-strategy/${strategyId1}`;
      const putStrategy2URI = `/client-forms/${formId}/pmax-strategy/${strategyId2}`;
      const putStrategy3URI = `/client-forms/${formId}/pmax-strategy/${strategyId3}`;

      const data1 = {
        validateAssets: false,
        assets: strategyCampaign1,
      };
      const data2 = {
        validateAssets: false,
        assets: strategyCampaign2,
      };
      const data3 = {
        validateAssets: false,
        assets: strategyCampaign3,
      };

      const promiseArray = [api.put(putStrategy1URI, data1)];
      dispatch(
        createPmaxCampaign({
          data: {
            campaignNumber: PMaxCampaignName.campaign1,
            assetCount: pmaxStrategy1.assetCount,
            budget: pmaxStrategy1.budget,
            assets: strategyCampaign1.map((asset) => {
              delete asset.id;
              delete asset.theme;
              delete asset.themes;
              return {
                ...asset,
                businessName: "",
                status: BACKEND_STATUS.PENDING,
                audience: { type: "PREDEFINED" },
                finalUrl:
                  agencyType === "LD"
                    ? form?.onboardingInfo?.personalInfo?.companyWebsite
                    : form?.onboardingInfo?.clientAccess?.websiteUrl,
                ytVideosUrls: asset.ytVideosMetadata,
              };
            }),
          },
        })
      );

      if (strategyCampaign2.length) {
        dispatch(
          createPmaxCampaign({
            data: {
              campaignNumber: PMaxCampaignName.campaign2,
              assetCount: pmaxStrategy2.assetCount,
              budget: pmaxStrategy2.budget,
              assets: strategyCampaign2.map((asset) => {
                delete asset.id;
                delete asset.theme;
                delete asset.themes;
                return {
                  ...asset,
                  businessName: "",
                  status: BACKEND_STATUS.PENDING,
                  audience: { type: "PREDEFINED" },
                  finalUrl:
                    agencyType === "LD"
                      ? form?.onboardingInfo?.personalInfo?.companyWebsite
                      : form?.onboardingInfo?.clientAccess?.websiteUrl,
                  ytVideosUrls: asset.ytVideosMetadata,
                };
              }),
            },
          })
        );
        promiseArray.push(api.put(putStrategy2URI, data2));
      }
      if (strategyCampaign3.length) {
        dispatch(
          createPmaxCampaign({
            data: {
              campaignNumber: PMaxCampaignName.campaign3,
              assetCount: pmaxStrategy3.assetCount,
              budget: pmaxStrategy3.budget,
              assets: strategyCampaign3.map((asset) => {
                delete asset.id;
                delete asset.theme;
                delete asset.themes;
                return {
                  ...asset,
                  status: BACKEND_STATUS.PENDING,
                  businessName: "",
                  audience: { type: "PREDEFINED" },
                  finalUrl:
                    agencyType === "LD"
                      ? form?.onboardingInfo?.personalInfo?.companyWebsite
                      : form?.onboardingInfo?.clientAccess?.websiteUrl,
                  ytVideosUrls: asset.ytVideosMetadata,
                };
              }),
            },
          })
        );
        promiseArray.push(api.put(putStrategy3URI, data3));
      }

      Promise.all(promiseArray)
        .then((res) => console.log(res))
        .catch((err) => console.log("ERROR:", err));
    }

    if(toApprove){
      approveDeckApi(strategyIterations).then((res) => {
        setModalApprove(true);
      });
    }
  };
  return (
    <div className="intro_strategy_main clientdashboard">
      {strategyIterations.length !== 0 &&
        strategyIterations[strategyIterations.length - 1].state ===
          "CHANGES_SUGGESTED" && (
          <div className="intro_strategy_layout">
            <div className="intro_strategy_warning">
              <span className="icon orange_check">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.75"
                    width="20"
                    height="20"
                    rx="10"
                    fill="#F99F35"
                  />
                  <path
                    d="M6 10.7832L8.86226 13.65L14.7592 7.75"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <p>
                You are viewing the Strategy copy generated on{" "}
                {publishedDate(
                  strategyIterations[strategyIterations.length - 1].stateTs
                )}
                . Your suggested changes have been registered and you will be
                notified soon
              </p>
            </div>
          </div>
        )}
      <div className="google_strategy_layout">
        <div className="google_strategy_banner">
          <div className="google_banner_part">
            <div className="google_strategy_row">
              <div className="google_strategy_logo">
                <span></span>
                <p>Google</p>
              </div>
              <div className="google_strategy_budget">
                <p>Total Budget : ${totalBudget}</p>
              </div>
            </div>
            <p className="google_strategy_info">
              Here, you can review the strategy for running your campaigns on
              google to drive efficient delivery and performance. We recommend
              that you go through each of the recommendations here and replace
              the ones you do not want to display in your campaign strategy
              deck. Removing the ones you do not find useful, will automatically
              generate new recommendations at the bottom of each of the lists.
            </p>
          </div>
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Brand Campaign</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${brandCampaign?.budget || 0}</p>
                </div>
              </div>
              <p className="google_title_info">
                Brand Campaigns essentially help you serve ads whenever a user
                searches for anything related to your brand name. This is
                generally to ensure no competitor is eating up your prospective
                leads.
              </p>
            </div>
          </div>
          {(brandCampaign?.adsets || []).map((campaign, ids) => {
            return (
              <Accordion className="accordion_strategy_body" key={ids}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>{campaign.name}</h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <div className="strategy_accordion_layout">
                    <div className="strategy_row_item">
                      <div className="strategy_column_item search_row_strategy">
                        <Autocomplete
                          open
                          multiple
                          disableCloseOnSelect
                          noOptionsText=""
                          renderOption={(props, option, { selected }) => (
                            <li className="autosearch_list">
                              <div className="strategy_list_row">
                                <div {...props} onClick={() => {}}>
                                  <div className="strategy_list_column">
                                    <span className="search_icon"></span>
                                    <p>{option}</p>
                                  </div>
                                </div>
                                <div>
                                  <div className="headline_column_actions">
                                    {/* <button
                                      className={`list_search_actions accept_icon`}
                                    ></button> */}
                                    <button
                                      className={`list_search_actions reject_icon`}
                                      onClick={() => {
                                        removeKeyword(
                                          option,
                                          "brandCampaign",
                                          campaign.name
                                        );
                                      }}
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          options={[...campaign.keywords]}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <div className="search_strategy_header">
                              <span className="search_icon"></span>
                              <TextField
                                {...params}
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Search..."
                                variant="standard"
                              />
                            </div>
                          )}
                          disablePortal={true}
                          className="auto_complete_search"
                        />
                      </div>
                      <div className="strategy_column_item">
                        <Tabs>
                          <div className="custom_tab_header">
                            <Tab>Headline</Tab>
                            <Tab>Description</Tab>
                            <Tab>Preview</Tab>
                          </div>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.headlines || []).map(
                                (headline, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{headline.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{headline.category}</p>
                                          <span>
                                            {headline.value.length}/30
                                          </span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={() => removeResource(headline, "brandCampaing", "headline")}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          onClick={() => {
                                            removeResource(
                                              headline,
                                              "brandCampaign",
                                              "headlines",
                                              campaign.name
                                            );
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.descriptions || []).map(
                                (desc, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{desc.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{desc.category}</p>
                                          <span>{desc.value.length}/90</span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          onClick={() => {
                                            removeResource(
                                              desc,
                                              "brandCampaign",
                                              "descriptions",
                                              campaign.name
                                            );
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(0, 3)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(0, 1)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(3, 6)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(1, 2)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Competitor Campaign</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${competitorCampaign?.budget || 0}</p>
                </div>
              </div>
              <p className="google_title_info">
                Bidding on your competitor's brand terms to create awareness and
                steal market share.
              </p>
            </div>
          </div>
          {(competitorCampaign?.adsets || []).map((campaign, ids) => {
            return (
              <Accordion className="accordion_strategy_body" key={ids}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>{campaign.name}</h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <div className="strategy_accordion_layout">
                    <div className="strategy_row_item">
                      <div className="strategy_column_item search_row_strategy">
                        <Autocomplete
                          open
                          multiple
                          disableCloseOnSelect
                          noOptionsText=""
                          renderOption={(props, option, { selected }) => (
                            <li className="autosearch_list">
                              <div className="strategy_list_row">
                                <div {...props} onClick={() => {}}>
                                  <div className="strategy_list_column">
                                    <span className="search_icon"></span>
                                    <p>{option}</p>
                                  </div>
                                </div>
                                <div>
                                  <div className="headline_column_actions">
                                    {/* <button
                                      className={`list_search_actions accept_icon`}
                                    ></button> */}
                                    <button
                                      className={`list_search_actions reject_icon`}
                                      onClick={() => {
                                        console.log("in skjdfkajsf", campaign);
                                        removeKeyword(
                                          option,
                                          "competitorCampaign",
                                          campaign.name
                                        );
                                      }}
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          options={[...campaign.keywords]}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <div className="search_strategy_header">
                              <span className="search_icon"></span>
                              <></>
                              <TextField
                                {...params}
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Search..."
                                variant="standard"
                              />
                            </div>
                          )}
                          disablePortal={true}
                          className="auto_complete_search"
                        />
                      </div>
                      <div className="strategy_column_item">
                        <Tabs>
                          <div className="custom_tab_header">
                            <Tab>Headline</Tab>
                            <Tab>Description</Tab>
                            <Tab>Preview</Tab>
                          </div>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.headlines || []).map(
                                (headline, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{headline.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{headline.category}</p>
                                          <span>
                                            {headline.value.length}/30
                                          </span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          onClick={() => {
                                            removeResource(
                                              headline,
                                              "competitorCampaign",
                                              "headlines",
                                              campaign.name
                                            );
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.descriptions || []).map(
                                (desc, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{desc.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{desc.category}</p>
                                          <span>{desc.value.length}/90</span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          onClick={() => {
                                            removeResource(
                                              desc,
                                              "competitorCampaign",
                                              "descriptions",
                                              campaign.name
                                            );
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(0, 3)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(0, 1)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(3, 2)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(1, 2)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Services Campaign</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${serviceCampaign?.budget || 0}</p>
                </div>
              </div>
              <p className="google_title_info">
                Bidding on high intent keywords like invisalign, dental
                implants, veneers, etc.
              </p>
            </div>
          </div>
          {(serviceCampaign?.adsets || []).map((campaign, ids) => {
            return (
              <Accordion className="accordion_strategy_body" key={ids}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>{campaign.name}</h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <div className="strategy_accordion_layout">
                    <div className="strategy_row_item">
                      <div className="strategy_column_item search_row_strategy">
                        <Autocomplete
                          open
                          multiple
                          disableCloseOnSelect
                          noOptionsText=""
                          renderOption={(props, option, { selected }) => (
                            <li className="autosearch_list">
                              <div className="strategy_list_row">
                                <div {...props} onClick={() => {}}>
                                  <div className="strategy_list_column">
                                    <span className="search_icon"></span>
                                    <p>{option}</p>
                                  </div>
                                </div>
                                <div>
                                  <div className="headline_column_actions">
                                    {/* <button
                                      className={`list_search_actions accept_icon`}
                                    ></button> */}
                                    <button
                                      className={`list_search_actions reject_icon`}
                                      onClick={(e) => {
                                        removeKeyword(
                                          option,
                                          "serviceCampaign",
                                          campaign.name
                                        );
                                        e.preventDefault();
                                      }}
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          options={[...campaign.keywords]}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <div className="search_strategy_header">
                              <span className="search_icon"></span>
                              <TextField
                                {...params}
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Search..."
                                variant="standard"
                              />
                            </div>
                          )}
                          disablePortal={true}
                          className="auto_complete_search"
                        />
                      </div>
                      <div className="strategy_column_item">
                        <Tabs>
                          <div className="custom_tab_header">
                            <Tab>Headline</Tab>
                            <Tab>Description</Tab>
                            <Tab>Preview</Tab>
                          </div>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.headlines || []).map(
                                (headline, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{headline.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{headline.category}</p>
                                          <span>
                                            {headline.value.length}/30
                                          </span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon "
                                          onClick={() => {
                                            removeResource(
                                              headline,
                                              "serviceCampaign",
                                              "headlines",
                                              campaign.name
                                            );
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.descriptions || []).map(
                                (desc, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{desc.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{desc.category}</p>
                                          <span>{desc.value.length}/90</span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          onClick={() => {
                                            removeResource(
                                              desc,
                                              "serviceCampaign",
                                              "descriptions",
                                              campaign.name
                                            );
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(0, 3)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(0, 1)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(3, 5)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(1, 2)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      {[BACKEND_STATUS.COMPLETED, BACKEND_STATUS.ONGOING].includes(
        clientForm?.googlePmaxStrategy?.status
      ) &&
        clientForm.pmaxCAICronStatus === CRON_STATUS_BACKEND.DONE &&
        clientForm.pmaxTAICronStatus === CRON_STATUS_BACKEND.DONE && (
          <>
            <div className="pmax_campaign">
              <div
                style={{
                  height: "108px",
                  width: "1069px",
                  backgroundColor: "#F0F3FF",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={CAMERA}
                  style={{ width: "56px", height: "56px", objectFit: "cover" }}
                  alt=""
                />
                <Typography
                  style={{
                    fontFamily: "Lato",
                    fontSize: "28px",
                    fontWeight: "400",
                    lineHeight: "34px",
                  }}
                >
                  {" "}
                  Performance Max Campaign
                </Typography>
              </div>
            </div>

            {strategyCampaign1.length > 0 && (
              <>
                <div className="strategy_row_layout">
                  <div className="strategy_google_row_main">
                    <div className="google_strategy_header">
                      <div>
                        <div className="google_strategy_row">
                          <div className="google_strategy_title">
                            <p>Campaign 1</p>
                          </div>
                          <div className="google_title_budget">
                            <p>Daily Budget : ${budgetCampaign1}</p>
                          </div>
                        </div>
                        <p className="google_title_info">
                          Brand Campaigns essentially help you serve ads
                          whenever a user searches for anything related to your
                          brand name. This is generally to ensure no competitor
                          is eating up your prospective leads.
                        </p>
                      </div>
                      {/* <div>
                      <PrimaryButton title="Approve All" icon="approve" type="light" />
                    </div> */}
                    </div>

                    {strategyCampaign1?.map((asset, index) => (
                      <Accordion
                        className="accordion_strategy_body"
                        key={index}
                      >
                        <AccordionSummary className="accordion_strategy_head">
                          <div className="strategy_accordion_title">
                            <h4
                              style={{
                                fontFamily: "Inter",
                                fontSize: "18px",
                                lineHeight: "21.78px",
                                fontWeight: "600",
                                paddingLeft: "14px",
                              }}
                            >
                              {asset?.name}
                            </h4>
                            <span></span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion_strategy_content">
                          <div className="strategy_accordion_layout">
                            <div
                              className="strategy_row_item"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                className="strategy_column_item search_row_strategy"
                                style={{ flexBasis: "40%" }}
                              >
                                <div className="strategy_segment">
                                  <span
                                    style={{
                                      fontFamily: "Inter",
                                      fontSize: "13.96px",
                                      fontWeight: "500",
                                      lineHeight: "16.9px",
                                      marginTop: "16.14px",
                                    }}
                                  >
                                    {" "}
                                    <img
                                      src={PEOPLE}
                                      style={{
                                        marginRight: "13.44px",
                                        width: "22.30px",
                                        height: "11.96px",
                                      }}
                                      alt=""
                                    />
                                    {asset?.segment?.name}
                                  </span>
                                  <div
                                    style={{
                                      borderBottom: "1px solid #DBDEE2",
                                      marginTop: "10.74px",
                                    }}
                                  ></div>
                                  {asset?.segment?.keywords?.map(
                                    (keyword, index) => (
                                      <div className="segment_body">
                                        <p key={index}>
                                          <img
                                            src={Person}
                                            style={{
                                              height: "11.94px",
                                              width: "9.53px",
                                              marginRight: "19.11px",
                                            }}
                                            alt=""
                                          />
                                          {keyword}
                                        </p>

                                        <img
                                          src={crossIcon}
                                          style={{ cursor: "pointer" }}
                                          alt="cross"
                                          onClick={() =>
                                            handleEditAssetGroup(
                                              "campaign1",
                                              asset.id,
                                              index,
                                              "Keywords"
                                            )
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <PMaxStrategyDeck
                                isClient={true}
                                asset={asset}
                                onRemove={handleEditAssetGroup}
                                campName="campaign1"
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </>
            )}

            {strategyCampaign2.length > 0 && (
              <>
                <div className="strategy_row_layout">
                  <div className="strategy_google_row_main">
                    <div className="google_strategy_header">
                      <div>
                        <div className="google_strategy_row">
                          <div className="google_strategy_title">
                            <p>Campaign 2</p>
                          </div>
                          <div className="google_title_budget">
                            <p>Daily Budget : ${budgetCampaign2}</p>
                          </div>
                        </div>
                        <p className="google_title_info">
                          Competitor campaigns are where you use what is working
                          for your competitor, by including it in your strategy.
                          Bidding on your competitor's brand terms to create
                          awareness and steal market share is one way to do
                          this.
                        </p>
                      </div>
                      {/* <div>
                     <PrimaryButton title="Approve All" icon="approve" type="light" />
                   </div> */}
                    </div>
                    {strategyCampaign2?.map((asset, index) => (
                      <Accordion
                        className="accordion_strategy_body"
                        key={index}
                      >
                        <AccordionSummary className="accordion_strategy_head">
                          <div className="strategy_accordion_title">
                            <h4
                              style={{
                                fontFamily: "Inter",
                                fontSize: "18px",
                                lineHeight: "21.78px",
                                fontWeight: "600",
                                paddingLeft: "14px",
                              }}
                            >
                              {asset?.name}
                            </h4>
                            <span></span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion_strategy_content">
                          <div className="strategy_accordion_layout">
                            <div
                              className="strategy_row_item"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                className="strategy_column_item search_row_strategy"
                                style={{ flexBasis: "40%" }}
                              >
                                <div className="strategy_segment">
                                  <span
                                    style={{
                                      fontFamily: "Inter",
                                      fontSize: "13.96px",
                                      fontWeight: "500",
                                      lineHeight: "16.9px",
                                      marginTop: "16.14px",
                                    }}
                                  >
                                    {" "}
                                    <img
                                      src={PEOPLE}
                                      style={{
                                        marginRight: "13.44px",
                                        width: "22.30px",
                                        height: "11.96px",
                                      }}
                                      alt=""
                                    />
                                    {asset?.segment?.name}
                                  </span>
                                  <div
                                    style={{
                                      borderBottom: "1px solid #DBDEE2",
                                      marginTop: "10.74px",
                                    }}
                                  ></div>
                                  {asset?.segment?.keywords?.map(
                                    (keyword, index) => (
                                      <div className="segment_body">
                                        <p key={index}>
                                          <img
                                            src={Person}
                                            style={{
                                              height: "11.94px",
                                              width: "9.53px",
                                              marginRight: "19.11px",
                                            }}
                                            alt=""
                                          />
                                          {keyword}{" "}
                                        </p>
                                        <img
                                          style={{
                                            cursor: "pointer",
                                            width: "11px",
                                            height: "11px",
                                          }}
                                          src={crossIcon}
                                          alt="cross"
                                          onClick={() =>
                                            handleEditAssetGroup(
                                              "campaign2",
                                              asset.id,
                                              index,
                                              "Keywords"
                                            )
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <PMaxStrategyDeck
                                isClient={true}
                                asset={asset}
                                onRemove={handleEditAssetGroup}
                                campName="campaign2"
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </>
            )}

            {strategyCampaign3.length > 0 && (
              <>
                <div className="strategy_row_layout">
                  <div className="strategy_google_row_main">
                    <div className="google_strategy_header">
                      <div>
                        <div className="google_strategy_row">
                          <div className="google_strategy_title">
                            <p>Campaign 3</p>
                          </div>
                          <div className="google_title_budget">
                            <p>Daily Budget : ${budgetCampaign3}</p>
                          </div>
                        </div>
                        <p className="google_title_info">
                          Services Campaigns are where you bid for and include
                          high intent keywords that, either directly spell out
                          the services you provide, or reflect them in any
                          manner.
                        </p>
                      </div>
                      {/* <div>
                <PrimaryButton title="Approve All" icon="approve" type="light" />
              </div> */}
                    </div>

                    {strategyCampaign3?.map((asset, index) => (
                      <Accordion
                        className="accordion_strategy_body"
                        key={index}
                      >
                        <AccordionSummary className="accordion_strategy_head">
                          <div className="strategy_accordion_title">
                            <h4
                              style={{
                                fontFamily: "Inter",
                                fontSize: "18px",
                                lineHeight: "21.78px",
                                fontWeight: "600",
                                paddingLeft: "14px",
                              }}
                            >
                              {asset?.name}
                            </h4>
                            <span></span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion_strategy_content">
                          <div className="strategy_accordion_layout">
                            <div
                              className="strategy_row_item"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                className="strategy_column_item search_row_strategy"
                                style={{ flexBasis: "40%" }}
                              >
                                <div className="strategy_segment">
                                  <span
                                    style={{
                                      fontFamily: "Inter",
                                      fontSize: "13.96px",
                                      fontWeight: "500",
                                      lineHeight: "16.9px",
                                      marginTop: "16.14px",
                                    }}
                                  >
                                    {" "}
                                    <img
                                      src={PEOPLE}
                                      style={{
                                        marginRight: "13.44px",
                                        width: "22.30px",
                                        height: "11.96px",
                                      }}
                                      alt=""
                                    />
                                    {asset?.segment?.name}
                                  </span>
                                  <div
                                    style={{
                                      borderBottom: "1px solid #DBDEE2",
                                      marginTop: "10.74px",
                                    }}
                                  ></div>
                                  {asset?.segment?.keywords?.map(
                                    (keyword, index) => (
                                      <div className="segment_body">
                                        <p key={index}>
                                          <img
                                            src={Person}
                                            style={{
                                              height: "11.94px",
                                              width: "9.53px",
                                              marginRight: "19.11px",
                                            }}
                                            alt=""
                                          />
                                          {keyword}{" "}
                                        </p>
                                        <img
                                          style={{ cursor: "pointer" }}
                                          src={crossIcon}
                                          alt="cross"
                                          onClick={() =>
                                            handleEditAssetGroup(
                                              "campaign3",
                                              asset.id,
                                              index,
                                              "Keywords"
                                            )
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <PMaxStrategyDeck
                                isClient={true}
                                asset={asset}
                                onRemove={handleEditAssetGroup}
                                campName="campaign3"
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      <div className="preview_footer_row">
        <div
          className="footer_content"
          style={{ justifyContent: "space-between" }}
        >
          <button
            className="backbutton"
            onClick={() => {
              if(agencyType === 'LD'){
                dispatch(goToSpecificCompoClient(LDcomponentWithIndexClient[clientDashboardIdsLD.STRATEGY_DECK_INTRO]));
              } else {
                dispatch(goToSpecificCompoHDMClient(componentWithIndexHDMClient[clientDashboardIds.HDM_ANAYLSIS_DECK_PAGE]));
              }
            }}
          >
            BACK
          </button>
          <PrimaryButton
            title={
              agencyType === "HDM"
                ? "PROCEED"
                : isPlatformSelected(form, 'facebook-ads') !== -1
                ? "PROCEED"
                : "APPROVE"
            }
            disabled={
              agencyType === "HDM"
                ? false
                : isPlatformSelected(form, 'facebook-ads') === -1 &&
                  form?.strategyIterations?.length &&
                  ["CHANGES_SUGGESTED", "APPROVED"].includes(
                    form?.strategyIterations[
                      form?.strategyIterations.length - 1
                    ].state
                  )
                ? true
                : false
            }
            onClick={() => {
              if(agencyType === 'LD'){
                if(isPlatformSelected(form, 'facebook-ads') !== -1){
                  approveDeck(false);
                  dispatch(goToSpecificCompoClient(LDcomponentWithIndexClient[clientDashboardIdsLD.STRATEGY_DECK_FACEBOOK]));
                } else {
                  approveDeck(true);
                }
              }
              if(agencyType === 'HDM'){
                if(isPlatformSelectedHDM(form, 'facebook-ads') !== -1){
                  approveDeck(false);
                  dispatch(goToSpecificCompoHDMClient(componentWithIndexHDMClient[clientDashboardIds.HDM_STRATEGY_DECK_FACEBOOK_PAGE]))
                } else {
                  approveDeck(false);
                  dispatch(goToSpecificCompoHDMClient(componentWithIndexHDMClient[clientDashboardIds.HDM_STRATEGY_DECK_OURPLAN_PAGE]))
                }
              }
            }}
          ></PrimaryButton>
        </div>
      </div>
      <StrategyDeckApproval
        formSubm={modalApprove}
        setFormSubm={setModalApprove}
        title="Strategy Deck Approved"
        description="We will notify you once the campaigns are ready for your preview"
        buttonTitle="PROCEED"
        type="linear"
      />
    </div>
  );
};
export default StrategyDeckGoogle;
