import { HDMHeaderTabs, listOfIdsHDM } from "../../../utils/navbarUtil";
import {
  componentWithIndexHDMClient,
  clientDashboardIds,
} from "../../../utils/HDMClientDashboardIdsUtil";

const INITIAL_STATE = {
  currentComp: 0,
  currentLeftSidebar: null,
  currentHeaderTab: null,
  lastActiveComp: 0,
  visitedLastTime: 0,
};

export const updateNav = (currentComp) => {
  if (currentComp === 0) {
    return null;
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS1] &&
    currentComp < componentWithIndexHDMClient[clientDashboardIds.HDM_ACCESS]
  ) {
    return listOfIdsHDM.CLIENTANDACCESS;
  } else if (
    currentComp === componentWithIndexHDMClient[clientDashboardIds.HDM_ACCESS]
  ) {
    return listOfIdsHDM.accessSharing;
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.HDM_ONBOARDING] &&
    currentComp <
      componentWithIndexHDMClient[clientDashboardIds.HDM_STRATEGY_DECK_PAGE]
  ) {
    return listOfIdsHDM.ONBOARDING;
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.HDM_STRATEGY_DECK_PAGE] &&
    currentComp <
      componentWithIndexHDMClient[clientDashboardIds.HDM_CAMPAIGN_PREVIEW]
  ) {
    return listOfIdsHDM.STRATEGY_DECK;
  } else if (
    currentComp >=
    componentWithIndexHDMClient[clientDashboardIds.HDM_CAMPAIGN_PREVIEW_GOOGLE] && 
    currentComp <=
    componentWithIndexHDMClient[clientDashboardIds.HDM_CAMPAIGN_PREVIEW]
  ) {
    return listOfIdsHDM.CampaignPreviewFB;
  } else {
    return null;
  }
};

export const updateHeader = (currentComp) => {
  if (currentComp === 0) {
    return null;
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS1] &&
    currentComp <
      componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING1]
  ) {
    return HDMHeaderTabs["CLIENTANDACCESS"];
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING1] &&
    currentComp <
      componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE1]
  ) {
    return HDMHeaderTabs["BRANDORMESSAGING"];
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE1] &&
    currentComp <
      componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING1]
  ) {
    return HDMHeaderTabs["FACEBOOKTARGETING"];
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING1] &&
    currentComp <
      componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS1]
  ) {
    return HDMHeaderTabs["GOOGLETARGETING"];
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS1] &&
    currentComp <
      componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD1]
  ) {
    return HDMHeaderTabs["ABOUTYOURBUSSINESS"];
  } else if (
    currentComp >=
      componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD1] &&
    currentComp <
      componentWithIndexHDMClient[
        clientDashboardIds.REVIEW_HDMQUESTIONIER_RESPONSES
      ]
  ) {
    return HDMHeaderTabs["CREATIVEANDMONTAGEAD"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[clientDashboardIds.HDM_ONBOARDING]
  ) {
    return HDMHeaderTabs["ONBOARDING"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[clientDashboardIds.HDM_ONBOARDING_REVIEW]
  ) {
    return HDMHeaderTabs["REVIEWBRIEFINGDOCUMENT"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[clientDashboardIds.HDM_ONBOARDING_COMMUNICATION]
  ) {
    return HDMHeaderTabs["EXPECTATIONS"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[clientDashboardIds.HDM_STRATEGY_DECK_PAGE]
  ) {
    return HDMHeaderTabs["GOAL"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[clientDashboardIds.HDM_ANAYLSIS_DECK_PAGE]
  ) {
    return HDMHeaderTabs["ANALYSIS"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[
      clientDashboardIds.HDM_STRATEGY_DECK_GOOGLE_PAGE
    ]
  ) {
    return HDMHeaderTabs["GOOGLESTRATEGY"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[
      clientDashboardIds.HDM_STRATEGY_DECK_FACEBOOK_PAGE
    ]
  ) {
    return HDMHeaderTabs["FBSTRATEGY"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[
      clientDashboardIds.HDM_STRATEGY_DECK_OURPLAN_PAGE
    ]
  ) {
    return HDMHeaderTabs["OURPLAN"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[
      clientDashboardIds.HDM_STRATEGY_DECK_BESTPRACTICE_PAGE
    ]
  ) {
    return HDMHeaderTabs["BESTPRACTICES"];
  } else if (
    currentComp ===
    componentWithIndexHDMClient[
      clientDashboardIds.HDM_STRATEGY_DECK_SUGGESTCHANGES_PAGE
    ]
  ) {
    return HDMHeaderTabs["CHANGESSUGGESTED"];
  } else {
    return null;
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_CURRENT_COMPONENT_HDM":
      const currentComp2 = state.currentComp + 1;
      return {
        ...state,
        currentComp: currentComp2,
        currentHeaderTab: updateHeader(currentComp2),
        currentLeftSidebar: updateNav(currentComp2),
        visitedLastTime: Math.max(state.currentComp + 1, state.visitedLastTime),
      };

    case "GO_TO_SPECIFIC_COMPO_HDMCLIENT":
      const currentComp1 = action.payload;
      return {
        ...state,
        currentComp: currentComp1,
        currentHeaderTab: updateHeader(currentComp1),
        currentLeftSidebar: updateNav(currentComp1),
        visitedLastTime: Math.max(state.visitedLastTime, currentComp1),
      };
    case "GO_BACK_HDMCLIENT_COMPO":
      const currentComp = state.currentComp - 1;
      return {
        ...state,
        currentComp: currentComp,
        currentHeaderTab: updateHeader(currentComp),
        currentLeftSidebar: updateNav(currentComp),
      };
    case "REVIEW_HDMQUESTIONIER":
      return { ...state, ...action.payload };
    case "UPDATE_TAB_HDMCLIENT":
      const currentComphdm = action.payload;
      return {
        ...state,
        currentComp: currentComphdm,
        currentHeaderTab: updateHeader(currentComphdm),
        currentLeftSidebar: updateNav(currentComphdm),
      };
    case "GO_BACK_REVIEW_HDMQUESTIONIER": {
      const currentComp3 = action.payload;
      return {
        ...state,
        currentComp: currentComp3.currentComp,
        currentHeaderTab: updateHeader(currentComp3.currentComp),
        currentLeftSidebar: updateNav(currentComp3.currentComp),
        lastActiveComp: 0,
        visitedLastTime: Math.max(
          state.visitedLastTime,
          currentComp3.currentComp
        ),
      };
    }
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
