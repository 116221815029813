import React, { useEffect, forwardRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ClientDashboard from "./components/ClientDashboard/ClientDashboard";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { alreadyAuthenticated } from "./api/auth/actions";
import Login from "./components/Auth/Login";
import GenerateCreds from "./components/Admin/Admin";
import { getLocalStorage } from "./api/localStorage/localStorage";
import roles from "./utils/roles";
import AccountManager from "./components/AccountManager/AccountManager";
import SelectChannel from "./components/Admin/Analytics/analytics";
import AdminPop from "./components/Admin/AdminPop";
import HDMClientDashboard from "./components/HDMClientDashboard/HDMClientDashboard";
import GeneratePdf from "./components/Common/GeneratePdf";
import HDMAccountManager from "./components/HDMAccountManager/HDMAccountManager";
import BrandPage from "./components/AccountManager/BrandPage/Brandpage";
import { toggleSnackbar } from "./api/Global/slice";
import AnalyticDashboard from "./components/Analytics/AnalyticsDashboard";
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utils/queryClient';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const App = ({ isAuthnticated }) => {
  console.log("## in app", isAuthnticated);
  const dispatch = useDispatch();
  const showFlashmessage = useSelector((store) => store.global.showFlashmessage);
  const flashMessage = useSelector((store) => store.global.flashMessage);
  useEffect(() => {
    getLocalStorage("role") === roles.ACCOUNT_MANAGER &&
      getLocalStorage("agencyId") == 1 &&
      !getLocalStorage("isNextClicked") &&
      localStorage.clear();
    localStorage.getItem("accessToken") && dispatch(alreadyAuthenticated());
  }, []);

  const handleClose = () => {
    dispatch(toggleSnackbar());
  };
  return (
    <>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          className="snackbar"
          style={{ zIndex: 199 }}
          open={showFlashmessage}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleClose}
            severity={flashMessage.severity}
            sx={{ width: '100%' }}
          >
            {flashMessage.message}
          </Alert>
        </Snackbar>
      </Stack>
      <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {isAuthnticated ? (
            <>
              {(getLocalStorage("role") === roles.CLIENT ||
                getLocalStorage("role") === roles.ACCOUNT_MANAGER) &&
                (getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12) && (
                  <Route path="/client" element={<ClientDashboard />} />
                )}
              {(getLocalStorage("role") === roles.CLIENT ||
                getLocalStorage("role") === roles.HDM_CLIENT ||
                getLocalStorage("role") === roles.HDM_ACCOUNT_MANAGER) && (
                  <Route path="/analytic-dashboard" element={<AnalyticDashboard />} />
                )}
              {(getLocalStorage("role") === roles.ACCOUNT_MANAGER) &&
                (getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12) && (
                  <Route path="/brands" element={<BrandPage />} />
                )}
              {(getLocalStorage("role") === roles.HDM_CLIENT ||
                getLocalStorage("role") === roles.HDM_ACCOUNT_MANAGER) &&
                (getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) && (
                  <Route path="/hdmclient" element={<HDMClientDashboard />} />
                )}
              {(getLocalStorage("role") === roles.HDM_CLIENT ||
                getLocalStorage("role") === roles.HDM_ACCOUNT_MANAGER) &&
                (getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) && (
                  <Route
                    path="/hdmclient/:compo"
                    element={<HDMClientDashboard />}
                  />
                )}

              {getLocalStorage("role") === roles.HDM_ACCOUNT_MANAGER &&
                (getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) && (
                  <Route
                    path="/hdmaccountmanager"
                    element={<HDMAccountManager />}
                  />
                )}

              {(getLocalStorage("role") === roles.CLIENT ||
                getLocalStorage("role") === roles.ACCOUNT_MANAGER) &&
                (getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12) && (
                  <Route path="/client/:compo" element={<ClientDashboard />} />
                )}
              {getLocalStorage("role") === roles.SUPER_ADMIN && (
                <Route path="/admin" element={<AdminPop />} />
              )}
              {getLocalStorage("role") === roles.SUPER_ADMIN && (
                <Route path="/generate-creds" element={<GenerateCreds />} />
              )}
              {getLocalStorage("role") === roles.SUPER_ADMIN && (
                <Route path="/select-accounts" element={<SelectChannel />} />
              )}
              {getLocalStorage("role") === roles.ACCOUNT_MANAGER &&
                (getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12 ) && (
                  <Route path="/account-manager" element={<AccountManager />} />
                )}
              {(getLocalStorage("role") === roles.CLIENT ||
                getLocalStorage("role") === roles.ACCOUNT_MANAGER) &&
                (getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12 ) && (
                  <Route path="/pdf" element={<GeneratePdf />} />
                )}
              <Route
                path="*"
                element={
                  getLocalStorage("role") === roles.SUPER_ADMIN ? (
                    <AdminPop />
                  ) : getLocalStorage("role") === roles.ACCOUNT_MANAGER &&
                    (getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12) ? (
                    <AccountManager />
                  ) : getLocalStorage("role") === roles.CLIENT &&
                    (getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12 ) ? (
                    <ClientDashboard />
                  ) : getLocalStorage("role") === roles.HDM_CLIENT &&
                    (getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) ? (
                    <HDMClientDashboard />
                  ) : (
                    getLocalStorage("role") === roles.HDM_ACCOUNT_MANAGER &&
                    (getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) && <HDMAccountManager />
                  )
                }
              />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Login />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
      </QueryClientProvider>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthnticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(App);