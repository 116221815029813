import React, { useEffect, useState } from 'react';
import ABTests from '../CreativeResources/ABTests/ABTests';
import { Button } from '@material-ui/core';
import ABTestAddModal from '../CreativeResources/ABTests/ABTestAddModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAbTests } from '../../api/creativeApproval/slice';
import { updateCompoReduxClient } from '../../api/componentTracking/action';
const ABTesting = ({ isClient }) => {
  const dispatch = useDispatch();
  const abTests = useSelector(state => state.creativeApproval.abTests);
  const [modal, setmodal] = useState(false);
  const [read, isRead] = useState(true);

  useEffect(() => {
      dispatch(fetchAbTests());
  }, []);

  return (
    <>
      <ABTests isClient={isClient} read={read} isRead={isRead}/>
      <div className='preview_footer_row'>
        <div className='footer_con' style={{ justifyContent: 'end', gap: 16 }}>
          {!isClient && (
            <Button
              style={{
                width: '172px',
                height: '40px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'capitalize',
                background: '#FFFFFF',
                border: '1.5px solid #0869FB',
                color: '#0869FB',
              }}
              onClick={() => setmodal(true)}
            >
              + Add New A/B Test
            </Button>
          )}
          <Button
            style={{
              width: '172px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              textTransform: 'capitalize',
              background: '#0869FB',
            }}
            onClick={() => window.location.href = '/analytic-dashboard'}
          >
            PROCEED
          </Button>
        </div>
        {modal && <ABTestAddModal setModal={setmodal} />}
      </div>
    </>
  );
};

export default ABTesting;
