import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateCompoReduxClient } from "../../../api/componentTracking/action";
import { useDispatch, useSelector } from "react-redux";
import { updateTab } from "../../../api/componentTracking/action";
import { headerTabs } from "../../../utils/navbarUtil";
import Button from "@mui/material/Button";
import { updateHeader } from "../../../api/componentTracking/reducer";
import { completedFlag } from "../Questions/QuestionaireContainer";
import { scopeWorkMapping } from "../../AccountManager/HeaderNavSteps";
import {
  clientDashboardIds,
  LDcomponentWithIndexClient,
} from "../../../utils/clientDashboardIdsUtil";

const disableTab = (reduxState, clientCurrentCompoToRender, name) => {
  const platformSelected = reduxState.onboardingInfo?.platformInfo?.platforms;
  if (
    (name === "GOOGLE" &&
      platformSelected?.findIndex(
        (platform) => platform.platform === "google-ads"
      ) === -1) ||
    (name === "FACEBOOK" &&
      platformSelected?.findIndex(
        (platform) => platform.platform === "facebook-ads"
      ) === -1)
  ) {
    return true;
  } else if (
    clientCurrentCompoToRender === 26 &&
    reduxState.proposalIterations.length !== 0 &&
    reduxState.proposalIterations[reduxState.proposalIterations.length - 1]
      .state === "APPROVED"
  ) {
    return true;
  } else if (reduxState?.companyInfo[scopeWorkMapping[name]] === false) {
    return true;
  } else if (
    clientCurrentCompoToRender === 26 &&
    reduxState.proposalIterations.length !== 0 &&
    reduxState.proposalIterations[reduxState.proposalIterations.length - 1]
      .state === "CHANGES_SUGGESTED"
  ) {
    return true;
  } else if (
    name === "FB TARGETING" &&
    platformSelected?.findIndex(
      (platform) => platform.platform === "facebook-ads"
    ) === -1
  ) {
    return true;
  } else if (
    name === "GOOGLE TARGETING" &&
    platformSelected?.findIndex(
      (platform) => platform.platform === "google-ads"
    ) === -1
  ) {
    return true;
  }
  return false;
};
const HeaderNavSteps = ({
  headerNavList,
  active,
  visitedLastTime,
  clientCurrentCompoToRender,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.client);
  const [visitedLastTimeTemp, setVisitedLastTime] = useState(visitedLastTime);
  const platformSelected = useSelector(
    (state) => state?.client?.onboardingInfo?.platformInfo?.platforms
  );
  console.log("Header Nav:", headerNavList);
  useEffect(() => {
    let maxCompo = visitedLastTime;
    console.log("reduxState", reduxState, clientCurrentCompoToRender);
    if (reduxState.onboardingInfo.personalInfo.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST10]
      );
    }
    if (reduxState.onboardingInfo.platformInfo.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[
          clientDashboardIds.PLATFORM_INFORMATION_QUEST6
        ]
      );
    }
    if (reduxState.onboardingInfo.growthGoal.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[clientDashboardIds.GROWTH_GOALS_QUEST1]
      );
    }
    if (reduxState.published) {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[clientDashboardIds.PROPOSAL_TAB]
      );
    }
    if (
      reduxState.proposalIterations.length !== 0 &&
      ["CHANGES_APPLIED", "GENERATED"].includes(
        reduxState.proposalIterations[reduxState.proposalIterations.length - 1]
          .state
      )
    ) {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[clientDashboardIds.SUGGEST_CHANGES_TAB]
      );
    }
    if (reduxState.intakeInfo.productServiceInfo.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM9]
      );
    }
    if (reduxState.intakeInfo.preferences.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[
          clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES5
        ]
      );
    }
    if (reduxState.intakeInfo.audiences.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[
          clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE12
        ]
      );
    }
    if (reduxState.intakeInfo.brandAssets.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[
          clientDashboardIds.QUEST_INTAKE_FORM_BRANDING7
        ]
      );
    }
    if (reduxState.intakeInfo.customerTestimonial.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[
          clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF3
        ]
      );
    }
    if (
      reduxState.strategyIterations.length !== 0 &&
      (reduxState.strategyIterations[reduxState.strategyIterations.length - 1]
        .state === "GENERATED" ||
        reduxState.strategyIterations[reduxState.strategyIterations.length - 1]
          .state === "CHANGES_APPLIED")
    ) {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[
          clientDashboardIds.STRATEGY_DECK_SUGGEST_CHANGES
        ]
      );
    }
    if (
      reduxState.strategyIterations.length !== 0 &&
      reduxState.strategyIterations[reduxState.strategyIterations.length - 1]
        .state === "APPROVED"
    ) {
      maxCompo = Math.max(
        LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING5]
      );
    }
    if (
      clientCurrentCompoToRender >=
      LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_INTRO]
    ) {
      maxCompo = Math.max(
        maxCompo,
        LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_FACEBOOK]
      );
    }
    setVisitedLastTime(maxCompo);
  }, [visitedLastTime, reduxState]);
  return (
    <>
      {Object.values(headerNavList).map(
        ({ name, isActive, id, icon }, index) => {
          return (
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                  name === active
                    ? "#F1F7FF"
                    : [
                        LDcomponentWithIndexClient[
                          clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES
                        ],
                        LDcomponentWithIndexClient[
                          clientDashboardIds.CREATIVE_APPROVAL_CREATIVES
                        ],
                        LDcomponentWithIndexClient[
                          clientDashboardIds.CREATIVE_APPROVAL_LANDING_PAGES
                        ],
                        LDcomponentWithIndexClient[
                          clientDashboardIds.CREATIVE_APPROVAL_AB_TEST
                        ],
                      ].includes(id)
                    ? ""
                    : visitedLastTimeTemp < id
                    ? "rgb(235 235 228 / 90%)"
                    : disableTab(reduxState, clientCurrentCompoToRender, name)
                    ? "rgb(235 235 228 / 90%)"
                    : "",
                color: name === active ? "#0869FB" : "",
                width: `calc(100%/${Object.values(headerNavList).length})`,
                // borderRadius:0.5rem,
              }}
              disabled={
                [
                  LDcomponentWithIndexClient[
                    clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES
                  ],
                  LDcomponentWithIndexClient[
                    clientDashboardIds.CREATIVE_APPROVAL_CREATIVES
                  ],
                  LDcomponentWithIndexClient[
                    clientDashboardIds.CREATIVE_APPROVAL_LANDING_PAGES
                  ],
                  LDcomponentWithIndexClient[
                    clientDashboardIds.CREATIVE_APPROVAL_AB_TEST
                  ],
                ].includes(id)
                  ? false
                  : visitedLastTimeTemp >= id
                  ? disableTab(reduxState, clientCurrentCompoToRender, name)
                    ? true
                    : false
                  : true
              }
              key={index}
              onClick={() => {
                dispatch(updateTab(id));
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: name === active ? "#0869FB" : "#505050",
                  display: "flex",
                  alignItems: "center",
                  gap: ["PRICING", "CRO SETUP"].includes(name) ? 8 : 0,
                }}
              >
                {icon && <img src={icon} alt="" />}
                {name}
              </Typography>
            </Button>
          );
        }
      )}
    </>
  );
};

export default HeaderNavSteps;
