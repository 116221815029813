import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBackAmCompo } from '../../../api/accountManager/componentTracking/action';
import { getClientDetailsByFormId } from '../../../api/client/action';
import GoToNextCompoButton from '../GoToNextCompoButton';
import { goToSpecificCompoClient } from '../../../api/accountManager/componentTracking/action';
import { Google } from '@mui/icons-material';
import PrimaryButton from '../ButtonPrimary/Buttons';
import { getPmaxStrategies } from '../../../api/pmaxStrategy/slice';
import { accountManagerIds, isPlatformSelected, ldComponentWithIndexAm } from '../../../utils/accountManagerUtil';

const totalBudget = (clientState, pmaxBudget) => {
  console.log('clientstate', clientState);
  let total = 0;
  let isThere = false;
  if (clientState.googleSearchAdsStrategyInfo.brandCampaign.budget) {
    total += parseInt(
      clientState.googleSearchAdsStrategyInfo.brandCampaign.budget
    );
    isThere = true;
  }
  if (clientState.googleSearchAdsStrategyInfo.competitorCampaign.budget) {
    total += parseInt(
      clientState.googleSearchAdsStrategyInfo.competitorCampaign.budget
    );
    isThere = true;
  }
  if (clientState.googleSearchAdsStrategyInfo.serviceCampaign.budget) {
    total += parseInt(
      clientState.googleSearchAdsStrategyInfo.serviceCampaign.budget
    );
    isThere = true;
  }

  if (clientState.fbAdsStrategyInfo.bottomOfTheFunnel.budget) {
    total += parseInt(clientState.fbAdsStrategyInfo.bottomOfTheFunnel.budget);
    isThere = true;
  }
  if (clientState.fbAdsStrategyInfo.middleOfTheFunnel.budget) {
    total += parseInt(clientState.fbAdsStrategyInfo.middleOfTheFunnel.budget);
    isThere = true;
  }
  if (clientState.fbAdsStrategyInfo.topOfTheFunnelInterest.budget) {
    total += parseInt(
      clientState.fbAdsStrategyInfo.topOfTheFunnelInterest.budget
    );
    isThere = true;
  }
  if (clientState.fbAdsStrategyInfo.topOfTheFunnelLookalike.budget) {
    total += parseInt(
      clientState.fbAdsStrategyInfo.topOfTheFunnelLookalike.budget
    );
    isThere = true;
  }

  if (isThere) {
    Object.values(pmaxBudget || {}).map(value => {
      total += parseInt(value);
    })
    return total;
  } else {
    let spend = 0;
    clientState.onboardingInfo.platformInfo.platforms.forEach((platforrm) => {
      spend += parseInt(platforrm.spends);
    });
    Object.values(pmaxBudget || {}).map(value => {
      spend += parseInt(value);
    })
    return spend;
  }
};
const findCostPerConversion = (clientState, platform) => {
  if (!platform) return 0;
  return (
    clientState?.onboardingInfo?.platformInfo?.platforms?.find(
      (plat) => plat.platform === platform
    )?.cpa || 0
  );
};

const StrategyDeckIntro = () => {
  const dispatch = useDispatch();
  const clientState = useSelector((state) => state.form.form);
  const isProposalGenerating = clientState?.onboardingFormCompleted;
  const isProposalPublished = clientState?.published;
  const strategyIterations = clientState?.strategyIterations;
  const platformSelected = useSelector(
    (state) => state.form?.form?.onboardingInfo?.platformInfo?.platforms
  );

  const pmaxBudget = useSelector(store => store.pmaxStrategy.budget);
  let currentCompoAm = useSelector(
    (state) => state.amCurrentComp.currentCompAm
  );
  useEffect(() => {
    dispatch(getClientDetailsByFormId());
    dispatch(getPmaxStrategies());
  }, []);
  const publishedDate = (date) => moment(date).format('YYYY-MM-DD');
  return (
    <div className='intro_strategy_main'>
      <div
        className='intro_strategy_layout'
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {/* {strategyIterations.length !== 0 && (strategyIterations[strategyIterations.length - 1].state === "CHANGES_SUGGESTED") && <div className="intro_strategy_warning">
                    <span className="icon orange_check">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.75" width="20" height="20" rx="10" fill="#F99F35"/>
                            <path d="M6 10.7832L8.86226 13.65L14.7592 7.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                    <p>You are viewing The Strategy copy generated on {publishedDate(strategyIterations[strategyIterations.length - 1].stateTs)}. Your suggested  changes have been registered and you will be notified soon</p>
                </div>}   */}
        <div
          className='intro_strategy_banner'
          style={{
            marginTop: 12,
          }}
        >
          <div className='intro_banner_info'>
            <h2>{clientState?.onboardingInfo?.personalInfo?.company}</h2>
            <p>2023 Strategy Deck</p>
          </div>
          <div className='intro_banner_footer'>
            <div>
              <p>
                {clientState?.onboardingInfo?.personalInfo?.company} |{' '}
                {clientState?.strategyIterations?.length !== 0 &&
                  publishedDate(
                    clientState?.strategyIterations[
                      clientState?.strategyIterations.length - 1
                    ]?.stateTs
                  )}
              </p>
              <p>
                Proposal prepared for:{' '}
                {clientState?.onboardingInfo?.personalInfo?.primaryContactName}
              </p>
            </div>
            <div>
              <p>625 W Adams St Chicago,IL 60661,USA</p>
              <p>
                <a href='mailto:luke@lineardesign.com'>luke@lineardesign.com</a>
              </p>
            </div>
          </div>
        </div>
        <div
          className='intro_statatics_row'
          style={{
            marginTop: 14,
            marginBottom: 14,
          }}
        >
          <div className='intro_statatics_column'>
            <h3>${totalBudget(clientState, pmaxBudget)}</h3>
            <p>Total Daily Budget</p>
          </div>
          {(platformSelected || []).findIndex(
            (platform) => platform.platform === 'google-ads'
          ) !== -1 && (
            <div className='intro_statatics_column'>
              <h3>${findCostPerConversion(clientState, 'google-ads')}</h3>
              <p>
                Cost/Conversion
                <br />
                <span>(For Google)</span>
              </p>
            </div>
          )}
          {(platformSelected || []).findIndex(
            (platform) => platform.platform === 'facebook-ads'
          ) !== -1 && (
            <div className='intro_statatics_column'>
              <h3>${findCostPerConversion(clientState, 'facebook-ads')}</h3>
              <p>
                Cost/Conversion
                <br />
                <span>(For Facebook)</span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='preview_footer_row'>
        <div
          className='footer_content'
          style={{ justifyContent: 'space-between' }}
        >
          <button
            className='backbutton'
            style={{cursor: 'pointer'}}
            onClick={() => {
              if(isPlatformSelected(clientState, "facebook-ads") === -1){
                dispatch(goToSpecificCompoClient(ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY]))
              } else{
                dispatch(goBackAmCompo());
              }
            }}
          >
            BACK
          </button>
          <PrimaryButton
            title='PROCEED'
            onClick={() => {
              platformSelected?.findIndex(
                (platform) => platform.platform === 'google-ads'
              ) === -1
                ? dispatch(goToSpecificCompoClient(currentCompoAm + 2))
                : dispatch(goToSpecificCompoClient(currentCompoAm + 1));
            }}
          />{' '}
        </div>
      </div>
    </div>
  );
};
export default StrategyDeckIntro;
