import React, { useEffect, useState } from "react";
import AssetGroupTabListView from "../../PMaxStrategyCreation/AssetGroupTabListView";
import { Button, Typography, TextField, Select, MenuItem } from "@mui/material";
import infoIcon from "../../PMaxStrategyCreation/pmaxIcons/info-icon.svg";
import readIcon from "../../PMaxStrategyCreation/pmaxIcons/read-icon.svg";
import selectedReadIcon from "../../PMaxStrategyCreation/pmaxIcons/selected-read-icon.svg";
import writeIcon from "../../PMaxStrategyCreation/pmaxIcons/write-icon.svg";
import selectedWriteIcon from "../../PMaxStrategyCreation/pmaxIcons/selected-write-icon.svg";
import "./pmax-campaign-asset.scss";
import PMaxCampaignTargetting from "./PMaxCampaignTargetting";
import PMaxCampaignDetailedView from "./PMaxCampaignDetailedView";
import ErrorTooltip from "../../TooltipIndicators/ErrorTooltip";
import { PMaxCampaignName } from "../../../api/pmaxCampaign/enum";
import { useDispatch, useSelector } from "react-redux";
import { createPmaxCampaign, deletePmaxCamapaignAsset, getPmaxCampaigns, updateData, updatePmaxCampaign } from "../../../api/pmaxCampaign/slice";
import { BACKEND_STATUS, canPMaxConfirm, backendInterestDemoToFrontendInterestDemo, frontendInterestDemoToBackendInterestDemo } from '../../../utils/accountManagerUtil';
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
import { updateStartLoader } from "../../../api/pmaxStrategy/slice";


const textStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '19px',
  display: 'flex',
  alignItems: 'center',
  gap: 6,
};
const campaignTextStyle = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '19px',
  color: '#323232',
  gap: 6,
};

const PMaxCampaignAssetGroup = (props) => {
  console.log('props ---------------', props);
  const dispatch = useDispatch();

  const [read, isRead] = useState(true);
  const [assetGroups, setAssetGroups] = useState([]);
  const [currentAssetNumber, setCurrentAssetNumber] = useState(0);
  const [currentCampaign, setCurrentCampaign] = useState({})

  const [assetGroupName, setAssetGroupName] = useState('');
  const [finalUrl, setFinalUrl] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [displayPath1, setDisplayPath1] = useState('');
  const [displayPath2, setDisplayPath2] = useState('');

  const [audienceType, setAudienceType] = useState('PREDEFINED');
  const [newAudienceName, setNewAudienceName] = useState('');
  const [selectedExistingAudience, setSelectedExistingAudience] =
    useState(null);
  const [selectedSegment, setSelectedSegment] = useState([]);
  const [selectedYourData, setSelectedYourData] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [genders, setGenders] = useState([]);
  const [minAge, setMinAge] = useState(null);
  const [maxAge, setMaxAge] = useState(null);
  const [parentalStatus, setParentalStatus] = useState([]);
  const [minHouseholdIncome, setMinHouseholdIncome] = useState('');
  const [maxHouseholdIncome, setMaxHouseholdIncome] = useState('');

  const [headlines, setHeadlines] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [longHeadlines, setLongHeadlines] = useState([]);
  const [logoUrls, setlogoUrls] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [ytVideoUrls, setYtVideoUrls] = useState([]);
  const [hasError, setHasError] = useState(true);

  const [errors, setErrors] = useState({});
  const [currentAssetCount, setCurrentAssetCount] = useState(0);
  const startLoader = useSelector((state) => state.pmaxStrategy.startLoader);

  const [campaign1AssetGroups, campaign2AssetGroups, campaign3AssetGroups] =
    useSelector((store) => [
      store.pmaxCampaign.campaign1AssetGroups,
      store.pmaxCampaign.campaign2AssetGroups,
      store.pmaxCampaign.campaign3AssetGroups,
    ]);
  
    const [campaign1, campaign2, campaign3] =
    useSelector((store) => [
      store.pmaxCampaign.campaign1,
      store.pmaxCampaign.campaign2,
      store.pmaxCampaign.campaign3,
    ]);

  const [existingAudience, customSegments, userList] = useSelector((store) => [
    store.googleCustomers.audience,
    store.pmaxStrategy.customSegments,
    store.googleCustomers.users,
  ]);

  const [imageAssetLibrary, logoAssetLibrary, videoAssetLibrary] = useSelector(
    (store) => [
      store.pmaxStrategy.imageAssetLibrary,
      store.pmaxStrategy.logoAssetLibrary,
      store.pmaxStrategy.videoAssetLibrary,
    ]
  );
  const interestAndDemographies = useSelector(
    (store) => store.pmaxCampaign.interestAndDemographies
  );

  useEffect(() => {
    if(existingAudience?.length && audienceType === "PREDEFINED" && !selectedExistingAudience){
      setSelectedExistingAudience(existingAudience[0]);
    }
  }, [existingAudience])

  useEffect(() => {
    if (props.campaignNumber === PMaxCampaignName.campaign1) {
      setAssetGroups(campaign1AssetGroups);
      setCurrentCampaign(campaign1)
    }
    if (props.campaignNumber === PMaxCampaignName.campaign2) {
      setAssetGroups(campaign2AssetGroups);
      setCurrentCampaign(campaign2)
    }
    if (props.campaignNumber === PMaxCampaignName.campaign3) {
      setAssetGroups(campaign3AssetGroups);
      setCurrentCampaign(campaign3)
    }
    setCurrentAssetCount(props.campaignDetails.assetCount)
  }, [props.campaignNumber]);

  useEffect(() => {
    setHasError(!canPMaxConfirm(assetGroups));
    const currentAsset = assetGroups[currentAssetNumber];
    setErrors(currentAsset?.errors || {});
    setAssetGroupName(currentAsset?.name || '');
    setBusinessName(currentAsset?.businessName || '');
    setFinalUrl(currentAsset?.finalUrl || '');
    setDisplayPath1(currentAsset?.finalUrl1 || '');
    setDisplayPath2(currentAsset?.finalUrl2 || '');
    setHeadlines(currentAsset?.headlines || []);
    setDescriptions(currentAsset?.descriptions || []);
    setLongHeadlines(currentAsset?.longHeadlines || []);
    setlogoUrls(currentAsset?.logoUrls || []);
    setImageUrls(currentAsset?.imageUrls || []);
    setYtVideoUrls(currentAsset?.ytVideoUrls || []);
    setSelectedSegment(currentAsset?.audience?.segments || []);
    setSelectedYourData(currentAsset?.audience?.yourData || []);
    setAudienceType(currentAsset?.audience?.type || 'PREDEFINED');
    if(currentAsset?.audience?.type === 'PREDEFINED' || !currentAsset?.audience?.type){
      setSelectedExistingAudience(existingAudience?.find(aud => aud.name === currentAsset?.audience?.name) || existingAudience?.[0]);
    }else {
      setNewAudienceName(currentAsset?.audience?.name || '');
    }
    setGenders(currentAsset?.audience?.genders || []);
    setParentalStatus(currentAsset?.audience?.parentalStatus || []);
    setMinAge(currentAsset?.audience?.minAge);
    setMaxAge(currentAsset?.audience?.maxAge);
    setMinHouseholdIncome(currentAsset?.audience?.minHouseholdIncome || '');
    setMaxHouseholdIncome(currentAsset?.audience?.maxHouseholdIncome || '');
    let interestDemo = currentAsset?.audience?.interestDemographics;
    if (interestDemo) {
      setSelectedInterest(backendInterestDemoToFrontendInterestDemo(interestDemo));
    } 
  }, [currentAssetNumber, assetGroups]);

  const backClickHandler = () => {
    const currentAssetGroup = JSON.parse(JSON.stringify(assetGroups));
    const assets = {
      id: assetGroups[currentAssetNumber].id,
      assetNumber: assetGroups[currentAssetNumber].assetNumber,
      name: assetGroupName,
      status: BACKEND_STATUS.COMPLETED,
      headlines,
      descriptions,
      longHeadlines,
      imageUrls,
      logoUrls,
      ytVideoUrls,
      finalUrl,
      finalUrl1: displayPath1,
      finalUrl2: displayPath2,
      businessName,
      audience: {
        name: audienceType === 'PREDEFINED' ? selectedExistingAudience?.name: newAudienceName,
        type: audienceType,
        id:
          audienceType === 'PREDEFINED'
            ? selectedExistingAudience?.resourceName
            : null,
        genders: genders,
        parentalStatus: parentalStatus,
        minAge: minAge,
        maxAge: maxAge,
        minHouseholdIncome: minHouseholdIncome,
        maxHouseholdIncome: maxHouseholdIncome,
        yourData: selectedYourData,
        segments: selectedSegment,
        interestDemographics: frontendInterestDemoToBackendInterestDemo(selectedInterest),
      },
      errors: errors
    };

    currentAssetGroup[currentAssetNumber] = assets;
    if (props.campaignNumber === PMaxCampaignName.campaign1) {
      dispatch(
        updateData({
          campaign1AssetGroups: currentAssetGroup,
          campaign1: {
            ...campaign1,
            ...(props.campaignDetails),
            assetCount: currentAssetCount
          }
        })
      );
    }
    if (props.campaignNumber === PMaxCampaignName.campaign2) {
      dispatch(
        updateData({
          campaign2AssetGroups: currentAssetGroup,
          campaign2: {
            ...campaign2,
            ...(props.campaignDetails),
            assetCount: currentAssetCount
          }
        })
      );
    }
    if (props.campaignNumber === PMaxCampaignName.campaign3) {
      dispatch(
        updateData({
          campaign3AssetGroups: currentAssetGroup,
          campaign3: {
            ...campaign3,
            ...(props.campaignDetails),
            assetCount: currentAssetCount
          }
        })
      );
    }
    props.setViewAssetGroup(false);
  };

  const handleAddNewAsset = () => {
    const currentAssetGroup = JSON.parse(JSON.stringify(assetGroups));
    const totalAssetGroup = currentAssetGroup?.length;
    currentAssetGroup[currentAssetNumber] = {
      id: currentAssetGroup[currentAssetNumber].id,
      assetNumber: currentAssetGroup[currentAssetNumber].assetNumber,
      name: assetGroupName,
      headlines,
      descriptions,
      longHeadlines,
      imageUrls,
      logoUrls,
      ytVideoUrls,
      finalUrl,
      finalUrl1: displayPath1,
      finalUrl2: displayPath2,
      businessName,
      audience: {
        name: newAudienceName,
        type: audienceType,
        id:
          audienceType === 'PREDEFINED'
            ? selectedExistingAudience?.resourceName
            : null,
        genders: genders,
        parentalStatus: parentalStatus,
        minAge: minAge,
        maxAge: maxAge,
        minHouseholdIncome: minHouseholdIncome,
        maxHouseholdIncome: maxHouseholdIncome,
        yourData: selectedYourData,
        segments: selectedSegment,
        interestDemographics: frontendInterestDemoToBackendInterestDemo(selectedInterest),
      },
      errors,
    };
    setAssetGroups([...currentAssetGroup, {assetNumber: currentAssetCount+1}]);
    setCurrentAssetNumber(totalAssetGroup);
    setCurrentAssetCount(currentAssetCount+1)
  };

  const handleRemoveAssetGroup = (index) => {
    const currentAssetGroup = JSON.parse(JSON.stringify(assetGroups));
    const callback = () => {
      currentAssetGroup.splice(index, 1);
      setAssetGroups(currentAssetGroup);
      if (currentAssetNumber === index || assetGroups.length === 1) {
        setCurrentAssetNumber(0);
      }
    }
    if(currentAssetGroup[index].id && props.campaignDetails?.id){
      dispatch(updateStartLoader(true));
      dispatch(deletePmaxCamapaignAsset({campaignId: props.campaignDetails?.id, assetId: currentAssetGroup[index].id, callback: callback}));
    } else {
      currentAssetGroup.splice(index, 1);
      if (currentAssetNumber === index || assetGroups.length === 1) {
        setCurrentAssetNumber(0);
      }
      setAssetGroups(currentAssetGroup);
    }
  };

  const confirmAdsetHandler = () => {
    dispatch(updateStartLoader(true));
    const currentAssetGroup = JSON.parse(JSON.stringify(assetGroups));
    const assets = {
      id: assetGroups[currentAssetNumber].id,
      assetNumber: assetGroups[currentAssetNumber].assetNumber,
      name: assetGroupName,
      status: BACKEND_STATUS.COMPLETED,
      headlines,
      descriptions,
      longHeadlines,
      imageUrls,
      logoUrls,
      ytVideoUrls,
      finalUrl,
      finalUrl1: displayPath1,
      finalUrl2: displayPath2,
      businessName,
      audience: {
        name: audienceType === 'PREDEFINED' ? selectedExistingAudience?.name: newAudienceName,
        type: audienceType,
        id:
          audienceType === 'PREDEFINED'
            ? selectedExistingAudience?.resourceName
            : null,
        genders: genders,
        parentalStatus: parentalStatus,
        minAge: minAge,
        maxAge: maxAge,
        minHouseholdIncome: minHouseholdIncome,
        maxHouseholdIncome: maxHouseholdIncome,
        yourData: selectedYourData,
        segments: selectedSegment,
        interestDemographics: frontendInterestDemoToBackendInterestDemo(selectedInterest),
      },
    };

    currentAssetGroup[currentAssetNumber] = assets;
    const campaignDetails = props.campaignDetails;
    const body = {
      ...(campaignDetails || {}),
      assets: [assets],
      assetCount: currentAssetCount
    };

    const callbackToPut = (error, response) => {
      setErrors(error || {});
      dispatch(getPmaxCampaigns());
      if (error) {
        currentAssetGroup[currentAssetNumber].errors = error;
      }
      setAssetGroups(currentAssetGroup);
    };

    const callbackToPost = (error, response) => {
      setErrors(error || {});
      if (error) {
        currentAssetGroup[currentAssetNumber].errors = error;
      }
      setAssetGroups(currentAssetGroup);
    };
    if (props.campaignDetails?.id) {
      dispatch(
        updatePmaxCampaign({
          campaignId: props.campaignDetails?.id,
          body,
          callback: callbackToPut,
        })
      );
    } else {
      dispatch(
        createPmaxCampaign({
          data: body,
          callback: callbackToPost,
        })
      );
    }
  };

  const confirmCampaign = () => {
    dispatch(updateStartLoader(true));
    const currentAssetGroup = JSON.parse(JSON.stringify(assetGroups));
    const asset = {
      id: assetGroups[currentAssetNumber].id,
      name: assetGroupName,
      headlines,
      descriptions,
      longHeadlines,
      imageUrls,
      logoUrls,
      ytVideoUrls,
      finalUrl,
      finalUrl1: displayPath1,
      finalUrl2: displayPath2,
      businessName,
      audience: {
        name: audienceType === 'PREDEFINED' ? selectedExistingAudience?.name: newAudienceName,
        type: audienceType,
        id:
          audienceType === 'PREDEFINED'
            ? selectedExistingAudience?.resourceName
            : null,
        genders: genders,
        parentalStatus: parentalStatus,
        minAge: minAge,
        maxAge: maxAge,
        minHouseholdIncome: minHouseholdIncome,
        maxHouseholdIncome: maxHouseholdIncome,
        yourData: selectedYourData,
        segments: selectedSegment,
        interestDemographics: frontendInterestDemoToBackendInterestDemo(selectedInterest),
      },
    };
    currentAssetGroup[currentAssetNumber] = asset;
    const body = {
      ...(props.campaignDetails || {}),
      assets: currentAssetGroup,
      assetCount: currentAssetCount,
      status: BACKEND_STATUS.COMPLETED,
    };
    const callback = (error, response) => {
      dispatch(getPmaxCampaigns());
      props.setViewAssetGroup(false);
    };

    if (props.campaignDetails?.id) {
      dispatch(
        updatePmaxCampaign({
          campaignId: props.campaignDetails?.id,
          body,
          callback: callback,
        })
      );
    }
  };
  // const budgetTextStyle = {
  //   ...textStyle,
  //   marginLeft: -212,
  // };

  console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",  props, currentAssetCount, assetGroups);
  return (
    <div>
      <AssetGroupTabListView
        currCamp={assetGroups}
        handleAddNewAsset={handleAddNewAsset}
        handleChangeAssetGroup={(index) => setCurrentAssetNumber(index)}
        handleRemoveAssetGroup={handleRemoveAssetGroup}
        currAssetCounts={currentAssetCount}
        forCampaign={true}
      />
      <div className='pmax-campaign-detail'>
        <Typography style={textStyle}>
          Asset Group Name{' '}
          {errors?.assetGroupName && (
            <ErrorTooltip error={errors?.assetGroupName} />
          )}
          :{' '}
          {read ? (
            <span className='pmax-field-value'>
              {assetGroupName || 'Name goes here'}
            </span>
          ) : (
            <TextField
              variant='standard'
              placeholder='Name goes here'
              value={assetGroupName}
              onChange={(e) => setAssetGroupName(e.target.value)}
              inputProps={{
                style: {
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,

                  lineHeight: '19px',
                  width: 150,
                  paddingBottom: 2,
                  border: 0,
                },
              }}
            />
          )}
        </Typography>
        <Typography style={textStyle}>
          Business Name{' '}
          {errors?.businessName && (
            <ErrorTooltip error={errors?.businessName} />
          )}
          :{' '}
          {read ? (
            <span className='pmax-field-value'>
              {businessName || 'Name goes here'}
            </span>
          ) : (
            <TextField
              variant='standard'
              placeholder='Name goes here'
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              inputProps={{
                style: {
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: '19px',
                  width: 150,
                  paddingBottom: 2,
                  border: 0,
                },
              }}
            />
          )}
        </Typography>
        <>
          <div
            className='read-write-icon'
            style={{
              display: 'flex',
              flexDirection: 'row',
              // marginTop: -4,
              // paddingBottom: 12,
            }}
          >
            <div
              className='read-icon'
              style={{
                width: 40,
                height: 40,
                border: read ? '1px solid #0869FB' : '1px solid #CCCCCC',
                borderRadius: '8px 0px 0px 8px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                background: read ? 'rgba(8, 105, 251, 0.07)' : '',
              }}
              onClick={() => isRead(true)}
            >
              <img src={read ? selectedReadIcon : readIcon} alt='' />
            </div>
            <div
              className='write-icon'
              style={{
                width: 40,
                height: 40,
                border: !read ? '1px solid #0869FB' : '1px solid #CCCCCC',
                borderRadius: '0px 8px 8px 0px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                background: !read ? 'rgba(8, 105, 251, 0.07)' : '',
              }}
              onClick={() => isRead(false)}
            >
              <img src={!read ? selectedWriteIcon : writeIcon} alt='' />
            </div>
          </div>
        </>
      </div>
      <div
        className='pmax-campaign-asset-group-container'
        style={{
          overflowY: 'auto',
        }}
      >
        <div className='pmax-campaign-action'>
          <div className='pmax-campaign-path-container'>
            <div className='pmax-campaing-path-url-section'>
              <Typography style={campaignTextStyle}>
                Final URL{' '}
                {errors?.finalUrl && <ErrorTooltip errors={errors?.finalUrl} />}
              </Typography>
              <TextField
                variant='outlined'
                placeholder='Enter Text'
                value={finalUrl}
                onChange={(e) => setFinalUrl(e.target.value)}
                inputProps={{
                  style: {
                    color: '#333333',
                    width: 328,
                    height: 36,
                    background: '#FFFFFF',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 11.5,
                    padding: '0 0 0 16px',
                  },
                }}
              />
            </div>
            <div className='campaign-separator'>/</div>
            <div className='pmax-campaing-path-url-section'>
              <Typography style={campaignTextStyle}>Display Path</Typography>
              <TextField
                variant='outlined'
                placeholder='Enter Text'
                value={displayPath1}
                onChange={(e) => setDisplayPath1(e.target.value)}
                inputProps={{
                  style: {
                    color: '#333333',
                    width: 154,
                    height: 36,
                    background: '#FFFFFF',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 11.5,
                    padding: '0 0 0 16px',
                  },
                }}
              />
            </div>
            <div className='campaign-separator'>/</div>
            <div className='pmax-campaing-path-url-section'>
              <TextField
                variant='outlined'
                placeholder='Enter Text'
                value={displayPath2}
                onChange={(e) => setDisplayPath2(e.target.value)}
                inputProps={{
                  style: {
                    color: '#333333',
                    width: 154,
                    height: 36,
                    background: '#FFFFFF',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 11.5,
                    padding: '0 0 0 16px',
                  },
                }}
              />
            </div>
          </div>
          {/* <div className='pmax-campaign-call-to-action'>
            <Typography style={campaignTextStyle}>Call To Action</Typography>
            <Select
              // displayEmpty
              placeholder='Choose'
              variant='outlined'
              style={{
                width: 199,
                height: 36,
                background: '#FFFFFF',
                border: '0.964432px solid #DDDDDD',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11.5,
                outline: 'none',
                boxShadow: 'none',
              }}
            >
              <MenuItem value='automated'>Automated (Recommended)</MenuItem>
              <MenuItem value='learnMore'>Learn More</MenuItem>
              <MenuItem value='getQuote'>Get quote</MenuItem>
              <MenuItem value='applyNow'>Apply Now</MenuItem>
              <MenuItem value='signUp'>Sign Up</MenuItem>
              <MenuItem value='contactUs'>Contact Us</MenuItem>
              <MenuItem value='subscribe'>Subscribe</MenuItem>
              <MenuItem value='download'>Download</MenuItem>
              <MenuItem value='bookNow'>Book Now</MenuItem>
              <MenuItem value='signUp'>Sign Up</MenuItem>
            </Select>
          </div> */}
        </div>
        <div className='pmax-campaign-targeting-and-detailed-view'>
          <PMaxCampaignTargetting
            read={read}
            errors={errors}
            existingAudience={existingAudience}
            selectedExistingAudience={selectedExistingAudience}
            setSelectedExistingAudience={setSelectedExistingAudience}
            audienceType={audienceType}
            setAudienceType={setAudienceType}
            newAudienceName={newAudienceName}
            setNewAudienceName={setNewAudienceName}
            customSegments={customSegments}
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
            userList={userList}
            selectedYourData={selectedYourData}
            setSelectedYourData={setSelectedYourData}
            interestAndDemographies={interestAndDemographies}
            selectedInterest={selectedInterest}
            setSelectedInterest={setSelectedInterest}
            genders={genders}
            setGenders={setGenders}
            minAge={minAge}
            setMinAge={setMinAge}
            maxAge={maxAge}
            setMaxAge={setMaxAge}
            parentalStatus={parentalStatus}
            setParentalStatus={setParentalStatus}
            minHouseholdIncome={minHouseholdIncome}
            setMinHouseholdIncome={setMinHouseholdIncome}
            maxHouseholdIncome={maxHouseholdIncome}
            setMaxHouseholdIncome={setMaxHouseholdIncome}
            disabled={ props.isDisabled}
          />
          <PMaxCampaignDetailedView
            read={read}
            errors={errors}
            headlines={headlines}
            setHeadlines={setHeadlines}
            descriptions={descriptions}
            setDescriptions={setDescriptions}
            longHeadlines={longHeadlines}
            setLongHeadlines={setLongHeadlines}
            logoUrls={logoUrls}
            setlogoUrls={setlogoUrls}
            imageUrls={imageUrls}
            setImageUrls={setImageUrls}
            ytVideoUrls={ytVideoUrls}
            setYtVideoUrls={setYtVideoUrls}
            imageAssetLibrary={imageAssetLibrary}
            videoAssetLibrary={videoAssetLibrary}
            logoAssetLibrary={logoAssetLibrary}
            disabled={ props.isDisabled}
          />
        </div>
      </div>
      <div className='preview_footer_row'>
        <div
          className='footer_con'
          style={{ justifyContent: 'flex-start', gap: 16 }}
        >
          <Button
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textTransform: 'capitalize',
              background: '#FFFFFF',

              color: '#0869FB',
            }}
            onClick={backClickHandler}
          >
            BACK
          </Button>
          <Button
            onClick={confirmAdsetHandler}
            disabled={ props.isDisabled }
            style={{
              width: '172px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textTransform: 'capitalize',
              background: '#FFFFFF',
              border: '1.5px solid #0869FB',
              color: '#0869FB',
              marginLeft: '39rem',
            }}
          >
            Confirm Asset Group
          </Button>
          <Button
            onClick={confirmCampaign}
            disabled={ props.isDisabled || hasError}
            style={{
              width: '172px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              textTransform: 'capitalize',
              background: '#0869FB',
              opacity: hasError ? '0.4' : '1',
            }}
          >
            Confirm Campaign
          </Button>
        </div>
        {startLoader && <PmaxLoader />}
      </div>
    </div>
  );
};

export default PMaxCampaignAssetGroup;
