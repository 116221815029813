import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import BpRadio from '../../ClientDashboard/CustomRadio/Radio';
import { ExpandMore } from '@mui/icons-material';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};
const MenuRadioList = (props) => {
  const [selectedOption, setSelectedOption] = useState(
    props.selected || 'Enter Text'
  );
  useEffect(() => {
    if (props.selected) {
      setSelectedOption(props.selected);
    }
  }, [props]);
  return (
    <>
      <Select
        disabled={props.disabled}
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        value={selectedOption}
        renderValue={(selected) => selected}
        MenuProps={MenuProps}
        variant="outlined"
        placeholder="Enter Text"
        // IconComponent={() => (
        //   <ExpandMore
        //     fontSize="small"
        //     sx={{ position: "absolute", right: 7 }}
        //   />
        // )}
        sx={{
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
        style={{
          width: 130,
          height: 27,
          background: '#FFFFFF',
          border: '1px solid #CCECFE',
          borderRadius: 4,
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 12,
          outline: 'none',
          color: selectedOption === 'Enter Text' ? '#666666' : '#0869FB',
        }}
      >
        <RadioGroup>
          {props.list.map((name) => (
            <MenuItem
              key={name}
              value={name}
              sx={{
                '& .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label':
                  {
                    color: 'black',
                  },
              }}
              disabled={(props.disabledItems || []).includes(name)}
            >
              <FormControlLabel
                value={name}
                control={<BpRadio />}
                label={name}
                disabled={(props.disabledItems || []).includes(name)}
                onClick={(event) => {
                  setSelectedOption(event.target.value);
                  props.setValue(event.target.value);
                }}
                checked={name === selectedOption}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </Select>
    </>
  );
};

export default MenuRadioList;
