import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_CAMPAIGN_SELECTOR_STAGES } from "../../constants/LDConstants";
import GoogleCampaignSelector from "../GoogleCampaignSelector/GoogleCampaignSelector";
import {
  BACKEND_STATUS,
  CRON_STATUS_BACKEND,
  accountManagerIds,
  ldComponentWithIndexAm,
  componentWithIndexHDMAm,
  accountManagerIdsHDM,
} from "../../utils/accountManagerUtil";
import { goToSpecificCompoClientAm } from "../../api/accountManager/componentTracking/action";
import { startCronPMaxStrategy } from "../../api/pmaxStrategy/slice";
import { goToSpecificCompoHDMAM } from "../../api/HDMAM/componentTracking/action";
import { getAdPixels } from "../../api/HDMAM/campaign/action";

const CampaignGoogleSelector = ({
    agencyType="LD"
}) => {
  const dispatch = useDispatch();

  const [clientForm, setClientForm] = useState();
  const [pmaxGeneratingButton, setPmaxGeneratingButton] = useState(false);
  const [pmaxOngoingButton, setPmaxOngoingButton] = useState(false);
  const [pmaxCompletedButton, setPmaxCompletedButton] = useState(false);

  const [searchGeneratingButton, setSearchGeneratingButton] = useState(false);
  const [searchOngoingButton, setSearchOngoingButton] = useState(false);
  const [searchCompletedButton, setSearchCompletedButton] = useState(false);

  const form = useSelector((store) => store.hdmFormAm);

  useEffect(() => {
    dispatch(getAdPixels());
  }, [])

  useEffect(() => {
    if (form) {
      setClientForm(form);
      if (
        false
      ) {
        // dispatch(getPmaxStrategies());
        setPmaxCompletedButton(true);
      } else if (
        [BACKEND_STATUS.COMPLETED].includes(
          form?.googlePmaxStrategy?.status
        )
        ) {
        setPmaxOngoingButton(true);
      }

      if (false) {
        setSearchCompletedButton(true);
      } else if(form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED){
        setSearchOngoingButton(true);
      }
    }
  }, [form]);

  const onClickHandlerForSearchStrategy = () => {
    if (clientForm?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED) {
      switch (agencyType) {
          case 'LD':
            dispatch(
              goToSpecificCompoClientAm(
                ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_PAGE]
              )
            );
            break;
          case 'HDM':
            dispatch(
              goToSpecificCompoHDMAM(
                componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_PAGE]
              )
            );
            break;
          default:
            console.log('no agencyType matched...');
      }
    }
  };
  const onClickHandlerForPMaxStrategySelect = () => {
    if (
      [BACKEND_STATUS.COMPLETED].includes(
        clientForm?.googlePmaxStrategy?.status
      )
    ) {
      switch (agencyType) {
          case 'LD':
            dispatch(
              goToSpecificCompoClientAm(
                ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_PAGE_PMAX]
              )
            );
              break;
            case 'HDM':
            dispatch(
              goToSpecificCompoHDMAM(
                componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_PAGE_PMAX]
              )
            );
              break;
            default:
            console.log('no agencyType matched...');
      }
    }
  };

  return (
    <GoogleCampaignSelector
      stage={GOOGLE_CAMPAIGN_SELECTOR_STAGES.Campaign}
      pmaxOngoingButton={pmaxOngoingButton}
      pmaxGeneratingButton={pmaxGeneratingButton}
      pmaxCompletedButton={pmaxCompletedButton}
      pmaxClickHandler={onClickHandlerForPMaxStrategySelect}
      searchOngoingButton={searchOngoingButton}
      searchGeneratingButton={searchGeneratingButton}
      searchCompletedButton={searchCompletedButton}
      searchClickHandler={onClickHandlerForSearchStrategy}
    />
  );
};

export default CampaignGoogleSelector;
