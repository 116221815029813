import { put, takeLatest, select } from 'redux-saga/effects';
import { api } from '../../index';
import {
  createProposalReducer,
  sendRefererEmailPricing,
  updateProposalData,
  updateSelectedPriceCard,
} from './slice';
import { updateData as formUpdate } from '../forms/slice';
import { BACKEND_STATUS } from '../../../utils/accountManagerUtil';

function* createProject(action) {
  const url = `/client-forms`;
  const store = yield select();
  const form = store.form.form;
  const body = { ...form };
  console.log('in client pricing saga', action.payload);
  const pricing = store.pricing.pricing;
  const sowPricingInfo = { ...body.sowPricingInfo };
  sowPricingInfo['clientPricing'] = {
    status: BACKEND_STATUS.COMPLETED,
    ...pricing[action.payload],
  };
  sowPricingInfo.status = BACKEND_STATUS.COMPLETED;
  // body.template = store.templates.selectedTemplate;
  // body.template = "80445585-f354-49f4-bcab-521df065a6ea";
  body.sowPricingInfo = sowPricingInfo;
  const formResponse = yield api.post(url, body);
  yield put(updateProposalData(true));
  yield put(formUpdate(formResponse.data));
  yield put(updateSelectedPriceCard(action.payload));
}

function* sendEmailReferer(action) {
  const { email, callback } = action.payload;
  const url = `/send_mail/email/${email}`;
  // console.log(url);
  const emailSendStatus = yield api.post(url);
  if (emailSendStatus.data === 'success') {
    callback?.();
  }
  // console.log(emailSendStatus);
}

export default function* pricingSaga() {
  yield takeLatest(createProposalReducer.type, createProject);
  yield takeLatest(sendRefererEmailPricing.type, sendEmailReferer);
}
