import { useEffect, useState } from "react";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { useTheme, styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputBase from "@mui/material/InputBase";
import { no_preview_icon as noIreviewIcon } from "../../../assets/icons/common/common";
import { Tabs, useTabState, Panel } from "../../CustomTab/customTab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  goBackAmCompo,
  goToSpecificCompoClient,
} from "../../../api/accountManager/componentTracking/action";
import StrategyDeckSureModal from "./StrategyDeckSureModal";
import ProposalPublishedModal from "../../modals/ProposalPublishedModal";
import PEOPLE from "./Vector.svg";
import Person from "./person.svg";
import CAMERA from "../../../assets/icons/pmax_campaign.svg";
import PMaxStrategyDeck from "./PmaxStrategyDeck";
import { Typography } from "@mui/material";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import {
  BACKEND_STATUS,
  CRON_STATUS_BACKEND,
  PROPOSAL_STATE,
} from "../../../utils/accountManagerUtil";
import {
  goBackHDMAmCompo,
  goToSpecificCompoHDMAM,
  updateCompoReduxHDMAM,
} from "../../../api/HDMAM/componentTracking/action";
import { api } from "../../../api";
import { getLocalStorage } from "../../../api/localStorage/localStorage";

const cn = (...args) => args.filter(Boolean).join(" ");

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button className={cn("tab", isActive && "active")} onClick={onClick}>
      {children}
    </button>
  );
};

const StrategyDeckGoogle = ({ agencyType = "LD" }) => {
  const dispatch = useDispatch();

  const [totalBudget, setTotalBudget] = useState(0);
  const [brandCampaign, setBrandCampaign] = useState({});
  const [competitorCampaign, setCompetitorCampaign] = useState({});
  const [serviceCampaign, setServiceCampaign] = useState({});
  const [sureModal, setSureModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [clientForm, setClientForm] = useState({
    pmaxCAICronStatus: "PENDING",
    pmaxTAICronStatus: "PENDING",
    googlePmaxStrategy: { status: "ONGOING" },
  });
  const [strategyCampaign1, setStrategyCamapign1] = useState([]);
  const [strategyCampaign2, setStrategyCamapign2] = useState([]);
  const [strategyCampaign3, setStrategyCamapign3] = useState([]);
  const [budgetCampaign1, setBudgetCampaign1] = useState(0);
  const [budgetCampaign2, setBudgetCampaign2] = useState(0);
  const [budgetCampaign3, setBudgetCampaign3] = useState(0);
  const platformSelected = useSelector((state) =>
    agencyType === "HDM"
      ? state.hdmFormAm?.onboardingInfo?.clientAccess?.platforms
      : state.form?.form?.onboardingInfo?.platformInfo?.platforms
  );
  const form = useSelector((store) =>
    agencyType === "HDM" ? store.hdmFormAm : store.form.form
  );
  let currentCompoAm = useSelector(
    (state) => state.amCurrentComp.currentCompAm
  );
  let currentCompoHDMAm = useSelector(
    (state) => state.hdmAMCurrentComp.currentCompAm
  );

  console.log("SC1:", strategyCampaign1);

  useEffect(() => {
    const formId = getLocalStorage("formId")
      ? getLocalStorage("formId")
      : getLocalStorage("amSelectedFormId");
    const getClientFormURI = `/client-forms/${formId}`;
    const response = api.get(getClientFormURI);

    response
      .then((res) => {
        const form = res.data;
        setClientForm(form);
      })
      .catch((err) => console.log("ERROR:", err));
  }, []);

  useEffect(() => {
    const formId = getLocalStorage("formId")
      ? getLocalStorage("formId")
      : getLocalStorage("amSelectedFormId");
    const getStrategiesURI = `/client-forms/${formId}/pmax-strategy`;
    const campaign1 = [];
    const campaign2 = [];
    const campaign3 = [];
    api
      .get(getStrategiesURI)
      .then((response) => {
        const { strategies } = response.data;

        strategies.forEach((strategy) => {
          if (strategy.name === "campaign1" || strategy.name === "Campaign1") {
            setBudgetCampaign1(strategy?.budget || 0);
            strategy.assets.forEach((asset) => campaign1.push({ ...asset }));
          } else if (strategy.name === "campaign2") {
            setBudgetCampaign2(strategy?.budget || 0);
            strategy.assets.forEach((asset) => campaign2.push({ ...asset }));
          } else {
            setBudgetCampaign3(strategy?.budget || 0);
            strategy.assets.forEach((asset) => campaign3.push({ ...asset }));
          }
        });
        if (campaign1.length) setStrategyCamapign1(campaign1);
        if (campaign2.length) setStrategyCamapign2(campaign2);
        if (campaign3.length) setStrategyCamapign3(campaign3);
      })
      .catch((err) => console.log("Error:", err?.response?.data));
  }, []);

  useEffect(() => {
    if (form) {
      const strategyInfo = form?.googleSearchAdsStrategyInfo;
      const brandCampaignInfo = strategyInfo?.brandCampaign;
      const competitorCampaignInfo = strategyInfo?.competitorCampaign;
      const serviceCampaignInfo = strategyInfo?.serviceCampaign;

      setBrandCampaign(brandCampaignInfo || {});
      setCompetitorCampaign(competitorCampaignInfo || {});
      setServiceCampaign(serviceCampaignInfo || {});
    }
  }, [form]);

  useEffect(() => {
    setTotalBudget(
      parseInt(brandCampaign?.budget || "0") +
        parseInt(competitorCampaign?.budget || "0") +
        parseInt(serviceCampaign?.budget || "0") +
        budgetCampaign1 +
        budgetCampaign2 +
        budgetCampaign3
    );
  }, [
    brandCampaign,
    competitorCampaign,
    serviceCampaign,
    budgetCampaign1,
    budgetCampaign2,
    budgetCampaign3,
  ]);

  const publishDeck = () => {
    const strategyIterations = [...(form?.strategyIterations || [])];
    if (!strategyIterations.length) {
      strategyIterations.push({
        state: PROPOSAL_STATE.GENERATED,
      });
    } else {
      strategyIterations.push({
        state: PROPOSAL_STATE.CHANGES_APPLIED,
      });
    }
    dispatch(
      updateFormReducer({
        strategyIterations: strategyIterations,
      })
    );
    setSureModal(false);
    setPublishModal(true);
  };

  return (
    <div className="intro_strategy_main">
      <div className="google_strategy_layout">
        <div className="google_strategy_banner">
          <div className="google_banner_part">
            <div className="google_strategy_row">
              <div className="google_strategy_logo">
                <span></span>
                <p>Google</p>
              </div>
              <div className="google_strategy_budget">
                <p>Total Daily Budget : ${totalBudget}</p>
              </div>
            </div>
            <p className="google_strategy_info">
              Here, you can review the strategy for running your campaigns on
              google to drive efficient delivery and performance. We recommend
              that you go through each of the recommendations here and replace
              the ones you do not want to display in your campaign strategy
              deck. Removing the ones you do not find useful, will automatically
              generate new recommendations at the bottom of each of the lists.
            </p>
          </div>
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Brand Campaign</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${brandCampaign?.budget || 0}</p>
                </div>
              </div>
              <p className="google_title_info">
                Brand Campaigns essentially help you serve ads whenever a user
                searches for anything related to your brand name. This is
                generally to ensure no competitor is eating up your prospective
                leads.
              </p>
            </div>
            {/* <div>
              <PrimaryButton title="Approve All" icon="approve" type="light" />
            </div> */}
          </div>

          {(brandCampaign?.adsets || []).map((campaign, ids) => {
            return (
              <Accordion className="accordion_strategy_body" key={ids}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>{campaign.name}</h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <div className="strategy_accordion_layout">
                    <div className="strategy_row_item">
                      <div className="strategy_column_item search_row_strategy">
                        <Autocomplete
                          open
                          multiple
                          // disableCloseOnSelect
                          noOptionsText=""
                          renderOption={(props, option, { selected }) => (
                            <li
                              {...props}
                              className="autosearch_list"
                              onClick={() => {}}
                            >
                              <div className="strategy_list_row">
                                <div className="strategy_list_column">
                                  <span className="search_icon"></span>
                                  <p>{option}</p>
                                </div>
                                <div>
                                  <div className="headline_column_actions">
                                    {/* <button
                                      className={`list_search_actions accept_icon`}
                                    ></button> */}
                                    <button
                                      className={`list_search_actions reject_icon`}
                                      disabled
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          options={[...campaign.keywords]}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <div className="search_strategy_header">
                              <span className="search_icon"></span>
                              <TextField
                                {...params}
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Search..."
                                variant="standard"
                              />
                            </div>
                          )}
                          disablePortal={true}
                          className="auto_complete_search"
                        />
                      </div>
                      <div className="strategy_column_item">
                        <Tabs>
                          <div className="custom_tab_header">
                            <Tab>Headline</Tab>
                            <Tab>Description</Tab>
                            <Tab>Preview</Tab>
                          </div>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.headlines || []).map(
                                (headline, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{headline.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{headline.category}</p>
                                          <span>
                                            {headline.value.length}/30
                                          </span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon " disabled></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          disabled
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.descriptions || []).map(
                                (desc, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{desc.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{desc.category}</p>
                                          <span>{desc.value.length}/90</span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          disabled
                                          onClick={() => {
                                            // removeResource(desc, "brandCampaign", "descriptions", campaign.name)
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(0, 3)
                                    .map((headline) => headline.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(0, 1)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(3, 6)
                                    .map((headline) => headline.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(1, 2)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Competitor Campaign</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${competitorCampaign.budget}</p>
                </div>
              </div>
              <p className="google_title_info">
                Competitor campaigns are where you use what is working for your
                competitor, by including it in your strategy. Bidding on your
                competitor's brand terms to create awareness and steal market
                share is one way to do this.
              </p>
            </div>
          </div>
          {(competitorCampaign?.adsets || []).map((campaign, ids) => {
            return (
              <Accordion className="accordion_strategy_body" key={ids}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>{campaign.name}</h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <div className="strategy_accordion_layout">
                    <div className="strategy_row_item">
                      <div className="strategy_column_item search_row_strategy">
                        <Autocomplete
                          open
                          multiple
                          disableCloseOnSelect
                          noOptionsText=""
                          renderOption={(props, option, { selected }) => (
                            <li
                              {...props}
                              className="autosearch_list"
                              onClick={() => {}}
                            >
                              <div className="strategy_list_row">
                                <div className="strategy_list_column">
                                  <span className="search_icon"></span>
                                  <p>{option}</p>
                                </div>
                                <div>
                                  <div className="headline_column_actions">
                                    {/* <button
                                      className={`list_search_actions accept_icon`}
                                    ></button> */}
                                    <button
                                      className={`list_search_actions reject_icon`}
                                      disabled
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          options={[...campaign.keywords]}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <div className="search_strategy_header">
                              <span className="search_icon"></span>
                              <></>
                              <TextField
                                {...params}
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Search..."
                                variant="standard"
                              />
                            </div>
                          )}
                          disablePortal={true}
                          className="auto_complete_search"
                        />
                      </div>
                      <div className="strategy_column_item">
                        <Tabs>
                          <div className="custom_tab_header">
                            <Tab>Headline</Tab>
                            <Tab>Description</Tab>
                            <Tab>Preview</Tab>
                          </div>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.headlines || []).map(
                                (headline, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{headline.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{headline.category}</p>
                                          <span>
                                            {headline.value.length}/30
                                          </span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          disabled
                                          onClick={() => {
                                            // removeResource(headline, "competitorCampaign", "headlines", campaign.name)
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.descriptions || []).map(
                                (desc, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{desc.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{desc.category}</p>
                                          <span>{desc.value.length}/90</span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          disabled
                                          onClick={() => {
                                            // removeResource(desc, "competitorCampaign", "descriptions", campaign.name)
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(0, 3)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(0, 1)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(3, 6)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(1, 2)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Services Campaign</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${serviceCampaign.budget}</p>
                </div>
              </div>
              <p className="google_title_info">
                Services Campaigns are where you bid for and include high intent
                keywords that, either directly spell out the services you
                provide, or reflect them in any manner.
              </p>
            </div>
          </div>
          {(serviceCampaign?.adsets || []).map((campaign, ids) => {
            return (
              <Accordion className="accordion_strategy_body" key={ids}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>{campaign.name}</h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <div className="strategy_accordion_layout">
                    <div className="strategy_row_item">
                      <div className="strategy_column_item search_row_strategy">
                        <Autocomplete
                          open
                          multiple
                          disableCloseOnSelect
                          noOptionsText=""
                          renderOption={(props, option, { selected }) => (
                            <li
                              {...props}
                              className="autosearch_list"
                              onClick={() => {}}
                            >
                              <div className="strategy_list_row">
                                <div className="strategy_list_column">
                                  <span className="search_icon"></span>
                                  <p>{option}</p>
                                </div>
                                <div>
                                  <div className="headline_column_actions">
                                    {/* <button
                                      className={`list_search_actions accept_icon`}
                                    ></button> */}
                                    <button
                                      className={`list_search_actions reject_icon`}
                                      disabled
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          options={[...campaign.keywords]}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <div className="search_strategy_header">
                              <span className="search_icon"></span>
                              <TextField
                                {...params}
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Search..."
                                variant="standard"
                              />
                            </div>
                          )}
                          disablePortal={true}
                          className="auto_complete_search"
                        />
                      </div>
                      <div className="strategy_column_item">
                        <Tabs>
                          <div className="custom_tab_header">
                            <Tab>Headline</Tab>
                            <Tab>Description</Tab>
                            <Tab>Preview</Tab>
                          </div>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.headlines || []).map(
                                (headline, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{headline.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{headline.category}</p>
                                          <span>
                                            {headline.value.length}/30
                                          </span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          disabled
                                          onClick={() => {
                                            //removeResource(headline, "serviceCampaign", "headlines", campaign.name)
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              {(campaign?.descriptions || []).map(
                                (desc, idx) => {
                                  return (
                                    <div
                                      className="headerline_row_strategy"
                                      key={idx}
                                    >
                                      <div className="headline_column_strategy">
                                        <h4>{desc.value}</h4>
                                        <div className="headline_keyword_details">
                                          <p>{desc.category}</p>
                                          <span>{desc.value.length}/90</span>
                                        </div>
                                      </div>
                                      <div className="headline_column_actions">
                                        {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                        <button
                                          className="actions_headline_strategy reject_icon"
                                          disabled
                                          onClick={() => {
                                            // removeResource(desc, "serviceCampaign", "descriptions", campaign.name)
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                          <Panel>
                            <div className={`tabpanel_strategy`}>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(0, 3)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(0, 1)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                              <div className="strategy_campaign_preview">
                                <p className="campaign_preview_title">
                                  {(campaign?.headlines || [])
                                    .slice(3, 6)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                                <p className="campaign_preview_link">
                                  {
                                    form?.onboardingInfo?.personalInfo
                                      ?.companyWebsite
                                  }
                                </p>
                                <p className="campaign_preview_desc">
                                  {(campaign?.descriptions || [])
                                    .slice(1, 2)
                                    .map((desc) => desc.value)
                                    .join(" - ")}
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>

      {[BACKEND_STATUS.COMPLETED, BACKEND_STATUS.ONGOING].includes(
        clientForm?.googlePmaxStrategy?.status
      ) &&
        clientForm.pmaxCAICronStatus === CRON_STATUS_BACKEND.DONE &&
        clientForm.pmaxTAICronStatus === CRON_STATUS_BACKEND.DONE && (
          <>
            <div className="pmax_campaign">
              <div
                style={{
                  height: "108px",
                  width: "1069px",
                  backgroundColor: "#F0F3FF",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={CAMERA}
                  style={{ width: "56px", height: "56px", objectFit: "cover" }}
                  alt=""
                />
                <Typography
                  style={{
                    fontFamily: "Lato",
                    fontSize: "28px",
                    fontWeight: "400",
                    lineHeight: "34px",
                  }}
                >
                  {" "}
                  Performance Max Campaign
                </Typography>
              </div>
            </div>

            {strategyCampaign1.length > 0 && (
              <>
                <div className="strategy_row_layout">
                  <div className="strategy_google_row_main">
                    <div className="google_strategy_header">
                      <div>
                        <div className="google_strategy_row">
                          <div className="google_strategy_title">
                            <p>Campaign 1</p>
                          </div>
                          <div className="google_title_budget">
                            <p>Daily Budget : ${budgetCampaign1}</p>
                          </div>
                        </div>
                        <p className="google_title_info">
                          Brand Campaigns essentially help you serve ads
                          whenever a user searches for anything related to your
                          brand name. This is generally to ensure no competitor
                          is eating up your prospective leads.
                        </p>
                      </div>
                      {/* <div>
                      <PrimaryButton title="Approve All" icon="approve" type="light" />
                    </div> */}
                    </div>

                    {strategyCampaign1.length > 0 &&
                      strategyCampaign1?.map((asset, index) => (
                        <Accordion
                          className="accordion_strategy_body"
                          key={index}
                        >
                          <AccordionSummary className="accordion_strategy_head">
                            <div className="strategy_accordion_title">
                              <h4
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "18px",
                                  lineHeight: "21.78px",
                                  fontWeight: "600",
                                  paddingLeft: "14px",
                                }}
                              >
                                {asset?.name}
                              </h4>
                              <span></span>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="accordion_strategy_content">
                            <div className="strategy_accordion_layout">
                              <div
                                className="strategy_row_item"
                                style={{ justifyContent: "center" }}
                              >
                                <div
                                  className="strategy_column_item search_row_strategy"
                                  style={{ flexBasis: "40%" }}
                                >
                                  <div className="strategy_segment">
                                    <span
                                      style={{
                                        fontFamily: "Inter",
                                        fontSize: "13.96px",
                                        fontWeight: "500",
                                        lineHeight: "16.9px",
                                        marginTop: "16.14px",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={PEOPLE}
                                        style={{
                                          marginRight: "13.44px",
                                          width: "22.30px",
                                          height: "11.96px",
                                        }}
                                        alt=""
                                      />
                                      {asset?.segment?.name}
                                    </span>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #DBDEE2",
                                        marginTop: "10.74px",
                                      }}
                                    ></div>
                                    {asset?.segment?.keywords?.map(
                                      (keyword, index) => (
                                        <div className="segment_body">
                                          <p key={index}>
                                            <img
                                              src={Person}
                                              style={{
                                                height: "11.94px",
                                                width: "9.53px",
                                                marginRight: "19.11px",
                                              }}
                                              alt=""
                                            />
                                            {keyword}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <PMaxStrategyDeck
                                  isClient={false}
                                  asset={asset}
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </div>
                </div>
              </>
            )}

            {strategyCampaign2.length > 0 && (
              <>
                <div className="strategy_row_layout">
                  <div className="strategy_google_row_main">
                    <div className="google_strategy_header">
                      <div>
                        <div className="google_strategy_row">
                          <div className="google_strategy_title">
                            <p>Campaign 2</p>
                          </div>
                          <div className="google_title_budget">
                            <p>Daily Budget : ${budgetCampaign2}</p>
                          </div>
                        </div>
                        <p className="google_title_info">
                          Competitor campaigns are where you use what is working
                          for your competitor, by including it in your strategy.
                          Bidding on your competitor's brand terms to create
                          awareness and steal market share is one way to do
                          this.
                        </p>
                      </div>
                      {/* <div>
                      <PrimaryButton title="Approve All" icon="approve" type="light" />
                    </div> */}
                    </div>
                    {strategyCampaign2.length > 0 &&
                      strategyCampaign2?.map((asset, index) => (
                        <Accordion
                          className="accordion_strategy_body"
                          key={index}
                        >
                          <AccordionSummary className="accordion_strategy_head">
                            <div className="strategy_accordion_title">
                              <h4
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "18px",
                                  lineHeight: "21.78px",
                                  fontWeight: "600",
                                  paddingLeft: "14px",
                                }}
                              >
                                {asset?.name}
                              </h4>
                              <span></span>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="accordion_strategy_content">
                            <div className="strategy_accordion_layout">
                              <div
                                className="strategy_row_item"
                                style={{ justifyContent: "center" }}
                              >
                                <div
                                  className="strategy_column_item search_row_strategy"
                                  style={{ flexBasis: "40%" }}
                                >
                                  <div className="strategy_segment">
                                    <span
                                      style={{
                                        fontFamily: "Inter",
                                        fontSize: "13.96px",
                                        fontWeight: "500",
                                        lineHeight: "16.9px",
                                        marginTop: "16.14px",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={PEOPLE}
                                        style={{
                                          marginRight: "13.44px",
                                          width: "22.30px",
                                          height: "11.96px",
                                        }}
                                        alt=""
                                      />
                                      {asset?.segment?.name}
                                    </span>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #DBDEE2",
                                        marginTop: "10.74px",
                                      }}
                                    ></div>
                                    {asset?.segment?.keywords?.map(
                                      (keyword, index) => (
                                        <div className="segment_body">
                                          <p key={index}>
                                            <img
                                              src={Person}
                                              style={{
                                                height: "11.94px",
                                                width: "9.53px",
                                                marginRight: "19.11px",
                                              }}
                                              alt=""
                                            />
                                            {keyword}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <PMaxStrategyDeck
                                  isClient={false}
                                  asset={asset}
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </div>
                </div>
              </>
            )}

            {strategyCampaign3.length > 0 && (
              <>
                <div className="strategy_row_layout">
                  <div className="strategy_google_row_main">
                    <div className="google_strategy_header">
                      <div>
                        <div className="google_strategy_row">
                          <div className="google_strategy_title">
                            <p>Campaign 3</p>
                          </div>
                          <div className="google_title_budget">
                            <p>Daily Budget : ${budgetCampaign3}</p>
                          </div>
                        </div>
                        <p className="google_title_info">
                          Services Campaigns are where you bid for and include
                          high intent keywords that, either directly spell out
                          the services you provide, or reflect them in any
                          manner.
                        </p>
                      </div>
                      {/* <div>
                 <PrimaryButton title="Approve All" icon="approve" type="light" />
               </div> */}
                    </div>

                    {strategyCampaign3.length > 0 &&
                      strategyCampaign3?.map((asset, index) => (
                        <Accordion
                          className="accordion_strategy_body"
                          key={index}
                        >
                          <AccordionSummary className="accordion_strategy_head">
                            <div className="strategy_accordion_title">
                              <h4
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "18px",
                                  lineHeight: "21.78px",
                                  fontWeight: "600",
                                  paddingLeft: "14px",
                                }}
                              >
                                {asset?.name}
                              </h4>
                              <span></span>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="accordion_strategy_content">
                            <div className="strategy_accordion_layout">
                              <div
                                className="strategy_row_item"
                                style={{ justifyContent: "center" }}
                              >
                                <div
                                  className="strategy_column_item search_row_strategy"
                                  style={{ flexBasis: "40%" }}
                                >
                                  <div className="strategy_segment">
                                    <span
                                      style={{
                                        fontFamily: "Inter",
                                        fontSize: "13.96px",
                                        fontWeight: "500",
                                        lineHeight: "16.9px",
                                        marginTop: "16.14px",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={PEOPLE}
                                        style={{
                                          marginRight: "13.44px",
                                          width: "22.30px",
                                          height: "11.96px",
                                        }}
                                        alt=""
                                      />
                                      {asset?.segment?.name}
                                    </span>
                                    <div
                                      style={{
                                        borderBottom: "1px solid #DBDEE2",
                                        marginTop: "10.74px",
                                      }}
                                    ></div>
                                    {asset?.segment?.keywords?.map(
                                      (keyword, index) => (
                                        <div className="segment_body">
                                          <p key={index}>
                                            <img
                                              src={Person}
                                              style={{
                                                height: "11.94px",
                                                width: "9.53px",
                                                marginRight: "19.11px",
                                              }}
                                              alt=""
                                            />
                                            {keyword}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                <PMaxStrategyDeck
                                  isClient={false}
                                  asset={asset}
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </div>
                </div>
              </>
            )}
          </>
        )}

      <div className="preview_footer_row">
        <div
          className="footer_content"
          style={{ justifyContent: "space-between" }}
        >
          <button
            className="backbutton"
            onClick={() => {
              if (agencyType === "LD") {
                dispatch(goBackAmCompo());
              } else {
                dispatch(goBackHDMAmCompo());
              }
            }}
            style={{
              cursor: 'pointer'
            }}
          >
            BACK
          </button>
          <PrimaryButton
            title={
              agencyType === "HDM"
                ? "PROCEED"
                : platformSelected.findIndex(
                    (platform) => platform.platform === "facebook-ads"
                  ) !== -1
                ? "PROCEED"
                : "PUBLISH"
            }
            disabled={
              agencyType === "HDM"
                ? false
                : platformSelected.findIndex(
                    (platform) => platform.platform === "facebook-ads"
                  ) === -1 &&
                  form?.strategyIterations &&
                  form?.strategyIterations.length !== 0 &&
                  ["GENERATED", "APPROVED", "CHANGES_APPLIED"].includes(
                    form?.strategyIterations[
                      form?.strategyIterations.length - 1
                    ].state
                  )
                ? true
                : false
            }
            onClick={() => {
              if (
                platformSelected.findIndex(
                  (platform) => platform.platform === "facebook-ads"
                ) !== -1
              ) {
                if (agencyType === "HDM") {
                  dispatch(updateCompoReduxHDMAM());
                } else {
                  dispatch(goToSpecificCompoClient(currentCompoAm + 1));
                }
              } else {
                if (agencyType === "HDM") {
                  dispatch(goToSpecificCompoHDMAM(currentCompoHDMAm + 2));
                } else {
                  setSureModal(true);
                }
              }
            }}
          ></PrimaryButton>
        </div>
      </div>

      {sureModal && (
        <StrategyDeckSureModal
          modal={sureModal}
          setModal={setSureModal}
          publishDeck={publishDeck}
        />
      )}
      {publishModal && (
        <ProposalPublishedModal
          formSubm={publishModal}
          setFormSubm={setPublishModal}
          title="Strategy Deck Published!"
          description="You will be notified as soon as the client has reviewed / approved the strategy deck."
          buttonTitle="Home"
        />
      )}
    </div>
  );
};
export default StrategyDeckGoogle;
