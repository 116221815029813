import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {button_bg_corner as corner} from "../../assets/icons/common/common";
import { useDispatch, useSelector } from "react-redux";
import { goToSpecificCompoClient } from "../../api/componentTracking/action";
import { goToSpecificCompoHDMClient } from "../../api/HDMClient/componentTracking/action";

const GoToSpecificCompoButton = (props) => {
  const dispatch = useDispatch();
  const CustomButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: "14px",
    padding: props.type == "corner" ? "11px 25px" : "10px 24px",
    border: props.type == "corner" ? "0px solid" : "1px solid",
    lineHeight: "18px",
    background:
      props.type == "bordered"
        ? "#ffffff"
        : props.type == "corner"
        ? "#0869FB url(" + corner + ") no-repeat 0 0"
        : "#0869FB",
    borderColor: props.type == "bordered" ? "#0869FB" : "#0869FB",
    borderRadius: "8px",
    color: props.type == "bordered" ? "#0869FB" : "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 600,
    width: props.width ? props.width : "",
    "&:hover": {
      color: props.type == "bordered" ? "#FFFFFF" : "#FFFFFF",
    },
    "&:disabled": {
      color: props.type == "bordered" ? "#FFFFFF" : "#FFFFFF",
      borderColor: props.type == "bordered" ? "#b2d1ff" : "#b2d1ff",
      cursor: "not-allowed",
      pointerEvents: "none",
      background:
        props.type == "bordered"
          ? "#ffffff"
          : props.type == "corner"
          ? "#0869FB url(" + corner + ") no-repeat 0 0"
          : "#b2d1ff",
    },
  });
  const goToSpecificCompo = (type, compoNumber) => {
    dispatch(
      type === "client"
        ? goToSpecificCompoClient(compoNumber)
        : type === "hdm-client"
        ? goToSpecificCompoHDMClient(compoNumber)
        : ""
    );
  };
  return (
    <CustomButton
      variant="contained"
      {...props}
      onClick={() => goToSpecificCompo(props.type, props.compoNumber)}
    >
      {props.title}
    </CustomButton>
  );
};

export default GoToSpecificCompoButton;
