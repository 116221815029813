import {
  accountManagerIds,
  accountManagerIdsHDM,
} from "../../utils/accountManagerUtil";
// import ReviewPoints from "./ReviewPoints/ReviewPoints";
import AccountPreviewScrn from "./AccountPreview/AccountPreview";
import QuestionaireContainer from "./Questions/QuestionaireContainer";
import GoogleAdsSetup from "./ScopeOfWorkAndPricing/GoogleAdsSetup";
import FacebookSetup from "./ScopeOfWorkAndPricing/FacebookAdsSetup";
import CROAdsSetup from "./ScopeOfWorkAndPricing/CROAdsSetup";
import ClientPricing from "./ClientPricing/ClientPricing";
// import GoogleAdsAccount from "./Questions/GoogleAdsAccount";
import LDGoogleAdsAccount from "../GoogleAudit/LDGoogleAdsAccount";
import LDReviewPoints from "../GoogleAudit/ReviewPoints/LDReviewPoints";
import Overview from "./Proposal/Overview";
import SuggestChanges from "./SuggestChanges";
import QuestionierPreview from "./Questions/QuestionerPreview";
import SharedAssetsAm from "./SharedAssetsAm/SharedAssetsAm";
import IntakeFormPreview from "./AccountPreview/IntakeFormPreview";
import StrategyInput from "./StrategyDeck/StrategyInput";
import StrategyInputCompetitor from "./StrategyDeck/StrategyInputCompetitor";
import StrategyInputService from "./StrategyDeck/StrategyInputService";
import StrategyDeckGoogle from "./StrategyDeck/StrategyDeckGoogle";
import CampaignCreation from "./CampaignApproval/CampaignCreationNew";
import CampaignPreview from "./CampaignApproval/CampaignPreview";
import StrategyDeckIntro from "./StrategyDeck/StrategyDeckIntro";
// import FacebookAccounts from "../HDMAccountManager/Audit/FacebookAccounts";
// import AuditAccount from "../HDMAccountManager/Audit/AuditAccount";
// import AuditCampaign from "../HDMAccountManager/Audit/AuditCampaign";
// import AuditAdset from "../HDMAccountManager/Audit/AuditAdset";
// import AuditAd from "../HDMAccountManager/Audit/AuditAd"
import LDFacebookAccounts from '../FacebookAudit/Accounts/LDFacebookAccounts';
import LDAuditAccount from '../FacebookAudit/AuditAccount/LDAuditAccount';
import LDAuditAdset from '../FacebookAudit/AuditAdset/LDAuditAdset';
import LDAuditAd from '../FacebookAudit/AuditAd/LDAuditAd';
import LDAuditCampaign from '../FacebookAudit/AuditCampaign/LDAuditCampaign';
import LookALike from "../HDMAccountManager/StrategyCreation/LookaLike";
import TofInterest from "../HDMAccountManager/StrategyCreation/TOFInterest";
import MOF from "../HDMAccountManager/StrategyCreation/MOF";
import BOF from "../HDMAccountManager/StrategyCreation/BOF";
import BOFCampaign from "../HDMAccountManager/BOFCampaignFB/BOFCampaign";
import HDMAdset from "../HDMAccountManager/HDMCampaignCreation/HDMAdset";
import HDMCampaignCreation from "../HDMAccountManager/HDMCampaignCreation/HDMCampaignCreation";
import InterestCampaign from "../HDMAccountManager/InterestCampaignFB/InterestCampaign";
import LookAlikeCampaign from "../HDMAccountManager/LookAlikeCampaignFB/LookAlikeCampaign";
import MOFCampaign from "../HDMAccountManager/MOFCampaignFB/MOFCampaign";
import CampaignPreviewFb from "../HDMAccountManager/HDMCampaignCreation/CampaignPreview";
import StrategyDeckFacebookHDM from "../HDMAccountManager/HDMAMStrategy/HDMAMStrategyDeckFacebookHDM";
import GeneratingStrategyPage from "./GeneratingStrategyPage";
import AdCopies from "./AdCopies";
import Creatives from "./Creatives";
import LandingPages from "./LandingPages";
import ABTesting from "./ABTesting";
import PMaxStrategyCreation from "../AccountManager/PMaxStrategyCreation";
import GoogleCampaignSelector from "../GoogleCampaignSelector/GoogleCampaignSelector";
import StrategyGoogleCampaignSelector from "./StrategyGoogleCampaignSelector";
import CampaignGoogleSelector from "./CampaignGoogleSelector";
import PMaxCampaignCreationLD from "./PMaxCampaignCreationLD";

const RenderCurrentComponent = ({ currentCompoent }) => {
  console.log("currentCompoent", currentCompoent);
  const currentCompenent = (currentCompoent) => {
    switch (currentCompoent) {
      case accountManagerIds.CLIENT_DETAILS_AM:
        return <AccountPreviewScrn />;
      case accountManagerIds.SHARED_ASSETS:
        return <SharedAssetsAm accessType="LD" type="READ_ACCESS" />;
      case accountManagerIds.SHARED_ASSETS_COMPLETE:
        return <SharedAssetsAm accessType="LD" type="COMPLETE_ACCESS" />;
      case accountManagerIds.SCOPE_GOOGLE_ADS_SETUP:
        return <GoogleAdsSetup />;
      case accountManagerIds.SCOPE_FACEBOOK_ADS_SETUP:
        return <FacebookSetup />;
      case accountManagerIds.SCOPE_CRO_ADS_SETUP:
        return <CROAdsSetup />;
      case accountManagerIds.SCOPE_PRICING_ADS_SETUP:
        return <ClientPricing />;
      case accountManagerIds.AUDIT_GOOGLE_ADS:
        // return <GoogleAdsAccount />;
        return <LDGoogleAdsAccount />;
      case accountManagerIds.AUDIT_REVIEW_POINTS:
        // return <ReviewPoints />;
        return <LDReviewPoints />;
      case accountManagerIdsHDM.HDM_FACEBOOK_ACCOUNTS:
        return <LDFacebookAccounts />;
        // return <FacebookAccounts agencyType="LD" />;
      case accountManagerIdsHDM.HDM_AUDIT_ACCOUNT:
        return <LDAuditAccount />;
        // return <AuditAccount agencyType="LD" />;
      case accountManagerIdsHDM.HDM_AUDIT_CAMPAIGN:
        return <LDAuditCampaign />;
        // return <AuditCampaign agencyType="LD" />;
      case accountManagerIdsHDM.HDM_AUDIT_ADSET:
        return <LDAuditAdset />;
        // return <AuditAdset agencyType="LD" />;
      case accountManagerIdsHDM.HDM_AUDIT_AD:
        return <LDAuditAd />;
        // return <AuditAd agencyType="LD" />;
      case accountManagerIds.REVIEW_INTAKE_FORM:
        return <IntakeFormPreview />;
      case accountManagerIds.PROPOSAL_PUBLISH:
        return <Overview />;
      case accountManagerIds.SUGGEST_CHANGES:
        return <SuggestChanges />;
      case accountManagerIds.REVIEW_QUESTIONIER_RESPONSES:
        return <QuestionierPreview />;
      case accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY:
        return <StrategyGoogleCampaignSelector />;
      case accountManagerIds.BRAND_STRATEGY_INPUT:
        return <StrategyInput />;
      case accountManagerIds.COMPETITOR_STRATEGY_INPUT:
        return <StrategyInputCompetitor />;
      case accountManagerIds.SERVICE_STRATEGY_INPUT:
        return <StrategyInputService />;
      case accountManagerIds.PMAX_STRATEGY_CAMPAIGN_1:
        return <PMaxStrategyCreation campName="campaign1" />;
      case accountManagerIds.PMAX_STRATEGY_CAMPAIGN_2:
        return <PMaxStrategyCreation campName="campaign2" />;
      case accountManagerIds.PMAX_STRATEGY_CAMPAIGN_3:
        return <PMaxStrategyCreation campName="campaign3" />;
      case accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE:
        return (
          <>
            <LookALike agencyType="LD" />
          </>
        );
      case accountManagerIdsHDM.STRATEGY_FACEBOOK_INTEREST:
        return (
          <>
            <TofInterest agencyType="LD" />
          </>
        );
      case accountManagerIdsHDM.STRATEGY_FACEBOOK_MOF:
        return (
          <>
            <MOF agencyType="LD" />
          </>
        );
      case accountManagerIdsHDM.STRATEGY_FACEBOOK_BOF:
        return (
          <>
            <BOF agencyType="LD" />
          </>
        );
      case accountManagerIds.STRATEGY_DECK_INTRODUCTION:
        return <StrategyDeckIntro />;
      case accountManagerIds.STRATEGY_DECK:
        return <StrategyDeckGoogle agencyType="LD" />;
      case accountManagerIds.STRATEGY_DECK_FACEBOOK:
        return <StrategyDeckFacebookHDM agencyType="LD" />;
      case accountManagerIds.STRATEGY_SUGGEST_CHANGES:
        return <SuggestChanges type="STRATEGY" />;
      case accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE:
        return <CampaignGoogleSelector />;
      case accountManagerIds.CAMPAIGN_CREATION_PAGE:
        return <CampaignCreation agencyType="LD" />;
      case accountManagerIds.CAMPAIGN_CREATION_PAGE_PMAX:
        return <PMaxCampaignCreationLD />;
      case accountManagerIdsHDM.CAMPAIGN_CREATION_FB:
        return <HDMCampaignCreation agencyType="LD" />;
      case accountManagerIdsHDM.ADSET_LEVEL_LOOKALIKE:
        return <LookAlikeCampaign agencyType="LD" />;
      case accountManagerIdsHDM.ADSET_LEVEL_INTEREST:
        return <InterestCampaign agencyType="LD" />;
      case accountManagerIdsHDM.ADSET_LEVEL_MOF:
        return <MOFCampaign agencyType="LD" />;
      case accountManagerIdsHDM.ADSET_LEVEL_BOF:
        return <BOFCampaign agencyType="LD" />;
      case accountManagerIds.CAMPAIGN_PREVIEW_PAGE:
        return <CampaignPreview />;
      case accountManagerIds.CAMPAIGN_PREVIEW_FB:
        return <CampaignPreviewFb agencyType="LD" />;
      case accountManagerIds.AD_COPIES:
        return <AdCopies isClient={false} />;
      case accountManagerIds.CREATIVES:
        return <Creatives />;
      case accountManagerIds.LANDING_PAGES:
        return <LandingPages />;
      case accountManagerIds.AB_TEST:
        return <ABTesting />;
      default:
        return <h1>In Progress...</h1>;
    }
  };
  return currentCompenent(currentCompoent);
};
export default RenderCurrentComponent;
