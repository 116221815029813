import { clientDashboardIds } from '../../utils/clientDashboardIdsUtil';
import QuestionaireContainer from './Questions/QuestionaireContainer';

import CompleteAccess from './CompleteAccess/completeAccess';

import { useSelector, useDispatch } from 'react-redux';
import WelcomeScreen from './Proposal/WelcomeScreen';
import Overview from './Proposal/Overview';
import SuggestChanges from './Proposal/SuggestChanges';
import HomePage from './HomePage/HomePage';
import QuestionierPreview from './Questions/Preview';
import FilledDetails from './AccountPreview/AccountPreview';
import StrategyDeckIntro from './StrategyDeck/StrategyDeckIntro';
import StrategyDeckGoogle from './StrategyDeck/StrategyDeckGoogle';
import EditResponseIntakeForm from './AccountPreview/EditResponseIntakeForm';
import SharedAssets from './SharedAssets/SharedAssets';
import SuggestChangesDeck from './StrategyDeck/SuggestChangesDeck';
import CampaignPreview from './CampaignPreview';
import GoogleAdsSetup from './ScopeOfWorkAndPricing/GoogleAdsSetup';
import FacebookAdsSetup from './ScopeOfWorkAndPricing/FacebookAdsSetup';
import CroAdsSetup from './ScopeOfWorkAndPricing/CROAdsSetup';
import ClientPricing from './ClientPricing/ClientPricing';
import HDMQuestionaireContainer from '../HDMClientDashboard/HDMQuestions/HDMQuestionaireContainer';
import StrategyDeckFacebookHDM from '../HDMClientDashboard/Strategy/StrategyDeckFacebookHDM';
import CampaignPreviewHdm from '../HDMClientDashboard/Campaign/CampaignPreview';
import ReadAccess from './ReadAccess/readAccess';
import Proposal from './Proposal/proposal';
import AdCopies from './AdCopies';
import Creatives from './Creatives';
import LandingPages from './LandingPages';
import ABTesting from './ABTesting';

const RenderCurrentComponent = ({ currentCompoent }) => {
  const reduxStates = useSelector((state) => state);
  console.log('reduxStates', reduxStates['hdmClient']);
  const currentCompenent = (currentCompoent) => {
    switch (currentCompoent) {
      case clientDashboardIds.RENDER_HOME_PAGE:
        return <HomePage />;
      case clientDashboardIds.QUEST_BASIC_QUEST1:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='company'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo']['company']
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST2:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='industry'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'industry'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST3:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='companyWebsite'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'companyWebsite'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST4:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='companyEmail'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'companyEmail'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST5:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='companyPhone'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'companyPhone'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST6:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='companyAddress'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'companyAddress'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST7:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='primaryContactName'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'primaryContactName'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST8:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='primaryContactRole'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'primaryContactRole'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST9:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='primaryContactEmail'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'primaryContactEmail'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_BASIC_QUEST10:
        return (
          <QuestionaireContainer
            infoType='personalInfo'
            objKey='primaryContactNumber'
            value={
              reduxStates['client']['onboardingInfo']['personalInfo'][
                'primaryContactNumber'
              ]
            }
          />
        );
      case clientDashboardIds.PLATFORM_INFORMATION_QUEST1:
        return (
          <QuestionaireContainer
            infoType='platformInfo'
            objKey='platforms'
            value={
              reduxStates['client']['onboardingInfo']['platformInfo'][
                'platforms'
              ]
            }
          />
        );
      case clientDashboardIds.PLATFORM_INFORMATION_QUEST2:
        return (
          <QuestionaireContainer
            infoType='platformInfo'
            objKey='averageTicketPrice'
            value={
              reduxStates['client']['onboardingInfo']['platformInfo'][
                'averageTicketPrice'
              ]
            }
          />
        );
      case clientDashboardIds.PLATFORM_INFORMATION_QUEST3:
        return (
          <QuestionaireContainer
            infoType='platformInfo'
            objKey='cltv'
            value={
              reduxStates['client']['onboardingInfo']['platformInfo']['cltv']
            }
          />
        );
      case clientDashboardIds.PLATFORM_INFORMATION_QUEST4:
        return (
          <QuestionaireContainer
            infoType='platformInfo'
            objKey='crm'
            value={
              reduxStates['client']['onboardingInfo']['platformInfo']['crm']
            }
          />
        );
      case clientDashboardIds.PLATFORM_INFORMATION_QUEST5:
        return (
          <QuestionaireContainer
            infoType='platformInfo'
            objKey='leadSource'
            value={
              reduxStates['client']['onboardingInfo']['platformInfo'][
                'leadSource'
              ]
            }
          />
        );
      case clientDashboardIds.PLATFORM_INFORMATION_QUEST6:
        return (
          <QuestionaireContainer
            infoType='platformInfo'
            objKey='competitors'
            value={
              reduxStates['client']['onboardingInfo']['platformInfo'][
                'competitors'
              ]
            }
          />
        );
      // case clientDashboardIds.PLATFORM_INFORMATION_QUEST7:
      //   return (
      //     <QuestionaireContainer
      //       infoType="platformInfo"
      //       objKey="Google-Facebook-CRO"
      //       value={
      //         reduxStates["client"]["onboardingInfo"]["platformInfo"][
      //           "Google-Facebook-CRO"
      //         ]
      //       }
      //     />
      //   );
      // case clientDashboardIds.PLATFORM_INFORMATION_QUEST8:
      //   return  <QuestionaireContainer
      //             infoType="platformInfo"
      //             objKey="brandDescription"
      //             value={
      //               reduxStates["client"]["onboardingInfo"]["platformInfo"][
      //                 "brandDescription"
      //               ]
      //             }
      //           />
      case clientDashboardIds.GROWTH_GOALS_QUEST1:
        return (
          <QuestionaireContainer
            infoType='growthGoal'
            objKey='goals'
            value={
              reduxStates['client']['onboardingInfo']['growthGoal']['goals']
            }
          />
        );
      case clientDashboardIds.REVIEW_DETAILS_CLIENT:
        return <FilledDetails />;
      case clientDashboardIds.SCOPE_GOOGLE_ADS_SETUP:
        return <GoogleAdsSetup />;
      case clientDashboardIds.SCOPE_FACEBOOK_ADS_SETUP:
        return <FacebookAdsSetup />;
      case clientDashboardIds.SCOPE_CRO_ADS_SETUP:
        return <CroAdsSetup />;
      case clientDashboardIds.SCOPE_PRICING_ADS_SETUP:
        return <ClientPricing />;
      case clientDashboardIds.READ_ACCESS:
        return <SharedAssets />;
      case clientDashboardIds.PROPOSAL_HOME_SCREEN:
        return <WelcomeScreen />;
      case clientDashboardIds.PROPOSAL_TAB:
        return <Overview />;
      case clientDashboardIds.SUGGEST_CHANGES_TAB:
        return <SuggestChanges />;
      case clientDashboardIds.CLIENT_COMPLETE_ACCESS:
        return <CompleteAccess />;
      case clientDashboardIds.QUEST_INTAKE_FORM1:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='targetCpa'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'targetCpa'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM2:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='profitMargin'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'profitMargin'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM3:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='avgClosingPercent'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'avgClosingPercent'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM4:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='sessionality'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'sessionality'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM5:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='appRelatedService'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'appRelatedService'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM6:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='descriptions'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'descriptions'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM7:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='brandDescription'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'brandDescription'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM8:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='leadEmailAddress'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'leadEmailAddress'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM9:
        return (
          <QuestionaireContainer
            infoType='productServiceInfo'
            objKey='brandServiceKeywords'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['productServiceInfo'][
                'brandServiceKeywords'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES1:
        return (
          <QuestionaireContainer
            infoType='preferences'
            objKey='competitorsIntake'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['preferences']['competitors']
            }
          />
        );

      case clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES2:
        return (
          <QuestionaireContainer
            infoType='preferences'
            objKey='monthlyAdSpend'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['preferences'][
                'monthlyAdSpend'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES3:
        return (
          <QuestionaireContainer
            infoType='preferences'
            objKey='leadPreferences'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['preferences'][
                'leadPreferences'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES4:
        return (
          <QuestionaireContainer
            infoType='preferences'
            objKey='trackingNumberDisplay'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['preferences'][
                'trackingNumberDisplay'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES5:
        return (
          <QuestionaireContainer
            infoType='preferences'
            objKey='handleDepletedAccountBalance'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['preferences'][
                'handleDepletedAccountBalance'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE1:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='ageRange'
            maxObjKey='maxAgeRange'
            minObjKey='minAgeRange'
            intakeForm={true}
            minAgeValue={
              reduxStates['client']['intakeInfo']['audiences']['minAgeRange']
            }
            maxAgeValue={
              reduxStates['client']['intakeInfo']['audiences']['maxAgeRange']
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE2:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='geoTargets'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences']['geoTargets']
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE3:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='gender'
            intakeForm={true}
            value={reduxStates['client']['intakeInfo']['audiences']['gender']}
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE4:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='advertiseLocation'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences'][
                'advertiseLocation'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE5:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='maritalStatus'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences']['maritalStatus']
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE6:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='customerCollar'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences']['customerCollar']
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE7:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='activitiesInterest'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences'][
                'activitiesInterest'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE8:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='excludeAudiences'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences'][
                'excludeAudiences'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE9:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='customerIncomeRange'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences'][
                'customerIncomeRange'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE10:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='customerEducation'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences'][
                'customerEducation'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE11:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='customerEmployment'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences'][
                'customerEmployment'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE12:
        return (
          <QuestionaireContainer
            infoType='audiences'
            objKey='formalCustomerPersona'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['audiences'][
                'formalCustomerPersona'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_BRANDING1:
        return (
          <QuestionaireContainer
            infoType='brandAssets'
            objKey='brandingStyleGuide'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['brandAssets'][
                'brandingStyleGuide'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_BRANDING2:
        return (
          <QuestionaireContainer
            infoType='brandAssets'
            objKey='companyLogo'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['brandAssets']['companyLogo']
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_BRANDING3:
        return (
          <QuestionaireContainer
            infoType='brandAssets'
            objKey='brandingMaterials'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['brandAssets'][
                'brandingMaterials'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_BRANDING4:
        return (
          <QuestionaireContainer
            infoType='brandAssets'
            objKey='companyClientLogo'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['brandAssets'][
                'companyClientLogo'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_BRANDING5:
        return (
          <QuestionaireContainer
            infoType='brandAssets'
            objKey='companyClientWebsite'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['brandAssets'][
                'companyClientWebsite'
              ]
            }
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_BRANDING6:
        return (
          <QuestionaireContainer
            infoType='brandAssets'
            objKey='awards'
            intakeForm={true}
            value={reduxStates['client']['intakeInfo']['brandAssets']['awards']}
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_BRANDING7:
        return (
          <QuestionaireContainer
            infoType='brandAssets'
            objKey='productImages'
            intakeForm={true}
            value={
              reduxStates['client']['intakeInfo']['brandAssets'][
                'productImages'
              ]
            }
            outerKey='intakeInfo'
          />
        );
      case clientDashboardIds.EDIT_RESPONSES_INTAKE_FORM:
        return <EditResponseIntakeForm />;
      case clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF1:
        return (
          <QuestionaireContainer
            infoType='customerTestimonial'
            objKey='testimonial1'
            value={
              reduxStates['client']['intakeInfo']['customerTestimonial'][
                'testimonial1'
              ]
            }
            outerKey='intakeInfo'
            intakeForm={true}
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF2:
        return (
          <QuestionaireContainer
            infoType='customerTestimonial'
            objKey='testimonial2'
            value={
              reduxStates['client']['intakeInfo']['customerTestimonial'][
                'testimonial2'
              ]
            }
            outerKey='intakeInfo'
            intakeForm={true}
          />
        );
      case clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF3:
        return (
          <QuestionaireContainer
            infoType='customerTestimonial'
            objKey='testimonial3'
            value={
              reduxStates['client']['intakeInfo']['customerTestimonial'][
                'testimonial3'
              ]
            }
            outerKey='intakeInfo'
            intakeForm={true}
          />
        );
      case clientDashboardIds.QUEST_FB_TARGETING1:
        return (
          <HDMQuestionaireContainer
            infoType='facebookTargeting'
            objKey='AgeRange'
            value={{
              min: reduxStates['hdmClient']['onboardingInfo'][
                'facebookTargeting'
              ]['minAgeRange'],
              max: reduxStates['hdmClient']['onboardingInfo'][
                'facebookTargeting'
              ]['maxAgeRange'],
            }}
            isLD={true}
            intakeForm={true}
          />
        );
      case clientDashboardIds.QUEST_FB_TARGETING2:
        return (
          <HDMQuestionaireContainer
            infoType='facebookTargeting'
            objKey='gender'
            value={
              reduxStates['hdmClient']['onboardingInfo']['facebookTargeting'][
                'gender'
              ]
            }
            isLD={true}
            intakeForm={true}
          />
        );
      case clientDashboardIds.QUEST_FB_TARGETING3:
        return (
          <HDMQuestionaireContainer
            infoType='facebookTargeting'
            objKey='advertisingLocations'
            value={
              reduxStates['hdmClient']['onboardingInfo']['facebookTargeting'][
                'advertisingLocations'
              ]
            }
            isLD={true}
            intakeForm={true}
          />
        );
      case clientDashboardIds.QUEST_FB_TARGETING4:
        return (
          <HDMQuestionaireContainer
            infoType='aboutBusiness'
            objKey='brandsInterests'
            value={
              reduxStates['hdmClient']['onboardingInfo']['aboutBusiness'][
                'brandsInterests'
              ]
            }
            isLD={true}
            intakeForm={true}
          />
        );
      case clientDashboardIds.QUEST_FB_TARGETING5:
        return (
          <HDMQuestionaireContainer
            infoType='facebookTargeting'
            objKey='excludes'
            value={
              reduxStates['hdmClient']['onboardingInfo']['facebookTargeting'][
                'excludes'
              ]
            }
            isLD={true}
            intakeForm={true}
          />
        );
      case clientDashboardIds.STRATEGY_DECK_INTRO:
        return <StrategyDeckIntro />;
      case clientDashboardIds.STRATEGY_DECK_GOOGLE:
        return <StrategyDeckGoogle agencyType='LD' />;
      case clientDashboardIds.STRATEGY_DECK_FACEBOOK:
        return <StrategyDeckFacebookHDM agencyType='LD'/>;
      case clientDashboardIds.STRATEGY_DECK_SUGGEST_CHANGES:
        return <SuggestChangesDeck />;
      case clientDashboardIds.REVIEW_QUESTIONIER_RESPONSES:
        return <QuestionierPreview />;
      case clientDashboardIds.CAMPAIGN_PREVIEW_CLIENT:
        return <CampaignPreview />;
      case clientDashboardIds.CAMPAIGN_PREVIEW_FACEBOOK:
        return <CampaignPreviewHdm />;
      case clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES:
        return <AdCopies isClient={true} />;
      case clientDashboardIds.CREATIVE_APPROVAL_CREATIVES:
        return <Creatives isClient={true} />;
      case clientDashboardIds.CREATIVE_APPROVAL_LANDING_PAGES:
        return <LandingPages isClient={true} />;
      case clientDashboardIds.CREATIVE_APPROVAL_AB_TEST:
        return <ABTesting isClient={true} />;
      default:
        return <></>;
    }
  };
  return currentCompenent(currentCompoent);
};
export default RenderCurrentComponent;
