import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import CONTENT from './strategy-Icons/content.png';
import CREATIVES from './strategy-Icons/Creatives.png';
import GOOGLE from './strategy-Icons/google-strategy.png';
import PRIORITY from './strategy-Icons/Priority.png';
import TIKTOK from './strategy-Icons/tiktok-strategy.png';
import FACEBOOK from './strategy-Icons/facebook.png';
import WEBSITE from './strategy-Icons/website.png';
import BULB from './strategy-Icons/Creatives.png';

import { Button } from '@mui/material';
import {
  goBackHDMClientCompo,
  goToSpecificCompoHDMClient,
  updateHDMCompoReduxClient,
} from '../../../api/HDMClient/componentTracking/action';
import './Analysis.css';
import TEST from './strategy-Icons/testing.png';
import { isPlatformSelectedHDM } from '../../../utils/accountManagerUtil';
const AnaylsisHDM = () => {
  const dispatch = useDispatch();
  const hdmclient = useSelector((state) => state.hdmClient);
  let currentCompoHDMClient = useSelector(state => state.hdmClientCurrentComp.currentComp);
  return (
    <div style={{ width: '100%' }}>
      <div className='proposal_layout proposal_flex_top '>
        <div className='preview_head'>
          <h4>PROPOSAL</h4>
          <h2>Horizons Digital Marketing </h2>
          {/* <img src="./test.svg" /> */}
        </div>
      </div>
      <div className='preview_footer_row'>
        <div className='back_button'>
          {' '}
          <Button
            style={{ color: '#0869FB' }}
            onClick={() => {
              dispatch(goBackHDMClientCompo());
            }}
          >
            BACK{' '}
          </Button>
        </div>
        <div className='footer_content'>
          <Button
            variant='contained'
            onClick={() => {
              if(isPlatformSelectedHDM(hdmclient, 'google-ads') !== -1){
                dispatch(updateHDMCompoReduxClient());
              } else {
                dispatch(goToSpecificCompoHDMClient(currentCompoHDMClient + 2));
              }
            }}
          >
            PROCEED
          </Button>
        </div>
      </div>
      <div className='analysis-main'></div>
      <div className='scroll_items'>
        {/* <div className='phase'>
        <Typography style={{fontFamily:'Lato',fontWeight:'700', color:'#000000',fontSize:'30px',lineHeight:'36px',marginLeft:'52px'}}>Onboarding Phases</Typography>
        <img style={{height:"491.34px", width:'100%'}} src="../../../assets/images/phase.png" alt="" />
        
    </div> */}

        {hdmclient?.accountAnalysis?.tiktok && (
          <div className='facebook'>
            <div className='Facebook_head'>
              {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
              <h3
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Lato',
                  fontSize: '25px',
                  fontWeight: '700',
                  lineHeight: '51.91px',
                  margin: '0px',
                }}
              >
                <img
                  style={{ position: 'relative', top: '15px' }}
                  src={FACEBOOK}
                  alt=''
                />{' '}
                Facebook
              </h3>
              {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
            </div>
            <div>
              <div style={{ marginLeft: 10, marginBottom: 40 }}>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '28px',
                        fontWeight: '400',
                        color: ' #333333',
                        marginLeft: '32.95px',
                      }}
                    >
                      {hdmclient?.accountAnalysis?.tiktok}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        )}
        {hdmclient?.accountAnalysis?.creatives && (
          <div className='Creatives'>
            <div className='Creatives_head'>
              {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
              <h3
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Lato',
                  fontSize: '25px',
                  fontWeight: '700',
                  lineHeight: '51.91px',
                  margin: '0px',
                }}
              >
                <img
                  style={{ position: 'relative', top: '15px' }}
                  src={CREATIVES}
                  alt=''
                />{' '}
                Creatives
              </h3>
              {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
            </div>
            <div>
              <div style={{ marginLeft: 10, marginBottom: 40 }}>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '28px',
                        fontWeight: '400',
                        color: ' #333333',
                        marginLeft: '32.95px',
                      }}
                    >
                      {hdmclient?.accountAnalysis?.creatives}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        )}
        {hdmclient?.accountAnalysis?.google && (
          <div className='Google'>
            <div className='Google_head'>
              {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
              <h3
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Lato',
                  fontSize: '25px',
                  fontWeight: '700',
                  lineHeight: '51.91px',
                  margin: '0px',
                }}
              >
                <img
                  style={{ position: 'relative', top: '15px' }}
                  src={GOOGLE}
                  alt=''
                />{' '}
                Google
              </h3>
              {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
            </div>
            <div>
              <div style={{ marginLeft: 10, marginBottom: 40 }}>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '28px',
                        fontWeight: '400',
                        color: ' #333333',
                        marginLeft: '32.95px',
                      }}
                    >
                      {hdmclient?.accountAnalysis?.google}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        )}
        {hdmclient?.accountAnalysis?.website && (
          <div className='Website'>
            <div className='Website_head'>
              {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
              <h3
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Lato',
                  fontSize: '25px',
                  fontWeight: '700',
                  lineHeight: '51.91px',
                  margin: '0px',
                }}
              >
                <img
                  style={{ position: 'relative', top: '15px' }}
                  src={WEBSITE}
                  alt=''
                />{' '}
                Website
              </h3>
              {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
            </div>
            <div>
              <div style={{ marginLeft: 10, marginBottom: 40 }}>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '28px',
                        fontWeight: '400',
                        color: ' #333333',
                        marginLeft: '32.95px',
                      }}
                    >
                      {hdmclient?.accountAnalysis?.website}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        )}

        {hdmclient?.accountAnalysis?.competitor && (
          <div className='Competitor'>
            <div className='Competitor_head'>
              {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
              <h3
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Lato',
                  fontSize: '25px',
                  fontWeight: '700',
                  lineHeight: '51.91px',
                  margin: '0px',
                }}
              >
                <img
                  style={{ position: 'relative', top: '15px' }}
                  src={BULB}
                  alt=''
                />{' '}
                Competitor
              </h3>
              {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
            </div>
            <div>
              <div style={{ marginLeft: 10, marginBottom: 40 }}>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        lineHeight: '28px',
                        fontWeight: '400',
                        color: ' #333333',
                        marginLeft: '32.95px',
                      }}
                    >
                      {hdmclient?.accountAnalysis?.competitor}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        )}

        {/* <div className="Priority">
          <div className="Priority_head">
            <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography>
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={PRIORITY}
                alt=""
              />{" "}
              Top 3 Priority Areas
            </h3>
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p>
          </div>
          <div className="pricing_card">
            <div className="card-priority" style={{ height: 250 }}>
              <div
                style={{
                  display: "flex",
                  background: " #F5F9FF",
                  width: "100%",
                  borderRadius: " 24px 24px 0px 0px",
                }}
              >
                <img
                  src={CONTENT}
                  style={{
                    marginTop: 22,
                    marginLeft: 10,
                    height: "50px",
                    width: "50px",
                  }}
                />
                <b
                  style={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    lineHeight: "22px",
                    fontWeight: "700",
                    color: " #170F49",
                    marginTop: 30,
                  }}
                >
                  Content
                </b>
              </div>
              <div>
                <ul>
                  <li className="list-content">
                    Have regular UGC content made for TikTok
                  </li>
                  <li className="list-content">
                    Upload organically regularly- at least 2-3 times a weeks
                  </li>
                  <li className="list-content">
                    Up to 5 Ad Style content for TikTok made bi-weeklys
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-priority" style={{ height: 250 }}>
              <div
                style={{
                  display: "flex",
                  background: " #F5F9FF",
                  width: "100%",
                  borderRadius: " 24px 24px 0px 0px",
                }}
              >
                <img
                  src={GOOGLE}
                  style={{
                    marginTop: 22,
                    marginLeft: 10,
                    height: 50,
                    width: 50,
                  }}
                />
                <b
                  style={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    lineHeight: "22px",
                    fontWeight: "700",
                    color: " #170F49",
                    marginTop: 30,
                  }}
                >
                  Google
                </b>
              </div>
              <div>
                <ul>
                  <li className="list-content">
                    Launching New Campaigns, Including shopping campaigns.
                  </li>
                  <li className="list-content">
                    Finding keywords that convert
                  </li>
                  <li className="list-content">
                    Begin seeing sales on the account
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-priority" style={{ height: 250 }}>
              <div
                style={{
                  display: "flex",
                  background: " #F5F9FF",
                  width: "100%",
                  borderRadius: " 24px 24px 0px 0px",
                }}
              >
                <img
                  src={TEST}
                  style={{
                    marginTop: 22,
                    marginLeft: 10,
                    height: "50px",
                    width: "50px",
                  }}
                />
                <b
                  style={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    lineHeight: "22px",
                    fontWeight: "700",
                    color: " #170F49",
                    marginTop: 30,
                  }}
                >
                  Testing
                </b>
              </div>
              <div>
                <ul>
                  <li className="list-content">
                    First weeks we are gathering data, seeing what is working
                  </li>
                  <li className="list-content">
                    Frequent change of creatives, videos and offers
                  </li>
                  <li className="list-content">Establish consistent results</li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default AnaylsisHDM;
