import { useEffect, useState } from "react";
import { Accordion, Typography } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { editicon as EDITICON} from "../../../assets/icons/icon";
import { useDispatch, useSelector } from "react-redux";
import "./AccountPreview.css";

import { clientDashboardIds } from "../../../utils/clientDashboardIdsUtil";
import { Button, IconButton } from "@mui/material";
import { clientDetailsMapping } from "./RouteMappingWithCompo";
import OnboardFormSubmModal from "../../modals/OnboardFormSubm";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { goBackClientCompo, goToSpecificCompoClient } from "../../../api/componentTracking/action";
import { onboardingFormSubmApi } from "../../../api/client/saga";
import {
  DB_KEY_MAP_GROWTH,
  DB_KEY_MAP_PERSONAL,
  DB_KEY_MAP_PLATFORM,
} from "../../../utils/dbKeyMapping";
import {
  BASIC_INFORMATION_LD,
  PLATFORM_INFORMATION_LD,
  GROWTH_GOALS_LD,
} from "../../../utils/questionsOrderToPreview";
import { updateClientDetails } from "../../../api/client/action";
import {getClientDetailsByFormId} from "../../../api/client/action";

const AccountPreviewScrn = () => {
  const dispatch = useDispatch();
  const clientReduxState = useSelector((state) => state.client);
  const basicInform = useSelector(
    (state) => state.client.onboardingInfo?.personalInfo
  );
  const platformInform = useSelector(
    (state) => state.client.onboardingInfo?.platformInfo
  );
  const growthGoals = useSelector(
    (state) => state.client.onboardingInfo?.growthGoal
  );
  const [formSubm, setFormSubm] = useState(false);

  const [basicFormQuestions, setBasicFormQuestions] = useState({});
  const [platformInformationQuestions, setPlatformInformationQuestions] =
    useState({});
  const [growthGoalQuestions, setGrowthGoalQuestions] = useState({});
  const companyInfo = useSelector((state) => state.client?.companyInfo);

  useEffect(() => {
    dispatch(updateClientDetails());
  }, []);

  useEffect(() => {
    if (basicInform) {
      const informations = {};
      BASIC_INFORMATION_LD.forEach((question) => {
        informations[question] = basicInform[question];
      });
      setBasicFormQuestions(informations);
    }
  }, [basicInform]);

  useEffect(() => {
    if (platformInform) {
      const informations = {};
      PLATFORM_INFORMATION_LD.forEach((question) => {
        if (question === "adsManaged") {
          informations[question] = "";
        } else {
          informations[question] = platformInform[question];
        }
      });
      setPlatformInformationQuestions(informations);
    }
  }, [platformInform]);

  useEffect(() => {
    if (growthGoals) {
      const informations = {};
      GROWTH_GOALS_LD.forEach((question) => {
        informations[question] = growthGoals[question];
      });
      setGrowthGoalQuestions(informations);
    }
  }, [growthGoals]);

  const mappedKey = {
    competitors: "name",
    platforms: "platform",
  };
  const desiredData = (key, value) => {
    if (key === "platforms") {
      let str = "";
      value.forEach(({ platform, spends, cpa }) => {
        if (platform.length !== 0) {
          str +=
            "(" + platform + "=>(spends=$" + spends + ",cpa=$" + cpa + ")),";
        }
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : "";
    }
    if (key === "crm" || key === "leadSource") {
      let str = "";
      value.forEach((val) => {
        if (val && val !== "Other") {
          str += val + ",";
        }
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : "";
    }
    if (key === "competitors") {
      let compNameValue = "";
      value.map((key, index) => {
        compNameValue += "(" + key.name + " => " + key.website + ")" + ",";
      });
      return compNameValue.slice(0, compNameValue.length - 1);
    }
    const tempArr = value.map((infoType) => infoType[mappedKey[key]]);
    return tempArr.join(",").slice(1);
  };
  const breakFromUnderscore = (str, key) => {
    if (["averageTicketPrice", "cltv"].includes(key)) {
      return `$${str}`;
    }
    if (typeof str === "string") {
      const data = str.split("_");
      if (data.length > 1) {
        const temp = data.join(",");
        return temp.slice(0, temp.length - 1);
      } else {
        return data[0];
      }
    } else {
      return str;
    }
  };
  const onboardingFormSubmitted = () => {
    onboardingFormSubmApi(clientReduxState).then((res) => {
      setFormSubm(!formSubm);
      dispatch(getClientDetailsByFormId());
    });
  };
  const adsManaged = () => {
    let str = "";
    [
      { key: "googleAdsManaged", value: "Google" },
      { key: "fbAdsManaged", value: "Facebook" },
      { key: "implementCro", value: "CRO" },
    ].forEach(({ key, value }, index) => {
      if (companyInfo[key]) {
        str = str + "(" + value + "=>" + "yes" + "),";
      } else {
        str = str + "(" + value + "=>" + "no" + "),";
      }
    });
    return str.length !== 0 ? str.substring(0, str.length - 1) : str;
  };
  console.log("platformInformationQuestions",platformInformationQuestions)
  return (
    <>
      <div
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <div className="top_head">
          <div className="resp">
            <h3 className="preview_heading">PREVIEW</h3>
            <h1 className="preview_header2">VIEW AND EDIT YOUR RESPONSES</h1>
            <span style={{ color: "#6F6C90", textAlign: "center" }}>
              Kindly review your responses carefully before publishing, as you
              will not <br />
              be able to edit this section once it is published.{" "}
            </span>

            <button
              className="btn back_button"
              onClick={() => dispatch(goBackClientCompo())}
            ></button>

            <PrimaryButton
              type="bordered"
              className="edit_response_button"
              title="Edit Responses"
              icon="edit"
              onClick={() =>
                dispatch({
                  type: "EDIT_CLIENT_RESPO_CLIENT",
                })
              }
            ></PrimaryButton>
          </div>
        </div>
        <div className="accordian">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight={600}>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(basicFormQuestions)?.map(
                  ([key, value], index) =>
                    DB_KEY_MAP_PERSONAL[key] !== undefined && (
                      <div className="tag">
                        <div className="content">
                          <span>{DB_KEY_MAP_PERSONAL[key]} : </span>
                          <span
                            style={{ color: "#619BF3", fontWeight: "bolder" }}
                          >
                            {(value || "").length !== 0 ? value : ""}
                          </span>{" "}
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Platform Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(platformInformationQuestions)?.map(
                  ([key, value], index) =>
                    DB_KEY_MAP_PLATFORM[key] !== undefined && (
                      <div className="tag">
                        <div className="content">
                          <span>{DB_KEY_MAP_PLATFORM[key]} : </span>
                          <span
                            style={{ color: "#619BF3", fontWeight: "bolder" }}
                          >
                            {Array.isArray(value)
                                ? desiredData(key, value)
                                : key === "adsManaged"
                                ? adsManaged(key)
                                : (value || "").length === 0
                                ? ""
                                : ["averageTicketPrice", "cltv"].includes(key)
                                ? `$${value}`
                                : value
                                ? value
                                : ""}
                          </span>{" "}
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Growth Goals</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(growthGoalQuestions)?.map(
                  ([key, value], index) =>
                    DB_KEY_MAP_GROWTH[key] !== undefined && (
                      <div className="tag">
                        <div className="content">
                          <span>{DB_KEY_MAP_GROWTH[key]} : </span>
                          <span
                            style={{ color: "#619BF3", fontWeight: "bolder" }}
                          >
                            {(value || "").length !== 0 ? value : ""}
                          </span>
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div className="preview_footer_row">
        <div className="footer_con">
          <button
            className="back"
            onClick={() => dispatch(goToSpecificCompoClient(0))}
          >
            BACK
          </button>
          <PrimaryButton
            title="PUBLISH"
            onClick={() => onboardingFormSubmitted()}
          ></PrimaryButton>
        </div>
      </div>
      <OnboardFormSubmModal formSubm={formSubm} setFormSubm={setFormSubm} />
    </>
  );
};
export default AccountPreviewScrn;
