import { address2_icon as addressIcon } from '../../../assets/icons/questionnare/questionnare';
import { company_icon as companyIcon } from '../../../assets/icons/questionnare/questionnare';
import { contact_icon as contactIcon } from '../../../assets/icons/questionnare/questionnare';
import crmSettingIcon from '../../../assets/icons/questionnare/crm_setting_icon.png';
import { diamond_icon as diamondIcon } from '../../../assets/icons/questionnare/questionnare';
import { email_icon as emailIcon } from '../../../assets/icons/questionnare/questionnare';
import { competitor_icon as growthIcon } from '../../../assets/icons/questionnare/questionnare';
import { industry_icon as industryIcon } from '../../../assets/icons/questionnare/questionnare';
import { primary_contact_name_icon as primaryContactIcon } from '../../../assets/icons/questionnare/questionnare';
import { growth_goal_icon as growthGoalIcon } from '../../../assets/icons/questionnare/questionnare';
import primaryEmailIcon from '../../../assets/icons/questionnare/email_icon.svg';
import { ticket_icon as ticketIcon } from '../../../assets/icons/questionnare/questionnare';
import { website_icon as websiteIcon } from '../../../assets/icons/questionnare/questionnare';
import { target_cpa_icon as targetCpaIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { profit_margin_icon as profitMarginIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { average_sales_icon as averageSalesIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { seasonability_icon as seasonabilityIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { app_related_services_icon as companyServicesIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { product_information_icon as productInfoIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { top_competitors_icon as topCompetitorIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { monthly_ad_spend_icon as monthlyAdSpendIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { lead_preferences_icon as leadPreferencesIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { monthly_budget_icon as monthlyBudgetIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { location_icon as locationIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { gender_icon as genderIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { advertise_icon as advertiseIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { maritial_status_icon as maritalStatusIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { customer_w_b_icon as customerWBIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { customer_interest_icon as interestIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { group_icon as excludeGroupIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { customer_household_income_icon as incomeIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { education_icon as educationIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { employment_status_icon as employmentIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { branding_icon as brandingIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { company_upload_logo_icon as companyUploadLogoIcon } from '../../../assets/icons/intakeForm/intakeForm';
import { award_icon as awardIcon } from '../../../assets/icons/intakeForm/intakeForm';
import customerTestimonialIcon from '../../../assets/icons/intakeForm/customer-testimonial-icon.svg';
import {
  clientDashboardIds,
  LDcomponentWithIndexClient,
} from '../../../utils/clientDashboardIdsUtil';

const questionBasicInformation = {
  company: {
    icon: companyIcon,
    questNumber: 1,
    questName: 'Company Name*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST1], //question number based on order of component rendering (clientDashboardUtil)
  },
  industry: {
    icon: industryIcon,
    questNumber: 2,
    questName: 'Industry',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST2],
  },
  companyWebsite: {
    icon: websiteIcon,
    questNumber: 3,
    questName: 'Company Website*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST3],
  },
  companyEmail: {
    icon: emailIcon,
    questNumber: 4,
    questName: 'Company Email Address',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST4],
  },
  companyPhone: {
    icon: contactIcon,
    questNumber: 5,
    questName: 'Company Phone Number',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST5],
  },
  companyAddress: {
    icon: addressIcon,
    questNumber: 6,
    questName: 'Company Office Address',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST6],
  },
  primaryContactName: {
    icon: primaryContactIcon,
    questNumber: 7,
    questName: 'Primary Contact - Full Name*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST7],
  },
  primaryContactRole: {
    icon: primaryContactIcon,
    questNumber: 8,
    questName: 'Primary Contact - Role/Title*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST8],
  },
  primaryContactEmail: {
    icon: emailIcon,
    questNumber: 9,
    questName: 'Primary Contact - Email Address*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST9],
  },
  primaryContactNumber: {
    icon: contactIcon,
    questNumber: 10,
    questName: 'Primary Contact - Phone Number*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST10],
  },
};
const platformInformation = {
  platforms: {
    questNumber: 1,
    questName: 'Please choose the platforms that you want us to work on*',
    questDescrip:
      'After choosing platforms, kindly mention their Spends and CPA to proceed.',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST1
    ],
  },
  averageTicketPrice: {
    icon: ticketIcon,
    questNumber: 2,
    questName: 'What is your Average Ticket Price(in $)?',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST2
    ],
  },
  cltv: {
    icon: diamondIcon,
    questNumber: 3,
    questName: 'What is your Customer Lifetime Value(in $)?',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST3
    ],
  },
  crm: {
    icon: crmSettingIcon,
    questNumber: 4,
    questName: 'Which CRM do you use?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST4
    ],
  },
  leadSource: {
    questNumber: 5,
    questName: 'What are your sources of leads?',
    questDescrip: 'Select as many as you use',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST5
    ],
  },
  competitors: {
    icon: growthIcon,
    questNumber: 6,
    questName: 'Enter your top competitors and their websites*',
    questDescrip: 'Enter as many competitors as you want',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST6
    ],
  },
  // 'Google-Facebook-CRO': {
  //   icon: growthGoalIcon,
  //   questNumber: 7,
  //   questName:
  //     'Please select the platforms for which you require our services.',
  //   questDescrip: '',
  //   id: LDcomponentWithIndexClient[clientDashboardIds.PLATFORM_INFORMATION_QUEST7],
  // },
  // brandDescription: {
  //   icon: growthIcon,
  //   questNumber: 8,
  //   questName: 'Describe your brand*',
  //   questDescrip:
  //     'Please start the description with your brand name and describe what the brand does/ sells. It would be great if you can mention some claims made by your brand or your USP, along with the type of audience you usually target.',
  //   id: LDcomponentWithIndexClient[clientDashboardIds.PLATFORM_INFORMATION_QUEST8],
  // },
};
const growthGoals = {
  goals: {
    icon: growthGoalIcon,
    questNumber: 1,
    questName: 'What are your growth goals?',
    questDescrip: 'Please elaborate your expectations and goals here',
    id: LDcomponentWithIndexClient[clientDashboardIds.GROWTH_GOALS_QUEST1],
  },
};

const intakeFormProductInfo = {
  targetCpa: {
    icon: targetCpaIcon,
    questNumber: 1,
    questName: 'What is your Target CPA (in $)?',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM1],
  },
  profitMargin: {
    icon: profitMarginIcon,
    questNumber: 2,
    questName: 'What is your Profit Margin?',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM2],
  },
  avgClosingPercent: {
    icon: averageSalesIcon,
    questNumber: 3,
    questName: 'What is your average sales closing percentage?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM3],
  },
  sessionality: {
    icon: seasonabilityIcon,
    questNumber: 4,
    questName: 'Is there any seasonality to your products/services?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM4],
  },
  appRelatedService: {
    icon: companyServicesIcon,
    questNumber: 5,
    questName: 'Does your company have an app related to these services?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM5],
  },
  descriptions: {
    icon: productInfoIcon,
    questNumber: 6,
    questName: 'Product/ Service Information*',
    questDescrip:
      'Please provide information about the products you want to advertise. NOTE: Please write only about the products/services that you wish to advertise. ',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM6],
  },
  brandDescription: {
    icon: productInfoIcon,
    questNumber: 7,
    questName: 'Describe your brand*',
    questDescrip:
      'Please start the description with your brand name and describe what the brand does/ sells. It would be great if you can mention some claims made by your brand or your USP, along with the type of audience you usually target.',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM7],
  },
  leadEmailAddress: {
    icon: emailIcon,
    questNumber: 8,
    questName: 'On which email address should the leads be sent?',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM8],
  },
  brandServiceKeywords: {
    icon: productInfoIcon,
    questNumber: 9,
    questName: 'Please add keywords relevant to your brand and services.',
    questDescrip:
      'Please provide information about the products you want to advertise.',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM9],
  },
};

const intakeFormPreferences = {
  competitorsIntake: {
    icon: topCompetitorIcon,
    questNumber: 1,
    questName: 'Enter your top competitors and their websites*',
    questDescrip: 'You can enter many inputs',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES1
    ],
  },
  monthlyAdSpend: {
    icon: monthlyAdSpendIcon,
    questNumber: 2,
    questName:
      'What is your total monthly ad spend budget (across all platforms, in $)?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES2
    ],
  },
  leadPreferences: {
    icon: leadPreferencesIcon,
    questNumber: 3,
    questName: 'What are your lead preferences?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES3
    ],
  },
  trackingNumberDisplay: {
    icon: contactIcon,
    questNumber: 4,
    questName:
      'Would you prefer your tracking phone number to show as your local area code or a toll-free number?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES4
    ],
  },
  handleDepletedAccountBalance: {
    icon: monthlyBudgetIcon,
    questNumber: 5,
    questName:
      'On rare occasions, your monthly budget may be depleted a few days before the end of the month. If this ever happens with your account how should we proceed?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES5
    ],
  },
};

const intakeFormAudience = {
  ageRange: {
    icon: monthlyAdSpendIcon,
    questNumber: 1,
    questName: 'What is the age range of your customer base?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE1
    ],
  },
  geoTargets: {
    icon: locationIcon,
    questNumber: 2,
    questName: "Enter the locations where you'd like to run your ad",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE2
    ],
  },
  gender: {
    icon: genderIcon,
    questNumber: 3,
    questName: 'Which gender is your main customer base?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE3
    ],
  },
  advertiseLocation: {
    icon: advertiseIcon,
    questNumber: 4,
    questName: 'Where do you advertise?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE4
    ],
  },
  maritalStatus: {
    icon: maritalStatusIcon,
    questNumber: 5,
    questName: 'What is the marital status of your target customer?',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE5
    ],
  },
  customerCollar: {
    icon: customerWBIcon,
    questNumber: 6,
    questName: 'Are your customers white-collar or blue-collar?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE6
    ],
  },
  activitiesInterest: {
    icon: interestIcon,
    questNumber: 7,
    questName: 'What activities/interests do your best customers have?',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE7
    ],
  },
  excludeAudiences: {
    icon: excludeGroupIcon,
    questNumber: 8,
    questName:
      'Are there specific locations, markets, or groups of people we should exclude?',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE8
    ],
  },
  customerIncomeRange: {
    icon: incomeIcon,
    questNumber: 9,
    questName: "What is your target customer's yearly household income?*",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE9
    ],
  },
  customerEducation: {
    icon: educationIcon,
    questNumber: 10,
    questName:
      'What is the highest level of school your target customer has completed or the highest degree they have received? (Check all that apply)*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE10
    ],
  },
  customerEmployment: {
    icon: employmentIcon,
    questNumber: 11,
    questName:
      "Which of the following categories best describes your target customer's employment status?*",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE11
    ],
  },
  formalCustomerPersona: {
    icon: primaryContactIcon,
    questNumber: 12,
    questName: 'If you have formal customer personas please upload them here.',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE12
    ],
  },
};
const intakeBrandingForm = {
  brandingStyleGuide: {
    icon: companyIcon,
    questNumber: 1,
    questName:
      "Please upload your company's branding or style guide. Should include company colors, fonts, logos, and usage guidelines.",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING1
    ],
  },
  companyLogo: {
    icon: companyIcon,
    questNumber: 2,
    questName:
      "Please upload your company's logo in vector or its largest format.",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING2
    ],
  },

  brandingMaterials: {
    icon: companyIcon,
    questNumber: 3,
    questName: 'Please upload your company branding material here.',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING3
    ],
  },
  companyClientLogo: {
    icon: companyIcon,
    questNumber: 4,
    questName:
      "Please upload your most reputable client's logos. (B2B clients only) ",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING4
    ],
  },
  companyClientWebsite: {
    icon: companyIcon,
    questNumber: 5,
    questName:
      "If you don't have the logos of your clients, please list their websites. We will do our best to gather their logos.",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING5
    ],
  },
  awards: {
    icon: companyIcon,
    questNumber: 6,
    questName:
      'Please upload logos, awards, and any other credibility signals. For example, mentioned in Forbes, won Best of State, Fortune 500, etc.',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING6
    ],
  },
  productImages: {
    icon: companyIcon,
    questNumber: 7,
    questName:
      'Please upload any photos, product images, and graphical assets. Include product photos, location photos, custom icon kits, custom graphics, etc.',
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING7
    ],
  },
};
const intakeSocialProofForm = {
  testimonial1: {
    icon: companyIcon,
    questNumber: 1,
    questName:
      "Customer Testimonial #1 Include the customer's name, age, gender, and job title when relevant.",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF1
    ],
  },
  testimonial2: {
    icon: companyIcon,
    questNumber: 2,
    questName:
      "Customer Testimonial #2 Include the customer's name, age, gender, and job title when relevant.",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF2
    ],
  },
  testimonial3: {
    icon: companyIcon,
    questNumber: 3,
    questName:
      "Customer Testimonial #3 Include the customer's name, age, gender, and job title when relevant.",
    questDescrip: '',
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF3
    ],
  },
};
export const fbTargeting = {
  AgeRange: {
    questNumber: 1,
    questName: 'What is the age range of your target customer base?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING1],
  },
  gender: {
    questNumber: 2,
    questName: 'What is the gender of your target customer base?*',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING2],
  },
  advertisingLocations: {
    questNumber: 3,
    questName: 'What locations do you advertise in?',
    questDescrip: '',
    id: 66,
  },
  aboutBusines: {
    questNumber: 4,
    questName:
      'Please mention a few interest keywords that you want us to target.*',
    questDescrip:
      'Try to keep the interest keywords generic (related to your brand or service, as it will help us in finding better target groups for you.',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING4],
  },
  excludes: {
    questNumber: 5,
    questName:
      'Are there specific locations, markets, or groups of people we should exclude? Enter them here.',
    questDescrip: '',
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING5],
  },
};
export const crmQuestions = [
  'Mail Chimp',
  'Constant Contact',
  'Speak2Leads',
  'Campaign Monitor',
  'HubSpot',
  'Salesforce',
  'AWeber',
  'Infusionsoft',
  "Don't use any",
  'Other',
];

export const sourcesOfLeads = [
  'Pay-per-click (PPC) advertising',
  'Listing websites',
  'Social Media',
  'Email newsletters',
  'Referrals',
  'Search engines',
  'Other',
];

export const mappedQuestion = {
  personalInfo: questionBasicInformation,
  platformInfo: platformInformation,
  growthGoal: growthGoals,
  productServiceInfo: intakeFormProductInfo,
  preferences: intakeFormPreferences,
  audiences: intakeFormAudience,
  brandAssets: intakeBrandingForm,
  customerTestimonial: intakeSocialProofForm,
  facebookTargeting: fbTargeting,
};

export const mappedFbTargetingQuestion = {
  facebookTargeting: fbTargeting,
};
