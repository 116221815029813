import React, { useEffect, useState } from "react";
import { pixis_icon as pixisIcon } from "../../../assets/icons/icon";
import backgroundImage from "../../../assets/images/leftsidepanelbg.svg";
import { home_icon as homeIcon } from "../../../assets/icons/icon";
import { more_info_icon as moreInfoIcon } from "../../../assets/icons/icon";
import { alert_icon as alertIcon } from "../../../assets/icons/icon";
import { profile_icon as profileIcon } from "../../../assets/icons/icon";
import { arrow_down_icon as arrowDown } from "../../../assets/icons/icon";
import { Box, Container, Tabs, Typography, Grid, Divider } from "@mui/material";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import { logout } from "../../../api/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  checkingReadAccess,
  dbStatus,
  checkingForCompleteAccess,
} from "../HomePage/HomePage";

import { log_out as logOutIcon } from "../../../assets/icons/icon";
// import notificationStar from '../../../src/assets/icons/star.svg';
import { formStatus } from "../../../utils/notificationUtil";
import {
  clientDashboardIds,
  LDcomponentWithIndexClient,
  showAnalyticsDashboard,
} from "../../../utils/clientDashboardIdsUtil";
import { useAuth0 } from "@auth0/auth0-react";
import "./navigationSteps.css";

const NavigationSteps = ({ navigateSteps, active, dashboard }) => {
  const currentCompo = useSelector(
    (state) => state.clientCurrentComp.currentComp
  );
  const clientState = useSelector((state) => state.client);
  const [profilePopUp, setProfilePopUp] = useState(false);
  const [notificationPopUp, setNotificationPopUp] = useState(false);
  // const currentNavIndex = navigateSteps?.findIndex((nav) => nav.id === active);
  const [navigateStepsWithStatus, setNavigateStepsWithStatus] = useState([]);
  const [notificationArray, setNotificationArray] = useState([]);
  const [stepName, setStep] = useState("");
  const dispatch = useDispatch();
  const { logout: logoutAuth0 } = useAuth0();
  const logoutFromUser = () => {
    logoutAuth0({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } });
    dispatch(logout());
  };
  const isPublished = clientState.published;
  const isOnboardingCompleted = clientState.onboardingInfo.status;
  const isSowPricingCompleted = clientState.sowPricingInfo.status;
  const [loginEmail, setLoginEmail] = useState();
  const [loginName, setLoginName] = useState("");
  const proposalStage =
    clientState.proposalIterations.length !== 0
      ? clientState["proposalIterations"][
          clientState.proposalIterations.length - 1
        ]["state"]
      : null;
  const sharedAssetsAccountsGoogle = useSelector(
    (state) => state.sharedAssets.googleSharedAssetsAccounts
  );
  const strategyIterations = useSelector(
    (state) => state.client.strategyIterations
  );
  const platformSelected = useSelector(
    (state) => state?.client?.onboardingInfo?.platformInfo?.platforms
  );
  const accountid = useSelector((state) => state?.client?.accessSharing);

  useEffect(() => {
    setLoginName(localStorage.getItem("amName") || "Jim Halpert");
    setLoginEmail(localStorage.getItem("sub"));
  }, []);
  useEffect(() => {
    const notificationArray = [];
    const notificationData = { ...sharedAssetsAccountsGoogle, ...clientState };
    if (notificationData) {
      notificationArray.push(
        "Thank you for choosing Linear Design. Please fill your onboarding form to start the process."
      );
      if (notificationData?.onboardingInfo?.status === formStatus.completed) {
        notificationArray.push(
          "Thanks for filling up the onboarding form. Please provide read access to your accounts so that we can generate a proposal for you."
        );
      }
      if (
        notificationData?.gadsAssets &&
        notificationData?.gadsAssets?.length > 0
      ) {
        notificationArray.push(
          "Thanks for providing read access. Please wait while we review them."
        );
        notificationData?.gadsAssets.forEach((gad) => {
          if (gad.status === formStatus.read_access) {
            notificationArray.push(
              "Read access has been successfully shared. We have started working on the proposal."
            );
          }
        });
        if (
          notificationData.gadsAssets.every(
            (gad) => gad.status === formStatus.ACCESS_REVOKED
          )
        ) {
          notificationArray.push(
            "It seems that correct read access has not been provided for these accounts - 'ad account ids'. Please click here to do it again. AI - Include all the 'account ids' where AM didn't recieved the access. (Dynamic)"
          );
        }
      }
      if (
        notificationData?.proposalIterations &&
        notificationData?.proposalIterations.length > 0
      ) {
        if (
          notificationData.proposalIterations[
            notificationData.proposalIterations.length - 1
          ].state === formStatus.generated
        ) {
          notificationArray.push(
            "Your proposal is ready! Please review it and feel free to suggest any changes using the 'Suggest Changes' section."
          );
        }
        if (
          notificationData.proposalIterations[
            notificationData.proposalIterations.length - 1
          ].state === formStatus.approved
        ) {
          notificationArray.push(
            "Thanks for accepting the proposal! Please provide complete access to your accounts so that we can prepare a strategy for you."
          );
        }
        if (
          notificationData.proposalIterations[
            notificationData.proposalIterations.length - 1
          ].state === formStatus.changes_suggested
        ) {
          notificationArray.push(
            "We have received your changes. Hold tight while we work on a new proposal for you."
          );
        }
      }
      if (
        notificationData?.gadsAssets &&
        notificationData?.gadsAssets?.length > 0
      ) {
        notificationData?.gadsAssets.forEach((gad) => {
          if (gad.status === formStatus.verifiying_complete_access) {
            notificationArray.push(
              "Thanks for providing complete access. Please wait while we review them."
            );
          }
          if (gad.status === formStatus.complete_access) {
            notificationArray.push(
              "Complete access has been successfully shared. Please fill the intake form now. This will help us in getting an indepth understanding about your brand, product and audience."
            );
          }
          if (gad.status === formStatus.access_revoke) {
            notificationArray.push(
              "Complete access has been successfully shared. Please fill the intake form now. This will help us in getting an indepth understanding about your brand, product and audience."
            );
          }
        });
      }
      if (notificationData?.intakeInfo?.state === formStatus.completed) {
        notificationArray.push(
          "The intake form has been successfully submitted! We are working on your strategy now."
        );
      }
      if (
        notificationData?.strategyIterations &&
        notificationData?.strategyIterations?.length > 0
      ) {
        if (
          notificationData.strategyIterations[
            notificationData.strategyIterations.length - 1
          ].state === formStatus.generated
        ) {
          notificationArray.push(
            "The strategy deck has been released! Please have a look. You can approve the keywords, headlines and descriptions you like. If you don't like a keyword, headline or description - please disapprove it, we will immediately generate a new one for you."
          );
        }
        if (
          notificationData.strategyIterations[
            notificationData.strategyIterations.length - 1
          ].state === formStatus.approved
        ) {
          notificationArray.push(
            "Thanks for accepting the strategy! We'll update you once the campaigns have been successfully published in your ad account."
          );
        }
        if (
          notificationData.strategyIterations[
            notificationData.strategyIterations.length - 1
          ].state === formStatus.changeschanges_suggested_applied
        ) {
          notificationArray.push(
            "We have received your changes. Hold tight while we work on a new strategy for you."
          );
        }
      }
    }
    setNotificationArray(notificationArray);
    console.log(notificationArray);
  }, [clientState, sharedAssetsAccountsGoogle]);
  useEffect(() => {
    const isCampaignApproved = clientState?.generatedCampaignsInfo
      ? clientState?.generatedCampaignsInfo
      : null;
    const readAccessGranted = sharedAssetsAccountsGoogle.findIndex(
      (account) => account.status === "READ_ACCESS"
    );
    const readAccessVerifying = sharedAssetsAccountsGoogle.findIndex(
      (account) => account.status === "VERIFYING"
    );
    const avoidCompleteAccess =
      clientState.proposalIterations.length !== 0 &&
      clientState.proposalIterations[clientState.proposalIterations.length - 1]
        .state === "APPROVED"
        ? false
        : true;
    const verifyingCompleteAccess = sharedAssetsAccountsGoogle.findIndex(
      (account) => account.status === "VERIFYING_COMPLETE_ACCESS"
    );
    const isCompleteAccess = sharedAssetsAccountsGoogle.findIndex(
      (account) => account.status === "COMPLETE_ACCESS"
    );
    const isCompleteAccessNotShared = sharedAssetsAccountsGoogle.findIndex(
      (account) => account.status === "COMPLETED_ACCESS_NOT_SHARED"
    );
    const intakeCompleted = clientState.intakeInfo.status;
    const tempArr = [];
    console.log(
      "checkingForCompleteAccess(platformSelected,accountid))",
      checkingForCompleteAccess(platformSelected, accountid)
    );
    console.log("platformSelected", platformSelected, "account id", accountid);
    navigateSteps.forEach((nav, index) => {
      //Onboarding Form
      // if (isOnboardingCompleted === "COMPLETED" && index === 0) {
      //   tempArr.push({
      //     status: "COMPLETED",
      //     text: "Completed",
      //     name: nav.name,
      //     imgIcon: nav.imgIcon,
      //     id: nav.id,
      //   });
      // } else if (isOnboardingCompleted === "ONGOING" && index === 0) {
      //   tempArr.push({
      //     status: "CURRENT",
      //     text: "Ongoing",
      //     imgIcon: nav.imgIcon,
      //     name: nav.name,
      //     id: nav.id,
      //   });
      // }

      // //SOW & Pricing Form
      // else if (isSowPricingCompleted === "COMPLETED" && index === 1) {
      //   tempArr.push({
      //     status: "COMPLETED",
      //     text: "Completed",
      //     name: nav.name,
      //     imgIcon: nav.imgIcon,
      //     id: nav.id,
      //   });
      // } else if (isSowPricingCompleted === "ONGOING" && index === 1) {
      //   tempArr.push({
      //     status: "CURRENT",
      //     text: "Ongoing",
      //     imgIcon: nav.imgIcon,
      //     name: nav.name,
      //     id: nav.id,
      //   });
      // }
      //Read Access
      // else if (
      if (
        ([dbStatus.READ_ACCESS].includes(
          checkingReadAccess(platformSelected, accountid)
        ) ||
          [
            dbStatus.COMPLETE_ACCESS,
            dbStatus.COMPLETED_ACCESS_NOT_SHARED,
            dbStatus.VERIFYING_COMPLETE_ACCESS,
          ].includes(checkingForCompleteAccess(platformSelected, accountid))) &&
        index === 0 &&
        platformSelected.length !== 0
      ) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          name: nav.name,
          imgIcon: nav.imgIcon,
          id: nav.id,
        });
      } else if (
        (checkingReadAccess(platformSelected, accountid) ===
          dbStatus.VERIFYING ||
          isSowPricingCompleted === "COMPLETED") &&
        index === 0
      ) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //Proposal
      else if (proposalStage === "APPROVED" && index === 1) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (
        ([dbStatus.READ_ACCESS].includes(
          checkingReadAccess(platformSelected, accountid)
        ) ||
          [
            dbStatus.COMPLETE_ACCESS,
            dbStatus.COMPLETED_ACCESS_NOT_SHARED,
            dbStatus.VERIFYING_COMPLETE_ACCESS,
          ].includes(checkingForCompleteAccess(platformSelected, accountid))) &&
        index === 1 &&
        platformSelected.length !== 0
      ) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (proposalStage === "GENERATED" && index === 1) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (proposalStage === "CHANGES_SUGGESTED" && index === 1) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (readAccessGranted !== -1 && index === 1) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //intake form
      else if (intakeCompleted === "COMPLETED" && index === 2) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (proposalStage === "APPROVED" && index === 2) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //Complete Access
      else if (
        checkingForCompleteAccess(platformSelected, accountid) ===
          dbStatus.COMPLETE_ACCESS &&
        index === 3 &&
        platformSelected.length !== 0
      ) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (intakeCompleted === "COMPLETED" && index === 3) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //strategy deck
      else if (
        index === 4 &&
        strategyIterations.length !== 0 &&
        strategyIterations[strategyIterations.length - 1].state ===
          "APPROVED" &&
        platformSelected.length !== 0
      ) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (
        checkingForCompleteAccess(platformSelected, accountid) ===
          dbStatus.COMPLETE_ACCESS &&
        strategyIterations.length !== 0 &&
        (strategyIterations[strategyIterations.length - 1].state ===
          "GENERATED" ||
          strategyIterations[strategyIterations.length - 1].state ===
            "CHANGES_SUGGESTED") &&
        index === 4 &&
        platformSelected.length !== 0
      ) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (
        index === 4 &&
        checkingForCompleteAccess(platformSelected, accountid) ===
          dbStatus.COMPLETE_ACCESS &&
        platformSelected.length !== 0
      ) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //campaign preview
      else if (
        platformSelected.findIndex(
          (platform) => platform.platform === "google-ads"
        ) !== -1 &&
        showAnalyticsDashboard(clientState)
      ) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (
        platformSelected.findIndex(
          (platform) => platform.platform === "facebook-ads"
        ) !== -1 &&
        showAnalyticsDashboard(clientState)
      ) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //Creative Approval
      else if (index === 7 && showAnalyticsDashboard(clientState)) {
        // else if(index === 9){
        tempArr.push({
          status: "COMPLETED",
          text: "",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (index === 8 && showAnalyticsDashboard(clientState)) {
        // else if(index === 10){
        tempArr.push({
          status: "COMPLETED",
          text: "",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } else if (nav.id === active) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //final
      else {
        tempArr.push({
          status: "INCOMPLETED",
          text: [7, 8].includes(index) ? "" : "To be Unlocked",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
    });
    //console.log("checkingReadAccess(platformSelected,accountid)",checkingReadAccess(platformSelected,accountid));
    setNavigateStepsWithStatus(tempArr);
  }, [
    active,
    isOnboardingCompleted,
    currentCompo,
    sharedAssetsAccountsGoogle,
    strategyIterations,
  ]);
  const redirectToCompo = (id, name) => {
    if (id === "CREATIVE_APPROVAL") {
      dispatch(
        goToSpecificCompoClient(
          LDcomponentWithIndexClient[
            clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES
          ]
        )
      );
    } else if (id === "AnalyticDashboard") {
      window.location.href = "/analytic-dashboard";
    }
  };
  return (
    <Box
      style={{
        background: `url(${backgroundImage}),white`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPositionY: -38,
        height: "100vh",
      }}
    >
      <div className="sidebar_layout">
        <div className="logoContainer">
          <img src={pixisIcon} alt="" />
          <Typography
            variant="h4"
            component="h3"
            style={{
              color: "#0869FB",
              marginTop: 10,
            }}
          >
            Onboarding
          </Typography>
          {/* <h2>Automations</h2> */}
        </div>
        {navigateStepsWithStatus.length !== 0 && (
          <div className="steps">
            <div className="steps_inner">
              {navigateStepsWithStatus?.map((step, key) => (
                <div className="stepHelper" key={key}>
                  <div
                    className={`step ${
                      stepName === step.id &&
                      ["CREATIVE_APPROVAL", "AnalyticDashboard"].includes(
                        step.id
                      )
                        ? "stepHighlight"
                        : ""
                    }`}
                    id={step.id}
                    onClick={() => {
                      if (step.status === "COMPLETED") {
                        redirectToCompo(step.id, step.name);
                      }
                      setStep(step.id);
                    }}
                  >
                    <div
                      className={
                        step.status === "COMPLETED"
                          ? "question-logo completedQuest"
                          : step.status === "CURRENT"
                          ? "question-logo selectedBackground"
                          : "question-logo"
                      }
                    >
                      <span className="left_nav_icon"></span>
                    </div>
                    <div className="step-description">
                      <Typography
                        style={{
                          marginBottom: 0,
                          fontSize: "14px",
                          color:
                            step.status === "CURRENT" ||
                            step.status === "COMPLETED"
                              ? "#000000"
                              : "#adadad",
                        }}
                      >
                        {step.name}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: 0,
                          fontSize: 12,
                          color: "#aeaeae",
                        }}
                      >
                        {step.text}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {profilePopUp && (
          <div
            style={{
              width: 200,
              textAlign: "center",
              height: "auto",
              position: "absolute",
              bottom: "5rem",
              right: "0rem",
              backgroundColor: "white",
              // border: '1px solid #d3d3d3',
              zIndex: 1,
              cursor: "pointer",
              background: "#FFFFFF",
              border: "0.35px solid #E9E9E9",
              boxShadow: "0px 7px 80px rgba(0, 0, 0, 0.05)",
              borderRadius: "8px 8px 0px 8px",
              padding: "0.3rem",
            }}
          >
            <p
              style={{
                paddingBottom: "1rem",
                paddingRight: "inherit",
                paddingLeft: "inherit",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                gap: 8,
                marginBottom: 0,
              }}
              onClick={() => logoutFromUser()}
            >
              {localStorage.getItem("brandId")}
              <div
                style={{
                  width: 20,
                  height: 20,
                }}
              >
                <img src={logOutIcon} alt="" width={18} height={18} />
              </div>
            </p>
          </div>
        )}
        {notificationPopUp && (
          <div
            className="notifiacitonPopup"
            style={{
              width: 342,
              height: 392,
              position: "absolute",
              bottom: "5rem",
              left: "5rem",
              backgroundColor: "white",
              // border: '1px solid #d3d3d3',
              zIndex: 1,
              cursor: "pointer",
              background: "#FFFFFF",
              border: "0.35px solid #E9E9E9",
              boxShadow: "0px 7px 80px rgba(0, 0, 0, 0.05)",
              borderRadius: "8px 8px 0px 8px",
            }}
          >
            <h3>Notifications</h3>
            {notificationArray.length !== 0 && (
              <div className="notification-list">
                {notificationArray.reverse()?.map((notification, index) => (
                  <div className="notifiaciton-box" key={index}>
                    <div className="notifiaciton-card">
                      <p>{notification} </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* <div className="notification-list">
              <div className="notifiaciton-box">
                <div className="notifiaciton-card">
                  <label><span><img src={notificationStar} alt='' /></span>Proposal Generated <span className="days-ago">2 days ago</span></label>
                  <p>Lorem ipsum dolor sit amet, consectetur elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                </div>
              </div>
              <div className="notifiaciton-box">
                <div className="notifiaciton-card">
                  <label><span><img src={notificationStar} alt='' /></span>Proposal Generated <span class="now">now</span></label>
                  <p>Lorem ipsum dolor sit amet, consectetur elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                </div>
              </div>
              <div className="notifiaciton-box">
                <div className="notifiaciton-card">
                  <label><span><img src={notificationStar} alt='' /></span>Proposal Generated <span>now</span></label>
                  <p>Lorem ipsum dolor sit amet, consectetur elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor</p>
                </div>
              </div>
            </div> */}
          </div>
        )}
        <div className="navigation_footer">
          <div className="nav-footer-icons">
            <div
              className="footer-icon"
              style={{
                backgroundColor: currentCompo === 0 ? "#0769FB" : "#eee",
              }}
            >
              <img
                src={homeIcon}
                alt=""
                onClick={() => {
                  if (dashboard) {
                    window.location.href = "/client";
                  } else {
                    dispatch(
                      goToSpecificCompoClient(
                        LDcomponentWithIndexClient[
                          clientDashboardIds.RENDER_HOME_PAGE
                        ]
                      )
                    );
                  }
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="footer-icon">
              <img src={moreInfoIcon} alt="" className="moreInfoIcon-img" />
            </div>
            <div className="footer-icon">
              <img
                src={alertIcon}
                alt=""
                onClick={() => setNotificationPopUp(!notificationPopUp)}
              />
            </div>
            <div
              className="footer-icon profile-icon"
              onClick={() => setProfilePopUp(!profilePopUp)}
            >
              <img src={profileIcon} alt="" />
              <img src={arrowDown} alt="" className="arrow-img" />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default NavigationSteps;
