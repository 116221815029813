import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Form from './Form';
import clientLogo from '../../assets/images/admin-client-logo.svg';
import adminLogo from '../../assets/images/admin-manager-logo.svg';
import jwt_decode from 'jwt-decode';
import { getLocalStorage } from '../../api/localStorage/localStorage';
import { generatePasswordApi } from '../../api/auth/saga';
import roles from '../../utils/roles';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import ConnectAccount from '../Admin/ConnectAccountModal';
import { AdsList } from '../Admin/channel.js';
import { useDispatch } from 'react-redux';
import Pop from './AdminPop';
import { useAuth0 } from '@auth0/auth0-react';

const Admin = () => {
  const [client, setClient] = useState({ name: null, email: null });
  const [accManager, setAccManager] = useState({ name: null, email: null });
  const [role, setRole] = useState(null);
  const [generatedPassword, setGeneratedPassword] = useState({
    client: null,
    accManager: null,
  });
  const [error, setError] = useState({ client: null, accManager: null });
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  const setToDefault = (key, type) => {
    if (type === 'error') {
      setError({ ...error, [key]: null });
    } else if (type === 'password') {
      setGeneratedPassword({ ...generatedPassword, [key]: null });
    }
  };

  const validation = (role) => {
    if (role === roles.CLIENT) {
      return client.name?.length > 0 && client.email?.length > 0;
    } else if (role === roles.ACCOUNT_MANAGER) {
      return accManager.name?.length > 0 && accManager.email?.length > 0;
    }
  };

  const generatePassword = (role) => {
    if (!validation(role)) {
      if (role === roles.CLIENT) {
        setError({ ...error, client: "fields can't be empty" });
      } else if (role === roles.ACCOUNT_MANAGER) {
        setError({ ...error, accManager: "fields can't be empty" });
      }
      return;
    }
    if (role === roles.CLIENT && client.name?.length <= 2) {
      setError({
        ...error,
        client: 'Brand name should have more than 2 characters',
      });
      return;
    }
    let obj = null;
    if (role === roles.CLIENT) {
      obj = { brandId: client.name, role: role, username: client.email };
    } else if (role === roles.ACCOUNT_MANAGER) {
      obj = {
        accountManagerName: accManager.name,
        role: role,
        username: accManager.email,
      };
    }
    generatePasswordApi(obj)
      .then((res) => {
        const { role, password } = res;
        if (role === roles.CLIENT) {
          setGeneratedPassword({ ...generatedPassword, ['client']: password });
          setToDefault('client', 'error');
        } else if (role === roles.ACCOUNT_MANAGER) {
          setGeneratedPassword({
            ...generatedPassword,
            ['accManager']: password,
          });
          setToDefault('accManager', 'error');
        }
      })
      .catch((err) => {
        if (role === roles.CLIENT) {
          setError({ ...error, client: err.response.data.detail });
        } else if (role === roles.ACCOUNT_MANAGER) {
          setError({ ...error, accManager: err.response.data.detail });
        }
      });
  };

  const getData = (dataFields, text, field, role) => {
    if (role === roles.CLIENT) {
      setClient({ ...dataFields, [field]: text });
      setToDefault('client', 'password');
    } else if (role === roles.ACCOUNT_MANAGER) {
      setAccManager({ ...dataFields, [field]: text });
      setToDefault('accManager', 'password');
    }
  };
  const { logout: logoutAuth0 } = useAuth0();
  useEffect(() => {
    const decode = jwt_decode(getLocalStorage('accessToken'));
    setRole(decode.role);
  }, [role]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100vw',
          backgroundColor: '#ffff',
          position: 'absolute',
          marginTop: '10px',
        }}
      >
        <Button
          style={{ transform: 'translateX(20px)', color: '#0869FB' }}
          onClick={() => {
            window.location.href = '/admin';
          }}
        >
          <ArrowBackIcon /> BACK
        </Button>
        <Button
          style={{ transform: 'translateX(-20px)', color: '#7308FB' }}
          onClick={() => {
            logoutAuth0({
              logoutParams: { returnTo: process.env.REACT_APP_BASE_URL },
            });
            localStorage.clear();
            window.location.href = '/';
          }}
        >
          Logout {'  '} <LogoutIcon />
        </Button>
      </div>
      <Grid
        container
        style={{ width: '100vw', height: '100vh', overflowX: 'hidden' }}
      >
        <Grid xs={12} sm={12} md={6} style={{ backgroundColor: '#FBFBFF' }}>
          <Form
            img={clientLogo}
            title='CLIENT'
            role={roles.CLIENT}
            textFieldLables={{
              box1: 'Brand Name',
              box2: 'E-mail ID',
            }}
            btnBackgColor='#0869FB'
            generatePassword={generatePassword}
            dataFields={client}
            getData={getData}
            email={client.email}
            generatedPassword={generatedPassword.client}
            error={error.client}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} style={{ backgroundColor: '#FFFF' }}>
          <Form
            img={adminLogo}
            title='ACCOUNT MANAGER'
            role={roles.ACCOUNT_MANAGER}
            textFieldLables={{
              box1: 'Account Manager Name',
              box2: 'E-mail ID',
            }}
            generatePassword={generatePassword}
            btnBackgColor='#7308FB'
            dataFields={accManager}
            getData={getData}
            email={accManager.email}
            generatedPassword={generatedPassword.accManager}
            error={error.accManager}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default Admin;
