import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../ClientDashboard/ButtonPrimary/Buttons";
import { arrowDownIcon, warningIcon } from "../../assets/icons/creative/creative";
// import warningIcon from "../../assets/icons/creative/warning-icon.svg";
import { Button, Typography } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { useEffect, useState, useMemo, useRef } from "react";
import FacebookAdCopy from "../CreativeResources/ADCopies/FacebookAdCopy";
import GoogleAdCopy from "../CreativeResources/ADCopies/GoogleAdCopy";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { prepareAdCopy } from "../../utils/creativeApprovalUtil";
import {
  CREATIVE_OBJECT_STATUS_ENUM,
  PLATFORM_NAME,
} from "../../api/creativeApproval/enums";
import {
  addAdCopy,
  updateToBeSaved,
  putAdCopies,
  updateAdCopyContent,
  fetchAdCopies,
  updateAdCopyStatus,
} from "../../api/creativeApproval/slice";

const AdCopies = ({ isClient }) => {
  const dispatch = useDispatch();
  const adCopies = useSelector((state) => state.creativeApproval.adCopies);
  const toBeSaved = useSelector((state) => state.creativeApproval.toBeSaved);
  const [newAdUuid, setNewAdUuid] = useState(null);
  const [addNewAdCopy, setAddNewAdCopy] = useState(false);
  const [openPending, setOpenPending] = useState(true);
  const [openApproved, setOpenApproved] = useState(false);
  const [openDisapproved, setOpenDisapproved] = useState(false);
  const scrollToRef = useRef();

  // cache adCopies
  const sortedAdCopies = useMemo(
      () => adCopies
              .slice()
              .sort((a, b) => b.updatedOn.localeCompare(a.updatedOn))
    , 
   [JSON.stringify(adCopies)]);


  useEffect(() => {
    dispatch(fetchAdCopies());
  }, []);

  useEffect(() => {
    if (toBeSaved) {
      // call api with put request
      dispatch(putAdCopies());
      dispatch(updateToBeSaved());
    }
  }, [toBeSaved]);

  const onSaveHandler = ({
    id,
    theme,
    campaignName,
    headlines,
    descriptions,
    primaryText,
  }) => {
    dispatch(
      updateAdCopyContent({
        id,
        theme,
        status: CREATIVE_OBJECT_STATUS_ENUM.PENDING,
        campaignName,
        headlines,
        descriptions,
        primaryText,
      })
    );
    if(newAdUuid === id){
      setNewAdUuid(null);
    }
    setOpenPending(true);
    setOpenDisapproved(false);
    setOpenApproved(false);
  };

  // not awailable at account manager side
  const onApproveHandler = (id) => {
    dispatch(updateAdCopyStatus({
        id, status: CREATIVE_OBJECT_STATUS_ENUM.APPROVED
    }));
  };

  // not awailable at account manager side
  const onDispproveHandler = (id) => {
    dispatch(updateAdCopyStatus({
        id, status: CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED
    }));
  };

  const onRemoveHandler = ({
    id,
    theme,
    campaignName,
    headlines,
    descriptions,
    primaryText,
  }) => {
    dispatch(
      updateAdCopyContent({
        id,
        theme,
        status: CREATIVE_OBJECT_STATUS_ENUM.REMOVED,
        campaignName,
        headlines,
        descriptions,
        primaryText,
      })
    );
    if(newAdUuid === id){
      setNewAdUuid(null);
    }
  };


  const fbAddNewAdCopies = () => {
    const newAdCopy = prepareAdCopy(PLATFORM_NAME.FACEBOOK);
    setNewAdUuid(newAdCopy.id);
    setOpenPending(true);
    dispatch(addAdCopy([newAdCopy, ...sortedAdCopies]));
    setAddNewAdCopy(false);
    scrollToRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const googleAddNewAdCopies = () => {
    const newAdCopy = prepareAdCopy(PLATFORM_NAME.GOOGLE);
    setNewAdUuid(newAdCopy.id);
    setOpenPending(true);
    dispatch(addAdCopy([newAdCopy, ...sortedAdCopies]));
    setAddNewAdCopy(false);
    scrollToRef.current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <div>
      {sortedAdCopies.length ? (
        <div>
          <Accordion
            ref={scrollToRef}
            expanded={openPending}
            style={{
              marginBottom: 16,
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{
                background: "#F7FAFF",
                height: "64px",
                cursor: "pointer",
              }}
              expandIcon={<ExpandCircleDownOutlined />}
              IconButtonProps={{
                onClick: () => {
                  setOpenPending(prev => !prev);
                },
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: 18,
                }}
              >
                Pending
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {sortedAdCopies.some((creative) => {
                return creative.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING;
              }) ? (
                sortedAdCopies
                  .filter((creative) => {
                    return (
                      creative.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING
                    );
                  })
                  .map((ad, index) => {
                    if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                      return (
                        <FacebookAdCopy
                          key={index}
                          expand={newAdUuid === ad.id ? true : false}
                          adCopy={true}
                          read={newAdUuid === ad.id ? false : true}
                          id={ad.id}
                          headlineList={ad.headlines}
                          descriptionList={ad.descriptions}
                          primaryText={ad.primaryText}
                          campaignName={ad.campaignName}
                          theme={ad.theme}
                          status={CREATIVE_OBJECT_STATUS_ENUM.PENDING}
                          isClient={isClient}
                          onSaveHandler={onSaveHandler}
                          onApproveHandler={onApproveHandler}
                          onDisapproveHandler={onDispproveHandler}
                          onRemoveHandler={onRemoveHandler}
                        />
                      );
                    }
                    if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                      return (
                        <GoogleAdCopy
                          key={index}
                          expand={newAdUuid === ad.id ? true : false}
                          adCopy={true}
                          read={newAdUuid === ad.id ? false : true}
                          id={ad.id}
                          headlineList={ad.headlines}
                          descriptionList={ad.descriptions}
                          campaignName={ad.campaignName}
                          theme={ad.theme}
                          status={CREATIVE_OBJECT_STATUS_ENUM.PENDING}
                          isClient={isClient}
                          onSaveHandler={onSaveHandler}
                          onApproveHandler={onApproveHandler}
                          onDisapproveHandler={onDispproveHandler}
                          onRemoveHandler={onRemoveHandler}
                        />
                      );
                    }
                  })
              ) : (
                <h1
                  style={{
                    fontFamily: "Inter",
                    textAlign: "center",
                    color: "rgba(51,51,51,0.8)",
                    fontWeight: "500",
                    fontSize: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 6,
                  }}
                >
                  <img src={warningIcon} alt="" width={20} height={20} />
                  No pending Ad Copies
                </h1>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={openApproved}
            style={{
              marginBottom: 16,
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{
                background: "rgba(26, 191, 161, 0.05)",
                height: "64px",
                cursor: "pointer",
              }}
              IconButtonProps={{
                onClick: () => {
                  setOpenApproved(prev => !prev);
                },
              }}
              expandIcon={<ExpandCircleDownOutlined />}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: 18,
                }}
              >
                Approved
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {sortedAdCopies.some((creative) => {
                return creative.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED;
              }) ? (
                sortedAdCopies
                  .filter((creative) => {
                    return (
                      creative.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED
                    );
                  })
                  .map((ad, index) => {
                    if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                      return (
                        <FacebookAdCopy
                          key={index}
                          expand={newAdUuid === ad.id ? true : false}
                          adCopy={true}
                          read={newAdUuid === ad.id ? false : true}
                          id={ad.id}
                          headlineList={ad.headlines}
                          descriptionList={ad.descriptions}
                          primaryText={ad.primaryText}
                          campaignName={ad.campaignName}
                          theme={ad.theme}
                          status={ad.status}
                          isClient={isClient}
                          onSaveHandler={onSaveHandler}
                          onApproveHandler={onApproveHandler}
                          onDisapproveHandler={onDispproveHandler}
                          onRemoveHandler={onRemoveHandler}
                        />
                      );
                    }
                    if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                      return (
                        <GoogleAdCopy
                          key={index}
                          expand={newAdUuid === ad.id ? true : false}
                          adCopy={true}
                          read={newAdUuid === ad.id ? false : true}
                          id={ad.id}
                          headlineList={ad.headlines}
                          descriptionList={ad.descriptions}
                          campaignName={ad.campaignName}
                          theme={ad.theme}
                          status={ad.status}
                          isClient={isClient}
                          onSaveHandler={onSaveHandler}
                          onApproveHandler={onApproveHandler}
                          onDisapproveHandler={onDispproveHandler}
                          onRemoveHandler={onRemoveHandler}
                        />
                      );
                    }
                  })
              ) : (
                <h1
                  style={{
                    fontFamily: "Inter",
                    textAlign: "center",
                    color: "rgba(51,51,51,0.8)",
                    fontWeight: "500",
                    fontSize: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 6,
                  }}
                >
                  <img src={warningIcon} alt="" width={20} height={20} />
                  No approved Ad Copies
                </h1>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={openDisapproved}
            style={{
              marginBottom: 16,
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{
                background: "rgba(232, 75, 71, 0.05)",
                height: "64px",
                cursor: "pointer",
              }}
              IconButtonProps={{
                onClick: () => {
                  setOpenDisapproved(prev => !prev);
                },
              }}
              expandIcon={<ExpandCircleDownOutlined />}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: 18,
                }}
              >
                Disapproved
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {sortedAdCopies.some((creative) => {
                return creative.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED;
              }) ? (
                sortedAdCopies
                  .filter((creative) => {
                    return (
                      creative.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED
                    );
                  })
                  .map((ad, index) => {
                    if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                      return (
                        <FacebookAdCopy
                          key={index}
                          expand={newAdUuid === ad.id ? true : false}
                          adCopy={true}
                          read={newAdUuid === ad.id ? false : true}
                          id={ad.id}
                          headlineList={ad.headlines}
                          descriptionList={ad.descriptions}
                          primaryText={ad.primaryText}
                          campaignName={ad.campaignName}
                          theme={ad.theme}
                          status={ad.status}
                          isClient={isClient}
                          onSaveHandler={onSaveHandler}
                          onApproveHandler={onApproveHandler}
                          onDisapproveHandler={onDispproveHandler}
                          onRemoveHandler={onRemoveHandler}
                        />
                      );
                    }
                    if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                      return (
                        <GoogleAdCopy
                          key={index}
                          expand={newAdUuid === ad.id ? true : false}
                          adCopy={true}
                          read={newAdUuid === ad.id ? false : true}
                          id={ad.id}
                          headlineList={ad.headlines}
                          descriptionList={ad.descriptions}
                          campaignName={ad.campaignName}
                          theme={ad.theme}
                          status={ad.status}
                          isClient={isClient}
                          onSaveHandler={onSaveHandler}
                          onApproveHandler={onApproveHandler}
                          onDisapproveHandler={onDispproveHandler}
                          onRemoveHandler={onRemoveHandler}
                        />
                      );
                    }
                  })
              ) : (
                <h1
                  style={{
                    fontFamily: "Inter",
                    textAlign: "center",
                    color: "rgba(51,51,51,0.8)",
                    fontWeight: "500",
                    fontSize: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 6,
                  }}
                >
                  <img src={warningIcon} alt="" width={20} height={20} />
                  No disapproved Ad Copies
                </h1>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        // <div>{adCopies.map((creative) => creative)}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 35,
            marginTop: "8rem",
          }}
        >
          <div
            style={{
              width: 200,
              height: 200,
              background: "#FFFFFF",
              border: "0.5px solid #E9E9E9",
              boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.04)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0 40px 0 40px",
            }}
          >
            <img src={warningIcon} alt="" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 20,
            }}
          >
            <Typography
              style={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 24,
                lineHeight: "27px",
              }}
            >
              No ad copies to show!
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "26px",
                color: "#6F6C90",
              }}
            >
              Please add some ad copies that can be approved by the client.
            </Typography>
          </div>
        </div>
      )}
      {addNewAdCopy && (
        <div
          style={{
            position: "fixed",
            width: 256,
            height: 84,
            background: "#FFFFFF",
            border: "0.5px solid #E5E5E5",
            boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
            borderRadius: 8,
            padding: "12px 0",
            bottom: 64,
            right: 28,
            zIndex: 999,
          }}
        >
          <div onClick={fbAddNewAdCopies}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "24px",
                paddingLeft: 16,
                marginBottom: 12,
                cursor: "pointer",
              }}
            >
              Add New Facebook Ad Copy
            </Typography>
          </div>
          <div onClick={googleAddNewAdCopies}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                lineHeight: "24px",
                paddingLeft: 16,
                cursor: "pointer",
              }}
            >
              Add New Google Ad Copy
            </Typography>
          </div>
        </div>
      )}
      <div className="preview_footer_row">
        <div className="footer_con" style={{ justifyContent: "end" }}>
          <Button
            style={{
              width: "172px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textTransform: "capitalize",
              background: "#0869FB",
            }}
            onClick={() => setAddNewAdCopy(!addNewAdCopy)}
            disabled={newAdUuid}
          >
            Add New Ad Copy{" "}
            <img
              src={arrowDownIcon}
              alt="arrow-down"
              style={{
                marginLeft: 6,
                marginTop: 3,
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdCopies;
