import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTabHDM } from "../../../api/HDMClient/componentTracking/action";
import { isPlatformSelectedHDM } from "../../../utils/accountManagerUtil";
import {
  clientDashboardIds,
  componentWithIndexHDMClient,
} from "../../../utils/HDMClientDashboardIdsUtil";

const HDMHeaderNavSteps = ({ headerNavList, active, visitedLastTime }) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.hdmClient);
  const [visitedLastTimeTemp, setVisitedLastTime] = useState(visitedLastTime);
  const disableSuggestChanges =
    reduxState &&
    reduxState?.strategyIterations &&
    reduxState?.strategyIterations?.length !== 0
      ? ["CHANGES_SUGGESTED", "APPROVED"].includes(
          reduxState.strategyIterations[
            reduxState.strategyIterations.length - 1
          ].state
        )
        ? true
        : false
      : false;
  useEffect(() => {
    let maxCompo = Math.max(visitedLastTimeTemp, visitedLastTime);
    if (reduxState.onboardingInfo.clientAccess.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS6]
      );
    }
    if (reduxState.onboardingInfo.brandMessaging.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING12]
      );
    }
    if (reduxState.onboardingInfo.facebookTargeting.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE7]
      );
    }
    if (reduxState.onboardingInfo.googleTargeting.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING9]
      );
    }
    if (reduxState.onboardingInfo.aboutBusiness.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS22]
      );
    }
    if (
      reduxState.onboardingInfo.creativeAndMontageAds.status === "COMPLETED"
    ) {
      maxCompo = Math.max(
        maxCompo,
        componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD13]
      );
    }
    if (reduxState.onboardingInfo.status === "COMPLETED") {
      maxCompo = Math.max(
        maxCompo,
        72
      );
    }

    if (
      reduxState?.strategyIterations &&
      reduxState.strategyIterations.length !== 0 &&
      (reduxState.strategyIterations[reduxState.strategyIterations.length - 1]
        .state === "GENERATED" ||
        reduxState.strategyIterations[reduxState.strategyIterations.length - 1]
          .state === "CHANGES_APPLIED")
    ) {
      maxCompo = Math.max(
        maxCompo,
        componentWithIndexHDMClient[
          clientDashboardIds.HDM_STRATEGY_DECK_SUGGESTCHANGES_PAGE
        ]
      );
    }
    if (
      reduxState?.strategyIterations &&
      reduxState.strategyIterations.length !== 0 &&
      reduxState.strategyIterations[reduxState.strategyIterations.length - 1]
        .state === "APPROVED"
    ) {
      maxCompo = Math.max(
        maxCompo,
        componentWithIndexHDMClient[
          clientDashboardIds.HDM_STRATEGY_DECK_BESTPRACTICE_PAGE
        ]
      );
    }
    setVisitedLastTime(maxCompo);
  }, [visitedLastTime, reduxState]);

  const platformToBeDisable = (id,name) => {
    if(id === 79 && disableSuggestChanges){
      return true;
    }
    if(name === "GOOGLE TARGETING" && isPlatformSelectedHDM(reduxState, "google-ads") === -1){
      return true;
    } 
    if(name === "FACEBOOK TARGETING" && isPlatformSelectedHDM(reduxState, "facebook-ads") === -1){
      return true;
    } 
    if(id === componentWithIndexHDMClient[clientDashboardIds.HDM_STRATEGY_DECK_FACEBOOK_PAGE]){
      if(isPlatformSelectedHDM(reduxState, "facebook-ads") === -1){
        return true
      }
    }
    if(id === componentWithIndexHDMClient[clientDashboardIds.HDM_STRATEGY_DECK_GOOGLE_PAGE]){
      if(isPlatformSelectedHDM(reduxState, "google-ads") === -1){
        return true
      }
    }
    return false;
  }
  return (
    <>
      {Object.values(headerNavList).map(({ name, isActive, id }, index) => {
        return (
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background:
                name === active
                  ? "#F1F7FF"
                  : visitedLastTimeTemp < id ||
                    (id === 79 && disableSuggestChanges) || platformToBeDisable(id,name)
                  ? "rgb(235 235 228 / 90%)"
                  : "",
              color: name === active ? "#0869FB" : "",
              width: `calc(100%/${Object.values(headerNavList).length})`,
              // borderRadius:0.5rem,
            }}
            disabled={(visitedLastTimeTemp >= id) ? (platformToBeDisable(id,name) ? true : false) : true}
            key={index}
            onClick={() => dispatch(updateTabHDM(id))}
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 16,
                color: name === active ? "#0869FB" : "#505050",
              }}
            >
              {name}
            </Typography>
          </Button>
        );
      })}
    </>
  );
};

export default HDMHeaderNavSteps;
