import { max } from 'moment';
import {
  clientObjKeys,
  GET_PERSONAL_INFO_CLIENT,
  GET_PLATFORM_INFO_CLIENT,
  GET_PREFERENCES_INTAKE_FORM,
  GET_AUDIENCE_INTAKE_FORM,
  GET_GROWTH_GOAL_CLIENT,
  UPDATE_WHOLE_CLIENT_FORMID,
  UPDATE_ARRAY_PLATFORMS_CHECKBOX,
  GET_PRODUCT_INFO_INTAKE_FORM,
  GET_AUDIENCE_BRAND_ASSETS_FORM,
  UPDATE_CITY_SUGGESTIONS,
  LOCATION_ADDED_TO_FORM,
} from './enum';

const INITIAL_STATE = {
  onboardingInfo: {
    status: 'PENDING',
    personalInfo: clientObjKeys.onboardingInfo.personalInfo,
    platformInfo: clientObjKeys.onboardingInfo.platformInfo,
    growthGoal: clientObjKeys.onboardingInfo.growthGoal,
    aboutBusiness: clientObjKeys.onboardingInfo.aboutBusiness,
    facebookTargeting: clientObjKeys.onboardingInfo.facebookTargeting,
  },
  intakeInfo: {
    status: 'PENDING',
    productServiceInfo: clientObjKeys.intakeInfo.productServiceInfo,
    audiences: clientObjKeys.intakeInfo.audiences,
    brandAssets: clientObjKeys.intakeInfo.brandAssets,
    customerTestimonial: clientObjKeys.intakeInfo.customerTestimonial,
    preferences: clientObjKeys.intakeInfo.preferences,
  },
  companyInfo: clientObjKeys.companyInfo,
  sowPricingInfo: {
    status: 'PENDING',
    googleAdsInfo: clientObjKeys.sowPricingInfo.googleAdsInfo,
    facebookAdsInfo: clientObjKeys.sowPricingInfo.facebookAdsInfo,
    croInfo: clientObjKeys.sowPricingInfo.croInfo,
    clientPricing: clientObjKeys.sowPricingInfo.clientPricing,
  },
  proposalIterations: clientObjKeys.proposalIterations,
  googleSearchAdsStrategyInfo: {
    status: 'PENDING',
    brandCampaign: clientObjKeys.googleSearchAdsStrategyInfo.brandCampaign,
    competitorCampaign:
      clientObjKeys.googleSearchAdsStrategyInfo.competitorCampaign,
    serviceCampaign: clientObjKeys.googleSearchAdsStrategyInfo.serviceCampaign,
  },
  fbAdsStrategyInfo: clientObjKeys.fbAdsStrategyInfo,
  gAdsCustomerId: clientObjKeys.gAdsCustomerId,
  strategyIterations: clientObjKeys.strategyIterations,
  published: false,
  clientGoogleAdsInfo: clientObjKeys.clientGoogleAdsInfo,
  locationOptions: [],
  geoTargets: [],
  generatedCampaignsInfo: null,
  accessSharing: clientObjKeys.accessSharing,
  auditInfo: clientObjKeys.auditInfo,
  googleSearchAdsGeneratedCampaignsInfo: null,
  fbAdsGeneratedCampaignsInfo: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { infoType, key, value, isOther, minKey, maxKey } = action.payload
    ? action.payload
    : { infoType: null, key: null, value: null, isOther: null };
  switch (action.type) {
    case GET_PERSONAL_INFO_CLIENT:
      const updatePersonalInfo = JSON.parse(JSON.stringify({ ...state }));
      updatePersonalInfo['onboardingInfo'][infoType][key] = value;
      return updatePersonalInfo;
    case GET_PLATFORM_INFO_CLIENT:
      const updatePlatformInfo = JSON.parse(JSON.stringify({ ...state }));
      const keys = key.split(' ');
      if (
        Array.isArray(
          updatePlatformInfo['onboardingInfo'][infoType][keys[0]]
        ) &&
        keys.length === 3
      ) {
        updatePlatformInfo['onboardingInfo'][infoType][keys[0]][
          parseInt(keys[1])
        ][keys[2]] = value;
      } else {
        updatePlatformInfo['onboardingInfo'][infoType][key] = value;
      }
      return updatePlatformInfo;
    case GET_GROWTH_GOAL_CLIENT:
      const updateGrowthGoal = JSON.parse(JSON.stringify({ ...state }));
      updateGrowthGoal['onboardingInfo'][infoType][key] = value;
      return updateGrowthGoal;
    case UPDATE_WHOLE_CLIENT_FORMID:
      return action.payload;
    case 'UPDATE_ACCOUNTS_SELECTED':
      const companyState = JSON.parse(JSON.stringify({ ...state }));
      companyState[infoType][key] = value;
      return companyState;
    case 'UPDATE_MULTIPLE_CHECKBOX':
      const crmState = JSON.parse(JSON.stringify({ ...state }));
      if (typeof value === 'object' && value !== null) {
        let str = '';
        Object.keys(value).forEach((key) => {
          if (value[key]['isActive']) {
            str = str + key + '_';
          }
        });
        crmState['onboardingInfo'][infoType][key] = str;
      } else if (typeof value === 'string') {
        let updatedArray = crmState['onboardingInfo'][infoType][key];
        const quest = action.payload.quest;
        const mappedObj = {};
        updatedArray.forEach((key, index) => {
          mappedObj[key] = {
            flag: false,
            ['index']: index,
          };
        });
        let otherValIndex = null;
        Object.entries(mappedObj).map(([key, value], index) => {
          if (!quest.includes(key)) {
            otherValIndex = value.index;
          }
        });
        if (isOther && otherValIndex !== null) {
          updatedArray[otherValIndex] = value;
        } else if (updatedArray.includes('Other') && value === 'Other') {
          const otherIndex = updatedArray.findIndex((ele) => ele === 'Other');
          updatedArray.splice(otherIndex, 1);
          otherValIndex !== null && updatedArray.splice(otherValIndex - 1, 1);
        } else {
          const index = updatedArray.findIndex((ele) => ele === value);
          index === -1
            ? updatedArray.push(value)
            : updatedArray.splice(index, 1);
          value === 'Other' && updatedArray.push(' ');
        }
        crmState['onboardingInfo'][infoType][key] = updatedArray;
      }
      return crmState;

    case 'ADD_NEW_FIELD_IN_PLATFORM_COMPETITORS':
      const updatedPlatformInfoCompetitorArray = JSON.parse(
        JSON.stringify({ ...state })
      );
      updatedPlatformInfoCompetitorArray['onboardingInfo']['platformInfo'][
        'competitors'
      ] = [
        ...updatedPlatformInfoCompetitorArray['onboardingInfo']['platformInfo'][
          'competitors'
        ],
        { name: '', website: '' },
      ];
      updatedPlatformInfoCompetitorArray['intakeInfo']['preferences'][
        'competitors'
      ] = [
        ...updatedPlatformInfoCompetitorArray['intakeInfo']['preferences'][
          'competitors'
        ],
        { name: '', website: '' },
      ];
      return updatedPlatformInfoCompetitorArray;

    case 'ADD_NEW_FIELD_IN_INTAKE_COMPETITORS':
      const currentStateObj = JSON.parse(JSON.stringify({ ...state }));
      currentStateObj['intakeInfo']['preferences']['competitors'] = [
        ...currentStateObj['intakeInfo']['preferences']['competitors'],
        { name: '', website: '' },
      ];
      return currentStateObj;
    case 'UPDATE_INTAKE_COMPETITORS':
      const currentStateObj1 = JSON.parse(JSON.stringify({ ...state }));
      currentStateObj1['intakeInfo']['preferences']['competitors'] = value;
      return currentStateObj1;
    case UPDATE_ARRAY_PLATFORMS_CHECKBOX:
      let updatePlatformInfoPlatforms = JSON.parse(
        JSON.stringify({ ...state })
      );
      let platformsArray =
        updatePlatformInfoPlatforms['onboardingInfo']['platformInfo'][
          'platforms'
        ];
      const tempArr = [];
      const index = platformsArray.findIndex(
        (platform) => platform.platform === action.payload.platform
      );
      index === -1
        ? tempArr.push({
            platform: action.payload.platform,
            spends: 0,
            cpa: 0,
            currency: '',
          })
        : platformsArray[index]['platform'] === ''
        ? tempArr.push({
            platform: action.payload.platform,
            spends: 0,
            cpa: 0,
            currency: '',
          })
        : platformsArray.splice(index, 1);
      updatePlatformInfoPlatforms['onboardingInfo']['platformInfo'][
        'platforms'
      ] = [...platformsArray, ...tempArr];
      return updatePlatformInfoPlatforms;
    case 'SET_SPENDS_TARGET_CPA':
      const updateSpendsCpa = JSON.parse(JSON.stringify({ ...state }));
      const findIndexFromPlatform = updateSpendsCpa['onboardingInfo'][
        'platformInfo'
      ]['platforms'].findIndex(
        (platform) => platform.platform === action.payload.type
      );
      updateSpendsCpa['onboardingInfo']['platformInfo']['platforms'][
        findIndexFromPlatform
      ][action.payload.key] = parseInt(
        action.payload.value.length === 0 ? 0 : action.payload.value
      );
      return updateSpendsCpa;
    case 'DELETE_COMPETITTOR_FIELD':
      const updatedPlatformInfoCompetitorArray1 = JSON.parse(
        JSON.stringify({ ...state })
      );
      const filterdArray = updatedPlatformInfoCompetitorArray1[
        'onboardingInfo'
      ]['platformInfo']['competitors'].filter((_, i) => action.payload !== i);
      updatedPlatformInfoCompetitorArray1['onboardingInfo']['platformInfo'][
        'competitors'
      ] = filterdArray;
      updatedPlatformInfoCompetitorArray1['intakeInfo']['preferences'][
        'competitors'
      ] = filterdArray;
      return updatedPlatformInfoCompetitorArray1;
    case 'DELETE_INTAKE_COMPETITOR_FIELD':
      const currentState = JSON.parse(JSON.stringify({ ...state }));
      const filterdCompetitors = currentState['intakeInfo']['preferences'][
        'competitors'
      ].filter((_, i) => action.payload !== i);
      currentState['intakeInfo']['preferences']['competitors'] =
        filterdCompetitors;
      return currentState;
    case 'SUGGEST_CHANGES_INPUT':
      const tempState = JSON.parse(JSON.stringify({ ...state }));
      return tempState;
    case 'READ_ACCESS_GOOGLE_INPUT':
      const tempState2 = JSON.parse(JSON.stringify({ ...state }));
      tempState['clientGAdsCustomerId'] = action.payload.value;
      return tempState2;
    case 'UPDATE_VALUE_CLIENT_WITHOUT_NESTED_OBJECT':
      const tempState4 = JSON.parse(JSON.stringify({ ...state }));
      tempState4[key] = value;
      return tempState4;
    case UPDATE_CITY_SUGGESTIONS:
      const tempState5 = JSON.parse(JSON.stringify({ ...state }));
      let options = action.payload.geoTargets.map(function (element) {
        return {
          name: element.name,
          title: element.canonicalName,
          resourceName: element.resourceName,
          reach: element.reach,
        };
      });
      if (options) {
        tempState5['intakeInfo']['audiences']['locationOptions'] = options;
      } else {
        tempState5['intakeInfo']['audiences']['locationOptions'] = [];
      }
      return tempState5;
    case LOCATION_ADDED_TO_FORM:
      const tempState6 = JSON.parse(JSON.stringify({ ...state }));
      let selected_values = action.payload;
      tempState6['intakeInfo']['audiences']['geoTargets'] = selected_values;
      return tempState6;
    case GET_PRODUCT_INFO_INTAKE_FORM:
      const tempState7 = JSON.parse(JSON.stringify({ ...state }));
      const keys2 = key.split(' ');
      if (
        Array.isArray(tempState7['intakeInfo'][infoType][keys2[0]]) &&
        keys2.length === 2
      ) {
        tempState7['intakeInfo'][infoType][keys2[0]][parseInt(keys2[1])] =
          value;
      } else if (
        Array.isArray(tempState7['intakeInfo'][infoType][keys2[0]]) &&
        keys2.length === 3
      ) {
        tempState7['intakeInfo'][infoType][keys2[0]][parseInt(keys2[1])][
          keys2[2]
        ] = value;
      } else {
        tempState7['intakeInfo'][infoType][key] = value;
      }
      return tempState7;
    case 'GET_RADIO_BUTTON_VALUE':
      const tempState8 = JSON.parse(JSON.stringify({ ...state }));
      tempState8[action.payload.outerKey][infoType][key] = value;
      return tempState8;
    case 'ADD_NEW_FIELD_SERVICE_INFORM_INTAKE_FORM':
      const updatedPlatformInfoCompetitorArray2 = JSON.parse(
        JSON.stringify({ ...state })
      );
      updatedPlatformInfoCompetitorArray2['intakeInfo']['productServiceInfo'][
        'descriptions'
      ] = [
        ...updatedPlatformInfoCompetitorArray2['intakeInfo'][
          'productServiceInfo'
        ]['descriptions'],
        { name: '', description: '' },
      ];
      return updatedPlatformInfoCompetitorArray2;
    case 'DELETE_SERVICE_INFORM_INTAKE_FORM_FIELD':
      const updatedPlatformInfoCompetitorArray3 = JSON.parse(
        JSON.stringify({ ...state })
      );
      const filterdArray1 = updatedPlatformInfoCompetitorArray3['intakeInfo'][
        'productServiceInfo'
      ]['descriptions'].filter((_, i) => action.payload !== i);
      updatedPlatformInfoCompetitorArray3['intakeInfo']['productServiceInfo'][
        'descriptions'
      ] = filterdArray1;
      return updatedPlatformInfoCompetitorArray3;
    case 'ADD_KEYWORD_IN_BRAND_AND_SERVICE_INTAKE_FORM':
      const updatedPlatformInfoCompetitorArray4 = JSON.parse(
        JSON.stringify({ ...state })
      );
      updatedPlatformInfoCompetitorArray4['intakeInfo']['productServiceInfo'][
        'brandServiceKeywords'
      ] = [
        ...updatedPlatformInfoCompetitorArray4['intakeInfo'][
          'productServiceInfo'
        ]['brandServiceKeywords'],
        '',
      ];
      return updatedPlatformInfoCompetitorArray4;
    case 'DELETE_ADD_KEYWORD_IN_BRAND_AND_SERVICE_INTAKE_FORM_FIELD':
      const updatedPlatformInfoCompetitorArray5 = JSON.parse(
        JSON.stringify({ ...state })
      );
      const filterdArray2 = updatedPlatformInfoCompetitorArray5['intakeInfo'][
        'productServiceInfo'
      ]['brandServiceKeywords'].filter((_, i) => action.payload !== i);
      updatedPlatformInfoCompetitorArray5['intakeInfo']['productServiceInfo'][
        'brandServiceKeywords'
      ] = filterdArray2;
      return updatedPlatformInfoCompetitorArray5;
    case GET_PREFERENCES_INTAKE_FORM:
      const tempState9 = JSON.parse(JSON.stringify({ ...state }));
      const preferencesKeys = key.split(' ');
      if (
        Array.isArray(tempState9['intakeInfo'][infoType][preferencesKeys[0]]) &&
        preferencesKeys.length === 3
      ) {
        tempState9['intakeInfo'][infoType][preferencesKeys[0]][
          parseInt(preferencesKeys[1])
        ][preferencesKeys[2]] = value;
      } else {
        tempState9['intakeInfo'][infoType][key] = value;
      }

      return tempState9;
    case GET_AUDIENCE_INTAKE_FORM:
      const tempState10 = JSON.parse(JSON.stringify({ ...state }));
      tempState10['intakeInfo'][infoType][key] = value;
      return tempState10;
    case 'GET_SINGLE_FILE_VALUE':
      const tempState11 = JSON.parse(JSON.stringify({ ...state }));
      tempState11[action.payload.outerKey][infoType][key] = Array.isArray(
        tempState11[action.payload.outerKey][infoType][key]
      )
        ? [value]
        : value;
      return tempState11;
    case 'GET_MULTIPLE_FILE_VALUE':
      let tempState14 = JSON.parse(JSON.stringify({ ...state }));
      console.log(action.payload, tempState14[action.payload.outerKey]);
      if (value.length !== 0) {
        const array = [...tempState14[action.payload.outerKey][infoType][key]];
        array.push(value);
        tempState14[action.payload.outerKey][infoType][key] = array;
      }
      return tempState14;
    case 'DELETE_MULTIPLE_FILE':
      const tempState15 = JSON.parse(JSON.stringify({ ...state }));
      tempState15[action.payload.outerKey][infoType][key] = tempState15[
        action.payload.outerKey
      ][infoType][key].filter((_, index) => index !== value);
      return tempState15;
    case GET_AUDIENCE_BRAND_ASSETS_FORM:
      const tempState12 = JSON.parse(JSON.stringify({ ...state }));
      tempState12['intakeInfo'][infoType][key] = value;
      return tempState12;
    case 'GET_VALUE_MULTIPLE_CHECKBOX_DROPDOWN':
      const tempState13 = JSON.parse(JSON.stringify({ ...state }));
      const arrTemp14 = tempState13[action.payload.outerKey][infoType][key];
      const existKeyIndex = arrTemp14.findIndex((val1) => val1 === value);
      if (existKeyIndex !== -1) {
        arrTemp14.splice(existKeyIndex, 1);
      } else {
        arrTemp14.push(value);
      }
      tempState13[action.payload.outerKey][infoType][key] = arrTemp14;
      return tempState13;
    //added the action creator for checking logic - ravi roshan
    case 'GET_VALUE_FROM_AGE_RANGE_MIN_MAX':
      const tempStateAgeRange = JSON.parse(JSON.stringify({ ...state }));
      const minAge = action.payload.minAge ? action.payload.minAge : '';
      const maxAge = action.payload.maxAge ? action.payload.maxAge : '';
      tempStateAgeRange[action.payload.outerKey][infoType][minKey] = minAge;
      tempStateAgeRange[action.payload.outerKey][infoType][maxKey] =
        minAge && maxAge && maxAge < minAge
          ? minAge === '65+'
            ? '65+'
            : ''
          : maxAge;
      return tempStateAgeRange;
    case 'UPDATE_ACCESS_SHARING_LD_CLIENT':
      const updatePersonalInfo5 = JSON.parse(JSON.stringify({ ...state }));
      updatePersonalInfo5[action.payload.infoType][action.payload.key][
        action.payload.key2
      ][action.payload.key3] = action.payload.value;
      updatePersonalInfo5[action.payload.infoType][action.payload.key][
        action.payload.key2
      ]['status'] = action.payload?.isComplete
        ? 'VERIFYING_COMPLETE_ACCESS'
        : 'VERIFYING';
      return updatePersonalInfo5;
    case 'UPDATE_PLATFORM_BRAND_DESCRIPTION':
      const updatedReduxState = JSON.parse(JSON.stringify({ ...state }));
      updatedReduxState['onboardingInfo']['platformInfo']['brandDescription'] =
        value;
      updatedReduxState['intakeInfo']['productServiceInfo'][
        'brandDescription'
      ] = value;
      return updatedReduxState;
    case 'UPDATE_PLATFORM_COMPETITORS':
      const reducerState = JSON.parse(JSON.stringify({ ...state }));
      const competitorsKeys = key.split(' ');
      if (
        Array.isArray(
          reducerState['onboardingInfo']['platformInfo'][competitorsKeys[0]]
        ) &&
        competitorsKeys.length === 3
      ) {
        reducerState['intakeInfo']['preferences'][competitorsKeys[0]][
          parseInt(competitorsKeys[1])
        ][competitorsKeys[2]] = value;
        reducerState['onboardingInfo']['platformInfo'][competitorsKeys[0]][
          parseInt(competitorsKeys[1])
        ][competitorsKeys[2]] = value;
      } else {
        reducerState['intakeInfo']['preferences'][key] = value;
        reducerState['onboardingInfo']['platformInfo'][key] = value;
      }

      return reducerState;
    case 'RESET':
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
