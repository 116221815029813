const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const bidding_icon  = `${imageBaseUrl}/assets/724422d3-3db8-4192-a025-0e17c3b12e7a`
export const budget_icon  = `${imageBaseUrl}/assets/c591d37f-14c5-4d49-9bd2-364057256dd9`
export const campaign_link_icon  = `${imageBaseUrl}/assets/d99886c3-95b1-4c92-980e-b9a9846d8925`
export const campaign_publish_error_icon  = `${imageBaseUrl}/assets/233863de-9331-4ec7-8f53-a0e8957d076f`
export const campaign_publish_modal_icon  = `${imageBaseUrl}/assets/591a8d23-ce4d-47ae-8eb3-7bbb33383e1d`
export const campaign_type_icon  = `${imageBaseUrl}/assets/660c73ff-c27c-4a60-9272-68635bf52da8`
export const conversion_goals_icon  = `${imageBaseUrl}/assets/435c10bb-0434-4f5e-83cb-28aee4f6b03a`
export const language_icon  = `${imageBaseUrl}/assets/13cfcacf-c128-43ae-8e3b-3f177e60b435`
export const location_icon  = `${imageBaseUrl}/assets/dc493c94-9f5d-41d2-9556-c8315a56403e`
export const networks_icon  = `${imageBaseUrl}/assets/36db36c7-442a-4284-b5b6-c561bdd60a25`
export const objective_icon  = `${imageBaseUrl}/assets/060df26f-6467-448b-a65a-12dba939ce97`
export const publish_campaign_loader  = `${imageBaseUrl}/assets/8ef4a3ca-1841-48fa-95a4-712df40f136d`
export const reach_goals_icon  = `${imageBaseUrl}/assets/ea0adb42-fac2-42cb-aadc-0d26af245dc4`
export const text_field_icon  = `${imageBaseUrl}/assets/46715e23-659a-4306-af38-d72cd2253836`
export const tracking_template_icon  = `${imageBaseUrl}/assets/2c18fbde-07c4-40b6-a391-01a5d943a497`
