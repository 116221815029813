import PrimaryButton from '../ButtonPrimary/Buttons';
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import {ready as readyGif} from '../../assets/icons/proposal/proposal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 580,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  height: '373px',
  borderRadius: '15px',
};

const FacebookCampaignStrategyCreationModal = ({
  modal,
  setModal,
  generateDeck,
}) => {
  return (
    <Modal
      open={modal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <div className='status_popup_layout'>
          <span
            className='setIcon'
            style={{
              width: '144px',
              height: '144px',
              borderRadius: 0,
              border: 0,
              marginBottom: '12px',
            }}
          >
            <img src={readyGif} />
          </span>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            style={{ transform: 'translateY(5px)' }}
          >
            Strategy Deck Published!
            <p>The strategy deck has been successfully generated. Please click on Proceed to view it. </p>
          </Typography>
          <div
            // className='action_popup'
            style={{
              transform: 'translateY(10px)',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '1.5rem',
              marginTop: '42px',
            }}
          >
            {/* <PrimaryButton
              title='Go Back'
              type='bordered'
              onClick={() => setModal(!modal)}
            /> */}
            <PrimaryButton
              title='PROCEED'
              onClick={() => generateDeck()}
            />
          </div>
          <a className='close_button' onClick={() => setModal(!modal)}>
            <span></span>
          </a>
        </div>
      </Box>
    </Modal>
  );
};

export default FacebookCampaignStrategyCreationModal;
