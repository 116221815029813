import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";

import { getFormByFormIdReducer } from "../../api/accountManager/forms/slice";
import { updateSideBar, goBackAmCompo } from "../../api/accountManager/componentTracking/action";
import PrimaryButton from "../ButtonPrimary/Buttons";

import { leftSidebarAMWithCurrentCompIds } from "../../utils/navbarUtil";
import { getChangesSuggestedObj } from "../../utils/accountManagerUtil";

const SuggestChanges = (props) => {
  const dispatch = useDispatch();
  const [suggestChanges, setSuggestChanges] = useState({});
  const form = useSelector(
    (store) => store.form.form
  );

  useEffect(() => {
    dispatch(getFormByFormIdReducer());
  }, []);
  useEffect(() => {
    if (form) {
      if(props.type === "STRATEGY"){
        setSuggestChanges(getChangesSuggestedObj(form.strategyIterations));
      }else{
        setSuggestChanges(getChangesSuggestedObj(form.proposalIterations));
      }
    }
  }, [form]);

  return (
    <div
      style={{
        marginTop: "2rem",
        marginLeft: "2rem",
      }}
    >
      <Typography
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 20,
          color: "#242731",
          marginBottom: "2rem",
        }}
      >
        Suggested Changes
      </Typography>

      <div
        style={{
          // display: "flex",
          alignItems: "center",
          gap: "3rem",
          marginBottom: "2rem",
        }}
      >
        {Object.keys(suggestChanges).map((change, idx) => {

          console.log(suggestChanges, idx, change);
          return (
            <div key={idx}>
              <Typography
              >
                {change}
              </Typography>
              <Typography
                style={{
                  background: "#FAFAFA",
                  borderRadius: 8,
                  padding: "0.5rem",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  width: 800,
                }}
              >
                {suggestChanges[change]}
              </Typography>
            </div>
          )
        })}
      </div>
      <div className="preview_footer">
        <button className="back" onClick={() => {
          dispatch(goBackAmCompo());
        }}>BACK</button>
        <PrimaryButton onClick={() => {
          if(props.type === "STRATEGY"){
            dispatch(updateSideBar(leftSidebarAMWithCurrentCompIds["STRATEGY_INPUT"]))
          }else{
            dispatch(updateSideBar(leftSidebarAMWithCurrentCompIds["ACCOUNT_SCOPE_OF_WORK_AND_PRICING"]))
          }
        }} title={`Go Back to ${props.type === "STRATEGY" ? "Strategy Inputs" : "Pricing"}`} />
      </div>
    </div>
  );
};

export default SuggestChanges;
