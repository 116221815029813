import InputTextField from '../../ClientDashboard/InputTextField/TextField';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@mui/material';
import BpRadio from '../../ClientDashboard/CustomRadio/Radio';
const productInfoQuestion = [
  {
    question: 'What is your target CPA?',
    description: '',
    answerInputComponent: <InputTextField width='600px' />,
  },
  {
    question: 'What is your profit margin?',
    description: '',
    answerInputComponent: <InputTextField width='600px' />,
  },
  {
    question: 'What is your average sales closing percentage?*',
    description: '',
    answerInputComponent: (
      <>
        <FormControl>
          <RadioGroup
            defaultValue=''
            aria-labelledby='demo-customized-radios'
            name='customized-radios'
            style={{
              marginLeft: '0.8rem',
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <FormControlLabel
              value='10-25%'
              control={<BpRadio />}
              label='10-25%'
              style={{
                width: 180,
                height: 42,
                background: '#FFFFFF',
                border: '1px solid #DBDBDB',
                boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                borderRadius: 10,
                paddingLeft: '1rem',
                // marginBottom: '1rem',
                color: '#7F7D87',
              }}
            />
            <FormControlLabel
              value='10-30%'
              control={<BpRadio />}
              label='10-25%'
              style={{
                width: 180,
                height: 42,
                background: '#FFFFFF',
                border: '1px solid #DBDBDB',
                boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                borderRadius: 10,
                paddingLeft: '1rem',
                color: '#7F7D87',
              }}
            />
            <RadioGroup
              defaultValue=''
              aria-labelledby='demo-customized-radios'
              name='customized-radios'
              style={{
                flexDirection: 'row',
                gap: '1rem',
              }}
            >
              <FormControlLabel
                value='10-35%'
                control={<BpRadio />}
                label='10-25%'
                style={{
                  width: 180,
                  height: 42,
                  background: '#FFFFFF',
                  border: '1px solid #DBDBDB',
                  boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                  borderRadius: 10,
                  paddingLeft: '1rem',
                  marginBottom: '1rem',
                  color: '#7F7D87',
                }}
              />
              <FormControlLabel
                value='10-40%'
                control={<BpRadio />}
                label='10-25%'
                style={{
                  width: 180,
                  height: 42,
                  background: '#FFFFFF',
                  border: '1px solid #DBDBDB',
                  boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                  borderRadius: 10,
                  paddingLeft: '1rem',
                  color: '#7F7D87',
                }}
              />
            </RadioGroup>
          </RadioGroup>
        </FormControl>
      </>
    ),
  },
  {
    question: 'Is there any seasonality to your products/services?*',
    description: '',
    answerInputComponent: (
      <FormControl>
        <RadioGroup
          defaultValue=''
          aria-labelledby='demo-customized-radios'
          name='customized-radios'
          style={{
            marginLeft: '0.8rem',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          <FormControlLabel
            value='Yes'
            control={<BpRadio />}
            label='Yes'
            style={{
              width: 100,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              // marginBottom: '1rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='No'
            control={<BpRadio />}
            label='No'
            style={{
              width: 100,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='Maybe'
            control={<BpRadio />}
            label='Maybe'
            style={{
              width: 100,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              color: '#7F7D87',
            }}
          />
        </RadioGroup>
      </FormControl>
    ),
  },
  {
    question: 'Does your company have an app related to these services?*',
    description: '',
    answerInputComponent: (
      <FormControl>
        <RadioGroup
          defaultValue=''
          aria-labelledby='demo-customized-radios'
          name='customized-radios'
          style={{
            marginLeft: '0.8rem',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          <FormControlLabel
            value='Yes'
            control={<BpRadio />}
            label='Yes'
            style={{
              width: 100,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              // marginBottom: '1rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='No'
            control={<BpRadio />}
            label='No'
            style={{
              width: 100,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              color: '#7F7D87',
            }}
          />
        </RadioGroup>
      </FormControl>
    ),
  },
  {
    question: 'Product/ Service Information',
    description:
      'Please provide information about the products you want to advertise. ',
    answerInputComponent: (
      <>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <InputTextField width='300px' placeholder='Product' />
          <InputTextField width='300px' placeholder='Description' />
        </div>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <InputTextField width='300px' placeholder='Product' />
          <InputTextField width='300px' placeholder='Description' />
        </div>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <InputTextField width='300px' placeholder='Product' />
          <InputTextField width='300px' placeholder='Description' />
        </div>
        <div
          style={{
            background: '#E3EEFF',
            borderRadius: 6,
            padding: '3px 9px',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 11,
              color: '#0869FB',
            }}
          >
            + ADD FIELD
          </Typography>
        </div>
      </>
    ),
  },
  {
    question: 'Describe your brand',
    description: 'Help us understand your brand better',
    answerInputComponent: <InputTextField width='600px' multiline={true} />,
  },
  {
    question: 'On which email address should the leads be sent?',
    description: '',
    answerInputComponent: <InputTextField width='600px' />,
  },
];
const preferencesQuestions = [
  {
    question: 'Enter your top competitors and their websites',
    description: 'You can enter many inputs',
    answerInputComponent: (
      <>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <InputTextField width='300px' placeholder='Product' />
          <InputTextField width='300px' placeholder='Description' />
        </div>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <InputTextField width='300px' placeholder='Product' />
          <InputTextField width='300px' placeholder='Description' />
        </div>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <InputTextField width='300px' placeholder='Product' />
          <InputTextField width='300px' placeholder='Description' />
        </div>
        <div
          style={{
            background: '#E3EEFF',
            borderRadius: 6,
            padding: '3px 9px',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 11,
              color: '#0869FB',
            }}
          >
            + ADD FIELD
          </Typography>
        </div>
      </>
    ),
  },
  {
    question:
      'What is your total monthly ad spend budget (across all platforms)?*',
    description: '',
    answerInputComponent: <InputTextField width='600px' />,
  },
  {
    question: 'What are your lead preferences?*',
    description: '',
    answerInputComponent: (
      <>
        <FormControl>
          <RadioGroup
            defaultValue=''
            aria-labelledby='demo-customized-radios'
            name='customized-radios'
            style={{
              marginLeft: '0.8rem',
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <FormControlLabel
              value='Calls'
              control={<BpRadio />}
              label='Calls'
              style={{
                width: 180,
                height: 42,
                background: '#FFFFFF',
                border: '1px solid #DBDBDB',
                boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                borderRadius: 10,
                paddingLeft: '1rem',
                // marginBottom: '1rem',
                color: '#7F7D87',
              }}
            />
            <FormControlLabel
              value='Emails'
              control={<BpRadio />}
              label='Emails'
              style={{
                width: 180,
                height: 42,
                background: '#FFFFFF',
                border: '1px solid #DBDBDB',
                boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                borderRadius: 10,
                paddingLeft: '1rem',
                color: '#7F7D87',
              }}
            />
            <FormControlLabel
              value='Walk-Ins'
              control={<BpRadio />}
              label='Walk-Ins'
              style={{
                width: 180,
                height: 42,
                background: '#FFFFFF',
                border: '1px solid #DBDBDB',
                boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                borderRadius: 10,
                paddingLeft: '1rem',
                marginBottom: '1rem',
                color: '#7F7D87',
              }}
            />
            <FormControlLabel
              value='Purchases'
              control={<BpRadio />}
              label='Purchases'
              style={{
                width: 180,
                height: 42,
                background: '#FFFFFF',
                border: '1px solid #DBDBDB',
                boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                borderRadius: 10,
                paddingLeft: '1rem',
                color: '#7F7D87',
              }}
            />
          </RadioGroup>
        </FormControl>
      </>
    ),
  },
  {
    question:
      'Would you prefer your tracking phone number to show as your local area code or a toll-free number?*',
    description: '',
    answerInputComponent: (
      <FormControl>
        <RadioGroup
          defaultValue=''
          aria-labelledby='demo-customized-radios'
          name='customized-radios'
          style={{
            marginLeft: '0.8rem',
            gap: '1rem',
          }}
        >
          <FormControlLabel
            value='Local Area Code'
            control={<BpRadio />}
            label='Local Area Code'
            style={{
              width: 400,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              // marginBottom: '1rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='Not Applicable (not using call tracking)'
            control={<BpRadio />}
            label='Not Applicable (not using call tracking)'
            style={{
              width: 400,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='Toll-Free Number'
            control={<BpRadio />}
            label='Toll-Free Number'
            style={{
              width: 400,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              color: '#7F7D87',
            }}
          />
        </RadioGroup>
      </FormControl>
    ),
  },
  {
    question:
      'On rare occasions, your monthly budget may be depleted a few days before the end of the month. If this ever happens with your account how should we proceed?*',
    description: '',
    answerInputComponent: (
      <FormControl>
        <RadioGroup
          defaultValue=''
          aria-labelledby='demo-customized-radios'
          name='customized-radios'
          style={{
            marginLeft: '0.8rem',
            gap: '1rem',
          }}
        >
          <FormControlLabel
            value='Continue running ads and notify you via email (recommended)'
            control={<BpRadio />}
            label='Continue running ads and notify you via email (recommended)'
            style={{
              width: 600,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              // marginBottom: '1rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='Pause ads and notify you via email'
            control={<BpRadio />}
            label='Pause ads and notify you via email'
            style={{
              width: 600,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value="Don't ever pause ads or notify me about this"
            control={<BpRadio />}
            label="Don't ever pause ads or notify me about this"
            style={{
              width: 600,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '0.5rem',
              color: '#7F7D87',
            }}
          />
        </RadioGroup>
      </FormControl>
    ),
  },
];
export const questionForm = {
  'PRODUCT INFO': productInfoQuestion,
  PREFERENCES: preferencesQuestions,
  AUDIENCE: [],
  BRANDING: [],
  'SOCIAL PROOF': [],
};