import { useDispatch, useSelector } from 'react-redux';
import CommonFacebookAccounts from './CommonFacebookAccounts';
import { useCallback } from 'react';
import { getAuditFacebook, hitTargetingKeywordsApi } from '../../../api/HDMAM/audit/saga';
import { saveHdmAmFormsData } from '../../../api/HDMAM/form/action';
import { updateCompoReduxHDMAM } from '../../../api/HDMAM/componentTracking/action';

export default function HDMFacebookAccounts () {
  const dispatch = useDispatch();
  
  const fbAccountId = useSelector((state) => state?.hdmFormAm?.fbAdsAccountId);
  
  // return a promise
  const onGenerateReport = useCallback((options) => {
    return getAuditFacebook(options, "HDM")
      .then(async (res) => {
        const apiHit = await hitTargetingKeywordsApi();
        dispatch({ type: "GET_AUDIT_DETAILS_HDM", payload: res });
        dispatch({
          type: "UPDATE_FACEBOOK_ACCOUNT_ID_HDM",
          payload: {
            accountId: options.accountId,
            accountName: options.selectedCustomerName,
          },
        });
        dispatch(saveHdmAmFormsData());
          dispatch({
            type: "GENERATE_FACEBOOK_ADS_CAI",
          });
        dispatch(updateCompoReduxHDMAM());
      })
  }, [dispatch]);

  return (
    <CommonFacebookAccounts
      fbAccountId={fbAccountId}
      onGenerateReport={onGenerateReport}
    />
  )
}