import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import Radio from '@mui/material/Radio';
import './targeting-look-alike.css';
import BpRadio from '../../ClientDashboard/CustomRadio/Radio';
import LookalikeLocationField from './LookalikeLocationField';

const filterDropdown = (group, type, audiences) => {
  const arrTemp = [];
  audiences &&
    audiences.forEach((value, index) => {
      if (value.subtype === type) {
        arrTemp.push({
          group: group,
          name: value.name,
          reach: value.maxReach,
          id: value.id,
        });
      }
    });
  return arrTemp;
};

const LookAlikeTargetting = (props) => {
  const audiences = useSelector(
    (state) => state.strategyCreationHdmAm.audiences
  );

  const audienceDropdown = {
    WEBSITE: filterDropdown('WEBSITE', 'WEBSITE', audiences),
    // CUSTOM: filterDropdown('CUSTOM', 'CUSTOM', audiences),
    // 'FB EVENT': filterDropdown('FB EVENT', 'FB_EVENT', audiences),
  };

  const DropDownRender = () => {
    var ListArray = [];
    for (const key in audienceDropdown) {
      if (Object.hasOwnProperty.call(audienceDropdown, key)) {
        const element = audienceDropdown[key];
        ListArray.push(
          <ListSubheader key={key} className='drop_custom_list_head'>
            {key}
          </ListSubheader>
        );
        {
          element.forEach((ele, index) => {
            ListArray.push(
              <MenuItem
                key={index}
                value={ele.name}
                className='drop_custom_list_row'
              >
                <div className='drop_custom_list'>
                  <div>
                    <Radio checked={props.data.audience.name === ele.name} />
                    <p style={{ overflow: 'revert' }}>{ele.name}</p>
                  </div>
                  <span>{ele.reach}</span>
                </div>
              </MenuItem>
            );
          });
        }
      }
    }
    return ListArray;
  };
  const handleChange = (e, field) => {
    if (field === 'audience') {
      props.updateReducer(
        (audiences || []).find((aud) => aud.name === e.target.value),
        field
      );
    } else {
      props.updateReducer(e.target.value, field);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 300);
    };
  };

  const optimizedFn = useCallback(debounce(handleChange), []);
  return (
    <div className='targeting-container'>
      <div className='header-targeting-container'>
        <Typography className='targeting-header__title'>Targeting</Typography>
      </div>
      <div className='targeting-form'>
        <div className='targeting-form-element'>
          <Typography className='targeting-form-element__title'>
            Location
          </Typography>
          <LookalikeLocationField
            advertisingLocations={props.data.advertisingLocations}
            updateReducer={props.updateReducer}
          />
          {/* <TextField
            placeholder="Select"
            variant="outlined"
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            value={props.data.location}
            onChange={(e) => optimizedFn(e, "location")}
            // onChange={(e) => {
            //   handleChange(e, "location");
            // }}
            inputProps={{
              style: {
                padding: "11px 16px",
                background: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: 8,
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12,
                lineHeight: "20px",
              },
            }}
          /> */}
        </div>
        <div className='targeting-form-element age-form-element'>
          <Typography className='targeting-form-element__title'>Age</Typography>
          <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
            <TextField
              placeholder='From'
              variant='outlined'
              type='number'
              sx={{
                'input::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                'input::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                'input[type=number]': {
                  '-moz-appearance': 'textfield',
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              }}
              value={props.data.minAge || ''}
              onChange={(e) => handleChange(e, 'minAge')}
              // onChange={(e) => {
              //   handleChange(e, "ageFrom");
              // }}
              inputProps={{
                style: {
                  padding: '11px 16px',
                  background: '#FFFFFF',
                  border: '1px solid #DDDDDD',
                  borderRadius: 8,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '20px',
                },
              }}
            />

            <div className='divider-age-range'></div>
            <TextField
              placeholder='To'
              variant='outlined'
              type='number'
              sx={{
                'input::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                'input::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                'input[type=number]': {
                  '-moz-appearance': 'textfield',
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              }}
              value={props.data.maxAge || ''}
              onChange={(e) => handleChange(e, 'maxAge')}
              // onChange={(e) => {
              //   handleChange(e, "ageTo");
              // }}
              inputProps={{
                style: {
                  padding: '11px 16px',
                  background: '#FFFFFF',
                  border: '1px solid #DDDDDD',
                  borderRadius: 8,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '20px',
                },
              }}
            />
          </div>
        </div>
        <div className='targeting-form-element gender-form-element'>
          <Typography className='targeting-form-element__title'>
            Gender
          </Typography>
          <>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              style={{
                gap: '21px',
              }}
              value={props.data.gender}
              onChange={(e) => {
                handleChange(e, 'gender');
              }}
            >
              <FormControlLabel
                value='Both'
                control={<BpRadio />}
                label='Both'
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px !important',
                  lineHeight: '20px',
                  color: '#999999',
                }}
              />
              <FormControlLabel
                value='Male'
                control={<BpRadio />}
                label='Male'
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px !important',
                  lineHeight: '20px',
                  color: '#999999',
                }}
              />
              <FormControlLabel
                value='Female'
                control={<BpRadio />}
                label='Female'
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px !important',
                  lineHeight: '20px',
                  color: '#999999',
                }}
              />
            </RadioGroup>
          </>
        </div>
        <div className='targeting-form-element'>
          <Typography className='targeting-form-element__title'>
            Custom Audience
          </Typography>
          <div>
            <FormControl sx={{ width: '100%' }}>
              <Select
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                style={{
                  height: 42,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  background: '#FFFFFF',
                  border: '1px solid #DDDDDD',
                  borderRadius: 8,
                  color: '#000000',
                }}
                value={props?.data?.audience?.name || ''}
                onChange={(e) => {
                  handleChange(e, 'audience');
                }}
                renderValue={(selected) => selected}
              >
                <ListSubheader className='drop_custom_list_tophead'>
                  <span>Audience</span> <span>Reach</span>
                </ListSubheader>
                {DropDownRender()}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='targeting-form-element lookAlikeformelement'>
          <Typography className='targeting-form-element__title'>
            Lookalike
          </Typography>
          <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <TextField
                placeholder='From'
                variant='outlined'
                type='number'
                sx={{
                  'input::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  'input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  'input[type=number]': {
                    '-moz-appearance': 'textfield',
                  },
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                value={props.data.minRange || ''}
                onChange={(e) => handleChange(e, 'minRange')}
                // onChange={(e) => {
                //   handleChange(e, "lookalikeFrom");
                // }}
                inputProps={{
                  style: {
                    padding: '11px 16px',
                    background: '#FFFFFF',
                    border: '1px solid #DDDDDD',
                    borderRadius: 8,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '20px',
                  },
                }}
              />
              <span
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#999999',
                }}
              >
                %
              </span>
            </div>

            <div className='divider-age-range'></div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <TextField
                placeholder='To'
                variant='outlined'
                type='number'
                sx={{
                  'input::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  'input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  'input[type=number]': {
                    '-moz-appearance': 'textfield',
                  },
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                value={props.data.maxRange || ''}
                onChange={(e) => handleChange(e, 'maxRange')}
                // onChange={(e) => {
                //       handleChange(e, "lookalikeTo");
                //     }}
                inputProps={{
                  style: {
                    padding: '11px 16px',
                    background: '#FFFFFF',
                    border: '1px solid #DDDDDD',
                    borderRadius: 8,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '20px',
                  },
                }}
              />
              <span
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#999999',
                }}
              >
                %
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LookAlikeTargetting;
