export const randomisedFacebookAds = (ads) => {
    const desiredArray=[];
    let id=1;
    ads && ads.forEach((ad) => {
        desiredArray.push({headline:ad.headline,imageUrl:ad.imageUrl,primaryText:ad.primaryText,description:ad.description,requestId:ad.requestId});
        ad.response.forEach((response) => {
            desiredArray.push({...response,...{imageUrl:ad.imageUrl,requestId:ad.requestId,id:id}});
            id++;
        });
    });
    const outputArray=[];
    const numberOfRadmise=2;
    for(let i=0;i<numberOfRadmise;i++){
        const randomNumber=Math.floor(Math.random() * desiredArray.length);
        outputArray.push({...desiredArray[randomNumber],...{adName:"Ad "+(randomNumber+1)}});
    }
    return outputArray;
}

export const numberOfAds = (ads) => {
    const desiredArray=[];
    let id=1;
    ads && ads.forEach((ad) => {
        desiredArray.push({headline:ad.headline,imageUrl:ad.imageUrl,primaryText:ad.primaryText,description:ad.description,requestId:ad.requestId});
        ad.response.forEach((response) => {
            desiredArray.push({...response,...{imageUrl:ad.imageUrl,requestId:ad.requestId,id:id}});
            id++;
        });
    });
    return desiredArray.length;
}

export const mappedAdsByCreative = (ads) => {
   const obj={};
   let id=1;
   ads && ads.forEach((ad,index) => {
       const array=[];
       ad.response.forEach((response)=>{
           array.push({...response,...{imageUrl:ad.imageUrl,requestId:ad.requestId}});
       });
       if(array.length !== 0){
        obj["CREATIVE"+(id)]=array;
        id++;
       }
    });
   return obj;
}

export const isAnyAdsThereInAdsetHdm = (adsets) => {
    let isThere=false;
    adsets?.forEach((adset) => {
       if(adset.facebookAds.length === 0){
         isThere=true;
       }
    });
    return isThere;
}
