import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import READ from "./icons/read-icon.png";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  goBackHDMAmCompo,
  updateCompoReduxHDMAM,
} from "../../../api/HDMAM/componentTracking/action";
import { updateStatusHdmAm } from "../../../api/HDMAM/form/action";
import { setLocalStorage } from "../../../api/localStorage/localStorage";
import { fb_icon as FB } from "../../../assets/icons/social/social";
import { google2 as GOOGLE } from "../../../assets/icons/social/social";
import AccessSharingWaitPage from "../../modals/AccessSharingWaitPage";
import StatusDropdown from "../HDMDropdownBar/HDMDropdown";
import InputForDeleteAccountId from "../InputForAcoountIdDelete/InputForAccountId";
import "./Access.css";
import { saveHdmAmFormsData } from "../../../api/HDMAM/form/action";
import { isDataSavedHdmAmForm } from "../../../api/HDMAM/form/saga";
import {
  dbStatus,
  mappedStatusDb,
} from "../../ClientDashboard/HomePage/HomePage";
import {
  goToSpecificCompoClientAm,
  updateCompoReduxAM,
} from "../../../api/accountManager/componentTracking/action";
import { getFormByFormIdReducer } from "../../../api/accountManager/forms/slice";
import { updatedStatusHdm } from "../../../api/HDMClient/saga";
import { saveCompleteAccess } from "../../../api/HDMAM/form/saga";
import {
  BACKEND_STATUS,
  hasAccessProvided,
  isPlatformSelected,
  leftBarStatus,
} from "../../../utils/accountManagerUtil";
import { channel } from "redux-saga";
import { ChannelType } from "../../Admin/channel.js";

const formType = (accessType, reduxStates) => {
  if (accessType === 'LD') {
    return reduxStates?.form?.form;
  } else {
    return reduxStates?.hdmFormAm;
  }
};

const disableCondition = (form) => {
  let disable = true;
  if (
    isPlatformSelected(form, ChannelType.GoogleAds) !== -1 &&
    form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.PENDING
  ) {
    disable = false;
  }
  if (
    isPlatformSelected(form, ChannelType.FacebookAds) !== -1 &&
    form?.fbAdsStrategyInfo?.status === BACKEND_STATUS.PENDING
  ) {
    disable = false;
  }
  return disable;
};

const validate = (platformSelected, reduxStates, accessType) => {
  const accessSharing = reduxStates["accessSharing"];
  if (accessSharing?.status === "COMPLETED" && false) {
    return true;
  }
  if (accessSharing && platformSelected.length === 2) {
    if (
      accessSharing?.fbAccess?.adAccount?.status === dbStatus.VERIFYING ||
      accessSharing?.googleAccess?.googleAdAccount?.status ===
        dbStatus.VERIFYING ||
      accessSharing?.fbAccess?.adAccount?.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS ||
      accessSharing?.googleAccess?.googleAdAccount?.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
    ) {
      return true;
    }
  } else if (accessSharing && platformSelected.length === 1) {
    if (
      platformSelected[0].platform === 'google-ads' &&
      (accessSharing?.googleAccess?.googleAdAccount?.status === 'VERIFYING' ||
        accessSharing?.googleAccess?.googleAdAccount?.status ===
          dbStatus.VERIFYING_COMPLETE_ACCESS)
    ) {
      return true;
    } else if (
      (platformSelected[0].platform === 'facebook-ads' &&
        accessSharing?.fbAccess?.adAccount?.status === 'VERIFYING') ||
      accessSharing?.fbAccess?.adAccount?.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
    ) {
      return true;
    }
  }
  return false;
};

const HDMAccessAM = ({ accessType = 'HDM', type = 'COMPLETE_ACCESS' }) => {
  const dispatch = useDispatch();

  const [showHoldPage, setShowHoldPage] = useState(false);
  const reduxStates = formType(
    accessType,
    useSelector((state) => state)
  );
  const amCurrentCompoToRender = useSelector(
    (state) => state.amCurrentComp.currentCompAm
  );
  const platformSelected =
    useSelector((state) =>
      accessType === 'LD'
        ? state?.client?.onboardingInfo?.platformInfo?.platforms
        : state?.hdmFormAm?.onboardingInfo?.clientAccess?.platforms
    ) || [];

  const form = useSelector((store) => store?.form?.form);
  const hdmFormAm = useSelector((state) => state?.hdmFormAm);
  const hdmClient = useSelector((state) => state?.hdmClient);

  useEffect(() => {
    if (accessType === "LD" && form) {
      if (type === "READ_ACCESS") {
        if (
          !hasAccessProvided(
            form?.onboardingInfo?.platformInfo?.platforms,
            form?.accessSharing
          )
        ) {
          setShowHoldPage(true);
        }
      }
    }
  }, [form]);
  const readAccessProceed = (hdmFormAm, hdmClient, accessType, reduxStates) => {
    isDataSavedHdmAmForm(hdmFormAm, hdmClient, accessType, reduxStates).then(
      (res) => {
        if (accessType === 'LD') {
          if (
            !(
              reduxStates.accessSharing.fbAccess.adAccount.status ===
                dbStatus.ACCESS_REVOKED ||
              reduxStates.accessSharing.googleAccess.googleAdAccount.status ===
                dbStatus.ACCESS_REVOKED
            )
          ) {
            dispatch(getFormByFormIdReducer());
            if (
              platformSelected.findIndex(
                (platform) => platform.platform === "google-ads"
              ) !== -1
            ) {
              dispatch(updateCompoReduxAM());
            } else if (
              platformSelected.findIndex(
                (platform) => platform.platform === "facebook-ads"
              ) !== -1
            ) {
              let compoNumber = amCurrentCompoToRender;
              if (form?.accountAnalysis?.status === "COMPLETED") {
                compoNumber = compoNumber + 4;
              } else {
                compoNumber = compoNumber + 3;
              }
              dispatch(goToSpecificCompoClientAm(compoNumber));
            }
          }
        } else {
          if (
            !(
              reduxStates.accessSharing.fbAccess.adAccount.status ===
                dbStatus.ACCESS_REVOKED ||
              reduxStates.accessSharing.googleAccess.googleAdAccount.status ===
                dbStatus.ACCESS_REVOKED
            )
          ) {
            dispatch({
              type: 'UPDATE_WHOLE_HDM_AM_DETAILS',
              payload: res.data,
            });
            dispatch({
              type: 'UPDATE_WHOLE_CLIENT_FORM_HDM',
              payload: res.data,
            });
            dispatch(updateCompoReduxHDMAM());
          }
        }
      }
    );
  };
  return (
    <>
      <div style={{ width: "100%" }}>
        {showHoldPage ? (
          <AccessSharingWaitPage />
        ) : (
          <div className="read_container-hdm">
            <Paper spacing={2} className="readaccess_paper">
              {/* {documentGenPage ? (
              <div className="layout_submission">
                <div className="center">
                  <img src={readyGif} className="img-submit" />
                  <div className="submit-title">
                    Hold Tight! Onboarding Document Is Being Generated.
                  </div>
                  <div className="submit-desc">
                    We will notify you as soon as the onboarding doc is ready
                    <br />
                    for your approval.
                  </div>
                  <Button
                    variant="contained"
                    onClick={() => dispatch(updateCompoReduxHDMAM())}
                    // setDocumentGenPage(true)}
                    //  dispatch(updateHDMCompoReduxClient())}
                  >
                    PROCEED
                  </Button>
                </div>
              </div>
            ) : ( */}
              <>
                <div className="layout">
                  <div className="top_read_section">
                    <h3 className="access_heading">ACCESS</h3>

                    {/* <span className="lock_icon"></span> */}
                    <h2 className="read_title">ACCESS FOR YOUR PLATFORMS</h2>
                    <p className="paragraph">
                      Please provide us the view access for your Ad Accounts
                      which will help us understand the current issues in your
                      account. Don’t worry we wont be making any changes to your
                      account.
                    </p>
                  </div>
                  <div className="grid_home_page_hdm">
                    <div className="grid_colum_hdm">
                      <div className="grid_account_column_hdm">
                        <div className="social_list_home_hdm">
                          <img src={FB} alt="" />
                          <p className="social_name_title_hdm">Facebook</p>
                        </div>

                        <div className="social_media">
                          <TableContainer>
                            <Table
                              style={
                                platformSelected.length !== 2
                                  ? {
                                      pointerEvents:
                                        platformSelected.length === 1 &&
                                        platformSelected[0].platform ===
                                          "facebook-ads"
                                          ? "all"
                                          : "none",
                                      backgroundColor:
                                        platformSelected.length === 1 &&
                                        platformSelected[0].platform ===
                                          "facebook-ads"
                                          ? ""
                                          : "#eeee",
                                    }
                                  : {}
                              }
                            >
                              <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    width: '30%',
                                    backgroundColor: '#F0F3FF',
                                  }}
                                >
                                  <b>Asset</b>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: '30%',
                                    backgroundColor: '#F0F3FF',
                                  }}
                                >
                                  <b>Status</b>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: '40%',
                                    backgroundColor: '#F0F3FF',
                                  }}
                                >
                                  <b>Account ID</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    <div className="asset_title">
                                      Page
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    {reduxStates["accessSharing"]["fbAccess"][
                                      "page"
                                    ]["accountId"].length !== 0 ? (
                                      <StatusDropdown
                                        objKey="fbAccess"
                                        objKey2="page"
                                        objKey3="status"
                                        infoType="accessSharing"
                                        value={
                                          reduxStates["accessSharing"][
                                            "fbAccess"
                                          ]["page"]["status"]
                                        }
                                        accessType={accessType}
                                        type={type}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell style={{
                                    width: '40%',
                                  }}>
                                    <InputForDeleteAccountId
                                      objKey="fbAccess"
                                      objKey2="page"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates["accessSharing"][
                                          "fbAccess"
                                        ]["page"]["accountId"]
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    <div className="asset_title">
                                      Ad Account{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    {reduxStates["accessSharing"]["fbAccess"][
                                      "adAccount"
                                    ]["accountId"].length !== 0 ? (
                                      <StatusDropdown
                                        objKey="fbAccess"
                                        objKey2="adAccount"
                                        objKey3="status"
                                        infoType="accessSharing"
                                        value={
                                          reduxStates["accessSharing"][
                                            "fbAccess"
                                          ]["adAccount"]["status"]
                                        }
                                        accessType={accessType}
                                        type={type}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell style={{
                                    width: '40%',
                                  }}>
                                    <InputForDeleteAccountId
                                      objKey="fbAccess"
                                      objKey2="adAccount"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates["accessSharing"][
                                          "fbAccess"
                                        ]["adAccount"]["accountId"]
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell  style={{
                                    width: '30%',
                                  }}>
                                    <div className="asset_title">
                                      Catalog{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell  style={{
                                    width: '30%',
                                  }}>
                                    {reduxStates["accessSharing"]["fbAccess"][
                                      "catalog"
                                    ]["accountId"].length !== 0 ? (
                                      <StatusDropdown
                                        objKey="fbAccess"
                                        objKey2="catalog"
                                        objKey3="status"
                                        infoType="accessSharing"
                                        value={
                                          reduxStates["accessSharing"][
                                            "fbAccess"
                                          ]["catalog"]["status"]
                                        }
                                        accessType={accessType}
                                        type={type}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell  style={{
                                    width: '40%',
                                  }}>
                                    <InputForDeleteAccountId
                                      objKey="fbAccess"
                                      objKey2="catalog"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates["accessSharing"][
                                          "fbAccess"
                                        ]["catalog"]["accountId"]
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell  style={{
                                    width: '30%',
                                  }}>
                                    <div className="asset_title">
                                      Pixel{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell  style={{
                                    width: '30%',
                                  }}>
                                    {reduxStates["accessSharing"]["fbAccess"][
                                      "pixel"
                                    ]["accountId"].length !== 0 ? (
                                      <StatusDropdown
                                        objKey="fbAccess"
                                        objKey2="pixel"
                                        objKey3="status"
                                        infoType="accessSharing"
                                        value={
                                          reduxStates["accessSharing"][
                                            "fbAccess"
                                          ]["pixel"]["status"]
                                        }
                                        accessType={accessType}
                                        type={type}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell  style={{
                                    width: '40%',
                                  }}>
                                    <InputForDeleteAccountId
                                      objKey="fbAccess"
                                      objKey2="pixel"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates["accessSharing"][
                                          "fbAccess"
                                        ]["pixel"]["accountId"]
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                    <div className="grid_colum_hdm">
                      <div className="grid_account_column_hdm">
                        <div className="social_list_home_hdm">
                          <img src={GOOGLE} alt="" />
                          <p className="social_name_title_hdm">Google</p>
                        </div>

                        <div className="social_media">
                          <TableContainer>
                            <Table
                              style={
                                platformSelected.length !== 2
                                  ? {
                                      pointerEvents:
                                        platformSelected.length === 1 &&
                                        platformSelected[0].platform ===
                                          "google-ads"
                                          ? "all"
                                          : "none",
                                      backgroundColor:
                                        platformSelected.length === 1 &&
                                        platformSelected[0].platform ===
                                          "google-ads"
                                          ? ""
                                          : "#eeee",
                                    }
                                  : {}
                              }
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{ width: '30%', backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Asset</b>
                                  </TableCell>
                                  <TableCell
                                    style={{ width: '30%', backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Status</b>
                                  </TableCell>
                                  <TableCell
                                    style={{width: '40%', backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Account ID</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    <div className="asset_title">
                                      Analytics
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    {reduxStates["accessSharing"][
                                      "googleAccess"
                                    ]["googleAnalytics"]["accountId"].length !==
                                    0 ? (
                                      <StatusDropdown
                                        objKey="googleAccess"
                                        objKey2="googleAnalytics"
                                        objKey3="status"
                                        infoType="accessSharing"
                                        value={
                                          reduxStates["accessSharing"][
                                            "googleAccess"
                                          ]["googleAnalytics"]["status"]
                                        }
                                        accessType={accessType}
                                        type={type}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell style={{
                                    width: '40%',
                                  }}>
                                    <InputForDeleteAccountId
                                      objKey="googleAccess"
                                      objKey2="googleAnalytics"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates["accessSharing"][
                                          "googleAccess"
                                        ]["googleAnalytics"]["accountId"]
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    <div className="asset_title">
                                      Ad Account{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    {reduxStates["accessSharing"][
                                      "googleAccess"
                                    ]["googleAdAccount"]["accountId"].length !==
                                    0 ? (
                                      <StatusDropdown
                                        objKey="googleAccess"
                                        objKey2="googleAdAccount"
                                        objKey3="status"
                                        infoType="accessSharing"
                                        value={
                                          reduxStates["accessSharing"][
                                            "googleAccess"
                                          ]["googleAdAccount"]["status"]
                                        }
                                        accessType={accessType}
                                        type={type}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell style={{
                                    width: '40%',
                                  }}>
                                    <InputForDeleteAccountId
                                      objKey="googleAccess"
                                      objKey2="googleAdAccount"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates["accessSharing"][
                                          "googleAccess"
                                        ]["googleAdAccount"]["accountId"]
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    <div className="asset_title">
                                      Merchant Centre{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell style={{
                                    width: '30%',
                                    textAlign: 'center',
                                  }}>
                                    {" "}
                                    {reduxStates["accessSharing"][
                                      "googleAccess"
                                    ]["googleMerchant"]["accountId"].length !==
                                    0 ? (
                                      <StatusDropdown
                                        objKey="googleAccess"
                                        objKey2="googleMerchant"
                                        objKey3="status"
                                        infoType="accessSharing"
                                        value={
                                          reduxStates["accessSharing"][
                                            "googleAccess"
                                          ]["googleMerchant"]["status"]
                                        }
                                        accessType={accessType}
                                        type={type}
                                      />
                                    ) : (
                                      "-------------"
                                    )}
                                  </TableCell>
                                  <TableCell style={{
                                    width: '40%',
                                    textAlign: 'center',
                                  }}>
                                    <InputForDeleteAccountId
                                      objKey="googleAccess"
                                      objKey2="googleMerchant"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates["accessSharing"][
                                          "googleAccess"
                                        ]["googleMerchant"]["accountId"]
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{
                                    width: '30%',
                                  }}>
                                    <div className="asset_title">
                                      Tag Manager{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell style={{
                                    width: '30%',
                                    textAlign: 'center',
                                  }}>
                                    {" "}
                                    {reduxStates["accessSharing"][
                                      "googleAccess"
                                    ]["googleAndShopifyLinked"]["accountId"]
                                      .length !== 0 ? (
                                      <StatusDropdown
                                        objKey="googleAccess"
                                        objKey2="googleAndShopifyLinked"
                                        objKey3="status"
                                        infoType="accessSharing"
                                        value={
                                          reduxStates["accessSharing"][
                                            "googleAccess"
                                          ]["googleAndShopifyLinked"]["status"]
                                        }
                                        accessType={accessType}
                                        type={type}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell style={{
                                    width: '40%',
                                  }}>
                                    <InputForDeleteAccountId
                                      objKey="googleAccess"
                                      objKey2="googleAndShopifyLinked"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates["accessSharing"][
                                          "googleAccess"
                                        ]["googleAndShopifyLinked"]["accountId"]
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="grid_home_page_hdm">
                  <div className="grid_colum_hdm">
                    <div className="grid_account_column_hdm">
                      <div className="social_list_home_hdm">
                        <img src={TIKTOK} alt="" />
                        <p className="social_name_title_hdm">Tik-Tok</p>
                      </div>

                      <div className="social_media">
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{ backgroundColor: "#F0F3FF" }}
                                >
                                  <b>Asset</b>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "#F0F3FF" }}
                                >
                                  <b>Status</b>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "#F0F3FF" }}
                                >
                                  <b>Account ID</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <div className="asset_title">
                                    TikTok Ads Manager{" "}
                                    <img
                                      src={READ}
                                      style={{
                                        position: "absolute",
                                        marginLeft: 10,
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <StatusDropdown
                                    objKey="tiktokAccess"
                                    objKey2="tiktokAdsManager"
                                    objKey3="status"
                                    infoType="accessSharing"
                                    value={
                                      reduxStates["hdmClient"][
                                        "accessSharing"
                                      ]["tiktokAccess"]["tiktokAdsManager"][
                                        "status"
                                      ]
                                    }
                                    accessType={accessType}
                                    type={type}
                                  />
                                </TableCell>
                                <TableCell>
                                  <InputForDeleteAccountId
                                    objKey="tiktokAccess"
                                    objKey2="tiktokAdsManager"
                                    objKey3="accountId"
                                    infoType="accessSharing"
                                    value={
                                      reduxStates["hdmClient"][
                                        "accessSharing"
                                      ]["tiktokAccess"]["tiktokAdsManager"][
                                        "accountId"
                                      ]
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div className="asset_title">
                                    TikTok Pixel{" "}
                                    <img
                                      src={READ}
                                      style={{
                                        position: "absolute",
                                        marginLeft: 10,
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <StatusDropdown
                                    objKey="tiktokAccess"
                                    objKey2="tiktokPixel"
                                    objKey3="status"
                                    infoType="accessSharing"
                                    value={
                                      reduxStates["hdmClient"][
                                        "accessSharing"
                                      ]["tiktokAccess"]["tiktokPixel"]["status"]
                                    }
                                    accessType={accessType}
                                    type={type}
                                  />
                                </TableCell>
                                <TableCell>
                                  <InputForDeleteAccountId
                                    objKey="tiktokAccess"
                                    objKey2="tiktokPixel"
                                    objKey3="accountId"
                                    infoType="accessSharing"
                                    value={
                                      reduxStates["hdmClient"][
                                        "accessSharing"
                                      ]["tiktokAccess"]["tiktokPixel"][
                                        "accountId"
                                      ]
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                  <div className="grid_colum_hdm">
                    <div className="grid_account_column_hdm">
                      <div className="social_list_home_hdm">
                        <img src={Shopify} alt="" />
                        <p className="social_name_title_hdm">Shopify</p>
                      </div>

                      <div className="social_media">
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{ backgroundColor: "#F0F3FF" }}
                                >
                                  <b>Asset</b>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "#F0F3FF" }}
                                >
                                  <b>Status</b>
                                </TableCell>
                                <TableCell
                                  style={{ backgroundColor: "#F0F3FF" }}
                                >
                                  <b>Account ID</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <div className="asset_title">
                                    Facebook sales channel{" "}
                                    <img
                                      src={READ}
                                      style={{
                                        position: "absolute",
                                        marginLeft: 10,
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <StatusDropdown
                                    objKey="shopifyAccess"
                                    objKey2="fbSalesChannel"
                                    objKey3="status"
                                    infoType="accessSharing"
                                    value={
                                      reduxStates["hdmClient"][
                                        "accessSharing"
                                      ]["shopifyAccess"]["fbSalesChannel"][
                                        "status"
                                      ]
                                    }
                                    accessType={accessType}
                                    type={type}
                                  />
                                </TableCell>
                                <TableCell>
                                  <InputForDeleteAccountId
                                    objKey="shopifyAccess"
                                    objKey2="fbSalesChannel"
                                    objKey3="accountId"
                                    infoType="accessSharing"
                                    value={
                                      reduxStates["hdmClient"][
                                        "accessSharing"
                                      ]["shopifyAccess"]["fbSalesChannel"][
                                        "accountId"
                                      ]
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div className="asset_title">
                                    TikTok Sales Channel{" "}
                                    <img
                                      src={READ}
                                      style={{
                                        position: "absolute",
                                        marginLeft: 10,
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <StatusDropdown
                                    objKey="shopifyAccess"
                                    objKey2="tiktokSalesChannel"
                                    objKey3="status"
                                    infoType="accessSharing"
                                    value={
                                      reduxStates["hdmClient"][
                                        "accessSharing"
                                      ]["shopifyAccess"]["tiktokSalesChannel"][
                                        "status"
                                      ]
                                    }
                                    accessType={accessType}
                                    type={type}
                                  />
                                </TableCell>
                                <TableCell>
                                  <InputForDeleteAccountId
                                    objKey="shopifyAccess"
                                    objKey2="tiktokSalesChannel"
                                    objKey3="accountId"
                                    infoType="accessSharing"
                                    value={
                                      reduxStates["hdmClient"][
                                        "accessSharing"
                                      ]["shopifyAccess"]["tiktokSalesChannel"][
                                        "accountId"
                                      ]
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
                <div className="preview_footer_row">
                  <div className="footer_con">
                    <button
                      className="back"
                      onClick={() => {
                        dispatch(goBackHDMAmCompo());
                      }}
                    >
                      BACK
                    </button>
                    <Button
                      variant="contained"
                      disabled={
                        type === "COMPLETE_ACCESS" && accessType === "LD"
                          ? disableCondition(form)
                          : validate(platformSelected, reduxStates, accessType)
                      }
                      onClick={() => {
                        if (type === "COMPLETE_ACCESS") {
                          saveCompleteAccess(
                            reduxStates,
                            dbStatus.COMPLETE_ACCESS,
                            false,
                            accessType
                          ).then((res) => {
                            if (accessType === "HDM") {
                              const isAnyAcceesRevoked =
                                reduxStates.accessSharing.fbAccess.adAccount
                                  .status === dbStatus.ACCESS_REVOKED ||
                                reduxStates.accessSharing.googleAccess
                                  .googleAdAccount.status ===
                                  dbStatus.ACCESS_REVOKED;
                              if (!isAnyAcceesRevoked) {
                                dispatch(updateCompoReduxHDMAM());
                                dispatch({
                                  type: "UPDATE_WHOLE_HDM_AM_DETAILS_SAGA",
                                });
                              }
                            } else {
                              dispatch(getFormByFormIdReducer());
                              dispatch(updateCompoReduxAM())
                              dispatch({
                                type: "UPDATE_WHOLE_HDM_AM_DETAILS_SAGA",
                              });
                            }
                          });
                        } else {
                          readAccessProceed(
                            hdmFormAm,
                            hdmClient,
                            accessType,
                            reduxStates
                          );
                        }
                      }}
                    >
                      PROCEED
                    </Button>
                  </div>
                </div>
              </>
            </Paper>
          </div>
        )}
      </div>
    </>
  );
};

export default HDMAccessAM;
