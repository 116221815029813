import {ready as readyGif} from '../../../assets/icons/proposal/proposal';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { useDispatch } from 'react-redux';
import {updateCompoReduxClient} from "../../../api/componentTracking/action";

const WelcomeScreen = () => {
    const dispatch=useDispatch();
    return (
        <div id="working_proposal" style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80vh", paddingRight:'18px', width:'100%'}}>
            <div className="proposal_status" style={{display:"flex",justifyContent:"center",alignItems:"center",background:'#ffffff', paddingRight:'18px', width:'100%', height:'100%', borderRadius:"8px"}}>
                <div className="proposal_status_layout">
                    <span className="setIcon"><img src={readyGif} /></span>
                    <h2 className="proposal_status_title">You’re all set!</h2>
                    <p className="proposal_status_typography">We are working on your proposal and will notify you when it is ready. In the meantime, you can know more about Pixis by browsing the Homepage. </p>
                    <PrimaryButton title='Home' onClick={()=>dispatch(updateCompoReduxClient())}/>
                </div>
            </div>
        </div> 
    )
}
export default WelcomeScreen;