import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLocation } from "../../../api/HDMAM/campaign/action";

const MyChip = (props) => {
  return (
    <div className="custom_chips">
      <span>{props.label}</span>
      <span className="close_tag" onClick={props.onDelete}></span>
    </div>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BOFLocationField = (props) => {
  const dispatch = useDispatch();

  let locationOptions = useSelector((state) => state.hdmCampaign.locationsList);
  if (!locationOptions) {
    locationOptions = [];
  }
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags"
      options={locationOptions}
      defaultValue={props.advertisingLocations}
      onChange={(event, values) => {
        props.updateReducer(values, 'advertisingLocations');
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <MyChip {...getTagProps({ index })} label={option.name} />
        ));
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option, inputValue, { insideWords: true });
        const parts = parse(option, matches);
        console.log(parts)
        return (
          <li {...props} className="search_location_list">
            <div className="location_list_head">
              {" "}
              <p>Location</p> <p>Type</p>
            </div>
            <div className="location_list_content">
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? "#0869FB" : "#333333",
                    }}
                  >
                    {part.text.name}
                  </span>
                ))}
              </div>
              <span>{option.type}</span>
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <div className="location_question_header">
          <span className="search_icon"></span>
          <TextField
            {...params}
            ref={params.InputProps.ref}
            autoFocus
            style={{
              width: props.width,
              height: props.height ? props.height : "",
              animation: props.bounce && `${props.bounceAnimation} 0.9s both`,
            }}
            placeholder={props.placeholder ? props.placeholder : "Search"}
            variant="standard"
            onChange={(e) => {
              dispatch({
                type: "getCampaignLocation",
                payload: {
                  value: e.target.value,
                },
              });
            }}
          />
        </div>
      )}
      disablePortal={true}
      className="auto_location_search"
    />
  );
};

export default BOFLocationField;
