const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const address_icon  = `${imageBaseUrl}/assets/d5691f71-b5af-4da4-a1af-cfdc3a25144f` 
export const address2_icon  = `${imageBaseUrl}/assets/ba83dff4-0624-4644-90f9-b3488dc70e52`
export const company_icon  = `${imageBaseUrl}/assets/41489b3f-0197-437a-81f1-00a8ca1ebf90`
export const company_logo_icon  = `${imageBaseUrl}/assets/98fe174b-78e0-42eb-b36f-74efd412a42c`
export const company_office_address_icon  = `${imageBaseUrl}/assets/27c566b2-b3ca-401c-8dad-9c1f04e91855`
export const company_phone_icon  = `${imageBaseUrl}/assets/5713e22f-54c0-4942-9908-62042584fdb1`
export const competitor_icon  = `${imageBaseUrl}/assets/b4162a70-12fc-4696-b84d-a3e19ba81dd0`
export const contact_icon  = `${imageBaseUrl}/assets/4a25ad00-d87d-46f0-bd68-038bfc8e7acc`
export const cpa_icon  = `${imageBaseUrl}/assets/56097c9d-1e1c-4b29-bee7-ebcd9055f887`
export const crm_setting_icon  = `${imageBaseUrl}/assets/5a3bbd6e-ad29-4871-b0b1-cea554b5eb2a`
export const diamond_icon  = `${imageBaseUrl}/assets/591ce4ce-cc90-4922-be8a-3db9f843b4c0`
export const email_icon  = `${imageBaseUrl}/assets/fe79d222-60f9-4135-8573-065c64870aaa`
export const growth_goal_icon  = `${imageBaseUrl}/assets/c2fb515c-1c3f-4fac-87a8-c1d3179eb64e`
export const growth_icon  = `${imageBaseUrl}/assets/ea44f2b0-b273-47ed-bb9c-f8a137e0c25e`
export const industry_icon  = `${imageBaseUrl}/assets/1374b869-1f56-4119-b30b-3ab9967a2c47`
export const primary_contact_icon  = `${imageBaseUrl}/assets/2ebb633c-af87-48e7-bd92-93fc27017240`
export const primary_contact_name_icon  = `${imageBaseUrl}/assets/074d4269-ca72-48e7-8b90-159078c2cc2d`
export const primary_contact_role_icon  = `${imageBaseUrl}/assets/f14805d0-316b-4d07-9422-831b466aea08`
export const primary_email_icon  = `${imageBaseUrl}/assets/cfb0eb2d-4a5e-4e86-845e-9d9cc4e14367`
export const service_icon  = `${imageBaseUrl}/assets/382586fb-c46b-444a-9843-ec60d4fa7799`
export const signature_logo_icon  = `${imageBaseUrl}/assets/eb259965-c470-4a04-a562-1dde048a44ad`
export const spends  = `${imageBaseUrl}/assets/efef069d-3f4a-4cf3-9336-7c6e60216620`
export const target_cpa_icon  = `${imageBaseUrl}/assets/96dae041-9759-4804-9a1b-d31c9023765b`
export const ticket_icon  = `${imageBaseUrl}/assets/5695acc3-1e94-4d92-899b-21d68f0434c7`
export const ticket_price_icon  = `${imageBaseUrl}/assets/fc34d4dc-c953-4517-b059-0ea9e752ea94`
export const website_icon  = `${imageBaseUrl}/assets/fe6127d9-df00-4f54-bd46-245fe9a86413`