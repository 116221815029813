const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const accept_fill  = `${imageBaseUrl}/assets/6c854703-29b5-451d-9b7b-d09b0a3145a0`
export const accept_blue  = `${imageBaseUrl}/assets/d0dcfcc5-6fc2-4ddb-aee5-9ca059831970`
export const accept_white = `${imageBaseUrl}/assets/a4e448d0-f2c1-43b8-b4a4-15fbf983620f`
export const accept = `${imageBaseUrl}/assets/d45ae6d6-f483-4f12-9f9c-8bae84b82946`
export const account_proposal = `${imageBaseUrl}/assets/f555673f-78ff-4d84-a3c9-4811ad3f803b`
export const approve_fill_icon = `${imageBaseUrl}/assets/3cc10853-d9bc-479c-8fa5-55b78ba04f37`
export const approve_icon = `${imageBaseUrl}/assets/2c35b944-b1ae-4dcb-9f68-ee3cdeb8b062`
export const audit        = `${imageBaseUrl}/assets/d57d4c58-72a2-4c81-be51-334c5aa9bb34`
export const back_arrow   = `${imageBaseUrl}/assets/d28170d9-fff6-483c-ae7d-aa61e12a6f3e`
export const bookicon     = `${imageBaseUrl}/assets/3e73a2b8-0efd-496c-9df0-95595ab661e4`
export const button_bg_corner   = `${imageBaseUrl}/assets/65968f50-fd79-4a92-906e-7bcc31dc68f0`
export const check        = `${imageBaseUrl}/assets/7784f23f-e8ee-430d-b3da-1acc25baac5d`
export const checked_icon = `${imageBaseUrl}/assets/17f2c474-ecb4-413a-b055-bc8f5556abd5`
export const close        = `${imageBaseUrl}/assets/8f540b5c-f68c-454d-b067-c49dde632c70`
export const competitors_website_icon       = `${imageBaseUrl}/assets/b1f80d18-53ec-4244-9929-ecff42e7cba6`
export const cross_icon_black      = `${imageBaseUrl}/assets/41bcddc-de94-48fd-9479-a956362d20b4`
export const cross_icon_blue       = `${imageBaseUrl}/assets/a8f54f64-011c-462b-8540-e07fde43b0cd`
export const cross_icon   = `${imageBaseUrl}/assets/7992065d-5c73-4ae0-9fe8-b14cb0338420`
export const disapprove_icon       = `${imageBaseUrl}/assets/b12ea1e8-b0a0-44da-b79b-500efc0da848`
export const down_arrow      = `${imageBaseUrl}/assets/7f98f62f-3f63-43dc-bb56-fcd8f860e5ed`
export const drop_arrow      = `${imageBaseUrl}/assets/925aeb15-ac77-4aff-9317-9469be25dfd9`
export const drop_down_arrow      = `${imageBaseUrl}/assets/79726c17-d7e0-444b-94be-2406ff8d398e`
export const edit_icon      = `${imageBaseUrl}/assets/5e0fb960-7776-4cd4-a2be-879f65836262`
export const Edit_white      = `${imageBaseUrl}/assets/478c7145-1e77-4b07-8ec1-5b30ef82255e`
export const Edit     = `${imageBaseUrl}/assets/e1747a36-0a33-42bc-8ed0-b6746162923f`
export const export_icon     = `${imageBaseUrl}/assets/05f0390c-237a-470c-9951-d096e08540d7`
export const facebook_strategy     = `${imageBaseUrl}/assets/ad009a1c-fe95-4ed6-bbc4-dd43bc0f3464`
export const form_complete_icon     = `${imageBaseUrl}/assets/a265439c-064d-4f70-8d13-522e23b79f56`
export const global_icon     = `${imageBaseUrl}/assets/0ac7ffac-1f29-4210-8b77-ff2e5b5a2ca3`
export const google_strategy_icon     = `${imageBaseUrl}/assets/0ac7ffac-1f29-4210-8b77-ff2e5b5a2ca3`
export const Green_Checked_Icon     = `${imageBaseUrl}/assets/278f0e5a-bd8a-40f2-84fc-51a2b42b594e`
export const info_icon     = `${imageBaseUrl}/assets/f77de7f4-66b1-4280-a292-1eb5e3fb0b85`
export const inspiration_icon     = `${imageBaseUrl}/assets/4b2dc3cc-36a7-4722-b6b7-1341c19f333a`
export const menu    = `${imageBaseUrl}/assets/b3be30b4-faa2-4d41-aef5-29efcf125dec`
export const mice_icon    = `${imageBaseUrl}/assets/3cd8bcfb-8359-4510-bd6f-0c75a9275a2c`
export const no_preview_icon    = `${imageBaseUrl}/assets/d608857b-6bfd-466c-a877-2ce147aeed39`
export const onboarding    = `${imageBaseUrl}/assets/8537a49d-fef4-4141-a144-0abda109a0a6`
export const pencil    = `${imageBaseUrl}/assets/80da6e56-d74a-4af4-9252-a12d21c702a9`
export const phone_icon    = `${imageBaseUrl}/assets/0e42c591-ba74-4a4d-a66b-227480684b7d`
export const preview_blue    = `${imageBaseUrl}/assets/fc78b992-843b-41b6-95ef-2da5b43ba253`
export const preview    = `${imageBaseUrl}/assets/e2634053-3a11-4139-bdf9-eb10575116cd`
export const pricing_icon    = `${imageBaseUrl}/assets/3ac2776d-165b-4c49-a5c7-bd24d2d423f5`
export const reject_fill    = `${imageBaseUrl}/assets/a3df975a-1c4f-4939-90a0-e68cc91d443e`
export const search_icon   = `${imageBaseUrl}/assets/ea5bf35b-458e-4eca-9b11-4809311bb773`
export const top_arrow   = `${imageBaseUrl}/assets/e2f23f01-7616-4552-9459-9181a64eec77`
export const trash  = `${imageBaseUrl}/assets/a5399955-17a7-49e3-a93b-e7a60a4c0b1f`