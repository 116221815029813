import { useDispatch } from 'react-redux';
import { goBackHDMAmCompo } from '../../../api/HDMAM/componentTracking/action';
import { onNextHDM } from '../GoToNextCompoButton';
import CommonAuditAdset from './CommonAuditAdset';

export default function HDMAuditAdset() {
  const dispatch = useDispatch();

  const onBack = () => {
    dispatch(goBackHDMAmCompo())
  };
  
  return (
    <CommonAuditAdset
      onBack={onBack}
      onNext={() => onNextHDM(dispatch)}
    />
  );
}