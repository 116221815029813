import React, { useState, useEffect } from "react";
import {notes_icon as notesIcon} from "../../../assets/icons/proposal/proposal";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import {ready as readyGif} from "../../../assets/icons/proposal/proposal";
import { suggestChangesApiStrategyDeck } from "../../../api/HDMClient/saga";
import { goToSpecificCompoHDMClient } from "../../../api/HDMClient/componentTracking/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 584,
  bgcolor: "background.paper",
  borderRadius: "20px",
  padding: "46px 47px 42px",
  boxShadow: 24,
};
const SuggestChangesHDMDeck = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const suggestInputRedux = useSelector(
    (state) => state.hdmClient.strategyIterations
  );
  const [suggestInput, setSugggestInput] = useState(suggestInputRedux);
  const suggestChangesDeck = () => {
    suggestChangesApiStrategyDeck(suggestInput, suggestInputRedux).then((res) => {
      dispatch({
        type: "UPDATE_WHOLE_HDM_CLIENT_FORMID",
        payload: res.data,
      })
      setModal(!modal);
    });
  };
  useEffect(() => {
    setSugggestInput(suggestInputRedux);
  }, [suggestInputRedux]);
  useEffect(() => {
    dispatch({
      type: "SUGGEST_CHANGES_INPUT",
      payload: { value: suggestInput },
    });
  }, [suggestInput]);
  return (
    <>
      <div
        className="changes_proposal_layout"
        style={{ transform: "translateY(70px)" }}
      >
        <div className="changes_notes_icon">
          <img src={notesIcon} />
        </div>
        <p className="notes_text">Enter your</p>
        <h3 className="notes_title">Notes Here</h3>
        <div className="notes_textarea_wrapper">
          <textarea
            className="notes_textarea"
            placeholder="400 characters"
            onChange={(e) => setSugggestInput(e.target.value)}
          ></textarea>
        </div>
        <div>
          <PrimaryButton
            title="Suggest Changes"
            onClick={() => suggestChangesDeck()}
          />
        </div>
      </div>

      <Modal
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="status_popup_layout">
            <div className="modal_icon">
              <img src={readyGif} />
            </div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Changes Suggested!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Please wait while we review the changes you asked for.
            </Typography>
            <div className="action_popup">
              <PrimaryButton
                title="Go Home"
                type="bordered"
                onClick={() => dispatch(goToSpecificCompoHDMClient(0))}
              />
              <PrimaryButton
                title="View Strategy"
                onClick={() => dispatch(goToSpecificCompoHDMClient(66))}
              />
            </div>
            <a
              className="close_button"
              onClick={() => {
                setModal(!modal);
                dispatch(goToSpecificCompoHDMClient(71));
              }}
              style={{ cursor: "pointer" }}
            >
              <span></span>
            </a>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default SuggestChangesHDMDeck;
