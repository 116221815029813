import React, { useEffect, useState } from "react";
import {pixis_icon as pixisIcon} from "../../../assets/icons/icon";
import backgroundImage from "../../../assets/images/leftsidepanelbg.svg";
import {home_icon as homeIcon }from "../../../assets/icons/icon";
import{more_info_icon as  moreInfoIcon} from "../../../assets/icons/icon";
import alertIcon from "../../../assets/icons/alert-icon.svg";
import {profile_icon as profileIcon} from "../../../assets/icons/icon";
import {arrow_down_icon as arrowDown} from "../../../assets/icons/icon";
import { Box, Container, Tabs, Typography, Grid, Divider } from "@mui/material";
import {updateNav} from "../../../api/HDMClient/componentTracking/reducer";
import { logout } from "../../../api/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import "./HDMNavigationSteps.css";
import{log_out as  logOutIcon} from "../../../assets/icons/icon";
import { goToSpecificCompoHDMClient } from "../../../api/HDMClient/componentTracking/action";
import {checkForAccess} from "../../../utils/hdmUtil";
import { dbStatus } from "../../ClientDashboard/HomePage/HomePage";
import {useAuth0} from "@auth0/auth0-react";
import { showAnalyticsDashboard } from "../../../utils/clientDashboardIdsUtil";

export const campaignPreviewFacebookComplete = (generatedCampaignsInfo) => {
  const campaignTypeList={
     ["middle_of_the_funnel"]:false,
     ["top_of_the_funnel_lookalike"]:false,
     ["bottom_of_the_funnel"]:false,
     ["top_of_the_funnel_interest"]:false
  };
  generatedCampaignsInfo?.forEach((campaign) => {
    if(campaign.responseData.status === "SUCCESS"){
      campaignTypeList[campaign.campaignType]=true;
    }
  });
  let count=0;
  Object.values(campaignTypeList).forEach((value) => {
    if(value){ count++; }
  });
  if(count === Object.values(campaignTypeList).length){
    return true;
  }else{
    return false;
  }
}
const HDMNavigationSteps = ({ navigateSteps, active, dashboard }) => {
  const clientState = useSelector((state) => state.hdmClient);
  const currentCompo = useSelector(
    (state) => state.hdmClientCurrentComp.currentComp
  );
  const [profilePopUp, setProfilePopUp] = useState(false);
  const currentNavIndex = navigateSteps?.findIndex((nav) => nav.id === active);
  const [navigateStepsWithStatus, setNavigateStepsWithStatus] = useState([]);

  const [completedNavbarList, setCompletedNavbarList] = useState(
    navigateSteps?.map((nav, index) =>
      currentNavIndex - 1 >= index ? true : false
    )
  );
  const generatedCampaignsInfo = clientState?.generatedCampaignsInfo;
  const dispatch = useDispatch();
  const {logout:logoutAuth0} = useAuth0();
  const logoutFromUser = () => {
    logoutAuth0({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } });
    dispatch(logout());
  };
  const isOnboardingCompleted = clientState.onboardingInfo.status;
  const accessSharing = clientState.accessSharing.status;
  const proposalStatus = clientState.proposalStatus;
  const platformSelected = clientState?.onboardingInfo?.clientAccess?.platforms;
  console.log("clientState",clientState)
  useEffect(() => {
    const tempArr = [];
    navigateSteps.forEach((nav, index) => {
      //Onboarding Form
      if (isOnboardingCompleted === "COMPLETED" && index === 0) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          name: nav.name,
          imgIcon: nav.imgIcon,
          id: nav.id,
        });
      }  else if ((isOnboardingCompleted === "ONGOING" || updateNav(currentCompo) === nav.id) && index === 0) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }else if (isOnboardingCompleted === "PENDING" && index === 0) {
        tempArr.push({
          status: "INCOMPLETED",
          text: "To be Unlocked",
          name: nav.name,
          imgIcon: nav.imgIcon,
          id: nav.id,
        });
      }
      //Read Access
      else if ((checkForAccess(clientState) === dbStatus.READ_ACCESS ||  checkForAccess(clientState) === dbStatus.COMPLETE_ACCESS) && isOnboardingCompleted === "COMPLETED" && index === 1) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          name: nav.name,
          imgIcon: nav.imgIcon,
          id: nav.id,
        });
      } else if ((accessSharing === "ONGOING" || isOnboardingCompleted === "COMPLETED") && index === 1) {
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      } 
      //onboarding doc
      else if (proposalStatus === "COMPLETED" && index === 2) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }else if((checkForAccess(clientState) === dbStatus.READ_ACCESS ||  checkForAccess(clientState) === dbStatus.COMPLETE_ACCESS) && isOnboardingCompleted === "COMPLETED" && index === 2){
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //strategy deck
      else if((clientState && clientState?.strategyIterations && clientState?.strategyIterations.length !== 0 && clientState?.strategyIterations[clientState?.strategyIterations.length-1].state === "APPROVED") && index === 3){
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      else if(proposalStatus === "COMPLETED" && index === 3){
        tempArr.push({
          status: "CURRENT",
          text: "Ongoing",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      //campaign 
      else if ((platformSelected.findIndex(platform => platform.platform === "facebook-ads") !== -1) && (clientState && showAnalyticsDashboard(clientState)) && index === 4) {
        tempArr.push({
          status: "COMPLETED",
          text: "Completed",
          name: nav.name,
          imgIcon: nav.imgIcon,
          id: nav.id,
        });
      }
      // else if ((platformSelected.findIndex(platform => platform.platform === "google-ads") !== -1) && ((clientState && clientState?.strategyIterations && clientState?.strategyIterations.length !== 0 && clientState?.strategyIterations[clientState?.strategyIterations.length-1].state === "APPROVED")) && index === 4) {
      //   tempArr.push({
      //     status: "COMPLETED",
      //     text: "Completed",
      //     name: nav.name,
      //     imgIcon: nav.imgIcon,
      //     id: nav.id,
      //   });
      // }
      //by default
      else if(index === 5 && showAnalyticsDashboard(clientState)){
        tempArr.push({
          status: "COMPLETED",
          text: "",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
      else {
        tempArr.push({
          status: "INCOMPLETED",
          text: index === 5 ? "" : "To be Unlocked",
          imgIcon: nav.imgIcon,
          name: nav.name,
          id: nav.id,
        });
      }
    });
    setNavigateStepsWithStatus(tempArr);
  }, [active, isOnboardingCompleted, currentCompo,clientState]);
  return (
    <Box
      style={{
        background: `url(${backgroundImage}),white`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPositionY: -38,
        height: "100vh",
      }}
    >
      <div className="sidebar_layout">
        <div className="logoContainer">
          <img src={pixisIcon} alt="" />
          <Typography
            variant="h4"
            component="h3"
            style={{
              color: "#0869FB",
              marginTop: 10,
            }}
          >
            Onboarding
          </Typography>
          {/* <h2>Automations</h2> */}
        </div>
        {navigateStepsWithStatus.length !== 0 && (
          <div className="steps">
            <div className="steps_inner">
              {navigateStepsWithStatus?.map((step, key) => (
                <div className="stepHelper" key={key}>
                  <div className="step" id={step.id}>
                    <div
                      className={
                        step.status === "COMPLETED"
                          ? "question-logo completedQuest"
                          : step.status === "CURRENT"
                          ? "question-logo selectedBackground"
                          : "question-logo"
                      }
                    >
                      <span className="left_nav_icon"></span>
                    </div>
                    <div className="step-description">
                      <Typography
                        style={{
                          marginBottom: 0,
                          fontSize: "14px",
                          color:
                            step.status === "CURRENT" ||
                            step.status === "COMPLETED"
                              ? "#000000"
                              : "#adadad",
                        }}
                      >
                        {step.name}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: 0,
                          fontSize: 12,
                          color: "#aeaeae",
                        }}
                      >
                        {step.text}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {profilePopUp && (
          <div
            style={{
              width: 200,
              textAlign: "center",
              height: "auto",
              position: "absolute",
              bottom: "5rem",
              right: "0rem",
              backgroundColor: "white",
              // border: '1px solid #d3d3d3',
              zIndex: 1,
              cursor: "pointer",
              background: "#FFFFFF",
              border: "0.35px solid #E9E9E9",
              boxShadow: "0px 7px 80px rgba(0, 0, 0, 0.05)",
              borderRadius: "8px 8px 0px 8px",
              padding: "0.3rem",
            }}
          >
            <p
              style={{
                paddingBottom: "1rem",
                paddingRight: "inherit",
                paddingLeft: "inherit",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                gap: 8,
                marginBottom: 0,
              }}
              onClick={() => logoutFromUser()}
            >
              {localStorage.getItem("brandId")}
              <div
                style={{
                  width: 20,
                  height: 20,
                }}
              >
                <img src={logOutIcon} alt="" width={18} height={18} />
              </div>
            </p>
          </div>
        )}
        <div className="navigation_footer">
          <div className="nav-footer-icons">
            <div
              className="footer-icon"
              onClick={() => {
                if (dashboard) {
                  window.location.href = "/hdmclient";
                } else {
                  dispatch(goToSpecificCompoHDMClient(0));
                  window.location.href = "/hdmclient";
                }
              }}
              style={{
                backgroundColor: currentCompo === 0 ? '#0769FB' : '#eee',
              }}
            >
              <img src={homeIcon} alt="" />
            </div>
            <div className="footer-icon">
              <img src={moreInfoIcon} alt="" className="moreInfoIcon-img" />
            </div>
            <div className="footer-icon">
              <img src={alertIcon} alt="" />
            </div>
            <div
              className="footer-icon profile-icon"
              onClick={() => setProfilePopUp(!profilePopUp)}
            >
              <img src={profileIcon} alt="" />
              <img src={arrowDown} alt="" className="arrow-img" />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default HDMNavigationSteps;
