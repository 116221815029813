const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const add_field_icon  = `${imageBaseUrl}/assets/511fc566-0aeb-4cd3-ab36-2558744c2cc5` 
export const adset_demo = `${imageBaseUrl}/assets/da282f0e-3413-449f-90fa-007a4ba75ba4`
export const alert_icon = `${imageBaseUrl}/assets/d1067d32-384b-4bfd-bbfc-3086a571ba2f`
export const approval_icon = `${imageBaseUrl}/assets/c4d378d0-c813-4664-b0f2-20308ad85bee`
export const arrow_back_nb = `${imageBaseUrl}/assets/bdf28de2-c54b-4593-8ff6-d741878f0259`
export const arrow_down_icon = `${imageBaseUrl}/assets/28385707-bd0c-434c-8c56-e1d8d012ab52`
export const back_arrow = `${imageBaseUrl}/assets/5442b9a9-957f-4a05-9e42-1a5da812d7a1`
export const background_nextSteps = `${imageBaseUrl}/assets/793cc6a7-4331-44e6-ae4d-9bea1e9ce617`
export const background_bonus = `${imageBaseUrl}/assets/cea9df84-3372-4930-b3f5-b0ad27c7a175`
export const background_communicationAndReporting = `${imageBaseUrl}/assets/f33a55bd-0af0-4be0-8d79-3e2689643076`
export const background_facebookTracking= `${imageBaseUrl}/assets/4cab8aee-fdf1-4889-afc7-242b28af1617`
export const background_WhatWeNeedFromYou= `${imageBaseUrl}/assets/9261d8b0-208c-4f54-a4eb-ece67ce748b3`
export const background_1= `${imageBaseUrl}/assets/526595cc-e457-462a-aa11-6c7bd2bd1f34`
export const background_2= `${imageBaseUrl}/assets/5ff0ca4e-e249-426c-b74a-97b8a6b9e6b9`
export const blue_platform_icon= `${imageBaseUrl}/assets/4572f168-2f81-4da4-83b4-90e1d459c8eb`
export const brand_nav_illustration= `${imageBaseUrl}/assets/4922085c-8438-4989-98a3-8af0a22c8125`
export const camera= `${imageBaseUrl}/assets/53948b47-6e63-410c-87ee-3923e6eca0a4`
export const checked_icon= `${imageBaseUrl}/assets/d0a117b4-b52d-4bfc-b23d-c024d913c136`
export const clear_ads_icon_hover= `${imageBaseUrl}/assets/7a3d702f-ed4c-4c61-b558-c45bf4c2d7fc`
export const complete_access_icon= `${imageBaseUrl}/assets/1d32ae42-1cf7-4e69-9f9b-cb25d004ea1f`
export const cro_pricing_icon= `${imageBaseUrl}/assets/8686669c-6620-4c91-a471-d5a32613731b`
export const delete_icon= `${imageBaseUrl}/assets/8ef3e5b4-b559-43b5-8711-07d2dc0398d4`
export const edit_pencil_icon= `${imageBaseUrl}/assets/49ffb929-83f6-4e03-a2e2-e327779207cc`
export const editicon= `${imageBaseUrl}/assets/31e04163-da0b-4dc8-af36-a3f978b3a12e`
export const expectationHDMAM= `${imageBaseUrl}/assets/1fca668a-773a-456f-a7d6-6421da13da91`
export const facebook_pricing_icon= `${imageBaseUrl}/assets/2ea9c9b2-cfbf-4240-9c60-e90d6e5768e1`
export const facebook= `${imageBaseUrl}/assets/8fdb0a94-a651-481c-be80-575494cf8406`
export const fileUpload= `${imageBaseUrl}/assets/53759d2b-5853-4b7f-a046-6783b4e959f1`
export const form_icon= `${imageBaseUrl}/assets/1a315909-e306-4901-b326-55f1ec03220b`
export const google_icon_big= `${imageBaseUrl}/assets/f70710f6-6c4c-4845-b722-124adf6a5bb0`
export const google_pricing_icon= `${imageBaseUrl}/assets/57961fde-f5d1-4ef3-8c04-a15cc3e1dfe6`
export const google_analytics= `${imageBaseUrl}/assets/48bc6a5e-8dfb-4a2e-bdcc-9781940790cb`
export const google_icon= `${imageBaseUrl}/assets/ead9fc35-4b9f-41ee-82af-d5699190d039`
export const green_preview_eye= `${imageBaseUrl}/assets/2d11d21d-b098-43c1-99bc-1a71af43554a`
export const home_icon= `${imageBaseUrl}/assets/80f3f8a4-bd1a-4527-8ae2-6ecc11b016bb`
export const link_arrow= `${imageBaseUrl}/assets/866dd07a-8b50-4911-83d3-ac3b019224c1`
export const log_out= `${imageBaseUrl}/assets/f6e16942-0dc4-4bdf-9fd3-44d3be6899f7`
export const man_give_presentation= `${imageBaseUrl}/assets/82fd5f26-7efd-4b58-b70a-a011c12083c7`
export const more_info_icon= `${imageBaseUrl}/assets/84b20750-701f-4df1-b8af-49eeec05c164`
export const next_arrow_slider= `${imageBaseUrl}/assets/e5b709eb-4cae-4319-a617-3c6e235a947d`
export const onBoardHDMAM= `${imageBaseUrl}/assets/ecb39940-6502-4fc8-928a-7689b7d46f17`
export const onBoardPhaseHDMAM= `${imageBaseUrl}/assets/b5255c69-c49e-4396-b22f-af8fc65ca01c`
export const pixis_icon= `${imageBaseUrl}/assets/58f95cd0-e1f2-4d64-9495-ebdc2e78bca0`
export const pixis= `${imageBaseUrl}/assets/c8914d39-bdee-4afe-8dab-056ca5768668`
export const popup_close_button= `${imageBaseUrl}/assets/3833d5ff-7a01-4451-aee9-26718aaace62`
export const prev_arrow_slider= `${imageBaseUrl}/assets/09f62a50-67cb-4330-ae3d-ec41fd2e1d2c`
export const preview_icon_ad= `${imageBaseUrl}/assets/0951bcc0-0a34-43ff-a9c5-cf5057665c2b`
export const pricing_icon= `${imageBaseUrl}/assets/5caf30d9-ceb3-4aec-a679-e9bdb8906c54`
export const profile_icon= `${imageBaseUrl}/assets/cc6f1fd3-81e4-4d92-9ced-38542ff2476f`
export const proposal_icon= `${imageBaseUrl}/assets/ed2f11a7-1090-4b6d-bc2d-2478baa1a9a2`
export const proposalIcon= `${imageBaseUrl}/assets/6f18322f-faf3-4f82-b4af-4184d23f6624`
export const questionairre_icon= `${imageBaseUrl}/assets/71c259f8-aafa-4b24-8898-bb193362f0db`
export const readaccess_icon= `${imageBaseUrl}/assets/777df0a6-7e83-4fb7-9cfe-267254c4516f`
export const redirect_new_icon= `${imageBaseUrl}/assets/1739933e-ecc3-4b9c-94e0-c93f336aeb5a`
export const reviewHDMAM= `${imageBaseUrl}/assets/ebef7cdd-cfe0-4a7a-a597-313f746e326f`
export const right_arrow= `${imageBaseUrl}/assets/33b8a0ad-c858-47d6-a318-b9902d20011c`
export const search= `${imageBaseUrl}/assets/9cc37b11-a772-4eb3-8028-7d1530c300b9`
export const separator= `${imageBaseUrl}/assets/3a5bf94e-e74b-45dd-838b-e500644a1fde`
export const sidenav_bg= `${imageBaseUrl}/assets/11bc737a-b6c7-4960-bc4d-8f321135f39e`
export const strategy_icon= `${imageBaseUrl}/assets/999eefc-b1bb-4c81-8204-e11d304a702b`
export const trash_action= `${imageBaseUrl}/assets/72868b8d-a46c-4230-a9c6-5b1fbca69703`
export const trash= `${imageBaseUrl}/assets/e19fee5e-bcf8-4931-a5a8-fee4ec30610c`
export const up_arrow_button_icon= `${imageBaseUrl}/assets/27a46731-92ba-495f-9e20-d41013769624`
export const waiting= `${imageBaseUrl}/assets/57df54fd-3522-457c-8c02-a2ff0a0d2e4e`
export const waitingIcon2= `${imageBaseUrl}/assets/7011bf6b-e5d1-4b0e-a3d4-0b4b2102e267`
export const timeprocess= `${imageBaseUrl}/assets/71d4f93f-4ab3-4e50-81a1-c1f8719a39d1`