import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccount } from "../../api/analytics/action";
import { getFacebbokAccounts } from "../../api/HDMAM/audit/action";
import { getLocalStorage } from "../../api/localStorage/localStorage";
import {
  HDMAccountLeftSideBar,
  HDMCLientLeftSidebar,
  LeftSidebar,
  AccountLeftSideBar,
} from "../../utils/navbarUtil";
import roles from "../../utils/roles";
import ClientNavigationSteps from "../ClientDashboard/NavigationSteps";
import ManagerNavigationSteps from "../AccountManager/NavigationSteps";
import NavigationSteps from "../HDMAccountManager/NavigationSteps";
import HDMNavigationSteps from "../HDMClientDashboard/HDMNavigationSteps";
import Dashboard from "./Dashboard/Dashboard";
// import "./styles.scss";
const AnalyticDashboard = () => {
  const role = getLocalStorage("role");
  const agency = ["2", "13"].includes(getLocalStorage("agencyId")) ? "HDM" : "LD";
  const currentLeftSidebar = useSelector((state) =>
    agency === "LD"
      ? role === roles.CLIENT
        ? state.clientCurrentComp.currentLeftSidebar
        : state.amCurrentComp.currentLeftSidebarAm
      : role === roles.HDM_CLIENT
        ? state.hdmClientCurrentComp.currentLeftSidebar
        : state.hdmAMCurrentComp.currentLeftSidebarAm
  );

  const platforms = useSelector(state => agency === "LD" ?
    state.client.onboardingInfo.platformInfo.platforms :
    state.hdmClient.onboardingInfo.clientAccess.platforms) ?? []

  const { customerId, customerName } = useSelector(state => state[agency === "LD" ? "client" : "hdmClient"].clientGoogleAdsInfo) ?? {}
  const { fbAdsAccountId, fbAdsAccountName } = useSelector(state => state.client) ?? {}

  // need to change
  const fbAccounts = useSelector(state => state.hdmAudit.facebookAccounts)
  const fbAccId = useSelector(state => state.hdmClient.accessSharing?.fbAccess?.adAccount?.accountId)

  const dispatch = useDispatch();
  useEffect(() => {
    // need to be removed
    dispatch(getFacebbokAccounts());
    dispatch(
      selectAccount({
        channel: "google-ads",
        account: platforms.find(platform => platform.platform === "google-ads") ? {
          id: customerId,
          name: customerName
        } : null,
      })
    );
    dispatch(
      selectAccount({
        channel: "facebook-ads",
        account: platforms.find(platform => platform.platform === "facebook-ads") ? (agency === "LD" ? {
          id: fbAdsAccountId,
          name: fbAdsAccountName
        } : {
          id: fbAccId,
          name: fbAccounts.find(acc => acc.accountId === fbAccId)?.name
        }) : null
      })
    );
  }, []);

  console.log("in analytics", agency, role);
  return (
    <main class={`main_layout`}>
      <section className="nav_section">
        {agency === "LD" ? (
          role === roles.CLIENT ? (
            <ClientNavigationSteps
              navigateSteps={LeftSidebar}
              active={currentLeftSidebar}
              dashboard={true}
            />
          ) : (
            <ManagerNavigationSteps
              navigateSteps={AccountLeftSideBar}
              active={currentLeftSidebar}
            />
          )
        ) : role === roles.HDM_CLIENT ? (
          <HDMNavigationSteps
            navigateSteps={HDMCLientLeftSidebar}
            active={currentLeftSidebar}
            dashboard={true}
          />
        ) : (
          <NavigationSteps
            navigateSteps={HDMAccountLeftSideBar}
            active={currentLeftSidebar}
          />
        )}
      </section>
      <section
        className={`${currentLeftSidebar ? "top_nav_item" : ""} right_layout`}
      >
        <Dashboard brand={agency === "LD" ? "google-ads" : "facebook-ads"} />
      </section>
    </main>
  );
};

export default AnalyticDashboard;

//For using Iframe
{
  /* <iframe
    style={{ borderRadius: "10px" }}
    src={`https://analytics-dev.pixis.ai/dashboard/`}
    width="100%"
    height="100%"
></iframe>  */
}
