import React, {useEffect, useState} from "react";
import {pixis as PixisIcon} from "../../assets/icons/icon";
import {Button, Grid, Typography} from "@mui/material";
import SignInIcon from "../../assets/images/SignIn.svg";
import {userDetailsApi} from "../../api/auth/saga";
import {setLocalStorage} from "../../api/localStorage/localStorage";
import jwt_decode from "jwt-decode";
import roles from "../../utils/roles";
import {getHDMForms} from "../../api/HDMClient/saga";
import {getFormIdWhileLogin} from "../../api/client/saga";
import {useAuth0} from "@auth0/auth0-react";

const LoginPage = (props) => {
    const {resetError} = props;

    const {
        isAuthenticated,
        isLoading,
        getAccessTokenSilently,
        loginWithRedirect,
    } = useAuth0();

    const [state, setState] = React.useState({
        email: "",
        password: ""
    });
    const [errors, setErrors] = React.useState({
        email: null,
        password: null
    });
    const [dbloginError, setDbLoginError] = useState("");
    const [modal, setModal] = useState(false);
    const [proposals, setProposals] = useState([]);
    const validateData = property => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        switch (property) {
            case "email":
                if (state.email.length === 0) {
                    return "Email is required";
                } else if (!emailRegex.test(state.email)) {
                    return "Invalid Email";
                }
                break;
            case "password":
                if (state.password.length === 0) {
                    return "Password is required";
                }
                break;
            default:
                return null;
        }
        return null;

    }

    const [isLoadingLogin, setIsLoadingLogin] = useState(isAuthenticated);

    useEffect(() => {
        setIsLoadingLogin(isAuthenticated)
        const getAccessToken = async () => {
            return await getAccessTokenSilently()
        }
        if (isAuthenticated) {
            getAccessToken().then((access_token) => {
                setLocalStorage("accessToken", access_token);
                userDetailsApi().then((res) => {

                    const {sub, role, agencyId, brandId, amName} = jwt_decode(
                        res.accessToken
                    );


                    // setLocalStorage("accessToken", res.access_token);
                    setLocalStorage("role", role);
                    setLocalStorage("sub", sub);
                    setLocalStorage("agencyId", agencyId);
                    setLocalStorage("brandId", brandId);
                    setLocalStorage("amName", amName);
                    if (roles.SUPER_ADMIN === role) {
                        window.location.href = "/admin";
                    } else if (roles.ACCOUNT_MANAGER === role && (agencyId == 1 || agencyId == 12)) {
                        // getProposals().then((res) => {
                        //   setModal(!modal);
                        //   setProposals(res.values);
                        // });
                        setLocalStorage("isNextClicked", true);
                        window.location.href = "/brands"
                    } else if (roles.CLIENT === role && (agencyId == 1 || agencyId == 12)) {
                        getFormIdWhileLogin().then((res) => {
                            res.values.length &&
                            setLocalStorage(
                                "formId",
                                res.values.length && res.values[0]._id
                            );
                            window.location.href = "/client";
                        });
                    } else if (roles.HDM_CLIENT === role && (agencyId == 2 || agencyId == 13)) {
                        getHDMForms().then((res) => {
                            res.values.length === 1 &&
                            setLocalStorage(
                                "formId",
                                res.values.length === 1 && res.values[0]._id
                            );
                            window.location.href = "/hdmclient";
                        });
                    } else if (roles.HDM_ACCOUNT_MANAGER === role && (agencyId == 2 || agencyId == 13)) {
                        window.location.href = "/hdmaccountmanager";
                    }
                }).catch(err => {
                    setIsLoadingLogin(false);
                })
            })

        }
        return () => {
        }
    }, [isAuthenticated]);
    return (
        <div>
            <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
                style={{
                    minHeight: "100vh",
                    position: "relative",
                    backgroundColor: "#ffff",
                }}
            >
                <Grid
                    sx={{
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#FCFCFF",
                    }}
                    item
                    sm={7}
                    lg={7}
                >
                    <img style={{height: "60%"}} src={SignInIcon} alt="Login"/>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    sx={{
                        backgroundColor: "#fff",
                        textAlign: "center",
                        overflow: "auto",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                    item
                    sm={5}
                    xs={12}
                    lg={5}
                >
                    <Grid xs={12} md={7} lg={7} id="parent_id">
                        <Grid item xs={12}>
                            <img src={PixisIcon} style={{width: 100}} alt="Pixis icon"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                style={{color: "#0869FB"}}
                                variant="h4"
                                gutterBottom
                            >
                                Onboarding
                            </Typography>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid sx={{marginBottom: "30px"}} item xs={12}>
                            <Typography
                                fontSize={14}
                                style={{color: "#222222", fontSize: 14, opacity: 0.6}}
                                variant="subtitle1"
                                gutterBottom
                            >
                                Sign In to access your account
                            </Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <Button
                                // onClick={() => handleCustomLogin()}
                                onClick={loginWithRedirect}
                                style={{width: "100%", height: "50px", borderRadius: "8px"}}
                                variant="contained"
                                color="primary"
                                disabled={isLoadingLogin}
                            >
                                {isLoadingLogin?
                                    <>Signing In...</>:
                                    <>Sign In</>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <CreateNewProposal modal={modal} setModal={setModal} proposals={proposals}/> */}
            </Grid>
        </div>
    );
};
export default LoginPage;