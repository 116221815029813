import HDMAccessAM from "../../HDMClientDashboard/HDMReadAccess/HDMAccessAM";

const SharedAssetsAm = (props) => {
  return (
    <div className="main_hero">
      <HDMAccessAM 
        accessType={props.accessType}
        type={props.type}
      />
    </div>
  );
};
export default SharedAssetsAm;
