import PrimaryButton from '../ClientDashboard/ButtonPrimary/Buttons';
import { arrowDownIcon, warningIcon } from '../../assets/icons/creative/creative';
// import warningIcon from '../../assets/icons/creative/warning-icon.svg';
import { Button, Typography } from '@material-ui/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import FacebookLandingPage from '../CreativeResources/LandingPages/FacebookLandingPage';
import GoogleLandingPage from '../CreativeResources/LandingPages/GoogleLandingPage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { addLandingPage, fetchLandingPages, putLandingPages, updateLandingPageContent, updateLandingPageStatus, updateToBeSaved } from '../../api/creativeApproval/slice';
import { CREATIVE_OBJECT_STATUS_ENUM, PLATFORM_NAME } from '../../api/creativeApproval/enums';
import { prepareNewLandingPage } from '../../utils/creativeApprovalUtil';
const LandingPages = () => {
  const dispatch = useDispatch();
  const landingPages = useSelector((state) => state.creativeApproval.landingPages);
  const toBeSaved = useSelector((state) => state.creativeApproval.toBeSaved);
  const [newLandingPageUuid, setNewLandingPageUuid] = useState(null);
  const [addNewLandingPage, setAddNewLandingPage] = useState(false);
  const [openPending, setOpenPending] = useState(true);
  const [openApproved, setOpenApproved] = useState(false);
  const [openDisapproved, setOpenDisapproved] = useState(false);
  const scrollToRef = useRef();

  // cache landingPages
  const sortedLandingPages = useMemo(
      () => landingPages
              .slice()
              .sort((a, b) => b.updatedOn.localeCompare(a.updatedOn))
    , 
   [JSON.stringify(landingPages)]);

  useEffect(() => {
    dispatch(fetchLandingPages());
  }, []);

  useEffect(() => {
    if (toBeSaved) {
      // call api with put request
      dispatch(putLandingPages());
      dispatch(updateToBeSaved());
    }
  }, [toBeSaved]);

  const onSaveHandler = ({
    id,
    theme,
    campaignName,
    link
  }) => {
    dispatch(
      updateLandingPageContent({
        id,
        status: CREATIVE_OBJECT_STATUS_ENUM.PENDING,
        theme,
        campaignName,
        link
      })
    );
    if(newLandingPageUuid === id){
      setNewLandingPageUuid(null);
    }
    setOpenPending(true);
    setOpenDisapproved(false);
    setOpenApproved(false);
  };

  // this function wont be called from
  // account manager page
  const onApproveHandler = (id) => {
    dispatch(updateLandingPageStatus({
        id, status: CREATIVE_OBJECT_STATUS_ENUM.APPROVED
    }));
  };

  // this function wont be called from
  // account manager page
  const onDispproveHandler = (id) => {
    dispatch(updateLandingPageStatus({
        id, status: CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED
    }));
  };

  const onRemoveHandler = ({
    id,
    theme,
    campaignName,
    link
  }) => {
    dispatch(
      updateLandingPageContent({
        id,
        status: CREATIVE_OBJECT_STATUS_ENUM.REMOVED,
        theme,
        campaignName,
        link
      })
    );
    if(newLandingPageUuid === id){
      setNewLandingPageUuid(null);
    }
  };

  const fbAddNewLandingPage = () => {
    const newLandingPage = prepareNewLandingPage(PLATFORM_NAME.FACEBOOK);
    setNewLandingPageUuid(newLandingPage.id);
    setOpenPending(true);
    dispatch(addLandingPage([newLandingPage, ...landingPages]));
    setAddNewLandingPage(false);
    scrollToRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const googleAddNewAdCopies = () => {
    const newLandingPage = prepareNewLandingPage(PLATFORM_NAME.GOOGLE);
    setNewLandingPageUuid(newLandingPage.id);
    setOpenPending(true);
    dispatch(addLandingPage([newLandingPage, ...landingPages]));
    setAddNewLandingPage(false);
    scrollToRef.current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <div>
      {sortedLandingPages.length ? (
        <div>
          <div>
            <Accordion
              expanded={openPending}
              ref={scrollToRef}
              style={{
                marginBottom: 16,
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                style={{
                  background: '#F7FAFF',
                  height: '64px',
                  cursor: 'pointer',
                }}
                expandIcon={<ExpandCircleDownOutlined />}
              IconButtonProps={{
                onClick: () => {
                  setOpenPending(prev => !prev);
                },
              }}
              >
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  Pending
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {sortedLandingPages.some((creative) => {
                    return creative.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING;
                }) ? (
                  sortedLandingPages
                    .filter((creative) => {
                        return creative.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING
                    })
                    .map((ad, index) => {
                      if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                        return (
                       <FacebookLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={CREATIVE_OBJECT_STATUS_ENUM.PENDING}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={false}
                         link={ad.link || ''}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDispproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                      if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                        return (
                       <GoogleLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={CREATIVE_OBJECT_STATUS_ENUM.PENDING}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={false}
                         link={ad.link || ''}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDispproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                    })
                ) : (
                  <h1
                    style={{
                      fontFamily: 'Inter',
                      textAlign: 'center',
                      color: 'rgba(51,51,51,0.8)',
                      fontWeight: '500',
                      fontSize: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 6,
                    }}
                  >
                    <img src={warningIcon} alt='' width={20} height={20} />
                    No pending Landing Pages
                  </h1>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={openApproved}
              style={{
                marginBottom: 16,
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                style={{
                  background: 'rgba(26, 191, 161, 0.05)',
                  height: '64px',
                  cursor: 'pointer',
                }}
              IconButtonProps={{
                onClick: () => {
                  setOpenApproved(prev => !prev);
                },
              }}
                expandIcon={<ExpandCircleDownOutlined />}
              >
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  Approved
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {sortedLandingPages.some((creative) => {
                    return creative.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED;
                }) ? (
                  sortedLandingPages
                    .filter((creative) => {
                        return creative.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED
                    })
                    .map((ad, index) => {
                      if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                        return (
                       <FacebookLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={ad.status}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={false}
                         link={ad.link || ''}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDispproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                      if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                        return (
                       <GoogleLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={ad.status}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={false}
                         link={ad.link || ''}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDispproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                    })
                ) : (
                  <h1
                    style={{
                      fontFamily: 'Inter',
                      textAlign: 'center',
                      color: 'rgba(51,51,51,0.8)',
                      fontWeight: '500',
                      fontSize: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 6,
                    }}
                  >
                    <img src={warningIcon} alt='' width={20} height={20} />
                    No approved Landing Pages
                  </h1>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={openDisapproved}
              style={{
                marginBottom: 16,
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                style={{
                  background: 'rgba(232, 75, 71, 0.05)',
                  height: '64px',
                  cursor: 'pointer',
                }}
              IconButtonProps={{
                onClick: () => {
                  setOpenDisapproved(prev => !prev);
                },
              }}
                expandIcon={<ExpandCircleDownOutlined />}
              >
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  Disapproved
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {sortedLandingPages.some((creative) => {
                    return creative.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED;
                }) ? (
                  sortedLandingPages
                    .filter((creative) => {
                        return creative.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED
                    })
                    .map((ad, index) => {
                      if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                        return (
                       <FacebookLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={ad.status}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={false}
                         link={ad.link || ''}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDispproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                      if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                        return (
                       <GoogleLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={ad.status}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={false}
                         link={ad.link || ''}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDispproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                    })
                ) : (
                  <h1
                    style={{
                      fontFamily: 'Inter',
                      textAlign: 'center',
                      color: 'rgba(51,51,51,0.8)',
                      fontWeight: '500',
                      fontSize: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 6,
                    }}
                  >
                    <img src={warningIcon} alt='' width={20} height={20} />
                    No Disapproved Landing Pages
                  </h1>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 35,
            marginTop: '8rem',
          }}
        >
          <div
            style={{
              width: 200,
              height: 200,
              background: '#FFFFFF',
              border: '0.5px solid #E9E9E9',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.04)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0 40px 0 40px',
            }}
          >
            <img src={warningIcon} alt='' />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
            }}
          >
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 24,
                lineHeight: '27px',
              }}
            >
              No Landing Pages to show!
            </Typography>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '26px',
                color: '#6F6C90',
              }}
            >
              Please add some Landing Pages that can be approved by the client.
            </Typography>
          </div>
        </div>
      )}
      {addNewLandingPage && (
        <div
          style={{
            position: 'fixed',
            width: 256,
            height: 84,
            background: '#FFFFFF',
            border: '0.5px solid #E5E5E5',
            boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.05)',
            borderRadius: 8,
            padding: '12px 0',
            bottom: 64,
            right: 28,
            zIndex: 999,
          }}
        >
          <div onClick={fbAddNewLandingPage}>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '24px',
                paddingLeft: 16,
                marginBottom: 12,
                cursor: 'pointer',
              }}
            >
              Add New Facebook Landing Page
            </Typography>
          </div>
          <div onClick={googleAddNewAdCopies}>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '24px',
                paddingLeft: 16,
                cursor: 'pointer',
              }}
            >
              Add New Google Landing Page
            </Typography>
          </div>
        </div>
      )}
      <div className='preview_footer_row'>
        <div className='footer_con' style={{ justifyContent: 'end' }}>
          <Button
            style={{
              width: '172px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              textTransform: 'capitalize',
              background: '#0869FB',
              cursor: 'pointer',
            }}
            onClick={() => setAddNewLandingPage(!addNewLandingPage)}
            disabled={newLandingPageUuid}
          >
            Add Landing Page{' '}
            <img
              src={arrowDownIcon}
              alt='arrow-down'
              style={{
                marginLeft: 6,
                marginTop: 3,
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingPages;
