import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import './campaign-language-select.scss';
const MyChip = (props) => {
  return (
    <div className='custom_chips'>
      <span>{props.label}</span>
      <span className='close_tag' onClick={props.onDelete}></span>
    </div>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const useStyles = makeStyles({
  popper: { maxWidth: '356px !important' }, // a style rule
});
const CampainLocationSelect = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let selectedValues = [...props.locations];
  // let selectedValues = useSelector( (state) => state.client.intakeInfo.audiences.geoTargets);
  let locationOptions = useSelector((state) => state.campain.locationList);
  const [selectedTags, setSelectedTags] = useState(selectedValues);
  useEffect(() => {
    console.log('selectedTags :>> ', selectedTags);
    // dispatch({
    //   type: LOCATION_ADDED_TO_FORM,
    //   payload: selectedTags,
    // });
    if(props.setHasChange){
      props.setHasChange(true);
    }
    props.setLocations(props.campType, selectedTags);
  }, [selectedTags]);
  if (!locationOptions) {
    locationOptions = [];
  }
  if (selectedValues && !selectedValues.length) selectedValues = undefined;
  return (
    <Autocomplete
      multiple
      id='checkboxes-tags'
      options={locationOptions}
      disabled={props.disabled}
      defaultValue={selectedValues}
      disableCloseOnSelect
      classes={{
        popper: classes.popper,
      }}
      onChange={(event, values) => {
        setSelectedTags(values);
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <MyChip {...getTagProps({ index })} label={option.name} />
        ));
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.title, inputValue, {
          insideWords: true,
        });
        const parts = parse(option.title, matches);
        return (
          <li {...props} className='search_location_list'>
            <div className='location_list_head'>
              {' '}
              <p>Location</p> <p>Reach</p>
            </div>
            <div className='location_list_content'>
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? '#0869FB' : '#333333',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
              <span>{option.reach}</span>
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <div className='location_question_header'>
          <span className='search_icon'></span>
          <TextField
            style={{ padding: 0 }}
            {...params}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder={props.placeholder}
            variant='standard'
            onChange={(e) =>
              dispatch({
                type: 'GET_CAMPAIGN_LOCATION',
                payload: {
                  infoType: props.type,
                  value: e.target.value,
                },
              })
            }
          />
        </div>
      )}
      disablePortal={true}
      className='auto_location_search'
    />
  );
};

export default CampainLocationSelect;
