import {ready as readyGif} from "../../../assets/icons/proposal/proposal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: '100vh',
  position:"absolute",
  top:"0"
};
const GeneratingStrategyPage = () => {
  return (

    <Box style={style}>
      <div id="working_proposal" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", paddingRight: '0px', width: '100%' }}>
        <div className="proposal_status" style={{ display: "flex", justifyContent: "center", alignItems: "center", background: '#ffffff', paddingRight: '0px', width: '100%', height: '100%', borderRadius: "8px" }}>
          <div className="proposal_status_layout">
            <span className="setIcon"><img src={readyGif} /></span>
            <h2 className="proposal_status_title">Generating Keywords and Ads</h2>
            <p style={{ fontFamily:'Lato',fontSize:'16px',lineHeight:'25.5px',fontWeight:'400',color: "#6F6C90" }}>Please sit back and browse our homepage, while we work on generating content for your adsets and campaigns. 
               We will notify you once they are ready to be sent for approval.</p>
          </div>
        </div>
      </div>
    </Box>
  )
}
export default GeneratingStrategyPage;

