import React from 'react';
import { Typography } from '@mui/material';
import textFieldIcon from '../../../assets/icons/campaign-approval/text-field-icon.svg';
import campaignLinkIcon from '../../../assets/icons/campaign-approval/campaign-link-icon.svg';
const CampaignTypePreview = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        minWidth: '681px',
        minHeight: '134px',
        margin: 'auto',
        marginTop: '2rem',
        paddingBottom: props.title === 'Services Campaign' ? '1.75rem' : '',
      }}
    >
      <div className='indicator'></div>
      <div className='campaign-preview-type-container'>
        <div className='campaign-link-container' onClick={() => {
          window.open(props.campaignURL, '_blank', 'noreferrer')
        }}>
          <img src={campaignLinkIcon} alt='' />
        </div>
        <div className='campaign-preview-type-field-header'>
          <Typography className='campaign-preview-type-field-header__title'>
            {props.title}
          </Typography>
        </div>
        <div className='campaign-form-container'>
          <div className='campaign-form-element'>
            <div className='icon-container'>
              <img src={textFieldIcon} alt='' />
            </div>
            <div className='form-input-element'>
              <Typography className='form-input-label'>Campaign ID</Typography>
              <Typography className='campaign-preview-value'>
                {props.campaignID}
              </Typography>
            </div>
          </div>
          <div className='campaign-form-element'>
            <div className='icon-container'>
              <img src={textFieldIcon} alt='' />
            </div>
            <div className='form-input-element'>
              <Typography className='form-input-label'>
                Campaign Name
              </Typography>
              <Typography className='campaign-preview-value'>
                {props.campaignName}
              </Typography>
            </div>
          </div>
          <div className='campaign-form-element'>
            <div className='icon-container'>
              <img src={textFieldIcon} alt='' />
            </div>
            <div className='form-input-element'>
              <Typography className='form-input-label'>Campaign URL</Typography>
              <Typography className='campaign-preview-value' style={{cursor: 'pointer'}} onClick={() => {
                window.open(props.campaignURL, '_blank', 'noreferrer')
              }}>
                {props.campaignURL}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTypePreview;
