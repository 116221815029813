import { useDispatch, useSelector } from 'react-redux';
import CommonAuditAccount from './CommonAuditAccount';
import { useCallback } from 'react';
import { getAlreadyAuditHdm } from '../../../api/HDMAM/audit/saga';
import { isPlatformSelected } from '../../../utils/accountManagerUtil';
import { goToSpecificCompoClient } from '../../../api/componentTracking/action';
import { onNextLD } from '../GoToNextCompoButton';

export default function LDAuditAccount() {
  const dispatch = useDispatch();

  const form = useSelector((state) => state.form.form);
  let ldCurrentCompo = useSelector(
    (state) => state.amCurrentComp.currentCompAm
  );

  const onFBAccountIdExists = useCallback(() => {
    return getAlreadyAuditHdm("LD").then((res) => {
      if (res.length !== 0) {
        const account = res.find(
          (account) => account.accountId === form?.fbAdsAccountId
        );
        dispatch({
          type: "GET_AUDIT_DETAILS_HDM",
          payload: account
            ? account.performanceData
            : res[res.length - 1].performanceData,
        });
      }
    });
  }, [dispatch, form?.fbAdsAccountId]);

  const onBack = () => {
    if (isPlatformSelected(form, "google-ads") !== -1) {
      dispatch(goToSpecificCompoClient(16));
    } else {
      dispatch(goToSpecificCompoClient(15));
    }
  };
  
  const spends =
    (
      (form?.onboardingInfo?.platformInfo?.platforms || []).find(
        (platform) => platform.platform === "facebook-ads"
      ) || {}
    ).spends || 0;

  const onAuditAccountChange = useCallback(() => {
    dispatch(goToSpecificCompoClient(ldCurrentCompo - 1))
  }, [dispatch, ldCurrentCompo]);

  return (
    <CommonAuditAccount
      form={form}
      onFBAccountIdExists={onFBAccountIdExists}
      onBack={onBack}
      spends={spends}
      onAuditAccountChange={onAuditAccountChange}
      onNext={() => onNextLD(dispatch)}
    />
  )
}