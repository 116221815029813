import { all, put, takeLatest, select, delay } from "redux-saga/effects";
import {api} from "../../index";

function* facebookAccounts() {
    try{
        const res=yield api.get("/facebook-ads/ad-accounts");
        yield put({
            type: "GET_FACEBOOK_ACCOUNTS",
            payload:res.data.accounts
        });
    }catch(err){
        console.log(err);
    }
}

export const getAlreadyAuditHdm=(agencyType)=>{
    return new Promise(async (resolve,reject) => {
       try{
           const res=await api.get("/facebook-audit/"+localStorage.getItem(agencyType === "LD" ? "formId" : "amSelectedFormId"));
           resolve(res.data.summaries);
       }catch(err){
           reject(err);
       }
    });
}

export const getAuditFacebook=(obj,agencyType)=>{
    return new Promise(async (resolve,reject) => {
       try{
           const res=await api.post("/facebook-audit/"+localStorage.getItem(agencyType === "LD" ? "formId" : "amSelectedFormId"),obj,false);
           resolve(res.data.response);
       }catch(err){
           reject(err);
       }
    });
}

export const hitTargetingKeywordsApi = () => {
    return new Promise(async (resolve,reject) => {
        try{
            const res=await api.post("/targeting-keywords/fb-ads/"+(localStorage.getItem("amSelectedFormId") ? localStorage.getItem("amSelectedFormId") : localStorage.getItem("formId")) ,{},false);
            resolve(res.data.response);
        }catch(err){
            reject(err);
        }
    });
}

export const isAccountValid = (accountId) => {
    return new Promise(async (res,rej)=>{
        try{
           const data=await api.post("/facebook-audit/initialise?account_id="+accountId);
           res(data);
        }catch(err){
           rej(err);
        }
    });
}

export const isMetaDataArrivedFacebook = (accountId) => {
    return new Promise(async (response,rej)=>{
        try{
           const res=await api.get("/facebook-audit/fetch-status/"+accountId);
           response(res.data);
        }catch(err){
           rej(err);
        }
    });
}

export const allAlreadyMetadataAccounts = () => {
    return new Promise(async (res,rej)=>{
        try{
           const data=await api.get("/facebook-audit/fetch-status");
           res(data);
        }catch(err){
           rej(err);
        }
    });
}

export default function* root() {
    yield all([
      takeLatest("GET_FACEBOOK_ACCOUNTS_SAGA", facebookAccounts)
    ]);
}