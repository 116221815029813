import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React from "react";
import { updateCompoReduxHDMAM } from "../../api/HDMAM/componentTracking/action";
import { saveHdmAmFormsData } from "../../api/HDMAM/form/action";
import { updateCompoReduxAM } from "../../api/accountManager/componentTracking/action";
import { ldFbAuditComplete } from "../../api/accountManager/forms/saga";
import { button_bg_corner as corner } from "../../assets/icons/common/common";

export const onNextLD = (dispatch, extra={}) => {
  if(extra?.type === "AUDIT_AD"){
    ldFbAuditComplete(extra?.data).then((res) => {
      dispatch(updateCompoReduxAM());
    });
  }else{
    dispatch(saveHdmAmFormsData());
    dispatch(updateCompoReduxAM());
  }
}

export const onNextHDM = (dispatch) => {
  dispatch(saveHdmAmFormsData());
  dispatch(updateCompoReduxHDMAM());
}

const GoToNextCompoButton = (props) => {
  const CustomButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: "14px",
    padding: props.type == "corner" ? "11px 25px" : "10px 24px",
    border: props.type == "corner" ? "0px solid" : "1px solid",
    lineHeight: "18px",
    background:
      props.type == "bordered"
        ? "#ffffff"
        : props.type == "corner"
        ? "#0869FB url(" + corner + ") no-repeat 0 0"
        : "#0869FB",
    borderColor: props.type == "bordered" ? "#0869FB" : "#0869FB",
    borderRadius: "8px",
    color: props.type == "bordered" ? "#0869FB" : "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 600,
    width: props.width ? props.width : "",
    "&:hover": {
      color: props.type == "bordered" ? "#FFFFFF" : "#FFFFFF",
    },
  });

  return (
    <CustomButton
      variant="contained"
      {...props}
      onClick={() => {
        if(props.onClickHandler){
          props.onClickHandler();
        }
        props.onNext?.()
      }}
    >
      {props.title}
    </CustomButton>
  );
};

export default GoToNextCompoButton;
