import React, { useCallback, useEffect, useState } from 'react';
import {
  Typography,
  Tab,
  Box,
  Button,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import crossIcon from './pmaxIcons/cross-icon.svg';
import infoIcon from './pmaxIcons/info-icon.svg';
import image from './pmaxIcons/image20.png';
import playIcon from './pmaxIcons/play-icon.svg';
import redCloseIcon from './pmaxIcons/red-cross-icon.svg';
import { VideoLibrary } from '@material-ui/icons';
import VideoLibraryModal from './VideoLibraryModal';
import ImageLibraryModal from './ImageLibraryModal';
import { useDropzone } from 'react-dropzone';
import { uploadFile } from '../../api/fileHandling/saga';
import { imageValidator } from '../../utils/pmaxStrategy';
import errorIcon from './pmaxIcons/error-icon.svg';
import ImageUploadStatusModal from './ImageUploadStatusModal';
import { api } from '../../api';
import axios from 'axios';
import grayInfoIcon from './pmaxIcons/gray-info-icon.svg';
import PmaxLoader from './PmaxLoader';
import AssetGroupDeleteModal from './AssetGroupDeleteModal';
import NoContentView from './NoContentView';
import { useDispatch, useSelector } from 'react-redux';
import { updateStartLoader } from '../../api/pmaxStrategy/slice';
const PMaxDetailedView = ({
  read,
  addedNewTerm=false,
  inputRef={},
  value,
  handleSetValue,
  theme = '',
  themes = [],
  handleSelectTheme = () => {},
  headlines,
  longHeadlines,
  descriptions,
  imageUrls,
  setimageUrls,
  setlogoUrls,
  logoUrls,
  ytVideosMetadata,
  setytVideosMetadata,
  change,
  add,
  remove,
  changeStatus = () => {},
  setSavedStatus = () => {},
  imageAssetLibrary,
  videoAssetLibrary,
  errors,
  logoAssetLibrary,
  handleGenerateAdCopies = () => {},
  handleGenerateThemes = () => {},
}) => {
  const [failedValidations, setFailedValidations] = useState([]);
  const [url, setUrl] = useState('');
  const [passedValidations, setPassedValidations] = useState([]);
  const [videoLibrary, setVideoLibrary] = useState(false);
  const [imageLibrary, setImageLibrary] = useState(false);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { name, segmentName, keywords, budget, ...detailValidationError } =
    errors || {};
  console.log('DE', detailValidationError);

  const selectedThemesCountGreaterThanFive = () => {
    const selectedThemes = [];
    themes.forEach((theme) => {
      if (theme.status === true) selectedThemes.push(theme);
    });
    if (selectedThemes.length >= 5) return true;
    else return false;
  };

  const { open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.gif'],
    },
  });
  const handleChange = (newValue) => {
    handleSetValue(newValue);
  };
  const removeTextStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '11px',
    color: '#E84B47',
  };

  const handleOpen = () => {
    open();
  };

  useEffect(() => {
    if (acceptedFiles.length !== 0)
      acceptedFiles.forEach((file) => {

        const image = new Image();
        image.onload = () => {
          const isImageValid = imageValidator({
            width: image.width,
            height: image.height,
            size: file.size,
            type: value,
          });
          // image.onabort
          const resolution = `${image.width}X${image.height}`;
          const size = `${Math.round(file.size / 1000)} KB`;
          const aspectRatio =
            Math.round((image.width / image.height) * 100) / 100;
          if (isImageValid.error === true) {
            setFailedValidations((prev) => ([
                { id: image.src, name: file.name, resolution, size, errorMessage: isImageValid.errorMessage, aspectRatio: mapAspectRatio(aspectRatio)},
              ...prev,
            ]));
          }
          else 
            setPassedValidations((prev) => ([
              { id: image.src, name: file.name, resolution, size, aspectRatio: mapAspectRatio(aspectRatio), file: file },
              ...prev,
            ]));
        };
        image.src = URL.createObjectURL(file);
      });
  }, [acceptedFiles]);

  const mapAspectRatio = (aspectRatio) => {
    if (aspectRatio === 4) return '4:1';
    if (aspectRatio === 0.8) return '4:5';
    if (aspectRatio === 1.91) return '1.91:1';
    if (aspectRatio === 1) return '1:1';
    return `${aspectRatio}:1`;
  };

  useEffect(() => {
    if (
      acceptedFiles.length &&
      (passedValidations.length + failedValidations.length) === acceptedFiles.length
    ) {
        setModal(true);
    }
  }, [passedValidations, failedValidations]);

  const error = Object.keys(detailValidationError).length ? true : false; //to be fetched from api
  const urlError = false;

  const handleClose = () => {
    setModal(false);
    passedValidations.forEach(file => URL.revokeObjectURL(file.src));
    failedValidations.forEach(file => URL.revokeObjectURL(file.src));
    setPassedValidations([]);
    setFailedValidations([]);
  };

  const handleAdd = (files) => {
    dispatch(updateStartLoader(true));

    const responseFiles = files.map((item) => {
      return api.post(
        `/files/image_url:upload?image_url=${encodeURIComponent(
          item.imageFullSizeUrl
        )}`
      );
    });

    setImageLibrary(false);

    Promise.allSettled(responseFiles)
      .then((res) => {
        console.log('RES:', res);
        const newFiles = [];
          files.forEach((file, index) => {
             const { status, value } = res[index];
             if (status === 'rejected') return;
             newFiles.push({
               id: value.data.fileId,
               name: file.name,
               resolution: `${Math.round(
                 file.imageFullSizeWidthPixels
               )}X${Math.round(file.imageFullSizeHeightPixels)}`,
             });
        });

        return newFiles;
      })
      .then((res) => {
        if (value === 'Image') setimageUrls([...res, ...imageUrls]);
        else if (value === 'Logo') setlogoUrls([...res, ...logoUrls]);
      })
      .catch((err) => console.log('Err:', err))
      .finally(() => {
        dispatch(updateStartLoader(false));
        changeStatus();
        setSavedStatus(false);
      });
  };

  const handleVidoAdd = (videos) => {
    setytVideosMetadata([...videos, ...ytVideosMetadata]);
    setVideoLibrary(false);
    changeStatus();
    setSavedStatus(false);
  };

  const addVideo = (addUrl) => {
    const url = new URL(addUrl);
    const searchParams = new URLSearchParams(url.search);
    const id = searchParams.get('v');

    const response = api.get(`/youtube/metadata?video_ids=${id}`);
    response
      .then((res) => {
        const metadata = res.data.items[0];
        setytVideosMetadata([{ ...metadata }, ...ytVideosMetadata]);
        setUrl('');
        changeStatus();
        setSavedStatus(false);
      })
      .catch((err) => console.log('Error:', err));
  };

  const handleProceed = () => {
    if (!passedValidations.length) {
        failedValidations.forEach(file => URL.revokeObjectURL(file.src));
        return
    };

    dispatch(updateStartLoader(true));
    const files = passedValidations.map((file) => {
      let formData = new FormData();
      formData.append('file', file.file);
      return uploadFile(formData);
    });

    Promise.allSettled(files)
      .then((res) => {
        console.log(res);
        const newFiles = [];
        passedValidations.forEach((file, index) => {
          const { status, value } = res[index]
          if (status === 'rejected') return;
          newFiles.push({
             id: value.fileId,
             name: file.name,
             resolution: file.resolution,
             aspectRatio: file.aspectRatio,
             size: file.size,
           });
        });
         return newFiles;
      })
      .then((files) => {

        console.log('files', files);
        if (value === 'Image') setimageUrls([...files, ...imageUrls]);
        else setlogoUrls([...files, ...logoUrls]);
      })
      .finally(() => {
        dispatch(updateStartLoader(false));
        passedValidations.forEach(file => URL.revokeObjectURL(file.src));
        failedValidations.forEach(file => URL.revokeObjectURL(file.src));
        setPassedValidations([]);
        setFailedValidations([]);
        setModal(false);
      });

    if (passedValidations.length > 0) {
      changeStatus();
      setSavedStatus(false);
    }
  };

  return (
    <div className='p-max-detailed-view-container'>
      <div className='pmax-detailed-view'>
        <div>
          <div className='pmax-navigation-controls'>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Theme'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Theme' ? '#0869FB' : '',
                opacity: value === 'Theme' ? 1 : '',
              }}
              onClick={() => handleChange('Theme')}
            >
              Theme
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Headline'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Headline' ? '#0869FB' : '',
                opacity: value === 'Headline' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Headline')}
            >
              Headline
              {detailValidationError?.headlines?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {detailValidationError?.headlines.length}{' '}
                          {detailValidationError?.headlines.length === 1
                            ? 'Error'
                            : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {detailValidationError?.headlines?.map(
                          (headline, index) => (
                            <Typography
                              key={index}
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: '20px',
                                color: '#333333',
                                marginBottom: 8,
                              }}
                            >
                              {index + 1}. {headline}
                            </Typography>
                          )
                        )}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Long Headline'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Long Headline' ? '#0869FB' : '',
                opacity: value === 'Long Headline' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Long Headline')}
            >
              Long Headline
              {detailValidationError?.longHeadlines?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {detailValidationError?.longHeadlines.length}{' '}
                          {detailValidationError?.longHeadlines.length === 1
                            ? 'Error'
                            : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {detailValidationError?.longHeadlines.map(
                          (longHeadline, index) => (
                            <Typography
                              key={index}
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: '20px',
                                color: '#333333',
                                marginBottom: 8,
                              }}
                            >
                              {index + 1}. {longHeadline}
                            </Typography>
                          )
                        )}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Description'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Description' ? '#0869FB' : '',
                opacity: value === 'Description' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Description')}
            >
              Description
              {detailValidationError?.descriptions?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {detailValidationError?.descriptions.length}{' '}
                          {detailValidationError?.descriptions.length === 1
                            ? 'Error'
                            : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {detailValidationError?.descriptions.map(
                          (description, index) => (
                            <Typography
                              key={index}
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: '20px',
                                color: '#333333',
                                marginBottom: 8,
                              }}
                            >
                              {index + 1}. {description}
                            </Typography>
                          )
                        )}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Image'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Image' ? '#0869FB' : '',
                opacity: value === 'Image' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Image')}
            >
              Image
              {detailValidationError?.imageUrls?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {detailValidationError?.imageUrls.length}{' '}
                          {detailValidationError?.imageUrls.length === 1
                            ? 'Error'
                            : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {detailValidationError?.imageUrls.map(
                          (image, index) => (
                            <Typography
                              key={index}
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: '20px',
                                color: '#333333',
                                marginBottom: 8,
                              }}
                            >
                              {index + 1}. {image}
                            </Typography>
                          )
                        )}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Logo'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Logo' ? '#0869FB' : '',
                opacity: value === 'Logo' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Logo')}
            >
              Logo
              {detailValidationError?.logoUrls?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {detailValidationError?.logoUrls.length}{' '}
                          {detailValidationError?.logoUrls.length === 1
                            ? 'Error'
                            : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {detailValidationError?.logoUrls.map((logo, index) => (
                          <Typography
                            key={index}
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                              marginBottom: 8,
                            }}
                          >
                            {index + 1}. {logo}
                          </Typography>
                        ))}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Videos'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Videos' ? '#0869FB' : '',
                opacity: value === 'Videos' ? 1 : '',
                padding: '8px 16px',
              }}
              onClick={() => handleChange('Videos')}
            >
              Videos
              {/* {error && <img src={errorIcon} alt='error' />} */}
            </div>
          </div>
        </div>

        {value === 'Theme' && (
          <div>
            <div className='pmax-theme-search'>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: '17px',
                  color: ' #7E7E7E',
                }}
              >
                Theme
              </Typography>
              <TextField
                variant='outlined'
                placeholder='Default Theme Goes Here'
                value={theme}
                inputProps={{
                  style: {
                    color: '#333333',
                    width: 375,
                    height: 42,
                    background: '#FFFFFF',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    padding: '0 0 0 16px',
                  },
                }}
                onChange={(e) => change(e)}
              />
              <div className='search-button-container'>
                <Button
                  className='search-button'
                  onClick={() => handleGenerateThemes()}
                >
                  Search
                </Button>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                padding: 16,
                paddingLeft: 24,
                paddingTop: 0,
              }}
            >
              <img
                src={grayInfoIcon}
                alt='info'
                style={{
                  width: 16,
                  height: 16,
                }}
              />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 11,
                  lineHeight: '13px',
                  color: '#9798A1',
                }}
              >
                {value === 'Theme' && 'Please select a maximum of 5 themes.'}
              </Typography>
            </div>
            <div className='pmax-theme-list'>
              <FormGroup>
                {themes.length === 0 ? (
                  <NoContentView tab='Theme' buttonName='Generate Ad Copies' />
                ) : (
                  themes.map((theme, index) => (
                    <FormControlLabel
                      disabled={
                        selectedThemesCountGreaterThanFive() && !theme?.status
                      }
                      key={index}
                      control={
                        <Checkbox checked={theme?.status ? true : false} />
                      }
                      label={
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: '20px',
                            color: '#333333',
                          }}
                        >
                          {theme?.name}
                        </Typography>
                      }
                      onClick={() => handleSelectTheme(index)}
                    />
                  ))
                )}
              </FormGroup>
            </div>
            <div className='add-search-term-container'>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => handleGenerateAdCopies()}
              >
                Generate Ad Copies
              </Button>
            </div>
          </div>
        )}
        {value === 'Headline' && (
          <div>
            <div className='headline-container'>
              {headlines.length === 0 ? (
                <NoContentView tab='Headline' buttonName='Add Headline' />
              ) : (
                headlines.map((headline, index) => (
                  <div className='headline' key={index}>
                    <div
                      className='headline-text'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      { !addedNewTerm && (
                          read ? (
                        headline
                      ) : (
                        <TextField
                          variant='standard'
                          value={headline}
                          inputProps={{
                            style: {
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 14,
                              width: 500,
                              paddingBottom: 2,
                              border: 0,
                            },
                            maxLength: 30,
                          }}
                          onChange={(e) => change(e, index)}
                        />
                      ))}
                       { addedNewTerm && ( read ? ((index === headlines.length - 1) ? (
                          <TextField variant='standard'
                            value={headline}
                            inputRef={inputRef}
                            inputProps={{
                              style: {
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 14,
                                width: 500,
                                paddingBottom: 2,
                                border: 0,
                              },
                              maxLength: 30,
                            }}
                            onChange={(e) => change(e, index)}
                          />
                       ) : headline ) : (
                           ((index === headlines.length - 1) ?
                          <TextField variant='standard'
                            value={headline}
                            inputRef={inputRef}
                            inputProps={{
                              style: {
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 14,
                                width: 500,
                                paddingBottom: 2,
                                border: 0,
                              },
                              maxLength: 30,
                            }}
                            onChange={(e) => change(e, index)}
                          /> : (
                          <TextField variant='standard'
                            value={headline}
                            inputProps={{
                              style: {
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 14,
                                width: 500,
                                paddingBottom: 2,
                                border: 0,
                              },
                              maxLength: 30,
                            }}
                            onChange={(e) => change(e, index)}
                            />
                          ))
                       ))}
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineheight: '31px',
                          color: '#666666',
                          paddingRight: 15,
                        }}
                      >
                        {headline.length !== 0 && `${headline.length}/30`}
                      </Typography>
                    </div>
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => remove(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                )) 
              )}
            </div>
            <div className='add-search-term-container'>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => add()}
              >
                + Add Headline
              </Button>
            </div>
          </div>
        )}
        {value === 'Long Headline' && (
          <div>
            <div className='description-container'>
              {longHeadlines.length === 0 ? (
                <NoContentView
                  tab='Long Headline'
                  buttonName='Add Long Headline'
                />
              ) : (
                longHeadlines.map((longHeadline, index) => (
                  <div className='description' key={index}>
                    <div
                      className='description-text'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        lineHeight: 1.6,
                      }}
                    >
                      { !addedNewTerm && (
                          read ? (
                        longHeadline
                      ) : (
                        <TextField
                          variant='standard'
                          value={longHeadline}
                          multiline
                          inputProps={{
                            style: {
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 14,
                              width: 510,
                              paddingBottom: 0,
                              border: 0,
                            },
                            maxLength: 90,
                          }}
                          onChange={(e) => change(e, index)}
                        />
                      ))}
                       {
                           addedNewTerm && (
                               read ?
                               ((index === longHeadlines.length - 1) ?
                                <TextField
                                  variant='standard'
                                  value={longHeadline}
                                  inputRef={inputRef}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                                   : longHeadline)
                               : (
                               ((index === longHeadlines.length - 1) ?
                                <TextField
                                  variant='standard'
                                  value={longHeadline}
                                  inputRef={inputRef}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                               : (
                                <TextField
                                  variant='standard'
                                  value={longHeadline}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                               )
                               ))
                           )}
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          paddingRight: 15,
                        }}
                      >
                        {longHeadline.length !== 0 &&
                          `${longHeadline.length}/90`}
                      </Typography>
                    </div>
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => remove(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))
              )}
            </div>
            <div className='add-search-term-container'>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => add()}
              >
                + Add Long Headline
              </Button>
            </div>
          </div>
        )}
        {value === 'Description' && (
          <div>
            <div className='description-container'>
              {descriptions.length === 0 ? (
                <NoContentView tab='Description' buttonName='Add Description' />
              ) : (
                descriptions.map((description, index) => (
                  <div className='description' key={index}>
                    <div
                      className='description-text'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        lineHeight: 1.6,
                      }}
                    >
                      { !addedNewTerm && (
                          read ? (
                        description
                      ) : (
                        <TextField
                          variant='standard'
                          value={description}
                          multiline
                          inputProps={{
                            style: {
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 14,
                              width: 510,
                              paddingBottom: 0,
                              border: 0,
                            },
                            maxLength: 90,
                          }}
                          onChange={(e) => change(e, index)}
                        />
                      ))}
                      {
                          addedNewTerm && (
                              read ? ((index === descriptions.length - 1) ? (
                                <TextField
                                  variant='standard'
                                  inputRef={inputRef}
                                  value={description}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                          ) : description ) : (
                              ((index === descriptions.length - 1) ? 
                                <TextField
                                  variant='standard'
                                  inputRef={inputRef}
                                  value={description}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                                  : (
                                <TextField
                                  variant='standard'
                                  value={description}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                                )
                          ))
                          )}
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineheight: '31px',
                          color: '#666666',
                          paddingRight: 15,
                        }}
                      >
                        {description.length !== 0 && `${description.length}/90`}
                      </Typography>
                    </div>
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => remove(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))
              )}
            </div>
            <div className='add-search-term-container'>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => add()}
              >
                + Add Description
              </Button>
            </div>
          </div>
        )}
        {(value === 'Image' || value === 'Logo') && (
          <div>
            <div className='image-view-container'>
              <div className='image-resolution-message'>
                <img src={infoIcon} alt='info' />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '17px',
                    color: '#7B7B7B',
                  }}
                >
                  {value === 'Logo'
                    ? 'Please only add a logo with 1:1 or 4:1 aspect ratio'
                    : 'Please only add an image with 1.91:1, 1:1 or 4:5 aspect ratio'}
                </Typography>
              </div>
              <div className='image-list-container'>
                {value === 'Image' &&
                  (imageUrls.length === 0 ? (
                    <NoContentView
                      tab={'Image'}
                      buttonName={'Upload or Library'}
                    />
                  ) : (
                    imageUrls.map((image, index) => (
                      <div className='image' key={index}>
                        <div className='delete-container'>
                          <img
                            src={crossIcon}
                            alt='cross'
                            onClick={() => remove(index)}
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                        <img
                          src={
                            process.env.REACT_APP_BACKEND_BASE_IMAGE_URL +
                            '/' +
                            image.id
                          }
                          alt='image'
                        />
                        <div className='name'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            Name:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            {image.name}
                          </Typography>
                        </div>
                        <div className='resolution'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            Resolution:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            {image.resolution}
                          </Typography>
                        </div>
                      </div>
                    ))
                  ))}
                {value === 'Logo' &&
                  (logoUrls.length === 0 ? (
                    <NoContentView
                      tab={'Logo'}
                      buttonName={'Upload or Library'}
                    />
                  ) : (
                    logoUrls.map((logo, index) => (
                      <div className='image' key={index}>
                        <div className='delete-container'>
                          <img
                            src={crossIcon}
                            alt='cross'
                            onClick={() => remove(index)}
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                        <img
                          src={
                            process.env.REACT_APP_BACKEND_BASE_IMAGE_URL +
                            '/' +
                            logo.id
                          }
                          alt='image'
                        />
                        <div className='name'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            Name:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            {logo.name}
                          </Typography>
                        </div>
                        <div className='resolution'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            Resolution:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            {logo.resolution}
                          </Typography>
                        </div>
                      </div>
                    ))
                  ))}
              </div>
            </div>
            <div className='image-action-container'>
              <div className='add-search-term-container'>
                <Button
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: '#0869FB',
                    textTransform: 'capitalize',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => handleOpen()}
                >
                  + Upload
                </Button>
              </div>
              <div className='add-search-term-container'>
                <Button
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: '#0869FB',
                    textTransform: 'capitalize',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => setImageLibrary(true)}
                >
                  + Library
                </Button>
              </div>
            </div>
            {imageLibrary && (
              <ImageLibraryModal
                setModal={setImageLibrary}
                assetLibrary={
                  value === 'Image' ? imageAssetLibrary : logoAssetLibrary
                }
                // uploadUrls={(value === "Image") ? setimageUrls : setlogoUrls }
                handleAdd={handleAdd}
              />
            )}
          </div>
        )}
        {value === 'Videos' && (
          <div>
            <div className='video-view-container'>
              <div className='yb-search-pmax-container'>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '19px',
                    color: '#323232',
                    marginBottom: 6,
                  }}
                >
                  Youtube URL
                </Typography>
                <div className='text-link-area'>
                  <TextField
                    placeholder='Enter URL here'
                    value={url}
                    style={{
                      width: '98%',
                      height: 41.22,
                      border: urlError ? '0.964432px solid #EF5854' : '',
                      borderRadius: urlError ? '7.71545px' : '',
                    }}
                    sx={{
                      '& fieldset': { border: 'none' },
                    }}
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 11.5732,
                        lineHeight: '19px',
                      },
                    }}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                  <Button
                    style={{
                      width: 89,
                      height: 40,
                      background: '#0869FB',
                      borderRadius: 8,
                      color: 'white',
                      textTransform: 'capitalize',
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '24px',
                    }}
                    onClick={() => addVideo(url)}
                    // onClick={() => setLoaderModal(true)}
                  >
                    + Add
                  </Button>
                </div>
                {urlError && (
                  <p
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: '15px',
                      color: '#EF5854',
                      marginLeft: 2.5,
                    }}
                  >
                    Video needs to be horizontal, vertical or square
                  </p>
                )}
              </div>
              <div className='video-list-container'>
                {/* Use this to iterate video-list  */}
                <div className='video-list'>
                  {ytVideosMetadata.length === 0 ? (
                    <NoContentView tab='Videos' buttonName='' />
                  ) : (
                    ytVideosMetadata.map((video, index) => (
                      <div
                        style={{
                          display: 'flex',
                          gap: 12,
                        }}
                      >
                        <div className='video'>
                          <img src={video.thumbnailUrl} alt='video' />
                          <img
                            src={playIcon}
                            alt='play'
                            className='play-icon'
                          />
                        </div>
                        <div className='video-details'>
                          <div className='name'>
                            <Typography
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: 12,
                                color: '#7B7B7B',
                              }}
                            >
                              Name:
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                                color: '#7B7B7B',
                              }}
                            >
                              {video.title}
                            </Typography>
                          </div>
                          <div className='link'>
                            <div className='link-details'>
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  fontSize: 12,
                                  color: '#7B7B7B',
                                }}
                              >
                                URL:
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  fontSize: 12,
                                  color: '#0869FB',
                                  textDecoration: 'underline',
                                }}
                              >
                                {`https://www.youtube.com/watch?v=${video.id}`}
                              </Typography>
                            </div>
                            <Button
                              className='remove-video'
                              onClick={() => remove(index)}
                            >
                              <img src={redCloseIcon} alt='' />
                              <Typography style={removeTextStyle}>
                                Remove
                              </Typography>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                {/* Use this to iterate video-list  */}
              </div>
            </div>
            <>
              <div className='add-search-term-container'>
                <Button
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: '#0869FB',
                    textTransform: 'capitalize',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => setVideoLibrary(true)}
                >
                  + Library
                </Button>
              </div>
            </>
          </div>
        )}
      </div>
      {modal && (
        <ImageUploadStatusModal
          handleClose={handleClose}
          verifiedFiles={passedValidations}
          errorFiles={failedValidations}
          handleProceed={handleProceed}
        />
      )}
      {videoLibrary && (
        <VideoLibraryModal
          setModal={setVideoLibrary}
          handleAdd={handleVidoAdd}
          videoAssetLibrary={videoAssetLibrary}
        />
      )}
    </div>
  );
};

export default PMaxDetailedView;
