import React, { useState } from 'react';
import {
  Typography,
  Tab,
  Box,
  Button,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import crossIcon from './cross-icon.svg';
import image from './image20.png';
import './pmaxstrategydeck.scss';
const PMaxStrategyDeck = ({
    isClient=false,
    asset,
    campName,
    onRemove,
}) => {
  const [value, setValue] = useState('Headline');


  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className='pmax-strategy-deck'>
      <div className='p-max-detailed-view-container'>
        <div className='pmax-detailed-view'>
          <div>
            <div className='pmax-navigation-controls'>
              <div
                className='pmax-control'
                style={{
                  borderBottom:
                    value === 'Headline'
                      ? '1px solid #0869FB'
                      : '1px solid rgba(240,240,240,1)',
                  cursor: 'pointer',
                  color: value === 'Headline' ? '#0869FB' : '',
                  opacity: value === 'Headline' ? 1 : '',
                }}
                onClick={() => handleChange('Headline')}
              >
                Headline
              </div>
              <div
                className='pmax-control'
                style={{
                  borderBottom:
                    value === 'Long Headline'
                      ? '1px solid #0869FB'
                      : '1px solid rgba(240,240,240,1)',
                  cursor: 'pointer',
                  color: value === 'Long Headline' ? '#0869FB' : '',
                  opacity: value === 'Long Headline' ? 1 : '',
                }}
                onClick={() => handleChange('Long Headline')}
              >
                Long Headline
              </div>
              <div
                className='pmax-control'
                style={{
                  borderBottom:
                    value === 'Description'
                      ? '1px solid #0869FB'
                      : '1px solid rgba(240,240,240,1)',
                  cursor: 'pointer',
                  color: value === 'Description' ? '#0869FB' : '',
                  opacity: value === 'Description' ? 1 : '',
                }}
                onClick={() => handleChange('Description')}
              >
                Description
              </div>
              <div
                className='pmax-control'
                style={{
                  borderBottom:
                    value === 'Image'
                      ? '1px solid #0869FB'
                      : '1px solid rgba(240,240,240,1)',
                  cursor: 'pointer',
                  color: value === 'Image' ? '#0869FB' : '',
                  opacity: value === 'Image' ? 1 : '',
                }}
                onClick={() => handleChange('Image')}
              >
                Image
              </div>
              <div
                className='pmax-control'
                style={{
                  borderBottom:
                    value === 'Logo'
                      ? '1px solid #0869FB'
                      : '1px solid rgba(240,240,240,1)',
                  cursor: 'pointer',
                  color: value === 'Logo' ? '#0869FB' : '',
                  opacity: value === 'Logo' ? 1 : '',
                }}
                onClick={() => handleChange('Logo')}
              >
                Logo
              </div>
              <div
                className='pmax-control'
                style={{
                  borderBottom:
                    value === 'Videos'
                      ? '1px solid #0869FB'
                      : '1px solid rgba(240,240,240,1)',
                  cursor: 'pointer',
                  color: value === 'Videos' ? '#0869FB' : '',
                  opacity: value === 'Videos' ? 1 : '',
                }}
                onClick={() => handleChange('Videos')}
              >
                Videos
              </div>
            </div>
          </div>

          {value === 'Headline' && (
            <div>
              <div className='headline-container'>
              { asset?.headlines?.map((headline, index) => 
                  <div className='headline' key={index}>
                    <div className='headline-text'>{headline}</div>
                    { isClient && 
                        <img 
                          src={crossIcon} 
                          alt='cross' 
                          onClick={() => onRemove(campName, asset.id, index, value)}
                        />
                    }
                  </div>
              )}
              </div>
            </div>
          )}
          {value === 'Long Headline' && (
            <div>
              <div className='description-container'>
              {  asset?.longHeadlines?.map((longHeadline, index) =>
                  <div className='description' key={index}>
                    <div className='description-text'>
                      {longHeadline}
                    </div>
                    { isClient && 
                        <img 
                          src={crossIcon} 
                          alt='cross' 
                          onClick={() => onRemove(campName, asset.id, index, value)}
                        />
                    }
                  </div>
              )}
              </div>
            </div>
          )}
          {value === 'Description' && (
            <div>
              <div className='description-container'>
              {  asset?.descriptions?.map((description, index) =>
                  <div className='description' key={index}>
                    <div className='description-text'>
                      {description}
                    </div>
                    { isClient && 
                        <img 
                          src={crossIcon} 
                          alt='cross' 
                          onClick={() => onRemove(campName, asset.id, index, value)}
                        />
                    }
                  </div>
              )}
              </div>
            </div>
          )}
          {(value === 'Image' || value === 'Logo' || value === 'Videos') && (
            <div>
              <div className='image-view-container'>
                <div className='image-list-container'>
              { value === 'Image' && 
                  asset?.imageUrls?.map(({ id }, index) =>
                  <div className='image' key={index}>
                      { isClient &&
                        <div className='delete-container'>
                        <img 
                          src={crossIcon} 
                          alt='cross' 
                          onClick={() => onRemove(campName, asset.id, index, value)}
                        />
                        </div>
                      }
                      <img
                        src={process.env.REACT_APP_BACKEND_BASE_IMAGE_URL + '/' + id}
                        alt='image'
                      />
                  </div>
              )}
              { value === 'Logo' && 
                  asset?.logoUrls?.map(({ id }, index) =>
                  <div className='image' key={index}>
                      { isClient &&
                        <div className='delete-container'>
                        <img 
                          src={crossIcon} 
                          alt='cross' 
                          onClick={() => onRemove(campName, asset.id, index, value)}
                        />
                        </div>
                      }
                      <img
                        src={process.env.REACT_APP_BACKEND_BASE_IMAGE_URL + '/' + id}
                        alt='image'
                      />
                  </div>
              )}
              { value === 'Videos' && 
                  asset?.ytVideosMetadata?.map(({ thumbnailUrl }, index) =>
                  <div className='image' key={index}>
                      { isClient &&
                        <div className='delete-container'>
                          <img 
                            src={crossIcon} 
                            alt='cross' 
                            onClick={() => onRemove(campName, asset.id, index, value)}
                          />
                        </div>
                      }
                      <img
                        src={thumbnailUrl}
                        alt='image'
                      />
                  </div>
              )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PMaxStrategyDeck;
