import { useDispatch } from 'react-redux';
import { goBackAmCompo } from '../../../api/accountManager/componentTracking/action';
import { onNextLD } from '../GoToNextCompoButton';
import CommonAuditAdset from './CommonAuditAdset';

export default function LDAuditAdset() {
  const dispatch = useDispatch();

  const onBack = () => {
    dispatch(goBackAmCompo());
  };
  
  return (
    <CommonAuditAdset
      onBack={onBack}
      onNext={() => onNextLD(dispatch)}
    />
  );
}