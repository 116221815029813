import { RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { forwardRef } from "react";
import BpRadio from "../../../ClientDashboard/CustomRadio/Radio";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

const DEFAULT_CHANGE_HANDLER = () => {};
const MenuRadioList = forwardRef(
  (
    {
      value = "",
      onChange,
      options,
      disabled,
      placeholder = "Select",
      isEditable = true,
      ...props
    },
    ref
  ) => {
    return (
      <Select
        value={value}
        onChange={DEFAULT_CHANGE_HANDLER}
        renderValue={(value) => value}
        MenuProps={MenuProps}
        variant="outlined"
        placeholder={placeholder}
        ref={ref}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "& .MuiSelect-select:focus": {
            backgroundColor: "transparent !important",
          },
          "& .Mui-disabled": {
            color: "#0869FB !important",
            "-webkit-text-fill-color": "#0869FB !important",
          }
        }}
        style={{
          width: 130,
          height: 27,
          background: "#FFFFFF",
          border: "1px solid #CCECFE",
          borderRadius: 4,
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 12,
          outline: "none",
          color: "#0869FB",
        }}
        classes={disabled ? {select: "Mui-disabled", root: "Mui-disabled"}: {}}
        {...props}
      >
        <RadioGroup onChange={(e) => (isEditable && !disabled) && onChange(e.target.value)}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <FormControlLabel
                name={props.name}
                value={option.value}
                control={<BpRadio checked={option.value === value} />}
                label={option.label}
                checked={option.value === value}
                disabled={option.disabled}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </Select>
    );
  }
);

export default MenuRadioList;
