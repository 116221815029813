import { TextField, Typography } from "@mui/material";
import { creative as BULB } from "../../../../../assets/icons/socialmedia/socialmedia";
import GoToNextCompoButton from "../../../GoToNextCompoButton";
import { goBackHDMAmCompo, goToSpecificCompoHDMAM, updateCompoReduxHDMAM } from "../../../../../api/HDMAM/componentTracking/action";
import { useDispatch, useSelector } from "react-redux";
import "./competitor.css";
import {
  accountManagerIdsHDM,
  BACKEND_STATUS,
  componentWithIndexHDMAm,
  isPlatformSelectedHDM,
} from "../../../../../utils/accountManagerUtil";
import { updateFormReducer } from "../../../../../api/accountManager/forms/slice";
import PrimaryButton from "../../../../ButtonPrimary/Buttons";

const CompetitorAnalysis = ({ competVal }) => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.hdmFormAm);
  const onClickHandler = () => {
    const updateState = {};
    if (isPlatformSelectedHDM(form, "google-ads") !== -1) {
      updateState.googleSearchAdsStrategyInfo = {
        ...(form.googleSearchAdsStrategyInfo || {}),
        status: BACKEND_STATUS.ONGOING,
      };
    }
    if (isPlatformSelectedHDM(form, "facebook-ads") !== -1) {
      updateState.fbAdsStrategyInfo = {
        ...(form.fbAdsStrategyInfo || {}),
        status: BACKEND_STATUS.ONGOING,
      };
    }
    updateState.accountAnalysis = {...(form.accountAnalysis), status: BACKEND_STATUS.COMPLETED}
    dispatch(updateFormReducer(updateState));
    if (isPlatformSelectedHDM(form, "google-ads") !== -1) {
      dispatch(updateCompoReduxHDMAM());
    } else {
      dispatch(goToSpecificCompoHDMAM(componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE]));
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="competitor_bk">
        <div
          style={{
            width: "56.84px",
            height: "56.84px",
            background: "#FFFFFF",
            boxShadow: "0px 13.3214px 17.7619px rgba(27, 33, 40, 0.08)",
            borderRadius: "10.6571px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={BULB} alt="" />
        </div>

        <Typography
          style={{
            fontFamily: "Lato",
            fontSize: "35.52px",
            fontWeight: "700",
            lineHeight: "46.18px",
            color: "#000000",
            marginLeft: "14.21px",
          }}
        >
          {" "}
          Analysis Competitor
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {/* <TextField placeholder='Please add text here...' id="outlined-multiline-static" InputProps={{style:{
                        height:'186px',
                        width:'1047px',
                        padding:'0px'
                        
                    }}}/> */}
        <TextField
          className="text-field"
          multiline
          rows={5}
          placeholder="Please add text here..."
          InputProps={{
            style: {
              height: "186px",
              width: "1047px",
              border: "1px solid #F0F0F0",
              background: "#F0F0F0",
            },
          }}
          value={competVal}
          onChange={(e) =>
            dispatch({
              type: "UPDATE_VALUE_ACCOUNT_ANALYSIS_HDM_AM",
              payload: { key: "competitor", value: e.target.value },
            })
          }
        />
      </div>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button className="back" onClick={() => dispatch(goBackHDMAmCompo())}>
            BACK
          </button>
          <PrimaryButton title="Next" onClick={onClickHandler} />
        </div>
      </div>
    </div>
  );
};
export default CompetitorAnalysis;
