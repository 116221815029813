import { useEffect, useState } from 'react';
import { Accordion, Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import './AccountPreview.css';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { goToSpecificCompoClient } from '../../../api/componentTracking/action';
import { updateFormReducer } from '../../../api/accountManager/forms/slice';
import {
  openClientView,
  updateCompoReduxAM,
} from '../../../api/accountManager/componentTracking/action';
import {
  DB_KEY_PRODUCT_INFO,
  DB_KEY_PRODUCT_PREFERENCES,
  DB_KEY_AUDIENCE,
  DB_KEY_BRANDING,
  DB_KEY_SOCIAL_PROOF,
} from '../../../utils/dbKeyMappingIntakeForm';
import {
  PRODUCT_INFORMATION_LD,
  PREFERENCES_LD,
  AUDIENCE_LD,
} from '../../../utils/questionsOrderToPreview';
import { goBackAmCompo } from '../../../api/accountManager/componentTracking/action';
import {
  generateKeywordsReducer,
  getKeywordsReducer,
} from '../../../api/accountManager/targetAI/slice';
import GeneratingStrategyPage from '../GeneratingStrategyPage';
import { fbTargeting } from '../../ClientDashboard/Questions/mappedQuestion';
import {
    BACKEND_STATUS,
  accessSharingType,
  getAccessStatus,
} from '../../../utils/accountManagerUtil';
import {
  clientDashboardIds,
  LDcomponentWithIndexClient,
} from '../../../utils/clientDashboardIdsUtil';

const IntakeFormPreview = () => {
  const dispatch = useDispatch();

  const [productInformationQuestions, setProductInformationQuestions] =
    useState({});
  const [preferenceQuestions, setPreferenceQuestions] = useState({});
  const [audienceQuestions, setAudienceQuestions] = useState({});
  const [showGeneratingStrategyPage, setShowGeneratingStrategyPage] =
    useState(false);

  const productInfo = useSelector(
    (state) => state.form.form?.intakeInfo?.productServiceInfo
  );
  const productPreferences = useSelector(
    (state) => state.form.form?.intakeInfo?.preferences
  );
  const audiences = useSelector(
    (state) => state.form.form?.intakeInfo?.audiences
  );
  const branding = useSelector(
    (state) => state.form.form?.intakeInfo?.brandAssets
  );
  const socialProof = useSelector(
    (state) => state.form.form?.intakeInfo?.customerTestimonial
  );
  const clientReduxState = useSelector((state) => state.form.form);

  const hasCalledTAI = useSelector((state) => state.targetAI.hasCalledTAI);
  const hasCalledCAI = useSelector(
    (store) => store.strategyCreationHdmAm.hasCalledCAI
  );
  const [fbTargetingQuestion, setFbTargetingQuestion] = useState({});
  const platforms = clientReduxState?.onboardingInfo?.platformInfo?.platforms;
  useEffect(() => {
    dispatch(getKeywordsReducer());
    dispatch({
      type: 'UPDATE_FACEBOOK_ADS_SAGA_STARTEGY',
    });
  }, []);
  useEffect(() => {
    if (productInfo) {
      const informations = {};
      PRODUCT_INFORMATION_LD.forEach((question) => {
        informations[question] = productInfo[question];
      });
      setProductInformationQuestions(informations);
    }
  }, [productInfo]);

  useEffect(() => {
    if (productPreferences) {
      const informations = {};
      PREFERENCES_LD.forEach((question) => {
        informations[question] = productPreferences[question];
      });
      setPreferenceQuestions(informations);
    }
  }, [productPreferences]);

  useEffect(() => {
    if (audiences) {
      const informations = {};
      AUDIENCE_LD.forEach((question) => {
        informations[question] = audiences[question];
      });
      setAudienceQuestions(informations);
    }
  }, [audiences]);

  useEffect(() => {
    let targetingKeyword = clientReduxState?.onboardingInfo?.facebookTargeting;
    const obj = {};
    obj['AgeRange'] =
      '(Min=>' +
      targetingKeyword?.minAgeRange +
      ',Max=>' +
      targetingKeyword?.maxAgeRange +
      ')';
    obj['gender'] = targetingKeyword?.gender;
    obj['advertisingLocations'] = targetingKeyword?.advertisingLocations;
    obj['aboutBusines'] =
      clientReduxState?.onboardingInfo?.aboutBusiness?.brandsInterests;
    obj['excludes'] = targetingKeyword?.excludes;
    setFbTargetingQuestion(obj);
  }, []);

  const mappedKey = {
    descriptions: 'name',
    location: 'city',
    competitors: 'name',
    platforms: 'platform',
  };

  const desiredData = (key, value) => {
    if (key === 'crm' || key === 'leadSource') {
      let str = '';
      value.forEach((val) => {
        if (val && val !== 'Other') {
          str += val + ',';
        }
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : '';
    }
    if (key === 'competitors') {
      let compNameValue = '';
      value.map((key, index) => {
        if (key.name.length !== 0 || key.website.length !== 0) {
          compNameValue += '(' + key.name + ' => ' + key.website + ')' + ',';
        }
      });
      return compNameValue.slice(0, compNameValue.length - 1);
    }
    const tempArr = value.map((infoType) => infoType[mappedKey[key]]);
    return tempArr.join(',').slice(1);
  };
  const breakFromUnderscore = (str, key) => {
    if (key === 'monthlyAdSpend') {
      return `$${str}`;
    }
    if (typeof str === 'string') {
      const data = str.split('_');
      if (data.length > 1) {
        const temp = data.join(',');
        return temp.slice(0, temp.length - 1);
      } else {
        return data[0];
      }
    } else {
      return str;
    }
  };
  const desiredData2 = (key, value) => {
    if (key === 'advertisingLocations') {
      let str = '';
      value.forEach((val) => {
        str = str + val.name + ',';
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : '';
    }
    if (key === 'geoTargets') {
      let str = '';
      value.forEach((v) => {
        str = str + `(name=>${v.title}),`;
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : '';
    }
    if (key === 'descriptions') {
      let str = '';
      value.forEach(({ name, description }, index) => {
        if (name.length !== 0 || description.length !== 0) {
          str += '(name=>' + name + ',description=>' + description + '),';
        }
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : '';
    }
    if (key === 'competitors') {
      let compNameValue = '';
      value.map((key, index) => {
        compNameValue += '(' + key.name + ' => ' + key.website + ')' + ',';
      });
      return compNameValue.slice(0, compNameValue.length - 1);
    }
    return value.filter((val) => val).join(',');
  };
  const desiredDataBool = (key, value) => {
    if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  const onClickHandler = () => {
    // if (!hasCalledTAI) {
    //   dispatch(generateKeywordsReducer());
    // }
    if (!hasCalledCAI) {
      dispatch({
        type: 'GENERATE_FACEBOOK_ADS_CAI',
      });
    }
    if (
      getAccessStatus(
        accessSharingType.VERIFYING_COMPLETE,
        clientReduxState?.onboardingInfo?.platformInfo?.platforms,
        clientReduxState?.accessSharing
      ) ||
      getAccessStatus(
        accessSharingType.COMPLETE,
        clientReduxState?.onboardingInfo?.platformInfo?.platforms,
        clientReduxState?.accessSharing
      )
    ) {
      const data = {
          completedSharedAssetStatus: BACKEND_STATUS.ONGOING
      }
      dispatch(updateFormReducer(data));
    }
    dispatch(updateCompoReduxAM());
  };

  return (
    <>
      <div
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        <div className='top_head'>
          <div className='resp'>
            <h3 className='preview_heading'>PREVIEW</h3>
            <h1 className='preview_header2'>INTAKE FORM</h1>
            <span style={{ color: '#6F6C90', textAlign: 'center' }}>
              Kindly review these client responses carefully{' '}
            </span>

            <button className='btn back_button' onClick={() => {}}></button>

            <PrimaryButton
              type='bordered'
              className='edit_response_button'
              title='Edit Responses'
              icon='edit'
              onClick={() => {
                dispatch(openClientView());
                dispatch(
                  goToSpecificCompoClient(
                    LDcomponentWithIndexClient[
                      clientDashboardIds.QUEST_INTAKE_FORM1
                    ]
                  )
                );
              }}
            ></PrimaryButton>
          </div>
        </div>
        <div className='accordian'>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography fontWeight={600}>Product Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(productInformationQuestions)?.map(
                  ([key, value], index) =>
                    DB_KEY_PRODUCT_INFO[key] !== undefined && (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_PRODUCT_INFO[key]} : </span>
                          <span
                            style={{ color: '#619BF3', fontWeight: 'bolder' }}
                          >
                            {Array.isArray(value)
                              ? desiredData2(key, value)
                              : value.length === 0
                              ? ''
                              : typeof value === 'boolean'
                              ? desiredDataBool(key, value)
                              : ['targetCpa'].includes(key)
                              ? `$${value}`
                              : value}
                          </span>{' '}
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Preferences</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(preferenceQuestions)?.map(
                  ([key, value], index) =>
                    DB_KEY_PRODUCT_PREFERENCES[key] !== undefined && (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_PRODUCT_PREFERENCES[key]} : </span>
                          <span
                            style={{ color: '#619BF3', fontWeight: 'bolder' }}
                          >
                            {Array.isArray(value)
                              ? desiredData(key, value)
                              : (value || '').length === 0
                              ? ''
                              : breakFromUnderscore(value, key)}
                          </span>{' '}
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {platforms?.findIndex(
          (platform) => platform.platform === 'google-ads'
        ) !== -1 && (
          <div className='accordian'>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={600}>Google Targeting</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='wrap'>
                  {Object.entries(audienceQuestions)?.map(
                    ([key, value], index) =>
                      DB_KEY_AUDIENCE[key] !== undefined && (
                        <div className='tag'>
                          <div className='content'>
                            <span>{DB_KEY_AUDIENCE[key]} : </span>
                            <span
                              style={{ color: '#619BF3', fontWeight: 'bolder' }}
                            >
                              {key === 'ageRanges'
                                ? `${audiences.minAgeRange}-${audiences.maxAgeRange}`
                                : Array.isArray(value)
                                ? desiredData2(key, value)
                                : (value || '').length === 0
                                ? ''
                                : breakFromUnderscore(value)}
                            </span>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}

        <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Branding</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(branding ? branding : {})?.map(
                  ([key, value], index) =>
                    DB_KEY_BRANDING[key] !== undefined && (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_BRANDING[key]} : </span>
                          <span
                            style={{ color: '#619BF3', fontWeight: 'bolder' }}
                          >
                            {value.length !== 0 ? value : ''}
                          </span>
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Social Proof</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(socialProof ? socialProof : {})?.map(
                  ([key, value], index) =>
                    DB_KEY_SOCIAL_PROOF[key] !== undefined && (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_SOCIAL_PROOF[key]} : </span>
                          <span
                            style={{ color: '#619BF3', fontWeight: 'bolder' }}
                          >
                            {value.length !== 0 ? value : ''}
                          </span>
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      {platforms?.findIndex(
        (platform) => platform.platform === 'facebook-ads'
      ) !== -1 && (
        <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>FB Targeting</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(fbTargetingQuestion)?.map(
                  ([key, value], index) =>
                    fbTargetingQuestion[key] !== undefined && (
                      <div className='tag'>
                        <div className='content'>
                          <span>{fbTargeting[key].questName} : </span>
                          <span
                            style={{ color: '#619BF3', fontWeight: 'bolder' }}
                          >
                            {Array.isArray(value)
                              ? desiredData2(key, value)
                              : value}
                          </span>
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <div className='preview_footer_row'>
        <div className='footer_con'>
          <button
            className='back'
            onClick={() => {
              dispatch(goBackAmCompo());
            }}
          >
            BACK
          </button>
          <PrimaryButton title='PROCEED' onClick={onClickHandler} />
        </div>
      </div>
    </>
  );
};
export default IntakeFormPreview;
