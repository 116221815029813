import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, Checkbox } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import image from './pmaxIcons/image20.png';
import playIcon from './pmaxIcons/play-icon.svg';
import { imageValidator } from '../../utils/pmaxStrategy';
import successIcon from './pmaxIcons/success-icon.svg';
import failedIcon from './pmaxIcons/failed-icon.svg';
const ImageLibraryModal = ({ setModal, assetLibrary, handleAdd }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 838,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '16px 0 0 0',
    // height: '591px',
    borderRadius: '8px',
    outline: 'none',
  };
  const [selectedImages, setSelectedImages] = useState(() => {
    const valid = [];
    assetLibrary.forEach((asset) => {
      const isIamgeValid = imageValidator({
        type: asset.type,
        width: asset.imageFullSizeWidthPixels,
        height: asset.imageFullSizeHeightPixels,
        size: asset.imageFullSize,
      });
      if (isIamgeValid.error) return;
      valid.push({ ...asset, checked: false });
    });
    return valid;
  });

  const [invalidImages, setInvalidImages] = useState(() => {
    const invalid = [];
    assetLibrary.forEach((asset) => {
      const isIamgeValid = imageValidator({
        type: asset.type,
        width: asset.imageFullSizeWidthPixels,
        height: asset.imageFullSizeHeightPixels,
        size: asset.imageFullSize,
      });
      if (!isIamgeValid.error) return;
      invalid.push({ ...asset, checked: false });
    });
    return invalid;
  });

  const handleClick = (index) => {
    const newImageAsset = selectedImages.slice();
    newImageAsset[index] = {
      ...newImageAsset[index],
      checked: !newImageAsset[index].checked,
    };
    setSelectedImages(newImageAsset);
  };

  const onAdd = () => {
    const selected = selectedImages.filter((image) => image.checked === true);

    handleAdd(selected);
  };

  console.log('SI:', selectedImages);

  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='image-library-modal'
    >
      <Box sx={style}>
        <Close
          onClick={() => setModal(false)}
          style={{
            position: 'absolute',
            top: '1.30rem',
            right: '1.5rem',
            cursor: 'pointer',
            background: '#F2F2F2',
            borderRadius: 4,
          }}
        />
        <div
        //   style={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     height: '100%',
        //     gap: '0.5rem',
        //   }}
        >
          <div
            style={{
              padding: '0 0 1rem 1.5rem',
              borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
              width: '100%',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 20,
                color: '#1C1E21',
              }}
            >
              Library
            </Typography>
          </div>
          <div className='image-list-container'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.75rem',
                gap: 8,
              }}
            >
              <img src={successIcon} alt='success' />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: '19px',
                  color: '#333333',
                }}
              >
                The following images can be added :
              </Typography>
            </div>
            <div className='image-success-list-container'>
              <>
                {selectedImages.map((asset, index) => (
                  <div className='image' key={index}>
                    <img src={asset.imageFullSizeUrl} alt='image' />
                    <Checkbox
                      className='checkbox'
                      checked={asset.checked}
                      onClick={() => handleClick(index)}
                    />
                    <div className='name'>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: 12,
                          color: '#7B7B7B',
                        }}
                      >
                        Name:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          color: '#7B7B7B',
                        }}
                      >
                        {asset.name}
                      </Typography>
                    </div>
                    <div className='resolution'>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: 12,
                          color: '#7B7B7B',
                        }}
                      >
                        Resolution:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          color: '#7B7B7B',
                        }}
                      >
                        {`${Math.round(
                          asset.imageFullSizeWidthPixels
                        )}X${Math.round(asset.imageFullSizeHeightPixels)}`}
                      </Typography>
                    </div>
                  </div>
                ))}
              </>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '1rem 0',
                marginTop: '1.5rem',
                gap: 8,
              }}
            >
              <img src={failedIcon} alt='success' />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: '19px',
                  color: '#333333',
                }}
              >
                The following images cannot be added:
              </Typography>
            </div>
            <div className='image-failed-list-container'>
              <>
                {invalidImages.map((asset, index) => (
                  <div className='image' key={index}>
                    <img src={asset.imageFullSizeUrl} alt='image' />
                    <div className='name'>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: 12,
                          color: '#7B7B7B',
                        }}
                      >
                        Name:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          color: '#7B7B7B',
                        }}
                      >
                        {asset.name}
                      </Typography>
                    </div>
                    <div className='resolution'>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: 12,
                          color: '#7B7B7B',
                        }}
                      >
                        Resolution:
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          color: '#7B7B7B',
                        }}
                      >
                        {`${Math.round(
                          asset.imageFullSizeWidthPixels
                        )}X${Math.round(asset.imageFullSizeHeightPixels)}`}
                      </Typography>
                    </div>
                  </div>
                ))}
              </>
            </div>
          </div>

          <div
            style={{
              //   margin: '2rem 0',
              //   paddingTop: '2rem',
              display: 'flex',
              justifyContent: 'end',
              gap: '1.5rem',
              alignItems: 'center',
              height: 71,
              background: '#FFFFFF',
              borderRadius: '0px 0px 8px 8px',
              boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.02)',
              marginRight: 23,
            }}
          >
            {/* onClick={() => setModal(false)} */}
            <Button
              style={{
                width: '81px',
                height: '40px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                textTransform: 'capitalize',
                background: '#0869FB',
              }}
              onClick={() => onAdd()}
            >
              Add
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ImageLibraryModal;
