export const BASIC_INFORMATION_LD = [
  "company",
  "industry",
  "companyWebsite",
  "companyEmail",
  "companyAddress",
  "companyMailingAddress",
  "companyPhone",
  "primaryContactName",
  "primaryContactRole",
  "primaryContactNumber",
  "primaryContactEmail",
];

export const PLATFORM_INFORMATION_LD = [
  "platforms",
  "averageTicketPrice",
  "cltv",
  "crm",
  "leadSource",
  "competitors",
  // "adsManaged",
  // "brandDescription"
];

export const GROWTH_GOALS_LD = ["goals"];

export const PRODUCT_INFORMATION_LD = [
  "targetCpa",
  "profitMargin",
  "avgClosingPercent",
  "sessionality",
  "appRelatedService",
  "descriptions",
  "brandDescription",
  "leadEmailAddress",
  "brandServiceKeywords",
];

export const PREFERENCES_LD = [
  "competitors",
  "monthlyAdSpend",
  "leadPreferences",
  "trackingNumberDisplay",
  "handleDepletedAccountBalance",
];

export const AUDIENCE_LD = ["ageRanges", "geoTargets", "gender", "advertiseLocation", "maritalStatus", "customerCollar", "activitiesInterest", "excludeAudiences", "customerIncomeRange", "customerEducation", "customerEmployment", "formalCustomerPersona"]
