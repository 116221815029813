import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { info_icon as INFO } from "../../../assets/icons/common/common";
import { coldAudience, warmAudience } from "../../../utils/auditHdmUtil";
import GoToNextCompoButton from "../GoToNextCompoButton";
import ButtonToggle from "../../HDMAccountManager/Audit/Ad/ButtonToggle";
import "./adset.css";

const CommonAuditAdset = ({
  onBack,
  onNext,
}) => {
  console.log("control:adset")
  const [coldAudienceType, setColdAudienceType] = useState("CPA");
  const [warmAudienceType, setWarmAudienceType] = useState("CPA");
  const [coldAud, setColdAud] = useState("best");
  const [warmAud, setWarmAud] = useState("best");
  const campaign = useSelector((state) => state.hdmAudit.auditDetails);

  const onClickHandlerColdAud = () => {
    if (coldAud === "best") {
      setColdAud("worst");
    } else {
      setColdAud("best");
    }
  };
  const onClickHandlerWarmAud = () => {
    if (warmAud === "best") {
      setWarmAud("worst");
    } else {
      setWarmAud("best");
    }
  };

  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FFFFFF",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 150,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #F0F0F0",
      fontFamily: "Inter",
      borderRadius: 8,
    },
  }));
  const truncateString = (str) => {
    return str?.length > 32 ? str?.substring(0, 32) + "..." : str;
  };
  return (
    <div
      style={{
        marginTop: "24.5px",
        marginLeft: "23.5px",
        marginRight: "24.5px",
        overflowY: "auto",
      }}
    >
      <Box className="common3" style={{ marginTop: "25px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "71px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "16px",
              color: "#170F49",
              marginBottom: "16px",
              padding: "15px 0px 0px 17.25px ",
            }}
          >
            Cold Audience - Adsets
          </Typography>
          <Box
            style={{
              marginRight: "19px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <ButtonToggle
              selectedValue={coldAud}
              clickHandler={onClickHandlerColdAud}
            />
            <FormControl style={{ minWidth: "165px" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  setColdAudienceType(e.target.value);
                }}
                value={coldAudienceType}
                style={{
                  fontSize: "10px",
                  marginTop: "5px",
                  marginLeft: "22px",
                }}
              >
                <MenuItem value={"CPA"}>CPA</MenuItem>
                <MenuItem value={"CTR"}>CTR</MenuItem>
                <MenuItem value={"CPM"}>CPM</MenuItem>
                <MenuItem value={"CVR"}>CVR</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ background: "#F0F3FF", height: "55px" }}>
                <TableCell style={{ width: "267.67px" }}>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    Adset Name
                  </b>
                </TableCell>
                <TableCell style={{ width: "232.66px" }}>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    Campaign Name
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    CPA($)
                  </b>
                  <InfoTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "600",
                            fontSize: "12px",
                            lineHeight: "20px",
                          }}
                        >
                          Cost per Acquisition
                        </Typography>
                        <b
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "20px",
                          }}
                        >
                          {"Spends / Conversions"}
                        </b>
                      </React.Fragment>
                    }
                  >
                    <IconButton>
                      <img src={INFO} alt="" />
                    </IconButton>
                  </InfoTooltip>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    CTR
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "12px",
                              lineHeight: "20px",
                            }}
                          >
                            Click Through Rate
                          </Typography>
                          <b
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "20px",
                              textAlign: "center",
                            }}
                          >
                            {"Link clicks / Impressions"}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt="" />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    CPM($)
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "12px",
                              lineHeight: "20px",
                            }}
                          >
                            Cost Per Thousand
                          </Typography>
                          <b
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "20px",
                              textAlign: "center",
                            }}
                          >
                            {"Spends * 1000 / Impressions"}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt="" />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    CVR{" "}
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "12px",
                              lineHeight: "20px",
                            }}
                          >
                            Conversion Ratio
                          </Typography>
                          <b
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "20px",
                              textAlign: "center",
                            }}
                          >
                            {"Conversions / Link Clicks"}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt="" />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    Audience Size
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaign?.campaign && coldAudience(
                coldAud,
                coldAudienceType,
                campaign?.adset?.values
              )?.map((adset, index) => (
                <TableRow
                  key={index}
                 >
                  <TableCell>
                    <Tooltip title={adset[2]} arrow>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      
                      {truncateString(adset[2]) }
                    </b>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={adset[0]} arrow>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {truncateString( adset[0])}
                    </b>
                    </Tooltip>
                    
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {/* {adset[8].toFixed(2)} */}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[9].toFixed(2)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[10].toFixed(2)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[11].toFixed(2)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[13].toFixed(2)}
                    </b>
                  </TableCell>
                </TableRow>
               ))} 
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className="common3" style={{ marginTop: "25px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "71px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "16px",
              color: "#170F49",
              marginBottom: "16px",
              padding: "15px 0px 0px 17.25px ",
            }}
          >
            Warm Audience - Adsets
          </Typography>
          <Box
            style={{
              marginRight: "19px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <ButtonToggle
              selectedValue={warmAud}
              clickHandler={onClickHandlerWarmAud}
            />
            <FormControl style={{ minWidth: "165px" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  setWarmAudienceType(e.target.value);
                }}
                value={warmAudienceType}
                style={{
                  fontSize: "10px",
                  marginTop: "5px",
                  marginLeft: "22px",
                }}
              >
                <MenuItem value={"CPA"}>CPA</MenuItem>
                <MenuItem value={"CTR"}>CTR</MenuItem>
                <MenuItem value={"CPM"}>CPM</MenuItem>
                <MenuItem value={"CVR"}>CVR</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ background: "#F0F3FF", height: "55px" }}>
                <TableCell style={{ width: "267.67px" }}>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    Adset Name
                  </b>
                </TableCell>
                <TableCell style={{ width: "232.66px" }}>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    Campaign Name
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    CPA($)
                  </b>
                  <InfoTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "600",
                            fontSize: "12px",
                            lineHeight: "20px",
                          }}
                        >
                          Cost per Acquisition
                        </Typography>
                        <b
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "20px",
                          }}
                        >
                          {"Spends / Conversions"}
                        </b>
                      </React.Fragment>
                    }
                  >
                    <IconButton>
                      <img src={INFO} alt="" />
                    </IconButton>
                  </InfoTooltip>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    CTR
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "12px",
                              lineHeight: "20px",
                            }}
                          >
                            Click Through Rate
                          </Typography>
                          <b
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "20px",
                              textAlign: "center",
                            }}
                          >
                            {"Link clicks / Impressions"}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt="" />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    CPM($)
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "12px",
                              lineHeight: "20px",
                            }}
                          >
                            Cost Per Thousand
                          </Typography>
                          <b
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "20px",
                              textAlign: "center",
                            }}
                          >
                            {"Spends * 1000 / Impressions"}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt="" />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    CVR{" "}
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "600",
                              fontSize: "12px",
                              lineHeight: "20px",
                            }}
                          >
                            Conversion Ratio
                          </Typography>
                          <b
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "20px",
                              textAlign: "center",
                            }}
                          >
                            {"Conversions / Link Clicks"}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt="" />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                      lineHeight: "15px",
                    }}
                  >
                    Audience Size
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaign?.campaign && warmAudience(
                warmAud,
                warmAudienceType,
                campaign?.adset?.values
              )?.map((adset, index) => (
                <TableRow >
                  <TableCell >
                    <Tooltip title={adset[2]} arrow>
                    <b
                      style={{              
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                       
                      }}
                    >
                      {truncateString( adset[2]) }
                    </b>
                      
                    </Tooltip>
                    
                  </TableCell>
                  <TableCell>
                    <Tooltip title={adset[0]}>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >


                      {truncateString (adset[0])}
                    </b>
                    </Tooltip>
                    
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[8].toFixed(2)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[9].toFixed(2)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[10].toFixed(2)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[11].toFixed(2)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#000000",
                        lineHeight: "15px",
                      }}
                    >
                      {adset[13].toFixed(2)}
                    </b>
                  </TableCell>
                </TableRow>
               ) 
               )} 
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button className="back" onClick={onBack}>
            BACK
          </button>
          <GoToNextCompoButton title="Next" onNext={onNext}/>
        </div>
      </div>
    </div>
  );
};
export default CommonAuditAdset;
