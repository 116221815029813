import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { editicon as EDIT } from "../../../assets/icons/icon";
// import TICK from "./strategy-Icons/tick.png";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateWholeFormDataHdmAm } from "../../../api/HDMAM/form/action";
import { checked_icon as Check } from "../../../assets/icons/strategyIcons/strategyIcons";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import GoToNextCompoButton from "../GoToNextCompoButton.js";
import "./account.css";

const CommonAuditAccount = ({ 
  form,
  onFBAccountIdExists,
  onBack,
  spends,
  onAuditAccountChange,
  onNext,
}) => {
  console.log("controlacc")
  const dispatch = useDispatch();
  const [editButton, setEditButton] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("agencyId") !== "1") {
      form?.auditInfo?.accountAuditInfo?.currentMonthlyRevenue?.length === 0 &&
        dispatch({
          type: "UPDATE_AUDIT_ACCOUNT_HDM_AM",
          payload: {
            key: "currentMonthlyRevenue",
            value: parseInt(
              form?.onboardingInfo?.aboutBusiness?.monthlyRevenue || "0"
            ),
          },
        });
      form?.auditInfo?.accountAuditInfo?.spend?.length === 0 &&
        dispatch({
          type: "UPDATE_AUDIT_ACCOUNT_HDM_AM",
          payload: {
            key: "spend",
            value:
              parseInt(
                form?.onboardingInfo?.aboutBusiness?.fbCamaignBudget || "0"
              ) +
              parseInt(
                form?.onboardingInfo?.aboutBusiness?.googleCamaignBudget || "0"
              ) +
              parseInt(
                form?.onboardingInfo?.aboutBusiness?.tiktokCamaignBudget || "0"
              ),
          },
        });
    } else {
      const fbIndex = form?.onboardingInfo?.platformInfo?.platforms.findIndex(
        (platform) => platform.platform === "facebook-ads"
      );
      form?.auditInfo?.accountAuditInfo?.currentMonthlyRevenue?.length === 0 &&
        dispatch({
          type: "UPDATE_AUDIT_ACCOUNT_HDM_AM",
          payload: {
            key: "currentMonthlyRevenue",
            value:
              fbIndex !== -1
                ? parseInt(
                    form?.onboardingInfo?.platformInfo?.platforms[fbIndex].cpa
                  )
                : 0,
          },
        });
      form?.auditInfo?.accountAuditInfo?.spend?.length === 0 &&
        dispatch({
          type: "UPDATE_AUDIT_ACCOUNT_HDM_AM",
          payload: {
            key: "spend",
            value:
              fbIndex !== -1
                ? parseInt(
                    form?.onboardingInfo?.platformInfo?.platforms[fbIndex]
                      .spends
                  )
                : 0,
          },
        });
    }
  }, [form?.auditInfo?.accountAuditInfo]);
  useEffect(() => {
    if (form?.fbAdsAccountId?.length !== 0) {
      onFBAccountIdExists?.();
    }
  }, [form,onFBAccountIdExists]);
  useEffect(() => {
    dispatch(updateWholeFormDataHdmAm());
  }, []);

  const backClickHandler = () => {
    onBack?.();
  };
  return (
    <div
      style={{
        marginTop: "24px",
        marginLeft: "24px",
        marginRight: "27px",
        overflowY: "auto",
      }}
    >
      {/*<Box className="common">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "16px",
              color: "#170F49",
              marginRight: "24px",
            }}
          >
            Conversion Event{" "}
          </Typography>

          <Box>
            <FormControl style={{ minWidth: "165px" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // onChange={(e)=>{setTypeInefficient(e.target.value)}}
                // value={typeInefficient}
                value="PURCHASES"
                style={{ fontSize: "10px", marginTop: "5px" }}
              >
                <MenuItem value={"PURCHASES"}>PURCHASES</MenuItem>
                <MenuItem value={"Add to Cart"}>Add to Cart</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </Box>*/}

      <Box className="common2">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "16px",
              color: "#170F49",
            }}
          >
            Historical Data
          </Typography>
          <button
            className="edit_button"
            onClick={() => setEditButton(!editButton)}
          >
            <img src={editButton ? Check : EDIT} alt="" />
          </button>
        </div>

        <TableContainer
          style={{ border: "1px solid #F0F0F0", marginTop: "8px" }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    background: "#FAFAFA",
                    padding: "10px 16px",
                    width: "508px",
                    height: "64px",
                  }}
                >
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    Current monthly Revenue (in $)
                  </b>
                </TableCell>
                <TableCell style={{ padding: "10px 16px" }}>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    {""}
                  </b>{" "}
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    {editButton ? (
                      <TextField
                        value={
                          form?.auditInfo?.accountAuditInfo
                            ?.currentMonthlyRevenue
                        }
                        variant="standard"
                        type="number"
                        onChange={(e) =>
                          dispatch({
                            type: "UPDATE_AUDIT_ACCOUNT_HDM_AM",
                            payload: {
                              key: "currentMonthlyRevenue",
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      form?.auditInfo?.accountAuditInfo?.currentMonthlyRevenue
                    )}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    background: "#FAFAFA",
                    padding: "10px 16px",
                    width: "508px",
                    height: "64px",
                  }}
                >
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    Spend (in $)
                  </b>
                </TableCell>
                <TableCell style={{ padding: "10px 16px" }}>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    {""}
                  </b>{" "}
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    {editButton ? (
                      <TextField
                        value={spends}
                        variant="standard"
                        type="number"
                        onChange={(e) =>
                          dispatch({
                            type: "UPDATE_AUDIT_ACCOUNT_HDM_AM",
                            payload: { key: "spend", value: e.target.value },
                          })
                        }
                      />
                    ) : (
                      spends
                    )}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    background: "#FAFAFA",
                    padding: "10px 16px",
                    width: "508px",
                    height: "64px",
                  }}
                >
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    Historical avg platform ROAS
                  </b>
                </TableCell>
                <TableCell style={{ padding: "10px 16px" }}>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    {editButton ? (
                      <TextField
                        value={
                          form?.auditInfo?.accountAuditInfo
                            ?.historicalAvgPlatformRoas
                        }
                        variant="standard"
                        type="number"
                        onChange={(e) =>
                          dispatch({
                            type: "UPDATE_AUDIT_ACCOUNT_HDM_AM",
                            payload: {
                              key: "historicalAvgPlatformRoas",
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      form?.auditInfo?.accountAuditInfo
                        ?.historicalAvgPlatformRoas
                    )}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    background: "#FAFAFA",
                    padding: "10px 16px",
                    width: "508px",
                    height: "64px",
                  }}
                >
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    Historical avg blended ROAS
                  </b>
                </TableCell>
                <TableCell style={{ padding: "10px 16px" }}>
                  {" "}
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    {editButton ? (
                      <TextField
                        value={
                          form?.auditInfo?.accountAuditInfo
                            ?.historicalAvgBlendedRoas
                        }
                        variant="standard"
                        type="number"
                        onChange={(e) =>
                          dispatch({
                            type: "UPDATE_AUDIT_ACCOUNT_HDM_AM",
                            payload: {
                              key: "historicalAvgBlendedRoas",
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    ) : (
                      form?.auditInfo?.accountAuditInfo
                        ?.historicalAvgBlendedRoas
                    )}
                  </b>{" "}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <div className="change_accnt">
        <PrimaryButton
          title="Change Audit Account"
          onClick={onAuditAccountChange}
        />
      </div>
      <br />
      {/* <div className="change_accnt">
        <PrimaryButton
          title="Generate Audit Again"
          onClick={() => initialiseAccountAgain()}
        />
      </div> */}
      <div className="preview_footer_row">
        <div className="footer_con">
          <button className="back" onClick={backClickHandler}>
            BACK
          </button>
          <GoToNextCompoButton title="Next" onNext={onNext} />
        </div>
      </div>
    </div>
  );
};
export default CommonAuditAccount;
