import { useDispatch, useSelector } from 'react-redux';
import CommonAuditAd from './CommonAuditAd';
import { BACKEND_STATUS, isPlatformSelected } from '../../../utils/accountManagerUtil';
import {onNextLD} from "../GoToNextCompoButton"
import { goBackAmCompo } from '../../../api/accountManager/componentTracking/action';

export default function LDAuditAd() {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form.form);
  const onboardingInfo = useSelector((state) => state?.form?.form?.onboardingInfo);

  const nextDisable = () => {
    return isPlatformSelected(form, 'google-ads') !== -1 && form.auditInfo.status !== BACKEND_STATUS.COMPLETED
  }
  
  const onBack = () => {
    dispatch(goBackAmCompo());
  };

  return (
    <CommonAuditAd
      onboardingInfo={onboardingInfo}
      nextDisable={nextDisable}
      onNext={() => onNextLD(dispatch, {
        type:"AUDIT_AD",
        data:form
      })}
      onBack={onBack}
    />
  )
}