import { Box, Grid, Input } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFacebbokAccounts } from "../../../api/HDMAM/audit/action";
import {
  allAlreadyMetadataAccounts,
  isAccountValid,
  isMetaDataArrivedFacebook,
} from "../../../api/HDMAM/audit/saga";
import { saveHdmAmFormsData } from "../../../api/HDMAM/form/action";
import { getHDMClientDetailsByFormId } from "../../../api/HDMClient/action";
import { search as SEARCH } from "../../../assets/icons/icon";
import DateModal from "../../AccountManager/DateModal/DateModal";
import LoaderModal from "../../AccountManager/LoaderModal/LoaderModal";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
import HoldTight from "../../HDMAccountManager/Audit/HoldTight";
import Option from "../../HDMAccountManager/Audit/Option";
import "../../GoogleAudit/googleaccount.css";

const CommonFacebookAccounts = ({ 
  fbAccountId,
  onGenerateReport,
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const hdmForm = useSelector((state) => state.hdmAudit);
  const [accountId, setAccount] = useState(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [selectedCustomerName, setSelectedCustomerName] = useState(null);
  const [facebookAccounts, setFacebookAccounts] = useState([
    ...hdmForm.facebookAccounts,
  ]);
  const [searchedValue, setSearchedValue] = useState("");
  const isUserFacebookAccountValid = useSelector(
    (state) => state.hdmAudit.isUserFacebookAccountValid
  );
  const onGenerateReportHandler = () => {
    setShowDateModal(false);
    setShowLoaderModal(true);
    onGenerateReport?.({ startDate, endDate, accountId: accountId, selectedCustomerName })
      .finally(() => {
        setShowLoaderModal(false);
      });
  };
  const [mappedAlreadyMetaData, setAlreadyMappedData] = useState({});

  useEffect(() => {
    setFacebookAccounts([...hdmForm.facebookAccounts]);
  }, [hdmForm.facebookAccounts]);

  useEffect(() => {
    dispatch(getFacebbokAccounts());
    dispatch({
      type: "UPDATE_WHOLE_HDM_AM_DETAILS_SAGA",
    });
  }, []);

  useEffect(() => {
    if (
      facebookAccounts.length !== 0 &&
      mappedAlreadyMetaData[fbAccountId] &&
      mappedAlreadyMetaData[fbAccountId].status === "COMPLETE"
    ) {
      let obj = null;
      facebookAccounts.forEach((account, index) => {
        if (account.accountId === fbAccountId) {
          obj = account;
        }
      });
      if (obj) {
        setAccount(obj.accountId);
        setSelectedCustomerName(obj.name);
      } else {
        setAccount(facebookAccounts[0].accountId);
        setSelectedCustomerName(facebookAccounts[0].name);
      }
    } else if (facebookAccounts.length !== 0) {
      if (
        mappedAlreadyMetaData[fbAccountId] &&
        mappedAlreadyMetaData[fbAccountId].status === "ONGOING"
      ) {
        let account = facebookAccounts[0];
        facebookAccounts.forEach((accountT) => {
          if (accountT.accountId === fbAccountId) {
            account = accountT;
          }
        });
        setAccount(account.accountId);
        setSelectedCustomerName(account.name);
      } else {
        setAccount(facebookAccounts[0].accountId);
        setSelectedCustomerName(facebookAccounts[0].name);
      }
    }
  }, [facebookAccounts, mappedAlreadyMetaData, fbAccountId]);

  const checkAlreadyMetaDataAccount = () => {
    allAlreadyMetadataAccounts()
      .then((res) => {
        setAlreadyMappedData(res.data);
      })
      .catch((err) => {
        setAlreadyMappedData({});
      });
  };

  useEffect(() => {
    checkAlreadyMetaDataAccount();
    dispatch(getHDMClientDetailsByFormId())
  }, []);

  //is user account valid
  useEffect(() => {
    if (
      mappedAlreadyMetaData[fbAccountId] &&
      mappedAlreadyMetaData[fbAccountId].status === "COMPLETE"
    ) {
      dispatch({
        type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
        payload: "LIST_OF_ACCOUNTS",
      });
      setShowDateModal(true);
    } else if (
      mappedAlreadyMetaData[fbAccountId] &&
      mappedAlreadyMetaData[fbAccountId].status === "ONGOING"
    ) {
      dispatch({
        type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
        payload: "HOLD_TIGHT",
      });
      setShowDateModal(false);
    } else {
      dispatch({
        type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
        payload: "USER_ACCOUNT_INVALID",
      });
      setShowDateModal(false);
    }
  }, [mappedAlreadyMetaData, fbAccountId]);

  //is meta data arrived
  useEffect(() => {
    setInterval(() => {
      if (
        mappedAlreadyMetaData[fbAccountId] &&
        mappedAlreadyMetaData[fbAccountId].status === "ONGOING"
      ) {
        isMetaDataArrivedFacebook(fbAccountId)
          .then((res) => {
            if (res[fbAccountId].status === "COMPLETE") {
              dispatch({
                type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
                payload: "LIST_OF_ACCOUNTS",
              });
              dispatch(getFacebbokAccounts());
              checkAlreadyMetaDataAccount();
              setShowDateModal(true);
            }
          })
          .catch((err) => {
            dispatch({
              type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
              payload: "HOLD_TIGHT",
            });
          });
      }
    }, 60000);
  });

  const hitInitialiseApi = () => {
    if (
      mappedAlreadyMetaData[accountId] &&
      mappedAlreadyMetaData[accountId].status === "COMPLETE"
    ) {
      setShowDateModal(true);
      dispatch({
        type: "UPDATE_FACEBOOK_ACCOUNT_ID_HDM",
        payload: {
          accountId: accountId,
          accountName: selectedCustomerName,
        },
      });
      dispatch(saveHdmAmFormsData());
    } else {
      isAccountValid(accountId)
        .then((res) => {
          dispatch({
            type: "UPDATE_FACEBOOK_ACCOUNT_ID_HDM",
            payload: {
              accountId: accountId,
              accountName: selectedCustomerName,
            },
          });
          dispatch(saveHdmAmFormsData());
          dispatch({
            type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
            payload: "HOLD_TIGHT",
          });
          checkAlreadyMetaDataAccount();
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
            payload: "USER_ACCOUNT_INVALID",
          });
          dispatch({
            type: "UPDATE_FACEBOOK_ACCOUNT_ID_HDM",
            payload: {
              accountId: accountId,
              accountName: selectedCustomerName,
            },
          });
          dispatch(saveHdmAmFormsData());
        });
    }
  };

  const searchFacebookAccount = (value) => {
    setSearchedValue(value);
    const updatedFilterd = hdmForm.facebookAccounts.filter((account) => {
      if (
        account.name.toLowerCase().includes(value.toLowerCase()) ||
        account.accountId.toLowerCase().includes(value.toLowerCase())
      ) {
        return account;
      }
    });
    setFacebookAccounts(updatedFilterd);
  };

  console.log("mappedAlreadyMetaData", mappedAlreadyMetaData);

  return isUserFacebookAccountValid === "USER_ACCOUNT_INVALID" ? (
    <div className="googlebox">
      <div
        style={{
          fontFamily: "sans-serif",
          fontWeight: "400",
          marginBottom: "0rem",
        }}
      >
        <h2>Ad Account</h2>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
          <img src={SEARCH} />
          <Input
            placeholder="Data Search"
            size="small"
            disableUnderline={true}
            value={searchedValue}
            onChange={(e) => searchFacebookAccount(e.target.value)}
          />
        </Box>
        <div className="line"></div>
      </div>

      <div className="assignee assignee_add_acccount">
        <span>Assigned</span>
        <div className="ft">
          <div>
            <BpRadio checked={true} />
          </div>
          <div className="ai">
            <span>{selectedCustomerName}</span>
            <p>Account Id: {accountId}</p>
          </div>
        </div>
      </div>

      <div className="line"></div>

      <div className="available">
        <span style={{ color: "#999999", fontFamily: "sans-serif" }}>
          Available
        </span>
        <div>
          <Grid>
            <div className="radio_add_acccount">
              {facebookAccounts.map((account, index) => (
                <Option
                  name={account.name}
                  account={account.accountId}
                  checked={accountId === account.accountId ? true : false}
                  setAccount={setAccount}
                  setSelectedCustomerName={setSelectedCustomerName}
                />
              ))}
            </div>
          </Grid>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="footer_down">
          <PrimaryButton title="Next" onClick={() => hitInitialiseApi()} />
        </div>
      </div>
      {showDateModal && (
        <DateModal
          setShowDateModal={setShowDateModal}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onClickHandler={onGenerateReportHandler}
        />
      )}
      {showLoaderModal && <LoaderModal />}
    </div>
  ) : isUserFacebookAccountValid === "LIST_OF_ACCOUNTS" ? (
    <>
      <div className="googlebox">
        <div
          style={{
            fontFamily: "sans-serif",
            fontWeight: "400",
            marginBottom: "0rem",
          }}
        >
          <h2>Ad Account</h2>
          <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
            <img src={SEARCH} />
            <Input
              placeholder="Data Search"
              size="small"
              disableUnderline={true}
              value={searchedValue}
              onChange={(e) => searchFacebookAccount(e.target.value)}
            />
          </Box>
          <div className="line"></div>
        </div>

        <div className="assignee assignee_add_acccount">
          <span>Assigned</span>
          <div className="ft">
            <div>
              <BpRadio checked={true} />
            </div>
            <div className="ai">
              <span>{selectedCustomerName}</span>
              <p>Account Id: {accountId}</p>
            </div>
          </div>
        </div>

        <div className="line"></div>

        <div className="available">
          <span style={{ color: "#999999", fontFamily: "sans-serif" }}>
            Available
          </span>
          <div>
            <Grid>
              <div className="radio_add_acccount">
                {facebookAccounts.map((account, index) => (
                  <Option
                    name={account.name}
                    account={account.accountId}
                    checked={accountId === account.accountId ? true : false}
                    setAccount={setAccount}
                    setSelectedCustomerName={setSelectedCustomerName}
                  />
                ))}
              </div>
            </Grid>
          </div>
        </div>
        <div className="preview_footer_row">
          <div className="footer_down">
            <PrimaryButton title="Next" onClick={() => hitInitialiseApi()} />
          </div>
        </div>
        {showDateModal && (
          <DateModal
            setShowDateModal={setShowDateModal}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onClickHandler={onGenerateReportHandler}
          />
        )}
        {showLoaderModal && <LoaderModal />}
      </div>
    </>
  ) : (
    <HoldTight />
  );
};
export default CommonFacebookAccounts;
