import { useDispatch, useSelector } from 'react-redux';
import CommonFacebookAccounts from './CommonFacebookAccounts';
import { useCallback } from 'react';
import { getAuditFacebook, hitTargetingKeywordsApi } from '../../../api/HDMAM/audit/saga';
import { updateCompoReduxAM } from '../../../api/accountManager/componentTracking/action';
import { saveHdmAmFormsData } from '../../../api/HDMAM/form/action';

export default function LDFacebookAccounts () {
  const dispatch = useDispatch();

  const fbAccountId = useSelector((state) => state?.form?.form?.fbAdsAccountId);

  // return a promise
  const onGenerateReport = useCallback((options) => {
    return getAuditFacebook(options, "LD")
      .then(async (res) => {
        const apiHit = await hitTargetingKeywordsApi();
        dispatch({ type: "GET_AUDIT_DETAILS_HDM", payload: res });
        dispatch({
          type: "UPDATE_FACEBOOK_ACCOUNT_ID_HDM",
          payload: {
            accountId: options.accountId,
            accountName: options.selectedCustomerName,
          },
        });
        dispatch(saveHdmAmFormsData());
        dispatch(updateCompoReduxAM());
      })
  }, [dispatch]);

  return (
    <CommonFacebookAccounts
     fbAccountId={fbAccountId}
     onGenerateReport={onGenerateReport} 
    />
  )
}