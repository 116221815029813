import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box, Grid, Input } from "@mui/material";
import "./googleaccount.css";
import { search as SEARCH } from "../../assets/icons/icon";
import BpRadio from "../ClientDashboard/CustomRadio/Radio";
import Option from "../AccountManager/Option";
import PrimaryButton from "../ButtonPrimary/Buttons";
import DateModal from "../AccountManager/DateModal/DateModal";
import {
  getGoogleCustomersReducers,
  createGoogleAdsAuditReducer,
  updateSelectedCustomerId,
  updateSelectedLoginCustomerId,
  updateSelectedLoginCustomerName,
  updateSelectedCustomerName,
  updateGotInsights,
  getInsightsForFormIdReducer,
} from "../../api/accountManager/googleAdsCustomer/slice";
import { updateFormReducer } from "../../api/accountManager/forms/slice";
import LoaderModal from "../AccountManager/LoaderModal/LoaderModal";
import { useGoogleAccountMetaData } from './service';

const CommonGoogleAdsAccount = ({ 
  form,
  onGotInsights,
  onHaveInsights,
}) => {
  const dispatch = useDispatch();
  const googleAdsCustomer = useSelector(
    (store) => store.googleCustomers.googleAdCustomers
  );
  
  const googleSharedAssetsAccounts = useSelector(
    (state) => state.sharedAssets.googleSharedAssetsAccounts
  );
  const selectedCustomerId = useSelector(
    (store) => store.googleCustomers.selectedCustomerId
  );
  const selectedLoginCustomerId = useSelector(
    (store) => store.googleCustomers.selectedLoginCustomerId
  );
  const selectedCustomerName = useSelector(
    (store) => store.googleCustomers.selectedCustomerName
  );
  const selectedLoginCustomerName = useSelector(
    (store) => store.googleCustomers.selectedLoginCustomerName
  );
  const gotInsights = useSelector((store) => store.googleCustomers.gotInsights);
  const insights = useSelector((store) => store.googleCustomers.insights);

  const haveInsights = useSelector(
    (store) => store.googleCustomers.haveInsights
  );

  const { data: googleAccMetaData } = useGoogleAccountMetaData(
    {
      customerId: selectedCustomerId,
      loginCustomerId: selectedLoginCustomerId,
    },
    { enabled: !!selectedCustomerId && !!selectedLoginCustomerId }
  );

  const [startDate, setStartDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [showDateModal, setShowDateModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [googleCustomers, setGoogleCustomers] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");

  useEffect(() => {
    // dispatch(getGoogleCustomersReducers());
    if (!insights.length) {
      dispatch(getInsightsForFormIdReducer());
    }
  }, []);

  useEffect(() => {
    if (gotInsights) {
      setShowLoaderModal(false);
      dispatch(
        updateFormReducer({
          gAdsCustomerId: selectedCustomerId,
          clientGoogleAdsInfo: {
            customerId: selectedCustomerId,
            loginCustomerId: selectedLoginCustomerId,
            customerName: selectedCustomerName,
            loginCustomerName: selectedLoginCustomerName,
          },
        })
      );
      onGotInsights?.();
      dispatch(updateGotInsights(false));
    }
  }, [gotInsights]);

  useEffect(() => {
    if (!haveInsights) {
      dispatch(getGoogleCustomersReducers());
    } else {
      onHaveInsights?.();
    }
  }, [haveInsights]);

  useEffect(() => {
    if (selectedCustomerId) {
      const googleCustomer = googleAdsCustomer.find(
        (customer) =>
          customer.customerId === selectedCustomerId &&
          customer.loginCustomerId === selectedLoginCustomerId
      );
      dispatch(updateSelectedCustomerName(googleCustomer.customerName));
      dispatch(
        updateSelectedLoginCustomerName(googleCustomer.loginCustomerName)
      );
    } else {
      if (form) {
        if (form?.accessSharing?.googleAccess?.googleAdAccount?.accountId) {
          const presentCustomer = googleAdsCustomer.find(
            (acc) =>
              acc.customerId ===
              form?.accessSharing?.googleAccess?.googleAdAccount?.accountId
          );
          if (presentCustomer) {
            dispatch(updateSelectedCustomerId(presentCustomer.customerId));
            dispatch(updateSelectedCustomerName(presentCustomer.customerName));
            dispatch(
              updateSelectedLoginCustomerId(presentCustomer.loginCustomerId)
            );
            dispatch(
              updateSelectedLoginCustomerName(presentCustomer.loginCustomerName)
            );
          }
        } else {
          dispatch(updateSelectedCustomerId(googleAdsCustomer[0]?.customerId));
          dispatch(
            updateSelectedLoginCustomerId(googleAdsCustomer[0]?.loginCustomerId)
          );
          dispatch(
            updateSelectedCustomerName(googleAdsCustomer[0]?.customerName)
          );
          dispatch(
            updateSelectedLoginCustomerName(
              googleAdsCustomer[0]?.loginCustomerName
            )
          );
        }
      }
    }
  }, [selectedCustomerId, selectedLoginCustomerId, googleAdsCustomer, form]);

  useEffect(() => {
    if (!searchedValue) {
      setGoogleCustomers(googleAdsCustomer);
    } else {
      setGoogleCustomers(
        googleAdsCustomer.filter((customer) =>
          (customer.customerName || "")
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
        )
      );
    }
  }, [googleAdsCustomer, searchedValue]);

  const onGenerateReportHandler = () => {
    dispatch(
      createGoogleAdsAuditReducer({
        startDate: startDate,
        endDate: endDate,
      })
    );
    setShowDateModal(false);
    setShowLoaderModal(true);
  };
  return (
    <div className="googlebox">
      <div
        style={{
          fontFamily: "sans-serif",
          fontWeight: "400",
          marginBottom: "0rem",
        }}
      >
        <h2>Ad Account</h2>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
          <img src={SEARCH} />
          <Input
            placeholder="Data Search"
            size="small"
            disableUnderline={true}
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
          />
        </Box>
        <div className="line"></div>
      </div>

      <div className="assignee assignee_add_acccount">
        <span>Assigned</span>
        <div className="ft">
          <div>
            <BpRadio checked={true} />
          </div>
          <div className="ai">
            <span>{selectedCustomerName}</span>
            <p>
              Account Id: {selectedCustomerId} ({selectedLoginCustomerName})
            </p>
          </div>
        </div>
      </div>

      <div className="line"></div>

      <div className="available">
        <span>Available</span>
        <div>
          <Grid>
            <div className="radio_add_acccount">
              {googleCustomers.map((customer, idx) => (
                <Option
                  key={idx}
                  accountId={customer.customerId}
                  customerName={customer.customerName}
                  loginAccountId={customer.loginCustomerId}
                  loginCustomerName={customer.loginCustomerName}
                  checked={
                    customer.customerId === selectedCustomerId &&
                    customer.loginCustomerId === selectedLoginCustomerId
                  }
                />
              ))}
            </div>
          </Grid>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="footer_down">
          {/* <button className="download">Download</button> */}
          <PrimaryButton title="Next" onClick={() => setShowDateModal(true)} />
        </div>
      </div>
      {showDateModal && (
        <DateModal
          setShowDateModal={setShowDateModal}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onClickHandler={onGenerateReportHandler}
          timeZone={googleAccMetaData?.timeZone}
        />
      )}
      {showLoaderModal && <LoaderModal />}
    </div>
  );
};
export default CommonGoogleAdsAccount;
