import React from 'react';
import { Typography } from '@mui/material';
import './button-toggle.css';


const ButtonToggle = (props) => {
  return (
    <div className='button-container' onClick={() => props.clickHandler()}>
      <div
        className={`${props.selectedValue === 'best' ? 'selected-best' : ''}`}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 10,
            lineHeight: '12px',
            color: props.selectedValue === 'best' ? '#FFFFFF' : '#000000',
          }}
          className='best'
        >
          Best
        </Typography>
      </div>
      <div
        className={`${props.selectedValue === 'worst' ? 'selected-worst' : ''}`}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 10,
            lineHeight: '12px',
            color: props.selectedValue === 'worst' ? '#FFFFFF' : '#000000',
          }}
          className='worst'
        >
          Worst
        </Typography>
      </div>
    </div>
  );
};

export default ButtonToggle;