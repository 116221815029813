import { dbStatus } from "../components/ClientDashboard/HomePage/HomePage";

export const checkForAccess = (clientState) => {
  const platforms=clientState?.onboardingInfo?.clientAccess?.platforms;
  const accessSharing = {...clientState?.accessSharing};
  if(!platforms || !(platforms || []).length){
    return dbStatus.PENDING;
  }
  //pending
  let isPending=false;
  platforms?.forEach((platform) => {
    if(platform.platform === "facebook-ads"){
      if(accessSharing?.fbAccess?.adAccount?.status ===  dbStatus.VERIFYING && accessSharing?.fbAccess?.adAccount?.accountId?.length === 0){
        isPending=true;
      }
    }else if(platform.platform === "google-ads"){
      if(accessSharing?.googleAccess?.googleAdAccount?.status === dbStatus.VERIFYING && accessSharing?.googleAccess?.googleAdAccount?.accountId?.length === 0){
        isPending=true;
      }
    }
  });
  if(isPending){
    return dbStatus.PENDING;
  }
  //verifying
  let isVerifying=false;
  platforms.forEach((platform) => {
    if(platform.platform === "facebook-ads"){
      if(accessSharing?.fbAccess?.adAccount?.status ===  dbStatus.VERIFYING && accessSharing?.fbAccess?.adAccount?.accountId?.length !== 0){
        isVerifying=true;
      }
    }else if(platform.platform === "google-ads"){
      if(accessSharing?.googleAccess?.googleAdAccount?.status === dbStatus.VERIFYING && accessSharing?.googleAccess?.googleAdAccount?.accountId?.length !== 0){
        isVerifying=true;
      }
    }
  });
  if(isVerifying){
    return dbStatus.VERIFYING;
  }
  //access revoked
  let isAccessRevoked=false;
  platforms.forEach((platform) => {
    if(platform.platform === "facebook-ads"){
      if(accessSharing?.fbAccess?.adAccount?.status ===  dbStatus.ACCESS_REVOKED){
        isAccessRevoked=true;
      }
    }else if(platform.platform === "google-ads"){
      if(accessSharing?.googleAccess?.googleAdAccount?.status === dbStatus.ACCESS_REVOKED){
        isAccessRevoked=true;
      }
    }
  })
  if(isAccessRevoked){
    return dbStatus.ACCESS_REVOKED;
  }
  //Read Access
  let isRead=false;
  platforms.forEach((platform) => {
    if(platform.platform === "facebook-ads"){
      if(accessSharing?.fbAccess?.adAccount?.status ===  dbStatus.READ_ACCESS){
        isRead=true;
      }
    }else if(platform.platform === "google-ads"){
      if(accessSharing?.googleAccess?.googleAdAccount?.status === dbStatus.READ_ACCESS){
        isRead=true;
      }
    }
  });
  if(isRead){
    return dbStatus.READ_ACCESS;
  }
  //complete access
  let countComplete=0;
  platforms.forEach((platform) => {
    if(platform.platform === "facebook-ads"){
      if(accessSharing?.fbAccess?.adAccount?.status ===  dbStatus.COMPLETE_ACCESS){
        countComplete++;
      }
    }else if(platform.platform === "google-ads"){
      if(accessSharing?.googleAccess?.googleAdAccount?.status === dbStatus.COMPLETE_ACCESS){
        countComplete++;
      }
    }
  });
  if(countComplete === platforms?.length){
    return dbStatus.COMPLETE_ACCESS;
  }
};

export const getRoas = (roas, spend, revenue) => {
  if(roas >= 0.2){
    return roas;
  } else {
    return revenue/spend ;
  }
}

export const getFacebookBudget = (platforms) => {
  if (!platforms) {
    return 0;
  }
  const facebookPlatformData = platforms.find(
    (platform) => platform.platform === "facebook-ads"
  );
  return facebookPlatformData ? facebookPlatformData.spends : 0;
};