import React from 'react';
import { Tooltip, Typography } from '@mui/material';
const CustomToolkitForInfo = (props) => {
  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-arrow': {
            color: 'white',
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'white',
            color: 'black',
            marginBottom: '20px',
            filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
            padding: '10px 8px 8px 12px',
          },
        },
      }}
      title={
        <>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '20px',
              color: '#333333',
            }}
          >
            {props.originalText}
          </Typography>
        </>
      }
      arrow
      placement={props.type === 'facebook' ? 'top' : 'bottom'}
    >
      <Typography>{props.text}</Typography>
    </Tooltip>
  );
};

export default CustomToolkitForInfo;
