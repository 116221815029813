import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHDMClientDetailsByFormId } from '../../../api/HDMClient/action';
import { goToSpecificCompoHDMClient } from '../../../api/HDMClient/componentTracking/action';
import { setLocalStorage } from '../../../api/localStorage/localStorage';
import { network_icon as Network } from '../../../assets/icons/HDMAccount/HDMAccount';
import Shopify from '../../../assets/icons/HDMAccount/ShopifyIcon.png';
import { ready as readyGif } from '../../../assets/icons/proposal/proposal';
import { fb_icon as FB } from '../../../assets/icons/social/social';
import { google2 as GOOGLE } from '../../../assets/icons/social/social';
import InputComponent from '../HDMInputComponent/HDMInputComponent';
import './Access.css';
import READ from './icons/read-icon.png';
import TIKTOK from './icons/tik-tok.png';
import { updatedStatusHdm } from '../../../api/HDMClient/saga';
import { goToSpecificCompoClient } from '../../../api/componentTracking/action';
import { getClientDetailsByFormId } from '../../../api/client/action';
import { sharedAssetsPostApi } from '../../../api/sharedAssets/saga';
import {timeprocess as timeclock} from '../../../assets/icons/icon'
import {
  dbStatus,
  mappedStatusDb,
} from '../../ClientDashboard/HomePage/HomePage';

const validate = (platformSelected, accountId) => {
  if (
    platformSelected.length === 2 &&
    (accountId.fbAccess.adAccount.accountId.trim().length === 0 ||
      accountId.googleAccess.googleAdAccount.accountId?.trim().length === 0)
  ) {
    return true;
  } else if (
    platformSelected.length === 1 &&
    platformSelected[0].platform === 'google-ads' &&
    accountId.googleAccess.googleAdAccount.accountId?.trim().length === 0
  ) {
    return true;
  } else if (
    platformSelected.length === 1 &&
    platformSelected[0].platform === 'facebook-ads' &&
    accountId.fbAccess.adAccount.accountId?.trim().length === 0
  ) {
    return true;
  }
  return false;
};

const currentFormType = (agencyType, reduxStates) => {
  if (agencyType === 'LD') {
    return reduxStates.client;
  } else {
    return reduxStates.hdmClient;
  }
};

const currentAccessSharingType = (agencyType, reduxState) => {
  if (agencyType === 'LD') {
    return reduxState?.client?.accessSharing;
  } else {
    return reduxState?.hdmClient?.accessSharing;
  }
};

const HDMAccess = ({ agencyType = 'HDM' }) => {
  const dispatch = useDispatch();
  const accountid = currentAccessSharingType(
    agencyType,
    useSelector((state) => state)
  );
  const reduxStates = currentFormType(
    agencyType,
    useSelector((state) => state)
  );
  const platformSelected =
    agencyType === 'LD'
      ? reduxStates?.onboardingInfo?.platformInfo?.platforms
      : reduxStates?.onboardingInfo?.clientAccess?.platforms;
  const [documentGenPage, setDocumentGenPage] = useState(false);
  useEffect(() => {
    if (agencyType === 'LD') {
      dispatch(getClientDetailsByFormId());
    } else {
      dispatch(getHDMClientDetailsByFormId());
    }
  }, []);
  return (
    <>
      <div style={{ width: '100%' }}>
        <div className='read_container-hdm'>
          <Paper spacing={2} className='readaccess_paper'>
            {documentGenPage || accountid?.status === 'ONGOING' ? (
              <div
                className='layout_submission'
                style={{
                  width: '100%',
                  margin: 'auto',
                }}
              >
                <div className='center'>
                  <img
                    src={timeclock}
                    className="img-submit"
                    style={{
                      width: 180,
                      height: 180,
                    }}
                  />
                  <div className='submit-title'>
                    Hold Tight!{' '}
                    {agencyType === 'HDM' ? 'Onboarding Document' : 'Proposal'}{' '}
                    Is Being Generated.
                  </div>
                  <div className='submit-desc'>
                    We will notify you as soon as the{' '}
                    {agencyType === 'HDM' ? 'onboarding doc' : 'proposal'} is
                    ready
                    <br />
                    for your approval.
                  </div>
                  <Button
                    variant='contained'
                    style={{
                      marginTop: '2rem',
                      width: 110,
                    }}
                    onClick={() => {
                      if (agencyType === 'LD') {
                        dispatch(goToSpecificCompoClient(0));
                        window.location.href = '/client';
                      } else {
                        dispatch(goToSpecificCompoHDMClient(0));
                        window.location.href = '/hdmclient';
                      }
                    }}
                  >
                    Home
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className='layout'>
                  <div className='top_read_section'>
                    <h3 className='access_heading'>ACCESS</h3>

                    {/* <span className="lock_icon"></span> */}
                    <h2 className='read_title'>ACCESS FOR YOUR PLATFORMS</h2>
                    <p className='paragraph'>
                      Please provide us the view access for your Ad Accounts
                      which will help us understand the current issues in your
                      account. Don’t worry we wont be making any changes to your
                      account.
                    </p>
                  </div>
                  <div className='grid_home_page_hdm'>
                    <div className='grid_colum_hdm'>
                      <div className='grid_account_column_hdm'>
                        <div className='social_list_home_hdm'>
                          <img src={FB} alt='' />
                          <p className='social_name_title_hdm'>Facebook</p>
                        </div>
                        <div
                          className='social_media'
                          style={
                            platformSelected.findIndex(
                              (platform) => platform.platform === 'facebook-ads'
                            ) !== -1
                              ? {
                                  pointerEvents: ![
                                    'READ_ACCESS',
                                    'COMPLETE_ACCESS',
                                  ].includes(
                                    accountid?.fbAccess?.adAccount?.status
                                  )
                                    ? 'all'
                                    : 'none',
                                  backgroundColor: ![
                                    'READ_ACCESS',
                                    'COMPLETE_ACCESS',
                                  ].includes(
                                    accountid?.fbAccess?.adAccount?.status
                                  )
                                    ? ''
                                    : '#eeee',
                                }
                              : {
                                  pointerEvents: 'none',
                                  backgroundColor: '#eeee',
                                }
                          }
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                      backgroundColor: '#F0F3FF',
                                    }}
                                  >
                                    <b>Asset</b>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                      backgroundColor: '#F0F3FF',
                                    }}
                                  >
                                    <b>Status</b>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                      backgroundColor: '#F0F3FF',
                                    }}
                                  >
                                    <b>Account ID</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    <div className='asset_title'>
                                      Page
                                      <img
                                        src={READ}
                                        style={{
                                          position: 'absolute',
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {accountid.fbAccess.page.accountId
                                      .length !== 0 ? (
                                      <span
                                        style={{
                                          fontFamily: 'Inter',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: 14,
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          color:
                                            accountid.fbAccess.page.status ===
                                            'VERIFYING'
                                              ? '#F8862D'
                                              : accountid.fbAccess.page
                                                  .status === 'ACCESS_REVOKED'
                                              ? '#E84B47'
                                              : '#12C8B2',
                                        }}
                                      >
                                        {
                                          mappedStatusDb[
                                            accountid.fbAccess.page.status
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      `-----------------`
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                    }}
                                  >
                                    <InputComponent
                                      objKey='fbAccess'
                                      objKey2='page'
                                      objKey3='accountId'
                                      infoType='accessSharing'
                                      value={
                                        reduxStates['accessSharing'][
                                          'fbAccess'
                                        ]['page']['accountId']
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    <div className='asset_title'>
                                      Ad Account{' '}
                                      <img
                                        src={READ}
                                        style={{
                                          position: 'absolute',
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {accountid.fbAccess.adAccount.accountId
                                      .length !== 0 ? (
                                      <span
                                        style={{
                                          fontFamily: 'Inter',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: 14,
                                          display: "flex",
                                          alignItems: "flex-end",
                                          color: [
                                            dbStatus.VERIFYING,
                                            dbStatus.VERIFYING_COMPLETE_ACCESS,
                                          ].includes(
                                            accountid.fbAccess.adAccount.status
                                          )
                                            ? "#F8862D"
                                            : accountid.fbAccess.adAccount
                                                .status === "ACCESS_REVOKED"
                                            ? "#E84B47"
                                            : "#12C8B2",
                                        }}
                                      >
                                        {
                                          mappedStatusDb[
                                            accountid.fbAccess.adAccount.status
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      `-----------------`
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                    }}
                                  >
                                    <InputComponent
                                      objKey='fbAccess'
                                      objKey2='adAccount'
                                      objKey3='accountId'
                                      infoType='accessSharing'
                                      value={
                                        reduxStates['accessSharing'][
                                          'fbAccess'
                                        ]['adAccount']['accountId']
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    <div className='asset_title'>
                                      Catalog{' '}
                                      <img
                                        src={READ}
                                        style={{
                                          position: 'absolute',
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {accountid.fbAccess.catalog.accountId
                                      .length !== 0 ? (
                                      <span
                                        style={{
                                          fontFamily: 'Inter',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: 14,
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          color:
                                            accountid.fbAccess.catalog
                                              .status === 'VERIFYING'
                                              ? '#F8862D'
                                              : accountid.fbAccess.catalog
                                                  .status === 'ACCESS_REVOKED'
                                              ? '#E84B47'
                                              : '#12C8B2',
                                        }}
                                      >
                                        {
                                          mappedStatusDb[
                                            accountid.fbAccess.catalog.status
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      `-----------------`
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                    }}
                                  >
                                    <InputComponent
                                      objKey='fbAccess'
                                      objKey2='catalog'
                                      objKey3='accountId'
                                      infoType='accessSharing'
                                      value={
                                        reduxStates['accessSharing'][
                                          'fbAccess'
                                        ]['catalog']['accountId']
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    <div className='asset_title'>
                                      Pixel{' '}
                                      <img
                                        src={READ}
                                        style={{
                                          position: 'absolute',
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {accountid.fbAccess.pixel.accountId
                                      .length !== 0 ? (
                                      <span
                                        style={{
                                          fontFamily: 'Inter',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: 14,
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          color:
                                            accountid.fbAccess.pixel.status ===
                                            'VERIFYING'
                                              ? '#F8862D'
                                              : accountid.fbAccess.pixel
                                                  .status === 'ACCESS_REVOKED'
                                              ? '#E84B47'
                                              : '#12C8B2',
                                        }}
                                      >
                                        {
                                          mappedStatusDb[
                                            accountid.fbAccess.pixel.status
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      `-----------------`
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                    }}
                                  >
                                    <InputComponent
                                      objKey='fbAccess'
                                      objKey2='pixel'
                                      objKey3='accountId'
                                      infoType='accessSharing'
                                      value={
                                        reduxStates['accessSharing'][
                                          'fbAccess'
                                        ]['pixel']['accountId']
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>

                    {/*google ads*/}
                    <div className='grid_colum_hdm'>
                      <div className='grid_account_column_hdm'>
                        <div className='social_list_home_hdm'>
                          <img src={GOOGLE} alt='' />
                          <p className='social_name_title_hdm'>Google</p>
                        </div>

                        <div
                          className='social_media'
                          style={
                            platformSelected.findIndex(
                              (platform) => platform.platform === 'google-ads'
                            ) !== -1
                              ? {
                                  pointerEvents: ![
                                    'READ_ACCESS',
                                    'COMPLETE_ACCESS',
                                  ].includes(
                                    accountid?.googleAccess?.googleAdAccount
                                      ?.status
                                  )
                                    ? 'all'
                                    : 'none',
                                  backgroundColor: ![
                                    'READ_ACCESS',
                                    'COMPLETE_ACCESS',
                                  ].includes(
                                    accountid?.googleAccess?.googleAdAccount
                                      ?.status
                                  )
                                    ? ''
                                    : '#eeee',
                                }
                              : {
                                  pointerEvents: 'none',
                                  backgroundColor: '#eeee',
                                }
                          }
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                      backgroundColor: '#F0F3FF',
                                    }}
                                  >
                                    <b>Asset</b>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                      backgroundColor: '#F0F3FF',
                                    }}
                                  >
                                    <b>Status</b>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                      backgroundColor: '#F0F3FF',
                                    }}
                                  >
                                    <b>Account ID</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    <div className='asset_title'>
                                      Analytics
                                      <img
                                        src={READ}
                                        style={{
                                          position: 'absolute',
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {accountid.googleAccess.googleAnalytics
                                      .accountId.length !== 0 ? (
                                      <span
                                        style={{
                                          fontFamily: 'Inter',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: 14,
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          color:
                                            accountid.googleAccess
                                              .googleAnalytics.status ===
                                            'VERIFYING'
                                              ? '#F8862D'
                                              : accountid.googleAccess
                                                  .googleAnalytics.status ===
                                                'ACCESS_REVOKED'
                                              ? '#E84B47'
                                              : '#12C8B2',
                                        }}
                                      >
                                        {
                                          mappedStatusDb[
                                            accountid.googleAccess
                                              .googleAnalytics.status
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      `-----------------`
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                    }}
                                  >
                                    <InputComponent
                                      objKey='googleAccess'
                                      objKey2='googleAnalytics'
                                      objKey3='accountId'
                                      infoType='accessSharing'
                                      value={
                                        reduxStates['accessSharing'][
                                          'googleAccess'
                                        ]['googleAnalytics']['accountId']
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    <div className='asset_title'>
                                      Ad Account{' '}
                                      <img
                                        src={READ}
                                        style={{
                                          position: 'absolute',
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {accountid.googleAccess.googleAdAccount
                                      .accountId.length !== 0 ? (
                                      <span
                                        style={{
                                          fontFamily: 'Inter',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: 14,
                                          display: "flex",
                                          alignItems: "flex-end",
                                          color: [
                                            dbStatus.VERIFYING,
                                            dbStatus.VERIFYING_COMPLETE_ACCESS,
                                          ].includes(
                                            accountid.googleAccess
                                              .googleAdAccount.status
                                          )
                                            ? "#F8862D"
                                            : accountid.googleAccess
                                                .googleAdAccount.status ===
                                              "ACCESS_REVOKED"
                                            ? "#E84B47"
                                            : "#12C8B2",
                                        }}
                                      >
                                        {
                                          mappedStatusDb[
                                            accountid.googleAccess
                                              .googleAdAccount.status
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      `-----------------`
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                    }}
                                  >
                                    <InputComponent
                                      objKey='googleAccess'
                                      objKey2='googleAdAccount'
                                      objKey3='accountId'
                                      infoType='accessSharing'
                                      value={
                                        reduxStates['accessSharing'][
                                          'googleAccess'
                                        ]['googleAdAccount']['accountId']
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    <div className='asset_title'>
                                      Merchant{' '}
                                      <img
                                        src={READ}
                                        style={{
                                          position: 'absolute',
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {' '}
                                    {accountid.googleAccess.googleMerchant
                                      .accountId.length !== 0 ? (
                                      <span
                                        style={{
                                          fontFamily: 'Inter',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: 14,
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          color:
                                            accountid.googleAccess
                                              .googleMerchant.status ===
                                            'VERIFYING'
                                              ? '#F8862D'
                                              : accountid.googleAccess
                                                  .googleMerchant.status ===
                                                'ACCESS_REVOKED'
                                              ? '#E84B47'
                                              : '#12C8B2',
                                        }}
                                      >
                                        {
                                          mappedStatusDb[
                                            accountid.googleAccess
                                              .googleMerchant.status
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      `-----------------`
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                    }}
                                  >
                                    <InputComponent
                                      objKey='googleAccess'
                                      objKey2='googleMerchant'
                                      objKey3='accountId'
                                      infoType='accessSharing'
                                      value={
                                        reduxStates['accessSharing'][
                                          'googleAccess'
                                        ]['googleMerchant']['accountId']
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    <div className='asset_title'>
                                      Tag Manager{' '}
                                      <img
                                        src={READ}
                                        style={{
                                          position: 'absolute',
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {' '}
                                    {accountid.googleAccess
                                      .googleAndShopifyLinked.accountId
                                      .length !== 0 ? (
                                      <span
                                        style={{
                                          fontFamily: 'Inter',
                                          fontStyle: 'normal',
                                          fontWeight: 400,
                                          fontSize: 14,
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          color:
                                            accountid.googleAccess
                                              .googleAndShopifyLinked.status ===
                                            'VERIFYING'
                                              ? '#F8862D'
                                              : accountid.googleAccess
                                                  .googleAndShopifyLinked
                                                  .status === 'ACCESS_REVOKED'
                                              ? '#E84B47'
                                              : '#12C8B2',
                                        }}
                                      >
                                        {
                                          mappedStatusDb[
                                            accountid.googleAccess
                                              .googleAndShopifyLinked.status
                                          ]
                                        }
                                      </span>
                                    ) : (
                                      `-----------------`
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '40%',
                                    }}
                                  >
                                    <InputComponent
                                      objKey='googleAccess'
                                      objKey2='googleAndShopifyLinked'
                                      objKey3='accountId'
                                      infoType='accessSharing'
                                      value={
                                        reduxStates['accessSharing'][
                                          'googleAccess'
                                        ]['googleAndShopifyLinked']['accountId']
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="grid_home_page_hdm">
                    <div className="grid_colum_hdm">
                      <div className="grid_account_column_hdm">
                        <div className="social_list_home_hdm">
                          <img src={TIKTOK} alt="" />
                          <p className="social_name_title_hdm">Tik-Tok</p>
                        </div>

                        <div className="social_media">
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{ backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Asset</b>
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Status</b>
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Account ID</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <div className="asset_title">
                                      TikTok Ads Manager{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    <span
                                      style={{
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: 14,
                                        display: "flex",
                                        alignItems: "flex-end",
                                        color: "#F8862D",
                                      }}
                                    >
                                      {
                                        mappedStatusDb[accountid.tiktokAccess.tiktokAdsManager
                                          .status]
                                      }
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <InputComponent
                                      objKey="tiktokAccess"
                                      objKey2="tiktokAdsManager"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates[
                                          "accessSharing"
                                        ]["tiktokAccess"]["tiktokAdsManager"][
                                          "accountId"
                                        ]
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div className="asset_title">
                                      TikTok Pixel{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    <span
                                      style={{
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: 14,
                                        display: "flex",
                                        alignItems: "flex-end",
                                        color: "#F8862D",
                                      }}
                                    >
                                      {
                                        mappedStatusDb[accountid.tiktokAccess.tiktokPixel
                                          .status]
                                      }
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <InputComponent
                                      objKey="tiktokAccess"
                                      objKey2="tiktokPixel"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates[
                                          "accessSharing"
                                        ]["tiktokAccess"]["tiktokPixel"][
                                          "accountId"
                                        ]
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                    <div className="grid_colum_hdm">
                      <div className="grid_account_column_hdm">
                        <div className="social_list_home_hdm">
                          <img src={Shopify} alt="" />
                          <p className="social_name_title_hdm">Shopify</p>
                        </div>

                        <div className="social_media">
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{ backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Asset</b>
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Status</b>
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: "#F0F3FF" }}
                                  >
                                    <b>Account ID</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <div className="asset_title">
                                      Facebook sales channel{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <span
                                      style={{
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: 14,
                                        display: "flex",
                                        alignItems: "flex-end",
                                        color: "#F8862D",
                                      }}
                                    >
                                      {
                                        mappedStatusDb[accountid.shopifyAccess.fbSalesChannel
                                          .status]
                                      }
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <InputComponent
                                      objKey="shopifyAccess"
                                      objKey2="fbSalesChannel"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates[
                                          "accessSharing"
                                        ]["shopifyAccess"]["fbSalesChannel"][
                                          "accountId"
                                        ]
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <div className="asset_title">
                                      TikTok Sales Channel{" "}
                                      <img
                                        src={READ}
                                        style={{
                                          position: "absolute",
                                          marginLeft: 10,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    <span
                                      style={{
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: 14,
                                        display: "flex",
                                        alignItems: "flex-end",
                                        color: "#F8862D",
                                      }}
                                    >
                                      {
                                        mappedStatusDb[accountid.shopifyAccess
                                          .tiktokSalesChannel.status]
                                      }
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <InputComponent
                                      objKey="shopifyAccess"
                                      objKey2="tiktokSalesChannel"
                                      objKey3="accountId"
                                      infoType="accessSharing"
                                      value={
                                        reduxStates[
                                          "accessSharing"
                                        ]["shopifyAccess"][
                                          "tiktokSalesChannel"
                                        ]["accountId"]
                                      }
                                      agencyType={agencyType}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className='preview_footer_row'>
                  <div className='footer_content'>
                    <Button
                      variant='contained'
                      disabled={validate(platformSelected, accountid)}
                      onClick={() => {
                        updatedStatusHdm(
                          'ONGOING',
                          'accessSharing',
                          reduxStates
                        ).then((res) => {
                          setDocumentGenPage(true);
                          dispatch(getHDMClientDetailsByFormId());
                        });
                      }}
                    >
                      PROCEED
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Paper>
        </div>
      </div>
    </>
  );
};

export default HDMAccess;
