import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  Typography,
  Select,
  MenuItem,
  RadioGroup,
  Button,
  TextField,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { Popover } from 'react-tiny-popover';
import BpRadio from '../../ClientDashboard/CustomRadio/Radio';
import crossIcon from '../../PMaxStrategyCreation/pmaxIcons/cross-icon.svg';
import ErrorTooltip from '../../TooltipIndicators/ErrorTooltip';
import PMaxData from './PMaxData';
import PMaxSegmentField from './PMaxSegmentField';
import PMaxInterestDemographies from './PMaxInterestDemographies';

const PMaxCampaignTargetting = ({
  read,
  errors = {},
  existingAudience = [],
  selectedExistingAudience,
  setSelectedExistingAudience,
  audienceType,
  setAudienceType,
  newAudienceName,
  setNewAudienceName,
  customSegments,
  selectedSegment,
  setSelectedSegment,
  userList,
  selectedYourData,
  setSelectedYourData,
  interestAndDemographies,
  selectedInterest,
  setSelectedInterest,
  genders,
  setGenders,
  minAge,
  setMinAge,
  maxAge,
  setMaxAge,
  parentalStatus,
  setParentalStatus,
  minHouseholdIncome,
  setMinHouseholdIncome,
  maxHouseholdIncome,
  setMaxHouseholdIncome,
}) => {
  const textStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
    marginBottom: 30,
  };
  const searchTermTextStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#333333',
  };
  const campaignTextStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '19px',
    color: '#323232',
    alignSelf: 'flex-start',
    paddingLeft: 15.5,
  };
  const handleAudienceChange = (event) => {
    setAudienceType(event.target.value);
  };

  const { audienceName, audienceParentalStatus, audienceGenders } = errors;

  let errorsArray = [];
  if (audienceName?.length) errorsArray = [...errorsArray, ...audienceName];
  if (audienceParentalStatus?.length)
    errorsArray = [...errorsArray, ...audienceParentalStatus];
  if (audienceGenders?.length)
    errorsArray = [...errorsArray, ...audienceGenders];

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpenLanguage, setIsPopoverOpenLanguage] = useState(false);
  const [isPopOverInterestOpen, setIsPopOverInterestOpen] = useState(false);

  useEffect(() => {
    console.log('Min income ' + minHouseholdIncome);
    console.log('Max income ' + maxHouseholdIncome);
  }, [minHouseholdIncome, maxHouseholdIncome]);
  return (
    <div className='pmax-existing-new-segment-view'>
      <div className='pmax-campaign-target-heading'>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 15.43,
            lineHeight: '19px',
            color: '#333333',
            padding: '15px 0 18px 15px',
          }}
        >
          Targeting
        </Typography>
      </div>
      <div className='pmax-existing-segment'>
        <RadioGroup>
          <div>
            <FormControlLabel
              value='PREDEFINED'
              control={<BpRadio />}
              checked={audienceType === 'PREDEFINED'}
              label='Existing Audience'
              onChange={handleAudienceChange}
            />
            <Select
              // displayEmpty
              disabled={audienceType !== 'PREDEFINED'}
              placeholder='Choose'
              variant='outlined'
              value={
                audienceType === 'PREDEFINED'
                  ? selectedExistingAudience || ''
                  : ''
              }
              onChange={(e) => setSelectedExistingAudience(e.target.value)}
              style={{
                width: 229,
                height: 41,
                background: '#FFFFFF',
                border: '0.964432px solid #DDDDDD',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11.5,
                // opacity: 0.4,
                outline: 'none',
                boxShadow: 'none',
                marginLeft: 12,
              }}
              className='existing-segement-select-box'
            >
              {existingAudience?.map((audience, index) => {
                return <MenuItem value={audience}>{audience.name}</MenuItem>;
              })}
            </Select>
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <FormControlLabel
              value='NEW'
              control={<BpRadio />}
              checked={audienceType === 'NEW'}
              style={{
                marginBottom: 2,
                marginTop: 18,
              }}
              label='Create New Audience'
              className='new-segment-radio'
              onChange={handleAudienceChange}
            />
            {errorsArray?.length > 0 && audienceType !== "PREDEFINED" && <ErrorTooltip error={errorsArray} />}
          </div>
        </RadioGroup>
      </div>
      <div
        style={{
          opacity: audienceType === 'PREDEFINED' ? 0.3 : 1,
        }}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 13.502,
            lineHeight: '19px',
            color: '#333333',
            paddingTop: 12,
            paddingBottom: 4,
            paddingLeft: 12.5,
            marginTop: -12,
          }}
        >
          Audience Name :{' '}
          {read ? (
            <span className='pmax-field-value'>
              {newAudienceName || 'Name goes here'}
            </span>
          ) : (
            <TextField
              variant='standard'
              placeholder='Name goes here'
              value={newAudienceName}
              onChange={(e) => setNewAudienceName(e.target.value)}
              inputProps={{
                style: {
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: '19px',
                  width: 150,
                  paddingBottom: 2,
                  border: 0,
                },
              }}
            />
          )}
        </Typography>
        <div className='pmax-campaign-call-to-action'>
          <Typography style={campaignTextStyle}>Segments</Typography>
          <Popover
            isOpen={isPopoverOpenLanguage}
            // align='start'
            positions={['top', 'bottom']}
            // containerStyle={{ left: '0px', top: '0px' }}
            onClickOutside={() => setIsPopoverOpenLanguage(false)}
            content={
              <div
                className='question'
                style={{
                  background: '#FFF',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '16px 8px',
                  gap: '16px',
                  width: '406px',
                  minHeight: '296px',
                  border: '1px solid #F0F0F0',
                  boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.05)',
                  borderRadius: '8px',
                  zIndex: 99,
                }}
              >
                <PMaxSegmentField
                  segments={customSegments}
                  selectedSegments={selectedSegment}
                  setSelectedSegments={(value) => setSelectedSegment(value)}
                />
              </div>
            }
          >
            <div
              onClick={() => {
                if (audienceType !== 'PREDEFINED') {
                  setIsPopoverOpenLanguage(!isPopoverOpenLanguage);
                }
              }}
              style={{
                width: '94%',
                height: 36,
                background: '#ffffff',
                border: '0.964432px solid rgb(221, 221, 221)',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
                outline: 'none',
                // color: props.isCampaignComplete
                //   ? "rgba(0, 0, 0, 0.38)"
                //   : "#0869FB",
                padding: '9.5px 10px',
                // cursor: props.isCampaignComplete ? "revert" : "pointer",
              }}
            >
              {selectedSegment.length
                ? `${selectedSegment.length} Selected`
                : 'Select'}
            </div>
          </Popover>
        </div>
        <div className='pmax-campaign-call-to-action'>
          <Typography style={campaignTextStyle}>Your Data</Typography>
          <Popover
            isOpen={isPopoverOpen}
            align='start'
            positions={['top', 'bottom']}
            containerStyle={{ left: '0px', top: '0px' }}
            onClickOutside={() => setIsPopoverOpen(false)}
            content={
              <div
                className='question'
                style={{
                  background: '#FFF',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '16px 8px',
                  gap: '16px',
                  width: '406px',
                  minHeight: '296px',
                  border: '1px solid #F0F0F0',
                  boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.05)',
                  borderRadius: '8px',
                  zIndex: 99,
                }}
              >
                <PMaxData
                  yourData={userList}
                  selectedYourData={selectedYourData}
                  setSelectedYourData={(value) => setSelectedYourData(value)}
                />
              </div>
            }
          >
            <div
              onClick={() => {
                if (audienceType !== 'PREDEFINED') {
                  setIsPopoverOpen(!isPopoverOpen);
                }
              }}
              style={{
                width: '94%',
                height: 36,
                background: '#ffffff',
                border: '0.964432px solid rgb(221, 221, 221)',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
                outline: 'none',
                // color: props.isCampaignComplete
                //   ? "rgba(0, 0, 0, 0.38)"
                //   : "#0869FB",
                padding: '9.5px 10px',
                // cursor: props.isCampaignComplete ? "revert" : "pointer",
              }}
            >
              {selectedYourData.length
                ? `${selectedYourData.length} Selected`
                : 'Select'}
            </div>
          </Popover>
        </div>
        <div className='pmax-campaign-call-to-action'>
          <Typography style={campaignTextStyle}>
            Interests & Detailed Demographics
          </Typography>
          <Popover
            isOpen={isPopOverInterestOpen}
            align='start'
            positions={['top', 'bottom']}
            containerStyle={{ left: '0px', top: '0px' }}
            onClickOutside={() => setIsPopOverInterestOpen(false)}
            content={
              <div
                className='question'
                style={{
                  background: '#FFF',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '16px 8px',
                  gap: '16px',
                  width: '406px',
                  minHeight: '296px',
                  border: '1px solid #F0F0F0',
                  boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.05)',
                  borderRadius: '8px',
                  zIndex: 99,
                }}
              >
                <PMaxInterestDemographies
                  interestDemographies={interestAndDemographies}
                  selectedInterestDemographies={selectedInterest}
                  setSelectedInterst={(value) => setSelectedInterest(value)}
                />
              </div>
            }
          >
            <div
              onClick={() => {
                if (audienceType !== 'PREDEFINED') {
                  setIsPopOverInterestOpen(!isPopOverInterestOpen);
                }
              }}
              style={{
                width: '94%',
                height: 36,
                background: '#ffffff',
                border: '0.964432px solid rgb(221, 221, 221)',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
                outline: 'none',
                // color: props.isCampaignComplete
                //   ? "rgba(0, 0, 0, 0.38)"
                //   : "#0869FB",
                padding: '9.5px 10px',
                // cursor: props.isCampaignComplete ? "revert" : "pointer",
              }}
            >
              {selectedYourData.length
                ? `${selectedYourData.length} Selected`
                : 'Select'}
            </div>
          </Popover>
          {/* <Select
            // displayEmpty
            placeholder='Choose'
            variant='outlined'
            sx={{
              '& fieldset': { border: 'none' },
            }}
            value={selectedInterest}
            disabled={audienceType === 'PREDEFINED'}
            onChange={(e) => setSelectedInterest(e.target.value)}
            style={{
              width: '94%',
              height: 36,
              background: '#FFFFFF',
              border: '0.964432px solid rgb(221, 221, 221)',
              borderRadius: 7.71,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 11.5,
              outline: 'none',
              boxShadow: 'none',
            }}
          >
            {interestAndDemographies.map((interest, index) => {
              return (
                <MenuItem key={index} value={interest}>
                  {interest.value}
                </MenuItem>
              );
            })}
          </Select> */}
        </div>
        <div className='pmax-gender-parental-status'>
          <Typography style={campaignTextStyle}>Gender</Typography>
          <FormGroup className='form-checkbox-style'>
            <FormControlLabel
              disabled={audienceType === 'PREDEFINED'}
              name='Female'
              control={<Checkbox />}
              onClick={() => {
                if (genders.includes('Female')) {
                  setGenders((prev) => {
                    let tempGenders = [...prev];
                    tempGenders.splice(tempGenders.indexOf('Female'), 1);
                    return tempGenders;
                  });
                } else {
                  setGenders([...genders, 'Female']);
                }
              }}
              label='Female'
              checked={(genders || []).includes('Female')}
            />
            <FormControlLabel
              disabled={audienceType === 'PREDEFINED'}
              name='Male'
              control={<Checkbox />}
              onClick={() => {
                if (genders.includes('Male')) {
                  setGenders((prev) => {
                    let tempGenders = [...prev];
                    tempGenders.splice(tempGenders.indexOf('Male'), 1);
                    return tempGenders;
                  });
                } else {
                  setGenders([...genders, 'Male']);
                }
              }}
              label='Male'
              checked={(genders || []).includes('Male')}
            />
            <FormControlLabel
              disabled={audienceType === 'PREDEFINED'}
              name='Unknown'
              control={<Checkbox />}
              onClick={() => {
                if (genders.includes('Unknown')) {
                  setGenders((prev) => {
                    let tempGenders = [...prev];
                    tempGenders.splice(tempGenders.indexOf('Unknown'), 1);
                    return tempGenders;
                  });
                } else {
                  setGenders([...genders, 'Unknown']);
                }
              }}
              label='Unknown'
              checked={(genders || []).includes('Unknown')}
            />
          </FormGroup>
        </div>
        <div className='pmax-age-household-income'>
          <Typography style={campaignTextStyle}>Age</Typography>
          <div className='pmax-campaign-age-range'>
            <Select
              // displayEmpty
              placeholder='Choose'
              disabled={audienceType === 'PREDEFINED'}
              variant='outlined'
              style={{
                width: 104,
                height: 36,
                background: '#FFFFFF',
                border: '0.964432px solid #DDDDDD',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11.5,
                outline: 'none',
                boxShadow: 'none',
              }}
              value={minAge}
              onChange={(e) => setMinAge(e.target.value)}
            >
              <MenuItem value='18'>18</MenuItem>
              <MenuItem value='25'>25</MenuItem>
              <MenuItem value='35'>35</MenuItem>
              <MenuItem value='45'>45</MenuItem>
              <MenuItem value='55'>55</MenuItem>
              <MenuItem value='65+'>65+</MenuItem>
            </Select>
            <div className='separator'></div>
            <Select
              // displayEmpty
              placeholder='Choose'
              variant='outlined'
              style={{
                width: 104,
                height: 36,
                background: '#FFFFFF',
                border: '0.964432px solid #DDDDDD',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11.5,
                outline: 'none',
                boxShadow: 'none',
              }}
              disabled={audienceType === 'PREDEFINED' || minAge === '65+'}
              value={maxAge > minAge ? maxAge : minAge === '65+' ? '65+' : ''}
              onChange={(e) => setMaxAge(e.target.value)}
            >
              <MenuItem value='24' disabled={minAge > 24}>
                24
              </MenuItem>
              <MenuItem value='34' disabled={minAge > 34}>
                34
              </MenuItem>
              <MenuItem value='44' disabled={minAge > 44}>
                44
              </MenuItem>
              <MenuItem value='54' disabled={minAge > 54}>
                54
              </MenuItem>
              <MenuItem value='64' disabled={minAge > 64}>
                64
              </MenuItem>
              <MenuItem value='65+' disabled={minAge > 65}>
                65+
              </MenuItem>
            </Select>
          </div>
        </div>
        <div className='pmax-gender-parental-status'>
          <Typography style={campaignTextStyle}>Parental Status</Typography>
          <FormGroup className='form-checkbox-style'>
            <FormControlLabel
              disabled={audienceType === 'PREDEFINED'}
              name='Female'
              control={<Checkbox />}
              onClick={() => {
                if(parentalStatus.includes("Female")){
                  setParentalStatus(prev => {
                    let tempParentalStatus = [...prev];
                    tempParentalStatus.splice(
                      tempParentalStatus.indexOf('Female'),
                      1
                    );
                    return tempParentalStatus;
                  });
                } else {
                  setParentalStatus([...parentalStatus, "Female"]);
                }
              }}
              label='Female'
              checked={(parentalStatus || []).includes('Female')}
            />
            <FormControlLabel
              disabled={audienceType === 'PREDEFINED'}
              name='Male'
              control={<Checkbox />}
              onClick={() => {
                if(parentalStatus.includes("Male")){
                  setParentalStatus(prev => {
                    let tempParentalStatus = [...prev];
                    tempParentalStatus.splice(
                      tempParentalStatus.indexOf('Male'),
                      1
                    );
                    return tempParentalStatus;
                  });
                } else {
                  setParentalStatus([...parentalStatus, "Male"]);
                }
              }}
              label='Male'
              checked={(parentalStatus || []).includes('Male')}
            />
            <FormControlLabel
              disabled={audienceType === 'PREDEFINED'}
              name='Unknown'
              control={<Checkbox />}
              onClick={() => {
                if(parentalStatus.includes("Unknown")){
                  setParentalStatus(prev => {
                    let tempParentalStatus = [...prev];
                    tempParentalStatus.splice(
                      tempParentalStatus.indexOf('Unknown'),
                      1
                    );
                    return tempParentalStatus;
                  });
                } else {
                  setParentalStatus([...parentalStatus, "Unknown"]);
                }
              }}
              label='Unknown'
              checked={(parentalStatus || []).includes('Unknown')}
            />
          </FormGroup>
        </div>
        <div className='pmax-age-household-income'>
          <Typography style={campaignTextStyle}>Household Income</Typography>
          <div className='pmax-campaign-age-range'>
            <Select
              // displayEmpty
              disabled={audienceType === 'PREDEFINED'}
              placeholder='Choose'
              variant='outlined'
              style={{
                width: 104,
                height: 36,
                background: '#FFFFFF',
                border: '0.964432px solid #DDDDDD',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11.5,
                outline: 'none',
                boxShadow: 'none',
              }}
              value={minHouseholdIncome}
              onChange={(e) => {
                console.log(e.target.value);
                setMinHouseholdIncome(e.target.value);
              }}
            >
              <MenuItem value='Top 10%'>Top 10%</MenuItem>
              <MenuItem value='11%'>11%</MenuItem>
              <MenuItem value='21%'>21%</MenuItem>
              <MenuItem value='31%'>31%</MenuItem>
              <MenuItem value='41%'>41%</MenuItem>
              <MenuItem value='Lower 50%'>Lower 50%</MenuItem>
            </Select>
            <div className='separator'></div>
            <Select
              // displayEmpty
              disabled={
                audienceType === 'PREDEFINED' ||
                minHouseholdIncome === 'Lower 50%'
              }
              placeholder='Choose'
              variant='outlined'
              style={{
                width: 104,
                height: 36,
                background: '#FFFFFF',
                border: '0.964432px solid #DDDDDD',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11.5,
                outline: 'none',
                boxShadow: 'none',
              }}
              value={
                minHouseholdIncome === 'Lower 50%'
                  ? 'Lower 50%'
                  : maxHouseholdIncome
              }
              onChange={(e) => setMaxHouseholdIncome(e.target.value)}
            >
              <MenuItem
                value='Top 10%'
                disabled={['Top 10%', '11%', '21%', '31%', '41%'].includes(
                  minHouseholdIncome
                )}
              >
                Top 10%
              </MenuItem>
              <MenuItem
                value='11%'
                disabled={['11%', '21%', '31%', '41%'].includes(
                  minHouseholdIncome
                )}
              >
                11%
              </MenuItem>
              <MenuItem
                value='21%'
                disabled={['21%', '31%', '41%'].includes(minHouseholdIncome)}
              >
                21%
              </MenuItem>
              <MenuItem
                value='31%'
                disabled={['31%', '41%'].includes(minHouseholdIncome)}
              >
                31%
              </MenuItem>
              <MenuItem
                value='41%'
                disabled={['41%', 'Lower 50%'].includes(minHouseholdIncome)}
              >
                41%
              </MenuItem>
              <MenuItem value='Lower 50%'>Lower 50%</MenuItem>
            </Select>

            <FormControlLabel
              disabled={audienceType === 'PREDEFINED'}
              control={<Checkbox />}
              label='Unknown'
              style={{
                marginLeft: 4,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PMaxCampaignTargetting;
