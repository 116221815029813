import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import GoToNextCompoButton from "../GoToNextCompoButton";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";

import "./Googleads.css";
import {
  updateGoogleAdsInfoReducer,
  updateGoogleAdData,
} from "../../../api/accountManager/forms/slice";
import { goBackAmCompo } from "../../../api/accountManager/componentTracking/action";
import { updateCompoReduxAM } from "../../../api/accountManager/componentTracking/action";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";

const GoogleAdsSetup = () => {
  const dispatch = useDispatch();
  const form = useSelector((store) => store.form.form);
  const googleAdsInfo = useSelector((store) => store.form.googleAdsInfo);
  const [accountOperations, setAccountOperations] = useState([]);
  const [displayAdCampaigns, setDisplayAdCampaigns] = useState("");
  const [shoppingAdCampaigns, setShoppingAdCampaigns] = useState("");
  const [localServiceAdCampaigns, setLocalServiceAdCampaigns] = useState("");
  const [videoAdCampaigns, setVideoAdCampaigns] = useState("");
  const [callTrackingAdCampaigns, setCallTrackingAdCampaigns] = useState("");

  const googleAdsInfoRef = useRef({
    accountOperations: accountOperations,
    displayAdCampaigns: displayAdCampaigns,
    shoppingAdCampaigns: shoppingAdCampaigns,
    localServiceAdCampaigns: localServiceAdCampaigns,
    videoAdCampaigns: videoAdCampaigns,
    callTrackingAdCampaigns: callTrackingAdCampaigns,
  });

  const [displayAdCampaignsRadio, setDisplayAdCampaignsRadio] = useState(false);
  const [shoppingAdCampaignsRadio, setShoppingAdCampaignsRadio] =
    useState(false);
  const [localServiceAdCampaignsRadio, setLocalServiceAdCampaignsRadio] =
    useState(false);
  const [videoAdCampaignsRadio, setVideoAdCampaignsRadio] = useState(false);
  const [callTrackingRadio, setCallTrackingAdCampaignsRadio] = useState(false);

  useEffect(() => {
    googleAdsInfoRef.current = {
      accountOperations: accountOperations,
      displayAdCampaigns: displayAdCampaigns,
      shoppingAdCampaigns: shoppingAdCampaigns,
      localServiceAdCampaigns: localServiceAdCampaigns,
      videoAdCampaigns: videoAdCampaigns,
      callTrackingAdCampaigns: callTrackingAdCampaigns,
    };
  }, [
    accountOperations,
    displayAdCampaigns,
    shoppingAdCampaigns,
    localServiceAdCampaigns,
    videoAdCampaigns,
    callTrackingAdCampaigns,
  ]);

  useEffect(() => {
    dispatch(updateCompoReduxAM());
    if (form?.companyInfo?.googleAdsManaged === false) {
    }
  }, [form]);

  useEffect(() => {
    setAccountOperations(googleAdsInfo?.accountOperations || []);
    setDisplayAdCampaigns(googleAdsInfo?.displayAdCampaigns || "");
    setShoppingAdCampaigns(googleAdsInfo?.shoppingAdCampaigns || "");
    setLocalServiceAdCampaigns(googleAdsInfo?.localServiceAdCampaigns || "");
    setVideoAdCampaigns(googleAdsInfo?.videoAdCampaigns || "");
    setCallTrackingAdCampaigns(googleAdsInfo?.callTrackingAdCampaigns || "");

    if (googleAdsInfo?.displayAdCampaigns) {
      setDisplayAdCampaignsRadio(true);
    }
    if (googleAdsInfo?.shoppingAdCampaigns) {
      setShoppingAdCampaignsRadio(true);
    }
    if (googleAdsInfo?.localServiceAdCampaigns) {
      setLocalServiceAdCampaignsRadio(true);
    }
    if (googleAdsInfo?.videoAdCampaigns) {
      setVideoAdCampaignsRadio(true);
    }
    if (googleAdsInfo?.callTrackingAdCampaigns) {
      setCallTrackingAdCampaignsRadio(true);
    }

    googleAdsInfoRef.current = {
      accountOperations: googleAdsInfo?.accountOperations || [],
      displayAdCampaigns: googleAdsInfo?.displayAdCampaigns || "",
      shoppingAdCampaigns: googleAdsInfo?.shoppingAdCampaigns || "",
      localServiceAdCampaigns: googleAdsInfo?.localServiceAdCampaigns || "",
      videoAdCampaigns: googleAdsInfo?.videoAdCampaigns || "",
      callTrackingAdCampaigns: googleAdsInfo?.callTrackingAdCampaigns || "",
    };
  }, [googleAdsInfo]);

  useEffect(() => {
    return () => {
      dispatch(
        updateGoogleAdData({
          ...googleAdsInfo,
          ...googleAdsInfoRef.current,
        })
      );
    };
  }, []);

  const onClickHandler = () => {
    dispatch(
      updateGoogleAdsInfoReducer({
        status: BACKEND_STATUS.COMPLETED,
        accountOperations: accountOperations,
        displayAdCampaigns: displayAdCampaigns,
        shoppingAdCampaigns: shoppingAdCampaigns,
        localServiceAdCampaigns: localServiceAdCampaigns,
        videoAdCampaigns: videoAdCampaigns,
        callTrackingAdCampaigns: callTrackingAdCampaigns,
      })
    );
  };

  return (
    <div className="box">
      <div className="heading_setup">
        <span>Google Ads Account Build/Setup/Optimization</span>
        <div className="form_checkbox_row">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountOperations.includes("Build")}
                  onChange={(e) => {
                    const accountOperationBuffer = [...accountOperations];
                    if (e.target.checked) {
                      accountOperationBuffer.push("Build");
                    } else {
                      accountOperationBuffer.splice(
                        accountOperationBuffer.indexOf("Build"),
                        1
                      );
                    }
                    setAccountOperations(accountOperationBuffer);
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "sans-serif", color: "#7F7D87" }}>
                  Build
                </Typography>
              }
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountOperations.includes("Setup")}
                  onChange={(e) => {
                    const accountOperationBuffer = [...accountOperations];
                    if (e.target.checked) {
                      accountOperationBuffer.push("Setup");
                    } else {
                      accountOperationBuffer.splice(
                        accountOperationBuffer.indexOf("Setup"),
                        1
                      );
                    }
                    setAccountOperations(accountOperationBuffer);
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "sans-serif", color: "#7F7D87" }}>
                  Setup
                </Typography>
              }
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountOperations.includes("Optimization")}
                  onChange={(e) => {
                    const accountOperationBuffer = [...accountOperations];
                    if (e.target.checked) {
                      accountOperationBuffer.push("Optimization");
                    } else {
                      accountOperationBuffer.splice(
                        accountOperationBuffer.indexOf("Optimization"),
                        1
                      );
                    }
                    setAccountOperations(accountOperationBuffer);
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "sans-serif", color: "#7F7D87" }}>
                  Optimization
                </Typography>
              }
            />
          </FormGroup>
        </div>
      </div>
      <div className="heading_setup">
        <span>Display Ad Campaigns *</span>
        <div>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={displayAdCampaignsRadio}
            onChange={() =>
              !displayAdCampaigns &&
              setDisplayAdCampaignsRadio(!displayAdCampaignsRadio)
            }
            style={{
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <FormControlLabel value={false} control={<BpRadio />} label="NO" />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label="Other"
            />
            <TextField
              variant="standard"
              value={displayAdCampaigns}
              disabled={!displayAdCampaignsRadio}
              style={{
                width: "400px",
                fontFamily: "sans-serif",
                color: "#7F7D87",
              }}
              onChange={(e) => setDisplayAdCampaigns(e.target.value)}
            />
          </RadioGroup>
        </div>
      </div>

      <div className="heading_setup">
        <span>Shopping Ad Campaigns *</span>
        <div>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={shoppingAdCampaignsRadio}
            onChange={() =>
              !shoppingAdCampaigns &&
              setShoppingAdCampaignsRadio(!shoppingAdCampaignsRadio)
            }
            style={{
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <FormControlLabel value={false} control={<BpRadio />} label="NO" />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label="Other"
            />
            <TextField
              variant="standard"
              disabled={!shoppingAdCampaignsRadio}
              value={shoppingAdCampaigns}
              style={{
                width: "400px",
                fontFamily: "sans-serif",
                color: "#7F7D87",
              }}
              onChange={(e) => setShoppingAdCampaigns(e.target.value)}
            />
          </RadioGroup>
        </div>
      </div>

      <div className="heading_setup">
        <span>Local Service Ad Campaigns *</span>
        <div>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={localServiceAdCampaignsRadio}
            onChange={() =>
              !localServiceAdCampaigns &&
              setLocalServiceAdCampaignsRadio(!localServiceAdCampaignsRadio)
            }
            style={{
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <FormControlLabel value={false} control={<BpRadio />} label="NO" />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label="Other"
            />
            <TextField
              variant="standard"
              value={localServiceAdCampaigns}
              disabled={!localServiceAdCampaignsRadio}
              style={{
                width: "400px",
                fontFamily: "sans-serif",
                color: "#7F7D87",
              }}
              onChange={(e) => setLocalServiceAdCampaigns(e.target.value)}
            />
          </RadioGroup>
        </div>
      </div>

      <div className="heading_setup">
        <span>Video Ad Campaigns *</span>
        <div>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={videoAdCampaignsRadio}
            onChange={() =>
              !videoAdCampaigns &&
              setVideoAdCampaignsRadio(!videoAdCampaignsRadio)
            }
            style={{
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <FormControlLabel value={false} control={<BpRadio />} label="NO" />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label="Other"
            />
            <TextField
              variant="standard"
              value={videoAdCampaigns}
              disabled={!videoAdCampaignsRadio}
              style={{
                width: "400px",
                fontFamily: "sans-serif",
                color: "#7F7D87",
              }}
              onChange={(e) => setVideoAdCampaigns(e.target.value)}
            />
          </RadioGroup>
        </div>
      </div>

      <div className="heading_setup">
        <span>Call Tracking (CallRail) *</span>
        <div>
          <RadioGroup
            defaultValue=""
            aria-labelledby="demo-customized-radios"
            name="customized-radios"
            value={callTrackingRadio}
            onChange={() =>
              !callTrackingAdCampaigns &&
              setCallTrackingAdCampaignsRadio(!callTrackingRadio)
            }
            style={{
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <FormControlLabel value={false} control={<BpRadio />} label="NO" />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label="Other"
            />
            <TextField
              variant="standard"
              value={callTrackingAdCampaigns}
              disabled={!callTrackingRadio}
              style={{
                width: "400px",
                fontFamily: "sans-serif",
                color: "#7F7D87",
              }}
              onChange={(e) => setCallTrackingAdCampaigns(e.target.value)}
            />
          </RadioGroup>
        </div>
      </div>

      <div className="preview_footer_row">
        <div className="footer_con">
          <button className="back" onClick={() => dispatch(goBackAmCompo())}>
            BACK
          </button>
          <GoToNextCompoButton title="Next" onClickHandler={onClickHandler} />
        </div>
      </div>
    </div>
  );
};
export default GoogleAdsSetup;
