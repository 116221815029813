import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import GoToNextCompoButton from '../GoToNextCompoButton';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import 'slick-carousel/slick/slick.css';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import { man_give_presentation as manGivePresentation } from '../../../assets/icons/icon';
import { adset_demo as adsetDemoImage } from '../../../assets/icons/icon';

import { editicon as EDIT } from '../../../assets/icons/icon';
import { checked_icon as Check } from '../../../assets/icons/strategyIcons/strategyIcons';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import {
  getAudiencesHdmAm,
  getFacebookKeywords,
  getFacebookAdsStrategy,
} from '../../../api/HDMAM/strategyCreation/action';
import {
  mappedAdsByCreative,
  isAnyAdsThereInAdsetHdm,
} from '../../../utils/strategyCreationFacebook';
import { updateWholeFormDataHdmAm } from '../../../api/HDMAM/form/action';
import { TextField } from '@mui/material';
import {
  assignValueFirstTimeStrategyCreation,
  addNewAdsetAction,
  deleteAdsetAction,
  updateValueAdsetDropdown,
  updateValueRangeAdset,
  deleteSpecificAdsAction,
  editSpecificAdsAction,
  clearAllAdsHdm,
  updateBudgetFunnelHdm,
} from '../../../api/HDMAM/form/action';
import {
  goBackHDMAmCompo,
  goToSpecificCompoHDMAM,
} from '../../../api/HDMAM/componentTracking/action';
import { uploadFile } from '../../../api/fileHandling/saga';
import { numberOfAds } from '../../../utils/strategyCreationFacebook';
import HoldTight from './HoldTight';
import { getFacebookBudget } from '../../../utils/hdmUtil';

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: true,
  dots: false,
};

const filterDropdown = (group, type, audiences) => {
  const arrTemp = [];
  audiences &&
    audiences.forEach((value, index) => {
      if (value.subtype === type) {
        arrTemp.push({
          group: group,
          name: value.name,
          reach: value.maxReach,
          id: value.id,
        });
      }
    });
  return arrTemp;
};

export const findUrl = (facebookAds, id) => {
  let idt = '';
  facebookAds.forEach((ad) => {
    if (ad.requestId === id) {
      idt = ad.imageUrl;
    }
  });
  return idt;
};

const mappedAdset = (audiences) => {
  const mappedAudience = {};
  audiences &&
    audiences.forEach((audience, index) => {
      mappedAudience[index] = audience;
    });
  return mappedAudience;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1004,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  padding: '46px 47px 42px',
  boxShadow: 24,
};

const LookALike = ({ agencyType = 'HDM' }) => {
  const dispatch = useDispatch();
  const [editText, setEditText] = useState(false);
  const [addNewad, setAddNewad] = useState(false);
  const [editAd, setEditAd] = useState(false);
  const [newAdsSelect, setNewAdsSelect] = useState([]);
  const [audienceName, setAudienceName] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const hiddenFileInput = useRef(null);
  const audiences = useSelector(
    (state) => state.strategyCreationHdmAm.audiences
  );
  const facebookAds = useSelector(
    (state) => state.strategyCreationHdmAm.facebookAds
  );
  const hdmStrategyCreation = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo
  );
  const [currentAdset, setCurrentAdset] = useState(0);
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({});
  const lookalikeTof = useSelector(
    (state) =>
      state.hdmFormAm.fbAdsStrategyInfo?.topOfTheFunnelLookalike?.adsets
  );
  const [adsets, setAdsets] = useState(mappedAdset(lookalikeTof));
  const [currentCreative, setCurrentCreative] = useState('CREATIVE1');
  const [mappedCreativeAd, setMappedCreativesAd] = useState(
    mappedAdsByCreative(facebookAds)
  );
  const onboardingInfo = useSelector((state) =>
    agencyType === 'LD'
      ? state?.form?.form?.onboardingInfo
      : state.hdmClient.onboardingInfo
  );
  const fbAdAccount = useSelector((state) =>
    agencyType === 'LD'
      ? state?.form?.form?.fbAdsAccountId
      : state.hdmFormAm?.fbAdsAccountId
  );
  const [editAdsDetails, setEditAdsDetails] = useState(null);
  const [mappedDropdown, setMappedDropdown] = useState({});
  const keywordsFacebook = useSelector(
    (state) => state.strategyCreationHdmAm.keywords
  );
  const [facebookBudget, setFacebookBudget] = useState(0);
  const [addToThisAdsetList, setAddToThisAdsetList] = useState({});
  const [addToAllAdsetList, setAddToAllAdsetList] = useState({});
  const [showHoldon, setShowHoldOn] = useState(false);
  const form = useSelector((state) => state);

  useEffect(() => {
    dispatch(updateWholeFormDataHdmAm());
  }, []);

  useEffect(() => {
    setAdsets(mappedAdset(lookalikeTof));
  }, [lookalikeTof]);

  useEffect(() => {
    if (fbAdAccount && fbAdAccount.length !== 0) {
      dispatch(getAudiencesHdmAm());
    }
  }, [fbAdAccount]);

  useEffect(() => {
    setFacebookBudget(
      agencyType === 'LD'
        ? getFacebookBudget(onboardingInfo?.platformInfo.platforms)
        : getFacebookBudget(onboardingInfo?.clientAccess.platforms)
    );
  }, [onboardingInfo]);
  useEffect(() => {
    dispatch(getFacebookAdsStrategy());
  }, []);

  useEffect(() => {
    dispatch(getFacebookKeywords());
  }, []);

  useEffect(() => {
    if (
      hdmStrategyCreation?.topOfTheFunnelLookalike?.adsets?.length === 0 &&
      (!facebookAds ||
        !audiences ||
        !keywordsFacebook ||
        facebookAds?.length === 0 ||
        audiences?.length === 0 ||
        keywordsFacebook?.length === 0) &&
      hdmStrategyCreation?.topOfTheFunnelLookalike?.status === 'PENDING'
    ) {
      setShowHoldOn(true);
    } else {
      setShowHoldOn(false);
    }
  }, [hdmStrategyCreation, facebookAds, audiences, keywordsFacebook]);

  useEffect(() => {
    if (
      hdmStrategyCreation?.topOfTheFunnelLookalike?.adsets?.length === 0 &&
      facebookAds &&
      audiences &&
      keywordsFacebook &&
      facebookAds?.length !== 0 &&
      audiences?.length !== 0 &&
      keywordsFacebook?.length !== 0 &&
      hdmStrategyCreation?.topOfTheFunnelLookalike?.status === 'PENDING'
    ) {
      dispatch(
        assignValueFirstTimeStrategyCreation({
          hdmAmForm: hdmStrategyCreation,
          facebookAds: facebookAds,
          audiences: audiences,
          keywordsFacebook: keywordsFacebook,
        })
      );
    }
  }, [hdmStrategyCreation, facebookAds, audiences, keywordsFacebook]);

  useEffect(() => {
    setMappedCreativesAd(mappedAdsByCreative(facebookAds));
  }, [facebookAds]);

  useEffect(() => {
    const tempObj = { ...mappedDropdown };
    audiences &&
      audiences.forEach((value, index) => {
        tempObj[value.name] = value;
      });
    setMappedDropdown(tempObj);
  }, [audiences]);

  const handleChangeRadio = (event, index) => {
    const {
      target: { value },
    } = event;
    setNewAdsSelect(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeRadioAudience = (event) => {
    const {
      target: { value },
    } = event;
    setAudienceName(typeof value === 'string' ? value.split(',') : value);
  };

  const audienceDropdown = {
    WEBSITE: filterDropdown('WEBSITE', 'WEBSITE', audiences),
    // CUSTOM: filterDropdown("CUSTOM", "CUSTOM", audiences),
    // "FB EVENT": filterDropdown("FB EVENT", "FB_EVENT", audiences),
  };

  const handleUploadFile = (event) => {
    hiddenFileInput.current.click();
  };

  const DropDownRender = () => {
    var ListArray = [];
    for (const key in audienceDropdown) {
      if (Object.hasOwnProperty.call(audienceDropdown, key)) {
        const element = audienceDropdown[key];
        ListArray.push(
          <ListSubheader key={key} className='drop_custom_list_head'>
            {key}
          </ListSubheader>
        );
        {
          element.forEach((ele, index) => {
            ListArray.push(
              <MenuItem
                key={index}
                value={ele.name}
                className='drop_custom_list_row'
                style={{
                  width: '400px',
                }}
              >
                <div className='drop_custom_list'>
                  <div>
                    <Radio
                      checked={
                        adsets[currentAdset] &&
                        adsets[currentAdset]?.audience &&
                        adsets[currentAdset].audience.name === ele.name
                      }
                    />
                    <p style={{ overflow: 'revert' }}>{ele.name}</p>
                  </div>
                  <span>{ele.reach}</span>
                </div>
              </MenuItem>
            );
          });
        }
      }
    }
    return ListArray;
  };

  //add new adset
  const addNewAdset = () => {
    if (facebookAds.length !== 0) {
      dispatch(
        addNewAdsetAction({
          outerKey: 'topOfTheFunnelLookalike',
          facebookAds: facebookAds,
        })
      );
    }
  };
  //delete adset
  const deleteAdset = (index) => {
    dispatch(
      deleteAdsetAction({ outerKey: 'topOfTheFunnelLookalike', index: index })
    );
    setCurrentAdset(index - 1 < 0 ? 0 : index - 1);
  };
  const deleteSpecificAds = (adsetIndex, removedIndex) => {
    dispatch(
      deleteSpecificAdsAction({
        outerKey: 'topOfTheFunnelLookalike',
        index: adsetIndex,
        removedIndex: removedIndex,
      })
    );
  };
  const editSpecificAds = () => {
    const tempObj = { ...editAdsDetails };
    tempObj['adsetIndex'] = parseInt(tempObj['adsetIndex']);
    dispatch(
      editSpecificAdsAction({
        index: tempObj.adsetIndex,
        editIndex: tempObj.currentAd,
        ad: tempObj.ad,
        outerKey: 'topOfTheFunnelLookalike',
      })
    );
    setEditAdsDetails(null);
    setEditAd(!editAd);
  };
  const setBudget = (budget) => {
    dispatch(
      updateBudgetFunnelHdm({
        outerKey: 'topOfTheFunnelLookalike',
        value: budget,
      })
    );
  };
  useEffect(() => {
    if (
      hdmStrategyCreation?.topOfTheFunnelLookalike?.budget === 0 &&
      facebookBudget !== 0
    ) {
      setBudget(parseInt(parseInt(facebookBudget) / 4 / 30.4));
    }
  }, [hdmStrategyCreation, facebookBudget]);
  const truncateString = (str) => {
    return str?.length > 120 ? str?.substring(0, 120) + '...' : str;
  };
  console.log(
    'hdmStrategyCreation',
    hdmStrategyCreation?.topOfTheFunnelLookalike?.budget
  );
  return (
    <>
      {!showHoldon ? (
        <>
          <div className='hd_strategy_layout'>
            <div className='hd_strategy_head flex-center-space'>
              <div className='hd_strategy_drop_head flex-center-space'>
                <p className='margin_0'>Daily Budget : ($)</p>
                <input
                  type='number'
                  className='budget_input_hdm'
                  size=''
                  onChange={(e) => setBudget(parseInt(e.target.value))}
                  value={hdmStrategyCreation?.topOfTheFunnelLookalike?.budget}
                />
              </div>
              <div className='hd_strategy_button_row flex-center-space'>
                <PrimaryButton
                  type='errorLight'
                  className='edit_response_button'
                  title='Clear All Ads'
                  icon='clear'
                  onClick={() => {
                    dispatch(
                      clearAllAdsHdm({
                        outerKey: 'topOfTheFunnelLookalike',
                        index: currentAdset,
                      })
                    );
                    setAddToThisAdsetList({});
                    setAddToAllAdsetList({});
                  }}
                ></PrimaryButton>
                <PrimaryButton
                  type='light'
                  className='edit_response_button'
                  title='+ Add New Ad'
                  onClick={() => setAddNewad(!addNewad)}
                ></PrimaryButton>
              </div>
            </div>

            {/*adsets*/}
            <div className='hd_strategy_sections flex-top-space'>
              <div className='adset_hdm_row'>
                <div className='adset_hdm_row_inner'>
                  {Object.entries(adsets).map(([key, value], index) => (
                    <div
                      className={
                        parseInt(key) === parseInt(currentAdset)
                          ? 'adset_block_hdm selected'
                          : 'adset_block_hdm'
                      }
                      key={index}
                      onClick={() => {
                        setCurrentAdset(key);
                      }}
                    >
                      <div className='adset_head_hdm flex-center-space'>
                        <p
                          style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            gap: '6px',
                          }}
                        >
                          <span>Adset Name:</span>
                          {editText &&
                          parseInt(key) === parseInt(currentAdset) ? (
                            <TextField
                              variant='standard'
                              style={{
                                width: 80,
                              }}
                              inputProps={{
                                style: {
                                  fontFamily: 'Inter',
                                  fontWeight: '600',
                                  fontSize: '12px',
                                  lineHeight: '18px',
                                  color: '#333333',
                                  paddingBottom: 0,
                                },
                              }}
                              value={value?.adsetName}
                              onChange={(e) =>
                                dispatch({
                                  type: 'UPDATE_ADSET_NAME_HDM',
                                  payload: {
                                    index: index,
                                    outerKey: 'topOfTheFunnelLookalike',
                                    value: e.target.value,
                                  },
                                })
                              }
                            />
                          ) : (
                            value?.adsetName
                          )}
                          <button
                            style={{
                              background: '#F8F8F8',
                              borderRadius: '6px',
                              border: 'transparent',
                              width: '0',
                              height: '0',
                            }}
                            onClick={() => setEditText(!editText)}
                          >
                            <img
                              src={
                                editText &&
                                parseInt(key) === parseInt(currentAdset)
                                  ? Check
                                  : EDIT
                              }
                              alt=''
                            />
                          </button>
                        </p>
                        <a
                          className='delete_adset'
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteAdset(parseInt(key));
                          }}
                        ></a>
                      </div>
                      <div className='adset_list_hdm flex-center-space'>
                        <div className='adset_dropdown_hdm'>
                          <label className='adset_label_hdm'>Audience</label>
                          <Select
                            variant='standard'
                            placeholder='Audience Name'
                            className='audience_droplist_hdm'
                            defaultValue={value?.audience?.name}
                            defaultChecked={value?.audience?.name}
                            onChange={(e, obj) => {
                              handleChangeRadioAudience(e, obj);
                              dispatch(
                                updateValueAdsetDropdown({
                                  index: index,
                                  outerKey: 'topOfTheFunnelLookalike',
                                  name: e.target.value,
                                  minReach: mappedDropdown[e.target.value]
                                    ? mappedDropdown[e.target.value]['minReach']
                                    : '',
                                  maxReach: mappedDropdown[e.target.value]
                                    ? mappedDropdown[e.target.value]['maxReach']
                                    : '',
                                  subType: mappedDropdown[e.target.value]
                                    ? mappedDropdown[e.target.value]['subType']
                                    : '',
                                  id: mappedDropdown[e.target.value]
                                    ? mappedDropdown[e.target.value]['id']
                                    : '',
                                })
                              );
                            }}
                            renderValue={(selected) => selected}
                            // value={
                            //   selectedDropdownValues[key]
                            //     ? selectedDropdownValues[key]["name"]
                            //     : ""
                            // }
                          >
                            <ListSubheader className='drop_custom_list_tophead'>
                              <span>Audience</span> <span>Reach</span>
                            </ListSubheader>
                            {DropDownRender()}
                          </Select>
                        </div>
                        <div className='adset_range_hdm'>
                          <label className='adset_label_hdm'>Range</label>
                          <div className='range_hdm_row'>
                            <input
                              type='text'
                              max='99'
                              maxLength={2}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                dispatch(
                                  updateValueRangeAdset({
                                    index: index,
                                    outerKey: 'topOfTheFunnelLookalike',
                                    value: e.target.value,
                                    type: 'minRange',
                                  })
                                )
                              }
                              className='range_input_hdm'
                              placeholder='10'
                              value={value.minRange}
                            />{' '}
                            %<p className='hypen_seperator'>-</p>
                            <input
                              type='text'
                              max='99'
                              maxLength={2}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                dispatch(
                                  updateValueRangeAdset({
                                    index: index,
                                    outerKey: 'topOfTheFunnelLookalike',
                                    value: e.target.value,
                                    type: 'maxRange',
                                  })
                                )
                              }
                              className='range_input_hdm'
                              placeholder='20'
                              value={value.maxRange}
                            />{' '}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='addnew_adset_row'>
                  <button
                    className='addnew_adset_button'
                    onClick={() => addNewAdset()}
                  >
                    + ADD NEW ADSET
                  </button>
                </div>
              </div>

              {/*slide*/}
              <div className='hd_strategy_column'>
                <div className='adset_slider'>
                  <Slider {...settings}>
                    {adsets[currentAdset] &&
                      adsets[currentAdset]['facebookAds'] &&
                      adsets[currentAdset]['facebookAds'].map((ad, index) => (
                        <div className='adset_slide' key={index}>
                          <div className='adset_slide_block'>
                            <div className='adset_slide_head'>
                              <h3>
                                {ad?.adName ? ad.adName : 'AD ' + (index + 1)}
                              </h3>
                            </div>
                            <div className='adset_slide_head_skip'>
                              <Tooltip title={ad?.primaryText} arrow>
                                <p>{truncateString(ad?.primaryText)}</p>
                              </Tooltip>
                            </div>
                            <div className='adset_slide_image'>
                              <img
                                src={
                                  process.env.REACT_APP_BACKEND_BASE_URL +
                                  '/files/' +
                                  ad?.imageUrl
                                }
                              />
                            </div>
                            <div className='adset_slide_footer flex-center-space'>
                              <div className='adset_slide_footer_info'>
                                <h2>
                                  {onboardingInfo?.clientAccess?.websiteUrl}
                                </h2>
                                <p>{ad?.headline}</p>
                                <span>{ad?.description}</span>
                              </div>
                              <div className='adset_slide_footer_action'>
                                <a
                                  href='javascript:;'
                                  className='adset_book_button'
                                >
                                  Book Now
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className='adset_slide_action_row flex-center-center'>
                            <button
                              className='edit_button'
                              onClick={() => {
                                setEditAdsDetails({
                                  adsetIndex: currentAdset,
                                  currentAd: index,
                                  ad: { ...ad },
                                });
                                setEditAd(!editAd);
                              }}
                            ></button>
                            <button
                              className='delete_button'
                              onClick={() =>
                                deleteSpecificAds(currentAdset, index)
                              }
                            ></button>
                          </div>
                        </div>
                      ))}
                  </Slider>
                  {adsets[currentAdset] &&
                    adsets[currentAdset]['facebookAds'] &&
                    adsets[currentAdset]['facebookAds'].length === 0 && (
                      <>
                        <div className='noadd_screen_hdm'>
                          <img src={manGivePresentation} />
                          <p>Please add a few ads to this adset to proceed. </p>
                          <PrimaryButton
                            type='light'
                            className='edit_response_button'
                            title='+ Add New Ad'
                            onClick={() => setAddNewad(!addNewad)}
                          ></PrimaryButton>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className='preview_footer_row'>
            <div className='footer_con'>
              <button
                className='back'
                onClick={() => dispatch(goToSpecificCompoHDMAM(15))}
              >
                BACK
              </button>
              <GoToNextCompoButton
                title='Confirm'
                disabled={isAnyAdsThereInAdsetHdm(lookalikeTof)}
                agencyType={agencyType}
              />
            </div>
          </div>

          {/*creative*/}
          <Modal
            open={addNewad}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className='large_addad_popup'
          >
            <Box sx={style}>
              <div className='addad_popup_layout'>
                <div className='addad_modal_header'>
                  <p>Add Ad</p>
                  <a
                    className='popup_close_button'
                    onClick={() => setAddNewad(!addNewad)}
                  >
                    <span></span>
                  </a>
                </div>
                <div className='addad_modal_layout'>
                  <div className='addad_modal_dropdown'>
                    <Select
                      labelId='demo-multiple-checkbox-label'
                      id='demo-multiple-checkbox'
                      value={currentCreative}
                      onChange={(e) => setCurrentCreative(e.target.value)}
                    >
                      {Object.entries(mappedCreativeAd)
                        .filter(([key, value]) => value.length !== 0)
                        .map(([key, value], index) => (
                          <MenuItem
                            key={index}
                            value={key}
                            className='drop_custom_list_row popup_drop_list'
                          >
                            <div className='drop_custom_list'>
                              <div>
                                <Radio
                                  value={key}
                                  checked={
                                    key === currentCreative ? true : false
                                  }
                                />
                                <p>Creative {index + 1}</p>
                              </div>
                              <span>
                                <img
                                  src={
                                    process.env.REACT_APP_BACKEND_BASE_URL +
                                    '/files/' +
                                    findUrl(facebookAds, value[0].requestId)
                                  }
                                  style={{ width: '50px', height: '50px' }}
                                />
                              </span>
                            </div>
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                  <div className='addad_modal_slider'>
                    <div className='adset_slider'>
                      <Slider {...settings}>
                        {mappedCreativeAd[currentCreative] &&
                          mappedCreativeAd[currentCreative].map(
                            (creative, index) => (
                              <div className='adset_slide' key={index}>
                                <div className='adset_slide_block'>
                                  <div className='adset_slide_head'>
                                    <h3>Ad {index + 1}</h3>
                                  </div>
                                  <div className='adset_slide_head_skip'>
                                    <Tooltip title={creative.primaryText} arrow>
                                      <p>
                                        {truncateString(creative.primaryText)}
                                      </p>
                                    </Tooltip>
                                  </div>
                                  <div className='adset_slide_image'>
                                    <img
                                      src={
                                        process.env.REACT_APP_BACKEND_BASE_URL +
                                        '/files/' +
                                        creative.imageUrl
                                      }
                                    />
                                  </div>
                                  <div className='adset_slide_footer flex-center-space'>
                                    <div className='adset_slide_footer_info'>
                                      <h2>
                                        {
                                          onboardingInfo?.clientAccess
                                            ?.websiteUrl
                                        }
                                      </h2>
                                      <p>{creative.headline}</p>
                                      <span>{creative.description}</span>
                                    </div>
                                    <div className='adset_slide_footer_action'>
                                      <a
                                        href='javascript:;'
                                        className='adset_book_button'
                                      >
                                        Book Now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className='adset_slide_action_row flex-center-center'>
                                  <div className='addad_action_row'>
                                    <RadioGroup
                                      aria-labelledby={`${
                                        currentCreative + '_' + index
                                      }-demo-controlled-radio-buttons-group`}
                                      className='radio_group_popup'
                                      name={`${
                                        currentCreative + '_' + index
                                      }-controlled-radio-buttons-group`}
                                      onChange={(e) => {
                                        var value = e.target.value.split('-');
                                        if (value[1] === 'ADD_TO_THIS_ADSET') {
                                          const obj1 = {
                                            ...addToThisAdsetList,
                                          };
                                          const obj2 = { ...addToAllAdsetList };
                                          if (
                                            obj2[currentCreative + '-' + index]
                                          ) {
                                            delete obj2[
                                              currentCreative + '-' + index
                                            ];
                                            obj1[
                                              currentCreative + '-' + index
                                            ] = {
                                              ...creative,
                                              ...{
                                                adName:
                                                  'AD ' +
                                                  Math.floor(
                                                    Math.random() *
                                                      numberOfAds(facebookAds)
                                                  ),
                                              },
                                            };
                                          } else {
                                            obj1[
                                              currentCreative + '-' + index
                                            ] = {
                                              ...creative,
                                              ...{
                                                adName:
                                                  'AD ' +
                                                  Math.floor(
                                                    Math.random() *
                                                      numberOfAds(facebookAds)
                                                  ),
                                              },
                                            };
                                          }
                                          setAddToThisAdsetList(obj1);
                                          setAddToAllAdsetList(obj2);
                                        } else {
                                          const obj1 = { ...addToAllAdsetList };
                                          const obj2 = {
                                            ...addToThisAdsetList,
                                          };
                                          if (
                                            obj2[currentCreative + '-' + index]
                                          ) {
                                            delete obj2[
                                              currentCreative + '-' + index
                                            ];
                                            obj1[
                                              currentCreative + '-' + index
                                            ] = {
                                              ...creative,
                                              ...{
                                                adName:
                                                  'AD ' +
                                                  Math.floor(
                                                    Math.random() *
                                                      numberOfAds(facebookAds)
                                                  ),
                                              },
                                            };
                                          } else {
                                            obj1[
                                              currentCreative + '-' + index
                                            ] = {
                                              ...creative,
                                              ...{
                                                adName:
                                                  'AD ' +
                                                  Math.floor(
                                                    Math.random() *
                                                      numberOfAds(facebookAds)
                                                  ),
                                              },
                                            };
                                          }
                                          setAddToAllAdsetList(obj1);
                                          setAddToThisAdsetList(obj2);
                                        }
                                      }}
                                    >
                                      <FormControlLabel
                                        className='addad_lable_radio'
                                        value={`${
                                          currentCreative + '_' + index
                                        }-ADD_TO_THIS_ADSET`}
                                        control={
                                          <Radio className='addad_radio_action' />
                                        }
                                        label='Add to this adset'
                                      />
                                      <FormControlLabel
                                        className='addad_lable_radio'
                                        value={`${
                                          currentCreative + '_' + index
                                        }-ADD_TO_ALL_ADSET`}
                                        control={
                                          <Radio className='addad_radio_action' />
                                        }
                                        label='Add to all adsets'
                                      />
                                    </RadioGroup>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </Slider>
                    </div>
                  </div>
                </div>

                <div className='addad_modal_footer'>
                  <div className='addad_row_footer'>
                    <button
                      className='cancle_button_modal'
                      onClick={() => {
                        setAddToThisAdsetList({});
                        setAddToAllAdsetList({});
                        setAddNewad(!addNewad);
                      }}
                    >
                      Cancel
                    </button>
                    <PrimaryButton
                      className='edit_response_button'
                      title='Save Changes'
                      onClick={() => {
                        dispatch({
                          type: 'ADD_TO_THIS_AND_ALL_ADSET',
                          payload: {
                            addToThisAdsetList: addToThisAdsetList,
                            addToAllAdsetList: addToAllAdsetList,
                            currentAdset: currentAdset,
                            outerKey: 'topOfTheFunnelLookalike',
                          },
                        });
                        setAddToThisAdsetList({});
                        setAddToAllAdsetList({});
                        setAddNewad(!addNewad);
                      }}
                    ></PrimaryButton>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          {/*edit ad*/}
          {editAdsDetails && (
            <Modal
              open={editAd}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
              className='large_addad_popup'
            >
              <Box sx={style}>
                <div className='addad_popup_layout'>
                  <div className='addad_modal_header'>
                    <p>Edit Ad</p>
                    <a
                      className='popup_close_button'
                      onClick={() => setEditAd(!editAd)}
                    >
                      <span></span>
                    </a>
                  </div>
                  <div className='addad_modal_layout'>
                    <div className='addad_modal_sections'>
                      <div className='addad_modal_preview'>
                        <div className='ad_preview_title'>
                          <p>Ad 1</p>
                        </div>
                        <div className='adset_slide_head_skip'>
                          <Tooltip title={editAdsDetails.ad.primaryText} arrow>
                            <p>
                              {truncateString(editAdsDetails.ad.primaryText)}
                            </p>
                          </Tooltip>
                        </div>
                        <div className='ad_preview_image'>
                          <img
                            src={
                              process.env.REACT_APP_BACKEND_BASE_URL +
                              '/files/' +
                              editAdsDetails.ad.imageUrl
                            }
                          />
                          <div className='preview_ad_upload'>
                            <label htmlFor='select-image'>
                              <input
                                type='file'
                                ref={hiddenFileInput}
                                id='select-image'
                                style={{ display: 'none' }}
                                accept='image/*'
                                onChange={(e) => {
                                  const tempObj = { ...editAdsDetails };
                                  const form = new FormData();
                                  form.append('file', e.target.files[0]);
                                  setSelectedImage(e.target.files[0]);
                                  uploadFile(form).then((res) => {
                                    tempObj['ad']['imageUrl'] = res.fileId;
                                    setEditAdsDetails(tempObj);
                                  });
                                }}
                              />
                              <PrimaryButton
                                className='edit_response_button'
                                onClick={handleUploadFile}
                                title='Upload New Image'
                                icon='camera'
                              ></PrimaryButton>
                            </label>
                          </div>
                        </div>
                        <div className='adset_slide_footer flex-center-space'>
                          <div className='adset_slide_footer_info'>
                            <h2>{onboardingInfo?.clientAccess?.websiteUrl}</h2>
                            <p>{editAdsDetails.ad.headline}</p>
                            <span>{editAdsDetails.ad.description}</span>
                          </div>
                        </div>
                      </div>
                      <div className='addad_modal_edit_options'>
                        <form>
                          <div className='form_row_edit'>
                            <label>Primary Text</label>
                            <textarea
                              className='large_textarea'
                              value={editAdsDetails.ad.primaryText}
                              onChange={(e) => {
                                const tempObj = { ...editAdsDetails };
                                tempObj['ad']['primaryText'] = e.target.value;
                                setEditAdsDetails(tempObj);
                              }}
                            >
                              {editAdsDetails.ad.primaryText}
                            </textarea>
                          </div>
                          <div className='form_row_edit'>
                            <label>Headline</label>
                            <input
                              type='text'
                              defaultValue={editAdsDetails.ad.headline}
                              value={editAdsDetails.ad.headline}
                              onChange={(e) => {
                                const tempObj = { ...editAdsDetails };
                                tempObj['ad']['headline'] = e.target.value;
                                setEditAdsDetails(tempObj);
                              }}
                            />
                          </div>
                          <div className='form_row_edit'>
                            <label>Description</label>
                            <textarea
                              onChange={(e) => {
                                const tempObj = { ...editAdsDetails };
                                tempObj['ad']['description'] = e.target.value;
                                setEditAdsDetails(tempObj);
                              }}
                              value={editAdsDetails.ad.description}
                            >
                              {editAdsDetails.ad.description}
                            </textarea>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className='addad_modal_footer'>
                    <div className='addad_row_footer'>
                      <button
                        className='cancle_button_modal'
                        onClick={() => setEditAd(!editAd)}
                      >
                        Cancel
                      </button>
                      <PrimaryButton
                        className='edit_response_button'
                        title='Save Changes'
                        onClick={() => editSpecificAds()}
                      ></PrimaryButton>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          )}
        </>
      ) : (
        <>
          <HoldTight />
        </>
      )}
    </>
  );
};

export default LookALike;
