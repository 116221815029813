import { useState, useEffect } from "react";
import AllOutIcon from "@mui/icons-material/AllOut";
import { Button, Card, CardContent, CardHeader, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Carousel from "react-grid-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  goBackHDMClientCompo,
  goToSpecificCompoHDMClient,
  updateHDMCompoReduxClient,
} from "../../../api/HDMClient/componentTracking/action";
import {
  updateHDMStrategyDeck,
  updateHDMClientDetails,
  approveStrategyDeckWithDetails,
} from "../../../api/HDMClient/action";
import { useTabState } from "../../CustomTab/customTab";
import "./StrategyDeckGoogleHDM.css";
import FACEBOOK from "./strategy-Icons/facebook.png";
import SAMPLE1 from "./strategy-Icons/sample1.jfif";
import SAMPLE2 from "./strategy-Icons/sample2.jpg";
import TICK from "./strategy-Icons/tick.png";
import USERGROUP from "./strategy-Icons/userGroup.png";
import USER from "./strategy-Icons/Vector.png";
import { approveDeckApi } from "../../../api/client/saga";
import StrategyDeckApproval from "../../modals/StrategyDeckApproval";
import {
  clientDashboardIds,
  componentWithIndexHDMClient,
} from "../../../utils/HDMClientDashboardIdsUtil";
import { clientDashboardIds as clientDashboardIdsLD } from "../../../utils/clientDashboardIdsUtil";
import {
  isPlatformSelected,
  isPlatformSelectedHDM,
} from "../../../utils/accountManagerUtil";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import { LDcomponentWithIndexClient } from "../../../utils/clientDashboardIdsUtil";

const cn = (...args) => args.filter(Boolean).join(" ");

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button className={cn("tab", isActive && "active")} onClick={onClick}>
      {children}
    </button>
  );
};

const StrategyDeckFacebookHDM = ({agencyType="HDM"}) => {
  const [modalApprove, setModalApprove] = useState(false);
  const dispatch = useDispatch();

  const form = useSelector((state) =>
    agencyType === "HDM" ? state.hdmClient : state.client
  );
  const [totalBudget, setTotalBudget] = useState(0);
  const [tofLookAlike, setTofLookAlike] = useState({});
  const [tofInterest, setTofInterest] = useState({});
  const [mof, setMof] = useState({});
  const [bof, setBof] = useState({});
  const strategyIterations = useSelector(
    (state) => state.client.strategyIterations
  );

  useEffect(() => {
    if (form) {
      const tofLookAlikeBudget =
        form?.fbAdsStrategyInfo?.topOfTheFunnelLookalike?.budget || 0;
      const tofInterestBudget =
        form?.fbAdsStrategyInfo?.topOfTheFunnelInterest?.budget || 0;
      const mofBudget = form?.fbAdsStrategyInfo?.middleOfTheFunnel?.budget || 0;
      const bofBudget = form?.fbAdsStrategyInfo?.bottomOfTheFunnel?.budget || 0;
      const budget =
        tofLookAlikeBudget + tofInterestBudget + mofBudget + bofBudget;

      setTotalBudget(budget);
      setTofLookAlike(form?.fbAdsStrategyInfo?.topOfTheFunnelLookalike || {});
      setTofInterest(form?.fbAdsStrategyInfo?.topOfTheFunnelInterest || {});
      setMof(form?.fbAdsStrategyInfo?.middleOfTheFunnel || {});
      setBof(form?.fbAdsStrategyInfo?.bottomOfTheFunnel || {});
    }
  }, [form]);

  const removeFaceBookAds = (strategyType, adsetId, adName) => {
    let strategyContent = {};
    if (strategyType === "topOfTheFunnelLookalike") {
      strategyContent = { ...tofLookAlike };
    } else if (strategyType === "topOfTheFunnelInterest") {
      strategyContent = { ...tofInterest };
    } else if (strategyType === "middleOfTheFunnel") {
      strategyContent = { ...mof };
    } else {
      strategyContent = { ...bof };
    }

    const adsets = (strategyContent.adsets || []).map((adset, index) => {
      if (index === adsetId) {
        const facebookAds = (adset.facebookAds || []).filter(
          (ad) => ad.adName !== adName
        );
        return {
          ...adset,
          facebookAds: facebookAds,
        };
      } else {
        return {
          ...adset,
        };
      }
    });
    strategyContent.adsets = adsets;
    dispatch(
      updateHDMStrategyDeck({
        [strategyType]: strategyContent,
      })
    );
  };

  const removeKeyword = (adsetName, keywordId) => {
    const topOfTheFunnelInterest = { ...tofInterest };
    const adsets = (topOfTheFunnelInterest.adsets || []).map((adset) => {
      if (adset.adsetName === adsetName) {
        const keywords = (adset.keywords || []).filter(
          (keyword) => keyword.id !== keywordId
        );
        return {
          ...adset,
          keywords: keywords,
        };
      } else {
        return { ...adset };
      }
    });
    topOfTheFunnelInterest["adsets"] = adsets;

    dispatch(
      updateHDMStrategyDeck({
        topOfTheFunnelInterest: topOfTheFunnelInterest,
      })
    );
  };
  const truncateString = (str) => {
    return str?.length > 30 ? str?.substring(0, 29) + "..." : str;
  };
  const truncateString2 = (str) => {
    return str?.length > 80 ? str?.substring(0, 80) + "..." : str;
  };

  console.log("form", form);
  return (
    <div className="intro_strategy_main">
      {/* <div className="intro_strategy_layout">
        <div className="intro_strategy_warning">
          <span className="icon orange_check">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.75" width="20" height="20" rx="10" fill="#F99F35" />
              <path
                d="M6 10.7832L8.86226 13.65L14.7592 7.75"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p>
            You are viewing the Strategy copy generated on 13 Dec 2022 - 09:00.
            Your suggested changes have been registered and you will be notified
            soon
          </p>
        </div>
      </div> */}
      <div className="google_strategy_layout">
        <div className="facebook_strategy_banner">
          <div className="google_banner_part">
            <div className="google_strategy_row">
              <div className="google_strategy_logo">
                <h6
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Lato",
                    fontSize: "25.5237px",
                    fontWeight: "700",
                    lineHeight: "46px",
                    margin: "0px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      top: "15px",
                      height: 100,
                      width: 100,
                    }}
                    src={FACEBOOK}
                    alt=""
                  />{" "}
                  Facebook
                </h6>
              </div>
              <div className="google_strategy_budget">
                <p>Total Budget : ${totalBudget}</p>
              </div>
            </div>
            <p
              className="google_strategy_info"
              style={{
                marginLeft: 10,
                fontWeight: 400,
                fontSize: "13.9749px",

                color: "#000000",
              }}
            >
              Please review the strategy for running your campaigns on facebook
              for most efficient delivery and performance.
            </p>
          </div>
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header" style={{ display: "flex" }}>
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Top of the Funnel - Lookalike Campaign </p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${tofLookAlike?.budget}</p>
                </div>
              </div>
              {/* <p className="google_title_info">
                Bidding on your branded terms to prevent competitors from taking
                your spot and capture searches generated from brand awareness
                campaigns.
              </p> */}
            </div>
            {/* <div
              style={{
                display: "flex",
                background: " #F2F7FF",
                borderRadius: "8.99194px",
                padding: " 14.9361px 21.3373px",
                width: "164.81px",
                height: " 51.21px",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                float: "right",
              }}
            >
              <img
                src={TICK}
                height={15}
                width={15}
                style={{ marginRight: 10 }}
              />
              <b style={{ color: "#0869FB" }}>Approve All</b>
            </div> */}
          </div>

          {(tofLookAlike.adsets || []).map((adset, index) => {
            return (
              <Accordion className="accordion_strategy_body" key={index}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>
                      {adset.adsetName || `Adset ${index + 1}`}
                      {/* <p
                        style={{
                          display: "inline",
                          fontSize: "10px",
                          color: "#959595",
                        }}
                      >
                        Ad Set 1
                      </p> */}
                    </h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: " 15.7359px",
                      color: " #333333",
                      display: "inline",
                      marginBottom: 10,
                    }}
                  >
                    Audience : {adset?.audeince?.name}
                    <p style={{ marginLeft: 10, display: "inline" }}>
                      {" "}
                      Range : {adset.minRange}% - {adset.maxRange}%
                    </p>
                  </p>
                  <div
                    className="strategy_accordion_layout"
                    style={{ marginTop: 20 }}
                  >
                    <div className="strategy_row_item">
                      <Carousel cols={3} rows={1} gap={0} loop>
                        {(adset.facebookAds || []).map((adItem, idx) => {
                          return (
                            <Carousel.Item key={idx - idx}>
                              <div
                                className="strategy_column_item"
                                style={{ margin: "0px 10px" }}
                              >
                                <Card sx={{ maxWidth: 345 }}>
                                  <CardHeader
                                    style={{
                                      background: " #EEF4FF",
                                      border: " 0.868595px solid #F0F0F0",
                                      borderRadius:
                                        "6.94876px 6.94876px 0px 0px;",
                                      textAlign: "center",
                                      height: "40px",
                                    }}
                                    subheader={adItem.adname}
                                  />
                                  <CardContent style={{ padding: 0 }}>
                                    <div>
                                      <Tooltip title={adItem.primaryText} arrow>
                                        <div
                                          style={{
                                            fontSize: 10,
                                            color: "#000000",
                                            fontWidth: "500",
                                            padding: 8,
                                          }}
                                        >
                                          {truncateString2(adItem.primaryText)}
                                        </div>
                                      </Tooltip>

                                      <div
                                        style={{
                                          border: "1px solid #F0F0F0",
                                          height: 1,
                                          display: "block",
                                        }}
                                      ></div>
                                      <div>
                                        <div
                                          style={{
                                            width: 311,
                                            height: 302,
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env
                                                .REACT_APP_BACKEND_BASE_URL +
                                              "/files/" +
                                              adItem.imageUrl
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          background: "#EEF4FF",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          padding: 8,
                                          paddingTop: 16,
                                        }}
                                      >
                                        <div style={{ display: "block" }}>
                                          <div
                                            style={{
                                              color: "#5E5E5E",
                                              fontSize: 10,
                                            }}
                                          >
                                            something.com
                                          </div>
                                          <Tooltip
                                            title={adItem.headline}
                                            arrow
                                          >
                                            <div style={{ fontSize: 12 }}>
                                              <b>
                                                {truncateString(
                                                  adItem.headline
                                                )}
                                              </b>
                                            </div>
                                          </Tooltip>

                                          <div
                                            style={{
                                              color: "#5E5E5E",
                                              fontSize: 10,
                                            }}
                                          >
                                            {adItem.description}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            fontFamily: "Roboto",
                                            padding: 9,
                                            color: "#000000",
                                            fontSize: 12,
                                            background: "#E4E6EA",
                                            borderRadius: "2px",
                                            fontWeight: "700",
                                            height: 32,
                                          }}
                                        >
                                          Book Now
                                        </div>
                                      </div>
                                    </div>
                                  </CardContent>
                                </Card>
                                <div className="headline_column_actions">
                                  {/* <button className="actions_headline_strategy accept_icon"></button> */}
                                  <button
                                    className="actions_headline_strategy reject_icon"
                                    onClick={() =>
                                      removeFaceBookAds(
                                        "topOfTheFunnelLookalike",
                                        index,
                                        adItem.adName
                                      )
                                    }
                                  ></button>
                                </div>
                              </div>{" "}
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
          {/* <Accordion className="accordion_strategy_body">
            <AccordionSummary className="accordion_strategy_head">
              <div className="strategy_accordion_title">
                <h4>
                  Adset 1 name{" "}
                  <p
                    style={{
                      display: "inline",
                      fontSize: "10px",
                      color: "#959595",
                    }}
                  >
                    Ad Set 1
                  </p>
                </h4>
                <span></span>
              </div>
            </AccordionSummary>
            <AccordionDetails className="accordion_strategy_content">
              <p
                style={{
                  fontWeight: 400,
                  fontSize: " 15.7359px",
                  color: " #333333",
                  display: "inline",
                  marginBottom: 10,
                }}
              >
                Audience : <a href="#">customaudience1308</a>
                <p style={{ marginLeft: 10, display: "inline" }}>
                  {" "}
                  Range : 3% - 5%
                </p>
              </p>
              <div
                className="strategy_accordion_layout"
                style={{ marginTop: 20 }}
              >
                <div className="strategy_row_item">
                  <Carousel cols={3} rows={1} gap={0} loop>
                    <Carousel.Item>
                      <div
                        className="strategy_column_item"
                        style={{ margin: "0px 10px" }}
                      >
                        <Card sx={{ maxWidth: 345 }}>
                          <CardHeader
                            style={{
                              background: " #EEF4FF",
                              border: " 0.868595px solid #F0F0F0",
                              borderRadius: "6.94876px 6.94876px 0px 0px;",
                              textAlign: "center",
                              height: "40px",
                            }}
                            subheader="Ad 1"
                          />
                          <CardContent style={{ padding: 0 }}>
                            <div>
                              <div
                                style={{
                                  fontSize: 10,
                                  color: "#000000",
                                  fontWidth: "500",
                                  padding: 8,
                                }}
                              >
                                Skip the pinterest boards - here are some of our
                                favourite condo home designs that you will eant
                                to bookmark and consider next!{" "}
                                <a href="#"> #livspace </a>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #F0F0F0",
                                  height: 1,
                                  marginTop: 15,
                                  display: "block",
                                  marginBottom: 15,
                                }}
                              ></div>
                              <div
                                style={{
                                  display: "block",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <div style={{ marginLeft: 72, fontSize: 12 }}>
                                    Before <b>Livspace</b>
                                  </div>
                                  <img
                                    src={SAMPLE1}
                                    height={100}
                                    width={200}
                                    style={{
                                      margin: "auto",
                                      display: "block",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    background: "red",
                                  }}
                                >
                                  <img
                                    src={SAMPLE2}
                                    height={100}
                                    width={200}
                                    style={{
                                      margin: "auto",
                                      display: "block",
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginLeft: 72,
                                      color: "#ffffff",
                                      fontSize: 12,
                                    }}
                                  >
                                    After <b>Livspace</b>
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "end",
                                      color: "#ffffff",
                                      fontSize: 12,
                                      marginRight: 10,
                                      paddingBottom: 10,
                                      display: "flex",
                                      justifyContent: "end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AllOutIcon />
                                    <span>LIVSPACE</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  background: "#EEF4FF",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: 8,
                                }}
                              >
                                <div style={{ display: "block" }}>
                                  <div
                                    style={{ color: "#5E5E5E", fontSize: 10 }}
                                  >
                                    LIVSPACE.com
                                  </div>
                                  <div style={{ fontSize: 12 }}>
                                    <b>Make Your Space your dream space</b>
                                  </div>
                                  <div
                                    style={{ color: "#5E5E5E", fontSize: 10 }}
                                  >
                                    Now in your city
                                  </div>
                                </div>
                                <div
                                  style={{
                                    padding: 9,
                                    color: "#000000",
                                    fontSize: 10,
                                    background: "#cdcdcd",
                                    borderRadius: "2px",
                                    height: 30,
                                  }}
                                >
                                  Book Now
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                        <div className="headline_column_actions">
                          <button className="actions_headline_strategy accept_icon"></button>
                          <button className="actions_headline_strategy reject_icon"></button>
                        </div>
                      </div>{" "}
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        className="strategy_column_item"
                        style={{ margin: "0px 10px" }}
                      >
                        <Card sx={{ maxWidth: 345 }}>
                          <CardHeader
                            style={{
                              background: " #EEF4FF",
                              border: " 0.868595px solid #F0F0F0",
                              borderRadius: "6.94876px 6.94876px 0px 0px;",
                              textAlign: "center",
                              height: "40px",
                            }}
                            subheader="Ad 1"
                          />
                          <CardContent style={{ padding: 0 }}>
                            <div>
                              <div
                                style={{
                                  fontSize: 10,
                                  color: "#000000",
                                  fontWidth: "500",
                                  padding: 8,
                                }}
                              >
                                Skip the pinterest boards - here are some of our
                                favourite condo home designs that you will eant
                                to bookmark and consider next!{" "}
                                <a href="#"> #livspace </a>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #F0F0F0",
                                  height: 1,
                                  marginTop: 15,
                                  display: "block",
                                  marginBottom: 15,
                                }}
                              ></div>
                              <div
                                style={{
                                  display: "block",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <div style={{ marginLeft: 72, fontSize: 12 }}>
                                    Before <b>Livspace</b>
                                  </div>
                                  <img
                                    src={SAMPLE1}
                                    height={100}
                                    width={200}
                                    style={{
                                      margin: "auto",
                                      display: "block",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    background: "red",
                                  }}
                                >
                                  <img
                                    src={SAMPLE2}
                                    height={100}
                                    width={200}
                                    style={{
                                      margin: "auto",
                                      display: "block",
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginLeft: 72,
                                      color: "#ffffff",
                                      fontSize: 12,
                                    }}
                                  >
                                    After <b>Livspace</b>
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "end",
                                      color: "#ffffff",
                                      fontSize: 12,
                                      marginRight: 10,
                                      paddingBottom: 10,
                                      display: "flex",
                                      justifyContent: "end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AllOutIcon />
                                    <span>LIVSPACE</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  background: "#EEF4FF",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: 8,
                                }}
                              >
                                <div style={{ display: "block" }}>
                                  <div
                                    style={{ color: "#5E5E5E", fontSize: 10 }}
                                  >
                                    LIVSPACE.com
                                  </div>
                                  <div style={{ fontSize: 12 }}>
                                    <b>Make Your Space your dream space</b>
                                  </div>
                                  <div
                                    style={{ color: "#5E5E5E", fontSize: 10 }}
                                  >
                                    Now in your city
                                  </div>
                                </div>
                                <div
                                  style={{
                                    padding: 9,
                                    color: "#000000",
                                    fontSize: 10,
                                    background: "#cdcdcd",
                                    borderRadius: "2px",
                                    height: 30,
                                  }}
                                >
                                  Book Now
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                        <div className="headline_column_actions">
                          <button className="actions_headline_strategy accept_icon"></button>
                          <button className="actions_headline_strategy reject_icon"></button>
                        </div>
                      </div>{" "}
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        className="strategy_column_item"
                        style={{ margin: "0px 10px" }}
                      >
                        <Card sx={{ maxWidth: 345 }}>
                          <CardHeader
                            style={{
                              background: " #EEF4FF",
                              border: " 0.868595px solid #F0F0F0",
                              borderRadius: "6.94876px 6.94876px 0px 0px;",
                              textAlign: "center",
                              height: "40px",
                            }}
                            subheader="Ad 1"
                          />
                          <CardContent style={{ padding: 0 }}>
                            <div>
                              <div
                                style={{
                                  fontSize: 10,
                                  color: "#000000",
                                  fontWidth: "500",
                                  padding: 8,
                                }}
                              >
                                Skip the pinterest boards - here are some of our
                                favourite condo home designs that you will eant
                                to bookmark and consider next!{" "}
                                <a href="#"> #livspace </a>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #F0F0F0",
                                  height: 1,
                                  marginTop: 15,
                                  display: "block",
                                  marginBottom: 15,
                                }}
                              ></div>
                              <div
                                style={{
                                  display: "block",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <div style={{ marginLeft: 72, fontSize: 12 }}>
                                    Before <b>Livspace</b>
                                  </div>
                                  <img
                                    src={SAMPLE1}
                                    height={100}
                                    width={200}
                                    style={{
                                      margin: "auto",
                                      display: "block",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    background: "red",
                                  }}
                                >
                                  <img
                                    src={SAMPLE2}
                                    height={100}
                                    width={200}
                                    style={{
                                      margin: "auto",
                                      display: "block",
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginLeft: 72,
                                      color: "#ffffff",
                                      fontSize: 12,
                                    }}
                                  >
                                    After <b>Livspace</b>
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "end",
                                      color: "#ffffff",
                                      fontSize: 12,
                                      marginRight: 10,
                                      paddingBottom: 10,
                                      display: "flex",
                                      justifyContent: "end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AllOutIcon />
                                    <span>LIVSPACE</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  background: "#EEF4FF",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: 8,
                                }}
                              >
                                <div style={{ display: "block" }}>
                                  <div
                                    style={{ color: "#5E5E5E", fontSize: 10 }}
                                  >
                                    LIVSPACE.com
                                  </div>
                                  <div style={{ fontSize: 12 }}>
                                    <b>Make Your Space your dream space</b>
                                  </div>
                                  <div
                                    style={{ color: "#5E5E5E", fontSize: 10 }}
                                  >
                                    Now in your city
                                  </div>
                                </div>
                                <div
                                  style={{
                                    padding: 9,
                                    color: "#000000",
                                    fontSize: 10,
                                    background: "#cdcdcd",
                                    borderRadius: "2px",
                                    height: 30,
                                  }}
                                >
                                  Book Now
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                        <div className="headline_column_actions">
                          <button className="actions_headline_strategy accept_icon"></button>
                          <button className="actions_headline_strategy reject_icon"></button>
                        </div>
                      </div>{" "}
                    </Carousel.Item>
                    <Carousel.Item>
                      <div
                        className="strategy_column_item"
                        style={{ margin: "0px 10px" }}
                      >
                        <Card sx={{ maxWidth: 345 }}>
                          <CardHeader
                            style={{
                              background: " #EEF4FF",
                              border: " 0.868595px solid #F0F0F0",
                              borderRadius: "6.94876px 6.94876px 0px 0px;",
                              textAlign: "center",
                              height: "40px",
                            }}
                            subheader="Ad 1"
                          />
                          <CardContent style={{ padding: 0 }}>
                            <div>
                              <div
                                style={{
                                  fontSize: 10,
                                  color: "#000000",
                                  fontWidth: "500",
                                  padding: 8,
                                }}
                              >
                                Skip the pinterest boards - here are some of our
                                favourite condo home designs that you will eant
                                to bookmark and consider next!{" "}
                                <a href="#"> #livspace </a>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #F0F0F0",
                                  height: 1,
                                  marginTop: 15,
                                  display: "block",
                                  marginBottom: 15,
                                }}
                              ></div>
                              <div
                                style={{
                                  display: "block",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <div style={{ marginLeft: 72, fontSize: 12 }}>
                                    Before <b>Livspace</b>
                                  </div>
                                  <img
                                    src={SAMPLE1}
                                    height={100}
                                    width={200}
                                    style={{
                                      margin: "auto",
                                      display: "block",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    background: "red",
                                  }}
                                >
                                  <img
                                    src={SAMPLE2}
                                    height={100}
                                    width={200}
                                    style={{
                                      margin: "auto",
                                      display: "block",
                                    }}
                                  />
                                  <div
                                    style={{
                                      marginLeft: 72,
                                      color: "#ffffff",
                                      fontSize: 12,
                                    }}
                                  >
                                    After <b>Livspace</b>
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "end",
                                      color: "#ffffff",
                                      fontSize: 12,
                                      marginRight: 10,
                                      paddingBottom: 10,
                                      display: "flex",
                                      justifyContent: "end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AllOutIcon />
                                    <span>LIVSPACE</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  background: "#EEF4FF",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: 8,
                                }}
                              >
                                <div style={{ display: "block" }}>
                                  <div
                                    style={{ color: "#5E5E5E", fontSize: 10 }}
                                  >
                                    LIVSPACE.com
                                  </div>
                                  <div style={{ fontSize: 12 }}>
                                    <b>Make Your Space your dream space</b>
                                  </div>
                                  <div
                                    style={{ color: "#5E5E5E", fontSize: 10 }}
                                  >
                                    Now in your city
                                  </div>
                                </div>
                                <div
                                  style={{
                                    padding: 9,
                                    color: "#000000",
                                    fontSize: 10,
                                    background: "#cdcdcd",
                                    borderRadius: "2px",
                                    height: 30,
                                  }}
                                >
                                  Book Now
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                        <div className="headline_column_actions">
                          <button className="actions_headline_strategy accept_icon"></button>
                          <button className="actions_headline_strategy reject_icon"></button>
                        </div>
                      </div>{" "}
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </AccordionDetails>
          </Accordion> */}
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Top of the Funnel - Interest</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${tofInterest.budget || 0}</p>
                </div>
              </div>
              {/* <p className="google_title_info">
                Bidding on your competitor's brand terms to create awareness and
                steal market share.
              </p> */}
            </div>
          </div>
          {(tofInterest.adsets || []).map((adset, index) => {
            return (
              <Accordion className="accordion_strategy_body" key={index}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>
                      {adset.adsetName + " "}
                      <p
                        style={{
                          display: "inline",
                          fontSize: "10px",
                          color: "#959595",
                        }}
                      >
                        Ad Set {index + 1}
                      </p>
                    </h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <div className="strategy_accordion_layout">
                    <div className="strategy_row_item">
                      <div className="strategy_column_item search_row_strategy">
                        <Autocomplete
                          open
                          multiple
                          disableCloseOnSelect
                          noOptionsText="No labels"
                          renderOption={(props, option, { selected }) => (
                            <li className="autosearch_list">
                              <div className="strategy_list_row">
                                <div {...props}>
                                  <div className="strategy_list_column">
                                    <span>
                                      <img src={USER} />
                                    </span>
                                    <p>{option.name}</p>
                                  </div>
                                </div>
                                <div>
                                  <div className="headline_column_actions">
                                    {/* <button
                                      className={`
                               
                                   
                                  list_search_actions accept_icon`}
                                    ></button> */}
                                    <button
                                      className={`
                               
                                   list_search_actions reject_icon`}
                                      onClick={() =>
                                        removeKeyword(
                                          adset.adsetName,
                                          option.id
                                        )
                                      }
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          options={[...adset.keywords]}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <div className="search_strategy_header">
                              <span>
                                {" "}
                                <img src={USERGROUP} />
                              </span>
                              <TextField
                                {...params}
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Search..."
                                variant="standard"
                              />
                            </div>
                          )}
                          disablePortal={true}
                          className="auto_complete_search"
                        />
                      </div>
                      <div style={{ display: "flex", marginLeft: -22 }}>
                        <Carousel cols={2} rows={1} gap={0} loop>
                          {(adset.facebookAds || []).map((adItem, idx) => {
                            return (
                              <Carousel.Item key={idx - idx}>
                                <div
                                  className="strategy_column_item"
                                  style={{ margin: "0px 10px" }}
                                >
                                  <Card sx={{ maxWidth: 345 }}>
                                    <CardHeader
                                      style={{
                                        background: " #EEF4FF",
                                        border: " 0.868595px solid #F0F0F0",
                                        borderRadius:
                                          "6.94876px 6.94876px 0px 0px;",
                                        textAlign: "center",
                                        height: "40px",
                                      }}
                                      subheader={adItem.adname}
                                    />
                                    <CardContent style={{ padding: 0 }}>
                                      <div>
                                        <Tooltip
                                          title={adItem.primaryText}
                                          arrow
                                        >
                                          <div
                                            style={{
                                              fontSize: 10,
                                              color: "#000000",
                                              fontWidth: "500",
                                              padding: 8,
                                            }}
                                          >
                                            {truncateString2(
                                              adItem.primaryText
                                            )}
                                          </div>
                                        </Tooltip>

                                        <div
                                          style={{
                                            border: "1px solid #F0F0F0",
                                            height: 1,
                                            display: "block",
                                          }}
                                        ></div>
                                        <div>
                                          <div
                                            style={{
                                              width: 311,
                                              height: 302,
                                            }}
                                          >
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_BACKEND_BASE_URL +
                                                "/files/" +
                                                adItem.imageUrl
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            background: "#EEF4FF",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: 8,
                                            paddingTop: 10,
                                          }}
                                        >
                                          <div style={{ display: "block" }}>
                                            <div
                                              style={{
                                                color: "#5E5E5E",
                                                fontSize: 10,
                                              }}
                                            >
                                              something.com
                                            </div>
                                            <Tooltip
                                              title={adItem.headline}
                                              arrow
                                            >
                                              <div style={{ fontSize: 12 }}>
                                                <b>
                                                  {truncateString(
                                                    adItem.headline
                                                  )}
                                                </b>
                                              </div>
                                            </Tooltip>

                                            <div
                                              style={{
                                                color: "#5E5E5E",
                                                fontSize: 10,
                                              }}
                                            >
                                              {adItem.description}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              fontFamily: "Roboto",
                                              padding: 9,
                                              color: "#000000",
                                              fontSize: 12,
                                              background: "#E4E6EA",
                                              borderRadius: "2px",
                                              fontWeight: "700",
                                              height: 32,
                                            }}
                                          >
                                            Book Now
                                          </div>
                                        </div>
                                      </div>
                                    </CardContent>
                                  </Card>
                                  <div className="headline_column_actions">
                                    {/* <button className="actions_headline_strategy accept_icon"></button> */}
                                    <button
                                      className="actions_headline_strategy reject_icon"
                                      onClick={() =>
                                        removeFaceBookAds(
                                          "topOfTheFunnelInterest",
                                          index,
                                          adItem.adName
                                        )
                                      }
                                    ></button>
                                  </div>
                                </div>{" "}
                              </Carousel.Item>
                            );
                          })}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Middle of the Funnel</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${mof.budget || 0}</p>
                </div>
              </div>
              {/* <p className="google_title_info">
                Bidding on your branded terms to prevent competitors from taking
                your spot and capture searches generated from brand awareness
                campaigns.
              </p> */}
            </div>
          </div>
          {(mof.adsets || []).map((adset, index) => {
            return (
              <Accordion className="accordion_strategy_body" key={index}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>
                      {adset.adsetName || `Adset ${index + 1}`}
                      {/* <p
                        style={{
                          display: "inline",
                          fontSize: "10px",
                          color: "#959595",
                        }}
                      >
                        Ad Set 1
                      </p> */}
                    </h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: " 15.7359px",
                      color: " #333333",
                      display: "inline",
                      marginBottom: 10,
                    }}
                  >
                    Audience : {adset?.audeince?.name}
                  </p>
                  <div
                    className="strategy_accordion_layout"
                    style={{ marginTop: 20 }}
                  >
                    <div className="strategy_row_item">
                      <Carousel cols={3} rows={1} gap={0} loop>
                        {(adset.facebookAds || []).map((adItem, idx) => {
                          return (
                            <Carousel.Item key={idx - idx}>
                              <div
                                className="strategy_column_item"
                                style={{ margin: "0px 10px" }}
                              >
                                <Card sx={{ maxWidth: 345 }}>
                                  <CardHeader
                                    style={{
                                      background: " #EEF4FF",
                                      border: " 0.868595px solid #F0F0F0",
                                      borderRadius:
                                        "6.94876px 6.94876px 0px 0px;",
                                      textAlign: "center",
                                      height: "40px",
                                    }}
                                    subheader={adItem.adname}
                                  />
                                  <CardContent style={{ padding: 0 }}>
                                    <div>
                                      <Tooltip title={adItem.primaryText} arrow>
                                        <div
                                          style={{
                                            fontSize: 10,
                                            color: "#000000",
                                            fontWidth: "500",
                                            padding: 8,
                                          }}
                                        >
                                          {truncateString2(adItem.primaryText)}
                                        </div>
                                      </Tooltip>

                                      <div
                                        style={{
                                          border: "1px solid #F0F0F0",
                                          height: 1,
                                          display: "block",
                                        }}
                                      ></div>
                                      <div>
                                        <div
                                          style={{
                                            width: 311,
                                            height: 302,
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env
                                                .REACT_APP_BACKEND_BASE_URL +
                                              "/files/" +
                                              adItem.imageUrl
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          background: "#EEF4FF",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          padding: 8,
                                          paddingTop: 16,
                                        }}
                                      >
                                        <div style={{ display: "block" }}>
                                          <div
                                            style={{
                                              color: "#5E5E5E",
                                              fontSize: 10,
                                            }}
                                          >
                                            something.com
                                          </div>
                                          <Tooltip
                                            title={adItem.headline}
                                            arrow
                                          >
                                            <div style={{ fontSize: 12 }}>
                                              <b>
                                                {truncateString(
                                                  adItem.headline
                                                )}
                                              </b>
                                            </div>
                                          </Tooltip>

                                          <div
                                            style={{
                                              color: "#5E5E5E",
                                              fontSize: 10,
                                            }}
                                          >
                                            {adItem.description}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            fontFamily: "Roboto",
                                            padding: 9,
                                            color: "#000000",
                                            fontSize: 12,
                                            background: "#E4E6EA",
                                            borderRadius: "2px",
                                            fontWeight: "700",
                                            height: 32,
                                          }}
                                        >
                                          Book Now
                                        </div>
                                      </div>
                                    </div>
                                  </CardContent>
                                </Card>
                                <div className="headline_column_actions">
                                  {/* <button className="actions_headline_strategy accept_icon"></button> */}
                                  <button
                                    className="actions_headline_strategy reject_icon"
                                    onClick={() =>
                                      removeFaceBookAds(
                                        "middleOfTheFunnel",
                                        index,
                                        adItem.adName
                                      )
                                    }
                                  ></button>
                                </div>
                              </div>{" "}
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <div className="strategy_row_layout">
        <div className="strategy_google_row_main">
          <div className="google_strategy_header">
            <div>
              <div className="google_strategy_row">
                <div className="google_strategy_title">
                  <p>Bottom of the Funnel</p>
                </div>
                <div className="google_title_budget">
                  <p>Daily Budget : ${bof.budget || 0}</p>
                </div>
              </div>
              {/* <p className="google_title_info">
                Bidding on your branded terms to prevent competitors from taking
                your spot and capture searches generated from brand awareness
                campaigns.
              </p> */}
            </div>
          </div>
          {(bof.adsets || []).map((adset, index) => {
            return (
              <Accordion className="accordion_strategy_body" key={index}>
                <AccordionSummary className="accordion_strategy_head">
                  <div className="strategy_accordion_title">
                    <h4>
                      {adset.adsetName || `Adset ${index + 1}`}
                      {/* <p
                        style={{
                          display: "inline",
                          fontSize: "10px",
                          color: "#959595",
                        }}
                      >
                        Ad Set 1
                      </p> */}
                    </h4>
                    <span></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="accordion_strategy_content">
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: " 15.7359px",
                      color: " #333333",
                      display: "inline",
                      marginBottom: 10,
                    }}
                  >
                    Audience : {adset?.audeince?.name}
                  </p>
                  <div
                    className="strategy_accordion_layout"
                    style={{ marginTop: 20 }}
                  >
                    <div className="strategy_row_item">
                      <Carousel cols={3} rows={1} gap={0} loop>
                        {(adset.facebookAds || []).map((adItem, idx) => {
                          return (
                            <Carousel.Item key={idx - idx}>
                              <div
                                className="strategy_column_item"
                                style={{ margin: "0px 10px" }}
                              >
                                <Card sx={{ maxWidth: 345 }}>
                                  <CardHeader
                                    style={{
                                      background: " #EEF4FF",
                                      border: " 0.868595px solid #F0F0F0",
                                      borderRadius:
                                        "6.94876px 6.94876px 0px 0px;",
                                      textAlign: "center",
                                      height: "40px",
                                    }}
                                    subheader={adItem.adname}
                                  />
                                  <CardContent style={{ padding: 0 }}>
                                    <div>
                                      <Tooltip title={adItem.primaryText} arrow>
                                        <div
                                          style={{
                                            fontSize: 10,
                                            color: "#000000",
                                            fontWidth: "500",
                                            padding: 8,
                                          }}
                                        >
                                          {truncateString2(adItem.primaryText)}
                                        </div>
                                      </Tooltip>

                                      <div
                                        style={{
                                          border: "1px solid #F0F0F0",
                                          height: 1,

                                          display: "block",
                                        }}
                                      ></div>
                                      <div>
                                        <div
                                          style={{
                                            width: 311,
                                            height: 302,
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env
                                                .REACT_APP_BACKEND_BASE_URL +
                                              "/files/" +
                                              adItem.imageUrl
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          background: "#EEF4FF",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          padding: 8,
                                          paddingTop: 16,
                                        }}
                                      >
                                        <div style={{ display: "block" }}>
                                          <div
                                            style={{
                                              color: "#5E5E5E",
                                              fontSize: 10,
                                            }}
                                          >
                                            something.com
                                          </div>
                                          <Tooltip
                                            title={adItem.headline}
                                            arrow
                                          >
                                            <div style={{ fontSize: 12 }}>
                                              <b>
                                                {truncateString(
                                                  adItem.headline
                                                )}
                                              </b>
                                            </div>
                                          </Tooltip>

                                          <div
                                            style={{
                                              color: "#5E5E5E",
                                              fontSize: 10,
                                            }}
                                          >
                                            {adItem.description}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            fontFamily: "Roboto",
                                            padding: 9,
                                            color: "#000000",
                                            fontSize: 12,
                                            background: "#E4E6EA",
                                            borderRadius: "2px",
                                            fontWeight: "700",
                                            height: 32,
                                          }}
                                        >
                                          Book Now
                                        </div>
                                      </div>
                                    </div>
                                  </CardContent>
                                </Card>
                                <div className="headline_column_actions">
                                  {/* <button className="actions_headline_strategy accept_icon"></button> */}
                                  <button
                                    className="actions_headline_strategy reject_icon"
                                    onClick={() =>
                                      removeFaceBookAds(
                                        "bottomOfTheFunnel",
                                        index,
                                        adItem.adName
                                      )
                                    }
                                  ></button>
                                </div>
                              </div>{" "}
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="back_button">
          {" "}
          <Button
            style={{ color: "#0869FB" }}
            onClick={() => {
              if (agencyType === "LD") {
                if (isPlatformSelected(form, "google-ads") !== -1) {
                  dispatch(
                    goToSpecificCompoClient(
                      LDcomponentWithIndexClient[
                        clientDashboardIdsLD.STRATEGY_DECK_GOOGLE
                      ]
                    )
                  );
                } else {
                  dispatch(
                    goToSpecificCompoClient(
                      LDcomponentWithIndexClient[
                        clientDashboardIdsLD.STRATEGY_DECK_INTRO
                      ]
                    )
                  );
                }
              } else {
                if (isPlatformSelectedHDM(form, "google-ads") !== -1) {
                  dispatch(
                    goToSpecificCompoHDMClient(
                      componentWithIndexHDMClient[
                        clientDashboardIds.HDM_STRATEGY_DECK_GOOGLE_PAGE
                      ]
                    )
                  );
                } else {
                  dispatch(
                    goToSpecificCompoHDMClient(
                      componentWithIndexHDMClient[
                        clientDashboardIds.HDM_ANAYLSIS_DECK_PAGE
                      ]
                    )
                  );
                }
              }
            }}
          >
            BACK{" "}
          </Button>
        </div>
        <div className="footer_content">
          <Button
            variant="contained"
            onClick={() => {
              if (agencyType === "LD") {
                const callback = () => {
                  setModalApprove(!modalApprove);
                }
                dispatch(approveStrategyDeckWithDetails({
                  callback
                }));
              } else {
                dispatch(updateHDMClientDetails());
                dispatch(
                  goToSpecificCompoHDMClient(
                    componentWithIndexHDMClient[
                      clientDashboardIds.HDM_STRATEGY_DECK_OURPLAN_PAGE
                    ]
                  )
                );
              }
            }}
            disabled={
              agencyType === "LD" && strategyIterations.length !== 0 &&
              ["APPROVED", "CHANGES_SUGGESTED"].includes(
                strategyIterations[strategyIterations.length - 1].state
              )
                ? true
                : false
            }
          >
            {agencyType === "LD" ? "APPROVE" : "PROCEED"}
          </Button>
        </div>
      </div>
      <StrategyDeckApproval
        formSubm={modalApprove}
        setFormSubm={setModalApprove}
        title="Strategy Deck Approved"
        description="We will notify you once the campaigns are ready for your preview"
        buttonTitle="PROCEED"
        type="linear"
      />
    </div>
  );
};
export default StrategyDeckFacebookHDM;
