const INITIAL_STATE={
    facebookAccounts:[],
    auditDetails:[],
    isUserFacebookAccountValid:"DEFAULT"
}

const reducer = (state=INITIAL_STATE,action) => {
    switch(action.type){
        case "GET_FACEBOOK_ACCOUNTS":
            return {...state,facebookAccounts:action.payload}
        case "GET_AUDIT_DETAILS_HDM":
            return {...state,auditDetails:action.payload}
        case "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS":
            return {...state,isUserFacebookAccountValid:action.payload}
        default:
            return state;
    }
}
export default reducer;