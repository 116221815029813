import { HDMHeaderTabs, listOfIdsHDM } from '../../utils/navbarUtil';
import {
  clientObjKeys,
  GET_ABOUT_YOUR_BUSSINESS,
  GET_BRAND_OR_MESSAGING,
  GET_CLIENT_ACCESS,
  GET_CREATIVE_AND_MONTAGE_AD,
  GET_TARGET_AUDIENCE,
  GET_ACCESS_ACCOUNTID,
  UPDATE_STRATEGY_DECK,
  UPDATE_HDM_ARRAY_PLATFORMS_CHECKBOX,
  UPDATE_CITY_SUGGESTIONS_HDM,
  GOOGLE_TARGETING,
} from './enum';

const INITIAL_STATE = {
  onboardingInfo: clientObjKeys.onboardingInfo,
  accessSharing: clientObjKeys.accessSharing,
  auditInfo: clientObjKeys.auditInfo,
  accountAnalysis: clientObjKeys.accountAnalysis,
  fbAdsStrategyInfo: clientObjKeys.fbAdsStrategyInfo,
  strategyIterations: clientObjKeys.strategyIterations,
  brandId: '',
  proposalStatus: 'PENDING',
  generatedCampaignsInfo: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { infoType, key, key2, key3, value, isOther, minKey, maxKey } =
    action.payload
      ? action.payload
      : { infoType: null, key: null, value: null, isOther: null };
  switch (action.type) {
    case GET_CLIENT_ACCESS:
      const updatePersonalInfo = JSON.parse(JSON.stringify({ ...state }));
      updatePersonalInfo['onboardingInfo'][infoType][key] = value;
      return updatePersonalInfo;

    case GET_BRAND_OR_MESSAGING:
      const updatePersonalInfo1 = JSON.parse(JSON.stringify({ ...state }));
      updatePersonalInfo1['onboardingInfo'][infoType][key] = value;
      return updatePersonalInfo1;

    case GET_TARGET_AUDIENCE:
      const updatePersonalInfo2 = JSON.parse(JSON.stringify({ ...state }));
      updatePersonalInfo2['onboardingInfo'][infoType][key] = value;
      return updatePersonalInfo2;

    case GOOGLE_TARGETING:
      const updatePersonalInfo6 = JSON.parse(JSON.stringify({ ...state }));
      updatePersonalInfo6['onboardingInfo'][infoType][key] = value;
      return updatePersonalInfo6;

    case GET_ABOUT_YOUR_BUSSINESS:
      const currentAboutBusinessState = JSON.parse(
        JSON.stringify({ ...state })
      );

      const keys2 = key.split(' ');
      if (
        Array.isArray(
          currentAboutBusinessState['onboardingInfo'][infoType][keys2[0]]
        ) &&
        keys2.length === 2
      ) {
        currentAboutBusinessState['onboardingInfo'][infoType][keys2[0]][
          parseInt(keys2[1])
        ] = value;
      } else if (
        Array.isArray(
          currentAboutBusinessState['onboardingInfo'][infoType][keys2[0]]
        ) &&
        keys2.length === 3
      ) {
        currentAboutBusinessState['onboardingInfo'][infoType][keys2[0]][
          parseInt(keys2[1])
        ][keys2[2]] = value;
      } else {
        currentAboutBusinessState['onboardingInfo'][infoType][key] = value;
      }
      return currentAboutBusinessState;

    case GET_CREATIVE_AND_MONTAGE_AD:
      const updatePersonalInfo4 = JSON.parse(JSON.stringify({ ...state }));
      updatePersonalInfo4['onboardingInfo'][infoType][key] = value;
      return updatePersonalInfo4;

    case GET_ACCESS_ACCOUNTID:
      const updatePersonalInfo5 = JSON.parse(JSON.stringify({ ...state }));
      updatePersonalInfo5[infoType][key][key2][key3] = value;
      updatePersonalInfo5[infoType][key][key2]['status'] = action.payload
        ?.isComplete
        ? 'VERIFYING_COMPLETE_ACCESS'
        : 'VERIFYING';
      return updatePersonalInfo5;

    case UPDATE_HDM_ARRAY_PLATFORMS_CHECKBOX:
      let updatePlatformInfoPlatforms = JSON.parse(
        JSON.stringify(JSON.parse(JSON.stringify({ ...state })))
      );
      let platformsArray =
        updatePlatformInfoPlatforms['onboardingInfo']['clientAccess'][
          'platforms'
        ];
      const tempArr = [];
      const index = platformsArray.findIndex(
        (platform) => platform.platform === action.payload.platform
      );
      index === -1
        ? tempArr.push({
            platform: action.payload.platform,
            spends: 0,
            cpa: 0,
            currency: '',
          })
        : platformsArray[index]['platform'] === ''
        ? tempArr.push({
            platform: action.payload.platform,
            spends: 0,
            cpa: 0,
            currency: '',
          })
        : platformsArray.splice(index, 1);
      updatePlatformInfoPlatforms['onboardingInfo']['clientAccess'][
        'platforms'
      ] = [...platformsArray, ...tempArr];
      return updatePlatformInfoPlatforms;

    case UPDATE_CITY_SUGGESTIONS_HDM:
      const currState = JSON.parse(
        JSON.stringify(JSON.parse(JSON.stringify({ ...state })))
      );
      let locationOptions = action.payload.geoTargets.map(function (element) {
        return {
          name: element.name,
          title: element.canonicalName,
          resourceName: element.resourceName,
          reach: element.reach,
        };
      });
      if (locationOptions) {
        currState['onboardingInfo']['googleTargeting']['locationOptions'] =
          locationOptions;
      } else {
        currState['onboardingInfo']['googleTargeting']['locationOptions'] = [];
      }
      return currState;
    case 'SET_SPENDS_TARGET_CPA_HDM':
      const updateSpendsCpa = JSON.parse(
        JSON.stringify(JSON.parse(JSON.stringify({ ...state })))
      );
      const findIndexFromPlatform = updateSpendsCpa['onboardingInfo'][
        'clientAccess'
      ]['platforms'].findIndex(
        (platform) => platform.platform === action.payload.type
      );
      updateSpendsCpa['onboardingInfo']['clientAccess']['platforms'][
        findIndexFromPlatform
      ][action.payload.key] = parseInt(
        action.payload.value.length === 0 ? 0 : action.payload.value
      );
      return updateSpendsCpa;
    case 'UPDATE_INTEREST_OPTIONS':
      const tempState7 = JSON.parse(JSON.stringify({ ...state }));
      tempState7['onboardingInfo']['facebookTargeting']['interestsOptions'] =
        action.payload.interests;
      return tempState7;
    case 'UPDATE_LOCATION_OPTIONS':
      const tempState5 = JSON.parse(JSON.stringify({ ...state }));
      let options = action.payload.locations.map(function (element) {
        return {
          name:
            element.type === 'country'
              ? element.name
              : element.type === 'region'
              ? `${element.name}, ${element.country_name}`
              : element.type === 'city'
              ? `${element.name}, ${element.region}, ${
                  element.countryName || element.country_name
                }`
              : element.type === 'zip'
              ? `${element.name}, ${element.primary_city}, ${element.region} ${
                  element.countryName || element.country_name
                }`
              : element.type === 'geo_market'
              ? `${element.name}, ${
                  element.countryName || element.country_name
                }`
              : element.type === 'electoral_district'
              ? `${element.name}, ${element.region} ${
                  element.countryName || element.country_name
                }`
              : `${element.name}`,
          type: element.type,
          key: element.key,
          countryName: element.countryName || element.country_name,
          region: element.region,
          supportsRegion: element.supports_region,
          supportsCity: element.supports_city,
        };
      });
      if (options) {
        tempState5['onboardingInfo']['facebookTargeting']['locationOptions'] =
          options;
      } else {
        tempState5['onboardingInfo']['facebookTargeting']['locationOptions'] =
          [];
      }
      return tempState5;
    case 'UPDATE_MULTIPLE_CHECKBOX_HDM':
      const tempState6 = JSON.parse(JSON.stringify(state));
      let selected_values = action.payload;
      if (typeof value === 'string') {
        let updatedArray = tempState6['onboardingInfo'][infoType][key];
        const quest = action.payload.element;
        const mappedObj = {};
        updatedArray.forEach((key, index) => {
          mappedObj[key] = {
            flag: false,
            ['index']: index,
          };
        });
        let otherValIndex = null;
        Object.entries(mappedObj).map(([key, value], index) => {
          if (!quest.includes(key)) {
            otherValIndex = value.index;
          }
        });
        if (isOther && otherValIndex !== null) {
          updatedArray[otherValIndex] = value;
        } else if (updatedArray.includes('Other') && value === 'Other') {
          const otherIndex = updatedArray.findIndex((ele) => ele === 'Other');
          updatedArray.splice(otherIndex, 1);
          otherValIndex !== null && updatedArray.splice(otherValIndex - 1, 1);
        } else {
          const index = updatedArray.findIndex((ele) => ele === value);
          index === -1
            ? updatedArray.push(value)
            : updatedArray.splice(index, 1);
          value === 'Other' && updatedArray.push(' ');
        }
        tempState6['onboardingInfo'][infoType][key] = updatedArray;
      } else {
        tempState6['onboardingInfo'][infoType][key] = selected_values.element;
      }
      return tempState6;
    case 'GET_VALUE_FROM_AGE_RANGE_MIN_MAX_HDM':
      const tempStateAgeRange = JSON.parse(JSON.stringify({ ...state }));
      const minAge = action.payload.minAge ? action.payload.minAge : '';
      const maxAge = action.payload.maxAge ? action.payload.maxAge : '';
      tempStateAgeRange[action.payload.outerKey][infoType][minKey] = minAge;
      tempStateAgeRange[action.payload.outerKey][infoType][maxKey] = maxAge;
      return tempStateAgeRange;
    case 'ADD_NEW_FIELD_IN_TOP_PRODUCTS':
      const updatedTopProductsArray = JSON.parse(JSON.stringify({ ...state }));
      updatedTopProductsArray['onboardingInfo']['aboutBusiness'][
        'topProducts'
      ] = [
        ...updatedTopProductsArray['onboardingInfo']['aboutBusiness'][
          'topProducts'
        ],
        '',
      ];
      return updatedTopProductsArray;
    case 'DELETE_NEW_FIELD_IN_TOP_PRODUCTS':
      const updatedTopProductsArray1 = JSON.parse(JSON.stringify({ ...state }));
      const filterdArray = updatedTopProductsArray1['onboardingInfo'][
        'aboutBusiness'
      ]['topProducts'].filter((_, i) => action.payload !== i);
      updatedTopProductsArray1['onboardingInfo']['aboutBusiness'][
        'topProducts'
      ] = filterdArray;
      return updatedTopProductsArray1;
    case 'ADD_NEW_FIELD_IN_BRAND_INTEREST':
      const updatedTopProductsArray2 = JSON.parse(JSON.stringify({ ...state }));
      updatedTopProductsArray2['onboardingInfo']['aboutBusiness'][
        'brandsInterests'
      ] = [
        ...updatedTopProductsArray2['onboardingInfo']['aboutBusiness'][
          'brandsInterests'
        ],
        '',
      ];
      return updatedTopProductsArray2;
    case 'DELETE_NEW_FIELD_IN_BRAND_INTEREST':
      const updatedTopProductsArray3 = JSON.parse(JSON.stringify({ ...state }));
      const filterdArray2 = updatedTopProductsArray3['onboardingInfo'][
        'aboutBusiness'
      ]['brandsInterests'].filter((_, i) => action.payload !== i);
      updatedTopProductsArray3['onboardingInfo']['aboutBusiness'][
        'brandsInterests'
      ] = filterdArray2;
      return updatedTopProductsArray3;
    case 'ADD_NEW_FIELD_IN_KEY_COMPETITORS':
      const updatedPlatformInfoCompetitorArray = JSON.parse(
        JSON.stringify({ ...state })
      );
      updatedPlatformInfoCompetitorArray['onboardingInfo']['aboutBusiness'][
        'keyCompetitors'
      ] = [
        ...updatedPlatformInfoCompetitorArray['onboardingInfo'][
          'aboutBusiness'
        ]['keyCompetitors'],
        { name: '', website: '' },
      ];
      return updatedPlatformInfoCompetitorArray;
    case 'DELETE_KEY_COMPETITTOR_FIELD':
      const updatedPlatformInfoCompetitorArray1 = JSON.parse(
        JSON.stringify({ ...state })
      );
      const filterdArray1 = updatedPlatformInfoCompetitorArray1[
        'onboardingInfo'
      ]['aboutBusiness']['keyCompetitors'].filter(
        (_, i) => action.payload !== i
      );
      updatedPlatformInfoCompetitorArray1['onboardingInfo']['aboutBusiness'][
        'keyCompetitors'
      ] = filterdArray1;
      return updatedPlatformInfoCompetitorArray1;
    case 'UPDATE_WHOLE_HDM_CLIENT_FORMID':
      return { ...state, ...action.payload };
    case UPDATE_STRATEGY_DECK:
      const updatedState = {
        ...state,
        fbAdsStrategyInfo: {
          ...state.fbAdsStrategyInfo,
          ...(action.payload || {}),
        },
      };
      return updatedState;
    case 'UPDATE_WHOLE_CLIENT_FORM_HDM':
      return { ...state, ...action.payload };
    case 'LOCATION_ADDED_TO_FORM_HDM':
      const currentState = JSON.parse(
        JSON.stringify(JSON.parse(JSON.stringify({ ...state })))
      );
      let selected_locations = action.payload;
      currentState['onboardingInfo']['googleTargeting']['geoTargets'] =
        selected_locations;
      return currentState;

    case 'ADD_NEW_FIELD_SERVICE_INFORM_ABOUT_BUSINESS_FORM':
      const updatedPlatformInfoCompetitorArray2 = JSON.parse(
        JSON.stringify(JSON.parse(JSON.stringify({ ...state })))
      );
      updatedPlatformInfoCompetitorArray2['onboardingInfo']['aboutBusiness'][
        'productandservice'
      ] = [
        ...updatedPlatformInfoCompetitorArray2['onboardingInfo'][
          'aboutBusiness'
        ]['productandservice'],
        { name: '', description: '' },
      ];
      return updatedPlatformInfoCompetitorArray2;

    case 'DELETE_SERVICE_INFORM_ABOUT_BUSINESS_FORM_FIELD':
      const updatedAboutBusinessInfo = JSON.parse(
        JSON.stringify(JSON.parse(JSON.stringify({ ...state })))
      );
      const filteredArray1 = updatedAboutBusinessInfo['onboardingInfo'][
        'aboutBusiness'
      ]['productandservice'].filter((_, i) => action.payload !== i);
      updatedAboutBusinessInfo['onboardingInfo']['aboutBusiness'][
        'productandservice'
      ] = filteredArray1;
      return updatedAboutBusinessInfo;

    case 'RESET':
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
