import { useEffect, useState } from "react";
import NavigationSteps from "./NavigationSteps/index";
import HeaderNavSteps from "./HeaderNavSteps";
import { Grid } from "@mui/material";
import { LeftSidebar } from "../../utils/navbarUtil";
import { headerNavbar } from "../../utils/navbarUtil";
import RenderCurrentComponent from "./RenderCurrentComponent";
import { mappedLdClientCompoNameWithIndex } from "../../utils/clientDashboardIdsUtil";
import { useDispatch, useSelector } from "react-redux";
import { getClientDetailsByFormId } from "../../api/client/action";
import { getLocalStorage } from "../../api/localStorage/localStorage";
import "./clientDashboard.css";

const ClientDashboard = () => {
  const clientCurrentCompoToRender = useSelector(
    (state) => state.clientCurrentComp.currentComp
  );

  const visitedLastTime = useSelector(
    (state) => state.clientCurrentComp.visitedLastTime
  );
  const currentLeftSidebar = useSelector(
    (state) => state.clientCurrentComp.currentLeftSidebar
  );
  const currentHeaderTab = useSelector(
    (state) => state.clientCurrentComp.currentHeaderTab
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getLocalStorage("formId") && dispatch(getClientDetailsByFormId());
    const clientDefaultQuest = window.location.href.split("/client/");
    clientDefaultQuest.length === 2 &&
      clientDefaultQuest[1] === "renderQuestion" &&
      dispatch({ type: "EDIT_CLIENT_RESPO_CLIENT" });
  }, []);

  console.log("MC", mappedLdClientCompoNameWithIndex);
  console.log("headNav", headerNavbar[currentLeftSidebar]);

  return (
    <main
      class={`${mappedLdClientCompoNameWithIndex.get(
        clientCurrentCompoToRender
      )} main_layout`}
    >
      <section className="nav_section">
        <NavigationSteps
          navigateSteps={LeftSidebar}
          active={currentLeftSidebar}
        />
      </section>
      <section
        className={`${
          currentLeftSidebar &&
          Object.values(headerNavbar[currentLeftSidebar]).length
            ? "top_nav_item"
            : ""
        } right_layout`}
      >
        <div className={`right_layout_inner`}>
          <div className="right_content_part">
            {currentLeftSidebar &&
              currentHeaderTab &&
              Object.values(headerNavbar[currentLeftSidebar]).length !== 0 && (
                <div className="top_nav_section">
                  <div className="top_nav_inner">
                    <div
                      style={{
                        width: "100%",
                        height: "64px",
                        display: "flex",
                      }}
                    >
                      <HeaderNavSteps
                        headerNavList={headerNavbar[currentLeftSidebar]}
                        active={currentHeaderTab}
                        visitedLastTime={visitedLastTime}
                        clientCurrentCompoToRender={clientCurrentCompoToRender}
                      />
                    </div>
                  </div>
                </div>
              )}
            <div
              className={`${currentLeftSidebar} right_main_section`}
              style={
                clientCurrentCompoToRender === 0
                  ? {
                      height: "calc(100vh - 171px)",
                    }
                  : {}
              }
            >
              <RenderCurrentComponent
                currentCompoent={mappedLdClientCompoNameWithIndex.get(
                  clientCurrentCompoToRender
                )}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default ClientDashboard;
