import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@mui/material';
import BpRadio from '../../CustomRadio/Radio';
import { useDispatch } from 'react-redux';

const LeadPreferences = ({
  value,
  objKey,
  infoType,
  outerKey,
  error,
  errorText,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <FormControl>
        <RadioGroup
          defaultValue=''
          aria-labelledby='demo-customized-radios'
          name='customized-radios'
          style={{
            marginLeft: '0.8rem',
            flexDirection: 'row',
            gap: '1rem',
            flexWrap: 'wrap',
            width: 550,
          }}
          value={value}
          onChange={(e) => {
            dispatch({
              type: 'GET_RADIO_BUTTON_VALUE',
              payload: {
                infoType: infoType,
                value: e.target.value,
                key: objKey,
                outerKey: outerKey,
              },
            });
          }}
        >
          <FormControlLabel
            value='Calls'
            control={<BpRadio />}
            label='Calls'
            style={{
              width: 180,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '1rem',
              // marginBottom: '1rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='Emails'
            control={<BpRadio />}
            label='Emails'
            style={{
              width: 180,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '1rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='Walk-Ins'
            control={<BpRadio />}
            label='Walk-Ins'
            style={{
              width: 180,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '1rem',
              marginBottom: '1rem',
              color: '#7F7D87',
            }}
          />
          <FormControlLabel
            value='Purchases'
            control={<BpRadio />}
            label='Purchases'
            style={{
              width: 180,
              height: 42,
              background: '#FFFFFF',
              border: '1px solid #DBDBDB',
              boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
              borderRadius: 10,
              paddingLeft: '1rem',
              color: '#7F7D87',
            }}
          />
        </RadioGroup>
        {error && <p style={{ color: 'red' }}>{errorText}</p>}
      </FormControl>
    </>
  );
};

export default LeadPreferences;
