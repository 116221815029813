import { UPDATE_CURRENT_COMPONENT_AM } from "../../../api/accountManager/componentTracking/enum";
import {
  accountManagerIds,
  ldComponentWithIndexAm,
} from "../../../utils/accountManagerUtil";
import {
  listOfIds,
  headerTabsAccountManager,
  listOfIdsHDM,
  HDMHeaderTabs,
} from "../../../utils/navbarUtil";

const INITIAL_STATE = {
  currentCompAm: 0,
  currentLeftSidebarAm: listOfIds.accountOnboarding,
  currentHeaderTabAm: null,
  lastActiveCompAm: 0,
  clientView: false,
};

const updateNav = (currentComp) => {
  if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.CLIENT_DETAILS_AM]
  ) {
    return listOfIds.accountOnboarding;
  } else if (
    currentComp >=
      ldComponentWithIndexAm[accountManagerIds.SCOPE_GOOGLE_ADS_SETUP] &&
    currentComp <=
      ldComponentWithIndexAm[accountManagerIds.SCOPE_PRICING_ADS_SETUP]
  ) {
    return listOfIds.accountScopeOfWorkAndPricing;
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.SHARED_ASSETS]
  ) {
    return listOfIds.sharedAssets;
  } else if (
//     currentComp >=
//       ldComponentWithIndexAm[
//         accountManagerIds.PROPOSAL_INPUTS_ONBOARDING_QUEST1
//       ] &&
//     currentComp <=
//       ldComponentWithIndexAm[
//         accountManagerIds.PROPOSAL_INPUTS_REQUIREMENT_QUEST1
//       ]
//   ) {
//     return listOfIds.proposalInputs;
//   } else if (
    currentComp >= ldComponentWithIndexAm[accountManagerIds.AUDIT_GOOGLE_ADS] &&
    currentComp <= ldComponentWithIndexAm[accountManagerIds.AUDIT_REVIEW_POINTS]
  ) {
    return listOfIds.audit;
  } else if (
    currentComp >=
      ldComponentWithIndexAm[accountManagerIds.HDM_FACEBOOK_ACCOUNTS] &&
    currentComp <= ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_AD]
  ) {
    return listOfIdsHDM.AuditHdm;
  }
  //proposal
  else if (
    currentComp >= ldComponentWithIndexAm[accountManagerIds.PROPOSAL_PUBLISH] &&
    currentComp <= ldComponentWithIndexAm[accountManagerIds.SUGGEST_CHANGES]
  ) {
    return listOfIds.proposal;
  }
  //review intake form
  else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.REVIEW_INTAKE_FORM]
  ) {
    return listOfIds.reviewIntakeForm;
  }
  //comple access
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.SHARED_ASSETS_COMPLETE]
  ) {
    return listOfIds.sharedAssetsComplete;
  }
  //strategy creation google
  else if (
    currentComp >=
      ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY] &&
    currentComp <=
    ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_3]
  ) {
    return listOfIds.strategyInput;
  }
  //stratgey creation facebook
  else if (
    currentComp >=
      ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_LOOKALIKE] &&
    currentComp <=
      ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_BOF]
  ) {
    return listOfIdsHDM.StrategyCreationFacebook;
  }
  //deck
  else if (
    currentComp >=
      ldComponentWithIndexAm[accountManagerIds.STRATEGY_DECK_INTRODUCTION] &&
    currentComp <=
      ldComponentWithIndexAm[accountManagerIds.STRATEGY_SUGGEST_CHANGES]
  ) {
    return listOfIds.strategy;
  }
  //google campaigin creation
  else if (
    currentComp >= ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE] &&
    currentComp <=
    ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_PAGE_PMAX]
  ) {
    return listOfIds.campaignForGoogle;
  }
  //facebook campaign creation
  else if (
    currentComp >=
      ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_FB] &&
    currentComp <= ldComponentWithIndexAm[accountManagerIds.ADSET_LEVEL_BOF]
  ) {
    return listOfIdsHDM.CampaignCreationFB;
  }
  //google campaign preview
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_PREVIEW_PAGE]
  ) {
    return listOfIds.CAMPAIGN_PREVIEW;
  }
  //facebook campaigin preview
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_PREVIEW_FB]
  ) {
    return listOfIdsHDM.CampaignPreviewFB;
  } else if (
    currentComp >= ldComponentWithIndexAm[accountManagerIds.AD_COPIES] &&
    currentComp <= ldComponentWithIndexAm[accountManagerIds.AB_TEST]
  ) {
    return listOfIds.creativeApproval;
  } else {
    return null;
  }
};
const updateHeader = (currentComp) => {
  if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.CLIENT_DETAILS_AM]
  ) {
    return null;
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.SCOPE_GOOGLE_ADS_SETUP]
  ) {
    return headerTabsAccountManager["GOOGLE ADS SETUP"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.SCOPE_FACEBOOK_ADS_SETUP]
  ) {
    return headerTabsAccountManager["FACEBOOK ADS SETUP"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.SCOPE_CRO_ADS_SETUP]
  ) {
    return headerTabsAccountManager["CRO SETUP"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.SCOPE_PRICING_ADS_SETUP]
  ) {
    return headerTabsAccountManager["PRICING"];
  } 
//   else if (
//     currentComp >=
//       ldComponentWithIndexAm[
//         accountManagerIds.PROPOSAL_INPUTS_ONBOARDING_QUEST1
//       ] &&
//     currentComp <=
//       ldComponentWithIndexAm[
//         accountManagerIds.PROPOSAL_INPUTS_ONBOARDING_QUEST7
//       ]
//   ) {
//     return headerTabsAccountManager["ONBOARDING FORM"];
//   } else if (
//     currentComp >=
//       ldComponentWithIndexAm[
//         accountManagerIds.PROPOSAL_INPUTS_PROPOSER_DETAILS_QUEST1
//       ] &&
//     currentComp <=
//       ldComponentWithIndexAm[
//         accountManagerIds.PROPOSAL_INPUTS_PROPOSER_DETAILS_QUEST2
//       ]
//   ) {
//     return headerTabsAccountManager["PROPOSER DETAILS"];
//   } else if (
//     currentComp ===
//     ldComponentWithIndexAm[accountManagerIds.PROPOSAL_INPUTS_REQUIREMENT_QUEST1]
//   ) {
//     return headerTabsAccountManager["REQUIREMENT DETAILS"];
//   }
  //audit for google
  else if (
    currentComp >= ldComponentWithIndexAm[accountManagerIds.AUDIT_GOOGLE_ADS] &&
    currentComp <= ldComponentWithIndexAm[accountManagerIds.AUDIT_REVIEW_POINTS]
  ) {
    return headerTabsAccountManager["GOOGLE ADS AUDIT"];
  }
  //audit for facebook
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.HDM_FACEBOOK_ACCOUNTS]
  ) {
    return null;
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_ACCOUNT]
  ) {
    return HDMHeaderTabs["ACCOUNT"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_CAMPAIGN]
  ) {
    return HDMHeaderTabs["CAMPAIGN"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_ADSET]
  ) {
    return HDMHeaderTabs["ADSET"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_AD]
  ) {
    return HDMHeaderTabs["AD"];
  }
  //proposal ld
  else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.PROPOSAL_PUBLISH]
  ) {
    return headerTabsAccountManager["PROPOSAL"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.SUGGEST_CHANGES]
  ) {
    return headerTabsAccountManager["CHANGES SUGGESTED"];
  }
  //stratgey creation Google
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.BRAND_STRATEGY_INPUT]
  ) {
    return headerTabsAccountManager["BRAND CAMPAIGN"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.COMPETITOR_STRATEGY_INPUT]
  ) {
    return headerTabsAccountManager["COMPETITOR CAMPAIGN"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.SERVICE_STRATEGY_INPUT]
  ) {
    return headerTabsAccountManager["SERVICES CAMPAIGN"];
  }
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_1]
  ) {
      return headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_1]; 
  }
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_2]
  ) {
      return headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_2]; 
  }
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_3]
  ) {
      return headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_3]; 
  }
  //stratgey creation facebook
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_LOOKALIKE]
  ) {
    return HDMHeaderTabs["LOOKALIKE"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_INTEREST]
  ) {
    return HDMHeaderTabs["INTEREST"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_MOF]
  ) {
    return HDMHeaderTabs["MOF"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_BOF]
  ) {
    return HDMHeaderTabs["BOF"];
  }
  //deck
  else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.STRATEGY_DECK_INTRODUCTION]
  ) {
    return headerTabsAccountManager["INTRODUCTION"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.STRATEGY_DECK]
  ) {
    return headerTabsAccountManager["GOOGLE"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.STRATEGY_DECK_FACEBOOK]
  ) {
    return headerTabsAccountManager["FACEBOOK"];
  } else if (
    currentComp ===
    ldComponentWithIndexAm[accountManagerIds.STRATEGY_SUGGEST_CHANGES]
  ) {
    return headerTabsAccountManager["STRATEGY_SUGGEST_CHANGES"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.AD_COPIES]
  ) {
    return headerTabsAccountManager["AD COPIES"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.CREATIVES]
  ) {
    return headerTabsAccountManager["CREATIVES"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.LANDING_PAGES]
  ) {
    return headerTabsAccountManager["LANDING PAGES"];
  } else if (
    currentComp === ldComponentWithIndexAm[accountManagerIds.AB_TEST]
  ) {
    return headerTabsAccountManager["AB TEST"];
  } else {
    return null;
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_COMPONENT_AM:
      return {
        ...state,
        currentCompAm: state.currentCompAm + 1,
        currentHeaderTabAm: updateHeader(state.currentCompAm + 1),
        currentLeftSidebarAm: updateNav(state.currentCompAm + 1),
      };
    case "GO_BACK_AM_COMPO":
      const currentComp = state.currentCompAm - 1;
      let currentLeftNavbar1 = updateNav(currentComp);
      let currentHeaderTabTemp1 = updateHeader(currentComp);
      return {
        ...state,
        currentCompAm: currentComp,
        currentHeaderTabAm: currentHeaderTabTemp1,
        currentLeftSidebarAm: currentLeftNavbar1,
      };
    case "GO_TO_SPECIFIC_COMPO_AM":
      const currentComp1 = action.payload;
      console.log("jhsjhs");
      return {
        ...state,
        currentCompAm: currentComp1,
        currentHeaderTabAm: updateHeader(currentComp1),
        currentLeftSidebarAm: updateNav(currentComp1),
      };
    case "UPDATE_TAB_AM":
      const currentComp2 = action.payload;
      return {
        ...state,
        currentCompAm: currentComp2,
        currentHeaderTabAm: updateHeader(currentComp2),
        currentLeftSidebarAm: updateNav(currentComp2),
      };
    case "UPDATE_SIDE_BAR_AM":
      const currentComp3 = action.payload;
      return {
        ...state,
        currentCompAm: currentComp3,
        currentHeaderTabAm: updateHeader(currentComp3),
        currentLeftSidebarAm: updateNav(currentComp3),
        clientView: false,
      };
    case "REVIEW_QUESTIONIER_AM":
      return { ...state, ...action.payload };

    case "GO_BACK_REVIEW_QUESTIONIER_AM": {
      const currentComp4 = action.payload;
      return {
        ...state,
        currentCompAm: currentComp4.currentComp,
        currentHeaderTabAm: updateHeader(currentComp4.currentComp),
        currentHeaderTabAm: updateNav(currentComp4.currentComp),
        lastActiveComp: 0,
      };
    }
    case "OPEN_CLIENT_VIEW":
      return {
        ...state,
        clientView: true,
      };
    case "CLOSE_CLIENT_VIEW":
      return {
        ...state,
        clientView: false,
      };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
