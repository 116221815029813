export const DB_KEY_MAP_PERSONAL = {
  completed: undefined,
  company: 'Company Name',
  industry: 'Company Industry',
  companyEmail: 'Company Email Address',
  companyWebsite: 'Company Website',
  companyAddress: 'Company Office Address',
  companyMailingAddress: undefined,
  companyPhone: 'Company Phone Number',
  primaryContactName: 'Primary Contact Full Name',
  primaryContactRole: 'Primary Contact Role And Title',
  primaryContactNumber: 'Primary Contact Phone Number',
  primaryContactEmail: 'Primary Contact Email Address',
};

export const DB_KEY_MAP_PLATFORM = {
  completed: undefined,
  averageTicketPrice: 'Average ticket price',
  cltv: 'What is your Customer Lifetime Value?',
  crm: 'Which is your current CRM tool?',
  callTracking: undefined,
  leadSource: 'What are your sources of leads?',
  competitors: 'Your top competitors and their websites',
  platforms: 'Platform chosen',
  // adsManaged: 'Please select the platforms for which you require our services.',
  // brandDescription: 'Describe your brand',
};

export const DB_KEY_MAP_GROWTH = {
  completed: undefined,
  goals: 'Growth Goals',
  notes: undefined,
};

export const DB_KEY_MAP_COMPANY = {
  completed: undefined,
  name: 'Company Name',
  industry: 'Industry',
  clientId: 'Client Id',
  mobileNumber: 'Client Mobile Number',
  fullName: 'Primary Contact Full Name',
  targetCpa: 'Target CPA of the client',
  companyLogo: undefined,
  proposerName: 'Name of person preparing proposal',
  signature: undefined,
  googleAdsManaged: 'Google Ads Managed',
  fbAdsManaged: 'Fb Ads Managed',
  implementCro: 'CRO Managed',
};

export const DB_KEY_MAP_CLIENT_ACCESS = {
  businessName: 'Business Name',
  primaryContactPerson: 'Primary Contact Person Name',
  // facebookPartenerRequestSendContactPerson: "FB Request",
  fbPageUrl: 'Facebook Page URL',
  // facebookPartenerRequestSendPageURL: "FB Request",
  websiteUrl: 'Website URL',
  shopifyStoryAvailable: 'Shopify Store Available?',
};

export const DB_KEY_MAP_BRAND_MESSAGING = {
  esthetic: 'Esthetic',
  tagline: 'Tagline',
  emotionalResponse: 'Emotional Response',
  uniqueSellingPoint: 'Unique Selling Point',
  whatSetsYouApart:
    'Why Should Someone Buy from YOU vs. another brand selling a similar product?',
  benefitsOfProducts: 'What are the top 3 benefits of your products?',
  painPoints: 'What are the top 3 pain points your products solve?',
  storyToPromote: 'What story do you want to promote',
  brandMessagingExampels:
    'What are some brands with messaging that you really like?',
  brandFont: 'What Are Your Brand Fonts',
  brandColor: 'What Are Your Brand Colours',
  logoLink: 'Please Add A Link To Your Logo',
};

export const DB_KEY_MAP_FACEBOOK_TARGETING = {
  minAgeRange: 'What is the age range of your target customer base?',
  gender: 'What is the gender of your target customer base?',
  advertisingLocations: 'What locations do you advertise in?',
  activitiesInterest: 'What activities/interests do your best customers have?',
  excludes:
    'Are there specific locations, markets, or groups of people we should exclude? Enter them here',
  formalCustomerPersonas:
    'If you have formal customer personas please upload them here',
  otherCharacteristics:
    'Any other characteristic that describe your target audience not covered above? Enter them here',
};

export const DB_KEY_MAP_GOOGLE_TARGETING = {
  status: undefined,
  ageRanges: 'What is the age range of your customer base?*',
  geoTargets: "Enter the locations where you'd like to run your ad",
  gender: 'Which gender is your main customer base?*',
  maritalStatus: 'What is the marital status of your target customer?',
  activitiesInterest: 'What activities/interests do your best customers have?',
  excludeAudiences:
    'Are there specific locations, markets, or groups of people we should exclude?',
  customerIncomeRange:
    "What is your target customer's yearly household income?*",
  customerEducation:
    'What is the highest level of school your target customer has completed or the highest degree they have received?',
  customerEmployment:
    "Which of the following categories best describes your target customer's employment status?*",
  location: undefined,
  advertiseLocation: undefined,
  customerCollar: undefined,
  formalCustomerPersona: undefined,
};

export const DB_KEY_MAP_ABOUT_BUSSINESS = {
  productandservice: 'Please enter the products you want to work on',
  noPromotionProduct: 'Any Products You Do NOT Want to Promote',
  offers:
    'Any other characteristic that describe your target audience not covered above? Enter them here',
  averageOrderValue: 'Average Order Value (in $)',
  cogsPerAov: 'COGS per AOV (in $)',
  shippingCostPerAov: 'COGS per AOV (in $)',
  breakEvenPoint:
    'Your breakeven point that is, the MAXIMUM cost you can pay to acquire a customer (given your Average Order Value you mentioned above)? (in $)',
  fbCamaignBudget: 'Campaign Budget (Facebook, in $)',
  googleCamaignBudget: 'Campaign Budget (Google, in $)',
  tiktokCamaignBudget: 'Campaign Budget (TikTok, in $)',
  countryTargeted: 'Countries Targeted',
  brandDescription: 'Describe your brand',
  keyCompetitors: 'Key Competitors',
  brandsInterests:
    'Please mention a few interest keywords that you want us to target.',
  commonJargons: 'Any Common Industry Jargon We Should Be Aware Of',
  monthlyRevenue: 'What is your monthly revenue goal? (in $)',
  monthlyRevenueGoal3Months:
    'What is your monthly revenue goal for 3 months from now? (in $)',
  monthlyRevenueGoal6Months:
    'What is your monthly revenue goal for 6 months from now? (in $)',
  monthlyRevenueGoal1Year: 'What is your revenue goal for this year? (in $)',
  maximizeEnterpriseValueProfit:
    'Do you want to maximize enterprise value OR do you want to maximize profit',
  requirementsForUs:
    "What would we have to do in the next 3-6 months to make sure you're happy with this relationship",
  dobHomeAddress: 'What is your DOB + Home Address',
};

export const DB_KEY_MAP_CREATIVE_MONTAGE = {
  videoReviews: 'Video Reviews',
  googleDriveEmail: 'Do you have a Google Drive specific email',
  googleDriveLink: 'Your Google Drive Link is',
  interestedMontageAds: 'Interested In Montage Ads',
  pocForProductDeliveries:
    'Key Point of Contact for Product Delivery to Creators',
  products: 'List of Products:',
  creatorPersona: 'Creator Persona',
  shootNotes: 'Shoot Notes',
  desiredCta: 'Desired Call to Action',
  promoCode: 'Promo Code + Terms of Promo',
  uspOfProductsSent: 'Unique Selling Points of Products You Sent',
  visualReferences: 'Links to Visual References',
  others: 'Anything else to Add',
};
