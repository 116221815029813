import React, { useState } from 'react';
import closeIcon from '../PMaxStrategyCreation/pmaxIcons/cross-icon.svg';
import selectedCloseIcon from '../PMaxStrategyCreation/pmaxIcons/selected-cross-icon.svg';
import AssetGroupDeleteModal from './AssetGroupDeleteModal';
const AssetGroupTabListView = ({
  handleChangeAssetGroup,
  handleAddNewAsset,
  handleRemoveAssetGroup,
  currAssetCounts=0,
  currStatus,
  currCamp,
  currAssetGroup,
  forCampaign
}) => {

  const [deleteModal, setDeleteModal] = useState(null);
  const assetsTabs = currCamp?.map((asset, i) => {
      if (currAssetGroup === i)
          return (
              <div
                key={i}
                className='asset-group-tab'
                style={{
                  borderLeft: currStatus === "COMPLETED" ? '1.45px solid #1ABFA1' : '1.45px solid #0869FB',
                  borderRight: currStatus === "COMPLETED" ? '1.45px solid #1ABFA1' : '1.45px solid #0869FB',
                  borderTop: currStatus === "COMPLETED" ? '1.45px solid #1ABFA1' : '1.45px solid #0869FB',
                  borderBottom: 0,
                  color: '#0869FB',
                  background: currStatus === "COMPLETED" ? '#F5FFFD' : 'white'
                }}
                onClick={() => onHandleClick(i)}
              >
              Asset Group { asset?.assetNumber || currAssetCounts}
              { currCamp.length > 1 && <img src={selectedCloseIcon} alt='close' 
                  // onClick={(e) => handleRemove(e, i)}
                  onClick={(e) => {
                    // if(forCampaign){
                    //   // e.stopPropagation();
                    //   handleRemoveAssetGroup(i)
                    // } else {
                      setDeleteModal(<AssetGroupDeleteModal handleClose={setDeleteModal} handleRemove={() => handleRemove(e, i)} />)
                      e.stopPropagation();
                    // }
                  }}
                /> 
              }
              </div>
          );
      else 
          return (
              <div className='asset-group-tab' 
                key={i}
                style={{
                  background: asset?.status === "COMPLETED" ? '#F5FFFD' : 'white',
                }}
                onClick={() => onHandleClick(i)}
              >
              Asset Group { asset?.assetNumber || currAssetCounts}
              { currCamp.length > 1 && <img src={closeIcon} alt='close' 
                  // onClick={(e) => handleRemove(e, i)}
                  onClick={(e) => {
                  // if(forCampaign){
                  //   // e.stopPropagation();
                  //   handleRemoveAssetGroup(i);
                  // } else {
                    setDeleteModal(<AssetGroupDeleteModal handleClose={setDeleteModal} handleRemove={() => handleRemove(e, i)} />);
                    e.stopPropagation();
                  // }
                }}
                />
              }
              </div>
          );
      });

  const handleRemove = (event, index) => {
      event.stopPropagation();
      handleRemoveAssetGroup(index);
      setDeleteModal(null);
  }

  const onHandleClick = (index) => {
      // setActiveIndex(index);
     handleChangeAssetGroup(index);
  }

  const onHandleAdd = index => {
     handleAddNewAsset(index);
  }

  return (
    <div className='asset-group-tab-list'>
      {/* Map the ad list here till the max asset group reaches */}

      {assetsTabs}
      {/* This below add new set should be removed after 7 asset */}
      { currCamp?.length < 7 
        && <div className='asset-group-tab'
              onClick={() => onHandleAdd(currCamp.length)}
            >
             + Add New Asset
           </div>
      }
      { deleteModal }
    </div>
  );
};

export default AssetGroupTabListView;
