import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  Tab,
  Box,
  Button,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import crossIcon from '../../PMaxStrategyCreation/pmaxIcons/cross-icon.svg';
import infoIcon from '../../PMaxStrategyCreation/pmaxIcons/info-icon.svg';
import image from '../../PMaxStrategyCreation/pmaxIcons/image20.png';
import playIcon from '../../PMaxStrategyCreation/pmaxIcons/play-icon.svg';
import redCloseIcon from '../../PMaxStrategyCreation/pmaxIcons/red-cross-icon.svg';
import VideoLibraryModal from '../../PMaxStrategyCreation/VideoLibraryModal';
import ImageLibraryModal from '../../PMaxStrategyCreation/ImageLibraryModal';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import errorIcon from '../../PMaxStrategyCreation/pmaxIcons/error-icon.svg';
import { useDropzone } from 'react-dropzone';
import { imageValidator } from '../../../utils/pmaxStrategy';
import NoContentView from '../../PMaxStrategyCreation/NoContentView';
import { uploadFile } from '../../../api/fileHandling/saga';
import { api } from '../../../api';
import ImageUploadStatusModal from '../../PMaxStrategyCreation/ImageUploadStatusModal';
import { useDispatch } from 'react-redux';
import { updateStartLoader } from '../../../api/pmaxStrategy/slice';
const PMaxCampaignDetailedView = ({
  read,
  headlines,
  setHeadlines,
  descriptions,
  setDescriptions,
  longHeadlines,
  setLongHeadlines,
  logoUrls,
  setlogoUrls,
  imageUrls,
  setImageUrls,
  ytVideoUrls,
  setYtVideoUrls,
  errors = {},
  imageAssetLibrary = [],
  videoAssetLibrary = [],
  logoAssetLibrary = [],
}) => {
  const [value, setValue] = useState('Headline');
  const [addedNewTerm, setAddedNewTerm] = useState(false);
  const [focusValue, setFocusValue] = useState(false);
  const [failedValidations, setFailedValidations] = useState([]);
  const [passedValidations, setPassedValidations] = useState([]);
  const [videoLibrary, setVideoLibrary] = useState(false);
  const [imageLibrary, setImageLibrary] = useState(false);
  const [url, setUrl] = useState('');
  const [modal, setModal] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const { open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.gif'],
    },
  });
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    open();
  };

  const removeTextStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '11px',
    color: '#E84B47',
  };

  useEffect(() => {
      if (focusValue) {
          switch (value) {
              case 'Headline':
                  if (headlines[headlines.length - 1] || !headlines.length)
                      setHeadlines([...headlines, '']);
                  break;
              case 'Long Headlines':
                  if (longHeadlines[longHeadlines.length - 1] || !longHeadlines.length)
                      setLongHeadlines([...longHeadlines, '']);
                  break;
              case 'Description':
                  if (descriptions[descriptions.length - 1] || !descriptions.length)
                      setDescriptions([...descriptions, '']);
                  break;
              default:
                  console.log('Wrong Value');
          }

          setAddedNewTerm(true);
          setFocusValue(false);
      }
  }, [focusValue]);

  useEffect(() => {
      if (addedNewTerm) {
       inputRef.current.focus();
       inputRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  }, [addedNewTerm]);

  useEffect(() => {
    if (acceptedFiles.length !== 0)
      acceptedFiles.forEach((file) => {

        const image = new Image();
        image.onload = () => {
          const isImageValid = imageValidator({
            width: image.width,
            height: image.height,
            size: file.size,
            type: value,
          });
          // image.onabort
          const resolution = `${image.width}X${image.height}`;
          const size = `${Math.round(file.size / 1000)} KB`;
          const aspectRatio =
            Math.round((image.width / image.height) * 100) / 100;
          if (isImageValid.error === true) {
            setFailedValidations((prev) => ([
                { id: image.src, name: file.name, resolution, size, errorMessage: isImageValid.errorMessage, aspectRatio: mapAspectRatio(aspectRatio)},
              ...prev,
            ]));
          }
          else 
            setPassedValidations((prev) => ([
              { id: image.src, name: file.name, resolution, size, aspectRatio: mapAspectRatio(aspectRatio), file: file },
              ...prev,
            ]));
        };
        image.src = URL.createObjectURL(file);
      });
  }, [acceptedFiles]);

  const mapAspectRatio = (aspectRatio) => {
    if (aspectRatio === 4) return '4:1';
    if (aspectRatio === 0.8) return '4:5';
    if (aspectRatio === 1.91) return '1.91:1';
    if (aspectRatio === 1) return '1:1';
    return `${aspectRatio}:1`;
  };

  useEffect(() => {
    if (
      acceptedFiles.length &&
      (passedValidations.length + failedValidations.length) === acceptedFiles.length
    ) {
        setModal(true);
    }
  }, [passedValidations, failedValidations]);

  const urlError = false;

  const handleClose = () => {
    setModal(false);
    passedValidations.forEach(file => URL.revokeObjectURL(file.src));
    failedValidations.forEach(file => URL.revokeObjectURL(file.src));
    setPassedValidations([]);
    setFailedValidations([]);
  };

  const change = (e, index) => {
    switch (value) {
      case 'Headline':
        const newHeadlineList = headlines.slice();
        newHeadlineList[index] = e.target.value;
        setHeadlines(newHeadlineList);
        break;
      case 'Long Headlines':
        const newLongHeadlineList = longHeadlines.slice();
        newLongHeadlineList[index] = e.target.value;
        setLongHeadlines(newLongHeadlineList);
        break;
      case 'Description':
        const newDescriptionList = descriptions.slice();
        newDescriptionList[index] = e.target.value;
        setDescriptions(newDescriptionList);
        break;
      case 'Image':
        const newimageUrls = imageUrls.slice();
        newimageUrls[index] = e.target.value;
        setImageUrls(newimageUrls);
        break;
      case 'Logo':
        const newlogoUrls = logoUrls.slice();
        newlogoUrls[index] = e.target.value;
        setlogoUrls(newlogoUrls);
        break;
      default:
        console.log('Wrong value');
    }
    // changeStatus();
    // setSavedStatus(false);
  };

  const remove = (index) => {
    switch (value) {
      case 'Headline':
        const newHeadlineList = headlines.slice();
        newHeadlineList.splice(index, 1);
        setHeadlines(newHeadlineList);
        break;
      case 'Long Headlines':
        const newLongHeadlineList = longHeadlines.slice();
        newLongHeadlineList.splice(index, 1);
        setLongHeadlines(newLongHeadlineList);
        break;
      case 'Description':
        const newDescriptionList = descriptions.slice();
        newDescriptionList.splice(index, 1);
        setDescriptions(newDescriptionList);
        break;
      case 'Image':
        const newimageUrls = imageUrls.slice();
        newimageUrls.splice(index, 1);
        setImageUrls(newimageUrls);
        break;
      case 'Logo':
        const newlogoUrls = logoUrls.slice();
        newlogoUrls.splice(index, 1);
        setlogoUrls(newlogoUrls);
        break;
      case 'Videos':
        const newVideoUrls = ytVideoUrls.slice();
        newVideoUrls.splice(index, 1);
        setYtVideoUrls(newVideoUrls);
        break;
      default:
        console.log('Wrong value');
    }
    setAddedNewTerm(false);
    setFocusValue(false);
    // changeStatus();
    // setSavedStatus(false);
  };

  const handleAdd = (files) => {
    dispatch(updateStartLoader(true));

    const responseFiles = files.map((item) => {
      return api.post(
        `/files/image_url:upload?image_url=${encodeURIComponent(
          item.imageFullSizeUrl
        )}`
      );
    });

    setImageLibrary(false);

    Promise.allSettled(responseFiles)
      .then((res) => {
        console.log('RES:', res);
        const newFiles = [];
          files.forEach((file, index) => {
             const { status, value } = res[index];
             if (status === 'rejected') return;
             newFiles.push({
               id: value.data.fileId,
               name: file.name,
               resolution: `${Math.round(
                 file.imageFullSizeWidthPixels
               )}X${Math.round(file.imageFullSizeHeightPixels)}`,
             });
        });

        return newFiles;
      })
      .then((res) => {
        if (value === 'Image') setImageUrls([...res, ...imageUrls]);
        else if (value === 'Logo') setlogoUrls([...res, ...logoUrls]);
      })
      .catch((err) => console.log('Err:', err))
      .finally(() => {
        dispatch(updateStartLoader(false));
        // changeStatus();
        // setSavedStatus(false);
      });
  };

  const handleVideoAdd = (videos) => {
    setYtVideoUrls([...videos, ...ytVideoUrls]);
    setVideoLibrary(false);
    //changeStatus();
    // setSavedStatus(false);
  };

  const addVideo = (addUrl) => {
    const url = new URL(addUrl);
    const searchParams = new URLSearchParams(url.search);
    const id = searchParams.get('v');

    const response = api.get(`/youtube/metadata?video_ids=${id}`);
    response
      .then((res) => {
        const metadata = res.data.items[0];
        setYtVideoUrls([{ ...metadata }, ...ytVideoUrls]);
        setUrl('');
      })
      .catch((err) => console.log('Error:', err));
  };

  const handleProceed = () => {
    if (!passedValidations.length) {
        failedValidations.forEach(file => URL.revokeObjectURL(file.src));
        return
    };

    dispatch(updateStartLoader(true));
    const files = passedValidations.map((file) => {
      let formData = new FormData();
      formData.append('file', file.file);
      return uploadFile(formData);
    });

    Promise.allSettled(files)
      .then((res) => {
        console.log(res);
        const newFiles = [];
        passedValidations.forEach((file, index) => {
          const { status, value } = res[index]
          if (status === 'rejected') return;
          newFiles.push({
             id: value.fileId,
             name: file.name,
             resolution: file.resolution,
             aspectRatio: file.aspectRatio,
             size: file.size,
           });
        });
         return newFiles;
      })
      .then((files) => {

        console.log('files', files);
        if (value === 'Image') setImageUrls([...files, ...imageUrls]);
        else setlogoUrls([...files, ...logoUrls]);
      })
      .finally(() => {
        dispatch(updateStartLoader(false));
        passedValidations.forEach(file => URL.revokeObjectURL(file.src));
        failedValidations.forEach(file => URL.revokeObjectURL(file.src));
        setPassedValidations([]);
        setFailedValidations([]);
        setModal(false);
      });

    if (passedValidations.length > 0) {
      // changeStatus();
      // setSavedStatus(false);
    }
  };

  const error = true;
  return (
    <div className='p-max-detailed-view-container'>
      <div className='pmax-detailed-view'>
        <div>
          <div className='pmax-navigation-controls'>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Headline'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Headline' ? '#0869FB' : '',
                opacity: value === 'Headline' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Headline')}
            >
              Headline
              {errors?.headlines?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {errors?.headlines.length}{' '}
                          {errors?.headlines.length === 1 ? 'Error' : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {errors?.headlines?.map((headline, index) => (
                          <Typography
                            key={index}
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                              marginBottom: 8,
                            }}
                          >
                            {index + 1}. {headline}
                          </Typography>
                        ))}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Long Headlines'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Long Headlines' ? '#0869FB' : '',
                opacity: value === 'Long Headlines' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Long Headlines')}
            >
              Long Headline
              {errors?.longHeadlines?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {errors?.longHeadlines.length}{' '}
                          {errors?.longHeadlines.length === 1
                            ? 'Error'
                            : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {errors?.longHeadlines.map((longHeadline, index) => (
                          <Typography
                            key={index}
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                              marginBottom: 8,
                            }}
                          >
                            {index + 1}. {longHeadline}
                          </Typography>
                        ))}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Description'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Description' ? '#0869FB' : '',
                opacity: value === 'Description' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Description')}
            >
              Description
              {errors?.descriptions?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {errors?.descriptions.length}{' '}
                          {errors?.descriptions.length === 1
                            ? 'Error'
                            : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {errors?.descriptions.map((description, index) => (
                          <Typography
                            key={index}
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                              marginBottom: 8,
                            }}
                          >
                            {index + 1}. {description}
                          </Typography>
                        ))}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Image'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Image' ? '#0869FB' : '',
                opacity: value === 'Image' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Image')}
            >
              Image
              {errors?.imageUrls?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {errors?.imageUrls.length}{' '}
                          {errors?.imageUrls.length === 1 ? 'Error' : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {errors?.imageUrls.map((image, index) => (
                          <Typography
                            key={index}
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                              marginBottom: 8,
                            }}
                          >
                            {index + 1}. {image}
                          </Typography>
                        ))}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Logo'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Logo' ? '#0869FB' : '',
                opacity: value === 'Logo' ? 1 : '',
                padding: !error && '8px 16px',
              }}
              onClick={() => handleChange('Logo')}
            >
              Logo
              {errors?.logoUrls?.length > 0 && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 4px 8px 16px',
                        width: 264,
                      },
                    },
                  }}
                  title={
                    <>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: '20px',
                          color: '#333333',
                          padding: '2px 0 8px 0',
                        }}
                      >
                        We found{' '}
                        <strong
                          style={{
                            color: '#EF5854',
                          }}
                        >
                          {errors?.logoUrls.length}{' '}
                          {errors?.logoUrls.length === 1 ? 'Error' : 'Errors'}
                        </strong>
                      </Typography>
                      <div>
                        {errors?.logoUrls.map((logo, index) => (
                          <Typography
                            key={index}
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                              marginBottom: 8,
                            }}
                          >
                            {index + 1}. {logo}
                          </Typography>
                        ))}
                      </div>
                    </>
                  }
                  arrow
                  placement='right-start'
                >
                  <img src={errorIcon} alt='error' />
                </Tooltip>
              )}
            </div>
            <div
              className='pmax-control'
              style={{
                borderBottom:
                  value === 'Videos'
                    ? '1px solid #0869FB'
                    : '1px solid rgba(240,240,240,1)',
                cursor: 'pointer',
                color: value === 'Videos' ? '#0869FB' : '',
                opacity: value === 'Videos' ? 1 : '',
                padding: '8px 16px',
              }}
              onClick={() => handleChange('Videos')}
            >
              Videos
            </div>
          </div>
        </div>

        {value === 'Headline' && (
          <div>
            <div className='headline-container'>
              {headlines.length === 0 ? (
                <NoContentView tab='Headline' buttonName='Add Headline' />
              ) : (
                headlines.map((headline, index) => (
                  <div className='headline' key={index}>
                    <div
                      className='headline-text'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      { !addedNewTerm && (
                          read ? (
                        headline
                      ) : (
                        <TextField
                          variant='standard'
                          value={headline}
                          inputProps={{
                            style: {
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 14,
                              width: 500,
                              paddingBottom: 2,
                              border: 0,
                            },
                            maxLength: 30,
                          }}
                          onChange={(e) => change(e, index)}
                        />
                      ))}
                       { addedNewTerm && ( read ? ((index === headlines.length - 1) ? (
                          <TextField variant='standard'
                            value={headline}
                            inputRef={inputRef}
                            inputProps={{
                              style: {
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 14,
                                width: 500,
                                paddingBottom: 2,
                                border: 0,
                              },
                              maxLength: 30,
                            }}
                            onChange={(e) => change(e, index)}
                          />
                       ) : headline ) : (
                           ((index === headlines.length - 1) ?
                          <TextField variant='standard'
                            value={headline}
                            inputRef={inputRef}
                            inputProps={{
                              style: {
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 14,
                                width: 500,
                                paddingBottom: 2,
                                border: 0,
                              },
                              maxLength: 30,
                            }}
                            onChange={(e) => change(e, index)}
                          /> : (
                          <TextField variant='standard'
                            value={headline}
                            inputProps={{
                              style: {
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 14,
                                width: 500,
                                paddingBottom: 2,
                                border: 0,
                              },
                              maxLength: 30,
                            }}
                            onChange={(e) => change(e, index)}
                            />
                          ))
                       ))}
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineheight: '31px',
                          color: '#666666',
                          paddingRight: 15,
                        }}
                      >
                        {headline.length !== 0 && `${headline.length}/30`}
                      </Typography>
                    </div>
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => remove(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                )) 
              )}
            </div>
            <div className='add-search-term-container'>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => { setAddedNewTerm(false); setFocusValue(true)}}
              >
                + Add Headline
              </Button>
            </div>
          </div>
        )}
        {value === 'Description' && (
          <div>
            <div className='description-container'>
              {descriptions.length === 0 ? (
                <NoContentView tab='Description' buttonName='Add Description' />
              ) : (
                descriptions.map((description, index) => (
                  <div className='description' key={index}>
                    <div
                      className='description-text'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        lineHeight: 1.6,
                      }}
                    >
                      { !addedNewTerm && (
                          read ? (
                        description
                      ) : (
                        <TextField
                          variant='standard'
                          value={description}
                          multiline
                          inputProps={{
                            style: {
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 14,
                              width: 510,
                              paddingBottom: 0,
                              border: 0,
                            },
                            maxLength: 90,
                          }}
                          onChange={(e) => change(e, index)}
                        />
                      ))}
                      {
                          addedNewTerm && (
                              read ? ((index === descriptions.length - 1) ? (
                                <TextField
                                  variant='standard'
                                  inputRef={inputRef}
                                  value={description}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                          ) : description ) : (
                              ((index === descriptions.length - 1) ? 
                                <TextField
                                  variant='standard'
                                  inputRef={inputRef}
                                  value={description}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                                  : (
                                <TextField
                                  variant='standard'
                                  value={description}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                                )
                          ))
                          )}
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineheight: '31px',
                          color: '#666666',
                          paddingRight: 15,
                        }}
                      >
                        {description.length !== 0 && `${description.length}/90`}
                      </Typography>
                    </div>
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => remove(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))
              )}
            </div>
            <div className='add-search-term-container'>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => { setAddedNewTerm(false); setFocusValue(true)}}
              >
                + Add Description
              </Button>
            </div>
          </div>
        )}
        {value === 'Long Headlines' && (
          <div>
            <div className='description-container'>
             {longHeadlines.length === 0 ? (
                <NoContentView
                  tab='Long Headline'
                  buttonName='Add Long Headline'
                />
              ) : (
                longHeadlines.map((longHeadline, index) => (
                  <div className='description' key={index}>
                    <div
                      className='description-text'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        lineHeight: 1.6,
                      }}
                    >
                      { !addedNewTerm && (
                          read ? (
                        longHeadline
                      ) : (
                        <TextField
                          variant='standard'
                          value={longHeadline}
                          multiline
                          inputProps={{
                            style: {
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 14,
                              width: 510,
                              paddingBottom: 0,
                              border: 0,
                            },
                            maxLength: 90,
                          }}
                          onChange={(e) => change(e, index)}
                        />
                      ))}
                       {
                           addedNewTerm && (
                               read ?
                               ((index === longHeadlines.length - 1) ?
                                <TextField
                                  variant='standard'
                                  value={longHeadline}
                                  inputRef={inputRef}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                                   : longHeadline)
                               : (
                               ((index === longHeadlines.length - 1) ?
                                <TextField
                                  variant='standard'
                                  value={longHeadline}
                                  inputRef={inputRef}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                               : (
                                <TextField
                                  variant='standard'
                                  value={longHeadline}
                                  multiline
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      width: 510,
                                      paddingBottom: 0,
                                      border: 0,
                                    },
                                    maxLength: 90,
                                  }}
                                  onChange={(e) => change(e, index)}
                                />
                               )
                               ))
                           )}
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          paddingRight: 15,
                        }}
                      >
                        {longHeadline.length !== 0 &&
                          `${longHeadline.length}/90`}
                      </Typography>
                    </div>
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => remove(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))
              )}
            </div>
            <div className='add-search-term-container'>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => { setAddedNewTerm(false); setFocusValue(true)}}
              >
                + Add Long Headline
              </Button>
            </div>
          </div>
        )}
        {(value === 'Image' || value === 'Logo') && (
          <div>
            <div className='image-view-container'>
              <div className='image-resolution-message'>
                <img src={infoIcon} alt='info' />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '17px',
                    color: '#7B7B7B',
                  }}
                >
                  {value === 'Logo'
                    ? 'Please only add a logo with 1:1 or 4:1 aspect ratio'
                    : 'Please only add an image with 1.91:1, 1:1 or 4:5 aspect ratio'}
                </Typography>
              </div>
              <div className='image-list-container'>
                {value === 'Image' &&
                  (imageUrls.length === 0 ? (
                    <NoContentView
                      tab={'Image'}
                      buttonName={'Upload or Library'}
                    />
                  ) : (
                    imageUrls.map((image, index) => (
                      <div className='image' key={index}>
                        <div className='delete-container'>
                          <img
                            src={crossIcon}
                            alt='cross'
                            onClick={() => {
                              let imagesTemp = JSON.parse(
                                JSON.stringify(imageUrls)
                              );
                              imagesTemp.splice(index, 1);
                              setImageUrls(imagesTemp);
                            }}
                          />
                        </div>
                        <img
                          src={
                            process.env.REACT_APP_BACKEND_BASE_IMAGE_URL +
                            '/' +
                            image.id
                          }
                          alt='image'
                        />
                        <div className='name'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            Name:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            {image.name}
                          </Typography>
                        </div>
                        <div className='resolution'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            Resolution:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            {image.resolution}
                          </Typography>
                        </div>
                      </div>
                    ))
                  ))}
                {value === 'Logo' &&
                  (logoUrls.length === 0 ? (
                    <NoContentView
                      tab={'Logo'}
                      buttonName={'Upload or Library'}
                    />
                  ) : (
                    logoUrls.map((logo, index) => (
                      <div className='image' key={index}>
                        <div className='delete-container'>
                          <img
                            src={crossIcon}
                            alt='cross'
                            onClick={() => {
                              let logoTemp = JSON.parse(
                                JSON.stringify(logoUrls)
                              );
                              logoTemp.splice(index, 1);
                              setlogoUrls(logoTemp);
                            }}
                          />
                        </div>
                        <img
                          src={
                            process.env.REACT_APP_BACKEND_BASE_IMAGE_URL +
                            '/' +
                            logo.id
                          }
                          alt='image'
                        />
                        <div className='name'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            Name:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            {logo.name}
                          </Typography>
                        </div>
                        <div className='resolution'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            Resolution:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            {logo.resolution}
                          </Typography>
                        </div>
                      </div>
                    ))
                  ))}
              </div>
            </div>
            <div className='image-action-container'>
              <div className='add-search-term-container'>
                <Button
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: '#0869FB',
                    textTransform: 'capitalize',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => handleOpen()}
                >
                  + Upload
                </Button>
              </div>
              <div className='add-search-term-container'>
                <Button
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: '#0869FB',
                    textTransform: 'capitalize',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => setImageLibrary(true)}
                >
                  + Library
                </Button>
              </div>
            </div>
            {imageLibrary && (
              <ImageLibraryModal
                setModal={setImageLibrary}
                assetLibrary={
                  value === 'Image' ? imageAssetLibrary : logoAssetLibrary
                }
                // uploadUrls={(value === "Image") ? setimageUrls : setlogoUrls }
                handleAdd={handleAdd}
              />
            )}
          </div>
        )}
        {value === 'Videos' && (
          <div>
            <div className='video-view-container'>
              <div className='yb-search-pmax-container'>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '19px',
                    color: '#323232',
                    marginBottom: 6,
                  }}
                >
                  Youtube URL
                </Typography>
                <div className='text-link-area'>
                  <TextField
                    placeholder='Enter URL here'
                    value={url}
                    style={{
                      width: '98%',
                      height: 41.22,
                      border: urlError ? '0.964432px solid #EF5854' : '',
                      borderRadius: urlError ? '7.71545px' : '',
                    }}
                    sx={{
                      '& fieldset': { border: 'none' },
                    }}
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 11.5732,
                        lineHeight: '19px',
                      },
                    }}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                  <Button
                    style={{
                      width: 89,
                      height: 40,
                      background: '#0869FB',
                      borderRadius: 8,
                      color: 'white',
                      textTransform: 'capitalize',
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '24px',
                    }}
                    onClick={() => addVideo(url)}
                    // onClick={() => setLoaderModal(true)}
                  >
                    + Add
                  </Button>
                </div>
                {urlError && (
                  <p
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: '15px',
                      color: '#EF5854',
                      marginLeft: 2.5,
                    }}
                  >
                    Video needs to be horizontal, vertical or square
                  </p>
                )}
              </div>
              <div className='video-list-container'>
                {/* Use this to iterate video-list  */}
                <>
                  {ytVideoUrls.length === 0 ? (
                    <NoContentView tab='Videos' buttonName='' />
                  ) : (
                    ytVideoUrls.map((video, index) => (
                      <div className='video-list'>
                        <div className='video'>
                          <img src={video.thumbnailUrl} alt='video' />
                          <img
                            src={playIcon}
                            alt='play'
                            className='play-icon'
                          />
                        </div>
                        <div className='video-details'>
                          <div className='name'>
                            <Typography
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: 12,
                                color: '#7B7B7B',
                              }}
                            >
                              Name:
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                                color: '#7B7B7B',
                              }}
                            >
                              {video.title}
                            </Typography>
                          </div>
                          <div className='link'>
                            <div className='link-details'>
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  fontSize: 12,
                                  color: '#7B7B7B',
                                }}
                              >
                                URL:
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  fontSize: 12,
                                  color: '#0869FB',
                                  textDecoration: 'underline',
                                }}
                              >
                                {`https://www.youtube.com/watch?v=${video.id}`}
                              </Typography>
                            </div>
                            <Button className='remove-video'>
                              <img src={redCloseIcon} alt='' />
                              <Typography
                                style={removeTextStyle}
                                onClick={() => {
                                  let videosTemp = JSON.parse(
                                    JSON.stringify(ytVideoUrls)
                                  );
                                  videosTemp.splice(index, 1);
                                  setYtVideoUrls(videosTemp);
                                }}
                              >
                                Remove
                              </Typography>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </>
                {/* Use this to iterate video-list  */}
              </div>
            </div>
            <>
              <div className='add-search-term-container'>
                <Button
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: '#0869FB',
                    textTransform: 'capitalize',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => setVideoLibrary(true)}
                >
                  + Library
                </Button>
              </div>
            </>
          </div>
        )}
      </div>
      {modal && (
        <ImageUploadStatusModal
          handleClose={handleClose}
          verifiedFiles={passedValidations}
          errorFiles={failedValidations}
          handleProceed={handleProceed}
        />
      )}
      {videoLibrary && (
        <VideoLibraryModal
          setModal={setVideoLibrary}
          handleAdd={handleVideoAdd}
          videoAssetLibrary={videoAssetLibrary}
        />
      )}
    </div>
  );
};

export default PMaxCampaignDetailedView;
