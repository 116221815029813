import moment from "moment";
import { all, put, takeLatest, select, delay } from "redux-saga/effects";
import {api} from "../../index";
import {getLocalStorage} from "../../localStorage/localStorage";

function* updateWholeHdmAmForm(){
    let accountId = yield select((state) => localStorage.getItem("agencyId") === "1" ? state?.form?.form?.fbAdsAccountId : state.hdmFormAm?.fbAdsAccountId);
    try {
        const res = yield api.get("/facebook-ads/audiences?account_id="+accountId);
        yield put({
            type: "UPDATE_AUDIENCE_HDM_AM",
            payload: res?.data?.audiences
        });
    } catch (err) {
        console.log(err);
    }
}


function* getKeywordsHdm(){
    try {
        const res = yield api.get("/targeting-keywords/fb-ads/"+(getLocalStorage("amSelectedFormId") ? getLocalStorage("amSelectedFormId") : getLocalStorage("formId")));
        yield put({
            type: "UPDATE_HDM_KEYWORDS_AM_FACEBOOK",
            payload: res.data.response
        });
    } catch (err) {
        console.log(err);
    }
}

export const getInterestedKeyword = (keyword) => {
    return new Promise(async (resolve,reject) => {
        try{
            const response=await api.get("/facebook-ads/interests-keywords?interest_keyword="+keyword);
            resolve(response.data);
        }catch(err){
            reject(err);
        }
    });
}


function* getFacebookAds(){
    try {
        const res = yield api.get("/creative-resource/fb-ads/"+(getLocalStorage("amSelectedFormId") ? getLocalStorage("amSelectedFormId") : getLocalStorage("formId")));
        yield put({
            type: "UPDATE_HAS_CALLED_CAI",
            payload: true
        });
        yield put({
            type: "UPDATE_HDM_FACEBOOK_ADS",
            payload: res.data.ads
        });
    } catch (err) {
        console.log(err);
    }
}

function* generateFacebookAdsCAI(){
    try {
        const res = yield api.post("/creative-resource/fb-ads/"+(getLocalStorage("amSelectedFormId") ? getLocalStorage("amSelectedFormId") : getLocalStorage("formId")));
        yield put({
            type: "UPDATE_HDM_FACEBOOK_ADS",
            payload: res.data.ads
        });
    } catch (err) {
        console.log(err);
    }
}

function* getRoas(){
    let accountId = yield select((state) => state.hdmFormAm?.fAdsAccountId);
    const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().slice(0,10);
    const endDate = new Date().toISOString().slice(0,10)
    try {
        const res = yield api.get(`/facebook-ads/ad-accounts/${accountId}/roas?startDate=${startDate}&endDate=${endDate}`);
        yield put({
            type: "UPDATE_FACEBOOK_ROAS",
            payload: res.data.roas
        });
    } catch (err) {
        console.log(err);
    }
}
function* getRoas3Months(){
    let accountId = yield select((state) => state.hdmFormAm?.fAdsAccountId);
    const startDate = new Date(Date.now() - 91 * 24 * 60 * 60 * 1000).toISOString().slice(0,10);
    const endDate = new Date().toISOString().slice(0,10)
    try {
        const res = yield api.get(`/facebook-ads/ad-accounts/${accountId}/roas?startDate=${startDate}&endDate=${endDate}`);
        yield put({
            type: "UPDATE_FACEBOOK_ROAS_3MONTHS",
            payload: res.data.roas
        });
    } catch (err) {
        console.log(err);
    }
}
function* getRoas6Months(){
    let accountId = yield select((state) => state.hdmFormAm?.fAdsAccountId);
    const startDate = new Date(Date.now() - 182 * 24 * 60 * 60 * 1000).toISOString().slice(0,10);
    const endDate = new Date().toISOString().slice(0,10)
    try {
        const res = yield api.get(`/facebook-ads/ad-accounts/${accountId}/roas?startDate=${startDate}&endDate=${endDate}`);
        yield put({
            type: "UPDATE_FACEBOOK_ROAS_6MONTHS",
            payload: res.data.roas
        });
    } catch (err) {
        console.log(err);
    }
}
function* getRoas12Monts(){
    let accountId = yield select((state) => state.hdmFormAm?.fAdsAccountId);
    const startDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString().slice(0,10);
    const endDate = new Date().toISOString().slice(0,10)
    try {
        const res = yield api.get(`/facebook-ads/ad-accounts/${accountId}/roas?startDate=${startDate}&endDate=${endDate}`);
        yield put({
            type: "UPDATE_FACEBOOK_ROAS_12MONTHS",
            payload: res.data.roas
        });
    } catch (err) {
        console.log(err);
    }
}

export const publishStrategyDeckForHdm = (keyword) => {
    return new Promise(async (resolve,reject) => {
        try{
            const response=await api.get("/facebook-ads/interests-keywords?interest_keyword="+keyword);
            resolve(response.data);
        }catch(err){
            reject(err);
        }
    });
}

export default function* root() {
    yield all([
      takeLatest("UPDATE_FACEBOOK_ADS_AUDIENCES_SAGA", updateWholeHdmAmForm),
      takeLatest("UPDATE_KEYWORDS_ADS_SAGA_HDM",getKeywordsHdm),
      takeLatest("UPDATE_FACEBOOK_ADS_SAGA_STARTEGY",getFacebookAds),
      takeLatest("GET_FACEBOOK_ROAS",getRoas),
      takeLatest("GET_FACEBOOK_ROAS_3MONTHS",getRoas3Months),
      takeLatest("GET_FACEBOOK_ROAS_6MONTHS",getRoas6Months),
      takeLatest("GET_FACEBOOK_ROAS_12MONTHS",getRoas12Monts),
      takeLatest("GENERATE_FACEBOOK_ADS_CAI", generateFacebookAdsCAI)
    ]);
}