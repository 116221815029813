import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHDMClientDetailsByFormId,
  updateHDMClientDetails,
} from '../../../api/HDMClient/action';
import { HDMHeaderTabs, listOfIdsHDM } from '../../../utils/navbarUtil';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import './HDMPreview.css';
import Box from '@mui/material/Box';
import {ready as readyGif} from '../../../assets/icons/proposal/proposal';
import {campaign_publish_modal_icon as infoIcon} from '../../../assets/icons/campaign-approval/campaign_approval';
import { useEffect, useState } from 'react';
import { getHDMAMClientDetailsByFormId } from '../../../api/HDMClient/saga';
import { getLocalStorage } from '../../../api/localStorage/localStorage';
import {
  DB_KEY_MAP_ABOUT_BUSSINESS,
  DB_KEY_MAP_BRAND_MESSAGING,
  DB_KEY_MAP_CLIENT_ACCESS,
  DB_KEY_MAP_CREATIVE_MONTAGE,
  DB_KEY_MAP_FACEBOOK_TARGETING,
  DB_KEY_MAP_GOOGLE_TARGETING,
} from '../../../utils/dbKeyMapping';
import {
  goBackReviewHDMQuestionier,
  goToReviewHDMQuestionier,
  updateHDMCompoReduxClient,
} from '../../../api/HDMClient/componentTracking/action';
import {onboardingFormSuccess} from "../../../api/HDMClient/saga";

const QuestionierPreview = () => {
  const dispatch = useDispatch();
  const hdmClient =  useSelector(
    (state) => state.hdmClient
  );
  const clientAccess = useSelector(
    (state) => state.hdmClient.onboardingInfo.clientAccess
  );
  const brandMessaging = useSelector(
    (state) => state.hdmClient.onboardingInfo.brandMessaging
  );
  const facebookTargeting = useSelector(
    (state) => state.hdmClient.onboardingInfo.facebookTargeting
  );
  const googleTargeting = useSelector(
    (state) => state.hdmClient.onboardingInfo.googleTargeting
  );
  const aboutBusiness = useSelector(
    (state) => state.hdmClient.onboardingInfo.aboutBusiness
  );
  const creativeAndMontageAds = useSelector(
    (state) => state.hdmClient.onboardingInfo.creativeAndMontageAds
  );
  const lastActiveComp = useSelector(
    (state) => state.hdmClientCurrentComp.lastActiveComp
  );
  const platforms=clientAccess?.platforms;
  const [confirmModal, setConfirmModal] = useState(false);

  const [successModal, setSuccessModal] = useState(false);

  const mappedKey = {
    advertisingLocations: 'name',
    activitiesInterest: 'name',
    keyCompetitors: 'name',
    platforms: 'platform',
  };

  const desiredData = (key, value) => {
    if(key === "keyCompetitors"){
      let compNameValue = "";
      value.map((key, index) => {
        compNameValue += "(" + key.name + " => " + key.website + ")" + ",";
      });
      return compNameValue.slice(0, compNameValue.length - 1);
    }
    if (key === "productandservice") {
      let str = "";
      value.forEach(({ name, description }, index) => {
        if(name.length !== 0 || description.length !== 0){
           str += "(name=>" + name + ",description=>" + description + "),";
        }
      });
      return str.length !== 0 ? str.substring(0,str.length-1) : "";
    }
    const tempArr = value.map((infoType) => {
      if (typeof infoType === 'string') {
        return infoType;
      }
      return infoType[mappedKey[key]];
    });
    return tempArr.join(',');
  };

  const getMinMaxAgeRange = (fbTargetingData = {}) => {
    return `${fbTargetingData?.minAgeRange}-${fbTargetingData?.maxAgeRange}`;
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 584,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    padding: '46px 47px 42px',
    boxShadow: 24,
  };
  const strVal = (str, key) => {
    if (
      [
        'averageOrderValue',
        'cogsPerAov',
        'shippingCostPerAov',
        'breakEvenPoint',
        'fbCamaignBudget',
        'googleCamaignBudget',
        'tiktokCamaignBudget',
        'monthlyRevenue',
        'monthlyRevenueGoal1Year',
        'monthlyRevenueGoal3Months',
        'monthlyRevenueGoal6Months',
      ].includes(key)
    ) {
      return '$' + str;
    } else {
      return str;
    }
  };
  console.log('brandMessaging', clientAccess);
  return (
    <div
    // style={{ width: '98%', MaxHeight: '98vh', background: 'white', borderRadius:8 ,overflow:'auto'}}
    >
      <div className='top_head'>
        {/* <div className="edit">
          <button className="btn"></button>
        </div> */}

        <div className='resp'>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <PrimaryButton
              title='Edit Responses'
              onClick={() => {
                dispatch(
                  goToReviewHDMQuestionier({
                    currentComp: 1,
                    currentLeftSidebar: listOfIdsHDM.CLIENTANDACCESS,
                    currentHeaderTab: HDMHeaderTabs.CLIENTANDACCESS,
                  })
                );
              }}
              icon='edit'
              type='bordered'
            />
          </div>

          <h3 className='preview_heading'>PREVIEW</h3>
          <h1 className='preview_header2'>VIEW AND EDIT YOUR RESPONSES</h1>
          <span
            style={{
              color: '#6F6C90',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '18px',
              lineHeight: '30px',
              color: '#6F6C90',
            }}
          >
            Please review your responses carefully, you will not be able to edit
            them later.{' '}
          </span>
        </div>
      </div>
      <div className=''>
        <div className='accordian'>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography fontWeight={600}>Client & Access</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(clientAccess ? clientAccess : {})?.map(
                  ([key, value], index) => {
                    return typeof value == 'boolean' &&
                      key.toLowerCase() !== 'status' ? (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_MAP_CLIENT_ACCESS[key]} :</span>
                          <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'No'}</a>
                        </div>
                      </div>
                    ) : (
                      key.toLowerCase() !== 'status' &&
                        DB_KEY_MAP_CLIENT_ACCESS[key] &&
                        value?.toString().trim() != '' && (
                          <div className='tag'>
                            <div className='content'>
                              <span>{DB_KEY_MAP_CLIENT_ACCESS[key]} : </span>
                              <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value?.length !== 0 ? value : ''}</a>{' '}
                            </div>
                          </div>
                        )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Brand / Messaging</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(brandMessaging ? brandMessaging : {})?.map(
                  ([key, value], index) => {
                    return typeof value == 'boolean' &&
                      key.toLowerCase() !== 'status' ? (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_MAP_BRAND_MESSAGING[key]} :</span>
                          <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'No'}</a>
                        </div>
                      </div>
                    ) : (
                      key.toLowerCase() !== 'status' &&
                        DB_KEY_MAP_BRAND_MESSAGING[key] && (
                          <div className='tag'>
                            <div className='content'>
                              <span>{DB_KEY_MAP_BRAND_MESSAGING[key]} : </span>
                              {/* href={"/client/" + key} */}
                              <a>
                                {Array.isArray(value) ? (
                                  <span style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</span>
                                ) : typeof value != 'boolean' ? (
                                  <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value?.length !== 0 ? value : ''}</a>
                                ) : (
                                  <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'NO'}</a>
                                )}
                              </a>{' '}
                            </div>
                          </div>
                        )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        {platforms?.findIndex(platform => platform.platform === "facebook-ads") !== -1 && <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Facebook Targeting</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(facebookTargeting ? facebookTargeting : {})?.map(
                  ([key, value], index) => {
                    return typeof value == 'boolean' &&
                      key.toLowerCase() !== 'status' ? (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_MAP_FACEBOOK_TARGETING[key]} :</span>
                          <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'No'}</a>
                        </div>
                      </div>
                    ) : (
                      key.toLowerCase() !== 'status' &&
                        DB_KEY_MAP_FACEBOOK_TARGETING[key] && (
                          <div className='tag'>
                            <div className='content'>
                              <span>{DB_KEY_MAP_FACEBOOK_TARGETING[key]} : </span>
                              {/* href={"/client/" + key} */}
                              <a>
                                {key === "minAgeRange" ? getMinMaxAgeRange(facebookTargeting) : Array.isArray(value) ? (
                                  <span style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</span>
                                ) : typeof value != 'boolean' ? (
                                  <a  style={{ color: "#619BF3",fontWeight:"bolder" }}>
                                    {value?.length !== 0
                                      ? strVal(value, key)
                                      : ''}
                                  </a>
                                ) : (
                                  <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'NO'}</a>
                                )}
                              </a>
                            </div>
                          </div>
                        )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>}
        {platforms?.findIndex(platform => platform.platform === "google-ads") !== -1 && <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Google Targeting</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(googleTargeting ? googleTargeting : {})?.map(
                  ([key, value], index) => {
                    return typeof value == 'boolean' &&
                      key.toLowerCase() !== 'status' ? (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_MAP_GOOGLE_TARGETING[key]} :</span>
                          <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'No'}</a>
                        </div>
                      </div>
                    ) : (
                      key.toLowerCase() !== 'status' &&
                        DB_KEY_MAP_GOOGLE_TARGETING[key] && (
                          <div className='tag'>
                            <div className='content'>
                              <span>{DB_KEY_MAP_GOOGLE_TARGETING[key]} : </span>
                              {/* href={"/client/" + key} */}
                              <a>
                                {Array.isArray(value) ? (
                                  <span style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</span>
                                ) : typeof value != 'boolean' ? (
                                  <a  style={{ color: "#619BF3",fontWeight:"bolder" }}>
                                    {value?.length !== 0
                                      ? strVal(value, key)
                                      : ''}
                                  </a>
                                ) : (
                                  <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'NO'}</a>
                                )}
                              </a>
                            </div>
                          </div>
                        )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>}
        <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>About Your Business</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(aboutBusiness ? aboutBusiness : {})?.map(
                  ([key, value], index) => {
                    return typeof value == 'boolean' &&
                      key.toLowerCase() !== 'status' ? (
                      <div className='tag'>
                        <div className='content'>
                          <span>{DB_KEY_MAP_ABOUT_BUSSINESS[key]} :</span>
                          <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'No'}</a>
                        </div>
                      </div>
                    ) : (
                      key.toLowerCase() !== 'status' &&
                        DB_KEY_MAP_ABOUT_BUSSINESS[key] && (
                          <div className='tag'>
                            <div className='content'>
                              <span>{DB_KEY_MAP_ABOUT_BUSSINESS[key]} : </span>
                              {/* href={"/client/" + key} */}
                              {Array.isArray(value) ? (
                                <span style={{ color: "#619BF3",fontWeight:"bolder" }}>{desiredData(key, value)}</span>
                              ) : typeof value != 'boolean' ? (
                                <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value?.length !== 0 ? strVal(value, key) : ''}</a>
                              ) : (
                                <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'NO'}</a>
                              )}
                            </div>
                          </div>
                        )
                    );
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className='accordian'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Creative & Montage Ad</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='wrap'>
                {Object.entries(
                  creativeAndMontageAds ? creativeAndMontageAds : {}
                )?.map(([key, value], index) => {
                  return typeof value == 'boolean' &&
                    key.toLowerCase() !== 'status' ? (
                    <div className='tag'>
                      <div className='content'>
                        <span>{DB_KEY_MAP_CREATIVE_MONTAGE[key]} :</span>
                        <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value ? 'Yes' : 'No'}</a>
                      </div>
                    </div>
                  ) : (
                    key.toLowerCase() !== 'status' &&
                      DB_KEY_MAP_CREATIVE_MONTAGE[key] && (
                        <div className='tag'>
                          <div className='content'>
                            <span>{DB_KEY_MAP_CREATIVE_MONTAGE[key]} : </span>
                            {/* href={"/client/" + key} */}
                            <a style={{ color: "#619BF3",fontWeight:"bolder" }}>{value?.length !== 0 ? value : ''} </a>
                          </div>
                        </div>
                      )
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className='preview_footer_row'>
        <div className='footer_con'>
          <button
            className='back'
            onClick={() => {
              dispatch(
                goBackReviewHDMQuestionier({
                  currentComp: lastActiveComp,
                })
              );
            }}
          >
            BACK
          </button>
          <PrimaryButton
            title='PUBLISH'
            disabled={
              creativeAndMontageAds &&
              !creativeAndMontageAds.status === 'COMPLETED'
            }
            onClick={() => {
              setConfirmModal(!confirmModal);
            }}
          />
        </div>
      </div>
      <Modal
        open={confirmModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='status_popup_layout'>
            <div
              className='modal_icon'
              style={{
                width: 60,
                height: 60,
              }}
            >
              <img src={infoIcon} />
            </div>
            <Typography
              id='modal-modal-title'
              variant='h6'
              component='h2'
              marginTop={'2rem'}
            >
              Are you sure you want to publish this form?
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2 }}>
              You won’t be able to make any changes later
            </Typography>
            <div className='action_popup'>
              <PrimaryButton
                title='PUBLISH'
                onClick={() => {
                  onboardingFormSuccess(hdmClient).then((res) => {
                    setConfirmModal(!confirmModal);
                    setSuccessModal(!successModal);
                    dispatch(getHDMClientDetailsByFormId());
                  }).catch((err) => {
                    setConfirmModal(true);
                  });
                }}
              />
            </div>
            <a
              className='close_button'
              onClick={() => {
                setConfirmModal(!confirmModal);
              }}
              style={{ cursor: 'pointer' }}
            >
              <span></span>
            </a>
          </div>
        </Box>
      </Modal>
      <Modal
        open={successModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='status_popup_layout'>
            <div className='modal_icon'>
              <img src={readyGif} />
            </div>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Onboarding Form Submitted!
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2 }}>
              Let’s move on to the next step
            </Typography>
            <div className='action_popup'>
              <PrimaryButton
                title='PROCEED'
                onClick={() => {
                  setSuccessModal(!successModal);
                  dispatch(updateHDMCompoReduxClient());
                }}
              />
            </div>
            <a
              className='close_button'
              onClick={() => {
                setSuccessModal(!successModal);
                dispatch(updateHDMCompoReduxClient());
              }}
              style={{ cursor: 'pointer' }}
            >
              <span></span>
            </a>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default QuestionierPreview;
