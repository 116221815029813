import {
  Button,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { goToSpecificCompoHDMAM } from '../../../api/HDMAM/componentTracking/action';
import { updateLookalike } from '../../../api/HDMAM/campaign/action';
import { budget_icon as budgetIcon } from '../../../assets/icons/campaign-approval/campaign_approval';
import { conversion_goals_icon as conversionGoalIcon } from '../../../assets/icons/campaign-approval/campaign_approval';
import { objective_icon as objectiveIcon } from '../../../assets/icons/campaign-approval/campaign_approval';
import { text_field_icon as textFieldIcon } from '../../../assets/icons/campaign-approval/campaign_approval';
import MenuRadioList from './MenuRadioList';
import BpRadio from '../../ClientDashboard/CustomRadio/Radio';
import {
  bidStrategy,
  conversionLocation,
  cta,
  optimisationAdDelivery,
  allowedConversionLocation,
  bidStrategyBackendMapping,
  bidStrategyFrontendMapping,
  objectiveBackendMapping,
  objectiveFrontendMappiing,
  destinationTypeBackendMapping,
  optimizationGoalBackendMapping,
  CTABackendMapping,
} from './constants';
import './hdmcampaign.css';
import { goToSpecificCompoClient } from '../../../api/accountManager/componentTracking/action';
import {
  accountManagerIds,
  accountManagerIdsHDM,
  componentWithIndexHDMAm,
  ldComponentWithIndexAm,
} from '../../../utils/accountManagerUtil';
const objectiveList = [
  'Leads',
  'Awareness',
  'Traffic',
  'Engagement',
  'App promotion',
  'Sales',
];

const campaignTypeList = [
  'Search',
  'Performance Max',
  'Display',
  'Shopping',
  'Video',
  'Discovery',
];

const goalReachList = [
  'Website Visits',
  'Phone Calls',
  'Store Visits',
  'App Downloads',
  'Lead from Submissions',
];
const networksList = [
  'Include Google Search Partners',
  'Include Google Display Networks',
];

const conversionGoalsList = [
  'Book Appointments',
  'Contacts',
  'Sign-ups',
  'Submit lead forms',
  'Other',
  'Page views',
  'Qualified leads',
  'Store visits',
];
const HDMCampaignType = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const [focusBudget, setFocusBudget] = useState(false);

  const setObjectiveValue = (value) => {
    props.updateReducer({
      ...props.data,
      objective: objectiveBackendMapping[value],
      adsets: (props.data.adsets || []).map((ad) => {
        return {
          ...ad,
          conversionLocation:
            destinationTypeBackendMapping[allowedConversionLocation[value][0]],
          optimizationAdDelivery:
            optimizationGoalBackendMapping[
              ((optimisationAdDelivery[value] || {})[
                allowedConversionLocation[value][0]
              ] || [])[0]
            ] || '',
          cta:
            CTABackendMapping[
              ((cta[value] || {})[allowedConversionLocation[value][0]] || [])[0]
            ] || '',
        };
      }),
    });
  };

  const setBidStrategyValue = (value) => {
    props.updateReducer({
      ...props.data,
      bidStrategy: bidStrategyBackendMapping[value],
    });
  };

  console.log('after', props);
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '97.5%',
          margin: 'auto',
          marginTop: '2rem',
          paddingBottom: props.title === 'BOF Campaign' ? '1.75rem' : '',
        }}
      >
        <div
          className='hdm-indicator'
          style={{
            background: props.indicator ? 'rgba(26, 191, 161, 0.1)' : '#EAF2FF',
          }}
        ></div>
        <div className='hdm-campaign-sub-container'>
          <div className='hdm-campaign-creation-field-header'>
            <Typography className='hdm-campaign-creation-field-header__title'>
              {props.title}
            </Typography>

            <Button
              disabled={
                !props.data.name ||
                (props.data.abo === 'ON' && !props.data.budget) ||
                props.isCampaignComplete
              }
              sx={{
                '&.Mui-disabled': {
                  opacity: '0.3',
                },
              }}
              variant='outlined'
              className='hdm-detailButton'
              onClick={() => {
                if (props.agencyType === 'LD') {
                  if (props.title === 'TOF- Lookalike Campaign') {
                    dispatch(
                      goToSpecificCompoClient(
                        ldComponentWithIndexAm[
                          accountManagerIds.ADSET_LEVEL_LOOKALIKE
                        ]
                      )
                    );
                  }
                  if (props.title === 'TOF- Interest Campaign') {
                    dispatch(
                      goToSpecificCompoClient(
                        ldComponentWithIndexAm[
                          accountManagerIds.ADSET_LEVEL_INTEREST
                        ]
                      )
                    );
                  }
                  if (props.title === 'MOF Campaign') {
                    dispatch(
                      goToSpecificCompoClient(
                        ldComponentWithIndexAm[
                          accountManagerIds.ADSET_LEVEL_MOF
                        ]
                      )
                    );
                  }
                  if (props.title === 'BOF Campaign') {
                    dispatch(
                      goToSpecificCompoClient(
                        ldComponentWithIndexAm[
                          accountManagerIds.ADSET_LEVEL_BOF
                        ]
                      )
                    );
                  }
                } else {
                  if (props.title === 'TOF- Lookalike Campaign') {
                    dispatch(
                      goToSpecificCompoHDMAM(
                        componentWithIndexHDMAm[
                          accountManagerIdsHDM.ADSET_LEVEL_LOOKALIKE
                        ]
                      )
                    );
                  }
                  if (props.title === 'TOF- Interest Campaign') {
                    dispatch(
                      goToSpecificCompoHDMAM(
                        componentWithIndexHDMAm[
                          accountManagerIdsHDM.ADSET_LEVEL_INTEREST
                        ]
                      )
                    );
                  }
                  if (props.title === 'MOF Campaign') {
                    dispatch(
                      goToSpecificCompoHDMAM(
                        componentWithIndexHDMAm[
                          accountManagerIdsHDM.ADSET_LEVEL_MOF
                        ]
                      )
                    );
                  }
                  if (props.title === 'BOF Campaign') {
                    dispatch(
                      goToSpecificCompoHDMAM(
                        componentWithIndexHDMAm[
                          accountManagerIdsHDM.ADSET_LEVEL_BOF
                        ]
                      )
                    );
                  }
                }
              }}
            >
              Adset Level
            </Button>
          </div>
          <div className='hdm-campaign-form-container'>
            <div className='hdm-campaign-form-element'>
              <div className='icon-container'>
                <img src={textFieldIcon} alt='' />
              </div>
              <div className='hdm-form-input-element'>
                <Typography className='hdm-form-input-label'>Name</Typography>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  size='small'
                  placeholder='Enter Name'
                  value={props.data.name}
                  disabled={props.isCampaignComplete}
                  inputProps={{
                    style: {
                      width: 100,
                      height: 8,
                      background: '#FFFFFF',
                      border: '1px solid #CCECFE',
                      borderRadius: 4,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                    },
                  }}
                  onChange={(e) => {
                    props.updateReducer({
                      ...props.data,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className='hdm-campaign-form-element'>
              <div className='icon-container'>
                <img src={objectiveIcon} alt='' />
              </div>
              <div className='hdm-form-input-element'>
                <Typography className='hdm-form-input-label'>
                  Objective
                </Typography>
                <MenuRadioList
                  list={Object.keys(conversionLocation)}
                  setValue={setObjectiveValue}
                  selected={objectiveFrontendMappiing[props.data.objective]}
                  disabled={props.isCampaignComplete}
                  disabledItems={['appPromotion']}
                />
              </div>
            </div>
            <div className={'hdm-campaign-form-element'}>
              <div className='hdm-form-input-element '>
                <Typography className='hdm-form-input-label'>ABO</Typography>
                <div>
                  <RadioGroup
                    row
                    value={props.data.abo}
                    defaultValue='ON'
                    onChange={(e) => {
                      props.updateReducer({
                        ...props.data,
                        abo: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      style={{ marginLeft: '0px' }}
                      disabled={props.isCampaignComplete}
                      value={'ON'}
                      control={<BpRadio />}
                      label={'ON'}
                    />
                    <FormControlLabel
                      value={'OFF'}
                      disabled={props.isCampaignComplete}
                      control={<BpRadio />}
                      label={'OFF'}
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>

            <div
              className={
                props.data.abo === 'OFF'
                  ? 'hdm-switch-disabled hdm-campaign-form-element'
                  : 'hdm-campaign-form-element'
              }
            >
              <div className='icon-container'>
                <img src={budgetIcon} alt='' />
              </div>
              <div className='hdm-form-input-element '>
                <Typography className='hdm-form-input-label'>
                  Daily Budget (in $)
                </Typography>

                <div style={{ position: 'relative' }}>
                  <TextField
                    id='outlined-basic'
                    disabled={
                      props.data.abo === 'OFF' || props.isCampaignComplete
                    }
                    variant='outlined'
                    size='small'
                    placeholder='Enter Budget'
                    type='number'
                    value={props.data.budget}
                    onChange={(e) => {
                      props.updateReducer({
                        ...props.data,
                        budget: parseInt(e.target.value),
                      });
                    }}
                    onFocus={() => setFocusBudget(!focusBudget)}
                    inputProps={{
                      style: {
                        boxSizing: 'border-box',
                        width: 128,
                        height: 26.75,
                        background: '#FFFFFF',
                        border: '1px solid #CCECFE',
                        borderRadius: 4,
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        // paddingLeft: 30,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                props.data.abo === 'OFF'
                  ? 'hdm-switch-disabled hdm-campaign-form-element'
                  : 'hdm-campaign-form-element'
              }
            >
              <div className='icon-container'>
                <img src={conversionGoalIcon} alt='' />
              </div>
              <div className='hdm-form-input-element'>
                <Typography className='hdm-form-input-label'>
                  Bid Strategy
                </Typography>
                <MenuRadioList
                  list={
                    bidStrategy[
                      objectiveFrontendMappiing[props.data.objective]
                    ] || []
                  }
                  disabled={
                    props.data.abo === 'OFF' || props.isCampaignComplete
                  }
                  setValue={setBidStrategyValue}
                  selected={bidStrategyFrontendMapping[props.data.bidStrategy]}
                  disabledItems={['Bid cap', 'Cost per result']}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HDMCampaignType;
