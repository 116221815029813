import { put, select, takeLatest, all } from 'redux-saga/effects';
import { api } from '../../index';
import { updateData, getGoogleCustomersReducers, createGoogleAdsAuditReducer, updateAuditReport, updateInsights, updateReviewPointsReducer, updateGotInsights, getInsightsForFormIdReducer, updateHaveInsights, publishCampaignReducer, publishSpecificCampaignTypeReducer, fetchAudience,
  updateAudience,fetchCriteria,
  updateGoogleData, fetchUserList} from './slice';

import { getFormByFormIdReducer } from '../forms/slice';
import { getGoogleCampaignObject } from '../../../utils/accountManagerUtil';
import { getLocalStorage } from '../../localStorage/localStorage';



function* getGoogleCustomers(action) {
  const url = `/g-ads/customers`;
  const googleResponse = yield api.get(url);
  yield put(updateData(googleResponse.data.customers));
}

function* createGoogleAdsAudit(action) {
  const url = `/g-ads/audit`;
  const store = yield select();
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const selectedCustomerId = store.googleCustomers.selectedCustomerId;
  const selectedLoginCustomerId = store.googleCustomers.selectedLoginCustomerId;
  const body = {
    "formId":formId,
    "startDate": action.payload.startDate,
    "endDate": action.payload.endDate,
    "customerId": selectedCustomerId,
    "loginCustomerId": selectedLoginCustomerId
  }
  const auditResponse = yield api.post(url, body);
  yield put(updateAuditReport(auditResponse.data));
  yield put(updateInsights(auditResponse.data.insights.map((insight => insight.insight))));
  yield put(updateGotInsights(true));
  yield put(updateHaveInsights(true));
}

function* updateReviewPoints(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/g-ads/audit-summary/${formId}`;
  const store = yield select();
  const body = {
    "summaries": action.payload.reviewPoints
  }
  const auditResponse = yield api.put(url, body);
  yield put(updateInsights(auditResponse.data.summaries ));
}

function* getInsightsForFormId(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/g-ads/audit-summary/${formId}`;
  try{
    const auditResponse = yield api.get(url);
    if(auditResponse){
      yield put(updateInsights(auditResponse.data.summaries));
      yield put(updateHaveInsights(true));
    } else {
      yield put(updateInsights([]));
      yield put(updateHaveInsights(false));
    }
  }catch(error){
    console.log("while fetching audit points", error.message);
    yield put(updateInsights([]));
    yield put(updateHaveInsights(false));
  }
}

export async function getInsights(formId){
  const url = `/g-ads/audit-summary/${formId}`;
  try{
    const auditResponse = await api.get(url);
    return auditResponse.data.summaries;
  }catch(error){
    console.log("while fetching insights", error.message);
  }
}

function* publishCampaign(action){
  const campaignTypes = ["brandCampaign", "competitorCampaign", "serviceCampaign"];
  const campaignTypesCampaignMapping = {
    brandCampaign: "BRAND",
    competitorCampaign: "COMPETITOR",
    serviceCampaign: "SERVICE"
  }
  let campaignObj = {
    brandCampaign: null,
    competitorCampaign: null,
    serviceCampaign: null,
  }
  const store = yield select();
  const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  if(form?.generatedCampaignsInfo){
    const result = getGoogleCampaignObject(form.generatedCampaignsInfo);
    campaignObj = result[0];
  }
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/g-ads-campaigns/${formId}`;
  try{
    yield all(campaignTypes.map(campaign => {
      try{
        if(!campaignObj[campaign]){
          return api.post(`${url}?campaignTypes=${campaignTypesCampaignMapping[campaign]}`);
        } else {
          return null;
        }
      }catch(error){
        console.log(`in ${campaign} - error is`, error.message);
      }
    }));
  }catch(error){
    console.log(`error is`, error.message);
  }
  yield put(getFormByFormIdReducer());
  // try{
  //   const campaignResposne = yield api.post(url);
  //   console.log("success in campaing publisheing", campaignResposne);
  //   if(campaignResposne.data.success){
  //     yield put(getFormByFormIdReducer());
  //   }
  // }catch(error){
  //   console.log("while publishing campaign", error.message);
  // }
}

function* publishSpecificCampaignType(action){
  const {campaignType, callback} = action.payload;
  const campaignTypesCampaignMapping = {
    brandCampaign: "BRAND",
    competitorCampaign: "COMPETITOR",
    serviceCampaign: "SERVICE"
  }
  let campaignObj = {
    brandCampaign: null,
    competitorCampaign: null,
    serviceCampaign: null,
  }
  const store = yield select();
  const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  if(form?.generatedCampaignsInfo) { // ??
    const result = getGoogleCampaignObject(form.generatedCampaignsInfo);
    campaignObj = result[0];
  }
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/g-ads-campaigns/${formId}`;
  try{
    if(!campaignObj[campaignType]){
      const response = yield api.post(`${url}?campaignTypes=${campaignTypesCampaignMapping[campaignType]}`);
      callback?.(null, response.data);
    }
  } catch(error){
    callback?.(error, null);
    console.log(`error is`, error.message);
  }
  yield put(getFormByFormIdReducer());
}

function* getAudience() {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/g-ads/audience?formId=${formId}`;
  try{
    const googleResponse = yield api.get(url);
    yield put(updateAudience(googleResponse.data.audiences));
  }catch(error){
    console.log("error while fetching audience", error.message);
  }
}
function* getCriteria() {
  const url = `/g-ads/criteria`;
  try{
    const googleResponse = yield api.get(url);
    const {affinityCategories, inmarketCategories, lifeEvents, detailedDemographics} = googleResponse.data;
    yield put(updateGoogleData({affinityCategories, inmarketCategories, lifeEvents, detailedDemographics}));
  }catch(error){
    console.log("error while fetching google criteria", error.message);
  }
}

function* getUserList() {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/g-ads/user-list?formId=${formId}`;
  try{
    const googleResponse = yield api.get(url);
    const {users} = googleResponse.data;
    yield put(updateGoogleData({users}));
  }catch(error){
    console.log("error while fetching google users list", error.message);
  }
}

export default function* googleAdCustomerSaga() {
  yield takeLatest(getGoogleCustomersReducers.type, getGoogleCustomers);
  yield takeLatest(createGoogleAdsAuditReducer.type, createGoogleAdsAudit);
  yield takeLatest(updateReviewPointsReducer.type, updateReviewPoints);
  yield takeLatest(getInsightsForFormIdReducer.type, getInsightsForFormId);
  yield takeLatest(publishCampaignReducer.type, publishCampaign);
  yield takeLatest(publishSpecificCampaignTypeReducer.type, publishSpecificCampaignType);
  yield takeLatest(fetchAudience.type, getAudience);
  yield takeLatest(fetchCriteria.type, getCriteria);
  yield takeLatest(fetchUserList.type, getUserList);
}