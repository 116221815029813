import { TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import {
  updateReviewPointsReducer,
} from "../../../api/accountManager/googleAdsCustomer/slice";
import { pencil as pencilIcon, trash as trashIcon } from "../../../assets/icons/common/common";
import { add_field_icon as addFieldIcon } from "../../../assets/icons/icon";
import {
  BACKEND_STATUS
} from "../../../utils/accountManagerUtil";
import PrimaryButton from "../../AccountManager/ButtonPrimary/Buttons";
import "./review.css";

const CommonReviewPoints = ({ 
  form,
  onClick,
}) => {
  const dispatch = useDispatch();
  const [reviewPoints, setReviewPoints] = useState([]);
  const [addedText, setAddedText] = useState("");
  const insights = useSelector((store) => store.googleCustomers.insights);
  const [editIdx, setEditIdx] = useState(null);

  useEffect(() => {
    if (insights.length) {
      setReviewPoints(insights);
    }
  }, [insights]);

  const onClickHandler = () => {
    onClick?.();
    if (form?.auditInfo?.status !== BACKEND_STATUS.COMPLETED) {
      dispatch(
        updateFormReducer({
          auditInfo: {
            ...form.auditInfo,
            status: BACKEND_STATUS.COMPLETED,
          },
        })
      );
    }
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(updateAppData({
  //       selectedCustomerId: null,
  //       selectedLoginCustomerId: null,
  //       auditReport: {},
  //       insights: [],
  //       gotInsights: false,
  //       haveInsights: true,
  //     }))
  //   }
  // }, [])

  const addClickHandler = () => {
    const reviewPointsBuffer = [...reviewPoints, addedText];
    setReviewPoints(reviewPointsBuffer);
    setAddedText("");
    dispatch(updateReviewPointsReducer({ reviewPoints: reviewPointsBuffer }));
  };
  const editClickHandler = (idx) => {
    if (editIdx) {
      setEditIdx(null);
      dispatch(updateReviewPointsReducer({ reviewPoints: reviewPoints }));
    } else {
      setEditIdx(idx);
    }
  };

  const deleteClickHandler = (idx) => {
    let reviewPointsBuffer = [...reviewPoints];
    reviewPointsBuffer.splice(idx, 1);
    setReviewPoints(reviewPointsBuffer);
    dispatch(updateReviewPointsReducer({ reviewPoints: reviewPointsBuffer }));
  };

  return (
    <div
      style={{
        padding: "24px",
      }}
      className="right_main_section"
    >
      <Typography
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 20,
          color: "#242731",
          marginBottom: "1rem",
        }}
      >
        Review Points
      </Typography>

      {reviewPoints.map((reviewPoint, idx) => {
        return (
          <div
            key={idx}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3rem",
              marginBottom: "1.5rem",
              width: "100%",
            }}
          >
            {editIdx === idx ? (
              <TextField
                variant="outlined"
                size="small"
                style={{ width: "70%" }}
                inputProps={{ style: { height: 16, fontSize: 12 } }}
                value={reviewPoint}
                onChange={(e) => {
                  const reviewPointsBuffer = [...reviewPoints];
                  reviewPointsBuffer.splice(idx, 1, e.target.value);
                  setReviewPoints(reviewPointsBuffer);
                }}
              />
            ) : (
              <Typography
                style={{
                  background: "#FAFAFA",
                  borderRadius: 8,
                  padding: "0.5rem",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  width: "70%",
                  lineHeight: "20px",
                }}
              >
                {reviewPoint}
              </Typography>
            )}
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                style={{
                  width: 32,
                  height: 32,
                  background: "#F8F8F8",
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={pencilIcon}
                  alt=""
                  onClick={() => editClickHandler(idx)}
                />
              </div>
              <div
                style={{
                  width: 32,
                  height: 32,
                  background: "#F8F8F8",
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={trashIcon}
                  alt=""
                  onClick={() => deleteClickHandler(idx)}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "3rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          style={{ width: "70%" }}
          inputProps={{ style: { height: 16, fontSize: 12 } }}
          placeholder="Add new field here"
          value={addedText}
          onChange={(e) => setAddedText(e.target.value)}
        />
        <div style={{ display: "flex", gap: "1rem" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.45rem",
              cursor: "pointer",
            }}
            onClick={addClickHandler}
          >
            <img src={addFieldIcon} alt="" />
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 14,
                color: "#0869FB",
              }}
            >
              Add Point
            </Typography>
          </div>
        </div>
      </div>

      <div className="preview_footer_row">
        <div className="footer_down">
          <PrimaryButton title="Save & Proceed" onClick={onClickHandler} />
        </div>
      </div>
    </div>
  );
};

export default CommonReviewPoints;
