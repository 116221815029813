import {
  Box,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import StarLeft from '../../../assets/images/LDProposal_images/star_left.png';
import StarRight from '../../../assets/images/LDProposal_images/star_right.png';
import Penji from '../../../assets/images/LDProposal_images/penji.jpeg';
import BrightBox from '../../../assets/images/LDProposal_images/brightbox.jpeg';
import PenjiTag from '../../../assets/images/LDProposal_images/penji_tag.png';
import BrightTag from '../../../assets/images/LDProposal_images/brightbox_tag.png';
import OrderMark from '../../../assets/images/LDProposal_images/ordermark.jpeg';
import OrderTag from '../../../assets/images/LDProposal_images/ordermark_tag.png';
import GAds from '../../../assets/images/LDProposal_images/google_ads.png';
import Asana from '../../../assets/images/LDProposal_images/asana.png';
import People from '../../../assets/images/LDProposal_images/expectation.png';
import Speaker from '../../../assets/images/LDProposal_images/speaker.png';
import PPC from '../../../assets/images/LDProposal_images/ppc_tag.png';
import Network from '../../../assets/images/LDProposal_images/networkppc_tag.png';
import Contract from '../../../assets/images/LDProposal_images/contractppc_tag.png';
import SSL from '../../../assets/images/LDProposal_images/spaceshipstar_left.png';
import SSR from '../../../assets/images/LDProposal_images/spaceshipstar_right.png';
import UFO from '../../../assets/images/LDProposal_images/spaceship.png';
import ArrowDown from '../../../assets/images/LDProposal_images/arrow_down.png';
import ArrowCurve from '../../../assets/images/LDProposal_images/arrowcurve.png';
import ThankYou from '../../../assets/images/LDProposal_images/thankyou_star.png';
import Swydo from '../../../assets/images/LDProposal_images/swydo.png';
import Strategy from '../../../assets/images/LDProposal_images/strategy.png';
import Bing from '../../../assets/images/LDProposal_images/paidsearch.png';
import Social from '../../../assets/images/LDProposal_images/paidsocial.png';
import LDGeneratePdf from '../../Common/LDGeneratePdf';
import '../../ClientDashboard/Proposal/./newoverview.css';
import moment from 'moment';
import mappedIndustry from '../../ClientDashboard/Proposal/mappedIndutrsy';
import { checked_icon as checkedIcon } from '../../../assets/icons/icon';
import CHECK from '../../../assets/icons/timeframe_check.svg';
import { socialMediaDesignMonthsCount } from '../../../utils/LDProposalUtils';

const selectedPricing = (amountRange) => {
  if (amountRange.minSpend >= 5000 && amountRange.maxSpend <= 25000) {
    return 'Starter';
  } else if (amountRange.minSpend >= 25000 && amountRange.maxSpend <= 75000) {
    return 'Growth';
  } else if (amountRange.minSpend >= 75000 && amountRange.maxSpend <= 150000) {
    return 'Premier';
  }
};
const baseService = (type, services) => {
  const res = services.find((service) => service.service === type);
  return res?.quantity ? res.quantity : '';
};
const mappedAddons = {
  'Additional Landing Pages': 'Additional Landing Pages',
  'Additional Pop Up': 'Additional Pop Up',
  'Additional Sticky Bar': 'Additional Sticky Bar',
  'Call Rail': 'Call Rail',
  Hotjar: 'HotJar (heatmaps, user recordings)',
};
const totalAddonPrice = (services) => {
  let total = 0;
  services.forEach((service) => {
    total += service.price * parseInt(service.quantity);
  });
  return total;
};

const NewOverview = ({ proposal, timeframeData }) => {
  const publishedDate = (date) => moment(date).format('YYYY-MM-DD');
  return (
    <>
      <div style={{ height: '100%', overflowX: 'hidden' }}>
        <div className='preview_head'>
          <h4>PROPOSAL</h4>
          {/* <h2>LINEAR DESIGN </h2> */}
          <PDFDownloadLink
            document={
              <LDGeneratePdf
                proposal={proposal}
                dateOfProposal={publishedDate(proposal.createdOn)}
                mappedIndustry={mappedIndustry}
                selectedPricing={selectedPricing}
                baseService={baseService}
                mappedAddons={mappedAddons}
                totalAddonPrice={totalAddonPrice}
                timeframeData={timeframeData}
              />
            }
            fileName='proposal.pdf'
          >
            <a className='download_pdf' style={{ cursor: 'pointer' }}></a>
          </PDFDownloadLink>
        </div>
        <div
          style={{
            height: '816px',
            backgroundColor: '#171843',
            position: 'relative',
            overflow: 'hidden',
            // padding: "20%",
            paddingLeft: '214px',
            paddingTop: '214px',
            paddingBottom: '214px',
            paddingRight: '180px',
            marginBottom: '2rem',
          }}
        >
          <img
            src={StarLeft}
            style={{
              height: '401px',
              width: '253px',
              transform: 'rotate(351deg)',
              position: 'absolute',
              left: '-30px',
              top: '422px',
            }}
            alt=''
          />
          <img
            src={StarRight}
            style={{
              height: '205px',
              width: '345px',
              position: 'absolute',
              left: '55rem',
              top: '1rem',
              transform: 'rotate(231deg)',
            }}
            alt=''
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
            }}
          >
            {/* <div
              style={{ position: 'relative', top: '15px', marginRight: '5rem' }}
            >
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: '4rem',
                  color: '#FFFFFF',
                  lineHeight: '60px',
                }}
              >
                linear
              </Typography>
              <b
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '18px',
                  fontWeight: '400',
                  color: '#FFFFFF',
                  marginLeft: '3px',
                }}
              >
                an Aiquire Inc. <br /> ("Pixis") brand
              </b>
            </div>
            <div>
              <p
                style={{
                  height: '431px',
                  backgroundColor: '#FFFFFF',
                  width: '2px',
                }}
              ></p>
            </div> */}
            <div>
              <p
                style={{
                  height: '431px',
                  backgroundColor: '#FFFFFF',
                  width: '2px',
                }}
              ></p>
            </div>
            <div style={{ marginLeft: '5rem' }}>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  color: '#F1494C',
                  lineHeight: '20px',
                  marginBottom: '1rem',
                }}
              >
                <p>
                  <strong>STATEMENT OF WORK (“SOW”) FOR</strong>
                </p>
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: '40px',
                  color: '#FFFFFF',
                  lineHeight: '32px',
                  marginBottom: '8rem',
                }}
              >
                {proposal?.onboardingInfo?.personalInfo?.company}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  color: '#F1494C',
                  lineHeight: '20px',
                }}
              >
                <strong>PREPARED FOR</strong>
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#FFFFFF',
                  lineHeight: '20px',
                  marginBottom: '5rem',
                }}
              >
                <span>
                  {proposal?.onboardingInfo?.personalInfo?.primaryContactName}
                </span>
              </Typography>

              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  color: '#F1494C',
                  lineHeight: '20px',
                }}
              >
                <strong>PREPARED BY</strong>
              </Typography>

              <Typography
                style={{
                  fontFamily: 'Montserrat',

                  fontSize: '14px',
                  color: '#FFFFFF',
                  lineHeight: '20px',
                  fontWeight: '500',
                  marginBottom: '3rem',
                }}
              >
                <span>Luke Heinecke</span>
                <br />
                <span>Mid Market Manager</span>
              </Typography>
              <Box
                sx={{
                  height: '20px',
                  width: '144px',
                  backgroundColor: '#F1494C',
                }}
              >
                <Typography
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: '500',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  {publishedDate(proposal.createdOn)}
                </Typography>
              </Box>
            </div>
          </div>
        </div>
        <div className='cases_studies'>
          <Box
            sx={{
              height: '235px',
              width: '100%',
              backgroundColor: '#171843',
            }}
          >
            <div style={{ marginLeft: '4rem', paddingTop: '6rem' }}>
              <Typography
                style={{
                  textAlign: 'left',
                  fontSize: '17px',
                  fontFamily: 'Montserrat',
                  lineHeight: '23px',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  color: '#F1494C',
                }}
              >
                case studies
              </Typography>

              <Typography
                style={{
                  textAlign: 'left',
                  fontSize: '42px',
                  fontFamily: 'Montserrat',
                  lineHeight: '48px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                }}
              >
                We’ve Done Amazing Work <br /> For Companies Like Yours
              </Typography>
            </div>
          </Box>
          <div style={{ marginLeft: '4rem', marginRight: '6rem' }}>
            <p
              style={{
                textAlign: 'left',
                fontSize: '14px',
                fontFamily: 'Montserrat',
                lineHeight: '20px',
                textTransform: 'none',
                fontWeight: '500',
                marginTop: '2rem',
                marginBottom: '5rem',
              }}
            >
              Here are a few examples for you to check out. You can visit our{' '}
              <a
                href='https://lineardesign.com/case-studies/'
                style={{ textDecoration: 'none', color: '#58baf9' }}
              >
                <strong>case studies</strong>
              </a>{' '}
              page to see more.
            </p>

            {/*industry mapping*/}
            <div
              className='cases'
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '6rem',
              }}
            >
              {mappedIndustry.has(
                proposal?.onboardingInfo?.personalInfo?.industry?.trim()
              ) &&
                mappedIndustry
                  .get(proposal?.onboardingInfo?.personalInfo?.industry.trim())
                  .links.map((link, index) => {
                    return (
                      <div className='penji' key={index}>
                        <div
                          style={{
                            height: '170px',
                            width: '284.906px',
                            position: 'relative',
                          }}
                        >
                          <img
                            src={
                              mappedIndustry.get(
                                proposal?.onboardingInfo?.personalInfo?.industry.trim()
                              ).imgs[index]
                            }
                            style={{
                              height: '170px',
                              width: '284.906px',
                              objectFit: 'cover',
                            }}
                            alt=''
                          />
                          <Box
                            sx={{
                              height: '46px',
                              width: '149.900px',
                              backgroundColor: '#F3F3F3',
                              position: 'absolute',
                              bottom: '9rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              left: '10px',
                            }}
                          >
                            <img
                              src={
                                mappedIndustry.get(
                                  proposal?.onboardingInfo?.personalInfo?.industry.trim()
                                ).logo[index]
                              }
                              style={{
                                height: '33px',
                                width: '60px',
                                objectFit: 'cover',
                              }}
                              alt=''
                            />
                          </Box>
                          <Box
                            sx={{
                              height: '23px',
                              width: '92px',
                              backgroundColor: '#F1484C',
                              position: 'absolute',
                              left: '193px',
                              bottom: '0px',
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: 'Montserrat',
                                fontSize: '17px',
                                lineHeight: '23px',
                                color: '#FFFFFF',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}
                            >
                              <a
                                href={
                                  mappedIndustry.get(
                                    proposal?.onboardingInfo?.personalInfo?.industry.trim()
                                  ).links[index]
                                }
                                style={{ textDecoration: 'none' }}
                                target='_blank'
                              >
                                <span
                                  style={{
                                    fontFamily: 'Montserrat',
                                    fontSize: '17px',
                                    lineHeight: '23px',
                                    color: '#FFFFFF',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  VIEW {'>'}
                                </span>
                              </a>
                            </Typography>
                          </Box>
                        </div>
                        <div
                          style={{
                            height: '112px',
                            width: '278px',
                            marginTop: '2rem',
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: 'Montserrat',
                              fontSize: '12px',
                              lineHeight: '16px',
                              textAlign: 'left',
                              fontWeight: '500',
                            }}
                          >
                            "
                            {
                              mappedIndustry.get(
                                proposal?.onboardingInfo?.personalInfo?.industry.trim()
                              ).testimony[index]
                            }
                            "
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Montserrat',
                              fontSize: '12px',
                              lineHeight: '16px',
                              textAlign: 'left',
                              fontWeight: '500',
                              marginTop: '1rem',
                            }}
                          >
                            {' '}
                            <strong>
                              {
                                mappedIndustry.get(
                                  proposal?.onboardingInfo?.personalInfo?.industry.trim()
                                ).head[index]
                              }
                            </strong>{' '}
                            <br />
                            <span>
                              {
                                mappedIndustry.get(
                                  proposal?.onboardingInfo?.personalInfo?.industry.trim()
                                ).subTitle[index]
                              }
                            </span>{' '}
                          </Typography>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>

        <div
          className='ppc_plan'
          style={{
            marginRight: '6rem',
            marginLeft: '4rem',
            marginBottom: '2rem',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontSize: '17px',
              lineHeight: '23px',
              textAlign: 'left',
              fontWeight: '700',
              color: '#F1484C',
              marginBottom: '2rem',
            }}
          >
            Pay Per Click (PPC) PLAN
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 24,
            }}
          >
            <Box
              sx={{
                height: '610px',
                width: '444.9px',
                backgroundColor: '#F0F9FE',
                paddingLeft: '44px',
                paddingRight: '44px',
                paddingTop: '44px',
                paddingBottom: '33px',
              }}
            >
              <h3
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '26px',
                  lineHeight: '32px',
                  textAlign: 'left',
                  margin: '0',
                }}
              >
                Paid Search: Google Ads
              </h3>
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '1.4',
                }}
              >
                We'll structure your account with the perfect amount of
                granularity using a technique called Specific Intent Campaigns.
                Then we'll link your Google Analytics account and import
                audiences for RLSA (retargeting lists for search ads). We'll
                also add the most relevant interests in observation mode to make
                bid adjustments on the most/least valuable segments. Finally,
                we'll send traffic from these campaigns to landing pages that
                are optimized to convert.
              </p>
              <div
                style={{
                  height: '26px',
                  width: '186px',
                  backgroundColor: '#F1484C',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '14px',
                    color: '#FFFFFF',
                    fontWeight: '500',
                    lineHeight: '20px',
                  }}
                >
                  Campaigns we'll create:
                </span>
              </div>
              <ul className='case_list'>
                <li>
                  <p>
                    <strong>Branded campaign:</strong> bidding on your branded
                    terms to prevent competitors from taking your spot and
                    capture searches generated from brand awareness campaigns.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Competitor campaign:</strong> bidding on your
                    competitor's brand terms to create awareness and steal
                    market share.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Services campaign (US):</strong> bidding on high
                    intent keywords.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Broad experimental campaigns:</strong> Testing RLSA
                    + Broad Match, and DSA campaigns to find new search terms.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Retargeting Display campaign:</strong> Follow people
                    around with banner ads who view key pages or perform micro
                    conversions, but don't convert on the primary offer.
                  </p>
                </li>
              </ul>
            </Box>

            <Box
              sx={{
                height: '610px',
                width: '444.9px',
                backgroundColor: '#DBF0FE',
                paddingLeft: '59px',
                paddingRight: '75px',
                paddingTop: '66px',
                paddingBottom: '77px',
              }}
            >
              <h3
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '26px',
                  lineHeight: '32px',
                  textAlign: 'left',
                  margin: '0',
                }}
              >
                Paid Social
              </h3>

              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '20px',
                }}
              >
                We'll create Facebook Ad campaigns, new audiences, write copy,
                and design creative.
              </p>

              <div
                style={{
                  height: '26px',
                  width: '286px',
                  backgroundColor: '#F1484C',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '14px',
                    color: '#FFFFFF',
                    fontWeight: '500',
                    lineHeight: '20px',
                  }}
                >
                  Additional Strategies & Tactics we'll use:
                </span>
              </div>
              <ul className='strategy'>
                <li>
                  <p>Write new ad copy and design new creative</p>
                </li>
                <li>
                  <p>
                    Test different ad formats (carousel, single image, video,
                    etc.)
                  </p>
                </li>
                <li>
                  <p>Test Lifetime budgets v. Daily budgets</p>
                </li>
                <li>
                  <p>
                    Fix limited custom audiences and create new saved audiences
                    to test against LALs
                  </p>
                </li>
                <li>
                  <p>
                    Create a campaign to drive warmer traffic to collect email
                    addresses for retargeting campaigns
                  </p>
                </li>
                <li>
                  <p>Implement successful retargeting campaigns</p>
                </li>
              </ul>
            </Box>
          </div>
        </div>

        <div className='design'>
          <p
            style={{
              width: '301.9px',
              height: '816px',
              backgroundColor: '#f0f9fe',
              position: 'absolute',
              right: '0px',
              margin: '0px',
            }}
          ></p>
          <div
            style={{
              marginLeft: '4rem',
              marginRight: '6rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: '100px',
                width: '367.9px',
              }}
            >
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '17px',
                  fontWeight: 'bold',
                  lineHeight: '23px',
                  textTransform: 'uppercase',
                  color: '#f1484c',
                }}
              >
                CREATIVE SELECTION & STRATEGY
              </p>

              <h1
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '42px',
                  fontWeight: 'bold',
                  lineHeight: '48px',
                  margin: '0px',
                }}
              >
                We’ll Design Your Ads For Every Platform
              </h1>

              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '20px',
                }}
              >
                Our team of designers will design new ads for all your paid
                social and display campaigns. You’ll be involved with access to
                comment and approve the designs. Check out our{' '}
                <a href='https://dribbble.com/LinearTeam'>
                  {' '}
                  <span style={{ color: '#58baf9' }}>Dribbble</span>
                </a>{' '}
                to see some of the epic ad creative we’ve designed for other
                clients.
              </p>
            </div>
            <div
              style={{
                height: '472px',
                width: '471.906px',
                position: 'relative',
                zIndex: '1',
                top: '5rem',
              }}
            >
              <img
                src={GAds}
                style={{
                  height: '472px',
                  width: '471.906px',
                  objectFit: 'cover',
                }}
                alt=''
              />
            </div>
          </div>
        </div>

        {/* <div
          className='cro_plan'
          style={{
            marginRight: '6rem',
            marginLeft: '4rem',
            marginBottom: '2rem',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontSize: '17px',
              lineHeight: '23px',
              textAlign: 'left',
              fontWeight: '700',
              color: '#F1484C',
              marginBottom: '2rem',
            }}
          >
            Conversion Ration Optimization (CRO) PLAN
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                height: '584px',
                width: '444.9px',
                backgroundColor: '#F0F9FE',
                paddingLeft: '44px',
                paddingRight: '30px',
                paddingTop: '44px',
                paddingBottom: '33px',
              }}
            >
              <h3
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '26px',
                  lineHeight: '32px',
                  textAlign: 'left',
                  margin: '0',
                }}
              >
                Landing Page Design + Optimization
              </h3>
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                We'll create landing pages to match the design and messaging of
                your ads then we'll use A/B testing, multivariate testing, and
                smart traffic to improve conversions exponentially.
              </p>
              <div
                style={{
                  height: '26px',
                  width: '321px',
                  backgroundColor: '#F1484C',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '14px',
                    color: '#FFFFFF',
                    fontWeight: '500',
                    lineHeight: '20px',
                  }}
                >
                  Landing Page strategies & tactics we'll use:
                </span>
              </div>
              <ul className='land_list'>
                <li>
                  <p>
                    Multi-step forms that break up the ﬁelds you’re using and
                    improve conversion rates
                  </p>
                </li>
                <li>
                  <p>
                    High tempo testing to always focus on having a variant
                    running alongside all landing page tests.
                  </p>
                </li>
                <li>
                  <p>
                    Test two-step pages and drive direct conversions after
                    content is downloaded (The Wedge Method)
                  </p>
                </li>
                <li>
                  <p>
                    Review heatmaps, user recordings, and user surveys to
                    constantly improve conversion rates
                  </p>
                </li>
                <li>
                  <p>
                    Dynamic text replacement for personalization and improved
                    message match
                  </p>
                </li>
              </ul>
            </Box>

            <Box
              sx={{
                height: '584px',
                width: '444.9px',
                backgroundColor: '#DBF0FE',
                paddingLeft: '59px',
                paddingRight: '50px',
                paddingTop: '43px',
                paddingBottom: '77px',
              }}
            >
              <h3
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '26px',
                  lineHeight: '32px',
                  textAlign: 'left',
                  margin: '0',
                }}
              >
                Smart <br /> Pop-Ups
              </h3>

              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '20px',
                }}
              >
                Pop-ups can improve site-wide conversions even from non-paid
                traffic sources. We'll design, write, and constantly test time
                and activity-based smart pop-ups to lower bounce rates and boost
                conversions.
              </p>

              <div
                style={{
                  height: '26px',
                  width: '181px',
                  backgroundColor: '#F1484C',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '14px',
                    color: '#FFFFFF',
                    fontWeight: '500',
                    lineHeight: '20px',
                  }}
                >
                  How we'll use pop-ups:
                </span>
              </div>
              <ul className='pop_up'>
                <li>
                  <p>
                    <strong>Scroll depth:</strong> when a visitor scrolls a
                    certain percentage on a page
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Time delay:</strong> when a visitor visits a page
                    for a specific amount of time
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Scroll depth:</strong> when a visitor has been to
                    the page multiple times
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Exit Intent:</strong> before the visitor exits the
                    page
                  </p>
                </li>
              </ul>
            </Box>
          </div>
        </div> */}

        <div className='project_scope'>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontSize: '17px',
              fontWeight: 'bold',
              lineHeight: '23px',
              textTransform: 'uppercase',
              color: '#f1484c',
            }}
          >
            PROJECT SCOPE
          </Typography>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '19px',
              marginTop: '10px',
              marginBottom: '10px',
              color: '#f1484c',
            }}
          >
            TERM:{' '}
            <span
              style={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '19px',

                color: '#0078d4',
              }}
            >
              The SOW and the Terms and Conditions shall be effective upon
              acceptance of this SoW and the Terms and Conditions and shall
              continue to be effective for 6 months
            </span>
          </Typography>
          <h3
            style={{
              fontFamily: 'Montserrat',
              fontSize: '42px',
              fontWeight: 'bold',
              lineHeight: '48px',
              margin: '0',
              marginBottom: '2rem',
            }}
          >
            Core Services
          </h3>
          <div className='serviceTable'>
            <TableContainer sx={{ marginBottom: '2rem' }}>
              <Table>
                <TableHead
                  sx={{
                    width: '100%',
                    backgroundColor: '#58baf9',
                    height: '26px',
                  }}
                >
                  <TableRow>
                    <TableCell
                      style={{
                        width: '31%',
                      }}
                    ></TableCell>
                    {['Starter', 'Growth', 'Premier'].map((addOn, index) => (
                      <TableCell
                        style={{
                          padding: '0',
                          textAlign: 'center',
                          fontFamily: 'Montserrat',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          color: '#FFFFFF',
                          position: 'relative',
                          width: '23%',
                        }}
                        key={index}
                      >
                        {addOn}{' '}
                        {selectedPricing(
                          proposal.sowPricingInfo.clientPricing.adSpendRange
                        ) === addOn && (
                          <img
                            src={checkedIcon}
                            alt='checked'
                            style={{
                              width: 16,
                              height: 16,
                              position: 'absolute',
                              right: 58,
                              top: 7.5,
                            }}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        paddingTop: '9px',
                        paddingBottom: '13px',
                      }}
                    >
                      {' '}
                      <strong>Media Spend Range</strong>{' '}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingTop: '9px',
                        paddingBottom: '13px',
                      }}
                    >
                      5k - 25k
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingTop: '9px',
                        paddingBottom: '13px',
                      }}
                    >
                      25k - 75k
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingTop: '9px',
                        paddingBottom: '13px',
                      }}
                    >
                      75k - 150k
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        paddingBottom: '13px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {' '}
                      <strong>Number of Accounts per Seat</strong>{' '}
                      <a
                        href='https://pixis.ai/seat-sharing-policy'
                        target='_blank'
                        rel='noreferrer'
                        style={{
                          fontFamily: 'Lato',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: 12,
                          color: 'rgba(8, 105, 251, 1)',
                          // textAlign: 'center',
                          textDecoration: 'none',
                        }}
                      >
                        Know More about Seat Sharing Policy
                      </a>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Starter'
                        ? baseService(
                            'Landing Pages',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : 'Share with up to 3 others'} */}
                      Share with up to 3 others
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Growth'
                        ? baseService(
                            'Landing Pages',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : 'Share with up to 2 others'} */}
                      Share with up to 2 others
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Premier'
                        ? baseService(
                            'Landing Pages',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : 'NA'} */}
                      NA
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {' '}
                      <strong>Account Manager Expertise</strong>{' '}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Starter'
                        ? baseService(
                            'Pop-up',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : 'Specialist'} */}
                      Specialist
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Growth'
                        ? baseService(
                            'Pop-up',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : 'Expert'} */}
                      Expert
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Premier'
                        ? baseService(
                            'Pop-up',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : 'Expert'} */}
                      Expert
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {' '}
                      <strong>Support Turn Around</strong>{' '}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Starter'
                        ? baseService(
                            'Sticky Bar',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : '1'} */}
                      2 Business Days
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Growth'
                        ? baseService(
                            'Sticky Bar',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : '1'} */}
                      1 Business Day
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Premier'
                        ? baseService(
                            'Sticky Bar',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : '1-2'} */}
                      6 Working Hour
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {' '}
                      <strong>Online Training Environment</strong>{' '}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Starter'
                        ? baseService(
                            'Sticky Bar',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : '1'} */}
                      Central
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Growth'
                        ? baseService(
                            'Sticky Bar',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : '1'} */}
                      Hybrid
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '500',
                        fontSize: '12px',
                        paddingBottom: '13px',
                      }}
                    >
                      {/* {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Premier'
                        ? baseService(
                            'Sticky Bar',
                            proposal.sowPricingInfo.clientPricing.baseServices
                          )
                        : '1-2'} */}
                      Dedicated
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        display: 'flex',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '12px',
                      }}
                    >
                      {' '}
                      <strong>Monthly Fee</strong>{' '}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '600',
                        fontSize: '12px',
                      }}
                    >
                      {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Starter'
                        ? '$' +
                          (parseInt(
                            proposal.sowPricingInfo.clientPricing
                              .basePricePerMonth
                          ) -
                            parseInt(
                              totalAddonPrice(
                                proposal?.sowPricingInfo?.clientPricing
                                  ?.addonServices
                              )
                            ))
                        : '$4,000'}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        fontSize: '12px',
                      }}
                    >
                      <span
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                        }}
                      >
                        Higher of <br />
                      </span>
                      {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Growth'
                        ? '$' +
                          (parseInt(
                            proposal.sowPricingInfo.clientPricing
                              .basePricePerMonth
                          ) -
                            parseInt(
                              totalAddonPrice(
                                proposal?.sowPricingInfo?.clientPricing
                                  ?.addonServices
                              )
                            ))
                        : // ' or ' +
                          // proposal.sowPricingInfo.clientPricing
                          //   .basePercentPerMonth +
                          // '%'
                          '$5250'}{' '}
                      <span
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                        }}
                      >
                        or
                      </span>{' '}
                      10%
                      <span
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                        }}
                      >
                        {' '}
                        <br /> of Media Spend
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '0',
                        borderBottom: 'none',
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        fontSize: '12px',
                      }}
                    >
                      <span
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                        }}
                      >
                        Higher of <br />
                      </span>
                      {selectedPricing(
                        proposal.sowPricingInfo.clientPricing.adSpendRange
                      ) === 'Premier'
                        ? '$' +
                          (parseInt(
                            proposal.sowPricingInfo.clientPricing
                              .basePricePerMonth
                          ) -
                            parseInt(
                              totalAddonPrice(
                                proposal?.sowPricingInfo?.clientPricing
                                  ?.addonServices
                              )
                            ))
                        : // ' or ' +
                          // proposal.sowPricingInfo.clientPricing
                          //   .basePercentPerMonth +
                          // '%'
                          '$12,500'}{' '}
                      <span
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                        }}
                      >
                        or
                      </span>{' '}
                      10%
                      <span
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                        }}
                      >
                        {' '}
                        <br /> of Media Spend
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/*Optional add ons*/}
            {/* <TableContainer>
              <Table>
                <TableHead sx={{ width: '100%' }}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: '#58baf8',
                        padding: '0',
                        borderBottom: '2px solid #e0e0e0',
                        fontFamily: 'Montserrat',
                        fontWeight: '700',
                        fontSize: '12px',
                        width: '31%',
                      }}
                    >
                      Optional Add-Ons
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#58baf8',
                        borderBottom: '2px solid #e0e0e0',
                        width: '23%',
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        color: '#58baf8',
                        borderBottom: '2px solid #e0e0e0',
                        width: '23%',
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        color: '#58baf8',
                        borderBottom: '2px solid #e0e0e0',
                        width: '23%',
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proposal?.sowPricingInfo?.clientPricing?.addonServices?.map(
                    (addOn, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            color: '#707070',
                            padding: '0',
                            borderBottom: 'none',
                            fontFamily: 'Montserrat',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            paddingTop: '9px',
                          }}
                        >
                          <strong>{mappedAddons[addOn.service]}</strong>{' '}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: 'center',
                            color: '#707070',
                            padding: '0',
                            borderBottom: 'none',
                            fontFamily: 'Montserrat',
                            fontWeight: '500',
                            fontSize: '12px',
                            paddingRight: 0,
                            paddingTop: '9px',
                          }}
                        >
                          {'$' + addOn.price + '/month'}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: 'center',
                            color: '#707070',
                            padding: '0',
                            borderBottom: 'none',
                            fontFamily: 'Montserrat',
                            fontWeight: '500',
                            fontSize: '12px',
                            paddingRight: 0,
                            paddingTop: '9px',
                          }}
                        >
                          {addOn.quantity}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: 'center',
                            color: '#707070',
                            padding: '0',
                            borderBottom: 'none',
                            fontFamily: 'Montserrat',
                            fontWeight: '500',
                            fontSize: '12px',
                            paddingRight: 0,
                            paddingTop: '9px',
                          }}
                        >
                          {'$' +
                            parseInt(addOn.quantity) * addOn.price +
                            '/month'}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer> */}
            {/* <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '4rem',
              }}
            >
              <strong
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  lineHeight: '14px',
                  paddingLeft: '0.7rem',
                }}
              >
                Optional Add-Ons
              </strong>
              <span
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '11px',
                  fontWeight: '500',
                  lineHeight: '14px',
                  paddingRight: '0.8rem',
                }}
              >
                {'$' +
                  '' +
                  totalAddonPrice(
                    proposal?.sowPricingInfo?.clientPricing?.addonServices
                  )}
              </span>
            </div> */}
            <Box
              style={{
                height: '24px',
                width: '100%',
                backgroundColor: '#f1484c',
                marginTop: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              <strong
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  lineHeight: '14px',
                  color: '#ffffff',
                }}
              >
                TOTAL PER MONTH
              </strong>
              <span
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '11px',
                  fontWeight: '500',
                  color: '#ffffff',
                  lineHeight: '14px',
                }}
              >
                ${proposal.sowPricingInfo.clientPricing.basePricePerMonth}
              </span>
            </Box>
          </div>
        </div>

        <div className='project_timeline'>
          <p
            style={{
              width: '301.9px',
              height: '816px',
              backgroundColor: '#f0f9fe',
              position: 'absolute',
              right: '0px',
              margin: '0px',
            }}
          ></p>
          <div
            style={{
              marginLeft: '4rem',
              marginRight: '6rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: '100px',
                width: '367.9px',
              }}
            >
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '17px',
                  fontWeight: 'bold',
                  lineHeight: '23px',
                  textTransform: 'uppercase',
                  color: '#f1484c',
                }}
              >
                PROJECT TIMELINE
              </p>

              <h1
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '42px',
                  fontWeight: 'bold',
                  lineHeight: '48px',
                  margin: '0px',
                }}
              >
                Always Know What Our Team Is Working On
              </h1>

              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '20px',
                }}
              >
                Below you’ll find an outline of what to expect during the first
                few months of working with us. After we kick-off, everything
                will be built into an Asana project so you can see each and
                every task. You’ll know what we’re working on and when it will
                be completed at all times.
              </p>
            </div>
            <div
              style={{
                height: '421px',
                width: '472px',
                position: 'relative',
                zIndex: '1',
                top: '5rem',
              }}
            >
              <img
                src={Asana}
                style={{ height: '421px', width: '472px', objectFit: 'cover' }}
                alt=''
              />
            </div>
          </div>
        </div>

        {/* <div className='timeframe'>
          <div
            style={{
              // height:'1056px',
              width: '100%',
            }}
          >
            <div
              className='time_heading'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '26px',
                  fontWeight: 'bold',
                  lineHeight: '23px',
                }}
              >
                Timeframe
              </Typography>
              <div style={{ display: 'flex', marginRight: '55px' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <p
                    style={{
                      backgroundColor: '#59BAFA',
                      width: '20px',
                      height: '20px',
                    }}
                  ></p>
                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      color: '#222222',
                      lineHeight: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Paid Social
                  </p>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginLeft: '42px',
                  }}
                >
                  <p
                    style={{
                      backgroundColor: '#fad15a',
                      width: '20px',
                      height: '20px',
                    }}
                  ></p>
                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      color: '#222222',
                      lineHeight: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Paid Search
                  </p>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginLeft: '42px',
                  }}
                >
                  <p
                    style={{
                      backgroundColor: '#f1484c',
                      width: '20px',
                      height: '20px',
                    }}
                  ></p>
                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      color: '#222222',
                      lineHeight: '14px',
                      fontWeight: '500',
                    }}
                  >
                    CRO
                  </p>
                </div>
              </div>
            </div>
            <p
              style={{
                borderTop: '3px solid #000000',
                width: '100%',
                margin: '0px',
                marginBottom: '2rem',
              }}
            ></p>
            <div style={{ marginBottom: '2rem' }}>
              <TableContainer>
                <Table sx={{ width: '100%' }}>
                  <TableHead sx={{ width: '100%', backgroundColor: '#e0e0e0' }}>
                    <TableRow>
                      <TableCell style={{ width: '10%' }}></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          width: '65%',
                          padding: '0px',
                          paddingLeft: '26px',
                        }}
                      >
                        Action Items
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          width: '35%',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {timeframeData.name || ''}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          paddingLeft: '5px',
                        }}
                      >
                        Month 1
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                          }}
                        ></p>{' '}
                        Kick-off Call & Strategy Call + Establish Goal
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#FAD15A',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Campaigns Build Out
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    {timeframeData['1'] && (
                      <TableRow>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '31px',
                              width: '26px',
                              margin: '0px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <p
                              style={{
                                height: '11px',
                                width: '11px',
                                backgroundColor: '#F1484C',
                                margin: '0px',
                                textAlign: 'center',
                              }}
                            ></p>{' '}
                          </p>{' '}
                          Conversion Rate Optimisation Designs
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {timeframeData['1']}{' '}
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#58BAF8',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Social Ad Designs
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        up to {
                          socialMediaDesignMonthsCount[timeframeData.name]
                        }{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          paddingLeft: '5px',
                        }}
                      >
                        Month 2
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#58BAF8',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>
                        </p>{' '}
                        Campaign Build Out
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#fad458',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Weekly Paid Search Optimizations
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#ef474a',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Conversion Rate Optimisation Reviews (new tests are
                        data-dependent)
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    {timeframeData['2'] && (
                      <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '31px',
                              width: '26px',
                              margin: '0px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <p
                              style={{
                                height: '11px',
                                width: '11px',
                                backgroundColor: '#F1484C',
                                margin: '0px',
                                textAlign: 'center',
                              }}
                            ></p>{' '}
                          </p>{' '}
                          Conversion Rate Optimisation Designs
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {timeframeData['2']}{' '}
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          paddingLeft: '5px',
                        }}
                      >
                        Month 3
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#58BAF8',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>
                        </p>{' '}
                        Weekly Paid Social Optimization
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#fad458',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Weekly Paid Search Optimizations
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    {timeframeData['3'] && (
                      <TableRow>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '31px',
                              width: '26px',
                              margin: '0px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <p
                              style={{
                                height: '11px',
                                width: '11px',
                                backgroundColor: '#F1484C',
                                margin: '0px',
                                textAlign: 'center',
                              }}
                            ></p>{' '}
                          </p>{' '}
                          Conversion Rate Optimisation Designs
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {timeframeData['3']}{' '}
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#ef474a',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>
                        Conversion Rate Optimisation Reviews (new tests are
                        data-dependent)
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                          }}
                        ></p>{' '}
                        Quaterly Business Review (Review progress & set new
                        goal)
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          paddingLeft: '5px',
                        }}
                      >
                        Month 4
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#58BAF8',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>
                        </p>{' '}
                        Weekly Paid Social Optimization
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#fad458',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Weekly Paid Search Optimizations
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    {timeframeData['4'] && (
                      <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '31px',
                              width: '26px',
                              margin: '0px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <p
                              style={{
                                height: '11px',
                                width: '11px',
                                backgroundColor: '#F1484C',
                                margin: '0px',
                                textAlign: 'center',
                              }}
                            ></p>{' '}
                          </p>{' '}
                          Conversion Rate Optimisation Designs
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {timeframeData['4']}{' '}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#ef474a',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Conversion Rate Optimisation Reviews (new tests are
                        data-dependent)
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#67b8e6',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Social Ad Designs
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        up to {
                          socialMediaDesignMonthsCount[timeframeData.name]
                        }{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          paddingLeft: '5px',
                        }}
                      >
                        Month 5
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#58BAF8',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>
                        </p>{' '}
                        Weekly Paid Social Optimization
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#fad458',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Weekly Paid Search Optimizations
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>
                    {timeframeData['5'] && (
                      <TableRow>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '31px',
                              width: '26px',
                              margin: '0px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <p
                              style={{
                                height: '11px',
                                width: '11px',
                                backgroundColor: '#F1484C',
                                margin: '0px',
                                textAlign: 'center',
                              }}
                            ></p>{' '}
                          </p>{' '}
                          Conversion Rate Optimisation Designs
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {timeframeData['5']}{' '}
                        </TableCell>

                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#ef474a',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Conversion Rate Optimisation Reviews (new tests are
                        data-dependent)
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>

                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          paddingLeft: '5px',
                        }}
                      >
                        Month 6
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#58BAF8',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>
                        </p>{' '}
                        Weekly Paid Social Optimization
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#fad458',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Weekly Paid Search Optimizations
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>

                    {timeframeData['6'] && (
                      <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              height: '31px',
                              width: '26px',
                              margin: '0px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <p
                              style={{
                                height: '11px',
                                width: '11px',
                                backgroundColor: '#F1484C',
                                margin: '0px',
                                textAlign: 'center',
                              }}
                            ></p>{' '}
                          </p>{' '}
                          Conversion Rate Optimisation Designs
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Montserrat',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            lineHeight: '14px',
                            borderBottom: 'none',
                            padding: '0px',
                            textAlign: 'center',
                          }}
                        >
                          {' '}
                          {timeframeData['6']}{' '}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              height: '11px',
                              width: '11px',
                              backgroundColor: '#ef474a',
                              margin: '0px',
                              textAlign: 'center',
                            }}
                          ></p>{' '}
                        </p>{' '}
                        Conversion Rate Optimisation Reviews (new tests are
                        data-dependent)
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>

                    </TableRow>

                    <TableRow style={{ backgroundColor: '#f3f3f5' }}>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <p
                          style={{
                            height: '31px',
                            width: '26px',
                            margin: '0px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                        </p>{' '}
                        Quaterly Business Reveiw + Renewal Options
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          lineHeight: '14px',
                          borderBottom: 'none',
                          padding: '0px',
                          textAlign: 'center',
                        }}
                      >
                        {' '}
                        <img
                          src={CHECK}
                          style={{ height: '14px', width: '11px' }}
                          alt=''
                        />{' '}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div> */}

        <div className='client_expectation'>
          <div
            style={{
              widht: '100%',
              height: '315px',
              backgroundColor: '#f0f9fe',
              paddingLeft: '4rem',
              paddingTop: '4rem',
              marginBottom: '2rem',
            }}
          >
            <div>
              <div
                style={{
                  height: '100px',
                  width: '577px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '17px',
                    fontWeight: 'bold',
                    lineHeight: '23px',
                    textTransform: 'uppercase',
                    color: '#f1484c',
                  }}
                >
                  CLIENT EXPECTATIONS
                </p>

                <h1
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '42px',
                    fontWeight: 'bold',
                    lineHeight: '48px',
                    margin: '0px',
                  }}
                >
                  What we expect from you
                </h1>

                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '20px',
                  }}
                >
                  We're super excited to kick some major butt together! We've
                  worked with tons of amazing companies like yours, and learned
                  that to get the most out of our relationship, a few
                  expectations and understandings need to be aligned. We hope
                  that the points below make as much sense to you as they do to
                  us - because we're promising everything back to you as well.
                </p>
              </div>
            </div>
          </div>
          <img
            src={People}
            style={{
              height: '816px',
              width: '349px',
              position: 'absolute',
              top: '0px',
              right: '0px',
            }}
            alt=''
          />

          <div style={{ marginLeft: '4rem' }}>
            <div style={{ width: '579.9px' }}>
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    fontWeight: 'bold',
                    color: '#f1484c',
                    lineHeight: '32px',
                    marginTop: '8px',
                    marginBottom: '0px',
                  }}
                >
                  1
                </p>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    fontWeight: '500',
                    marginLeft: '40px',
                    lineHeight: '14px',
                  }}
                >
                  You agree that it's your responsibility to be brutally honest
                  with us (don't be silent). We don't know how we're doing
                  unless we know the good and the bad, be it reporting or
                  results.
                </p>
              </div>
              <p
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#d6dfe0',
                  margin: '0px',
                }}
              ></p>
            </div>

            <div style={{ width: '579.9px' }}>
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    fontWeight: 'bold',
                    color: '#f1484c',
                    lineHeight: '32px',
                    marginTop: '8px',
                    marginBottom: '0px',
                  }}
                >
                  2
                </p>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    fontWeight: '500',
                    marginLeft: '40px',
                    lineHeight: '14px',
                  }}
                >
                  You agree to respond to our emails in a timely manner. We’re
                  working to grow your business as fast as we can, but we’ll
                  need you to be responsive.
                </p>
              </div>
              <p
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#d6dfe0',
                  margin: '0px',
                }}
              ></p>
            </div>

            <div style={{ width: '579.9px' }}>
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    fontWeight: 'bold',
                    color: '#f1484c',
                    lineHeight: '32px',
                    marginTop: '8px',
                    marginBottom: '0px',
                  }}
                >
                  3
                </p>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    fontWeight: '500',
                    marginLeft: '40px',
                    lineHeight: '14px',
                  }}
                >
                  All goals should be reasonable and realistic. Once we agree on
                  a goal you won’t change it until our quarterly business
                  review. Achieving results in paid search and conversion rate
                  optimization takes time.
                </p>
              </div>
              <p
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#d6dfe0',
                  margin: '0px',
                }}
              ></p>
            </div>

            <div style={{ width: '579.9px' }}>
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    fontWeight: 'bold',
                    color: '#f1484c',
                    lineHeight: '32px',
                    marginTop: '8px',
                    marginBottom: '0px',
                  }}
                >
                  4
                </p>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    fontWeight: '500',
                    marginLeft: '40px',
                    lineHeight: '14px',
                  }}
                >
                  You’ll do your best to keep email communications concise and
                  to the point. Longer conversations should be scheduled as
                  meetings instead of novels.
                </p>
              </div>
              <p
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#d6dfe0',
                  margin: '0px',
                }}
              ></p>
            </div>

            <div style={{ width: '579.9px' }}>
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    fontWeight: 'bold',
                    color: '#f1484c',
                    lineHeight: '32px',
                    marginTop: '8px',
                    marginBottom: '0px',
                  }}
                >
                  5
                </p>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '11px',
                    fontWeight: '500',
                    marginLeft: '40px',
                    lineHeight: '14px',
                  }}
                >
                  You agree to complete our weekly and monthly client
                  satisfaction surveys providing honest and direct feedback so
                  that our team can improve.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='referal'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                backgroundColor: '#f0f9fe',
                height: '816px',
                width: '466.9px',
                paddingLeft: '4rem',
                paddingTop: '4.75rem',
                paddingRight: '5.75rem',
              }}
            >
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '17px',
                  fontWeight: 'bold',
                  lineHeight: '23px',
                  textTransform: 'uppercase',
                  color: '#f1484c',
                }}
              >
                CUSTOMER REFERRAL PROGRAM
              </p>
              <h1
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '42px',
                  fontWeight: 'bold',
                  lineHeight: '48px',
                  margin: '0px',
                  marginBottom: '4.56rem',
                }}
              >
                Refer a colleague and earn 10% of their monthly contract as a
                discount off your monthly contract
              </h1>

              <Box
                sx={{
                  height: '121px',
                  width: '265.9px',
                  border: '2px solid #000000',
                  marginLeft: '2rem',
                  paddingBottom: '35px',
                  paddingLeft: '35px',
                  paddingTop: '25px',
                }}
              >
                <Typography
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '20px',
                    paddingLeft: '19px',
                  }}
                >
                  There's no limit. <br />
                  Refer enough people & we'll start paying you!
                </Typography>
              </Box>
              <div
                style={{
                  height: '76px',
                  width: '82.9px',
                  backgroundColor: '#f0f9fd',
                  position: 'absolute',
                  bottom: '129px',
                  left: '54px',
                }}
              >
                <img
                  src={Speaker}
                  style={{
                    height: '57px',
                    width: '82.9px',
                    objectFit: 'cover',
                  }}
                  alt=''
                />
              </div>
            </div>

            <div>
              <div
                style={{
                  height: '816px',

                  paddingLeft: '3.5rem',
                  paddingTop: '4.75rem',
                  paddingRight: '3.85rem',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    fontWeight: '700',
                    lineHeight: '32px',
                    margin: '0',
                    marginTop: '10px',
                    marginBottom: '2.6rem',
                  }}
                >
                  How It Works
                </p>
                <Box
                  sx={{
                    height: '86px',
                    width: '425.9px',
                    border: '2px solid #000000',
                    marginLeft: '2rem',
                    marginBottom: '3rem',
                  }}
                >
                  <div
                    style={{
                      height: '65px',
                      backgroundColor: '#FFFFFF',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: '529px',
                      top: '171px',
                    }}
                  >
                    <img
                      src={PPC}
                      style={{
                        height: '52px',
                        widht: '59.906px',
                        objectFit: 'cover',
                      }}
                      alt=''
                    />
                  </div>
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '20px',
                      paddingLeft: '60px',
                      marginTop: '22px',
                    }}
                  >
                    You have an active PPC campaign with <br /> Linear that
                    costs $5,000 per month
                  </Typography>
                  <img
                    src={ArrowDown}
                    style={{
                      height: '30px',
                      width: '30px',
                      objectFit: 'cover',
                      position: 'absolute',
                      top: '255px',
                      left: '744px',
                    }}
                    alt=''
                  />
                </Box>

                <Box
                  sx={{
                    height: '86px',
                    width: '425.9px',
                    border: '2px solid #000000',
                    marginLeft: '2rem',
                    marginBottom: '3rem',
                  }}
                >
                  <div
                    style={{
                      height: '65px',
                      backgroundColor: '#FFFFFF',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: '529px',
                      top: '304px',
                    }}
                  >
                    <img
                      src={Network}
                      style={{
                        height: '52px',
                        widht: '59.906px',
                        objectFit: 'cover',
                      }}
                      alt=''
                    />
                  </div>
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '20px',
                      paddingLeft: '60px',
                      marginTop: '22px',
                    }}
                  >
                    Someone in your network needs PPC <br /> services and you
                    refer them to Linear
                  </Typography>
                  <img
                    src={ArrowDown}
                    style={{
                      height: '30px',
                      width: '30px',
                      objectFit: 'cover',
                      position: 'absolute',
                      top: '390px',
                      left: '744px',
                    }}
                    alt=''
                  />
                </Box>

                <Box
                  sx={{
                    height: '86px',
                    width: '425.9px',
                    border: '2px solid #000000',
                    marginLeft: '2rem',
                    marginBottom: '5rem',
                  }}
                >
                  <div
                    style={{
                      height: '65px',
                      backgroundColor: '#FFFFFF',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      left: '529px',
                      top: '438px',
                    }}
                  >
                    <img
                      src={Contract}
                      style={{
                        height: '52px',
                        widht: '59.906px',
                        objectFit: 'cover',
                      }}
                      alt=''
                    />
                  </div>
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '20px',
                      paddingLeft: '60px',
                      marginTop: '22px',
                    }}
                  >
                    Your referral signs a new contract for <br /> PPC Services
                    at $5,000 per month
                  </Typography>
                </Box>
              </div>
              <div
                style={{
                  height: '226px',
                  width: '650px',
                  backgroundColor: '#151552',
                  position: 'absolute',
                  bottom: '0px',
                }}
              >
                <img
                  src={ArrowCurve}
                  style={{
                    height: '120px',
                    width: '119.9px',
                    objectFit: 'cover',
                    transform: 'rotate(45deg)',
                    position: 'absolute',
                    top: '-79px',
                    left: '207px',
                  }}
                  alt=''
                />

                <img
                  src={SSL}
                  style={{
                    height: '137px',
                    width: '187.9px',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                  }}
                  alt=''
                />
                <img
                  src={SSR}
                  style={{
                    height: '50px',
                    width: '41.9px',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '40px',
                    right: '35px',
                  }}
                  alt=''
                />
                <img
                  src={UFO}
                  style={{
                    height: '193px',
                    width: '221.9px',
                    objectFit: 'cover',
                    position: 'absolute',
                    bottom: '0px',
                    right: '0px',
                  }}
                  alt=''
                />
                <div
                  style={{
                    height: '96px',
                    width: '346px',
                    position: 'absolute',
                    top: '75px',
                    left: '59px',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '26px',
                      fontWeight: '600',
                      lineHeight: '32px',
                      color: '#FFFFFF',
                    }}
                  >
                    You get a Referral Credit and your PPC is now only $4,500
                    per month!
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='thankyou_pg'>
          <img
            src={ThankYou}
            style={{
              height: '316px',
              width: '530px',
              objectFit: 'cover',
              transform: 'rotate(231deg)',
              position: 'absolute',
              top: '20px',
              right: '-110px',
            }}
            alt=''
          />
          <div>
            {/* <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                fontSize: '4rem',
                color: '#FFFFFF',
                lineHeight: '60px',
              }}
            >
              linear
            </Typography>
            <b
              style={{
                fontFamily: 'Montserrat',
                fontSize: '18px',
                fontWeight: '400',
                color: '#FFFFFF',
                marginLeft: '3px',
              }}
            >
              an Aiquire Inc. ("Pixis") brand
            </b> */}

            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: '500',
                fontSize: '26px',
                color: '#FFFFFF',
                lineHeight: '32px',
                marginTop: '280px',
                marginBottom: '4rem',
              }}
            >
              We look forward to <br /> working with you.
            </Typography>

            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: '500',
                fontSize: '14px',
                color: '#FFFFFF',
                lineHeight: '20px',
                marginBottom: '1rem',
              }}
            >
              <span>Luke Heinecke</span> <br />
              <span>Mid Market Manager</span> <br />
              {/* <span style={{ marginBottom: '2rem' }}>
                luke@lineardesign.com
              </span> */}
            </Typography>

            <Typography
              style={{
                fontFamily: 'Montserrat',
                fontWeight: '500',
                fontSize: '14px',
                color: '#FFFFFF',
                lineHeight: '20px',
                marginBottom: '3rem',
              }}
            >
              <span>625 W Adams St Chicago, IL 60661 USA</span>
            </Typography>

            {/* <Box
              sx={{
                height: '20px',
                width: '154px',
                backgroundColor: '#f1484c',
                textAlign: 'center',
              }}
            >
              <a
                style={{ textDecoration: 'none' }}
                href='https://lineardesign.com/'
              >
                {' '}
                <span
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#FFFFFF',
                    lineHeight: '20px',
                  }}
                >
                  lineardesign.com
                </span>
              </a>
            </Box> */}
          </div>
        </div>

        <div className='customized_report'>
          <div
            style={{
              height: '760px',
              width: '301.9px',
              backgroundColor: '#f0f9fe',
              paddingLeft: '2rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={Swydo}
              style={{ height: '471px', width: '470.9px', objectFit: 'cover' }}
              alt=''
            />
          </div>
          <div style={{ height: '243px', width: '456px', marginLeft: '17rem' }}>
            <p
              style={{
                fontFamily: 'Montserrat',
                fontSize: '17px',
                fontWeight: 'bold',
                lineHeight: '23px',
                textTransform: 'uppercase',
                color: '#f1484c',
              }}
            >
              CUSTOMIZED REPORTING
            </p>

            <h1
              style={{
                fontFamily: 'Montserrat',
                fontSize: '42px',
                fontWeight: 'bold',
                lineHeight: '48px',
                margin: '0px',
              }}
            >
              Custom Reporting, Transparent Results
            </h1>

            <p
              style={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '20px',
              }}
            >
              You'll stay up-to-date on the progress we make towards your goal
              with weekly updates, bi-weekly calls, and an in-depth report
              delivered every month. We'll also create and share a dashboard
              showing you the most important performance metrics, on the fly.
            </p>
          </div>
        </div>

        {/* <div className='paid_search'>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontSize: '17px',
              fontWeight: '700',
              lineHeight: '23px',
              color: '#f1484c',
            }}
          >
            PAID SEARCH PLAN
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                height: '575px',
                width: '591.9px',
                backgroundColor: '#f0f9fe',
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={Strategy}
                style={{
                  height: '448px',
                  width: '478.8px',
                  objectFit: 'cover',
                  position: 'absolute',
                  left: '120px',
                  bottom: '155px',
                }}
                alt=''
              />
              <div style={{ marginLeft: '14rem', width: '350px' }}>
                <h3
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    lineHeight: '32px',
                    textAlign: 'left',
                    margin: '0',
                  }}
                >
                  Landing Page Design + Optimization
                </h3>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  We'll create landing pages to match the design and messaging
                  of your ads then we'll use A/B testing, multivariate testing,
                  and smart traffic to improve conversions exponentially.
                </p>
                <div
                  style={{
                    height: '26px',
                    width: '321px',
                    backgroundColor: '#F1484C',
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#FFFFFF',
                      fontWeight: '500',
                      lineHeight: '20px',
                    }}
                  >
                    Landing Page strategies & tactics we'll use:
                  </span>
                </div>
                <ul className='land_list2'>
                  <li>
                    <p>
                      Multi-step forms that break up the ﬁelds you’re using and
                      improve conversion rates
                    </p>
                  </li>
                  <li>
                    <p>
                      High tempo testing to always focus on having a variant
                      running alongside all landing page tests.
                    </p>
                  </li>
                  <li>
                    <p>
                      Test two-step pages and drive direct conversions after
                      content is downloaded (The Wedge Method)
                    </p>
                  </li>
                  <li>
                    <p>
                      Review heatmaps, user recordings, and user surveys to
                      constantly improve conversion rates
                    </p>
                  </li>
                  <li>
                    <p>
                      Dynamic text replacement for personalization and improved
                      message match
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className='paid_search'>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontSize: '17px',
              fontWeight: '700',
              lineHeight: '23px',
              color: '#f1484c',
            }}
          >
            PAID SEARCH PLAN
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                height: '575px',
                width: '591.9px',
                backgroundColor: '#f0f9fe',
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={GAds}
                style={{
                  height: '472px',
                  width: '478.8px',
                  objectFit: 'cover',
                  position: 'absolute',
                  left: '105px',
                }}
                alt=''
              />
              <div style={{ marginLeft: '13.5rem', width: '350px' }}>
                <h3
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    lineHeight: '32px',
                    textAlign: 'left',
                    margin: '0',
                  }}
                >
                  Smart <br />
                  Pop-Ups
                </h3>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: 1.4,
                  }}
                >
                  Pop-ups can improve site-wide conversions even from non-paid
                  traffic sources. We'll design, write, and constantly test time
                  and activity-based smart pop-ups to lower bounce rates and
                  boost conversions.
                </p>
                <div
                  style={{
                    height: '26px',
                    width: '175px',
                    backgroundColor: '#F1484C',
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#FFFFFF',
                      fontWeight: '500',
                      lineHeight: '20px',
                    }}
                  >
                    How we'll use pop-ups:
                  </span>
                </div>
                <ul className='pop_up2'>
                  <li>
                    <p>
                      <strong>Scroll depth:</strong> when a visitor scrolls a
                      certain percentage on a page
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Time delay:</strong> when a visitor visits a page
                      for a specific amount of time
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Scroll depth:</strong> when a visitor has been to
                      the page multiple times
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Exit Intent:</strong> before the visitor exits the
                      page
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}

        <div className='paid_search'>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontSize: '17px',
              fontWeight: '700',
              lineHeight: '23px',
              color: '#f1484c',
            }}
          >
            PAID SEARCH PLAN
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                height: '575px',
                width: '591.9px',
                backgroundColor: '#f0f9fe',
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={Bing}
                style={{
                  height: '474px',
                  width: '471.89x',
                  objectFit: 'cover',
                  position: 'absolute',
                  left: '105px',
                }}
                alt=''
              />
              <div style={{ marginLeft: '13.5rem', width: '350px' }}>
                <h3
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    lineHeight: '32px',
                    textAlign: 'left',
                    margin: '0',
                  }}
                >
                  Paid Search: Google Ads
                </h3>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: 1.4,
                  }}
                >
                  We'll structure your account with the perfect amount of
                  granularity using a technique called Specific Intent
                  Campaigns. Then we'll link your Google Analytics account and
                  import audiences for RLSA (retargeting lists for search ads).
                  We'll also add the most relevant interests in observation mode
                  to make bid adjustments on the most/least valuable segments.
                  Finally, we'll send traffic from these campaigns to landing
                  pages that are optimized to convert.
                </p>
                <div
                  style={{
                    height: '26px',
                    width: '175px',
                    backgroundColor: '#F1484C',
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#FFFFFF',
                      fontWeight: '500',
                      lineHeight: '20px',
                    }}
                  >
                    Campaigns we'll create:
                  </span>
                </div>
                <ul className='pop_up3'>
                  <li>
                    <p>
                      <strong>Branded campaign:</strong> bidding on your branded
                      terms to prevent competitors from taking your spot and
                      capture searches generated from brand awareness campaigns.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Competitor campaign:</strong> bidding on your
                      competitor's brand terms to create awareness and steal
                      market share.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Services campaign (US):</strong> bidding on high
                      intent keywords.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Broad experimental campaigns:</strong> Testing
                      RLSA + Broad Match, and DSA campaigns to find new search
                      terms.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Retargeting Display campaign:</strong> Follow
                      people around with banner ads who view key pages or
                      perform micro conversions, but don't convert on the
                      primary offer.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className='paid_search'>
          <Typography
            style={{
              fontFamily: 'Montserrat',
              fontSize: '17px',
              fontWeight: '700',
              lineHeight: '23px',
              color: '#f1484c',
            }}
          >
            PAID SEARCH PLAN
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                height: '575px',
                width: '591.9px',
                backgroundColor: '#f0f9fe',
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={Social}
                style={{
                  height: '472px',
                  width: '471.9px',
                  objectFit: 'cover',
                  position: 'absolute',
                  left: '105px',
                }}
                alt=''
              />
              <div style={{ marginLeft: '13.5rem', width: '350px' }}>
                <h3
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '26px',
                    lineHeight: '32px',
                    textAlign: 'left',
                    margin: '0',
                    marginBottom: '3rem',
                  }}
                >
                  Paid Social
                </h3>
                <p
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: 1.4,
                    marginBottom: '3rem',
                  }}
                >
                  We'll create Facebook Ad campaigns, new audiences, write copy,
                  and design creative.
                </p>
                <div
                  style={{
                    height: '26px',
                    width: '321px',
                    backgroundColor: '#F1484C',
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      color: '#FFFFFF',
                      fontWeight: '500',
                      lineHeight: '20px',
                    }}
                  >
                    Additional Strategies & Tactics we'll use:
                  </span>
                </div>
                <ul className='land_list2'>
                  <li>
                    <p>Write new ad copy and design new creative</p>
                  </li>
                  <li>
                    <p>
                      Test different ad formats (carousel, single image, video,
                      etc.)
                    </p>
                  </li>
                  <li>
                    <p>Test Lifetime budgets v. Daily budgets</p>
                  </li>
                  <li>
                    <p>
                      Create a campaign to drive warmer traffic to collect email
                      addresses for retargeting campaigns
                    </p>
                  </li>
                  <li>
                    <p>Implement successful retargeting campaigns</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='terms_condition'>
          <div
            style={{
              height: '160px',
              width: '100%',
              background: '#F2F9FE',
              paddingLeft: '76px',
              paddingTop: '73px',
              paddingBottom: '73px',
            }}
          >
            {/* <Typography style={{fontFamily:'Inter',fontSize:'20px',fontWeight:'700',textTransform:'uppercase',lineHeight:'24.2px',color:'#DF5653'}}>TERMS AND CONDITIONS</Typography> */}
          </div>
          <div
            style={{
              paddingTop: '35px',
              paddingLeft: '75px',
              paddingRight: '76px',
            }}
          >
            <div style={{ display: 'flex', marginBottom: '40px' }}>
              <span
                style={{
                  fontFamily: 'Inter',
                  fontSize: '35px',
                  fontWeight: '700',
                  color: '#DF5653',
                  lineHeight: '42.36px',
                }}
              >
                1
              </span>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: '500',
                  lineHeight: '27.72px',
                  marginLeft: '33px',
                }}
              >
                Please make sure you’ve read and understood the SOW. It’s really
                important to us that <br /> everything is transparent and
                understood from the beginning so that we lay a solid foundation{' '}
                <br /> for a great working relationship. The services set out in
                this SOW are being provided by Aiquire <br /> Inc, which inter
                alia operates the brand name Linear Design.
              </Typography>
            </div>

            <div style={{ display: 'flex', marginBottom: '40px' }}>
              <span
                style={{
                  fontFamily: 'Inter',
                  fontSize: '35px',
                  fontWeight: '700',
                  color: '#DF5653',
                  lineHeight: '42.36px',
                }}
              >
                2
              </span>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: '500',
                  lineHeight: '27.72px',
                  marginLeft: '33px',
                }}
              >
                This SOW is subject to and governed by the Terms and Conditions
                (“T&C’s”). Any capitalized <br /> term which is not defined
                under this SOW shall have the meaning assigned to it in the
                T&C’s, <br /> which is linked below. This SOW and the T&C’s
                together constitute a binding non- exclusive <br /> agreement
                (“Agreement”). Upon clicking on “I Agree” below, you indicate
                that you find the <br /> services and the proposed commercials
                set out herein acceptable and agree to be bound by <br /> this
                SOW, the Terms and Conditions and any other documents that this
                Agreement <br /> incorporates by reference or attachment.
              </Typography>
            </div>

            <div style={{ display: 'flex', marginBottom: '40px' }}>
              <span
                style={{
                  fontFamily: 'Inter',
                  fontSize: '35px',
                  fontWeight: '700',
                  color: '#DF5653',
                  lineHeight: '42.36px',
                }}
              >
                3
              </span>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: '500',
                  lineHeight: '27.72px',
                  marginLeft: '33px',
                }}
              >
                If you have any questions at all, please let us know. We’re
                happy to clarify any points and there <br /> may be some items
                that we can sort out together. We’re committed to finding the
                best way to <br /> work together.
              </Typography>
            </div>

            <div style={{ display: 'flex', marginBottom: '40px' }}>
              <span
                style={{
                  fontFamily: 'Inter',
                  fontSize: '35px',
                  fontWeight: '700',
                  color: '#DF5653',
                  lineHeight: '42.36px',
                }}
              >
                4
              </span>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: '500',
                  lineHeight: '27.72px',
                  marginLeft: '33px',
                }}
              >
                We’ll email you a separate copy of the signed contract for your
                records.
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: 67,
          backgroundColor: 'rgba(169, 169, 169,0.5)',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          paddingLeft: 54,
        }}
      >
        {/* <Typography
          style={{
            fontSize: 30,
            fontFamily: 'Montserrat',
            fontWeight: 600,
          }}
        >
          linear
        </Typography>
        <Typography
          style={{
            fontSize: 30,
            fontFamily: 'Montserrat',
            fontWeight: 300,
          }}
        >
          |
        </Typography> */}
        <Typography
          style={{
            fontSize: 13,
            fontFamily: 'Montserrat',
            fontWeight: 500,
            paddingTop: 2.5,
          }}
        >
          SOW for {proposal?.onboardingInfo?.personalInfo?.company}
        </Typography>
      </div>
    </>
  );
};
export default NewOverview;
