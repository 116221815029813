import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect, useMemo } from 'react';
// import readIcon from '../../../assets/icons/creative/read-icon.svg';
// import writeIcon from '../../../assets/icons/creative/write-icon.svg';
// import trashIcon from '../../../assets/icons/creative/trash-icon.svg';
// import selectedReadIcon from '../../../assets/icons/creative/selected-read-icon.svg';
// import selectedWriteIcon from '../../../assets/icons/creative/selected-write-icon.svg';
// import facebookIcon from '../../../assets/icons/creative/facebook-icon.svg';
import { 
    facebookIcon, 
    readIcon,
    writeIcon,
    trashIcon,
    selectedReadIcon,
    selectedWriteIcon,
    approvalIcon,
    disapprovedIcon,
    crossIcon
} from '../../../assets/icons/creative/creative';
import DeletionConfirmationModal from '../DeletionConfirmationModal';
import image from './image 20.png';
import '../ADCopies/headline.css';
// import approvalIcon from '../../../assets/icons/creative/approved-icon.svg';
// import disapprovedIcon from '../../../assets/icons/creative/disapproved-icon.svg';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { uploadFile } from '../../../api/fileHandling/saga';
import { CREATIVE_OBJECT_STATUS_ENUM } from '../../../api/creativeApproval/enums';
// import crossIcon from '../../../assets/icons/creative/cross-icon-creative.svg';
const DisplayImage = ({
  id,
  index,
  read,
  status,
  isClient,
  campaignName,
  onChange,
  onRemove,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 12,
          lineHeight: '15px',
          textAlign: 'left',
          padding: '5px 10px',
          background: '#EEF4FF',
          border: '0.868595px solid #F0F0F0',
          borderRadius: '6.94876px 6.94876px 0px 0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!read ? (
          <div
            style={{
              position: 'relative',
            }}
          >
            <TextField
              variant='standard'
              value={campaignName}
              disabled={read}
              inputProps={{
                style: {
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12.1603,
                  lineHeight: '15px',
                  width: 252,
                  paddingBottom: 4,
                  border: 0,
                },
              }}
              onChange={(e) => onChange(index, e.target.value)}
            />
        { isClient && status === CREATIVE_OBJECT_STATUS_ENUM.PENDING &&
            <div
              className='trash-icon'
              style={{
                width: 20,
                height: 20,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff3f3',
                position: 'absolute',
                top: -12,
                right: -18,
                zIndex: 100,
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              onClick={() => onRemove(index)}
            >
              <img src={crossIcon} alt='' width={14} height={14} />
            </div>
        }
            { !isClient  &&
            <div
              className='trash-icon'
              style={{
                width: 20,
                height: 20,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff3f3',
                position: 'absolute',
                top: -12,
                right: -18,
                zIndex: 100,
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              onClick={() => onRemove(index)}
            >
              <img src={crossIcon} alt='' width={14} height={14} />
            </div>
            }
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 12,
                lineHeight: '15px',
                textAlign: 'center',
                padding: '4px 0',
                background: '#EEF4FF',
                border: '0.868595px solid #F0F0F0',
                borderRadius: '6.94876px 6.94876px 0px 0px',
                width: 252,
                color: '#333333',
              }}
            >
              {campaignName}
            </Typography>
        { isClient && status === CREATIVE_OBJECT_STATUS_ENUM.PENDING &&
            <>
            <div
              className='trash-icon'
              style={{
                width: 20,
                height: 20,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff3f3',
                position: 'absolute',
                top: -12,
                right: -18,
                zIndex: 100,
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              onClick={() => onRemove(index)}
            >
              <img src={crossIcon} alt='' width={14} height={14} />
            </div>
            </>
        }
        { !isClient  &&
        <div
          className='trash-icon'
          style={{
            width: 20,
            height: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff3f3',
            position: 'absolute',
            top: -12,
            right: -18,
            zIndex: 100,
            borderRadius: '50%',
            cursor: 'pointer',
          }}
          onClick={() => onRemove(index)}
        >
          <img src={crossIcon} alt='' width={14} height={14} />
        </div>
        }
          </div>
        )}
      </div>
      <div
        style={{
          height: 303,
        }}
      >
        <img
          src={process.env.REACT_APP_BACKEND_BASE_IMAGE_URL + '/' + id}
          alt='pic'
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
    </div>
  );
};

const ExpandIcon = () => {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.0387 14.8495L17.8779 8.97756C18.0759 8.7779 18.2012 8.536 18.2393 8.27993C18.2774 8.02385 18.2267 7.76412 18.0931 7.53088C17.9594 7.29764 17.7484 7.10047 17.4844 6.96225C17.2204 6.82402 16.9144 6.75043 16.6017 6.75H4.92331C4.61069 6.75043 4.30462 6.82402 4.04065 6.96225C3.77668 7.10047 3.56563 7.29764 3.43201 7.53088C3.29838 7.76412 3.24766 8.02385 3.28574 8.27993C3.32382 8.536 3.44913 8.7779 3.64713 8.97756L9.48635 14.8495C9.64138 15.005 9.83622 15.1303 10.0569 15.2163C10.2775 15.3022 10.5185 15.3468 10.7625 15.3468C11.0065 15.3468 11.2475 15.3022 11.4682 15.2163C11.6888 15.1303 11.8837 15.005 12.0387 14.8495Z'
        fill='black'
      />
    </svg>
  );
};
const FacebookCreative = (props) => {
  const [id, setId] = useState(null);
  const [theme, setTheme] = useState(null);
  const [campaignName, setCampaignName] = useState(null);
  const [creativeImagess, setCreativeImagess] = useState([]);
  const [expand, setExpand] = useState(props.expand);
  const [read, isRead] = useState(true);
  const [deleteCampaign, setDeleteCampaign] = useState(false);
  const [showError, setShowError] = useState(false);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': [],
    },
  });

  const textFieldEmpty = Boolean(!theme || !campaignName || !creativeImagess.length);

  const toggleAccordian = () => {
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    setId(props.id);
    isRead(props.read);
    setTheme(props.theme);
    setExpand(props.expand);
    setCampaignName(props.campaignName);
    setCreativeImagess([...props.creativeImagess]);
  }, [JSON.stringify(props)]);

  useEffect(() => {
    let files = [];
    if (acceptedFiles.length !== 0) {
      acceptedFiles.map((file) => {
        let formData = new FormData();
        formData.append('file', file);
        files.push(uploadFile(formData));
      });
     Promise.all(files).then(res => {
         const newFiles = acceptedFiles.map((file, index) =>
             ({id: res[index].fileId, name: file.name})
         );
         setCreativeImagess([
             ...newFiles,
             ...creativeImagess
         ]);
     })
    }
  }, [acceptedFiles]);

  const onChangeCampaign = (index, value) => {
    setShowError(false);
    const newCreativeImagess = creativeImagess.slice();
    newCreativeImagess[index] = { ...newCreativeImagess[index], name: value };
    setCreativeImagess(newCreativeImagess);
  };

  const onRemoveCampaign = (index) => {
    const newCreativeImagess = creativeImagess.slice();
    newCreativeImagess.splice(index, 1);
    setCreativeImagess(newCreativeImagess);
  };

  // udpate creatives content
  const saveHandler = () => {
    if (textFieldEmpty && !props.isClient) {
        setShowError(true);
        return;
    };
    props.onSaveHandler({
      id,
      theme,
      campaignName,
      creativeImagess,
    });
    isRead(true);
    setExpand(false);
  };

  const approveHandler = () => {
    if (textFieldEmpty && !props.isClient) {
        setShowError(true);
        return;
    };
    props.onApproveHandler({
        id,
        theme,
        campaignName,
        creativeImagess
    });
  };

  const disapproveHandler = () => {
    if (textFieldEmpty && !props.isClient) {
        setShowError(true);
        return;
    };
    props.onDisapproveHandler({
        id,
        theme,
        campaignName,
        creativeImagess
    });
  };

  const removeHandler = () => {
    props.onRemoveHandler({
        id,
        theme,
        campaignName,
        creativeImagess
    });
    setDeleteCampaign(false);
  };

  return (
    <div>
      <Accordion
        expanded={expand}
        style={{
          marginBottom: 16,
          boxShadow: 'none',
          maxHeight: '100%',
        }}
      >
        <AccordionSummary
          style={{
            background:
              props.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED && !expand
                ? 'rgba(26, 191, 161, 0.05)'
                : props.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED && !expand
                ? 'rgba(232, 75, 71, 0.05)'
                : '#F7FAFF',
            height: '64px',
          }}
          expandIcon={<ExpandIcon />}
          IconButtonProps={{
            onClick: toggleAccordian,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={facebookIcon} alt='' />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  fontSize: 18,
                  // lineHeight: '21.78px',
                }}
              >
                Campaign :{' '}
                {read || props.isClient ? (
                  campaignName
                ) : (
                  <TextField
                    variant='standard'
                    value={campaignName}
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: 18,
                        fontStyle: 'normal',
                        lineHeight: '31px',
                        width: 200,
                        paddingBottom: 2,
                        border: 0,
                      },
                    }}
                    onChange={(e) => { setShowError(false); setCampaignName(e.target.value)}}
                  />
                )}
                {!expand && theme && (
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 13,
                      color: 'gray',
                    }}
                  >
                    Theme: {theme}
                  </Typography>
                )}
              </Typography>
            </div>
            {props.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED && !expand && (
              <div
                style={{
                  color: '#10AB99',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  paddingRight: 16,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '15px',
                }}
              >
                <img src={approvalIcon} alt='approved' />
                <span>Approved</span>
              </div>
            )}
            {props.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED && !expand && (
              <div
                style={{
                  color: '#E83155',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  paddingRight: 16,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '15px',
                }}
              >
                <img src={disapprovedIcon} alt='approved' />
                <span>Disapproved</span>
              </div>
            )}
            {expand &&
              (props.isClient
                ? props.isClient &&
                  props.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING
                : true) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 16,
                    paddingRight: 16,
                  }}
                >
                  <div
                    className='read-write-icon'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      className='read-icon'
                      style={{
                        width: 40,
                        height: 40,
                        border: read
                          ? '1px solid #0869FB'
                          : '1px solid #CCCCCC',
                        borderRadius: '8px 0px 0px 8px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: read ? 'rgba(8, 105, 251, 0.07)' : '',
                      }}
                      onClick={() => isRead(true)}
                    >
                      <img src={read ? selectedReadIcon : readIcon} alt='' />
                    </div>
                    <div
                      className='write-icon'
                      style={{
                        width: 40,
                        height: 40,
                        border: !read
                          ? '1px solid #0869FB'
                          : '1px solid #CCCCCC',
                        borderRadius: '0px 8px 8px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: !read ? 'rgba(8, 105, 251, 0.07)' : '',
                      }}
                      onClick={() => isRead(false)}
                    >
                      <img src={!read ? selectedWriteIcon : writeIcon} alt='' />
                    </div>
                  </div>
                  {!props.isClient && (
                    <div
                      className='trash-icon'
                      style={{
                        width: 40,
                        height: 40,
                        border: '1px solid #CCCCCC',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => setDeleteCampaign(true)}
                    >
                      <img src={trashIcon} alt='' />
                    </div>
                  )}
                </div>
              )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 18,
                width: '100%',
                gap: 16,
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                Theme
              </Typography>
              <TextField
                variant='outlined'
                placeholder='Theme Goes Here'
                value={theme}
                disabled={read || props.isClient ? true : false}
                style={{
                  width: '100%',
                  height: 42,
                }}
                inputProps={{
                  style: {
                    color: '#333333',
                    height: 0,
                    background: '#FFFFFF',
                    borderRadius: 12,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 14,
                    outline: 'none',
                  },
                }}
                onChange={(e) => { setShowError(false); setTheme(e.target.value)}}
              />
            </div>
          </div>
        </AccordionDetails>
        <div
          style={{
            display: 'flex',
            gap: 16,
            overflowX: 'scroll',
            margin: '0 16px',
            marginBottom: '2rem',
            paddingTop: '1rem',
          }}
        >
          <div
            style={{
              flexBasis: 286,
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            {!read ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 22,
                }}
              >
                {creativeImagess.length > 0 &&
                  creativeImagess.map((ad, index) => (
                    <DisplayImage
                      key={index}
                      id={ad.id}
                      index={index}
                      read={read}
                      status={props.status}
                      isClient={props.isClient}
                      campaignName={ad.name}
                      onChange={onChangeCampaign}
                      onRemove={onRemoveCampaign}
                    />
                  ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 22,
                }}
              >
                {creativeImagess.length > 0 &&
                  creativeImagess.map((ad, index) => (
                    <DisplayImage
                      key={index}
                      id={ad.id}
                      status={props.status}
                      isClient={props.isClient}
                      index={index}
                      read={read}
                      campaignName={ad.name}
                      onRemove={onRemoveCampaign}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            background: '#F7FAFF',
            height: '67px',
            alignItems: 'center',
            paddingRight: 12,
            gap: 12,
          }}
        >
        { (showError && textFieldEmpty) && !props.isClient && <p style={{ color: 'red' }}>Please fill all the fields</p> }
          {!props.isClient && (
            <Button
              style={{
                width: '136px',
                height: '40px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#0869FB',
                textTransform: 'capitalize',
                background: 'rgba(8, 105, 251, 0.12)',
                marginRight: props.isClient ? 0 : 12,
              }}
              onClick={open}
            >
              Add New Image
            </Button>
          )}
        { props.isClient && props.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING && (
          <Button
            style={{
              width: '109px',
              height: '40px',
              background: 'rgba(8, 105, 251, 0.12)',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#0869FB',
              textTransform: 'capitalize',
            }}
            onClick={saveHandler}
          >
            Save
          </Button>
        )
        }
        { !props.isClient && 
          <Button
            // disabled={textFieldEmpty}
            style={{
              width: '109px',
              height: '40px',
              background: 'rgba(8, 105, 251, 0.12)',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#0869FB',
              textTransform: 'capitalize',
            }}
            onClick={saveHandler}
          >
            Save
          </Button>

        }
          {props.isClient && props.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING && (
            <>
              <Button
                style={{
                  width: '109px',
                  height: '40px',
                  background: 'rgba(232, 49, 85, 0.1)',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#E84B47',
                  textTransform: 'capitalize',
                }}
                onClick={disapproveHandler}
              >
                Disapprove
              </Button>
              <Button
                style={{
                  width: '109px',
                  height: '40px',
                  background: '#0869FB',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                onClick={approveHandler}
              >
                Approve
              </Button>
            </>
          )}
        </div>
      </Accordion>
      {deleteCampaign && (
        <DeletionConfirmationModal
          cancelHandler={setDeleteCampaign}
          creative={true}
          deleteHandler={removeHandler}
        />
      )}
    </div>
  );
};

export default FacebookCreative;
