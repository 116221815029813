const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const box_icon  = `${imageBaseUrl}/assets/d386ba5a-f9b1-443c-8e48-fed5d0a2e1bd`
export const bulb_icon  = `${imageBaseUrl}/assets/2726f188-e40a-49ac-a012-223727e93ba2`
export const facebook_icon  = `${imageBaseUrl}/assets/924ced54-1e40-4e17-99be-a95f8204a6a0`
export const footer_icon  = `${imageBaseUrl}/assets/c980a18d-3b5e-4114-9445-ed1d8ff73cbf`
export const gift_icon  = `${imageBaseUrl}/assets/b780eb63-6f8e-42b7-a707-99c8a50eb250`
export const head_icon  = `${imageBaseUrl}/assets/9247df4b-7d19-4d57-b9d6-9ed07965e2df`
export const message_icon  = `${imageBaseUrl}/assets/2ec27cf7-b06d-48e8-aa7c-78a6bbb5c08f`
export const monitor_icon  = `${imageBaseUrl}/assets/ecd30539-4ab4-411a-97c6-7caca8e5647a`
export const next_icon  = `${imageBaseUrl}/assets/4b25cf51-9725-420c-8d3a-9d14cfd48df2`
export const pen_icon  = `${imageBaseUrl}/assets/a92a3e3c-6248-4b7c-8d6c-712e3dde99e2`
export const person_icon  = `${imageBaseUrl}/assets/57fca7a4-4f44-4a27-8130-8ffb6e88bcb7`
export const phone_icon  = `${imageBaseUrl}/assets/543b7307-0657-47c8-b633-00d87565703e`
export const strategy_background  = `${imageBaseUrl}/assets/543b7307-0657-47c8-b633-00d87565703e`