import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import './campaign-preview.css';
import CampaignTypePreview from './CampaignTypePreview';
import PrimaryButton from '../../ClientDashboard/ButtonPrimary/Buttons';
import { getFBCampaignObject } from '../../../utils/accountManagerUtil';
import { goToSpecificCompoHDMClient } from '../../../api/HDMClient/componentTracking/action';
const CampaignPreview = ({agencyType}) => {
  const dispatch = useDispatch();
  const [campaignsObj, setCampaignsObj] = useState({});
  const form = useSelector(state => agencyType === 'LD'? state.client : state.hdmClient);

  useEffect(() => {
    if (form?.fbAdsGeneratedCampaignsInfo) {
      const [campaignObj, isGenerating] = getFBCampaignObject(form.fbAdsGeneratedCampaignsInfo);
      setCampaignsObj(campaignObj, form);
    }
  }, [form])
  return (
    <div className='campaign-preview-container'>
      <div className='campaign-preview-header'>
        <Typography className='campaign-preview-header__title'>
          Campaign Preview
        </Typography>
        <Typography className='campaign-preview-header__description'>
          Here are details of the campaigns that were created
        </Typography>
      </div>
      <CampaignTypePreview
        title='TOP OF THE FUNNEL-LOOKALIKE'
        campaignID={campaignsObj.lookalike || ''}
        campaignName={form?.campaignInfo?.topOfTheFunnelLookalike?.name || 'TOF-Lookalike Campaign'}
        campaignURL='https://www.facebook.com/ads/manager/'
      />
      <CampaignTypePreview
        title='TOP OF THE FUNNEL-INTEREST'
        campaignID={campaignsObj.interest || ''}
        campaignName={form?.campaignInfo?.topOfTheFunnelInterest?.name || 'TOF-Interest Campaign'}
        campaignURL='https://www.facebook.com/ads/manager/'
      />
      <CampaignTypePreview
        title='MIDDLE OF THE FUNNEL'
        campaignID={campaignsObj.mof || ''}
        campaignName={form?.campaignInfo?.middleOfTheFunnel?.name || 'Middle of the Campaign'}
        campaignURL='https://www.facebook.com/ads/manager/'
      />
      <CampaignTypePreview
        title='BOTTOM OF THE FUNNEL'
        campaignID={campaignsObj.bof || ''}
        campaignName={form?.campaignInfo?.bottomOfTheFunnel?.name || 'Bottom of the Campaign'}
        campaignURL='https://www.facebook.com/ads/manager/'
      />
      <div className='preview_footer_row'>
        <div className='footer_con'>
          {/* <button className='back'>Back</button> */}
          <PrimaryButton title='Home' onClick={() => {
            dispatch(goToSpecificCompoHDMClient(0))
          }} />
        </div>
      </div>
    </div>
  );
};

export default CampaignPreview;
