import { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { pricing_icon as pricingIcon } from '../../../assets/icons/common/common';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { checked_icon as checkedIcon } from '../../../assets/icons/common/common';
import { drop_down_arrow as dropdownArrow } from '../../../assets/icons/common/common';
import OptionalAdOns from './OptionalAdOns';
import { pencil as pencilIcon } from '../../../assets/icons/common/common';
import TextField from '@material-ui/core/TextField';
import { checked_icon as checkedIconGreen } from '../../../assets/icons/icon';
import {
  updatePricing,
  createProposalReducer,
} from '../../../api/accountManager/pricing/slice';
import ReferalSuccessModal from './ReferalSuccessModal';
import { emailValidator } from '../../ClientDashboard/Questions/Validators/emailValidator';
import { sendRefererEmailPricing } from '../../../api/accountManager/pricing/slice';
const getBasePriceObj = (basePrice, basePercent) => {
  if (basePrice === -1) {
    return 'Custom';
  }
  if (basePercent && !basePrice) {
    return `${basePercent}%`;
  }
  if (basePrice && !basePercent) {
    return `$${basePrice}`;
  }
  if (!basePrice && !basePercent) {
    return `$0`;
  }
  return `$${basePrice} or ${basePercent}%`;
};

const getPriceAndPercentage = (priceObj = '') => {
  if (priceObj.includes('%') && priceObj.includes('$')) {
    const [price, percent] = priceObj.split(' or ');
    return [parseInt(price.slice(1)), parseInt(percent.slice(0, -1))];
  }
  if (priceObj.includes('%')) {
    return [0, parseInt(priceObj.slice(0, -1))];
  }
  if (priceObj.includes('$')) {
    return [parseInt(priceObj.slice(1)), 0];
  }
  return [0, 0];
};
const PriceRate = (props) => {
  const dispatch = useDispatch();

  const [basePrice, setBasePrice] = useState(props.monthlyFee);
  const [landingPages, setLandingPages] = useState(props.landingPages);
  const [popUps, setPopUp] = useState(props.popUps);
  const [stickyBars, setStickyBars] = useState(props.stickyBars);
  const [basePriceEdit, setBasePriceEdit] = useState(false);
  const [landingPagesEdit, setLandingPagesEdit] = useState(false);
  const [popUpsEdit, setPopUpsEdit] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [stickyBarsEdit, setStickyBarsEdit] = useState(false);
  const [optionalAdons, setoptionalAdons] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [additionalLPQuantity, setAdditionalLPQuantity] = useState(0);
  const [additionalPUQuantity, setAdditionalPUQuantity] = useState(0);
  const [additionalSBQuantity, setAdditionalSBQuantity] = useState(0);
  const [callRailQuantity, setCallRailQuantity] = useState(0);
  const [hotjarQuantity, setHotjarQuantity] = useState(0);
  const [inviteEmail, setInviteEmail] = useState('');
  const [additionalLPPrice, setAdditionalLPPrice] = useState('500');
  const [additionalPUPrice, setAdditionalPUPrice] = useState('100');
  const [additionalSBPrice, setAdditionalSBPrice] = useState('100');
  const [callRailPrice, setCallRailPrice] = useState('50');
  const [hotjarPrice, setHotjarPrice] = useState('50');
  const [priceCard, setPriceCard] = useState(false);
  const [toBeSave, setToBeSave] = useState(false);
  const [error, setError] = useState(false);

  const pricing = useSelector((state) => state.pricing.pricing);

  useEffect(() => {
    const pricingObj = pricing[props.priceCardNumber];
    setToBeSave(false);
    setBasePrice(
      getBasePriceObj(
        pricingObj.basePricePerMonth,
        pricingObj.basePercentPerMonth
      )
    );
    setLandingPages(pricingObj.baseServices[0].quantity);
    setPopUp(pricingObj.baseServices[1].quantity);
    setStickyBars(pricingObj.baseServices[2].quantity);
    setAdditionalLPQuantity(pricingObj.addonServices[0].quantity);
    setAdditionalPUQuantity(pricingObj.addonServices[1].quantity);
    setAdditionalSBQuantity(pricingObj.addonServices[2].quantity);
    setCallRailQuantity(pricingObj.addonServices[3].quantity);
    setHotjarQuantity(pricingObj.addonServices[4].quantity);
    setAdditionalLPPrice(pricingObj.addonServices[0].price);
    setAdditionalPUPrice(pricingObj.addonServices[1].price);
    setAdditionalSBPrice(pricingObj.addonServices[2].price);
    setCallRailPrice(pricingObj.addonServices[3].price);
    setHotjarPrice(pricingObj.addonServices[4].price);
  }, [pricing]);
  const selectPriceCardHandler = (id) => {
    props.onClickHandler(id);
  };
  useEffect(() => {
    if (toBeSave) {
      const [price, percent] = getPriceAndPercentage(basePrice);
      dispatch(
        updatePricing({
          priceIndex: props.priceCardNumber,
          pricing: {
            adSpendRange: {
              minSpend: props.minAdSpend,
              maxSpend: props.maxAdSpend,
            },
            currency: '$',
            basePricePerMonth: price,
            basePercentPerMonth: percent || 0,
            baseServices: [
              {
                service: 'Landing Pages',
                quantity: landingPages || '0',
              },
              {
                service: 'Pop-up',
                quantity: popUps || '0',
              },
              {
                service: 'Sticky Bar',
                quantity: stickyBars || '0',
              },
            ],
            addonServices: [
              {
                service: 'Additional Landing Pages',
                quantity: additionalLPQuantity,
                price: parseInt(additionalLPPrice || '0'),
              },
              {
                service: 'Additional Pop Up',
                quantity: additionalPUQuantity,
                price: parseInt(additionalPUPrice || '0'),
              },
              {
                service: 'Additional Sticky Bar',
                quantity: additionalSBQuantity,
                price: parseInt(additionalSBPrice || '0'),
              },
              {
                service: 'Call Rail',
                quantity: callRailQuantity,
                price: parseInt(callRailPrice || '0'),
              },
              {
                service: 'Hotjar',
                quantity: hotjarQuantity,
                price: parseInt(hotjarPrice || '0'),
              },
            ],
          },
        })
      );
    }
  }, [
    basePrice,
    landingPages,
    popUps,
    stickyBars,
    additionalLPQuantity,
    additionalLPPrice,
    additionalPUQuantity,
    additionalPUPrice,
    additionalSBQuantity,
    additionalSBPrice,
    callRailPrice,
    callRailQuantity,
    hotjarPrice,
    hotjarQuantity,
  ]);

  const increaseLPQuantityHandler = () => {
    if (parseInt(additionalLPQuantity) < 4) {
      setToBeSave(true);
      setAdditionalLPQuantity(parseInt(additionalLPQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' +
        (parseInt(basePrices[0].slice(1)) + parseInt(additionalLPPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseLPQuantityHandler = () => {
    if (additionalLPQuantity != 0) {
      setToBeSave(true);
      setAdditionalLPQuantity(additionalLPQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' +
        (parseInt(basePrices[0].slice(1)) - parseInt(additionalLPPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const increasePUQuantityHandler = () => {
    if (parseInt(additionalPUQuantity) < 4) {
      setToBeSave(true);
      setAdditionalPUQuantity(parseInt(additionalPUQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' +
        (parseInt(basePrices[0].slice(1)) + parseInt(additionalPUPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreasePUQuantityHandler = () => {
    if (additionalPUQuantity != 0) {
      setToBeSave(true);
      setAdditionalPUQuantity(additionalPUQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' +
        (parseInt(basePrices[0].slice(1)) - parseInt(additionalPUPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const increaseSBQuantityHandler = () => {
    if (parseInt(additionalSBQuantity) < 4) {
      setToBeSave(true);
      setAdditionalSBQuantity(parseInt(additionalSBQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' +
        (parseInt(basePrices[0].slice(1)) + parseInt(additionalSBPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseSBQuantityHandler = () => {
    if (additionalSBQuantity != 0) {
      setToBeSave(true);
      setAdditionalSBQuantity(additionalSBQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' +
        (parseInt(basePrices[0].slice(1)) - parseInt(additionalSBPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const increaseCallRailQuantityHandler = () => {
    if (parseInt(callRailQuantity) < 1) {
      setToBeSave(true);
      setCallRailQuantity(parseInt(callRailQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' +
        (parseInt(basePrices[0].slice(1)) + parseInt(callRailPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseCallRailQuantityHandler = () => {
    if (callRailQuantity != 0) {
      setToBeSave(true);
      setCallRailQuantity(callRailQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' +
        (parseInt(basePrices[0].slice(1)) - parseInt(callRailPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };
  const increaseHotjarQuantityHandler = () => {
    if (parseInt(hotjarQuantity) < 1) {
      setToBeSave(true);
      setHotjarQuantity(parseInt(hotjarQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) + parseInt(hotjarPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseHotjarQuantityHandler = () => {
    if (hotjarQuantity != 0) {
      setToBeSave(true);
      setHotjarQuantity(hotjarQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) - parseInt(hotjarPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };
  const callbackForModal = () => {
    setSuccessModal(true);
  };
  return (
    <div className='price-card-container'>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: 16,
          color: '#170F49',
          paddingBottom: 10,
          textAlign: 'center',
        }}
      >
        {props.title}
      </Typography>
      {props.selected && (
        <img
          src={checkedIconGreen}
          alt=''
          style={{ position: 'absolute', right: '0.75rem', top: '0.75rem' }}
        />
      )}
      <div className='pricing'>
        <div>
          <img src={pricingIcon} alt='' />
        </div>
        <div>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 13.8582,
              color: '#6F6C90',
            }}
          >
            Ad Spend Range
          </Typography>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 18.4776,
              color: '#170F49',
            }}
          >
            {props.adSpendRange}
          </Typography>
        </div>
      </div>
      {/* <div className="description">
        <p
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 13.8582,
            lineHeight: 1.5,
            color: "#6F6C90",
          }}
        >
          What’s included{" "}
        </p>
      </div> */}
      <div className='price'>
        <>
          {basePriceEdit ? (
            <p
              style={{
                display: 'flex',
                alignItems: 'end',
                gap: '0.3rem',
                margin: 0,
              }}
            >
              <TextField
                inputProps={{ style: { width: '120px' } }}
                value={basePrice}
                onChange={(e) => {
                  setToBeSave(true);
                  setBasePrice(e.target.value);
                  setHasChange(true);
                }}
              />
              /monthly{' '}
              <span
                onClick={() => {
                  if (basePriceEdit && basePrice === '') {
                    setToBeSave(true);
                    setBasePrice('$0');
                  }
                  setBasePriceEdit(!basePriceEdit);
                }}
              >
                <img src={pencilIcon} alt='' />
              </span>
            </p>
          ) : (
            <p
              style={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '0.3rem',
                margin: 0,
              }}
            >
              <span>{basePrice}</span>
              /monthly{' '}
              <span
                onClick={() => {
                  setToBeSave(true);
                  setBasePriceEdit(!basePriceEdit);
                }}
              >
                <img src={pencilIcon} alt='' />
              </span>
            </p>
          )}
        </>
      </div>
      <div
        style={{
          minHeight: 208,
          maxHeight: 208,
          overflowY: 'scroll',
        }}
      >
        <div className='features'>
          <p
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 13.8582,
              color: '#170F49',
            }}
          >
            What's included
          </p>
          <ul>
            {/* <li>
              <img src={checkedIcon} alt='' />
              {landingPagesEdit ? (
                <TextField
                  inputProps={{
                    style: { width: '28px', padding: 0, fontSize: 13.8582 },
                  }}
                  value={landingPages}
                  onChange={(e) => {
                    setToBeSave(true);
                    setLandingPages(e.target.value);
                    setHasChange(true);
                  }}
                />
              ) : (
                landingPages
              )}{' '}
              Landing Pages
              <img
                src={pencilIcon}
                alt=''
                onClick={() => {
                  if (landingPagesEdit && landingPages === '') {
                    setToBeSave(true);
                    setLandingPages('0');
                  }
                  setLandingPagesEdit(!landingPagesEdit);
                }}
              />
            </li>
            <li>
              <img src={checkedIcon} alt='' />
              {popUpsEdit ? (
                <TextField
                  inputProps={{
                    style: { width: '28px', padding: 0, fontSize: 13.8582 },
                  }}
                  value={popUps}
                  onChange={(e) => {
                    setToBeSave(true);
                    setHasChange(true);
                    setPopUp(e.target.value);
                  }}
                />
              ) : (
                popUps
              )}{' '}
              Pop-up
              <img
                src={pencilIcon}
                alt=''
                onClick={() => {
                  if (popUpsEdit && popUps === '') {
                    setToBeSave(true);
                    setPopUp('0');
                  }
                  setPopUpsEdit(!popUpsEdit);
                }}
              />
            </li>
            <li>
              <img src={checkedIcon} alt='' />
              {stickyBarsEdit ? (
                <TextField
                  inputProps={{
                    style: { width: '28px', padding: 0, fontSize: 13.8582 },
                  }}
                  value={stickyBars}
                  onChange={(e) => {
                    setToBeSave(true);
                    setStickyBars(e.target.value);
                    setHasChange(true);
                  }}
                />
              ) : (
                stickyBars
              )}{' '}
              Sticky Bar
              <img
                src={pencilIcon}
                alt=''
                onClick={() => {
                  if (stickyBarsEdit && stickyBars === '') {
                    setToBeSave(true);
                    setStickyBars('0');
                  }
                  setStickyBarsEdit(!stickyBarsEdit);
                }}
              />
            </li> */}
            <li>
              <img src={checkedIcon} alt='' />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span>Number of Accounts per Seat:</span>
                <span
                  style={{
                    fontWeight: '600',
                  }}
                >
                  {props.numberOfAccountsPerSear}
                </span>
                <a
                  href='https://pixis.ai/seat-sharing-policy'
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 12,
                    color: 'rgba(8, 105, 251, 1)',
                    // textAlign: 'center',
                    textDecoration: 'none',
                  }}
                >
                  Know More about Seat Sharing Policy
                </a>
              </div>
            </li>
            <li>
              <img src={checkedIcon} alt='' />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span>Account Manager Expertise:</span>
                <span
                  style={{
                    fontWeight: '600',
                  }}
                >
                  {props.accountManagerExpertise}
                </span>
              </div>
            </li>
            <li>
              <img src={checkedIcon} alt='' />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span>Support Turn Around:</span>
                <span
                  style={{
                    fontWeight: '600',
                  }}
                >
                  {props.accountManagerExpertise}
                </span>
              </div>
            </li>
            <li>
              <img src={checkedIcon} alt='' />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span>Online Training Environment:</span>
                <span
                  style={{
                    fontWeight: '600',
                  }}
                >
                  {props.onlineTrainingEnvironment}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <div>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 14,
                lineHeight: '15px',
                color: '#170F49',
              }}
            >
              Refer a Business
            </Typography>
            <a
              href='https://pixis.ai/referral-policy'
              target='_blank'
              rel='noreferrer'
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 12,
                color: 'rgba(8, 105, 251, 1)',
                // textAlign: 'center',
                textDecoration: 'none',
              }}
            >
              Know More about Referral Policy
            </a>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 8,
              marginBottom: 32,
            }}
            className='price-rate-referral-input'
          >
            <TextField
              placeholder='Enter email'
              variant='standard'
              // disableUnderline={true}
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
              // sx={{
              //   '& .MuiFilledInput-underline:before': {
              //     borderBottom: 'none !important',
              //   },
              // }}
              inputProps={{
                style: {
                  color: inviteEmail ? '#170F49' : '#ABACB4',
                  width: 156,
                  height: 18,
                  background: '#FFFFFF',
                  padding: '7px 0px 7px 12px',
                  border: `1px solid ${error ? 'red' : '#F3F3F3'}`,
                  // border: '1px solid red',
                  borderRadius: 6,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  outline: 'none',
                },
              }}
            />
            <Button
              style={{
                background: '#0869FB',
                borderRadius: 6,
                width: 67,
                height: 34,
                color: '#ffffff',
                textTransform: 'capitalize',
              }}
              onClick={() => {
                if (emailValidator(inviteEmail)) {
                  setError(false);
                  dispatch(
                    sendRefererEmailPricing({
                      email: inviteEmail,
                      callback: callbackForModal,
                    })
                  );
                } else {
                  setError(true);
                }
              }}
            >
              Send
            </Button>
          </div>
          {successModal && (
            <ReferalSuccessModal
              email={inviteEmail}
              setInviteEmail={setInviteEmail}
              isOpen={successModal}
              closeModal={setSuccessModal}
            />
          )}
        </div>
        {/* <div
          className='optional-add-ons'
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setoptionalAdons(!optionalAdons);
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.25rem',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 13.8582,
            }}
          >
            Optional Add-ons{' '}
            <img
              src={dropdownArrow}
              alt=''
              style={{
                transform: optionalAdons ? 'rotate(180deg)' : 'rotate(0deg)',
                // transition: 'transform 0.8s ease-in',
              }}
            />
          </p>
        </div> */}
        {/* {optionalAdons && (
          <div>
            <OptionalAdOns
              additionalLPQuantity={additionalLPQuantity}
              additionalLPPrice={additionalLPPrice}
              setAdditionalLPPrice={setAdditionalLPPrice}
              increaseLPQuantityHandler={increaseLPQuantityHandler}
              decreaseLPQuantityHandler={decreaseLPQuantityHandler}
              additionalPUQuantity={additionalPUQuantity}
              additionalPUPrice={additionalPUPrice}
              setAdditionalPUPrice={setAdditionalPUPrice}
              increasePUQuantityHandler={increasePUQuantityHandler}
              decreasePUQuantityHandler={decreasePUQuantityHandler}
              additionalSBQuantity={additionalSBQuantity}
              additionalSBPrice={additionalSBPrice}
              setAdditionalSBPrice={setAdditionalSBPrice}
              increaseSBQuantityHandler={increaseSBQuantityHandler}
              decreaseSBQuantityHandler={decreaseSBQuantityHandler}
              callRailQuantity={callRailQuantity}
              callRailPrice={callRailPrice}
              setCallRailPrice={setCallRailPrice}
              increaseCallRailQuantityHandler={increaseCallRailQuantityHandler}
              decreaseCallRailQuantityHandler={decreaseCallRailQuantityHandler}
              hotjarQuantity={hotjarQuantity}
              hotjarPrice={hotjarPrice}
              setHotjarPrice={setHotjarPrice}
              increaseHotjarQuantityHandler={increaseHotjarQuantityHandler}
              decreaseHotjarQuantityHandler={decreaseHotjarQuantityHandler}
              setHasChange={setHasChange}
            />
          </div>
        )} */}
      </div>
      <div className='add-to-proposal-button'>
        <PrimaryButton
          title='Add to Proposal'
          width='100%'
          // disabled={!hasChange}
          // onClick={() => addProposalHandler()}
          // onClick={() => dispatch(createProposalReducer(props.priceCardNumber))}
          onClick={() => selectPriceCardHandler(props.priceCardNumber)}
        >
          Add to Proposal
        </PrimaryButton>
      </div>
    </div>
  );
};

export default PriceRate;
