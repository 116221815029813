import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { Tabs, useTabState, Panel } from "../../CustomTab/customTab";

import NavigationSteps from "../NavigationSteps";
import brandLogo from "../../../assets/images/verizon_brand.png";
import { facebook_icon as facebookIcon } from "../../../assets/icons/social/social";
import googleIcon from "../../../assets/icons/social/google_icon.png";

import { getSharedAssetsReducer } from "../../../api/accountManager/sharedAssets/slice";
import { getAllFormsReducer, resetFormState } from "../../../api/accountManager/forms/slice";
import Avatar from "@mui/material/Avatar";
import {
  BACKEND_STATUS,
  ldComponentWithIndexAm,
} from "../../../utils/accountManagerUtil";
import { showAnalyticsDashboard } from "../../../utils/clientDashboardIdsUtil";
import { haveAccess, platformAccessKey } from "../../../utils/notificationUtil";
import { resetData } from "../../../api/channels/action";
import { resetGoogleCustomerState } from "../../../api/accountManager/googleAdsCustomer/slice";
import { resetPricingState } from "../../../api/accountManager/pricing/slice";

// const cn = (...args) => args.filter(Boolean).join(' ')

// const Tab = ({ children }) => {
//     const { isActive, onClick } = useTabState()

//     return (
//         <button className={cn('tab', isActive && 'active')} onClick={onClick}>
//             {children}
//         </button>
//     )
// }

const getStatusTitle = (form, allProposals) => {
  let title = "";
  let classes = "";
  console.log(
    "form brand page",
    form?.brandId,
    form?.sowPricingInfo?.status,
    ["PENDING", "ONGOING"].includes(form?.sowPricingInfo?.status)
  );
  if (["PENDING", "ONGOING"].includes(form?.sowPricingInfo?.status)) {
    title = "Onboarding: In Progress";
    classes = "review";
  }
  if (form?.sowPricingInfo?.status === "COMPLETED") {
    title = "Onboarding: Completed";
    classes = "progress";
    if (allProposals[form._id]) {
      title = "Shared Assets: Read";
      classes = "progress";
    }
  }
  if (form?.proposalIterations && form?.proposalIterations.length) {
    title = "Proposal: In Review";
    classes = "review";
    if (
      (form?.proposalIterations[form?.proposalIterations.length - 1] || {})
        .state === "APPROVED"
    ) {
      title = "Proposal: Accepted";
      classes = "progress";
      if (allProposals[form._id]) {
        title = "Shared Assets: Complete";
        classes = "progress";
      }
    }
  }
  if (
    form?.proposalIterations?.length &&
    (form?.proposalIterations[form?.proposalIterations.length - 1] || {})
      .state === "CHANGES_SUGGESTED"
  ) {
    title = "Proposal: Changes Suggested";
    classes = "review";
  }
  if (form?.intakeInfo?.status === "ONGOING") {
    title = "Product Intake Form: In Progress";
    classes = "review";
  }
  if (form?.intakeInfo?.status === "COMPLETED") {
    title = "Product Intake Form: Completed";
    classes = "progress";
  }
  if ((form?.strategyIterations || []).length) {
    if (
      ["GENERATED", "CHANGES_APPLIED"].includes(
        (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
          .state
      )
    ) {
      title = "Strategy Acceptance: In Review";
      classes = "review";
    }
    if (
      (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
        .state === "CHANGES_SUGGESTED"
    ) {
      title = "Strategy Acceptance: Changes Suggested";
      classes = "review";
    }
    if (
      (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
        .state === "APPROVED"
    ) {
      title = "Strategy Acceptance: Accepted";
      classes = "progress";
    }
  }
  if (showAnalyticsDashboard(form)) {
    title = "Campaign Generation: Complete";
    classes = "progress";
  }

  return [title, classes];
};

const BrandPage = () => {
  console.log("############# in brand page");
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(null);
  const googleAdsAccess = useSelector(
    (store) => store.sharedAssetsAM.googleAdsAccess
  );
  const allProposals = useSelector((store) => store.form.allForms);
  const open = true;

  useEffect(() => {
    dispatch(getAllFormsReducer());
    dispatch(resetData());
    dispatch(resetFormState());
    dispatch(resetGoogleCustomerState());
    dispatch(resetPricingState());
    localStorage.removeItem("formId");
  }, []);
  useEffect(() => {
    if (allProposals.length) {
      dispatch(
        getSharedAssetsReducer({
          // formIds: allProposals.map(proposal => proposal._id)
        })
      );
    }
  }, [allProposals]);

  return (
    <main class={`main_layout accountManger`}>
      <section className="nav_section">
        <NavigationSteps />
      </section>
      <section className={`right_layout`}>
        <div className={`right_layout_inner`}>
          <div className={`right_main_section`}>
            <div className="brand_selection_layout">
              <div className="brand_selection_header">
                <h4>Hello</h4>
                {/* <h2>Brand Selection</h2> */}
                <p>Please Select the brand that you want to work with</p>
              </div>
              <div className="brand_selection_body">
                <div className="selection_inner_layout success">
                  {(allProposals || []).map((proposal, idx) => {
                    const [title, classes] = getStatusTitle(
                      proposal,
                      googleAdsAccess
                    );
                    const haveFbAccess = haveAccess(
                      proposal?.accessSharing,
                      platformAccessKey.FACEBOOK_ACCESS
                    );
                    const haveGoogleAccess = haveAccess(
                      proposal?.accessSharing,
                      platformAccessKey.GOOGLE_ACCESS
                    );
                    return (
                      <div
                        className={`selection_brand_block ${
                          currentIndex === idx ? `selected ${classes}` : classes
                        }`}
                        key={idx}
                        onClick={() => setCurrentIndex(idx)}
                      >
                        <div className="brand_blocks_head">
                          <div className="block_brand_logo">
                            <Avatar
                              className="brand_avatar"
                              alt={proposal.brandId || ""}
                              src={"avatar.jpg"}
                            />
                          </div>
                          <div className="brand_selection_status">{title}</div>
                        </div>
                        <div className="brand_blocks_details">
                          <h3>{proposal.brandId}</h3>
                          <div className="brand_campaing_row">
                            <div className="brand_name_selection">
                              <div className="campaign_connect_logo">
                                <img src={facebookIcon} />
                              </div>
                              <p className="campaign_connect_title">
                                Facebook Ads
                              </p>
                            </div>
                            <span
                              className={`campaign_connect_tag ${
                                haveFbAccess ? "connected" : "not_connected"
                              }`}
                            >
                              {haveFbAccess ? "Connected" : "Not Connected"}
                            </span>
                          </div>
                          <div className="brand_campaing_row">
                            <div className="brand_name_selection">
                              <div className="campaign_connect_logo connected">
                                <img src={googleIcon} />
                              </div>
                              <p className="campaign_connect_title">
                                Google Ads
                              </p>
                            </div>
                            <span
                              className={`campaign_connect_tag ${
                                haveGoogleAccess ? "connected" : "not_connected"
                              }`}
                            >
                              {haveGoogleAccess ? "Connected" : "Not Connected"}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="preview_footer_row">
                <div
                  className="footer_content"
                  style={{ justifyContent: "flex-end" }}
                >
                  <PrimaryButton
                    title="Next"
                    style={{
                      backgroundColor:
                        currentIndex !== null ? "#0869FB" : "#B9CCE9",
                      color: "#ffff",
                      textTransform: "none",
                      fontSize: 13,
                    }}
                    disabled={
                      currentIndex === null
                      // ["PENDING", "ONGOING"].includes(
                      //   allProposals[currentIndex]?.sowPricingInfo?.status
                      // )
                    }
                    onClick={() => {
                      // if (
                      //   !["PENDING", "ONGOING"].includes(
                      //     allProposals[currentIndex]?.sowPricingInfo?.status
                      //   )
                      // ) {
                      //   localStorage.setItem(
                      //     "formId",
                      //     allProposals[currentIndex]._id
                      //   );
                      //   localStorage.setItem(
                      //     "brandId",
                      //     allProposals[currentIndex].brandId
                      //   );
                      //   localStorage.setItem("isNextClicked", true);
                      //   window.location.href = "/account-manager";
                      // }
                      localStorage.setItem(
                        "formId",
                        allProposals[currentIndex]._id
                      );
                      localStorage.setItem(
                        "brandId",
                        allProposals[currentIndex].brandId
                      );
                      localStorage.setItem("isNextClicked", true);
                      window.location.href = "/account-manager";
                    }}
                  ></PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default BrandPage;
