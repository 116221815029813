import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { button_bg_corner as corner } from "../../assets/icons/common/common";
import { useDispatch } from "react-redux";
import { updateCompoReduxHDMAM } from "../../api/HDMAM/componentTracking/action";
import { saveHdmAmFormsData } from "../../api/HDMAM/form/action";
import { updateCompoReduxAM } from "../../api/accountManager/componentTracking/action";
import { updateFormReducer } from "../../api/accountManager/forms/slice";
import { ldFbAuditComplete } from "../../api/accountManager/forms/saga";

const GoToNextCompoButton = (props) => {
  const dispatch = useDispatch();
  const CustomButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: "14px",
    padding: props.type == "corner" ? "11px 25px" : "10px 24px",
    border: props.type == "corner" ? "0px solid" : "1px solid",
    lineHeight: "18px",
    background:
      props.type == "bordered"
        ? "#ffffff"
        : props.type == "corner"
        ? "#0869FB url(" + corner + ") no-repeat 0 0"
        : "#0869FB",
    borderColor: props.type == "bordered" ? "#0869FB" : "#0869FB",
    borderRadius: "8px",
    color: props.type == "bordered" ? "#0869FB" : "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 600,
    width: props.width ? props.width : "",
    "&:hover": {
      color: props.type == "bordered" ? "#FFFFFF" : "#FFFFFF",
    },
  });
  const goToNextCompo = (agencyType) => {
    if (agencyType === "LD") {
      if(props?.extra?.type === "AUDIT_AD"){
        ldFbAuditComplete(props?.extra?.data).then((res) => {
          dispatch(updateCompoReduxAM());
        });
      }else{
        dispatch(saveHdmAmFormsData());
        dispatch(updateCompoReduxAM());
      }
    } else {
      dispatch(saveHdmAmFormsData());
      dispatch(updateCompoReduxHDMAM());
    }
  };
  return (
    <CustomButton
      variant="contained"
      {...props}
      onClick={() => {
        if(props.onClickHandler){
          props.onClickHandler();
        }
        goToNextCompo(props.agencyType);
      }}
    >
      {props.title}
    </CustomButton>
  );
};

export default GoToNextCompoButton;
