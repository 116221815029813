import { useDispatch, useSelector } from 'react-redux';
import CommonAuditCampaign from "./CommonAuditCampaign";
import { useCallback } from 'react';
import { getAlreadyAuditHdm } from '../../../api/HDMAM/audit/saga';
import { goBackAmCompo } from '../../../api/accountManager/componentTracking/action';
import { onNextLD } from '../GoToNextCompoButton';

export default function LDAuditCampaign() {
  const dispatch = useDispatch();
  
  const form = useSelector(state => state?.form?.form);

  const onFBAccountIdExists = useCallback(() => {
    getAlreadyAuditHdm("LD").then((res)=>{
      if(res.length !== 0){
        const account=res.find(account => account.accountId === form.fAdsAccountId);
        dispatch({ type: "GET_AUDIT_DETAILS_HDM", payload: account ? account.performanceData : res[res.length - 1].performanceData});
      }
    });
  }, [dispatch, form.fAdsAccountId]);

  const onBack = () => {
    dispatch(goBackAmCompo());
  }
  
  return (
    <CommonAuditCampaign 
      form={form}
      onFBAccountIdExists={onFBAccountIdExists}
      onBack={onBack} 
      onNext={() => onNextLD(dispatch)}
    />
  )
}