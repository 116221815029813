import { Typography,TextField,Button } from "@mui/material";

const flexCenter={
    display:"flex",
    alignItems:"center",
    flexDirection:"column"
}

const Form = ({img,title,textFieldLables,btnBackgColor,role,generatePassword,dataFields,getData,generatedPassword,email,error}) => {
    
    return  <>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",height:"100vh"}}>
                    <div>
                        <img src={img}/>
                    </div>
                    <div style={{marginTop:"20px"}}>
                        <Typography variant="h5" component="div" style={{letterSpacing:"2.5px"}}>
                            {title}
                        </Typography>
                    </div>
                    <div style={{width:"100%"}}>
                        <div style={{...flexCenter,marginTop:"40px"}}>
                            <TextField 
                                id="outlined-basic" 
                                label={textFieldLables.box1}
                                variant="outlined" 
                                style={{width:"60%",backgroundColor:"#ffff"}} 
                                size="medium"
                                placeholder={`Enter Your ${textFieldLables.box1}`}
                                value={dataFields["name"]}
                                onChange={(e) => getData(dataFields,e.target.value,"name",role)}
                            />
                        </div>
                        <div style={{...flexCenter,marginTop:"20px"}}>
                            <TextField 
                                id="outlined-basic" 
                                label={textFieldLables.box2}
                                variant="outlined" 
                                style={{width:"60%",backgroundColor:"#ffff"}} 
                                size="medium"
                                placeholder={`Enter Your ${textFieldLables.box2}`}
                                value={dataFields["email"]}
                                onChange={(e) => getData(dataFields,e.target.value,"email",role)}
                            />
                        </div>
                    </div>
                    <div style={{width:"100%"}}>
                        <div style={{...flexCenter,marginTop:"40px"}}>
                            <Button 
                                style={{
                                    backgroundColor:`${btnBackgColor}`,
                                    color:"#ffff",
                                    width:"60%",
                                    padding:"15px",
                                    textTransform:"none"
                                }}
                                onClick={()=>generatePassword(role)}
                            >
                                Generate Password
                            </Button>
                        </div>
                    </div>
                    {error &&   <div style={{width:"100%",...flexCenter}}>
                                    <Typography variant="h7" component="div" style={{color:"red",marginTop:"20px",backgroundColor:"#eeee",width:"60%",textAlign:"center",padding:"10px",boxSizing:"border-box"}}>
                                        {error}
                                    </Typography>
                                </div>}
                    {
                        generatedPassword  &&   <div style={{width:"100%",...flexCenter,marginTop:"20px"}}>
                                                    <div style={{...flexCenter,marginTop:"20px",border:"1px solid black",width:"60%",borderStyle:"dashed",backgroundColor:"#fff",padding:"5px"}}>
                                                        <Typography variant="h7" component="div" style={{margin:"10px"}}>
                                                            <span style={{textAlign:"left"}}> Email: </span> 
                                                            <span>{email}</span>
                                                        </Typography>
                                                        <Typography variant="h7" component="div" style={{margin:"5px"}}>
                                                            <span style={{textAlign:"left"}}>Password: </span> 
                                                            <span>{generatedPassword}</span>
                                                        </Typography>
                                                    </div>
                                                </div>
                    }
                </div>
            </>
}
export default Form;