
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_CAMPAIGN_SELECTOR_STAGES } from "../../constants/LDConstants";
import GoogleCampaignSelector from "../GoogleCampaignSelector/GoogleCampaignSelector";
import {
  BACKEND_STATUS,
  CRON_STATUS_BACKEND,
  accountManagerIds,
  ldComponentWithIndexAm,
  componentWithIndexHDMAm,
  accountManagerIdsHDM,
} from "../../utils/accountManagerUtil";
import { goToSpecificCompoClientAm } from "../../api/accountManager/componentTracking/action";
import { startCronPMaxStrategy, updateStartLoader } from "../../api/pmaxStrategy/slice";
import { generateKeywordsReducer } from "../../api/accountManager/targetAI/slice";
import { goToSpecificCompoHDMAM } from "../../api/HDMAM/componentTracking/action";
import { updateNavAM } from "../../api/HDMAM/componentTracking/reducer";
import PmaxLoader from "../PMaxStrategyCreation/PmaxLoader";

const StrategyGoogleCampaignSelector = () => {
  const dispatch = useDispatch();

  const [clientForm, setClientForm] = useState({
    pmaxCAICronStatus: "PENDING",
    pmaxTAICronStatus: "PENDING",
    googleAdsCronStatus: "PENDING",
  });
  const [pmaxUnlockButton, setPmaxUnlockButton] = useState(false);
  const [pmaxGeneratingButton, setPmaxGeneratingButton] = useState(false);
  const [pmaxOngoingButton, setPmaxOngoingButton] = useState(false);
  const [pmaxCompletedButton, setPmaxCompletedButton] = useState(false);

  const startLoader = useSelector(store => store.pmaxStrategy.startLoader);
  const [searchUnlockButton, setSearchUnlockButton] = useState(false);
  const [searchGeneratingButton, setSearchGeneratingButton] = useState(false);
  const [searchOngoingButton, setSearchOngoingButton] = useState(false);
  const [searchCompletedButton, setSearchCompletedButton] = useState(false);
  const form = useSelector((store) => store.hdmFormAm)

useEffect(() => {
    if (form) {
      setClientForm(form);
      if (
          form?.pmaxCAICronStatus === CRON_STATUS_BACKEND.FAILED ||
          form?.pmaxTAICronStatus === CRON_STATUS_BACKEND.FAILED)
            setPmaxUnlockButton(true);
       else if (
        [BACKEND_STATUS.ONGOING].includes(form?.googlePmaxStrategy?.status) &&
        (form?.pmaxCAICronStatus === CRON_STATUS_BACKEND.RUNNING ||
          form?.pmaxTAICronStatus === CRON_STATUS_BACKEND.RUNNING)
      ) {
        setPmaxGeneratingButton(true);
      } else if (
        [BACKEND_STATUS.ONGOING].includes(form?.googlePmaxStrategy?.status) &&
        form?.pmaxCAICronStatus === CRON_STATUS_BACKEND.DONE &&
        form?.pmaxTAICronStatus === CRON_STATUS_BACKEND.DONE
      ) {
        // dispatch(getPmaxStrategies());
        setPmaxOngoingButton(true);
      } else if (
        [BACKEND_STATUS.COMPLETED].includes(form?.googlePmaxStrategy?.status)
      ) {
        setPmaxOngoingButton(false);
        setPmaxCompletedButton(true);
      } else {
          setPmaxUnlockButton(true);
      }
        
      if (form?.googleAdsCronStatus === CRON_STATUS_BACKEND.FAILED)
        setSearchUnlockButton(true);
        else if (form.googleAdsCronStatus === CRON_STATUS_BACKEND.RUNNING) {
        setSearchGeneratingButton(true);
      } else if (form?.googleAdsCronStatus === CRON_STATUS_BACKEND.DONE) {
        if (
          form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED
        ) {
          setSearchOngoingButton(false);
          setSearchCompletedButton(true);
        } else {
          setSearchOngoingButton(true);
        }
      } else {
          setSearchUnlockButton(true);
      }
    }
  }, [JSON.stringify(form)]);

  const onClickHandlerForSearchStrategy = () => {
    if (
      !clientForm.googleAdsCronStatus ||
      clientForm.googleAdsCronStatus === CRON_STATUS_BACKEND.FAILED
    ) {
      dispatch(updateStartLoader(true));
      dispatch(generateKeywordsReducer());
    }
    if (clientForm.googleAdsCronStatus === CRON_STATUS_BACKEND.DONE) {
      dispatch(
        goToSpecificCompoHDMAM(
          componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_BRAND_CAMPAIGN]
        )
      );
    }
  };
  const onClickHandlerForPMaxStrategySelect = () => {
   if (
     (clientForm?.googlePmaxStrategy?.status === BACKEND_STATUS.PENDING) &&
     (!clientForm?.pmaxCAICronStatus &&
     !clientForm?.pmaxTAICronStatus ) || ([clientForm?.pmaxCAICronStatus, clientForm?.pmaxTAICronStatus].includes(CRON_STATUS_BACKEND.FAILED))
   ) {
      dispatch(updateStartLoader(true));
      dispatch(startCronPMaxStrategy());
   }
   if (
     [BACKEND_STATUS.ONGOING, BACKEND_STATUS.COMPLETED].includes(
       clientForm?.googlePmaxStrategy?.status
     ) &&
     clientForm.pmaxCAICronStatus === CRON_STATUS_BACKEND.DONE &&
     clientForm.pmaxTAICronStatus === CRON_STATUS_BACKEND.DONE
   ) {
            dispatch(
              goToSpecificCompoHDMAM(
                componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_1]
              )
            );
   }
  };

  const disableConfirm = () => {
      if (clientForm?.googlePmaxStrategy?.status === BACKEND_STATUS.COMPLETED && 
          form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED )
          return false;
      else if (clientForm?.googlePmaxStrategy?.status === BACKEND_STATUS.COMPLETED && !form?.googleSearchAdsStrategyInfo)
          return false;
      else if (!clientForm?.googlePmaxStrategy && form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED)
          return false
      else 
          return true;
  }

  const handleConfirm = () => {
      if ([BACKEND_STATUS.ONGOING, BACKEND_STATUS.COMPLETED].includes(form?.fbAdsStrategyInfo?.status)) {
          dispatch(
              goToSpecificCompoHDMAM(
                  componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE]
              )
          );
      }
      else {
          dispatch(
              goToSpecificCompoHDMAM(
                  componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOAL]
              )
          );
      }
  }

  return (
   <>
    <GoogleCampaignSelector
      stage={GOOGLE_CAMPAIGN_SELECTOR_STAGES.Strategy}
      pmaxOngoingButton={pmaxOngoingButton}
      pmaxGeneratingButton={pmaxGeneratingButton}
      pmaxCompletedButton={pmaxCompletedButton}
      pmaxClickHandler={onClickHandlerForPMaxStrategySelect}
      searchOngoingButton={searchOngoingButton}
      searchGeneratingButton={searchGeneratingButton}
      searchCompletedButton={searchCompletedButton}
      searchClickHandler={onClickHandlerForSearchStrategy}
      searchUnlockButton={searchUnlockButton}
      pmaxUnlockButton={pmaxUnlockButton}
      disableConfirm={disableConfirm}
      handleConfirm={handleConfirm}
    />
    { startLoader && <PmaxLoader /> }
   </>
  );
};

export default StrategyGoogleCampaignSelector;
