import {SAGA_UPDATE_CLIENT_DETAILS,GET_CLIENT_DETAILS_BY_FORM_ID,SET_VALUE_IN_PLATFORMS_CHECKBOX,UPDATE_ARRAY_PLATFORMS_CHECKBOX} from "./enum";

export const updateClientDetailsReducer = (type,payload) => {
    return {
        type:type,
        payload:payload
    }
}

export const updateClientDetails = () => {
    return {
        type:SAGA_UPDATE_CLIENT_DETAILS
    }
}

export const getClientDetailsByFormId = () => {
    return {
        type:GET_CLIENT_DETAILS_BY_FORM_ID
    }
}

export const updateArrayCheckbox = (payload) => {
    return {
        type:UPDATE_ARRAY_PLATFORMS_CHECKBOX,
        payload:payload
    }
}


export const updateValueClient = (payload) => {
    return {
        type:"UPDATE_VALUE_CLIENT_WITHOUT_NESTED_OBJECT",
        payload:{
            key:payload.key,
            value:payload.value
        }
    }
}