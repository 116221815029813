import { Button, Grid, Typography } from "@mui/material";
import {box_icon  as BOX }from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {bulb_icon as BULB} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {facebook_icon as FACEBOOK} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {footer_icon as THANKS} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {gift_icon as GIFT} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {message_icon as MSG} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {monitor_icon as SCREEN} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {next_icon as NEXT} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {pen_icon as PEN} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {person_icon as PERSON} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import {phone_icon as PHONE} from "../../../assets/icons/onboardingDocExpectationIcons/onboardingDocExpectation";
import ANA from "../../../assets/images/Onboarding/analysis.svg";
import AVATAR from "../../../assets/images/Onboarding/avatar.svg";
import DOC from "../../../assets/images/Onboarding/document-icon.svg";
import GOAL from "../../../assets/images/Onboarding/goal.svg";
import SCALE from "../../../assets/images/Onboarding/scale.svg";
import CHESS from "../../../assets/images/Onboarding/strategy-icon.svg";
import TEST from "../../../assets/images/Onboarding/test.svg";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import "./review.css";
import {
  updateHDMCompoReduxClient,
  goBackHDMClientCompo,
} from "../../../api/HDMClient/componentTracking/action";
import { isPlatformSelectedHDM } from "../../../utils/accountManagerUtil";
const ReviewHDM = () => {
  const clientData = useSelector((state) => state.hdmClient);
  const dispatch = useDispatch();
  const desiredDate = (date) =>
    moment(date).add(10, "days").format("YYYY-MM-DD");
  return (
    <div style={{ width: "100%" }}>
      <div className="proposal_layout proposal_flex_top ">
        <div className="preview_head">
          <h4>ONBOARDING DOCUMENT</h4>
          <h2>Horizons Digital Marketing </h2>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="back_button">
          {" "}
          <Button
            style={{ color: "#0869FB" }}
            onClick={() => dispatch(goBackHDMClientCompo())}
          >
            BACK{" "}
          </Button>
        </div>
        <div className="footer_content">
          <Button
            variant="contained"
            onClick={() => {
              dispatch(updateHDMCompoReduxClient());
            }}
          >
            PROCEED
          </Button>
        </div>
      </div>
      <div className="review12"></div>
      <div className="scroll_items">
        {/* <div className='hero2'  >
                <ReactLogo/>
                
            </div> */}
        <div className="brief">
          <div className="brief_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={DOC}
                alt=""
              />
              Briefing Document
            </h3>
          </div>
          <div className="pricing_card">
            {/* <div className="card">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Budgets
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                  marginTop: "24px",
                }}
              >
                $ {clientData.onboardingInfo.aboutBusiness.fbCamaignBudget}
              </b>
            </div> */}
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "98px",
                  marginTop: "35px",
                }}
              >
                Budgets
              </Typography>
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "13px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      marginLeft: "32.95px",
                    }}
                  >
                    GOOGLE
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "24px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                      marginTop: "24px",
                    }}
                  >
                    {isPlatformSelectedHDM(clientData, "google-ads") !== -1
                      ? `$${
                          clientData.onboardingInfo.clientAccess.platforms.find(
                            (platform) => platform.platform === "google-ads"
                          ).spends
                        }`
                      : "-"}
                  </b>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "13px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      marginLeft: "32.95px",
                    }}
                  >
                    FACEBOOK
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "24px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                      marginTop: "24px",
                    }}
                  >
                    {isPlatformSelectedHDM(clientData, "facebook-ads") !== -1
                      ? `$${
                          clientData.onboardingInfo.clientAccess.platforms.find(
                            (platform) => platform.platform === "facebook-ads"
                          ).spends
                        }`
                      : "-"}
                  </b>
                </Grid>
              </Grid>
            </div>
            {/*<div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Scope of Work
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                  marginTop: "24px",
                }}
              >
                20% $10
              </b>
              </div>*/}

            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Set Target Launch Date
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                  marginTop: "24px",
                }}
              >
                {desiredDate(clientData.accessSharing.updatedOn)}
              </b>
            </div>
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Break - even Point{" "}
                <b
                  style={{
                    fontFamily: "Lato",
                    fontSize: "12px",
                    lineHeight: "21px",
                    fontWeight: "400",
                    color: " #5B596B",
                  }}
                >
                  {" "}
                  {/*(AOV - COGS PER AOV + SHIPPING OER AOV)*/}
                </b>{" "}
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                  marginTop: "24px",
                }}
              >
                ${" "}
                {parseInt(
                  clientData.onboardingInfo.aboutBusiness.averageOrderValue
                ) -
                  parseInt(clientData.onboardingInfo.aboutBusiness.cogsPerAov) +
                  parseInt(
                    clientData.onboardingInfo.aboutBusiness.shippingCostPerAov
                  )}
              </b>
            </div>
          </div>
        </div>

        <div className="strategy_goal">
          <div className="strategy_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={CHESS}
                alt=""
              />
              Our Strategy Goals
            </h3>
          </div>
          <Grid container xs={12}>
            <Grid item xs={6} style={{ paddingLeft: "24px" }}>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={GOAL} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Goals & Objectives
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.09px",
                      color: "#666666",
                    }}
                  >
                    Determine Short & Long Term goals <br /> profitability
                    analysis
                  </b>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #EBEBEB",
                  width: "369px",
                  marginTop: "32px",
                  marginBottom: "32px",
                }}
              ></div>

              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={ANA} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Analysis
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#333333",
                    }}
                  >
                    What is the biggest bottleneck in the <br /> success of your
                    store? <br />
                  </b>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#666666",
                    }}
                  >
                    Not just ads… Creative, website, brand etc.
                  </b>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #EBEBEB",
                  width: "369px",
                  marginTop: "32px",
                  marginBottom: "32px",
                }}
              ></div>

              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={AVATAR} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Avatars
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.09px",
                      color: "#666666",
                    }}
                  >
                    • WHO are we selling to <br /> • Market research &
                    competitive research
                  </b>
                  {/* <ul style={{padding:'0px',fontSize:'14px',fontWeight:'400',lineHeight:'20px',color:'#666666'}}>
                                    <li>WHO are we selling to</li>
                                    <li>Market research & competitive research</li>
                                </ul> */}
                </div>
              </div>
            </Grid>
            {/* <Grid xs={1}></Grid> */}
            <Grid item xs={6}>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={TEST} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Testing
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.09px",
                      color: "#666666",
                    }}
                  >
                    • Test what will move the needle the most
                    <br /> • Avatars → Hooks → Ads (ad copy is last)
                  </b>
                  {/* <ul style={{padding:'0px',fontSize:'14px',fontWeight:'400',lineHeight:'20px',color:'#666666'}}>
                                    <li> Test what will move the needle the most</li>
                                    <li> Avatars → Hooks → Ads (ad copy is last)</li>
                                </ul> */}
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #EBEBEB",
                  width: "369px",
                  marginTop: "32px",
                  marginBottom: "32px",
                }}
              ></div>

              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={SCALE} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Scale
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.09px",
                      color: "#666666",
                    }}
                  >
                    • Vertical & Horizontal scaling{" "}
                  </b>
                  {/* <ul style={{padding:'0px',fontSize:'14px',fontWeight:'400',lineHeight:'20px',color:'#666666'}}>
                                    <li>WHO are we selling to</li>
                                    <li>Market research & competitive research</li>
                                </ul> */}
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #EBEBEB",
                  width: "369px",
                  marginTop: "66px",
                  marginBottom: "32px",
                }}
              ></div>
            </Grid>
          </Grid>
        </div>
        {/* <div className='hero2'>
                                    <Expect/>
            </div>
            <div className='communication'>
                <div className='comm_head'>
                <h3 style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px",margin:'0px'}}><img style={{position:'relative', top:'15px'}} src={COMM} alt="" />Communication & Reporting</h3>
          
                </div>

                <Grid container xs={12} >
                    <Grid item xs={6} style={{paddingLeft:'32px'}}>
                        <div className='main_container'>
                            <div className='head'>
                                <Typography>
                                    From Us
                                </Typography>
                                
                            </div>
                            <div className='listing'>
                                    <ul>
                                        <li>Weekly Reporting</li>
                                        <li>2 monthly meetings (1 strategy call + 1 touch <br /> base call)</li>
                                        <li>Availability as needed through AM calendar</li>
                                    </ul>
                                </div>
                        </div>
                    </Grid>
                </Grid>
            </div> */}
        <div className="hero1"></div>

        <div className="communication_title">
          <div className="commmunication_head">
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={MSG}
                alt=""
              />{" "}
              Communication & Reporting
            </h3>
          </div>
          <div className="communication_content">
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col-sm-6"
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 1.5398px 39px rgba(20, 20, 43, 0.04)",
                  borderRadius: 24,
                  width: "100%",
                  height: 285,
                  margin: "10px 40px",
                }}
              >
                {" "}
                <div
                  style={{
                    background: "#F5F9FF",
                    borderRadius: "24px 24px 0px 0px",
                    height: 62.39,
                    color: "#170F49",
                    padding: 20,
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  {" "}
                  From Us
                </div>
                <div>
                  <ul>
                    <li style={{ marginBottom: 35 }}> Weekly Reporting</li>
                    <li style={{ marginBottom: 35 }}>
                      2 monthly meetings (1 strategy call + 1 touch base call)
                    </li>
                    <li>Availability as needed through AM calendar</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-sm-6"
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 1.5398px 39px rgba(20, 20, 43, 0.04)",
                  borderRadius: 24,
                  height: 285,
                  width: "100%",
                  margin: "10px 40px",
                }}
              >
                <div
                  style={{
                    background: "#F5F9FF",
                    borderRadius: "24px 24px 0px 0px",
                    height: 62.39,
                    color: "#170F49",
                    padding: 20,
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  {" "}
                  From You
                </div>
                <div>
                  <ul>
                    <li style={{ marginBottom: 30 }}>
                      {" "}
                      We are a team here… Let us know of any changes to website,
                      product availability etc.
                    </li>
                    <li style={{ marginBottom: 30 }}>
                      Timely responses to emails
                    </li>
                    <li style={{ marginBottom: 30 }}>
                      1 week notice for new sales/content
                    </li>
                    <li>What is the best way to contact you?</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="what_we_need_title">
          <div className="what_we_need_head">
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={PERSON}
                alt=""
              />{" "}
              What We Need From You
            </h3>
          </div>
          <div className="what_we_need_content">
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={PHONE} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Consistent Creatives
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Ut suscipit mi cursus hendrerit ac nuncol leo pellentesque
                  mauris mattis.
                </div>
              </div>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={BULB} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Notifying Changes
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Turpis consectetur feugiat nullam egestas rhoncus amet quis
                  hac enim neque.
                </div>
              </div>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={SCREEN} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Timely Communication
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Ipsum at quis dui ridiculus blandit dolor arcu penatibus. Diam
                  nunc sit et nunc.
                </div>
              </div>
            </div>
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={PEN} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Fast Action
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Porta sit viverra ultricies at blandit dui. Nibh at sed et
                  pellentesque eget.
                </div>
              </div>
              <div
                className="col"
                style={{ height: 200, width: "100%", marginLeft: 25 }}
              >
                <img src={BOX} />
                <h3 style={{ color: "#1E1B39", fontWeight: 500, fontSize: 20 }}>
                  Sounds Fair?
                </h3>
                <div
                  style={{ color: "#6E6C83", fontWeight: 400, fontSize: 12 }}
                >
                  Ac in magna integer consectetur faucibus vitae volutpat lectus
                  sit aliquet enim.
                </div>
              </div>
              <div className="col" style={{ width: "100%" }}></div>
            </div>
          </div>
        </div>

        <div className="facebook_title">
          <div className="facebook_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={FACEBOOK}
                alt=""
              />{" "}
              Facebook Tracking Post iOS Changes
            </h3>
          </div>
          <div className="facebook_content">
            <div className="row" style={{ display: "flex" }}>
              <div className="col" style={{ width: "100%" }}>
                <div
                  style={{
                    background: "#FFFFFF",
                    border: "0.7699px solid #EFF0F7",
                    height: 146,
                    boxShadow: "0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08)",
                    borderRadius: 10.5055,
                    padding: 22,
                    margin: 20,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#000000",
                      marginBottom: 7,
                    }}
                  >
                    Delayed Reporting
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 11,
                      color: "#6E6C83",
                    }}
                  >
                    Realtime reporting is no longer supported. It can take up to
                    3 days for a purchase to be reported in Ads manager.
                  </span>
                </div>
              </div>
              <div className="col" style={{ width: "100%" }}>
                <div
                  style={{
                    background: "#FFFFFF",
                    border: "0.7699px solid #EFF0F7",
                    height: 146,
                    boxShadow: "0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08)",
                    borderRadius: 10.5055,
                    padding: 22,
                    margin: 20,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#000000",
                      marginBottom: 7,
                    }}
                  >
                    Estimated Results
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 11,
                      color: "#6E6C83",
                    }}
                  >
                    Facebook is using statistical modelling to account for
                    conversions from iOS 14 users. The numbers reported in
                    Facebook are not real.
                  </span>
                </div>
              </div>
              <div className="col" style={{ width: "100%" }}>
                <div
                  style={{
                    background: "#FFFFFF",
                    border: "0.7699px solid #EFF0F7",
                    height: 146,
                    boxShadow: "0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08)",
                    borderRadius: 10.5055,
                    padding: 22,
                    margin: 20,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#000000",
                      marginBottom: 7,
                    }}
                  >
                    Loss of Attribution
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 11,
                      color: "#6E6C83",
                    }}
                  >
                    Realtime reporting is no longer supported. It can take up to
                    3 days for a purchase to be reported in Ads manager.
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="row" style={{ display: "flex" }}>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    01
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    Blended ROAS
                  </span>
                </div>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    02
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    UTMs
                  </span>
                </div>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    03
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    Correlations
                  </span>
                </div>
              </div>
              <div className="row" style={{ display: "flex" }}>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    04
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    On Platform Statistics
                  </span>
                </div>
                <div
                  className="col-sm-4"
                  style={{
                    margin: "25px 25px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: " #333333",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      paddingRight: 10,
                    }}
                  >
                    05
                  </span>
                  <span
                    style={{
                      color: "#000000",
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: 200,
                      fontSize: 18,
                    }}
                  >
                    Overall Revenues
                  </span>
                </div>
                <div
                  className="col-sm-4"
                  style={{ width: "100%", margin: "25px 25px" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bonus_title">
          <div className="bonus_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={GIFT}
                alt=""
              />{" "}
              Bonus
            </h3>
          </div>
          <div className="bonus_content">
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              >
                <div
                  style={{
                    textAlign: "center",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px 10px 0px 0px",
                    height: 60,
                    paddingTop: 20,
                  }}
                >
                  1 referral = $300/month
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#F5F9FF",
                    border: "1px solid #0869FB",
                    borderRadius: "0px 0px 10px 10px",
                    height: 60,
                    color: "#0869FB",
                    paddingTop: 20,
                  }}
                >
                  $3,600/year
                </div>
              </div>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              >
                <div
                  style={{
                    textAlign: "center",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px 10px 0px 0px",
                    height: 60,
                    paddingTop: 20,
                  }}
                >
                  2 referrals = $600/month
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#F5F9FF",
                    border: "1px solid #0869FB",
                    borderRadius: "0px 0px 10px 10px",
                    height: 60,
                    color: "#0869FB",
                    paddingTop: 20,
                  }}
                >
                  $7,200/year
                </div>
              </div>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              >
                <div
                  style={{
                    textAlign: "center",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px 10px 0px 0px",
                    height: 60,
                    paddingTop: 20,
                  }}
                >
                  5 referrals = $1500/month
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#F5F9FF",
                    border: "1px solid #0869FB",
                    borderRadius: "0px 0px 10px 10px",
                    height: 60,
                    color: "#0869FB",
                    paddingTop: 20,
                  }}
                >
                  $18,000/year
                </div>
              </div>
            </div>
            <div className="row" style={{ display: "flex" }}>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              >
                <div
                  style={{
                    textAlign: "center",
                    background: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px 10px 0px 0px",
                    height: 60,
                    paddingTop: 20,
                  }}
                >
                  20 referrals = $6000/month
                </div>
                <div
                  style={{
                    textAlign: "center",
                    background: "#F5F9FF",
                    border: "1px solid #0869FB",
                    borderRadius: "0px 0px 10px 10px",
                    height: 60,
                    color: "#0869FB",
                    paddingTop: 20,
                  }}
                >
                  $72,000/year
                </div>
              </div>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              ></div>
              <div
                className="col"
                style={{ width: "100%", margin: "10px 30px " }}
              ></div>
            </div>
          </div>
        </div>
        <div classname="nextSteps_title">
          <div className="nextSteps_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={NEXT}
                alt=""
              />{" "}
              Next Steps
            </h3>
          </div>
          <div className="nextSteps_content">
            <Grid container xs={12}>
              <Grid item xs={3}>
                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      01
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Internal Strategy Call
                  </span>
                </div>

                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      02
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Historical Analysis
                  </span>
                </div>

                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      03
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Market Research
                  </span>
                </div>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid item xs={3}>
                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      04
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Campaign set-up
                  </span>
                </div>

                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      05
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Strategy Call & Ad approval
                  </span>
                </div>
                <div className="list">
                  <div className="circle">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }}
                    >
                      06
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "28px",
                      color: "#000000",
                    }}
                  >
                    Go LIVE
                    <div style={{ color: "#6D6D6D", fontSize: 12 }}>
                      (target is 10 business days from today)
                    </div>
                    {/* <b style={{ color: "#6D6D6D", fontSize: 11,lineHeight:'14px',fontWeight:'400' }}>(target is 10 business days from today)</b> */}
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="strategy123" style={{ height: 300, padding: 100 }}>
          <div
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 28,
              textAlign: "center",
              color: "#686868",
            }}
          >
            Let's Book Our
          </div>
          <div
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 60,
              textAlign: "center",
              color: "#000000",
            }}
          >
            Strategy Call
          </div>
        </div>
        <div classname="footer-bar">
          <img src={THANKS} style={{ width: "100%", height: 780 }} />
        </div>
      </div>
    </div>
  );
};
export default ReviewHDM;
