import { useEffect, useState } from "react";
import { updateCompoReduxClient } from "../../../api/componentTracking/action";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import "./HomePage.css";
import { bookicon as BOOK } from "../../../assets/icons/common/common";
import { fb_icon as FB } from "../../../assets/icons/social/social";
import { google2 as GOOGLE } from "../../../assets/icons/social/social";
import { proposal as PROPOSAL } from "../../../assets/icons/links/links";
import { deck as DECK } from "../../../assets/icons/links/links";
import { creative as CREATIVE } from "../../../assets/icons/links/links";
import { campaign as CAMPAIGN } from "../../../assets/icons/links/links";
import GOV from "../../../assets/images/governai.png";
import TAR from "../../../assets/images/targetai.png";
import CRE from "../../../assets/images/creativeai.png";

import { blue_platform_icon as bluePlatformIcon } from "../../../assets/icons/icon";
import { green_preview_eye as previewEyeIcon } from "../../../assets/icons/icon";

import { updateLeftSidebarStatusClient } from "../../../api/componentTracking/action";
import GoToSpecificCompoButton from "../../ButtonPrimary/GoToSpecificCompoButton";
import GoToNextCompoButton from "../../ButtonPrimary/GoToNextCompoButton";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import { getClientDetailsByFormId } from "../../../api/client/action";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import { sharedAssetsGetApi } from "../../../api/sharedAssets/saga";
import { getSharedAssetsGoogel } from "../../../api/sharedAssets/action";
import {
  clientDashboardIds,
  facebookCampaignPreview,
  googleCampaignPreview,
  LDcomponentWithIndexClient,
  showAnalyticsDashboard,
  showCampaignPreviewClient,
} from "../../../utils/clientDashboardIdsUtil";
import { Typography, Tooltip } from "@mui/material";
import CustomToolkitForInfo from "./CustomToolkitForInfo";
const StatusWithRightBtn = ({
  currentStep,
  nextStep,
  btnTitle,
  belowTitle,
  compoNumber,
  disabled = false,
  showTwoBtn = false,
}) => {
  return (
    <div className="status_bubble_inner">
      <div className="status_bar_row">
        <div className="bubble_current_status">
          <span className="icon">
            <img src={previewEyeIcon} />
          </span>
          <p>{currentStep}</p>
        </div>
        <div className="separator_status"></div>
        <div className="bubble_next_status">
          <div
            className={`${
              disabled
                ? "bubble_next_action disable_action"
                : "bubble_next_action"
            }`}
          >
            <span className="icon">
              <img src={bluePlatformIcon} />
            </span>
            <GoToSpecificCompoButton
              className="started_button"
              title={btnTitle}
              type="client"
              compoNumber={compoNumber}
              disabled={disabled}
            />
          </div>
          <p>{nextStep}</p>
        </div>
      </div>
      <p className="bubble_status_info">{belowTitle}</p>
      {showTwoBtn && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <GoToSpecificCompoButton
            className="started_button"
            title="Google"
            type="client"
            compoNumber={
              LDcomponentWithIndexClient[
                clientDashboardIds.CAMPAIGN_PREVIEW_CLIENT
              ]
            }
            disabled={disabled}
          />
          <GoToSpecificCompoButton
            className="started_button"
            title="Facebook"
            type="client"
            disabled={disabled}
            compoNumber={
              LDcomponentWithIndexClient[
                clientDashboardIds.CAMPAIGN_PREVIEW_FACEBOOK
              ]
            }
          />
        </div>
      )}
    </div>
  );
};

const StatusWithSingleTitle = ({
  title,
  showTwoBtn = false,
  googlePreviewDisabled,
  facebookPreviewDisabled,
  getStartedDisabled,
  compoNumber,
}) => {
  return (
    <>
      <img src={BOOK} alt="" />
      <span className="single_title_start">{title}</span>
      {!showTwoBtn && (
        // <GoToNextCompoButton
        //   className="started_button"
        //   title="Get Started"
        //   type="client"
        // />
        <GoToSpecificCompoButton
          className="started_button"
          title="Get Started"
          type="client"
          disabled={getStartedDisabled}
          compoNumber={compoNumber}
        />
      )}
      {showTwoBtn && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <GoToSpecificCompoButton
            className="started_button"
            disabled={googlePreviewDisabled}
            title="Google"
            type="client"
            compoNumber={
              LDcomponentWithIndexClient[
                clientDashboardIds.CAMPAIGN_PREVIEW_CLIENT
              ]
            }
          />
          <GoToSpecificCompoButton
            className="started_button"
            disabled={facebookPreviewDisabled}
            title="Facebook"
            type="client"
            compoNumber={
              LDcomponentWithIndexClient[
                clientDashboardIds.CAMPAIGN_PREVIEW_FACEBOOK
              ]
            }
          />
        </div>
      )}
    </>
  );
};
export const dbStatus = {
  PENDING: "PENDING",
  CONNECTED: "CONNECTED",
  NOT_CONNECTED: "NOT_CONNECTED",
  VERIFYING: "VERIFYING",
  READ_ACCESS: "READ_ACCESS",
  ACCESS_REVOKED: "ACCESS_REVOKED",
  COMPLETE_ACCESS: "COMPLETE_ACCESS",
  VERIFYING_COMPLETE_ACCESS: "VERIFYING_COMPLETE_ACCESS",
  COMPLETED_ACCESS_NOT_SHARED: "COMPLETED_ACCESS_NOT_SHARED",
};
export const mappedStatusDb = {
  [dbStatus.VERIFYING]: "Verifying",
  [dbStatus.READ_ACCESS]: "Read Access",
  [dbStatus.ACCESS_REVOKED]: "Not Shared",
  [dbStatus.VERIFYING_COMPLETE_ACCESS]: "Verifying",
  [dbStatus.COMPLETED_ACCESS_NOT_SHARED]: "Not Shared",
  [dbStatus.COMPLETE_ACCESS]: "Complete Access",
};
export const checkingReadAccess = (platformSelected, accountList) => {
  if (platformSelected.length === 2) {
    if (
      (accountList.fbAccess.adAccount.status === dbStatus.VERIFYING &&
        accountList.fbAccess.adAccount.accountId.length === 0) ||
      (accountList.googleAccess.googleAdAccount.status === dbStatus.VERIFYING &&
        accountList.googleAccess.googleAdAccount.accountId.length === 0)
    ) {
      return dbStatus.PENDING;
    } else if (
      (accountList.fbAccess.adAccount.status === dbStatus.VERIFYING &&
        accountList.fbAccess.adAccount.accountId.length !== 0) ||
      (accountList.googleAccess.googleAdAccount.status === dbStatus.VERIFYING &&
        accountList.googleAccess.googleAdAccount.accountId.length !== 0)
    ) {
      return dbStatus.VERIFYING;
    } else if (
      accountList.fbAccess.adAccount.status === dbStatus.ACCESS_REVOKED ||
      accountList.googleAccess.googleAdAccount.status ===
        dbStatus.ACCESS_REVOKED
    ) {
      return dbStatus.ACCESS_REVOKED;
    } else if (
      accountList.fbAccess.adAccount.status === dbStatus.READ_ACCESS &&
      accountList.googleAccess.googleAdAccount.status === dbStatus.READ_ACCESS
    ) {
      return dbStatus.READ_ACCESS;
    }
  } else if (
    platformSelected.length === 1 &&
    platformSelected[0].platform === "google-ads"
  ) {
    if (
      accountList.googleAccess.googleAdAccount.status === dbStatus.VERIFYING &&
      accountList.googleAccess.googleAdAccount.accountId.length === 0
    ) {
      return dbStatus.PENDING;
    } else if (
      accountList.googleAccess.googleAdAccount.status === dbStatus.VERIFYING &&
      accountList.googleAccess.googleAdAccount.accountId.length !== 0
    ) {
      return dbStatus.VERIFYING;
    } else if (
      accountList.googleAccess.googleAdAccount.status ===
      dbStatus.ACCESS_REVOKED
    ) {
      return dbStatus.ACCESS_REVOKED;
    } else if (
      accountList.googleAccess.googleAdAccount.status === dbStatus.READ_ACCESS
    ) {
      return dbStatus.READ_ACCESS;
    }
  } else if (
    platformSelected.length === 1 &&
    platformSelected[0].platform === "facebook-ads"
  ) {
    if (
      accountList.fbAccess.adAccount.status === dbStatus.VERIFYING &&
      accountList.fbAccess.adAccount.accountId.length === 0
    ) {
      return dbStatus.PENDING;
    } else if (
      accountList.fbAccess.adAccount.status === dbStatus.VERIFYING &&
      accountList.fbAccess.adAccount.accountId.length !== 0
    ) {
      return dbStatus.VERIFYING;
    } else if (
      accountList.fbAccess.adAccount.status === dbStatus.ACCESS_REVOKED
    ) {
      return dbStatus.ACCESS_REVOKED;
    } else if (accountList.fbAccess.adAccount.status === dbStatus.READ_ACCESS) {
      return dbStatus.READ_ACCESS;
    }
  }
  return dbStatus.PENDING;
};
export const checkingForCompleteAccess = (platformSelected, accountList) => {
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (
        accountList.fbAccess.adAccount.status ===
        dbStatus.COMPLETED_ACCESS_NOT_SHARED
      ) {
        return dbStatus.COMPLETED_ACCESS_NOT_SHARED;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status ===
        dbStatus.COMPLETED_ACCESS_NOT_SHARED
      ) {
        return dbStatus.COMPLETED_ACCESS_NOT_SHARED;
      }
    }
  }
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (
        accountList.fbAccess.adAccount.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
      ) {
        return dbStatus.VERIFYING_COMPLETE_ACCESS;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
      ) {
        return dbStatus.VERIFYING_COMPLETE_ACCESS;
      }
    }
  }
  let complete = true;
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (accountList.fbAccess.adAccount.status !== dbStatus.COMPLETE_ACCESS) {
        complete = false;
        break;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status !==
        dbStatus.COMPLETE_ACCESS
      ) {
        complete = false;
        break;
      }
    }
  }
  if (
    checkingReadAccess(platformSelected, accountList) === dbStatus.PENDING &&
    !complete
  ) {
    return dbStatus.PENDING;
  }
  if (
    dbStatus.VERIFYING === checkingReadAccess(platformSelected, accountList) &&
    !complete
  ) {
    return dbStatus.VERIFYING;
  }
  if (
    dbStatus.ACCESS_REVOKED ===
      checkingReadAccess(platformSelected, accountList) &&
    !complete
  ) {
    return dbStatus.ACCESS_REVOKED;
  }
  if (
    checkingReadAccess(platformSelected, accountList) ===
      dbStatus.READ_ACCESS &&
    !complete
  ) {
    return dbStatus.READ_ACCESS;
  }
  return complete
    ? dbStatus.COMPLETE_ACCESS
    : dbStatus.VERIFYING_COMPLETE_ACCESS;
};
const isAccountConnected = (platformName, accountList) => {
  if (platformName === "facebook-ads") {
    if (
      accountList?.fbAccess?.adAccount?.status === dbStatus.READ_ACCESS ||
      accountList?.fbAccess?.adAccount?.status === dbStatus.COMPLETE_ACCESS ||
      accountList?.fbAccess?.adAccount?.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
    ) {
      return dbStatus.CONNECTED;
    } else if (
      accountList?.fbAccess?.adAccount?.status === dbStatus.VERIFYING &&
      accountList.fbAccess.adAccount.accountId.length !== 0
    ) {
      return dbStatus.VERIFYING;
    }
  } else if (platformName === "google-ads") {
    if (
      accountList?.googleAccess?.googleAdAccount?.status ===
        dbStatus.READ_ACCESS ||
      accountList?.googleAccess?.googleAdAccount?.status ===
        dbStatus.COMPLETE_ACCESS ||
      accountList?.googleAccess?.googleAdAccount?.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
    ) {
      return dbStatus.CONNECTED;
    } else if (
      accountList?.googleAccess?.googleAdAccount?.status ===
        dbStatus.VERIFYING &&
      accountList?.googleAccess?.googleAdAccount?.accountId?.length !== 0
    ) {
      return dbStatus.VERIFYING;
    }
  }
  return dbStatus.NOT_CONNECTED;
};
const HomePage = () => {
  const dispatch = useDispatch();
  const [isCampaignApproved, setIsCampaignApproved] = useState(false);
  const clientState = useSelector((state) => state.client);
  const onboardingStatus = clientState?.onboardingInfo.status;
  const sowPricingStatus = clientState?.sowPricingInfo.status;

  const proposalStage =
    clientState.proposalIterations.length !== 0
      ? clientState["proposalIterations"][
          clientState.proposalIterations.length - 1
        ]["state"]
      : null;
  const sharedAssetsAccountsGoogle = useSelector(
    (state) => state.sharedAssets.googleSharedAssetsAccounts
  );
  const intakeForm = useSelector((state) => state.client.intakeInfo);
  const strategyIterations = useSelector(
    (state) => state.client.strategyIterations
  );
  const [sharedAssets, setSharedAssets] = useState([]);
  const [sharedAssetsStatus, setSharedAssetsStatus] = useState(null);
  const [provideCompleteAccess, setProvideCompleteAccess] = useState(false);
  const [isCompleteAccess, setCompleteAccess] = useState(false);
  const [isCompleteAccessVerifying, setCompleteAceessVerifying] =
    useState(false);
  const [isCompleteAccessNotShared, setCompleteAccessNotShared] =
    useState(false);
  const platformSelected = useSelector(
    (state) => state?.client?.onboardingInfo?.platformInfo?.platforms
  );
  const accountid = useSelector((state) => state?.client?.accessSharing);

  console.log(accountid);

  // const googleId = accountid.googleAccess.googleAdAccount.accountId;

  // const facebookId = accountid.fbAccess.adAccount.accountId;

  // console.log(
  //   googleId.length > 10 ? googleId.substring(0, 11) + '...' : googleId
  // );
  // console.log(
  //   facebookId.length > 10 ? facebookId.substring(0, 11) + '...' : facebookId
  // );

  useEffect(() => {
    if (showCampaignPreviewClient(clientState)) {
      setIsCampaignApproved(true);
    }
  }, [clientState]);

  useEffect(() => {
    dispatch(getClientDetailsByFormId());
    dispatch(getSharedAssetsGoogel());
  }, []);
  useEffect(() => {
    sharedAssetsGetApi().then((res) => {
      setSharedAssets(res.gadsAssets);
      let isCompleteAccess = false;
      let isCompleteAccessVerifying = false;
      let isRadAccess = false;
      let isCompleteAccessNotShare = false;
      let count = 0;
      res.gadsAssets.forEach(({ status }) => {
        if (status === "VERIFYING") {
          count++;
        } else if (status === "COMPLETE_ACCESS") {
          isCompleteAccess = true;
        } else if (status === "VERIFYING_COMPLETE_ACCESS") {
          isCompleteAccessVerifying = true;
        } else if (status === "READ_ACCESS") {
          isRadAccess = true;
        } else if (status === "COMPLETED_ACCESS_NOT_SHARED") {
          isCompleteAccessNotShare = true;
        }
      });
      setCompleteAccess(isCompleteAccess);
      setCompleteAceessVerifying(isCompleteAccessVerifying);
      count === res.gadsAssets.length && setSharedAssetsStatus("VERIFYING");
      const indexWithRevokeAccess = res.gadsAssets.findIndex(
        (ele) => ele.status === "ACCESS_REVOKED"
      );
      indexWithRevokeAccess !== -1 &&
        count === 0 &&
        !isRadAccess &&
        !isCompleteAccessNotShare &&
        !isCompleteAccessVerifying &&
        !isCompleteAccess &&
        setSharedAssetsStatus("ACCESS_REVOKED");
      count !== 0 && setSharedAssetsStatus("VERIFYING");
      const allSharedAssetNotEqualComplAccessIndex = res.gadsAssets.findIndex(
        (ele) => ele.status !== "COMPLETE_ACCESS"
      );
      clientState.proposalIterations.length !== 0 &&
        clientState.proposalIterations[
          clientState.proposalIterations.length - 1
        ].state === "APPROVED" &&
        allSharedAssetNotEqualComplAccessIndex !== -1 &&
        setProvideCompleteAccess(true);
      setCompleteAccessNotShared(
        isCompleteAccessNotShare &&
          !isCompleteAccess &&
          !isCompleteAccessVerifying
          ? true
          : false
      );
    });
  }, [clientState.proposalIterations]);
  return (
    <div className="main_hero">
      <div className="onboard">
        <div className="hero_banner">
          <div className="banner_left">
            <span>Welcome</span>
            <h2>{getLocalStorage("brandId")}</h2>
            <p>
              {clientState?.onboardingInfo?.status !== "COMPLETED"
                ? "Please wait while we connect with you on a call. You can use the dashboard after that to provide us read access to your accounts."
                : `Meet the most powerful product to infuse AI-led decision-making in
              every aspect of your marketing.`}
            </p>
          </div>
          <div className="bubble_banner">
            {
              // onboardingStatus === "PENDING" ? (
              //   <StatusWithSingleTitle title="Let's get started." />
              // ) : onboardingStatus !== "COMPLETED" ? (
              //   <StatusWithSingleTitle title="Please complete your onboarding form" />
              // ) : sowPricingStatus === "ONGOING" ? (
              //   <StatusWithRightBtn
              //     currentStep="Onboarding form complete"
              //     nextStep="View Scope of work & pricing"
              //     btnTitle="Manage Scope of work & pricing"
              //     compoNumber={
              //       LDcomponentWithIndexClient[
              //         clientDashboardIds.SCOPE_PRICING_ADS_SETUP
              //       ]
              //     }
              //     belowTitle="Please add Scope of work & pricing details"
              //   />
              // ) :
              checkingReadAccess(platformSelected, accountid) ===
                dbStatus.PENDING && proposalStage === null ? (
                // <StatusWithRightBtn
                //   currentStep="Onboarding form complete"
                //   nextStep="Provide read access"
                //   btnTitle="View Read Access"
                //   compoNumber={
                //     LDcomponentWithIndexClient[clientDashboardIds.READ_ACCESS]
                //   }
                //   belowTitle="Please provide read access"
                // />
                <StatusWithSingleTitle
                  title={
                    clientState?.onboardingInfo?.status === "COMPLETED"
                      ? "Let's get started."
                      : "Onboarding Call Pending"
                  }
                  getStartedDisabled={
                    !(clientState?.onboardingInfo?.status === "COMPLETED")
                  }
                  compoNumber={
                    LDcomponentWithIndexClient[clientDashboardIds.READ_ACCESS]
                  }
                />
              ) : checkingReadAccess(platformSelected, accountid) ===
                  dbStatus.VERIFYING && proposalStage === null ? (
                <StatusWithRightBtn
                  currentStep="Read Access Shared"
                  nextStep="Checking for read access"
                  btnTitle="Checking Read Access"
                  compoNumber={null}
                  belowTitle=""
                  disabled={true}
                />
              ) : checkingReadAccess(platformSelected, accountid) ===
                  dbStatus.ACCESS_REVOKED && proposalStage === null ? (
                <StatusWithRightBtn
                  currentStep="Onboarding form complete"
                  nextStep="Read access not received"
                  btnTitle="Read access not received"
                  compoNumber={
                    LDcomponentWithIndexClient[clientDashboardIds.READ_ACCESS]
                  }
                  belowTitle=""
                />
              ) : checkingReadAccess(platformSelected, accountid) ===
                  dbStatus.READ_ACCESS && proposalStage === null ? (
                <StatusWithRightBtn
                  currentStep="Read Access Granted"
                  nextStep="Generating Proposal"
                  btnTitle="View Proposal"
                  compoNumber={null}
                  belowTitle="You will be notified as soon as the proposal is ready for review"
                  disabled={true}
                />
              ) : proposalStage === "GENERATED" ? (
                <StatusWithRightBtn
                  currentStep="Read Access Granted"
                  nextStep="Proposal Generated"
                  btnTitle="Review Proposal"
                  compoNumber={
                    LDcomponentWithIndexClient[clientDashboardIds.PROPOSAL_TAB]
                  }
                  belowTitle="Your proposal has been generated and is ready for review"
                />
              ) : proposalStage === "CHANGES_SUGGESTED" ? (
                <StatusWithRightBtn
                  currentStep="Changes In Proposal"
                  nextStep="Generating New Proposal"
                  btnTitle="View Proposal"
                  compoNumber={null}
                  belowTitle="Your revised proposal is now being generated."
                  disabled={true}
                />
              ) : proposalStage === "CHANGES_APPLIED" ? (
                <StatusWithRightBtn
                  currentStep="Changes In Proposal"
                  nextStep="New Proposal Generated"
                  btnTitle="View Proposal"
                  compoNumber={
                    LDcomponentWithIndexClient[clientDashboardIds.PROPOSAL_TAB]
                  }
                  belowTitle="You can now view your revised proposal."
                />
              ) : checkingReadAccess(platformSelected, accountid) ===
                  dbStatus.READ_ACCESS && intakeForm?.status !== "COMPLETED" ? (
                <StatusWithRightBtn
                  currentStep="Proposal Accepted"
                  nextStep="Fill Intake Form"
                  btnTitle="View Intake Form"
                  compoNumber={
                    LDcomponentWithIndexClient[
                      clientDashboardIds.QUEST_INTAKE_FORM1
                    ]
                  }
                />
              ) : intakeForm?.status === "COMPLETED" &&
                ![
                  dbStatus.COMPLETED_ACCESS_NOT_SHARED,
                  dbStatus.VERIFYING_COMPLETE_ACCESS,
                  dbStatus.COMPLETE_ACCESS,
                ].includes(
                  checkingForCompleteAccess(platformSelected, accountid)
                ) ? (
                <StatusWithRightBtn
                  currentStep="Intake Form Complete"
                  nextStep="Provide Complete Access"
                  btnTitle="Complete Access"
                  compoNumber={
                    LDcomponentWithIndexClient[
                      clientDashboardIds.CLIENT_COMPLETE_ACCESS
                    ]
                  }
                />
              ) : checkingForCompleteAccess(platformSelected, accountid) ===
                dbStatus.COMPLETED_ACCESS_NOT_SHARED ? (
                <StatusWithRightBtn
                  currentStep="Intake Form Complete"
                  nextStep="Complete Access Not Received"
                  btnTitle="Complete Access Not Received"
                  compoNumber={
                    LDcomponentWithIndexClient[
                      clientDashboardIds.CLIENT_COMPLETE_ACCESS
                    ]
                  }
                />
              ) : checkingForCompleteAccess(platformSelected, accountid) ===
                dbStatus.VERIFYING_COMPLETE_ACCESS ? (
                <StatusWithRightBtn
                  currentStep="Intake Form Complete"
                  nextStep="Verifying Complete Access"
                  btnTitle="Verifying Complete Access"
                  compoNumber={null}
                  disabled={true}
                />
              ) : checkingForCompleteAccess(platformSelected, accountid) ===
                  dbStatus.COMPLETE_ACCESS &&
                strategyIterations.length === 0 ? (
                <StatusWithRightBtn
                  currentStep="Complete Access Received"
                  nextStep="Generating Strategy Deck"
                  btnTitle="View Strategy Deck"
                  compoNumber={null}
                  disabled={true}
                />
              ) : strategyIterations.length !== 0 &&
                strategyIterations[strategyIterations.length - 1].state ===
                  "GENERATED" ? (
                <StatusWithRightBtn
                  currentStep="Complete Access Received"
                  nextStep="Strategy Deck Generated"
                  btnTitle="View Strategy Deck"
                  compoNumber={
                    LDcomponentWithIndexClient[
                      clientDashboardIds.STRATEGY_DECK_INTRO
                    ]
                  }
                />
              ) : strategyIterations.length !== 0 &&
                strategyIterations[strategyIterations.length - 1].state ===
                  "CHANGES_SUGGESTED" ? (
                <StatusWithRightBtn
                  currentStep="Changes In Strategy Deck"
                  nextStep="Generating New Strategy Deck"
                  btnTitle="View Strategy Deck"
                  compoNumber={null}
                  disabled={true}
                />
              ) : strategyIterations.length !== 0 &&
                strategyIterations[strategyIterations.length - 1].state ===
                  "CHANGES_APPLIED" ? (
                <StatusWithRightBtn
                  currentStep="Changes In Strategy Deck"
                  nextStep="New strategy deck generated"
                  btnTitle="View Strategy Deck"
                  compoNumber={
                    LDcomponentWithIndexClient[
                      clientDashboardIds.STRATEGY_DECK_INTRO
                    ]
                  }
                />
              ) : strategyIterations.length !== 0 &&
                strategyIterations[strategyIterations.length - 1].state ===
                  "APPROVED" &&
                !isCampaignApproved ? (
                <StatusWithRightBtn
                  currentStep="Strategy Deck Approved"
                  nextStep="Generating campaigns"
                  btnTitle="Generating campaigns"
                  compoNumber={null}
                  disabled={true}
                  showTwoBtn={true}
                />
              ) : isCampaignApproved ? (
                <StatusWithSingleTitle
                  title="Campaigns Generated"
                  showTwoBtn={true}
                  googlePreviewDisabled={!googleCampaignPreview(clientState)}
                  facebookPreviewDisabled={
                    !facebookCampaignPreview(clientState)
                  }
                />
              ) : (
                <></>
              )
            }
          </div>
        </div>
        {console.log(
          "checkingForCompleteAccess(platformSelected,accountid)",
          checkingForCompleteAccess(platformSelected, accountid)
        )}
      </div>
      <div className="grid_home_page">
        <div className="grid_colum">
          <div className="grid_account_column">
            <p className="home_title">Accounts</p>

            <div className="social_media">
              {[dbStatus.CONNECTED, dbStatus.VERIFYING].includes(
                isAccountConnected("facebook-ads", accountid)
              ) ? (
                <div className="social_home">
                  <div className="social_list_home">
                    <img src={FB} alt="" />
                    <p className="social_name_title">
                      Facebook Ads <span>|</span> Account ID :{" "}
                    </p>
                    {accountid.fbAccess.adAccount.accountId.length > 10 ? (
                      <CustomToolkitForInfo
                        text={
                          accountid.fbAccess.adAccount.accountId.substring(
                            0,
                            10
                          ) + "..."
                        }
                        originalText={accountid.fbAccess.adAccount.accountId}
                        type="facebook"
                      />
                    ) : (
                      accountid.fbAccess.adAccount.accountId
                    )}
                  </div>
                  <div
                    className={
                      isAccountConnected("facebook-ads", accountid) ===
                      dbStatus.CONNECTED
                        ? "social_status_tag success"
                        : "social_status_tag warning"
                    }
                  >
                    <p>
                      {isAccountConnected("facebook-ads", accountid) ===
                      dbStatus.CONNECTED
                        ? "Connected"
                        : "Verifying"}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="social_home">
                  <div className="social_list_home">
                    <img src={FB} alt="" />
                    <p className="social_name_title">Facebook Ads</p>
                  </div>
                  <div className="social_status_tag warning">
                    <p>Not Connected</p>
                  </div>
                </div>
              )}

              {[dbStatus.CONNECTED, dbStatus.VERIFYING].includes(
                isAccountConnected("google-ads", accountid)
              ) ? (
                <div className="social_home">
                  <div className="social_list_home">
                    <img src={GOOGLE} alt="" />
                    <p className="social_name_title">
                      Google Ads <span>|</span> Account ID :{" "}
                    </p>
                    {accountid.googleAccess.googleAdAccount.accountId.length >
                    10 ? (
                      <CustomToolkitForInfo
                        text={
                          accountid.googleAccess.googleAdAccount.accountId.substring(
                            0,
                            10
                          ) + "..."
                        }
                        originalText={
                          accountid.googleAccess.googleAdAccount.accountId
                        }
                        type="google"
                      />
                    ) : (
                      accountid.googleAccess.googleAdAccount.accountId
                    )}
                  </div>
                  <div
                    className={
                      isAccountConnected("google-ads", accountid) ===
                      dbStatus.CONNECTED
                        ? "social_status_tag success"
                        : "social_status_tag warning"
                    }
                  >
                    <p>
                      {isAccountConnected("google-ads", accountid) ===
                      dbStatus.CONNECTED
                        ? "Connected"
                        : "Verifying"}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="social_home">
                  <div className="social_list_home">
                    <img src={GOOGLE} alt="" />
                    <p className="social_name_title">Google Ads</p>
                  </div>
                  <div className="social_status_tag warning">
                    <p>Not Connected</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid_quick_column">
            <p className="home_title">Quick Links</p>
            <div className="home_cards">
              <div className="card_block_home">
                <i>
                  <img src={PROPOSAL} alt="" />
                </i>
                <p>Proposal</p>
                <span
                  style={{
                    backgroundColor:
                      proposalStage === "GENERATED" ||
                      proposalStage === "APPROVED" ||
                      proposalStage === "CHANGES_APPLIED"
                        ? ""
                        : "#eeee",
                    opacity:
                      proposalStage === "GENERATED" ||
                      proposalStage === "APPROVED" ||
                      proposalStage === "CHANGES_APPLIED"
                        ? 1
                        : 0.5,
                    cursor: proposalStage !== "APPROVED" ? "" : "pointer",
                  }}
                  onClick={() => {
                    (proposalStage === "GENERATED" ||
                      proposalStage === "APPROVED" ||
                      proposalStage === "CHANGES_APPLIED") &&
                      dispatch(
                        goToSpecificCompoClient(
                          LDcomponentWithIndexClient[
                            clientDashboardIds.PROPOSAL_TAB
                          ]
                        )
                      );
                  }}
                >
                  View Proposal
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={DECK} alt="" />
                </i>
                <p>Strategy Deck</p>
                <span
                  style={{
                    backgroundColor:
                      strategyIterations.length !== 0 &&
                      (strategyIterations[strategyIterations.length - 1]
                        .state === "GENERATED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "CHANGES_APPROVED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "APPROVED")
                        ? ""
                        : "#eeee",
                    opacity:
                      strategyIterations.length !== 0 &&
                      (strategyIterations[strategyIterations.length - 1]
                        .state === "GENERATED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "CHANGES_APPROVED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "APPROVED")
                        ? 1
                        : 0.5,
                    cursor:
                      strategyIterations.length !== 0 &&
                      (strategyIterations[strategyIterations.length - 1]
                        .state === "GENERATED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "CHANGES_APPROVED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "APPROVED")
                        ? "pointer"
                        : "",
                  }}
                  onClick={() => {
                    strategyIterations.length !== 0 &&
                      (strategyIterations[strategyIterations.length - 1]
                        .state === "GENERATED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "CHANGES_APPROVED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "APPROVED") &&
                      dispatch(
                        goToSpecificCompoClient(
                          LDcomponentWithIndexClient[
                            clientDashboardIds.STRATEGY_DECK_INTRO
                          ]
                        )
                      );
                  }}
                >
                  View Strategy
                </span>
              </div>
              <div
                className="card_block_home"
                onClick={() => {
                  if (showAnalyticsDashboard(clientState)) {
                    dispatch(
                      goToSpecificCompoClient(
                        LDcomponentWithIndexClient[
                          clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES
                        ]
                      )
                    );
                  }
                }}
              >
                <i>
                  <img src={CREATIVE} alt="" />
                </i>
                <p>Creatives</p>
                <span
                  style={{
                    backgroundColor: isCampaignApproved ? "" : "#eeee",
                    opacity: isCampaignApproved ? 1 : 0.5,
                    cursor: isCampaignApproved ? "pointer" : "",
                  }}
                >
                  View Creatives
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={CAMPAIGN} alt="" />
                </i>
                <p>Campaign Setup</p>
                <span
                  style={{
                    backgroundColor: isCampaignApproved ? "" : "#eeee",
                    opacity: isCampaignApproved ? 1 : 0.5,
                    cursor: isCampaignApproved ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (googleCampaignPreview(clientState)) {
                      dispatch(
                        goToSpecificCompoClient(
                          LDcomponentWithIndexClient[
                            clientDashboardIds.CAMPAIGN_PREVIEW_CLIENT
                          ]
                        )
                      );
                    } else if (facebookCampaignPreview(clientState)) {
                      dispatch(
                        goToSpecificCompoClient(
                          LDcomponentWithIndexClient[
                            clientDashboardIds.CAMPAIGN_PREVIEW_FACEBOOK
                          ]
                        )
                      );
                    }
                  }}
                >
                  View Campaign
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={PROPOSAL} alt="" />
                </i>
                <p>Analytics</p>
                <span
                  style={{
                    backgroundColor: isCampaignApproved ? "" : "#eeee",
                    opacity: isCampaignApproved ? 1 : 0.5,
                    cursor: isCampaignApproved ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (isCampaignApproved) {
                      window.location.href = "/analytic-dashboard";
                    }
                    // if (Object.values(campaignsObj)?.every(val => val !== null)) {
                    //   window.location.href = "/analytic-dashboard";
                    // }
                  }}
                >
                  View Dashboard
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid_colum">
          <div className="grid_learning_column">
            <div className="learning_centre">
              <p className="home_title">Pixis Learning Centre</p>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={GOV} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Performance AI</h4>
                  <p>
                    Maximize your marketing ROI by leveraging hyper-contextual
                    proprietary models. An all-in-one AI-led performance engine
                    to manage all aspects of your marketing.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/performance-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={TAR} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Targeting AI</h4>
                  <p>
                    Fuel your targeting strategies with AI-powered customer
                    behavior & engagement feedbacks. Trained on billions of data
                    points, Targeting AI creates the most relevant cohorts for
                    your brand.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/targeting-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={CRE} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Creative AI</h4>
                  <p>
                    Generate contextual text and creative assets effortlessly
                    for each of your cohorts. Get cohort-specific asset
                    recommendations, engagement-based feedback, and more with
                    industry-leading Transformer-based models.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/creative-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
