import { clientDashboardIds } from "../../utils/HDMClientDashboardIdsUtil";
import HDMQuestionaireContainer from "./HDMQuestions/HDMQuestionaireContainer";
// import ReadAccess from "./ReadAccess/readAccess";

// import CompleteAccess from "./CompleteAccess/completeAccess";

import { useSelector } from "react-redux";
// import Proposal from "./Proposal/proposal";
import CommunicationHDM from "./DeckProposal/communication/communication";
import DeckSuccess from "./DeckProposal/DeckSuccessHDM";
import OnboardHDM from "./DeckProposal/Onboarding";
import ReviewHDM from "./DeckProposal/Review";
import HDMHomePage from "./HDMHomePage/HDMHomePage";
import QuestionierPreview from "./HDMQuestions/HDMPreview";
import HDMAccess from "./HDMReadAccess/HDMAccess";
import AnaylsisHDM from "./Strategy/Analysis";
import StrategyDeckGoogleHDM from "./Strategy/StrategyDeckGoogleHDM";
import OurPlanHDM from "./Strategy/OurPlan";
import BestPraticeHDM from "./Strategy/BestPractice";
import GoalHDM from "./Strategy/Goals";
import SuggestChangesHDMDeck from "./Strategy/LastPageStrategy";
import StrategyDeckFacebookHDM from "./Strategy/StrategyDeckFacebookHDM";
import CampaignPreview from "./Campaign/CampaignPreview";
import CampaignPreviewGoogle from "../AccountManager/CampaignApproval/CampaignPreview";
import StrategyDeckGoogle from "../ClientDashboard/StrategyDeck/StrategyDeckGoogle";

const HDMRenderCurrentComponent = ({ currentCompoent }) => {
  const reduxStates = useSelector((state) => state);
  const currentCompenent = (currentCompoent) => {
    switch (currentCompoent) {
      case clientDashboardIds.RENDER_HOME_PAGE:
        return (
          <>
            <HDMHomePage />
          </>
        );
      case clientDashboardIds.CLIENTANDACCESS1:
        return (
          <HDMQuestionaireContainer
            infoType="clientAccess"
            objKey="businessName"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["clientAccess"][
                "businessName"
              ]
            }
          />
        );
      case clientDashboardIds.CLIENTANDACCESS2:
        return (
          <HDMQuestionaireContainer
            infoType="clientAccess"
            objKey="primaryContactPerson"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["clientAccess"][
                "primaryContactPerson"
              ]
            }
            facebookRequestCheckbox={true}
            // checkboxObjKey="facebookPartenerRequestSendContactPerson"
            // checkboxValue={
            //   reduxStates["hdmClient"]['onboardingInfo']["clientAccess"][
            //     "facebookPartenerRequestSendContactPerson"
            //   ]
            // }
          />
        );
      case clientDashboardIds.CLIENTANDACCESS3:
        return (
          <HDMQuestionaireContainer
            infoType="clientAccess"
            objKey="fbPageUrl"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["clientAccess"][
                "fbPageUrl"
              ]
            }
            facebookRequestCheckbox={true}
            // checkboxObjKey="facebookPartenerRequestSendPageURL"
            // checkboxValue={
            //   reduxStates["hdmClient"]['onboardingInfo']["clientAccess"][
            //     "facebookPartenerRequestSendPageURL"
            //   ]
            // }
          />
        );
      case clientDashboardIds.CLIENTANDACCESS4:
        return (
          <HDMQuestionaireContainer
            infoType="clientAccess"
            objKey="websiteUrl"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["clientAccess"][
                "websiteUrl"
              ]
            }
          />
        );
      case clientDashboardIds.CLIENTANDACCESS5:
        return (
          <HDMQuestionaireContainer
            infoType="clientAccess"
            objKey="shopifyStoryAvailable"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["clientAccess"][
                "shopifyStoryAvailable"
              ]
            }
          />
        );
      case clientDashboardIds.CLIENTANDACCESS6:
        return (
          <HDMQuestionaireContainer
            infoType="clientAccess"
            objKey="platforms"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["clientAccess"][
                "platforms"
              ] || []
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING1:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="esthetic"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "esthetic"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING2:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="tagline"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "tagline"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING3:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="emotionalResponse"
            multiQuestion={true}
          />
        );
      case clientDashboardIds.BRANDORMESSAGING4:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="uniqueSellingPoint"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "uniqueSellingPoint"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING5:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="whatSetsYouApart"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "whatSetsYouApart"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING6:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="benefitsOfProducts"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "benefitsOfProducts"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING7:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="painPoints"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "painPoints"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING8:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="storyToPromote"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "storyToPromote"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING9:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="brandMessagingExampels"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "brandMessagingExampels"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING10:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="brandFont"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "brandFont"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING11:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="brandColor"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "brandColor"
              ]
            }
          />
        );
      case clientDashboardIds.BRANDORMESSAGING12:
        return (
          <HDMQuestionaireContainer
            infoType="brandMessaging"
            objKey="logoLink"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["brandMessaging"][
                "logoLink"
              ]
            }
          />
        );
      case clientDashboardIds.TARGETAUDIENCE1:
        return (
          <HDMQuestionaireContainer
            infoType="facebookTargeting"
            objKey="AgeRange"
            value={{
              min: reduxStates["hdmClient"]["onboardingInfo"][
                "facebookTargeting"
              ]["minAgeRange"],
              max: reduxStates["hdmClient"]["onboardingInfo"][
                "facebookTargeting"
              ]["maxAgeRange"],
            }}
          />
        );
      case clientDashboardIds.TARGETAUDIENCE2:
        return (
          <HDMQuestionaireContainer
            infoType="facebookTargeting"
            objKey="gender"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["facebookTargeting"][
                "gender"
              ]
            }
          />
        );
      case clientDashboardIds.TARGETAUDIENCE3:
        return (
          <HDMQuestionaireContainer
            infoType="facebookTargeting"
            objKey="advertisingLocations"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["facebookTargeting"][
                "advertisingLocations"
              ]
            }
          />
        );
      case clientDashboardIds.TARGETAUDIENCE4:
        return (
          <HDMQuestionaireContainer
            infoType="facebookTargeting"
            objKey="activitiesInterestHDM"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["facebookTargeting"][
                "activitiesInterest"
              ]
            }
          />
        );
      case clientDashboardIds.TARGETAUDIENCE5:
        return (
          <HDMQuestionaireContainer
            infoType="facebookTargeting"
            objKey="excludes"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["facebookTargeting"][
                "excludes"
              ]
            }
          />
        );
      case clientDashboardIds.TARGETAUDIENCE6:
        return (
          <HDMQuestionaireContainer
            infoType="facebookTargeting"
            objKey="formalCustomerPersonas"
            images={
              reduxStates["hdmClient"]["onboardingInfo"]["facebookTargeting"][
                "formalCustomerPersonas"
              ]
            }
          />
        );
      case clientDashboardIds.TARGETAUDIENCE7:
        return (
          <HDMQuestionaireContainer
            infoType="facebookTargeting"
            objKey="otherCharacteristics"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["facebookTargeting"][
                "otherCharacteristics"
              ]
            }
          />
        );
      case clientDashboardIds.GOOGLETARGETING1:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="ageRange"
            maxObjKey='maxAgeRange'
            minObjKey='minAgeRange'
            minAgeValue={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "minAgeRange"
              ]
            }
            maxAgeValue={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "maxAgeRange"
              ]
            }
            outerKey="onboardingInfo"
          />
        );
      case clientDashboardIds.GOOGLETARGETING2:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="geoTargets"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "geoTargets"
              ]
            }
          />
        );
      case clientDashboardIds.GOOGLETARGETING3:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="gender"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "gender"
              ]
            }
            outerKey="onboardingInfo"
          />
        );
      case clientDashboardIds.GOOGLETARGETING4:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="maritalStatus"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "maritalStatus"
              ]
            }
            outerKey="onboardingInfo"
          />
        );
      case clientDashboardIds.GOOGLETARGETING5:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="activitiesInterest"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "activitiesInterest"
              ]
            }
          />
        );
      case clientDashboardIds.GOOGLETARGETING6:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="excludeAudiences"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "excludeAudiences"
              ]
            }
          />
        );
      case clientDashboardIds.GOOGLETARGETING7:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="customerIncomeRange"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "customerIncomeRange"
              ]
            }
            outerKey="onboardingInfo"
          />
        );
      case clientDashboardIds.GOOGLETARGETING8:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="customerEducation"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "customerEducation"
              ]
            }
            outerKey="onboardingInfo"
          />
        );
      case clientDashboardIds.GOOGLETARGETING9:
        return (
          <HDMQuestionaireContainer
            infoType="googleTargeting"
            objKey="customerEmployment"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["googleTargeting"][
                "customerEmployment"
              ]
            }
            outerKey="onboardingInfo"
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS1:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="productandservice"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "productandservice"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS2:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="noPromotionProduct"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "noPromotionProduct"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS3:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="offers"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "offers"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS4:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="averageOrderValue"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "averageOrderValue"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS5:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="cogsPerAov"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "cogsPerAov"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS6:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="shippingCostPerAov"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "shippingCostPerAov"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS7:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="breakEvenPoint"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "breakEvenPoint"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS8:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="fbCamaignBudget"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "fbCamaignBudget"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS9:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="googleCamaignBudget"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "googleCamaignBudget"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS10:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="tiktokCamaignBudget"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "tiktokCamaignBudget"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS11:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="countryTargeted"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "countryTargeted"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS12:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="brandDescription"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "brandDescription"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS13:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="keyCompetitors"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "keyCompetitors"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS14:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="brandsInterests"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "brandsInterests"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS15:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="commonJargons"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "commonJargons"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS16:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="monthlyRevenue"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "monthlyRevenue"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS17:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="monthlyRevenueGoal3Months"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "monthlyRevenueGoal3Months"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS18:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="monthlyRevenueGoal6Months"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "monthlyRevenueGoal6Months"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS19:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="monthlyRevenueGoal1Year"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "monthlyRevenueGoal1Year"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS20:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="maximizeEnterpriseValueProfit"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "maximizeEnterpriseValueProfit"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS21:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="requirementsForUs"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "requirementsForUs"
              ]
            }
          />
        );
      case clientDashboardIds.ABOUTYOURBUSSINESS22:
        return (
          <HDMQuestionaireContainer
            infoType="aboutBusiness"
            objKey="dobHomeAddress"
            value={
              reduxStates["hdmClient"]["onboardingInfo"]["aboutBusiness"][
                "dobHomeAddress"
              ]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD1:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="videoReviews"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["videoReviews"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD2:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="googleDriveEmail"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["googleDriveEmail"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD3:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="googleDriveLink"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["googleDriveLink"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD4:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="interestedMontageAds"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["interestedMontageAds"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD5:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="pocForProductDeliveries"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["pocForProductDeliveries"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD6:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="products"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["products"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD7:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="creatorPersona"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["creatorPersona"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD8:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="shootNotes"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["shootNotes"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD9:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="desiredCta"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["desiredCta"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD10:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="promoCode"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["promoCode"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD11:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="uspOfProductsSent"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["uspOfProductsSent"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD12:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="visualReferences"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["visualReferences"]
            }
          />
        );
      case clientDashboardIds.CREATIVEANDMONTAGEAD13:
        return (
          <HDMQuestionaireContainer
            infoType="creativeAndMontageAds"
            objKey="others"
            value={
              reduxStates["hdmClient"]["onboardingInfo"][
                "creativeAndMontageAds"
              ]["others"]
            }
          />
        );
      case clientDashboardIds.REVIEW_HDMQUESTIONIER_RESPONSES:
        return <QuestionierPreview />;
      case clientDashboardIds.HDM_ACCESS:
        return <HDMAccess />;
      case clientDashboardIds.HDM_ONBOARDING:
        return <OnboardHDM />;
      case clientDashboardIds.HDM_ONBOARDING_REVIEW:
        return <ReviewHDM />;
      case clientDashboardIds.HDM_ONBOARDING_COMMUNICATION:
        return <CommunicationHDM />;
      case clientDashboardIds.HDM_ONBOARDING_SUCCESS:
        return <DeckSuccess />;
      case clientDashboardIds.HDM_STRATEGY_DECK_PAGE:
        return <GoalHDM />;
      case clientDashboardIds.HDM_ANAYLSIS_DECK_PAGE:
        return <AnaylsisHDM />;
      case clientDashboardIds.HDM_STRATEGY_DECK_GOOGLE_PAGE:
        return <StrategyDeckGoogle agencyType="HDM" />;
      case clientDashboardIds.HDM_STRATEGY_DECK_FACEBOOK_PAGE:
        return <StrategyDeckFacebookHDM agencyType="HDM" />;
      case clientDashboardIds.HDM_STRATEGY_DECK_OURPLAN_PAGE:
        return <OurPlanHDM />;
      case clientDashboardIds.HDM_STRATEGY_DECK_BESTPRACTICE_PAGE:
        return <BestPraticeHDM />;
      case clientDashboardIds.HDM_STRATEGY_DECK_SUGGESTCHANGES_PAGE:
        return <SuggestChangesHDMDeck />;
      case clientDashboardIds.HDM_CAMPAIGN_PREVIEW:
        return <CampaignPreview />;
      case clientDashboardIds.HDM_CAMPAIGN_PREVIEW_GOOGLE:
        return <CampaignPreviewGoogle agencyType="HDM" />;
      default:
        return <></>;
    }
  };
  return currentCompenent(currentCompoent);
};
export default HDMRenderCurrentComponent;
