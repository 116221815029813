import React from 'react';
import InputTextField from '../../InputTextField/TextField';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import {trash as trashIcon} from '../../../../assets/icons/common/common';


const AddKeywords = ({ value, objType, type,hitEnter }) => {
  console.log(value);
  const valuesIncoming = (value || [""])
  const dispatch = useDispatch();
  return (
    <>
      {valuesIncoming?.map((d, index) => (
        <div
          style={{ display: 'flex', gap: '2rem', marginBottom: '1.5rem' }}
          key={index}
        >
          <InputTextField
            width='600px'
            placeholder={'Keyword ' + (index + 1)}
            objKey={objType + ' ' + index}
            infoType={type}
            value={d}
            onKeyPress={hitEnter}
          />
          {index > 0 && (
            <img
              src={trashIcon}
              alt='delete'
              onClick={() =>
                dispatch({
                  type: 'DELETE_ADD_KEYWORD_IN_BRAND_AND_SERVICE_INTAKE_FORM_FIELD',
                  payload: index,
                })
              }
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
      ))}
      <div
        style={{
          background: '#E3EEFF',
          borderRadius: 6,
          padding: '3px 9px',
          display: 'inline-block',
        }}
      >
        <Typography
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 11,
            color:
              valuesIncoming[valuesIncoming?.length - 1]
                ? '#0869FB'
                : 'gray',
            cursor:
              valuesIncoming[valuesIncoming?.length - 1]
                ? 'pointer'
                : 'not-allowed',
          }}
          onClick={() =>
            valuesIncoming[valuesIncoming?.length - 1]
              ? dispatch({ type: 'ADD_KEYWORD_IN_BRAND_AND_SERVICE_INTAKE_FORM' })
              : ''
          }
        >
          + ADD MORE KEYWORDS
        </Typography>
      </div>
    </>
  );
};

export default AddKeywords;
