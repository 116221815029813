import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import GoToNextCompoButton from '../GoToNextCompoButton';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import {
  getFormByFormIdReducer,
  updateCompanyInfoReducer,
} from '../../../api/accountManager/forms/slice';
import 'slick-carousel/slick/slick.css';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Tooltip from '@mui/material/Tooltip';

import { addInterestAdset } from '../../../api/HDMAM/form/action';
import { man_give_presentation as manGivePresentation } from '../../../assets/icons/icon';
import { adset_demo as adsetDemoImage } from '../../../assets/icons/icon';

import {
  getFacebookKeywords,
  getFacebookAdsStrategy,
} from '../../../api/HDMAM/strategyCreation/action';
import RadioGroup from '@mui/material/RadioGroup';
import {
  mappedAdsByCreative,
  isAnyAdsThereInAdsetHdm,
} from '../../../utils/strategyCreationFacebook';
import { getInterestedKeyword } from '../../../api/HDMAM/strategyCreation/saga';
import {
  deleteAdsetAction,
  addToThisAdsetAction,
  addToAllAdsetAction,
  deleteSpecificAdsAction,
  editSpecificAdsAction,
  clearAllAdsHdm,
  updateBudgetFunnelHdm,
} from '../../../api/HDMAM/form/action';
import { uploadFile } from '../../../api/fileHandling/saga';
import { numberOfAds } from '../../../utils/strategyCreationFacebook';
import { findUrl } from './LookaLike';
import { goBackHDMAmCompo } from '../../../api/HDMAM/componentTracking/action';
import { updateWholeFormDataHdmAm } from '../../../api/HDMAM/form/action';
import { getFacebookBudget } from '../../../utils/hdmUtil';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1004,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  padding: '46px 47px 42px',
  boxShadow: 24,
};
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 475,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  padding: '46px 47px 42px',
  boxShadow: 24,
};

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: true,
  dots: false,
};

const MOF = ({ agencyType = 'HDM' }) => {
  const dispatch = useDispatch();
  const [addNewad, setAddNewad] = useState(false);
  const [editAd, setEditAd] = useState(false);
  const [editAdkeyword, setEditAdkeyword] = useState(false);
  const [editAdModalIndex, setEditAdModalIndex] = useState();
  const [newAdsSelect, setNewAdsSelect] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const hiddenFileInput = useRef(null);
  const [selectedKeyWord, setSelectedKeyWord] = useState({});
  const keywords = useSelector((state) => state.strategyCreationHdmAm.keywords);
  const topOfTheFunnelInterest = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.topOfTheFunnelInterest
  );
  const adsets = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.topOfTheFunnelInterest?.adsets
  );
  const onboardingInfo = useSelector((state) =>
    agencyType === 'LD'
      ? state?.form?.form?.onboardingInfo
      : state.hdmClient.onboardingInfo
  );
  const facebookAds = useSelector(
    (state) => state.strategyCreationHdmAm.facebookAds
  );
  const [currentAdset, setCurrentAdset] = useState(0);
  const [mappedCreativeAd, setMappedCreativesAd] = useState(
    mappedAdsByCreative(facebookAds)
  );
  const [currentCreative, setCurrentCreative] = useState('CREATIVE1');
  const [currentKeyword, setCurrentKeyword] = useState(null);
  const [keywordsByAdset, setKeywordsByAdset] = useState({});
  const [editAdsDetails, setEditAdsDetails] = useState(null);
  const [labels, setLabels] = useState({});
  const [facebookBudget, setFacebookBudget] = useState(0);
  const hdmStrategyCreation = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo
  );
  const [addToThisAdsetList, setAddToThisAdsetList] = useState({});
  const [addToAllAdsetList, setAddToAllAdsetList] = useState({});
  const [text, setText] = useState({});
  const form = useSelector((state) => state);
  const isFirstTimeStrategyCreation = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo.isFirstTimeStrategyCreation
  );

  useEffect(() => {
    if (isFirstTimeStrategyCreation) return;
    dispatch(getFormByFormIdReducer());
  }, [isFirstTimeStrategyCreation]);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    dispatch(getFacebookKeywords());
  }, []);

  useEffect(() => {
    dispatch(getFacebookAdsStrategy());
  }, []);

  useEffect(() => {
    setMappedCreativesAd(mappedAdsByCreative(facebookAds));
  }, [facebookAds]);

  useEffect(() => {
    if (isFirstTimeStrategyCreation) return;
    dispatch(updateWholeFormDataHdmAm());
  }, [isFirstTimeStrategyCreation]);

  useEffect(() => {
    setFacebookBudget(
      agencyType === 'LD'
        ? getFacebookBudget(onboardingInfo?.platformInfo.platforms)
        : getFacebookBudget(onboardingInfo?.clientAccess.platforms)
    );
  }, [onboardingInfo]);

  const handleChangeRadio = (event) => {
    const {
      target: { value },
    } = event;
    setNewAdsSelect(typeof value === 'string' ? value.split(',') : value);
  };

  const handleUploadFile = (event) => {
    hiddenFileInput.current.click();
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const MyChip = (props) => {};

  const autochange = (e, v, idx) => {
    var selectedChips = [];
    v.forEach((element) => {
      selectedChips.push(element);
    });
    setSelectedKeyWord({
      ...selectedKeyWord,
      [idx]: selectedChips.filter(onlyUnique),
    });
  };

  const addEditKeywordPopup = (i) => {
    setEditAdkeyword(!editAdkeyword);
    setEditAdModalIndex(i);
    var modalKeyword = selectedKeyWord[i];
    setSelectedKeyWord({ ...selectedKeyWord, ['popup']: modalKeyword });
  };

  const deleteSpecificAds = (adsetIndex, removedIndex) => {
    dispatch(
      deleteSpecificAdsAction({
        outerKey: 'topOfTheFunnelInterest',
        index: adsetIndex,
        removedIndex: removedIndex,
      })
    );
  };
  const getInterestedKeywords = (keyword, index) => {
    setTimeout(() => {
      const obj = { ...keywordsByAdset };
      getInterestedKeyword(keyword).then((res) => {
        obj[index] = res.interests;
        const arr = [];
        res.interests.forEach((value) => {
          arr.push({ name: value.name, title: value.name, id: value.id });
        });
        setLabels({ [currentAdset]: arr });
      });
      setKeywordsByAdset(obj);
    }, 5000);
  };
  const editSpecificAds = () => {
    const tempObj = { ...editAdsDetails };
    tempObj['adsetIndex'] = parseInt(tempObj['adsetIndex']);
    dispatch(
      editSpecificAdsAction({
        index: tempObj.adsetIndex,
        editIndex: tempObj.currentAd,
        ad: tempObj.ad,
        outerKey: 'topOfTheFunnelInterest',
      })
    );
    setEditAdsDetails(null);
    setEditAd(!editAd);
  };
  const deleteAdset = (index) => {
    dispatch(
      deleteAdsetAction({ outerKey: 'topOfTheFunnelInterest', index: index })
    );
    setCurrentAdset(index - 1 < 0 ? 0 : index - 1);
  };
  const addNewAdset = (adsets, keywords) => {
    if (keywords.length !== 0 && facebookAds.length !== 0) {
      dispatch(addInterestAdset({ adsets, keywords, facebookAds }));
    }
  };

  const setBudget = (budget) => {
    dispatch(
      updateBudgetFunnelHdm({
        outerKey: 'topOfTheFunnelInterest',
        value: budget,
      })
    );
  };
  useEffect(() => {
    if (
      hdmStrategyCreation?.topOfTheFunnelInterest?.budget === 0 &&
      facebookBudget !== 0
    ) {
      setBudget(parseInt(parseInt(facebookBudget) / 4 / 30.4));
    }
  }, [hdmStrategyCreation, facebookBudget]);

  const truncateString = (str) => {
    return str?.length > 120 ? str?.substring(0, 120) + '...' : str;
  };
  useEffect(() => {
    setLabels({});
  }, [currentAdset]);
  return (
    <>
      <div className='hd_strategy_layout'>
        <div className='hd_strategy_head flex-center-space'>
          <div className='hd_strategy_drop_head flex-center-space'>
            <p className='margin_0'>Daily Budget : ($)</p>
            <input
              type='number'
              className='budget_input_hdm'
              size=''
              onChange={(e) => setBudget(parseInt(e.target.value))}
              value={hdmStrategyCreation?.topOfTheFunnelInterest?.budget}
            />
          </div>
          <div className='hd_strategy_button_row flex-center-space'>
            <PrimaryButton
              type='errorLight'
              className='edit_response_button'
              title='Clear All Ads'
              icon='clear'
              onClick={() => {
                dispatch(
                  clearAllAdsHdm({
                    outerKey: 'topOfTheFunnelInterest',
                    index: currentAdset,
                  })
                );
                setAddToAllAdsetList({});
                setAddToThisAdsetList({});
              }}
            ></PrimaryButton>
            <PrimaryButton
              type='light'
              className='edit_response_button'
              title='+ Add New Ad'
              onClick={() => setAddNewad(!addNewad)}
            ></PrimaryButton>
          </div>
        </div>
        <div className='hd_strategy_sections flex-top-space tof-interest_tab'>
          <div className='hd_strategy_column adset_hdm_row'>
            <div className='adset_hdm_row_inner'>
              {topOfTheFunnelInterest &&
                topOfTheFunnelInterest.adsets.map((adset, index) => (
                  <div
                    className={
                      currentAdset === index
                        ? 'adset_block_hdm selected'
                        : 'adset_block_hdm'
                    }
                    key={index}
                    onClick={() => setCurrentAdset(index)}
                  >
                    <div className='adset_head_hdm flex-center-space'>
                      <p>
                        Ad Name: <span>{adset.adsetName}</span>
                      </p>
                      <a
                        className='delete_adset'
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteAdset(index);
                        }}
                      ></a>
                    </div>
                    <div className='tag_selected_blocks'>
                      <div className='tag_selected_chips'>
                        {adset.keywords.map(
                          (keyword, index) =>
                            index <= 11 && <span>{keyword.name}</span>
                        )}
                      </div>
                      <div className='edit_view_row'>
                        <button
                          className='edit_view_button'
                          onClick={() => addEditKeywordPopup(index)}
                        >
                          Edit/ View More
                        </button>
                      </div>
                    </div>
                    <div className='adset_list_hdm'>
                      <p className='addNew_keyword_title'>Add New Keyword</p>
                      <Autocomplete
                        multiple
                        id='checkboxes-tags'
                        defaultValue={text[currentAdset.toString()]}
                        value={text[currentAdset.toString()]}
                        renderTags={(tagValue, getTagProps) => {
                          return tagValue.map((option, index) => (
                            <MyChip
                              label={option.name}
                              key={option.name}
                              index={index}
                            />
                          ));
                        }}
                        renderOption={(
                          props,
                          option,
                          { inputValue, selected }
                        ) => {
                          const matches = match(option.title, inputValue, {
                            insideWords: true,
                          });
                          const parts = parse(option.title, matches);
                          return (
                            <li {...props} className='search_location_list'>
                              <div className='location_list_head'>
                                {' '}
                                <p>Interest Keywords</p>
                              </div>
                              <div className='location_list_content'>
                                <div>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    onChange={() => {
                                      dispatch({
                                        type: 'ADD_KEYWORD_IN_ADSET_HDM',
                                        payload: {
                                          value: option,
                                          index: currentAdset,
                                        },
                                      });
                                    }}
                                  />
                                  {parts.map((part, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        color: part.highlight
                                          ? '#0869FB'
                                          : '#333333',
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </li>
                          );
                        }}
                        options={
                          labels[currentAdset] ? [...labels[currentAdset]] : []
                        }
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                          <div className='location_question_header'>
                            <span className='search_icon'></span>
                            <TextField
                              {...params}
                              ref={params.InputProps.ref}
                              inputProps={params.inputProps}
                              autoFocus
                              placeholder='Search'
                              variant='standard'
                            />
                          </div>
                        )}
                        onChange={(eve, val) => {
                          autochange(eve, val, index);
                        }}
                        onInputChange={(e) => {
                          if (e.target.value !== 'on') {
                            const obj = { ...text };
                            obj[index.toString()] = e.target.value;
                            setText(obj);
                            setCurrentKeyword(e.target.value);
                            getInterestedKeywords(e.target.value, index);
                          }
                        }}
                        disablePortal={true}
                        className='auto_location_search'
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className='addnew_adset_row'>
              <button
                className='addnew_adset_button'
                onClick={() =>
                  addNewAdset(topOfTheFunnelInterest.adsets, keywords)
                }
              >
                + ADD NEW ADSET
              </button>
            </div>
          </div>
          <div className='hd_strategy_column'>
            <div className='adset_slider'>
              <Slider {...settings}>
                {topOfTheFunnelInterest &&
                  currentAdset < topOfTheFunnelInterest.adsets.length &&
                  topOfTheFunnelInterest.adsets
                    .filter((_, index) => index === currentAdset)[0]
                    ['facebookAds'].map((adset, index) => (
                      <div className='adset_slide' key={index}>
                        <div className='adset_slide_block'>
                          <div className='adset_slide_head'>
                            <h3>
                              {adset?.adName
                                ? adset.adName
                                : 'AD ' + (index + 1)}
                            </h3>
                          </div>
                          <div className='adset_slide_head_skip'>
                            <Tooltip title={adset.primaryText} arrow>
                              <p>{truncateString(adset.primaryText)}</p>
                            </Tooltip>
                          </div>
                          <div className='adset_slide_image'>
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_BASE_URL +
                                '/files/' +
                                adset.imageUrl
                              }
                            />
                          </div>
                          <div className='adset_slide_footer flex-center-space'>
                            <div className='adset_slide_footer_info'>
                              <h2>
                                {onboardingInfo?.clientAccess?.websiteUrl}
                              </h2>
                              <p>{adset.headline}</p>
                              <span>{adset.description}</span>
                            </div>
                            <div className='adset_slide_footer_action'>
                              <a
                                href='javascript:;'
                                className='adset_book_button'
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='adset_slide_action_row flex-center-center'>
                          <button
                            className='edit_button'
                            onClick={() => {
                              setEditAdsDetails({
                                adsetIndex: currentAdset,
                                currentAd: index,
                                ad: { ...adset },
                              });
                              setEditAd(!editAd);
                            }}
                          ></button>
                          <button
                            className='delete_button'
                            onClick={() =>
                              deleteSpecificAds(currentAdset, index)
                            }
                          ></button>
                        </div>
                      </div>
                    ))}
              </Slider>
              {topOfTheFunnelInterest &&
                currentAdset < topOfTheFunnelInterest.adsets.length &&
                topOfTheFunnelInterest.adsets.filter(
                  (_, index) => index === currentAdset
                )[0]['facebookAds'].length === 0 && (
                  <>
                    <div className='noadd_screen_hdm'>
                      <img src={manGivePresentation} />
                      <p>Please add a few ads to this adset to proceed. </p>
                      <PrimaryButton
                        type='light'
                        className='edit_response_button'
                        title='+ Add New Ad'
                        onClick={() => setAddNewad(!addNewad)}
                      ></PrimaryButton>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className='preview_footer_row'>
        <div className='footer_con'>
          <button className='back' onClick={() => dispatch(goBackHDMAmCompo())}>
            BACK
          </button>
          <GoToNextCompoButton
            title='Confirm'
            disabled={isAnyAdsThereInAdsetHdm(adsets)}
            agencyType={agencyType}
          />
        </div>
      </div>

      {/*creatives*/}
      <Modal
        open={addNewad}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='large_addad_popup'
      >
        <Box sx={style}>
          <div className='addad_popup_layout'>
            <div className='addad_modal_header'>
              <p>Add Ad</p>
              <a
                className='popup_close_button'
                onClick={() => setAddNewad(!addNewad)}
              >
                <span></span>
              </a>
            </div>
            <div className='addad_modal_layout'>
              <div className='addad_modal_dropdown'>
                <Select
                  labelId='demo-multiple-checkbox-label'
                  id='demo-multiple-checkbox'
                  value={currentCreative}
                  onChange={(e) => setCurrentCreative(e.target.value)}
                >
                  {Object.entries(mappedCreativeAd).map(
                    ([key, value], index) => (
                      <MenuItem
                        key={index}
                        value={key}
                        className='drop_custom_list_row popup_drop_list'
                      >
                        <div className='drop_custom_list'>
                          <div>
                            <Radio
                              value={key}
                              checked={key === currentCreative ? true : false}
                            />
                            <p>Creative {index + 1}</p>
                          </div>
                          <span>
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_BASE_URL +
                                '/files/' +
                                value[0].imageUrl
                              }
                              style={{ width: '50px', height: '50px' }}
                            />
                          </span>
                        </div>
                      </MenuItem>
                    )
                  )}
                </Select>
              </div>
              <div className='addad_modal_slider'>
                <div className='adset_slider'>
                  <Slider {...settings}>
                    {mappedCreativeAd[currentCreative] &&
                      mappedCreativeAd[currentCreative].map(
                        (creative, index) => (
                          <div className='adset_slide' key={index}>
                            <div className='adset_slide_block'>
                              <div className='adset_slide_head'>
                                <h3>Ad {index + 1}</h3>
                              </div>
                              <div className='adset_slide_head_skip'>
                                <Tooltip title={creative.primaryText} arrow>
                                  <p>{truncateString(creative.primaryText)}</p>
                                </Tooltip>
                              </div>
                              <div className='adset_slide_image'>
                                <img
                                  src={
                                    process.env.REACT_APP_BACKEND_BASE_URL +
                                    '/files/' +
                                    creative.imageUrl
                                  }
                                />
                              </div>
                              <div className='adset_slide_footer flex-center-space'>
                                <div className='adset_slide_footer_info'>
                                  <h2>
                                    {onboardingInfo?.clientAccess?.websiteUrl}
                                  </h2>
                                  <p>{creative.headline}</p>
                                  <span>{creative.description}</span>
                                </div>
                                <div className='adset_slide_footer_action'>
                                  <a
                                    href='javascript:;'
                                    className='adset_book_button'
                                  >
                                    Book Now
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='adset_slide_action_row flex-center-center'>
                              <div className='addad_action_row'>
                                <RadioGroup
                                  aria-labelledby={`${
                                    currentCreative + '_' + index
                                  }-demo-controlled-radio-buttons-group`}
                                  className='radio_group_popup'
                                  name={`${
                                    currentCreative + '_' + index
                                  }-controlled-radio-buttons-group`}
                                  onChange={(e) => {
                                    var value = e.target.value.split('-');
                                    if (value[1] === 'ADD_TO_THIS_ADSET') {
                                      const obj1 = { ...addToThisAdsetList };
                                      const obj2 = { ...addToAllAdsetList };
                                      if (obj2[currentCreative + '-' + index]) {
                                        delete obj2[
                                          currentCreative + '-' + index
                                        ];
                                        obj1[currentCreative + '-' + index] = {
                                          ...creative,
                                          ...{
                                            adName:
                                              'AD ' +
                                              Math.floor(
                                                Math.random() *
                                                  numberOfAds(facebookAds)
                                              ),
                                          },
                                        };
                                      } else {
                                        obj1[currentCreative + '-' + index] = {
                                          ...creative,
                                          ...{
                                            adName:
                                              'AD ' +
                                              Math.floor(
                                                Math.random() *
                                                  numberOfAds(facebookAds)
                                              ),
                                          },
                                        };
                                      }
                                      setAddToThisAdsetList(obj1);
                                      setAddToAllAdsetList(obj2);
                                    } else {
                                      const obj1 = { ...addToAllAdsetList };
                                      const obj2 = { ...addToThisAdsetList };
                                      if (obj2[currentCreative + '-' + index]) {
                                        delete obj2[
                                          currentCreative + '-' + index
                                        ];
                                        obj1[currentCreative + '-' + index] = {
                                          ...creative,
                                          ...{
                                            adName:
                                              'AD ' +
                                              Math.floor(
                                                Math.random() *
                                                  numberOfAds(facebookAds)
                                              ),
                                          },
                                        };
                                      } else {
                                        obj1[currentCreative + '-' + index] = {
                                          ...creative,
                                          ...{
                                            adName:
                                              'AD ' +
                                              Math.floor(
                                                Math.random() *
                                                  numberOfAds(facebookAds)
                                              ),
                                          },
                                        };
                                      }
                                      setAddToAllAdsetList(obj1);
                                      setAddToThisAdsetList(obj2);
                                    }
                                  }}
                                >
                                  <FormControlLabel
                                    className='addad_lable_radio'
                                    value={`${
                                      currentCreative + '_' + index
                                    }-ADD_TO_THIS_ADSET`}
                                    control={
                                      <Radio className='addad_radio_action' />
                                    }
                                    label='Add to this adset'
                                  />
                                  <FormControlLabel
                                    className='addad_lable_radio'
                                    value={`${
                                      currentCreative + '_' + index
                                    }-ADD_TO_ALL_ADSET`}
                                    control={
                                      <Radio className='addad_radio_action' />
                                    }
                                    label='Add to all adsets'
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </Slider>
                </div>
              </div>
            </div>

            <div className='addad_modal_footer'>
              <div className='addad_row_footer'>
                <button
                  className='cancle_button_modal'
                  onClick={() => {
                    setAddToThisAdsetList({});
                    setAddToAllAdsetList({});
                    setAddNewad(!addNewad);
                  }}
                >
                  Cancel
                </button>
                <PrimaryButton
                  className='edit_response_button'
                  title='Save Changes'
                  onClick={() => {
                    dispatch({
                      type: 'ADD_TO_THIS_AND_ALL_ADSET',
                      payload: {
                        addToThisAdsetList: addToThisAdsetList,
                        addToAllAdsetList: addToAllAdsetList,
                        currentAdset: currentAdset,
                        outerKey: 'topOfTheFunnelInterest',
                      },
                    });
                    setAddNewad(!addNewad);
                  }}
                ></PrimaryButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {editAdsDetails && (
        <Modal
          open={editAd}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='large_addad_popup'
        >
          <Box sx={style}>
            <div className='addad_popup_layout'>
              <div className='addad_modal_header'>
                <p>Edit Ad</p>
                <a
                  className='popup_close_button'
                  onClick={() => setEditAd(!editAd)}
                >
                  <span></span>
                </a>
              </div>
              <div className='addad_modal_layout'>
                <div className='addad_modal_sections'>
                  <div className='addad_modal_preview'>
                    <div className='ad_preview_title'>
                      <p>Ad 1</p>
                    </div>
                    <div className='adset_slide_head_skip'>
                      <Tooltip title={editAdsDetails.ad.primaryText} arrow>
                        <p>{truncateString(editAdsDetails.ad.primaryText)}</p>
                      </Tooltip>
                    </div>
                    <div className='ad_preview_image'>
                      <img
                        src={
                          process.env.REACT_APP_BACKEND_BASE_URL +
                          '/files/' +
                          editAdsDetails.ad.imageUrl
                        }
                      />
                      <div className='preview_ad_upload'>
                        <label htmlFor='select-image'>
                          <input
                            type='file'
                            ref={hiddenFileInput}
                            id='select-image'
                            style={{ display: 'none' }}
                            accept='image/*'
                            onChange={(e) => {
                              const tempObj = { ...editAdsDetails };
                              const form = new FormData();
                              form.append('file', e.target.files[0]);
                              setSelectedImage(e.target.files[0]);
                              uploadFile(form).then((res) => {
                                tempObj['ad']['imageUrl'] = res.fileId;
                                setEditAdsDetails(tempObj);
                              });
                            }}
                          />
                          <PrimaryButton
                            className='edit_response_button'
                            onClick={handleUploadFile}
                            title='Upload New Image'
                            icon='camera'
                          ></PrimaryButton>
                        </label>
                      </div>
                    </div>
                    <div className='adset_slide_footer flex-center-space'>
                      <div className='adset_slide_footer_info'>
                        <h2>{onboardingInfo?.clientAccess?.websiteUrl}</h2>
                        <p>{editAdsDetails.ad.headline}</p>
                        <span>{editAdsDetails.ad.description}</span>
                      </div>
                    </div>
                  </div>
                  <div className='addad_modal_edit_options'>
                    <form>
                      <div className='form_row_edit'>
                        <label>Primary Text</label>
                        <textarea
                          className='large_textarea'
                          value={editAdsDetails.ad.primaryText}
                          onChange={(e) => {
                            const tempObj = { ...editAdsDetails };
                            tempObj['ad']['primaryText'] = e.target.value;
                            setEditAdsDetails(tempObj);
                          }}
                        >
                          {editAdsDetails.ad.primaryText}
                        </textarea>
                      </div>
                      <div className='form_row_edit'>
                        <label>Headline</label>
                        <input
                          type='text'
                          defaultValue={editAdsDetails.ad.headline}
                          value={editAdsDetails.ad.headline}
                          onChange={(e) => {
                            const tempObj = { ...editAdsDetails };
                            tempObj['ad']['headline'] = e.target.value;
                            setEditAdsDetails(tempObj);
                          }}
                        />
                      </div>
                      <div className='form_row_edit'>
                        <label>Description</label>
                        <textarea
                          onChange={(e) => {
                            const tempObj = { ...editAdsDetails };
                            tempObj['ad']['description'] = e.target.value;
                            setEditAdsDetails(tempObj);
                          }}
                          value={editAdsDetails.ad.description}
                        >
                          {editAdsDetails.ad.description}
                        </textarea>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='addad_modal_footer'>
                <div className='addad_row_footer'>
                  <button
                    className='cancle_button_modal'
                    onClick={() => setEditAd(!editAd)}
                  >
                    Cancel
                  </button>
                  <PrimaryButton
                    className='edit_response_button'
                    title='Save Changes'
                    onClick={() => editSpecificAds()}
                  ></PrimaryButton>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}

      {/*edit/view more*/}
      <Modal
        open={editAdkeyword}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='small_added_popup'
      >
        <Box sx={style1}>
          <div className='addad_popup_layout'>
            <div className='addad_modal_header'>
              <p>Adset Name</p>
              <a
                className='popup_close_button'
                onClick={() => setEditAdkeyword(!editAdkeyword)}
              >
                <span></span>
              </a>
            </div>
            <div className='addad_modal_layout'>
              <div className='keyword_modal_sections'>
                <div className='tag_selected_blocks_popup'>
                  <div className='tag_selected_chips_popup'>
                    {topOfTheFunnelInterest &&
                      currentAdset < topOfTheFunnelInterest.adsets.length &&
                      topOfTheFunnelInterest.adsets
                        .filter((_, index) => index === currentAdset)[0]
                        ['keywords'].map((keyword, index) => (
                          <span className='chips_tag' index={index}>
                            {keyword.name}
                            <button
                              className='clearchip'
                              onClick={() =>
                                dispatch({
                                  type: 'DELETE_KEYWORD_IN_ADSET_HDM',
                                  payload: {
                                    index: currentAdset,
                                    value: keyword,
                                  },
                                })
                              }
                              style={{ marginLeft: '5px' }}
                            ></button>
                          </span>
                        ))}
                  </div>
                </div>
                <div className='adset_list_hdm_popup'>
                  <p className='addNew_keyword_title'>Add New Keyword</p>
                  <Autocomplete
                    multiple
                    id='checkboxes-tags'
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <MyChip
                          {...getTagProps({ index })}
                          label={option.name}
                        />
                      ));
                    }}
                    renderOption={(props, option, { inputValue, selected }) => {
                      const matches = match(option.title, inputValue, {
                        insideWords: true,
                      });
                      const parts = parse(option.title, matches);
                      return (
                        <li {...props} className='search_location_list'>
                          <div className='location_list_head'>
                            {' '}
                            <p>Interest Keywords</p>
                          </div>
                          <div className='location_list_content'>
                            <div>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                onChange={() => {
                                  dispatch({
                                    type: 'ADD_KEYWORD_IN_ADSET_HDM',
                                    payload: {
                                      value: option,
                                      index: currentAdset,
                                    },
                                  });
                                }}
                              />
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    color: part.highlight
                                      ? '#0869FB'
                                      : '#333333',
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </div>
                        </li>
                      );
                    }}
                    options={
                      labels[currentAdset] ? [...labels[currentAdset]] : []
                    }
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <div className='location_question_header'>
                        <span className='search_icon'></span>
                        <TextField
                          {...params}
                          ref={params.InputProps.ref}
                          inputProps={params.inputProps}
                          autoFocus
                          placeholder='Search'
                          variant='standard'
                        />
                      </div>
                    )}
                    onChange={(eve, val) => {
                      autochange(eve, val, 'popup');
                    }}
                    onKeyDown={(event) => {
                      setCurrentKeyword(event.target.value);
                      getInterestedKeywords(event.target.value, currentAdset);
                    }}
                    disablePortal={true}
                    className='auto_location_search'
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MOF;
