import { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  goBackHDMAmCompo,
  updateCompoReduxHDMAM,
} from '../../../api/HDMAM/componentTracking/action';
import './HDMAMBestPractice.css';
import CREATIVES from './strategy-Icons/Creatives.png';
import VIDEO from './strategy-Icons/videoedit.png';

import StrategyDeckSureModal from './StrategyDeckSureModal';
import ProposalPublishedModal from '../../modals/ProposalPublishedModal';

import { publishHdmAmFormStrategy } from '../../../api/HDMAM/form/action';
import { PROPOSAL_STATE } from '../../../utils/accountManagerUtil';

const BestPraticeHDM = () => {
  const dispatch = useDispatch();
  const [sureModal, setSureModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);

  const form = useSelector((state) => state.hdmFormAm);

  const publishStrategy = () => {
    dispatch(
      publishHdmAmFormStrategy({
        strategyIterations: [
          {
            state: (form?.strategyIterations || []).length
              ? PROPOSAL_STATE.CHANGES_APPLIED
              : PROPOSAL_STATE.GENERATED,
            suggestedChanges: [],
          },
        ],
      })
    );
    setSureModal(false);
    setPublishModal(true);
  };

  return (
    <div style={{ width: '100%' }}>
      <div className='proposal_layout proposal_flex_top '>
        <div className='preview_head'>
          <h4>PROPOSAL</h4>
          <h2>Horizons Digital Marketing </h2>
          {/* <img src="./test.svg" /> */}
        </div>
      </div>
      <div className='preview_footer_row'>
        <div className='back_button'>
          {' '}
          <Button
            style={{ color: '#0869FB', cursor:'pointer' }}
            onClick={() => {
              dispatch(goBackHDMAmCompo());
            }}
          >
            BACK{' '}
          </Button>
        </div>
        <div className='footer_content'>
          <Button
            variant='contained'
            onClick={() => setSureModal(true)}
            disabled={
              (form.strategyIterations || []).length &&
              ![PROPOSAL_STATE.CHANGES_SUGGESTED].includes(
                form.strategyIterations[form.strategyIterations.length - 1]
                  .state
              )
            }
          >
            PUBLISH
          </Button>
        </div>
      </div>
      <div className='best-main'></div>
      <div className='scroll_items'>
        {/* <div className='phase'>
        <Typography style={{fontFamily:'Lato',fontWeight:'700', color:'#000000',fontSize:'30px',lineHeight:'36px',marginLeft:'52px'}}>Onboarding Phases</Typography>
        <img style={{height:"491.34px", width:'100%'}} src="../../../assets/images/phase.png" alt="" />
        
    </div> */}

        <div className='Expectation'>
          <div className='Expectation_head'>
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '25px',
                fontWeight: '700',
                lineHeight: '51.91px',
                margin: '0px',
              }}
            >
              <img
                style={{
                  position: 'relative',
                  top: '15px',
                  height: 100,
                  width: 100,
                }}
                src={CREATIVES}
                alt=''
              />{' '}
              Creative Expectations
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    01
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    We NEED to have good creatives in order to perform well with
                    social ads
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    02
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    We will communicate what we need from you, if you’re unsure
                    about something… Just let us know
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    03
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    We will want to test radically different creatives… This is
                    important. If you, or I don’t like something… That personal
                    opinion doesn’t matter. It’s what the market decides. We ask
                    you have an open mind to testing. Sound fair?
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className='Video'>
          <div className='Video_head'>
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '25px',
                fontWeight: '700',
                lineHeight: '51.91px',
                margin: '0px',
              }}
            >
              <img
                style={{ position: 'relative', top: '15px' }}
                src={VIDEO}
                alt=''
              />{' '}
              Video Editing Expectations
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    01
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    If you would like to produce a specific video, please
                    provide an example where possible
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    02
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Usually can be done within 2-3 business days but allow for
                    up to 7 business days in busy seasons
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    03
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      lineHeight: '28px',
                      fontSize: '20px',

                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    We don’t charge anything additional for this service… So we
                    offer 1 revision
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      {sureModal && (
        <StrategyDeckSureModal
          modal={sureModal}
          setModal={setSureModal}
          publishDeck={publishStrategy}
        />
      )}
      {publishModal && (
        <ProposalPublishedModal
          formSubm={publishModal}
          setFormSubm={setPublishModal}
          title='Strategy Deck Published!'
          description='You will be notified as soon as the client has reviewed / approved the strategy deck.'
          buttonTitle='Home'
        />
      )}
    </div>
  );
};
export default BestPraticeHDM;
