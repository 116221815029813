import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  RadioGroup,
} from '@mui/material';
import { Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';

const MaritalStatusCheckbox = ({ value, objKey, infoType, outerKey }) => {
  const values = [
    'Married',
    'Widowed',
    'Separated',
    'Never Married',
    'Divorced',
  ];
  console.log(value);
  const dispatch = useDispatch();
  return (
    <FormControl variant='standard'>
      <Select
        inputProps={{ 'aria-label': 'Without label' }}
        style={{ width: 600 }}
        placeholder='Choose your answer here...'
        multiple
        renderValue={(selected) => selected.join(',')}
        value={
          value.length === 0 ? ['Choose Answers...'] : value
        }
      >
        <MenuItem disabled value='Choose your answer here' selected>
          <em>Choose your answer here</em>
        </MenuItem>
        <>
          {values.map((value1, index) => (
            <MenuItem value={value1} key={index}>
              <FormControlLabel
                value={value1}
                control={<Checkbox />}
                label={value1}
                onChange={(e) => {
                  dispatch({
                    type: 'UPDATE_MULTIPLE_CHECKBOX_HDM',
                    payload: {
                      infoType: infoType,
                      key: objKey,
                      value: e.target.value,
                      element: values,
                    },
                  });
                }}
                checked={value.includes(value1) ? true : false}
              />
            </MenuItem>
          ))}
        </>
      </Select>
    </FormControl>
  );
};

export default MaritalStatusCheckbox;
