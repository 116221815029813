import {
  APPROVE_STRATEGY_DECK_WITH_DETAILS,
  GET_HDM_CLIENT_DETAILS_BY_FORM_ID,
  SAGA_UPDATE_HDM_CLIENT_DETAILS,
  UPDATE_HDM_ARRAY_PLATFORMS_CHECKBOX,
  UPDATE_STRATEGY_DECK,
} from "./enum";

export const updateQuestionier = (type, payload) => {
  return { type, payload };
};

export const updateHDMClientDetailsReducer = (type, payload) => {
  return {
    type: type,
    payload: payload,
  };
};

export const updateHDMClientDetails = () => {
  return {
    type: SAGA_UPDATE_HDM_CLIENT_DETAILS,
  };
};

export const approveStrategyDeckWithDetails = () => {
  return {
    type: APPROVE_STRATEGY_DECK_WITH_DETAILS,
  };
};

export const getHDMClientDetailsByFormId = () => {
  return {
    type: GET_HDM_CLIENT_DETAILS_BY_FORM_ID,
  };
};

export const updateHDMArrayCheckbox = (payload) => {
  return {
    type: UPDATE_HDM_ARRAY_PLATFORMS_CHECKBOX,
    payload: payload,
  };
};

export const updateHDMStrategyDeck = (payload) => {
  return {
    type: UPDATE_STRATEGY_DECK,
    payload: payload,
  };
};
