import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import './campaign-preview.css';
import CampaignTypePreview from './CampaignTypePreview';
import PrimaryButton from '../../ClientDashboard/ButtonPrimary/Buttons';

import { getGoogleCampaignObject } from '../../../utils/accountManagerUtil';

const CampaignPreview = ({agencyType='LD'}) => {
  const [campaignObject, setCampaignObject] = useState({});
  const form = useSelector(store => agencyType === 'HDM' ? store.hdmFormAm: store.form.form);

  useEffect(() => {
    if (form?.googleSearchAdsGeneratedCampaignsInfo) {
      const [campaignObj, isGenerating, hasError, isGenerated] = getGoogleCampaignObject(form.googleSearchAdsGeneratedCampaignsInfo);
      setCampaignObject(campaignObj)
    }
  }, [form])
  return (
    <div className='campaign-preview-container'>
      <div className='campaign-preview-header'>
        <Typography className='campaign-preview-header__title'>
          Campaign Preview
        </Typography>
        <Typography className='campaign-preview-header__description'>
          Here are details of the campaigns that were created
        </Typography>
      </div>
      <CampaignTypePreview
        title='Brand Campaign'
        campaignID={campaignObject.brandCampaign || ''}
        campaignName={form?.campaignInfo?.brandCampaign?.name || 'Brand Campaign'}
        campaignURL='https://ads.google.com/'
      />
      <CampaignTypePreview
        title='Competitor Campaign'
        campaignID={campaignObject.competitorCampaign || ''}
        campaignName={form?.campaignInfo?.competitorCampaign?.name || 'Competitor Campaign'}
        campaignURL='https://ads.google.com/'
      />
      <CampaignTypePreview
        title='Services Campaign'
        campaignID={campaignObject.serviceCampaign || ''}
        campaignName={form?.campaignInfo?.serviceCampaign?.name || 'Services Campaign'}
        campaignURL='https://ads.google.com/'
      />
      <div className='preview_footer_row'>
        <div className='footer_con'>
          {/* <button className='back'>Back</button> */}
          <PrimaryButton title='Home' onClick={() => {
            window.location.href = '/brands';
          }} />
        </div>
      </div>
    </div>
  );
};

export default CampaignPreview;
