import { useState, useEffect } from 'react';
import NavigationSteps from './NavigationSteps/index';
import HeaderNavSteps from './HeaderNavSteps/index';
import { Grid } from '@mui/material';
import {
  AccountLeftSideBar,
  headerTabs,
  headerTabsAccountManager,
  listOfIdsHDM,
} from '../../utils/navbarUtil';
import {
  listOfIds,
  headerNavbarAM,
  leftSidebarAMWithCurrentCompIds,
} from '../../utils/navbarUtil';
import RenderCurrentComponent from './RenderCurrentComponent';
import RenderClientCurrentComponent from '../ClientDashboard/RenderCurrentComponent';
import { useSelector } from 'react-redux';
import '../ClientDashboard/clientDashboard.css';
import {
  leftBarStatus,
  mappedComponentWithIndex,
  ldComponentWithIndexAm,
} from '../../utils/accountManagerUtil';
import { getClientDetailsByFormId } from '../../api/client/action';
import { getInsightsForFormIdReducer } from '../../api/accountManager/googleAdsCustomer/slice';
import { useDispatch } from 'react-redux';
import { getLocalStorage } from '../../api/localStorage/localStorage';
import LoaderModal from './LoaderModal/LoaderModal';
import { mappedLdClientCompoNameWithIndex } from '../../utils/clientDashboardIdsUtil';
import { getSharedAssetsGoogel } from '../../api/sharedAssets/action';
import { updateSideBar } from '../../api/accountManager/componentTracking/action';
import { getStatusForLeftBar } from '../../utils/accountManagerUtil';
import { getFormByFormIdReducer } from '../../api/accountManager/forms/slice';
import { switchRedirect } from '../../api/analytics/action';
import { getAdPixels } from '../../api/HDMAM/campaign/action';

const AccountManager = () => {
  const dispatch = useDispatch();
  const amCurrentCompoToRender = useSelector(
    (state) => state.amCurrentComp.currentCompAm
  );
  const currentLeftSidebar = useSelector(
    (state) => state.amCurrentComp.currentLeftSidebarAm
  );
  const currentHeaderTab = useSelector(
    (state) => state.amCurrentComp.currentHeaderTabAm
  );
  const clientCurrentCompoToRender = useSelector(
    (state) => state.clientCurrentComp.currentComp
  );
  const currentClientHeaderTab = useSelector(
    (state) => state.clientCurrentComp.currentHeaderTab
  );
  const [firstTime, setFirstTime] = useState(true);
  const clientView = useSelector((state) => state.amCurrentComp.clientView);
  const form = useSelector((state) => state.form);
  const sharedAssets = useSelector(
    (state) => state.sharedAssets.googleSharedAssetsAccounts
  );
  const haveInsights = useSelector(
    (store) => store.googleCustomers.haveInsights
  );
  const redirectBack = useSelector((state) => state.dashboard.redirectBack);
  useEffect(() => {
    getLocalStorage('formId') && dispatch(getClientDetailsByFormId());
    dispatch(getInsightsForFormIdReducer());
    dispatch(getSharedAssetsGoogel());
    dispatch(getFormByFormIdReducer());
    dispatch(getAdPixels());
  }, []);

 console.log('currentLeftSideBar', currentLeftSidebar);
 console.log('headerNavBarHDM', Object.values(headerNavbarAM[currentLeftSidebar] || {}));

  useEffect(() => {
    if (redirectBack) {
      dispatch(switchRedirect());
      setFirstTime(false);
    } else if (firstTime && form.form && (sharedAssets || []).length) {
      const leftStatus = getStatusForLeftBar(
        form?.form || {},
        haveInsights,
        sharedAssets
      );
      const ongoingStage = Object.keys(leftStatus || {}).filter(
        (stage) => leftStatus[stage] === leftBarStatus.ongoing
      );
      if ((ongoingStage || []).length) {
        if (ongoingStage[0] === listOfIds.sharedAssets) {
          dispatch(
            updateSideBar(
              leftSidebarAMWithCurrentCompIds[listOfIds.accountOnboarding]
            )
          );
        } else {
          dispatch(
            updateSideBar(leftSidebarAMWithCurrentCompIds[ongoingStage[0]])
          );
        }
        setFirstTime(false);
      }
    }
  }, [form, haveInsights, sharedAssets]);

  const getHeadBarsForStrategyCreationGoogle = (currHeadTab) => {
    if (
      [
        headerTabsAccountManager['BRAND CAMPAIGN'],
        headerTabsAccountManager['COMPETITOR CAMPAIGN'],
        headerTabsAccountManager['SERVICES CAMPAIGN'],
      ].includes(currHeadTab)
    ) {
      return listOfIds.STRATEGY_INPUT_SEARCH;
    }
    if (
      [
        headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_1],
        headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_2],
        headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_3],
      ].includes(currHeadTab)
    ) {
      return listOfIds.STRATEGY_INPUT_PMAX;
    }
  };

  return (
    <main
      class={`${mappedComponentWithIndex.get(
        amCurrentCompoToRender
      )} main_layout accountManger`}
    >
      <section className='nav_section'>
        <NavigationSteps
          navigateSteps={AccountLeftSideBar}
          active={currentLeftSidebar}
        />
      </section>
      <section
        style={{
          paddingBottom: [listOfIds.campaignForGoogle].includes(currentLeftSidebar) ? "16px" : clientView ? '0px' : '74px',
          overflowY: 'auto',
        }}
        className={`${
          currentLeftSidebar &&
          (clientView ? currentClientHeaderTab : currentHeaderTab) &&
          Object.values(headerNavbarAM[currentLeftSidebar] || {}).length
            ? 'top_nav_item'
            : ''
        } right_layout`}
      >
        <div className={`right_layout_inner`}>
          {currentLeftSidebar &&
            (clientView ? currentClientHeaderTab : currentHeaderTab) &&
            Object.values(headerNavbarAM[currentLeftSidebar] || {}).length !==
              0 && (
              <div className='top_nav_section'>  
                <div className='top_nav_inner'>
                  <div
                    style={{
                      width: '100%',
                      height: '64px',
                      display: 'flex',
                    }}
                  >
                    <HeaderNavSteps
                      headerNavList={
                        currentLeftSidebar === listOfIds.strategyInput
                          ? headerNavbarAM[listOfIds.strategyInput][
                              getHeadBarsForStrategyCreationGoogle(
                                currentHeaderTab
                              )
                            ]
                          : headerNavbarAM[currentLeftSidebar]
                      }
                      active={
                        clientView ? currentClientHeaderTab : currentHeaderTab
                      }
                      clientView={clientView}
                      clientCurrentCompoToRender={clientCurrentCompoToRender}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* <TourTooltip/> */}
          <div className={`${currentLeftSidebar} right_main_section`}>
            {clientView ? (
              <RenderClientCurrentComponent
                currentCompoent={mappedLdClientCompoNameWithIndex.get(
                  clientCurrentCompoToRender
                )}
              />
            ) : (    
              <RenderCurrentComponent
                currentCompoent={mappedComponentWithIndex.get(
                  amCurrentCompoToRender
                )}
              />
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
export default AccountManager;
