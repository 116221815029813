import {api} from "../index";

export const uploadFile = (data) => {
    return new Promise(async (resolve,reject)=>{
        try{
            const response=await api.post(`/files:upload`,data,true);
            resolve(response.data);
        }catch(err){
            reject(err);
        }
    });
}

export const getImageUrl = (id) => {
    return new Promise(async (resolve,reject)=>{
        try{
            const response=await api.get(`/files/${id}`,true);
            resolve(response.data);
        }catch(err){
            console.log("err",err)
            reject(err);
        }
    });
}