import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { goToSpecificCompoHDMClient } from "../../../api/HDMClient/componentTracking/action";
import {ready as readyGif} from "../../../assets/icons/proposal/proposal";
import PrimaryButton from "../../AccountManager/ButtonPrimary/Buttons";
import "./LastPageHDM.css";

const DeckSuccess = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gap: "0.5rem",
        }}
      >
        <div>
          <img src={readyGif} alt="" width={150} height={150} />
        </div>
        <div>
          <Typography
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 24,
            }}
          >
            Please wait while we generate a strategy for you
          </Typography>
        </div>
        <div
          style={{
            margin: "2rem 0",
            display: "flex",
            gap: "1.5rem",
            justifyContent: "center",
          }}
        >
          <PrimaryButton
            title="Home"
            onClick={() => dispatch(goToSpecificCompoHDMClient(0))}
          />
        </div>
      </div>
      {/* <div className="center">
        <img src={readyGif} width={150} height={150} />
        <div className="submit-title">
         
        </div>
        <div className="submit-desc">
          We will notify you as soon as the onboarding doc is ready for your{" "}
          <br />
          approval.
        </div>
        <Button
          variant="contained"
          onClick={() => dispatch(goToSpecificCompoHDMClient(0))}
        >
          Home
        </Button>
      </div> */}
    </>
  );
};
export default DeckSuccess;
