import { all, put, takeLatest,select , delay} from "redux-saga/effects";
import {SAGA_UPDATE_CLIENT_DETAILS,GET_CLIENT_DETAILS_BY_FORM_ID,UPDATE_WHOLE_CLIENT_FORMID,CLIENT_STATUS_SAVE_DB,UPDATE_CITY_SUGGESTIONS,CITY_LOCATION_AUTOSUGGESTION_INPUT} from "./enum";
import {api} from "../../api/index";
import {setLocalStorage,getLocalStorage} from "../../api/localStorage/localStorage";
import {updateData} from "../../api/accountManager/forms/slice";
import { UPDATE_CITY_SUGGESTIONS_HDM } from "../HDMClient/enum";
import { clientDashboardIds, LDcomponentWithIndexClient } from "../../utils/clientDashboardIdsUtil";

function* updateClientDetails(_,isPublished=false){
    let client=yield select(state => state.client);
    yield put(updateData(client));
    const currentComp=yield select(state => state.clientCurrentComp.currentComp);
    if(currentComp !== LDcomponentWithIndexClient[clientDashboardIds.EDIT_RESPONSES_INTAKE_FORM]){
        // if(CLIENT_STATUS_SAVE_DB[currentComp] !== undefined){
        //     const {parentKey,childKey}=CLIENT_STATUS_SAVE_DB[currentComp];
        //     client[parentKey][childKey]["status"]="COMPLETED";
        // }else if(currentComp === 1 && client.onboardingInfo.personalInfo.status !== "COMPLETED"){
        //     client["onboardingInfo"]["personalInfo"]["status"]="ONGOING";
        //     client["onboardingInfo"]["status"]="ONGOING";
        // }else if(currentComp === 11 && client.onboardingInfo.platformInfo.status !== "COMPLETED"){
        //     client["onboardingInfo"]["platformInfo"]["status"]="ONGOING";
        // }
        const isFormIdExist={};
        if(getLocalStorage("formId")){
           isFormIdExist["_id"]=getLocalStorage("formId");
        }
        const desiredObj={...client,published:isPublished,...isFormIdExist};
        try{
            const res=yield api.post("/client-forms",desiredObj,false);
            yield put(updateData(res.data));
            yield put({
                type:"UPDATE_WHOLE_CLIENT_FORMID",
                payload:res.data
            });
            yield put({
                type:"UPDATE_WHOLE_CLIENT_FORMID",
                payload:res.data
            });
            yield put({
                type:"UPDATE_WHOLE_HDM_CLIENT_FORMID",
                payload:res.data
            });
            setLocalStorage("formId",res.data._id);
        }catch(err){
            console.log(err);
        }
    }
}

function* getClientDetailsByFormId(){
    try{
        const res=yield api.get("/client-forms/"+getLocalStorage("formId"));
        console.log("in saga &&&&&&&&&&&&&&&&&&", res);
        yield put({
            type:UPDATE_WHOLE_CLIENT_FORMID,
            payload:res.data
        })
        yield put({
            type: "UPDATE_WHOLE_HDM_CLIENT_FORMID",
            payload: res.data,
        });
    }catch(err){
        console.log(err);
    }
}

function* getClientCity(data){
    try {
        yield delay(500);
        const res=yield api.get("/g-ads/geo-targets?locationNames="+data.payload.value);
        try{
            yield put({
                type: UPDATE_CITY_SUGGESTIONS,
                payload:{...res.data, value: data.payload.value}
            })
        } catch (err){
            console.log("error in ld client", err.message);
        }
        try{
            yield put({
                type: UPDATE_CITY_SUGGESTIONS_HDM,
                payload:{...res.data, value: data.payload.value}
            })
        } catch (error){
            console.log("error in hdm client while fetching city", error.message);
        }
    } catch(err){
        console.log(err);
    }
}

export const getFormIdWhileLogin = () => {
    return new Promise(async (resolve,reject)=>{
                try{
                    const res=await api.get("/client-forms");
                    resolve(res.data);
                }catch(err){
                    reject(err);
                }
    })
}

export const suggestChangesApi = (value,array) => {
    const isFormIdExist={};
    if(getLocalStorage("formId")){
        isFormIdExist["_id"]=getLocalStorage("formId");
    }
    return new Promise(async (resolve,reject)=>{
        try{
            const newArray=array;
            newArray.push({state:"CHANGES_SUGGESTED",suggestedChanges:[value]});
            const res=await api.post("/client-forms",{published:false,proposalIterations:newArray,...isFormIdExist},false);
            resolve(res);
        }catch(err){
            reject(err);
        }
    });
}

export const onboardingFormSubmApi = (client) => {
    const tempClient = JSON.parse(JSON.stringify({...(client || {})}));
    tempClient["onboardingInfo"]["status"]="COMPLETED";
    tempClient["sowPricingInfo"]["status"] = "ONGOING";
    const isFormIdExist={};
    if(getLocalStorage("formId")){
        isFormIdExist["_id"]=getLocalStorage("formId");
    }
    return new Promise(async (resolve,reject)=>{
        try{
            const res=await api.post("/client-forms",{...tempClient,...isFormIdExist},false);
            resolve(res);
        }catch(err){
            reject(err);
        }
    });
}

export const proposalApprovedApi = (array) => {
    const isFormIdExist={};
    if(getLocalStorage("formId")){
        isFormIdExist["_id"]=getLocalStorage("formId");
    }
    return new Promise(async (resolve,reject)=>{
        try{
            const newArray=array;
            newArray.push({state:"APPROVED",suggestedChanges:[]});
            const res=await api.post("/client-forms",{published:true,proposalIterations:newArray,...isFormIdExist},false);
            resolve(res);
        }catch(err){
            reject(err);
        }
    });
}

export const getInsights = () => {
    return new Promise(async (resolve,reject)=>{
        try{
            const formId = localStorage.getItem("formId");
            const url = `/g-ads/audit-summary/${formId}`;
            const data=await api.get(url);
            resolve(data.data);
        }catch(err){
            reject(err);
        }
    })
}

export const intakeFormSubmittedApi = (client) => {
    const jsonify=JSON.parse(JSON.stringify(client));
    jsonify["intakeInfo"]["status"]="COMPLETED";
    const isFormIdExist={};
    if(getLocalStorage("formId")){
        isFormIdExist["_id"]=getLocalStorage("formId");
    }
    return new Promise(async (resolve,reject)=>{
        try{
            const res=await api.post("/client-forms",{...jsonify,...isFormIdExist},false);
            resolve(res);
        }catch(err){
            reject(err);
        }
    });
}

export const suggestChangesApiDeck = (value,array) => {
    const isFormIdExist={};
    if(getLocalStorage("formId")){
        isFormIdExist["_id"]=getLocalStorage("formId");
    }
    return new Promise(async (resolve,reject)=>{
        try{
            const newArray=array;
            newArray.push({state:"CHANGES_SUGGESTED",suggestedChanges:[value]});
            const res=await api.post("/client-forms",{strategyIterations:newArray,...isFormIdExist},false);
            resolve(res);
        }catch(err){
            reject(err);
        }
    });
}

export const approveDeckApi = (array,isFacebook=false,other=null) => {
    const isFormIdExist={};
    let extraObj={};
    let brandId = "";
    if(getLocalStorage("brandId") && getLocalStorage("brandId") !== "undefined"){
      brandId = getLocalStorage("brandId");
    } else {
      brandId = getLocalStorage("amSelectedBrandId");
    }
    if(getLocalStorage("formId")){
        isFormIdExist["_id"]=getLocalStorage("formId");
    }
    if(other){
        extraObj={...other};
        delete extraObj["strategyIterations"];
    }
    extraObj.brandId = brandId;
    return new Promise(async (resolve,reject)=>{
        try{
            const newArray=array;
            if(!isFacebook){
               newArray.push({state:"APPROVED",suggestedChanges:[]})
            }
            const res=await api.post("/client-forms",{strategyIterations:newArray,...extraObj,...isFormIdExist},false);
            resolve(res);
        }catch(err){
            reject(err);
        }
    });
}

export default function* root() {
    yield all([
        takeLatest(SAGA_UPDATE_CLIENT_DETAILS,updateClientDetails),
        takeLatest(GET_CLIENT_DETAILS_BY_FORM_ID,getClientDetailsByFormId),
        takeLatest(CITY_LOCATION_AUTOSUGGESTION_INPUT, getClientCity)
    ])
}
