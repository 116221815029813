import { Typography, Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import {
    CREATIVE_OBJECT_STATUS_ENUM,
  HEADLINE_CHAR_LIMIT,
  PLATFORM_NAME,
} from '../../../api/creativeApproval/enums';
import { crossIcon } from '../../../assets/icons/creative/creative';


const Headline = ({ isRead, setShowError, setIsRead, headlineList, platform, status, isClient, setHeadlineList }) => {
  const changeHeadline = (e, index) => {
    setShowError(false);
    const newHeadlineList = headlineList.slice();
    newHeadlineList[index] = e.target.value;
    setHeadlineList(newHeadlineList);
  };

  const removeHeadline = (index) => {
    setShowError(false);
    const newHeadlineList = headlineList.slice();
    newHeadlineList.splice(index, 1);
    setHeadlineList(newHeadlineList);
  };

  const maxCharLimitHeading =
    platform === PLATFORM_NAME.GOOGLE
      ? HEADLINE_CHAR_LIMIT.GOOGLE
      : HEADLINE_CHAR_LIMIT.FACEBOOK;
  return (
    <div
      style={{
        border: '2px solid #E6F6FF',
        borderRadius: '8px',
        width: '35%',
      }}
    >
      <div
        style={{
          padding: '10px 0 11px 20px',
          borderBottom: '1px solid #F0F0F0',
        }}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '19px',
            color: '#333333',
          }}
        >
          Headlines
        </Typography>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 10,
            lineHeight: '12px',
            color: '#827F7F',
            marginTop: 4,
          }}
        >
          Please add headlines to be approved by the client.
        </Typography>
      </div>
      <div
        style={{
          maxHeight: 225,
          minHeight: 225,
          padding: '1rem',
          overflowY: 'scroll',
        }}
      >
        {headlineList.map((headline, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 15,
              width: '100%',
              paddingBottom: 12,
            }}
          >
            <div
              style={{
                background: '#FFFFFF',
                border: '1px solid #F0F0F0',
                boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.04)',
                borderRadius: '5px',
                width: '98%',
                paddingLeft: 15,
                paddingTop: 6,
                paddingBottom: 6,
                position: 'relative',
                minHeight: 34,
              }}
            >
              <>
                {!isRead ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      variant='standard'
                      value={headline}
                      // disabled={isRead}
                      multiline
                      error={
                        headline.length === maxCharLimitHeading ? true : false
                      }
                      inputProps={{
                        style: {
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: 14,
                          lineHeight: 1.5,
                          width: 220,
                          // paddingBottom: 6,
                          border: 0,
                        },
                        maxLength:
                          platform === PLATFORM_NAME.GOOGLE
                            ? HEADLINE_CHAR_LIMIT.GOOGLE
                            : HEADLINE_CHAR_LIMIT.FACEBOOK,
                      }}
                      onChange={(e) => changeHeadline(e, index)}
                    />
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.5,
                        paddingRight: 8,
                      }}
                    >
                      {platform === PLATFORM_NAME.GOOGLE
                        ? `${headline.length}/${HEADLINE_CHAR_LIMIT.GOOGLE}`
                        : `${headline.length}/${HEADLINE_CHAR_LIMIT.FACEBOOK}`}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                      }}
                    >
                      {headline}
                    </Typography>
                    {headline.length !== 0 && (
                      <span
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: 1.5,
                          paddingRight: 8,
                        }}
                      >
                        {platform === PLATFORM_NAME.GOOGLE
                          ? `${headline.length}/${HEADLINE_CHAR_LIMIT.GOOGLE}`
                          : `${headline.length}/${HEADLINE_CHAR_LIMIT.FACEBOOK}`}
                      </span>
                    )}
                  </div>
                )}

                {/* {!isRead && (
                  <span>
                    {platform === PLATFORM_NAME.GOOGLE
                      ? `${headline.length}/${HEADLINE_CHAR_LIMIT.GOOGLE}`
                      : `${headline.length}/${HEADLINE_CHAR_LIMIT.FACEBOOK}`}
                  </span>
                )} */}
              </>
            </div>
      { isClient && status === CREATIVE_OBJECT_STATUS_ENUM.PENDING &&
            <img
              src={crossIcon}
              alt='cross-icon'
              onClick={() => removeHeadline(index)}
            />
      }
      { !isClient &&
            <img
              src={crossIcon}
              alt='cross-icon'
              onClick={() => removeHeadline(index)}
            />
      }
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#FFFFFF',
          borderTop: '1px solid #F0F0F0',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.03)',
          borderRadius: '0 0 8px 8px',
          padding: '8px 0',
        }}
      >
      { isClient && status === CREATIVE_OBJECT_STATUS_ENUM.PENDING &&
        <Button
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '20px',
            color: '#0869FB',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
          }}
          onClick={() => { 
              setHeadlineList([...headlineList, ''])
              setIsRead(false);
          }}
        >
          +Add Headline
        </Button>
      }
      { ! isClient &&
        <Button
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '20px',
            color: '#0869FB',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
          }}
          onClick={() => { 
              setHeadlineList([...headlineList, ''])
              setIsRead(false);
          }}
        >
          +Add Headline
        </Button>
      }
      </div>
    </div>
  );
};

export default Headline;
