import { Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";

const Menu = (props) => {
  const [currentCompClient, setCurrentCompoClient] = useState(
    props.currentCompoClient
  );
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.client);
  const [visitedLastTime, setVisitedLastTime] = useState(props.visitedLastTime);
  useEffect(() => {
    let maxCompo = Math.max(visitedLastTime, props.visitedLastTime);
    if(localStorage.getItem("role") === "ACCOUNT_MANAGER"){
        maxCompo = Math.max(maxCompo, 69);
    }else{
      if (reduxState.onboardingInfo.personalInfo.status === "COMPLETED") {
        maxCompo = Math.max(maxCompo, 10);
      }
      if (reduxState.onboardingInfo.platformInfo.status === "COMPLETED") {
        maxCompo = Math.max(maxCompo, 17);
      }
      if (reduxState.onboardingInfo.growthGoal.status === "COMPLETED") {
        maxCompo = Math.max(maxCompo, 18);
      }
      if (reduxState.published) {
        maxCompo = Math.max(maxCompo, 26);
      }
      if (reduxState.intakeInfo.productServiceInfo.status === "COMPLETED") {
        maxCompo = Math.max(maxCompo, 36);
      }
      if (reduxState.intakeInfo.preferences.status === "COMPLETED") {
        maxCompo = Math.max(maxCompo, 41);
      }
      if (reduxState.intakeInfo.audiences.status === "COMPLETED") {
        maxCompo = Math.max(maxCompo, 53);
      }
      if (reduxState.intakeInfo.brandAssets.status === "COMPLETED") {
        maxCompo = Math.max(maxCompo, 60);
      }
      if (reduxState.intakeInfo.customerTestimonial.status === "COMPLETED") {
        maxCompo = Math.max(maxCompo, 63);
      }
    }
    setVisitedLastTime(maxCompo);
  }, [reduxState, visitedLastTime, currentCompClient, props.visitedLastTime]);
  useEffect(() => {
    setCurrentCompoClient(props.currentCompoClient);
  }, [props.currentCompoClient]);
  return (
    <div
      style={{
        width: 300,
        maxHeight: "454px",
        background: "#FFFFFF",
        position: "absolute",
        right: 10,
        bottom: "5rem",
        borderRadius: 10,
        zIndex: 99,
        overflow: "auto",
      }}
    >
      <Typography
        style={{
          fontFamily: "Lato",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 18,
          padding: "1rem 0 0.5rem 1rem",
        }}
      >
        Question Menu
      </Typography>
      <Divider />
      {props.questions.map((question, index) => (
        <div key={question.id + currentCompClient}>
          <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 14,
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              paddingLeft: "0.75rem",
              backgroundColor: "#fcfcfc",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              const nearestMandQuest = props.questions.findIndex(
                (question) =>
                  currentCompClient < question.id &&
                  question.questName.charAt(question.questName.length - 1) ===
                    "*"
              );
              const currentCompoIndex = props.questions.findIndex(
                (question) => question.id === currentCompClient
              );
              if (question.id <= visitedLastTime) {
                dispatch(goToSpecificCompoClient(question.id));
              } else if (
                nearestMandQuest !== -1 &&
                props.questions[nearestMandQuest].id > question.id &&
                props.questions[currentCompoIndex].questName.charAt(
                  props.questions[currentCompoIndex].questName.length - 1
                ) !== "*"
              ) {
                dispatch(goToSpecificCompoClient(question.id));
              } else if (
                props.questions[currentCompoIndex].questName.charAt(
                  props.questions[currentCompoIndex].questName.length - 1
                ) !== "*"
              ) {
                dispatch(goToSpecificCompoClient(question.id));
              }
            }}
          >
            <span
              style={{
                display: "inline-block",
                lineHeight: 0,
                background: "#FFFFFF",
                border: `0.785714px solid ${
                  question.id < currentCompClient ||
                  question.id <= visitedLastTime
                    ? "#28B49B"
                    : "#0869FB"
                }`,
                // padding: '0.3rem',
                borderRadius: "50%",
                marginRight: "0.5rem",
                color:
                  question.id === currentCompClient
                    ? "white"
                    : question.id < currentCompClient ||
                      question.id <= visitedLastTime
                    ? "#28B49B"
                    : "",
                backgroundColor:
                  question.id < currentCompClient
                    ? ""
                    : question.id === currentCompClient
                    ? "#0869FB"
                    : "",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  paddingTop: "50%",
                  paddingBottom: "50%",
                  marginLeft: 6,
                  marginRight: 6,
                }}
              >
                {index + 1}
              </span>
            </span>{" "}
            {question.questName}
          </Typography>
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default Menu;
