import { Button, Grid, Typography } from "@mui/material";
import ANA from "../../../assets/images/Onboarding/analysis.svg";
import AVATAR from "../../../assets/images/Onboarding/avatar.svg";
import DOC from "../../../assets/images/Onboarding/document-icon.svg";
import GOAL from "../../../assets/images/Onboarding/goal.svg";
import SCALE from "../../../assets/images/Onboarding/scale.svg";
import CHESS from "../../../assets/images/Onboarding/strategy-icon.svg";
import TEST from "../../../assets/images/Onboarding/test.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  goBackHDMAmCompo,
  updateCompoReduxHDMAM,
} from "../../../api/HDMAM/componentTracking/action";
import "./review.css";
import { isPlatformSelectedHDM } from "../../../utils/accountManagerUtil";

const date = new Date();
const ReviewHDMAM = () => {
  const clientData = useSelector((state) => state.hdmClient);
  const dispatch = useDispatch();
  const desiredDate = (date) =>
    moment(date).add(10, "days").format("YYYY-MM-DD");
  return (
    <div style={{ width: "100%" }}>
      <div className="proposal_layout proposal_flex_top ">
        <div className="preview_head">
          <h4>ONBOARDING DOCUMENT</h4>
          <h2>Horizons Digital Marketing </h2>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="back_button">
          {" "}
          <Button
            style={{ color: "#0869FB" }}
            onClick={() => {
              dispatch(goBackHDMAmCompo());
            }}
          >
            BACK{" "}
          </Button>
        </div>
        <div className="footer_content">
          <Button
            variant="contained"
            onClick={() => {
              dispatch(updateCompoReduxHDMAM());
            }}
          >
            PROCEED
          </Button>
        </div>
      </div>
      <div className="review12"></div>
      <div className="scroll_items">
        {/* <div className='hero2'  >
                <ReactLogo/>
                
            </div> */}
        <div className="brief">
          <div className="brief_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={DOC}
                alt=""
              />
              Briefing Document
            </h3>
          </div>
          <div className="pricing_card">
            {/* <div className="card">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Budgets
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                  marginTop: "24px",
                }}
              >
                $ {clientData.onboardingInfo.aboutBusiness.fbCamaignBudget}
              </b>
            </div> */}
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "98px",
                  marginTop: "35px",
                }}
              >
                Budgets
              </Typography>
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "13px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      marginLeft: "32.95px",
                    }}
                  >
                    GOOGLE
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "24px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                      marginTop: "24px",
                    }}
                  >
                    {isPlatformSelectedHDM(clientData, "google-ads") !== -1
                      ? `$${
                          clientData.onboardingInfo.clientAccess.platforms.find(
                            (platform) => platform.platform === "google-ads"
                          ).spends
                        }`
                      : "-"}
                  </b>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "13px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      marginLeft: "32.95px",
                    }}
                  >
                    FACEBOOK
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "24px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                      marginTop: "24px",
                    }}
                  >
                    {isPlatformSelectedHDM(clientData, "facebook-ads") !== -1
                      ? `$${
                          clientData.onboardingInfo.clientAccess.platforms.find(
                            (platform) => platform.platform === "facebook-ads"
                          ).spends
                        }`
                      : "-"}
                  </b>
                </Grid>
              </Grid>
            </div>

            {/*<div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Scope of Work
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                  marginTop: "24px",
                }}
              >
                20% $10
              </b>
              </div>*/}

            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Set Target Launch Date
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                  marginTop: "24px",
                }}
              >
                {desiredDate(clientData.accessSharing.updatedOn)}
              </b>
            </div>
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Break - even Point{" "}
                <b
                  style={{
                    fontFamily: "Lato",
                    fontSize: "12px",
                    lineHeight: "21px",
                    fontWeight: "400",
                    color: " #5B596B",
                  }}
                >
                  {" "}
                  {/*(AOV - COGS PER AOV + SHIPPING OER AOV)*/}
                </b>{" "}
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                  marginTop: "24px",
                }}
              >
                $ {clientData.onboardingInfo.aboutBusiness.breakEvenPoint}
              </b>
            </div>
          </div>
        </div>

        <div className="strategy_goal">
          <div className="strategy_head">
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={CHESS}
                alt=""
              />
              Our Strategy Goals
            </h3>
          </div>
          <Grid container xs={12}>
            <Grid item xs={6} style={{ paddingLeft: "24px" }}>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={GOAL} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Goals & Objectives
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.09px",
                      color: "#666666",
                    }}
                  >
                    Determine Short & Long Term goals <br /> profitability
                    analysis
                  </b>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #EBEBEB",
                  width: "369px",
                  marginTop: "32px",
                  marginBottom: "32px",
                }}
              ></div>

              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={ANA} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Analysis
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#333333",
                    }}
                  >
                    What is the biggest bottleneck in the <br /> success of your
                    store? <br />
                  </b>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#666666",
                    }}
                  >
                    Not just ads… Creative, website, brand etc.
                  </b>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #EBEBEB",
                  width: "369px",
                  marginTop: "32px",
                  marginBottom: "32px",
                }}
              ></div>

              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={AVATAR} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Avatars
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.09px",
                      color: "#666666",
                    }}
                  >
                    • WHO are we selling to <br /> • Market research &
                    competitive research
                  </b>
                  {/* <ul style={{padding:'0px',fontSize:'14px',fontWeight:'400',lineHeight:'20px',color:'#666666'}}>
                                    <li>WHO are we selling to</li>
                                    <li>Market research & competitive research</li>
                                </ul> */}
                </div>
              </div>
            </Grid>
            {/* <Grid xs={1}></Grid> */}
            <Grid item xs={6}>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={TEST} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Testing
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.09px",
                      color: "#666666",
                    }}
                  >
                    • Test what will move the needle the most
                    <br /> • Avatars → Hooks → Ads (ad copy is last)
                  </b>
                  {/* <ul style={{padding:'0px',fontSize:'14px',fontWeight:'400',lineHeight:'20px',color:'#666666'}}>
                                    <li> Test what will move the needle the most</li>
                                    <li> Avatars → Hooks → Ads (ad copy is last)</li>
                                </ul> */}
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #EBEBEB",
                  width: "369px",
                  marginTop: "32px",
                  marginBottom: "32px",
                }}
              ></div>

              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <img src={SCALE} alt="" />
                <div className="" style={{ marginLeft: "16px" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "26.09px",
                      color: "#1E1B39",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Scale
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "22.09px",
                      color: "#666666",
                    }}
                  >
                    • Vertical & Horizontal scaling{" "}
                  </b>
                  {/* <ul style={{padding:'0px',fontSize:'14px',fontWeight:'400',lineHeight:'20px',color:'#666666'}}>
                                    <li>WHO are we selling to</li>
                                    <li>Market research & competitive research</li>
                                </ul> */}
                </div>
              </div>
              <div
                style={{
                  border: "1px solid #EBEBEB",
                  width: "369px",
                  marginTop: "66px",
                  marginBottom: "32px",
                }}
              ></div>
            </Grid>
          </Grid>
        </div>
        {/* <div className='hero2'>
                                    <Expect/>
            </div>
            <div className='communication'>
                <div className='comm_head'>
                <h3 style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px",margin:'0px'}}><img style={{position:'relative', top:'15px'}} src={COMM} alt="" />Communication & Reporting</h3>
                </div>

                <Grid container xs={12} >
                    <Grid item xs={6} style={{paddingLeft:'32px'}}>
                        <div className='main_container'>
                            <div className='head'>
                                <Typography>
                                    From Us
                                </Typography>
                                
                            </div>
                            <div className='listing'>
                                    <ul>
                                        <li>Weekly Reporting</li>
                                        <li>2 monthly meetings (1 strategy call + 1 touch <br /> base call)</li>
                                        <li>Availability as needed through AM calendar</li>
                                    </ul>
                                </div>
                        </div>
                    </Grid>
                </Grid>
            </div> */}
      </div>
    </div>
  );
};
export default ReviewHDMAM;
