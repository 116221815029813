import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {pricing_icon as pricingIcon} from '../../../assets/icons/common/common';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import {checked_icon as checkedIcon} from '../../../assets/icons/common/common';
import {drop_down_arrow as dropdownArrow} from '../../../assets/icons/common/common';
import OptionalAdOns from './OptionalAdOns';
import {pencil as pencilIcon} from '../../../assets/icons/common/common';
import TextField from '@material-ui/core/TextField';
import checkedIconGreen from '../../../assets/icons/checked-icon.svg';
import { updatePricing } from '../../../api/accountManager/pricing/slice';

const getBasePriceObj = (basePrice, basePercent) => {
  if(basePrice === -1){
    return 'Custom';
  } 
  if(basePercent && !basePrice){
    return `${basePercent}%`;
  }
  if(basePrice && !basePercent){
    return `$${basePrice}`;
  }
  if(!basePrice && !basePercent){
    return `$0`
  }
  return `$${basePrice} or ${basePercent}%`;
}

const getPriceAndPercentage = (priceObj = "") => {
  if(priceObj.includes("%") && priceObj.includes("$")){
    const [price, percent] = priceObj.split(' or ');
    return [parseInt(price.slice(1)), parseInt(percent.slice(0,-1))]
  }
  if(priceObj.includes("%")){
    return [0, parseInt(priceObj.slice(0,-1))];
  }
  if(priceObj.includes("$")){
    return [parseInt(priceObj.slice(1)), 0]
  }
  return [0,0]
}
const PriceRate = (props) => {
  const dispatch = useDispatch();

  const [basePrice, setBasePrice] = useState(props.monthlyFee);
  const [landingPages, setLandingPages] = useState(props.landingPages);
  const [popUps, setPopUp] = useState(props.popUps);
  const [stickyBars, setStickyBars] = useState(props.stickyBars);
  const [basePriceEdit, setBasePriceEdit] = useState(false);
  const [landingPagesEdit, setLandingPagesEdit] = useState(false);
  const [popUpsEdit, setPopUpsEdit] = useState(false);
  const [stickyBarsEdit, setStickyBarsEdit] = useState(false);
  const [optionalAdons, setoptionalAdons] = useState(false);

  const [additionalLPQuantity, setAdditionalLPQuantity] = useState(0);
  const [additionalPUQuantity, setAdditionalPUQuantity] = useState(0);
  const [additionalSBQuantity, setAdditionalSBQuantity] = useState(0);
  const [callRailQuantity, setCallRailQuantity] = useState(0);
  const [hotjarQuantity, setHotjarQuantity] = useState(0);

  const [additionalLPPrice, setAdditionalLPPrice] = useState("500");
  const [additionalPUPrice, setAdditionalPUPrice] = useState("100");
  const [additionalSBPrice, setAdditionalSBPrice] = useState("100");
  const [callRailPrice, setCallRailPrice] = useState("50");
  const [hotjarPrice, setHotjarPrice] = useState("50");
  const [priceCard, setPriceCard] = useState(false);

  const pricing = useSelector(state => state.pricing.pricing);

  useEffect(() => {
    const [price, percent] = getPriceAndPercentage(props.monthlyFee);
    const [currentPrice, currentPercent] = getPriceAndPercentage(basePrice);
    if(price > currentPrice){
      setBasePrice(`$${price}`);
    }
  }, [props]);
  useEffect(() => {
    const pricingObj = pricing[props.priceCardNumber];
    // setBasePrice(getBasePriceObj(pricingObj.basePricePerMonth, pricingObj.basePercentPerMonth));
    setLandingPages(pricingObj.baseServices[0].quantity);
    setPopUp(pricingObj.baseServices[1].quantity);
    setStickyBars(pricingObj.baseServices[2].quantity);
    setAdditionalLPQuantity(pricingObj.addonServices[0].quantity);
    setAdditionalPUQuantity(pricingObj.addonServices[1].quantity);
    setAdditionalSBQuantity(pricingObj.addonServices[2].quantity);
    setCallRailQuantity(pricingObj.addonServices[3].quantity);
    setHotjarQuantity(pricingObj.addonServices[4].quantity);
    setAdditionalLPPrice(pricingObj.addonServices[0].price);
    setAdditionalPUPrice(pricingObj.addonServices[1].price);
    setAdditionalSBPrice(pricingObj.addonServices[2].price);
    setCallRailPrice(pricingObj.addonServices[3].price);
    setHotjarPrice(pricingObj.addonServices[4].price);
  }, []);
  const selectPriceCardHandler = (id) => {
    props.onClickHandler(id);
  };
  useEffect(() => {
    const [price, percent] = getPriceAndPercentage(basePrice);
    dispatch(
      updatePricing({
        priceIndex: props.priceCardNumber,
        pricing : {
          "adSpendRange": {
            "minSpend": props.minAdSpend,
            "maxSpend": props.maxAdSpend
          },
          "currency": "$",
          "basePricePerMonth":  props.minAdSpend === 250000 && !basePrice.includes("$") && !basePrice.includes("%") ? -1 : price,
          "basePercentPerMonth": percent || 0,
          "baseServices": [
            {
              "service": "Landing Pages",
              "quantity": landingPages || '0'
            },
            {
              "service": "Pop-up",
              "quantity": popUps || '0'
            },
            {
              "service": "Sticky Bar",
              "quantity": stickyBars || '0',
            }
          ],
          "addonServices": [
            {
              "service": "Additional Landing Pages",
              "quantity": additionalLPQuantity,
              "price": parseInt(additionalLPPrice || '0')
            },
            {
              "service": "Additional Pop Up",
              "quantity": additionalPUQuantity,
              "price": parseInt(additionalPUPrice || "0")
            },
            {
              "service": "Additional Sticky Bar",
              "quantity": additionalSBQuantity,
              "price": parseInt(additionalSBPrice || "0")
            },
            {
              "service": "Call Rail",
              "quantity": callRailQuantity,
              "price": parseInt(callRailPrice || "0")
            },
            {
              "service": "Hotjar",
              "quantity": hotjarQuantity,
              "price": parseInt(hotjarPrice || "0")
            }
          ]
        }
      })
    );
  }, [basePrice, landingPages, popUps, stickyBars, additionalLPQuantity, additionalLPPrice, additionalPUQuantity, additionalPUPrice, additionalSBQuantity, additionalSBPrice, callRailPrice, callRailQuantity, hotjarPrice, hotjarQuantity]);

  const increaseLPQuantityHandler = () => {
    if(parseInt(additionalLPQuantity) < 4){
      setAdditionalLPQuantity(parseInt(additionalLPQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) + parseInt(additionalLPPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };

  const decreaseLPQuantityHandler = () => {
    if (additionalLPQuantity != 0) {
      setAdditionalLPQuantity(additionalLPQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) - parseInt(additionalLPPrice || '0'));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };

  const increasePUQuantityHandler = () => {
    if(parseInt(additionalPUQuantity) < 4){
      setAdditionalPUQuantity(parseInt(additionalPUQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) + parseInt(additionalPUPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };

  const decreasePUQuantityHandler = () => {
    if (additionalPUQuantity != 0) {
      setAdditionalPUQuantity(additionalPUQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) - parseInt(additionalPUPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };

  const increaseSBQuantityHandler = () => {
    if(parseInt(additionalSBQuantity) < 4){
      setAdditionalSBQuantity(parseInt(additionalSBQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) + parseInt(additionalSBPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };

  const decreaseSBQuantityHandler = () => {
    if (additionalSBQuantity != 0) {
      setAdditionalSBQuantity(additionalSBQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) - parseInt(additionalSBPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };

  const increaseCallRailQuantityHandler = () => {
    if(parseInt(callRailQuantity) < 1){
      setCallRailQuantity(parseInt(callRailQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) + parseInt(callRailPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };

  const decreaseCallRailQuantityHandler = () => {
    if (callRailQuantity != 0) {
      setCallRailQuantity(callRailQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) - parseInt(callRailPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };
  const increaseHotjarQuantityHandler = () => {
    if(parseInt(hotjarQuantity) < 1){
      setHotjarQuantity(parseInt(hotjarQuantity) + 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices = '$' + (parseInt(basePrices[0].slice(1)) + parseInt(hotjarPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };

  const decreaseHotjarQuantityHandler = () => {
    if (hotjarQuantity != 0) {
      setHotjarQuantity(hotjarQuantity - 1);
      const basePrices = basePrice.split(' or ');
      let updatedPrices =
        '$' + (parseInt(basePrices[0].slice(1)) - parseInt(hotjarPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + ' or ' + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
    }
  };
  return (
    <div className='price-card-container'>
      <Typography
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: 16,
          color: "#170F49",
          paddingBottom: 10,
          textAlign: 'center'
        }}
      >
        {props.title}
      </Typography>
      {props.selected && (
        <img
          src={checkedIconGreen}
          alt=''
          style={{ position: 'absolute', right: '0.75rem', top: '0.75rem' }}
        />
      )}
      <div className='pricing'>
        <div>
          <img src={pricingIcon} alt='' />
        </div>
        <div>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 13.8582,
              color: '#6F6C90',
            }}
          >
            Ad Spend Range
          </Typography>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 18.4776,
              color: '#170F49',
            }}
          >
            {props.adSpendRange}
          </Typography>
        </div>
      </div>
      <div className='description'>
        <p
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 13.8582,
            lineHeight: 1.5,
            color: '#6F6C90',
          }}
        >
          What’s included{' '}
        </p>
      </div>
      <div className='price'>
        <>
          {basePriceEdit ? (
            <p
              style={{
                display: 'flex',
                alignItems: 'end',
                gap: '0.3rem',
                margin: 0,
              }}
            >
              <TextField
                inputProps={{ style: { width: '120px' } }}
                value={basePrice}
                onChange={(e) => setBasePrice(e.target.value)}
              />
              /monthly{' '}
              <span
                onClick={() => {
                  if (basePriceEdit && basePrice === '') {
                    setBasePrice('$0');
                  }
                  setBasePriceEdit(!basePriceEdit);
                }}
              >
                <img src={pencilIcon} alt='' />
              </span>
            </p>
          ) : (
            <p
              style={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '0.3rem',
                margin: 0,
              }}
            >
              <span>{basePrice}</span>
              /monthly{' '}
              {/* <span
                onClick={() => {
                  setBasePriceEdit(!basePriceEdit);
                }}
              >
                <img src={pencilIcon} alt='' />
              </span> */}
            </p>
          )}
        </>
      </div>
      <div className='features'>
        <p
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 13.8582,
            color: '#170F49',
          }}
        >
          What's included
        </p>
        <ul>
          <li>
            <img src={checkedIcon} alt='' />
            {landingPagesEdit ? (
              <TextField
                inputProps={{
                  style: { width: '28px', padding: 0, fontSize: 13.8582 },
                }}
                value={landingPages}
                onChange={(e) => setLandingPages(e.target.value)}
              />
            ) : (
              landingPages
            )}{' '}
            Landing Pages
            {/* <img
              src={pencilIcon}
              alt=''
              onClick={() => {
                if (landingPagesEdit && landingPages === '') {
                  setLandingPages('0');
                }
                setLandingPagesEdit(!landingPagesEdit);
              }}
            /> */}
          </li>
          <li>
            <img src={checkedIcon} alt='' />
            {popUpsEdit ? (
              <TextField
                inputProps={{
                  style: { width: '28px', padding: 0, fontSize: 13.8582 },
                }}
                value={popUps}
                onChange={(e) => setPopUp(e.target.value)}
              />
            ) : (
              popUps
            )}{' '}
            Pop-up
            {/* <img
              src={pencilIcon}
              alt=''
              onClick={() => {
                if (popUpsEdit && popUps === '') {
                  setPopUp('0');
                }
                setPopUpsEdit(!popUpsEdit);
              }}
            /> */}
          </li>
          <li>
            <img src={checkedIcon} alt='' />
            {stickyBarsEdit ? (
              <TextField
                inputProps={{
                  style: { width: '28px', padding: 0, fontSize: 13.8582 },
                }}
                value={stickyBars}
                onChange={(e) => {
                  setStickyBars(e.target.value);
                }}
              />
            ) : (
              stickyBars
            )}{' '}
            Sticky Bar
            {/* <img
              src={pencilIcon}
              alt=''
              onClick={() => {
                if (stickyBarsEdit && stickyBars === '') {
                  setStickyBars('0');
                }
                setStickyBarsEdit(!stickyBarsEdit);
              }}
            /> */}
          </li>
        </ul>
      </div>
      <div
        className='optional-add-ons'
        onClick={() => setoptionalAdons(!optionalAdons)}
      >
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 13.8582,
          }}
        >
          Optional Ad ons <img src={dropdownArrow} alt='' />
        </p>
      </div>
      {optionalAdons && (
        <div>
          <OptionalAdOns
            additionalLPQuantity={additionalLPQuantity}
            additionalLPPrice={additionalLPPrice}
            setAdditionalLPPrice={setAdditionalLPPrice}
            increaseLPQuantityHandler={increaseLPQuantityHandler}
            decreaseLPQuantityHandler={decreaseLPQuantityHandler}
            additionalPUQuantity={additionalPUQuantity}
            additionalPUPrice={additionalPUPrice}
            setAdditionalPUPrice={setAdditionalPUPrice}
            increasePUQuantityHandler={increasePUQuantityHandler}
            decreasePUQuantityHandler={decreasePUQuantityHandler}
            additionalSBQuantity={additionalSBQuantity}
            additionalSBPrice={additionalSBPrice}
            setAdditionalSBPrice={setAdditionalSBPrice}
            increaseSBQuantityHandler={increaseSBQuantityHandler}
            decreaseSBQuantityHandler={decreaseSBQuantityHandler}
            callRailQuantity={callRailQuantity}
            callRailPrice={callRailPrice}
            setCallRailPrice={setCallRailPrice}
            increaseCallRailQuantityHandler={increaseCallRailQuantityHandler}
            decreaseCallRailQuantityHandler={decreaseCallRailQuantityHandler}
            hotjarQuantity={hotjarQuantity}
            hotjarPrice={hotjarPrice}
            setHotjarPrice={setHotjarPrice}
            increaseHotjarQuantityHandler={increaseHotjarQuantityHandler}
            decreaseHotjarQuantityHandler={decreaseHotjarQuantityHandler}
          />
        </div>
      )}
      {/* <div className='add-to-proposal-button'>
        <PrimaryButton
          title='Add to Proposal'
          width='100%'
          // onClick={() => addProposalHandler()}
          onClick={() => selectPriceCardHandler(props.priceCardNumber)}
        >
          Add to Proposal
        </PrimaryButton>
      </div> */}
    </div>
  );
};

export default PriceRate;
