import Button  from "@mui/material/Button";
import { useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import {account as ACCOUNT} from '../../assets/icons/admin/admin'
import {credentials as CREDENTIAL} from '../../assets/icons/admin/admin'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BadgeIcon from '@mui/icons-material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';
import {useAuth0} from "@auth0/auth0-react";

const Pop = () =>
{
    const {logout:logoutAuth0} = useAuth0();
    const [modal,setModal]=useState(false);
    return (

       <div style={{marginTop:"10px"}}>
           <div style={{display:'flex',justifyContent:'flex-end'}}>

           <Button style={{transform:"translateX(-20px)",color:"#000000",border:'0.459548px solid #E9E9E9',background:'#FFFFFF',boxShadow:'0px 9.19096px 105.04px rgba(0, 0, 0, 0.05)',borderRadius:'10px'}} onClick={()=>{
               logoutAuth0({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } });
               localStorage.clear()
               ;window.location.href="/"
           }
           }> {"  "} <LogoutIcon/></Button>

           </div>
                    {/* <Button style={{transform:"translateX(20px)",color:"#0869FB"}} onClick={()=>setModal(!modal)}><ArrowBackIcon/> Back</Button> */}
            
           <div style={{display:'flex',height:'100vh',flexDirection:'row',alignItems:'center',gap:'5rem',justifyContent:'center'}}>
           
            {/* <Card sx={{ maxWidth: 345 }} onClick={()=>{window.location.href="/select-accounts"}}>
                <CardActionArea>
                <CardContent style={{display:'flex',alignItems:'center',flexDirection:'column',gap:'2rem'}}>
                    <AccountBoxIcon fontSize="large"/>
                    <Typography style={{fontFamily:'Inter', lineHeight:'15px', fontSize:'14px',fontWeight:'600',color:'#0066FF'}}>
                        Connect an Account
                    </Typography>
                </CardContent>
                </CardActionArea>
            </Card>

            <Card sx={{ maxWidth: 345 }} onClick={()=>{window.location.href="/generate-creds"}}>
                <CardActionArea>
                <CardContent style={{display:'flex',alignItems:'center',flexDirection:'column',gap:'2rem'}}>
                    <BadgeIcon fontSize="large"/>
                    <Typography style={{fontFamily:'Inter', lineHeight:'15px', fontSize:'14px',fontWeight:'600',color:'#0066FF'}}>
                        Generate Credentials
                    </Typography>
                </CardContent>
                </CardActionArea>
            </Card> */}
            <Grid container spacing={6} xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Grid item xs={5}>
                    <Card  style={{display:'flex',alignItems:"center",justifyContent:'center'}} onClick={()=>{window.location.href="/select-accounts"}}>
                        <CardActionArea>
                            <CardContent style={{display:'flex',alignItems:'center',flexDirection:'column',gap:'2rem'}}>
                                <img src={ACCOUNT} alt="" style={{paddingTop:'30px'}} />
                                <Typography style={{fontFamily:'Lato', lineHeight:'43px', fontSize:'30px',fontWeight:'400',color:'#5B596B',textAlign:'center',paddingBottom:'64.4px'}}>
                        Connect an Account
                    </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
           
                <Grid item xs={5}>
                    <Card   style={{display:'flex',alignItems:"center",justifyContent:'center'}} onClick={()=>{window.location.href="/generate-creds"}}>
                        <CardActionArea>
                            <CardContent style={{display:'flex',alignItems:'center',flexDirection:'column'}} >
                                <img src={CREDENTIAL} alt="" />
                                <Typography style={{fontFamily:'Lato', lineHeight:'43px', fontSize:'30px',fontWeight:'400',color:'#5B596B',textAlign:'center',paddingBottom:'64.4px'}}>
                                Generate Credentials
                    </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>

           </div>
           
       </div>

    )
}
export default Pop;
