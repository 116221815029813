import { useDispatch, useSelector } from 'react-redux';
import CommonReviewPoints from './CommonReviewPoints';
import {
  updateCompoReduxAM,
  goToSpecificCompoClient,
} from "../../../api/accountManager/componentTracking/action";
import {
  accountManagerIds,
  ldComponentWithIndexAm,
} from "../../../utils/accountManagerUtil";

export default function LDReviewPoints() {
  const dispatch = useDispatch();

  const form = useSelector((store) => store.form.form);

  const onClick = () => {
    if (
      (form?.onboardingInfo?.platformInfo?.platforms || []).find(
        (pf) => pf.platform === "facebook-ads"
      )
    ) {
      if (form?.accountAnalysis?.status === "COMPLETED") {
        dispatch(
          goToSpecificCompoClient(
            ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_ACCOUNT]
          )
        );
      } else {
        dispatch(updateCompoReduxAM());
      }
    } else {
      dispatch(
        goToSpecificCompoClient(
          ldComponentWithIndexAm[accountManagerIds.PROPOSAL_PUBLISH]
        )
      );
    }
  }

  return (
    <CommonReviewPoints
      form={form}
      onClick={onClick}
    />
  )
}