import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { goBackAmCompo } from "../../../api/accountManager/componentTracking/action";
import GoToNextCompoButton from "../../ButtonPrimary/GoToNextCompoButton";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
import InputTextField from "../../ClientDashboard/InputTextField/TextField";
import './FacebookAds.css'
import { updateFacebookAdsInfoReducer, updateFacebookAdData } from "../../../api/accountManager/forms/slice";
import { updateCompoReduxAM } from "../../../api/accountManager/componentTracking/action";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";
import { goBackClientCompo, updateCompoReduxClient } from "../../../api/componentTracking/action";

const FacebookAdsSetup = () => {
  const dispatch = useDispatch();
  const form = useSelector(store => store.form.form);
  const facebookAdsInfo = useSelector(state => state.form.facebookAdsInfo);
  const [accountOperations, setAccountOperations] = useState([]);
  const [fbIgAdCampaigns, setFbIgAdCampaigns] = useState("");
  const [newAudienceCreation, setNewAudienceCreation] = useState("");
  const [customEvents, setCustomEvents] = useState("");
  const [automatedRules, setAutomatedRules] = useState("");
  const [utmTrackingTemplates, setUtmTrackingTemplates] = useState("");

  const facebookAdsInfoRef = useRef({
    "accountOperations": accountOperations,
    "fbIgAdCampaigns": fbIgAdCampaigns,
    "newAudienceCreation": newAudienceCreation,
    "customEvents": customEvents,
    "automatedRules": automatedRules,
    "utmTrackingTemplates": utmTrackingTemplates
  })

  const [fbIgAdCampaignsRadio, setFbIgAdCampaignsRadio] = useState(false);
  const [newAudienceCreationRadio, setNewAudienceCreatioRadio] = useState(false);
  const [customEventsRadio, setCustomEventsRadio] = useState(false);
  const [automatedRulesRadio, setAutomatedRulesRadio] = useState(false);
  const [utmTrackingTemplatesRadio, setUtmTrackingTemplatesRadio] = useState(false);

  useEffect(() => {
    facebookAdsInfoRef.current = {
      "accountOperations": accountOperations,
      "fbIgAdCampaigns": fbIgAdCampaigns,
      "newAudienceCreation": newAudienceCreation,
      "customEvents": customEvents,
      "automatedRules": automatedRules,
      "utmTrackingTemplates": utmTrackingTemplates
    }
  }, [accountOperations, fbIgAdCampaigns, newAudienceCreation, customEvents, automatedRules, utmTrackingTemplates]);

  useEffect(() => {
    dispatch(updateCompoReduxClient());
    if (form?.companyInfo?.fbAdsManaged === false) {
    }
  }, [form])
  useEffect(() => {
    setAccountOperations(facebookAdsInfo?.accountOperations || []);
    setFbIgAdCampaigns(facebookAdsInfo?.fbIgAdCampaigns || "");
    setNewAudienceCreation(facebookAdsInfo?.newAudienceCreation || "");
    setCustomEvents(facebookAdsInfo?.customEvents || "");
    setAutomatedRules(facebookAdsInfo?.automatedRules || "");
    setUtmTrackingTemplates(facebookAdsInfo?.utmTrackingTemplates || "");

    if (facebookAdsInfo?.fbIgAdCampaigns) {
      setFbIgAdCampaignsRadio(true)
    }
    if (facebookAdsInfo?.newAudienceCreation) {
      setNewAudienceCreatioRadio(true)
    }
    if (facebookAdsInfo?.customEvents) {
      setCustomEventsRadio(true)
    }
    if (facebookAdsInfo?.automatedRules) {
      setAutomatedRulesRadio(true)
    }
    if (facebookAdsInfo?.utmTrackingTemplates) {
      setUtmTrackingTemplatesRadio(true)
    }

    facebookAdsInfoRef.current = {
      "accountOperations": facebookAdsInfo?.accountOperations || [],
      "fbIgAdCampaigns": facebookAdsInfo?.fbIgAdCampaigns || "",
      "newAudienceCreation": facebookAdsInfo?.newAudienceCreation || "",
      "customEvents": facebookAdsInfo?.customEvents || "",
      "automatedRules": facebookAdsInfo?.automatedRules || "",
      "utmTrackingTemplates": facebookAdsInfo?.utmTrackingTemplates || ""
    }
  }, [facebookAdsInfo]);

  useEffect(() => {

    return () => {
      dispatch(updateFacebookAdData({
        ...facebookAdsInfo,
        ...facebookAdsInfoRef.current
      }))
    }
  }, []);
  const onClickHandler = () => {
    dispatch(updateFacebookAdsInfoReducer({
      "status": BACKEND_STATUS.COMPLETED,
      "accountOperations": accountOperations,
      "fbIgAdCampaigns": fbIgAdCampaigns,
      "newAudienceCreation": newAudienceCreation,
      "customEvents": customEvents,
      "automatedRules": automatedRules,
      "utmTrackingTemplates": utmTrackingTemplates
    }))
  }
  return (
    <div className="box">
      <div className="heading_setup" >
        <span>Facebook Ads Account Build/Setup/Optimization *</span>
        <div className="form_checkbox_row">
          <FormGroup  >
            <FormControlLabel control={<Checkbox checked={accountOperations.includes('Build')} onChange={(e) => {
              const accountOperationBuffer = [...accountOperations];
              if (e.target.checked) {
                accountOperationBuffer.push('Build');
              } else {
                accountOperationBuffer.splice(accountOperationBuffer.indexOf('Build'), 1);
              }
              setAccountOperations(accountOperationBuffer);

            }} />} label={
              <Typography sx={{ fontFamily: 'sans-serif', color: "#7F7D87" }}>
                Build
              </Typography>
            } />
          </FormGroup>

          <FormGroup  >
            <FormControlLabel control={<Checkbox checked={accountOperations.includes('Setup')} onChange={(e) => {
              const accountOperationBuffer = [...accountOperations];
              if (e.target.checked) {
                accountOperationBuffer.push('Setup');
              } else {
                accountOperationBuffer.splice(accountOperationBuffer.indexOf('Setup'), 1);
              }
              setAccountOperations(accountOperationBuffer);

            }} />} label={
              <Typography sx={{ fontFamily: 'sans-serif', color: "#7F7D87" }}>
                Setup
              </Typography>
            } />
          </FormGroup>

          <FormGroup  >
            <FormControlLabel control={<Checkbox checked={accountOperations.includes('Optimization')} onChange={(e) => {
              const accountOperationBuffer = [...accountOperations];
              if (e.target.checked) {
                accountOperationBuffer.push('Optimization');
              } else {
                accountOperationBuffer.splice(accountOperationBuffer.indexOf('Optimization'), 1);
              }
              setAccountOperations(accountOperationBuffer);

            }} />} label={
              <Typography sx={{ fontFamily: 'sans-serif', color: "#7F7D87" }}>
                Optimization
              </Typography>
            } />
          </FormGroup>
        </div>
      </div>
      <div className="heading_setup" >
        <span>FB/IG Ad Campaigns *</span>
        <div>
          <RadioGroup
            defaultValue=''
            aria-labelledby='demo-customized-radios'
            name='customized-radios'
            value={fbIgAdCampaignsRadio}
            onChange={() => !fbIgAdCampaigns && setFbIgAdCampaignsRadio(!fbIgAdCampaignsRadio)}
            style={{
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <FormControlLabel
              value={false}
              control={<BpRadio />}
              label='NO'
            />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label='Other'

            />
            <TextField variant="standard" value={fbIgAdCampaigns} disabled={!fbIgAdCampaignsRadio} style={{ width: '400px', fontFamily: 'sans-serif', color: "#7F7D87" }} onChange={(e) => setFbIgAdCampaigns(e.target.value)} />


          </RadioGroup>
        </div>
      </div>

      <div className="heading_setup" >
        <span>Custom Events *</span>
        <div>
          <RadioGroup
            defaultValue=''
            aria-labelledby='demo-customized-radios'
            name='customized-radios'
            value={customEventsRadio}
            onChange={() => !customEvents && setCustomEventsRadio(!customEventsRadio)}
            style={{
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <FormControlLabel
              value={false}
              control={<BpRadio />}
              label='NO'
            />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label='Other'

            />
            <TextField variant="standard" value={customEvents} disabled={!customEventsRadio} style={{ width: '400px', fontFamily: 'sans-serif', color: "#7F7D87" }} onChange={(e) => setCustomEvents(e.target.value)} />


          </RadioGroup>
        </div>
      </div>

      <div className="heading_setup" >
        <span>New Audience Creation/Segmentation *</span>
        <div>
          <RadioGroup
            defaultValue=''
            aria-labelledby='demo-customized-radios'
            name='customized-radios'
            value={newAudienceCreationRadio}
            onChange={() => !newAudienceCreation && setNewAudienceCreatioRadio(!newAudienceCreationRadio)}
            style={{
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <FormControlLabel
              value={false}
              control={<BpRadio />}
              label='NO'
            />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label='Other'

            />
            <TextField variant="standard" value={newAudienceCreation} disabled={!newAudienceCreationRadio} style={{ width: '400px', fontFamily: 'sans-serif', color: "#7F7D87" }} onChange={(e) => setNewAudienceCreation(e.target.value)} />


          </RadioGroup>
        </div>
      </div>

      <div className="heading_setup" >
        <span>Automated Rules *</span>
        <div>
          <RadioGroup
            defaultValue=''
            aria-labelledby='demo-customized-radios'
            name='customized-radios'
            value={automatedRulesRadio}
            onChange={() => !automatedRules && setAutomatedRulesRadio(!automatedRulesRadio)}
            style={{
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <FormControlLabel
              value={false}
              control={<BpRadio />}
              label='NO'
            />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label='Other'

            />
            <TextField variant="standard" value={automatedRules} disabled={!automatedRulesRadio} style={{ width: '400px', fontFamily: 'sans-serif', color: "#7F7D87" }} onChange={(e) => setAutomatedRules(e.target.value)} />


          </RadioGroup>
        </div>
      </div>

      <div className="heading_setup" >
        <span >UTM Tracking Templates *</span>
        <div>
          <RadioGroup
            defaultValue=''
            aria-labelledby='demo-customized-radios'
            name='customized-radios'
            value={utmTrackingTemplatesRadio}
            onChange={() => !utmTrackingTemplates && setUtmTrackingTemplatesRadio(!utmTrackingTemplatesRadio)}
            style={{
              flexDirection: 'row',
              gap: '1rem',
            }}
          >
            <FormControlLabel
              value={false}
              control={<BpRadio />}
              label='NO'
            />
            <FormControlLabel
              value={true}
              control={<BpRadio />}
              label='Other'

            />
            <TextField variant="standard" value={utmTrackingTemplates} disabled={!utmTrackingTemplatesRadio} style={{ width: '400px', fontFamily: 'sans-serif', color: "#7F7D87" }} onChange={(e) => setUtmTrackingTemplates(e.target.value)} />


          </RadioGroup>
        </div>
      </div>

      <div className="preview_footer_row">
        <div className="footer_con">
          <button className='back' onClick={() => dispatch(goBackClientCompo())}>BACK</button>
          <GoToNextCompoButton title='Next' type="client" onClickHandler={onClickHandler} />
        </div>
      </div>

    </div>


  )
}
export default FacebookAdsSetup;