import { useDispatch, useSelector } from 'react-redux';
import CommonGoogleAdsAccount from './CommonGoogleAdsAccount';
import { useCallback } from 'react';
import { updateCompoReduxAM } from "../../api/accountManager/componentTracking/action";

export default function LDGoogleAdsAccount() {
  const dispatch = useDispatch();
  
  const form = useSelector((store) => store.form.form);

  const onHaveInsights = useCallback(() => {
    dispatch(updateCompoReduxAM());
  }, [dispatch]);

  return (
    <CommonGoogleAdsAccount
      form={form}
      onHaveInsights={onHaveInsights}
    />
  )
}