import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BrandCampaignCreation from './BrandCampaignCreation/BrandCampaignCreation';
import CampaignTypeNew from "./CampaignTypes";
import CompetitorCampaignCreation from './CompetitorCampaignCreation/CompetitorCampaignCreation';
import ServicesCampaignCreation from './ServicesCampaignCreation/ServicesCampaignCreation';
import {
  goToSpecificCompoClient
} from '../../../api/accountManager/componentTracking/action';
import { getFormByFormIdReducer, updateFormForCampaignReducer } from '../../../api/accountManager/forms/slice';
import { publishCampaignReducer, publishSpecificCampaignTypeReducer } from '../../../api/accountManager/googleAdsCustomer/slice';
import {
  goToSpecificCompoHDMAM
} from '../../../api/HDMAM/componentTracking/action';
import {
  accountManagerIds,
  accountManagerIdsHDM,
  BACKEND_STATUS,
  componentWithIndexHDMAm,
  getGoogleCampaignObject,
  ldComponentWithIndexAm
} from '../../../utils/accountManagerUtil';
import {
  leftSidebarAMWithCurrentCompIds,
  listOfIds,
} from '../../../utils/navbarUtil';
import PublishCampaignModal from './PublishCampaignModal';
import PublishedCampaignPage from './PublishedCampaignPage';
import { AxiosError } from 'axios';
import { BIDDING_OPTIONS, CONVERSION_GOALS } from './CampaignTypes/constants';
import {back_arrow as backArrow} from '../../../assets/icons/icon';

const POLLING_INTERVAL = 1 * 60 * 1000; // 1 min
const DEFAULT_PUBLISHING_FORCED = {
  brandCampaign: false,
  competitorCampaign: false,
  serviceCampaign: false, 
};

const CampaignCreation = ({ agencyType }) => {
  const dispatch = useDispatch();

  const [hasCampaignError, setHasCampaignError] = useState(false);
  const [isCampaignGenerating, setIsCampaignGenerating] = useState(false);
  const [isCampaignGenerated, setIsCamapaignGenerated] = useState(false);
  const [campaignIds, setCampaignIds] = useState({});

  const [brandAdsets, setBrandAdsets] = useState([]);
  const [brandFinalUrl, setBrandFinalUrl] = useState('');
  const [brandUtmUrl, setBrandUtmUrl] = useState('');
  const [brandUtmSource, setBrandUtmSource] = useState('Google');
  const [brandUtmMedium, setBrandUtmMedium] = useState('CPC');
  const [brandUtmCampaign, setBrandUtmCampaign] = useState('Brand');
  const [isBrandComplete, setIsBrandComplete] = useState(false);
  const [hasBrandChange, setHasBrandChange] = useState(false);
  const [forBrandChange, setForBrandChange] = useState(true);

  const [competitorAdsets, setCompetitorAdsets] = useState([]);
  const [competitorFinalUrl, setCompetitorFinalUrl] = useState('');
  const [competitorUtmUrl, setCompetitorUtmUrl] = useState('');
  const [competitorUtmSource, setCompetitorUtmSource] = useState('Google');
  const [competitorUtmMedium, setCompetitorUtmMedium] = useState('CPC');
  const [competitorUtmCampaign, setCompetitorUtmCampaign] =
    useState('Competitor');
  const [isCompetitorComplete, setIsCompetitorComplete] = useState(false);
  const [hasCompetitorChange, setHasCompetitorChange] = useState(false);
  const [forCompetitorChange, setForCompetitorChange] = useState(true);

  const [serviceAdsets, setServiceAdsets] = useState([]);
  const [serviceFinalUrl, setServiceFinalUrl] = useState('');
  const [serviceUtmUrl, setServiceUtmUrl] = useState('');
  const [serviceUtmSource, setServiceUtmSource] = useState('Google');
  const [serviceUtmMedium, setServiceUtmMedium] = useState('CPC');
  const [serviceUtmCampaign, setServiceUtmCampaign] = useState('Service');
  const [isServiceComplete, setIsServiceComplete] = useState(false);
  const [hasServiceChange, setHasServiceChange] = useState(false);
  const [forServiceChange, setForServiceChange] = useState(true);

  const [showViewDetails, setShowViewDetails] = useState(false);
  const [viewDetailsType, setViewDetailsType] = useState(null);
  const [isAdGroupLevelDisabled, setIsAdGroupLevelDisabled] = useState(false);
  const [isPublishCampaign, setIsPublishCampaign] = useState(false);

  const [isCampPubHit, setIsCampPubHit] = useState(false);
  const form = useSelector((state) =>
    agencyType === 'LD' ? state.form.form : state.hdmFormAm
  );

  const brandFormRef = useRef();
  const competitorFormRef = useRef();
  const serviceFormRef = useRef();
  const nestedFormsPersistance = useRef({
    brandCampaign: {},
    competitorCampaign: {},
    serviceCampaign: {},
  });

  const [publishApiErrors, setPublishApiErrors] = useState({
    brandCampaign: "",
    competitorCampaign: "",
    serviceCampaign: "", 
  });

  const [isDirtyForms, setIsDirtyForms] = useState({
    brandCampaign: false,
    competitorCampaign: false,
    serviceCampaign: false, 
  });

  const [isPublishingForced, setIsPublishingForced] = useState(DEFAULT_PUBLISHING_FORCED);

  const persistData = () => {
    nestedFormsPersistance.current.brandCampaign = brandFormRef.current.getValues();
    nestedFormsPersistance.current.competitorCampaign = competitorFormRef.current.getValues();
    nestedFormsPersistance.current.serviceCampaign = serviceFormRef.current.getValues();
  };

  const persistIsDirty = () => {
    setIsDirtyForms({
      brandCampaign: brandFormRef.current.getIsDirty(),
      competitorCampaign: competitorFormRef.current.getIsDirty(),
      serviceCampaign: serviceFormRef.current.getIsDirty(), 
    }) 
  }

  const brandDefaultValues = useMemo(() => {
    if(Object.keys(nestedFormsPersistance.current.brandCampaign).length)
      return nestedFormsPersistance.current.brandCampaign;

    const locations =
        form.googleSearchAdsCampaignInfo?.brandCampaign?.locations ||
        form.onboardingInfo?.googleTargeting?.geoTargets,
      languages = form.googleSearchAdsCampaignInfo?.brandCampaign?.languages;

    return {
      campaignName:
        form.googleSearchAdsCampaignInfo?.brandCampaign?.name ||
        "PXPSM_Brand_Search_Lead",
      budget:
        form.googleSearchAdsCampaignInfo?.brandCampaign?.budget ||
        parseInt(form.googleSearchAdsStrategyInfo?.brandCampaign?.budget),
      ...(locations ? { locations } : {}),
      ...(languages ? { languages } : {}),
      bidding: form.googleSearchAdsCampaignInfo?.brandCampaign?.bidding || BIDDING_OPTIONS[0].value,
      conversionGoals: form.googleSearchAdsCampaignInfo?.brandCampaign?.conversionGoals || CONVERSION_GOALS,
      networks: form.googleSearchAdsCampaignInfo?.brandCampaign?.networks,
      maxCpcBidLimit: form.googleSearchAdsCampaignInfo?.brandCampaign?.maxCpcBidLimit,
      targetCpa: form.googleSearchAdsCampaignInfo?.brandCampaign?.targetCpa,
      selectTargetRoas: form.googleSearchAdsCampaignInfo?.brandCampaign?.selectTargetRoas,
    };
  }, [form, showViewDetails]);

  const competitorDefaultValues = useMemo(() => {
    if(Object.keys(nestedFormsPersistance.current.competitorCampaign).length)
      return nestedFormsPersistance.current.competitorCampaign;

    const locations =
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.locations ||
        form.onboardingInfo?.googleTargeting?.geoTargets,
      languages =
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.languages;

    return {
      campaignName:
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.name ||
        "PXPSM_Competitor_Search_Lead",
      budget:
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.budget ||
        parseInt(form.googleSearchAdsStrategyInfo?.competitorCampaign?.budget),
      ...(locations ? { locations } : {}),
      ...(languages ? { languages } : {}),
      bidding: form.googleSearchAdsCampaignInfo?.competitorCampaign?.bidding || BIDDING_OPTIONS[0].value,
      conversionGoals: form.googleSearchAdsCampaignInfo?.competitorCampaign?.conversionGoals || CONVERSION_GOALS,
      networks: form.googleSearchAdsCampaignInfo?.competitorCampaign?.networks,
      maxCpcBidLimit: form.googleSearchAdsCampaignInfo?.competitorCampaign?.maxCpcBidLimit,
      targetCpa: form.googleSearchAdsCampaignInfo?.competitorCampaign?.targetCpa,
      selectTargetRoas: form.googleSearchAdsCampaignInfo?.competitorCampaign?.selectTargetRoas,
    };
  }, [form, showViewDetails]);

  const serviceDefaultValues = useMemo(() => {
    if(Object.keys(nestedFormsPersistance.current.serviceCampaign).length)
      return nestedFormsPersistance.current.serviceCampaign; 

    const locations =
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.locations ||
        form.onboardingInfo?.googleTargeting?.geoTargets,
      languages = form.googleSearchAdsCampaignInfo?.serviceCampaign?.languages;

    return {
      campaignName:
        form.googleSearchAdsCampaignInfo?.serviceCampaign?.name ||
        "PXPSM_Services_Search_Lead",
      budget:
        form.googleSearchAdsCampaignInfo?.serviceCampaign?.budget ||
        parseInt(form.googleSearchAdsStrategyInfo?.serviceCampaign?.budget),
      ...(locations ? { locations } : {}),
      ...(languages ? { languages } : {}),
      bidding: form.googleSearchAdsCampaignInfo?.serviceCampaign?.bidding || BIDDING_OPTIONS[0].value,
      conversionGoals: form.googleSearchAdsCampaignInfo?.serviceCampaign?.conversionGoals || CONVERSION_GOALS,
      networks: form.googleSearchAdsCampaignInfo?.serviceCampaign?.networks,
      maxCpcBidLimit: form.googleSearchAdsCampaignInfo?.serviceCampaign?.maxCpcBidLimit,
      targetCpa: form.googleSearchAdsCampaignInfo?.serviceCampaign?.targetCpa,
      selectTargetRoas: form.googleSearchAdsCampaignInfo?.serviceCampaign?.selectTargetRoas,
    };
  }, [form, showViewDetails]);

  const intervelRef = useRef();

  useEffect(() => {
    // not initialized yet if campaignIds has no keys
    if(!Object.keys(campaignIds).length) return;
    if(!isCampaignGenerating) return;
    const callback = () => {
      dispatch(getFormByFormIdReducer({
        callback(error) {
          if(error) return;
          setIsPublishingForced(DEFAULT_PUBLISHING_FORCED);
        }
      }));
    };
    intervelRef.current = setInterval(callback, POLLING_INTERVAL);
    return () => {
      clearInterval(intervelRef.current);
    };
  }, [dispatch, campaignIds, isCampaignGenerating])

  useEffect(() => {
    if (form) {
      if (form?.googleSearchAdsGeneratedCampaignsInfo) {
        const [campaignObj, isGenerating, hasError, isGenerated] =
          getGoogleCampaignObject(form.googleSearchAdsGeneratedCampaignsInfo);

        setHasCampaignError(hasError);
        setIsCampaignGenerating(isGenerating);
        setIsCamapaignGenerated(isGenerated);
        setCampaignIds(campaignObj);
      }
      
      if ((form.googleSearchAdsCampaignInfo?.brandCampaign?.adsets || []).length) {
        setBrandAdsets(form.googleSearchAdsCampaignInfo?.brandCampaign?.adsets);
      } else {
        const adsets = [];
        form.googleSearchAdsStrategyInfo?.brandCampaign?.adsets.map((adset) => {
          adsets.push({
            name: adset.name,
            headlines: adset.headlines,
            descriptions: adset.descriptions,
            keywords: adset.keywords.map((keyword) => {
              return {
                keyword: keyword,
                matchType: 'exact',
              };
            }),
          });
        });

        setBrandAdsets(adsets);
      }
      setBrandFinalUrl(
        form.googleSearchAdsCampaignInfo?.brandCampaign?.finalUrl ||
          form.onboardingInfo?.personalInfo?.companyWebsite ||
          form.onboardingInfo?.clientAccess?.websiteUrl
      );
      setBrandUtmUrl(
        form.googleSearchAdsCampaignInfo?.brandCampaign?.trackingTemplate
          ? form.googleSearchAdsCampaignInfo?.brandCampaign?.trackingTemplate.split(
              '?'
            )[0]
          : form.onboardingInfo?.personalInfo?.companyWebsite ||
              form.onboardingInfo?.clientAccess?.websiteUrl
      );
      if (form.googleSearchAdsCampaignInfo?.brandCampaign?.trackingTemplate) {
        const querySearchParams = new URLSearchParams(
          form.googleSearchAdsCampaignInfo?.brandCampaign?.trackingTemplate.split(
            '?'
          )[1]
        );
        setBrandUtmSource(querySearchParams.get('utm_source'));
        setBrandUtmMedium(querySearchParams.get('utm_medium'));
        setBrandUtmCampaign(querySearchParams.get('utm_campaign'));
      }
      setIsBrandComplete(
        form.googleSearchAdsCampaignInfo?.brandCampaign?.status ===
          BACKEND_STATUS.COMPLETED
      );
      if (forBrandChange) {
        setHasBrandChange(false);
        setForBrandChange(false);
      }

      if (
        (form.googleSearchAdsCampaignInfo?.competitorCampaign?.adsets || [])
          .length
      ) {
        setCompetitorAdsets(
          form.googleSearchAdsCampaignInfo?.competitorCampaign?.adsets
        );
      } else {
        const adsets = [];
        form.googleSearchAdsStrategyInfo?.competitorCampaign?.adsets.map(
          (adset) => {
            adsets.push({
              name: adset.name,
              headlines: adset.headlines,
              descriptions: adset.descriptions,
              keywords: adset.keywords.map((keyword) => {
                return {
                  keyword: keyword,
                  matchType: 'exact',
                };
              }),
            });
          }
        );

        setCompetitorAdsets(adsets);
      }
      setCompetitorFinalUrl(
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.finalUrl ||
          form.onboardingInfo?.personalInfo?.companyWebsite ||
          form.onboardingInfo?.clientAccess?.websiteUrl
      );
      setCompetitorUtmUrl(
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.trackingTemplate
          ? form.googleSearchAdsCampaignInfo?.competitorCampaign?.trackingTemplate.split(
              '?'
            )[0]
          : form.onboardingInfo?.personalInfo?.companyWebsite ||
              form.onboardingInfo?.clientAccess?.websiteUrl
      );
      if (
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.trackingTemplate
      ) {
        const querySearchParams = new URLSearchParams(
          form.googleSearchAdsCampaignInfo?.competitorCampaign?.trackingTemplate.split(
            '?'
          )[1]
        );
        setCompetitorUtmSource(querySearchParams.get('utm_source'));
        setCompetitorUtmMedium(querySearchParams.get('utm_medium'));
        setCompetitorUtmCampaign(querySearchParams.get('utm_campaign'));
      }
      setIsCompetitorComplete(
        form.googleSearchAdsCampaignInfo?.competitorCampaign?.status ===
          BACKEND_STATUS.COMPLETED
      );
      if (forCompetitorChange) {
        setHasCompetitorChange(false);
        setForCompetitorChange(false);
      }

      if ((form.googleSearchAdsCampaignInfo?.serviceCampaign?.adsets || []).length) {
        setServiceAdsets(form.googleSearchAdsCampaignInfo?.serviceCampaign?.adsets);
      } else {
        const adsets = [];
        form.googleSearchAdsStrategyInfo?.serviceCampaign?.adsets.map(
          (adset) => {
            adsets.push({
              name: adset.name,
              headlines: adset.headlines,
              descriptions: adset.descriptions,
              keywords: adset.keywords.map((keyword) => {
                return {
                  keyword: keyword,
                  matchType: 'exact',
                };
              }),
            });
          }
        );

        setServiceAdsets(adsets);
      }
      setServiceFinalUrl(
        form.googleSearchAdsCampaignInfo?.serviceCampaign?.finalUrl ||
          form.onboardingInfo?.personalInfo?.companyWebsite ||
          form.onboardingInfo?.clientAccess?.websiteUrl
      );

      setServiceUtmUrl(
        form.googleSearchAdsCampaignInfo?.serviceCampaign?.trackingTemplate
          ? form.googleSearchAdsCampaignInfo?.serviceCampaign?.trackingTemplate.split(
              '?'
            )[0]
          : form.onboardingInfo?.personalInfo?.companyWebsite ||
              form.onboardingInfo?.clientAccess?.websiteUrl
      );
      if (form.googleSearchAdsCampaignInfo?.serviceCampaign?.trackingTemplate) {
        const querySearchParams = new URLSearchParams(
          form.googleSearchAdsCampaignInfo?.serviceCampaign?.trackingTemplate.split(
            '?'
          )[1]
        );
        setServiceUtmSource(querySearchParams.get('utm_source'));
        setServiceUtmMedium(querySearchParams.get('utm_medium'));
        setServiceUtmCampaign(querySearchParams.get('utm_campaign'));
      }
      setIsServiceComplete(
        form.googleSearchAdsCampaignInfo?.serviceCampaign?.status ===
          BACKEND_STATUS.COMPLETED
      );
      if (forServiceChange) {
        setHasServiceChange(false);
        setForServiceChange(false);
      }

      setIsCampPubHit(false);
    }
  }, [form]);

  const addKeyWords = (campaignType, adGroupName, newKeyword) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            keywords: [...asset.keywords, newKeyword],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            keywords: [...asset.keywords, newKeyword],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
      setHasCompetitorChange(true);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            keywords: [...asset.keywords, newKeyword],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
      setHasServiceChange(true);
    }
  };

  const deleteKeyWord = (campaignType, adGroupName, keyword) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const keywords = asset.keywords.filter((key) => key !== keyword);
          return {
            ...asset,
            keywords: [...keywords],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const keywords = asset.keywords.filter((key) => key !== keyword);
          return {
            ...asset,
            keywords: [...keywords],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
      setHasCompetitorChange(true);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const keywords = asset.keywords.filter((key) => key !== keyword);
          return {
            ...asset,
            keywords: [...keywords],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
      setHasServiceChange(true);
    }
  };

  const editKeyWord = (campaignType, adGroupName, keyword, newKeyword) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const keywords = asset.keywords.map((key) => {
            if (key.keyword === keyword.keyword) {
              return {
                keyword: newKeyword.keyword,
                matchType: newKeyword.matchType,
              };
            }
            return key;
          });
          return {
            ...asset,
            keywords: [...keywords],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const keywords = asset.keywords.map((key) => {
            if (key.keyword === keyword.keyword) {
              return {
                keyword: newKeyword.keyword,
                matchType: newKeyword.matchType,
              };
            }
            return key;
          });
          return {
            ...asset,
            keywords: [...keywords],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
      setHasCompetitorChange(true);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const keywords = asset.keywords.map((key) => {
            if (key.keyword === keyword.keyword) {
              return {
                keyword: newKeyword.keyword,
                matchType: newKeyword.matchType,
              };
            }
            return key;
          });
          return {
            ...asset,
            keywords: [...keywords],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
      setHasServiceChange(true);
    }
  };

  const addHeadline = (campaignType, adGroupName, headline) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            headlines: [...asset.headlines, headline],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            headlines: [...asset.headlines, headline],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
      setHasCompetitorChange(true);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            headlines: [...asset.headlines, headline],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
      setHasServiceChange(true);
    }
  };

  const deleteHeadline = (campaignType, adGroupName, headlineIndex) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const headlines = asset.headlines.filter(
            (head, index) => index !== headlineIndex
          );
          return {
            ...asset,
            headlines: [...headlines],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const headlines = asset.headlines.filter(
            (head, index) => index !== headlineIndex
          );
          return {
            ...asset,
            headlines: [...headlines],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
      setHasCompetitorChange(true);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const headlines = asset.headlines.filter(
            (head, index) => index !== headlineIndex
          );
          return {
            ...asset,
            headlines: [...headlines],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
      setHasServiceChange(true);
    }
  };

  const editHeadline = (campaignType, adGroupName, headline, newHeadline) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const headlines = asset.headlines.map((head) => {
            if (head.value === headline) {
              return { value: newHeadline, category: head.category };
            }
            return head;
          });
          return {
            ...asset,
            headlines: [...headlines],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const headlines = asset.headlines.map((head) => {
            if (head.value === headline) {
              return { value: newHeadline, category: head.category };
            }
            return head;
          });
          return {
            ...asset,
            headlines: [...headlines],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
      setHasCompetitorChange(true);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const headlines = asset.headlines.map((head) => {
            if (head.value === headline) {
              return { value: newHeadline, category: head.category };
            }
            return head;
          });
          return {
            ...asset,
            headlines: [...headlines],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
      setHasServiceChange(true);
    }
  };
  const addDescription = (campaignType, adGroupName, description) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            descriptions: [...asset.descriptions, description],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            descriptions: [...asset.descriptions, description],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
      setHasCompetitorChange(true);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          return {
            ...asset,
            descriptions: [...asset.descriptions, description],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
      setHasServiceChange(true);
    }
  };

  const deleteDescription = (campaignType, adGroupName, descriptionIndex) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const descriptions = asset.descriptions.filter(
            (_desc, index) => index !== descriptionIndex
          );
          return {
            ...asset,
            descriptions: [...descriptions],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const descriptions = asset.descriptions.filter(
            (_desc, index) => index !== descriptionIndex
          );
          return {
            ...asset,
            descriptions: [...descriptions],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const descriptions = asset.descriptions.filter(
            (_desc, index) => index !== descriptionIndex
          );
          return {
            ...asset,
            descriptions: [...descriptions],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
    }
  };

  const editDescription = (
    campaignType,
    adGroupName,
    description,
    newDescription
  ) => {
    if (campaignType === 'brandCampaign') {
      // const adsets = [...brandAdsets];
      const adsets = brandAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const descriptions = asset.descriptions.map((desc) => {
            if (desc.value === description) {
              return { value: newDescription, category: desc.category };
            }
            return desc;
          });
          return {
            ...asset,
            descriptions: [...descriptions],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setBrandAdsets(adsets);
      setHasBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      const adsets = competitorAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const descriptions = asset.descriptions.map((desc) => {
            if (desc.value === description) {
              return { value: newDescription, category: desc.category };
            }
            return desc;
          });
          return {
            ...asset,
            descriptions: [...descriptions],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setCompetitorAdsets(adsets);
      setHasCompetitorChange(true);
    } else {
      const adsets = serviceAdsets.map((asset) => {
        if (asset.name === adGroupName) {
          const descriptions = asset.descriptions.map((desc) => {
            if (desc.value === description) {
              return { value: newDescription, category: desc.category };
            }
            return desc;
          });
          return {
            ...asset,
            descriptions: [...descriptions],
          };
        } else {
          return {
            ...asset,
          };
        }
      });
      setServiceAdsets(adsets);
      setHasServiceChange(true);
    }
  };
  useEffect(() => {
    if (!showViewDetails) {
      setViewDetailsType(null);
    }
  }, [showViewDetails]);

  const getComponent = (detailsType) => {
    if (detailsType === 'brandCampaign') {
      return (
        <BrandCampaignCreation
          adsets={brandAdsets}
          confirmHandler={confirmHandler}
          finalUrl={brandFinalUrl}
          setFinalUrl={setBrandFinalUrl}
          websiteUtmUrl={brandUtmUrl}
          utmSource={brandUtmSource}
          utmMedium={brandUtmMedium}
          utmCampaign={brandUtmCampaign}
          setWebsiteUtmUrl={setBrandUtmUrl}
          setUtmSource={setBrandUtmSource}
          setUtmMedium={setBrandUtmMedium}
          setUtmCampaign={setBrandUtmCampaign}
          website={
            form.onboardingInfo?.personalInfo?.companyWebsite ||
            form.onboardingInfo?.clientAccess?.websiteUrl ||
            ''
          }
          setShowViewDetails={setShowViewDetails}
          addKeyWords={addKeyWords}
          addHeadline={addHeadline}
          addDescription={addDescription}
          deleteKeyWord={deleteKeyWord}
          deleteHeadline={deleteHeadline}
          deleteDescription={deleteDescription}
          editKeyWord={editKeyWord}
          editHeadline={editHeadline}
          editDescription={editDescription}
          isDisabled={isAdGroupLevelDisabled}
        />
      );
    } else if (detailsType === 'competitorCampaign') {
      return (
        <CompetitorCampaignCreation
          adsets={competitorAdsets}
          confirmHandler={confirmHandler}
          finalUrl={competitorFinalUrl}
          setFinalUrl={setCompetitorFinalUrl}
          websiteUtmUrl={competitorUtmUrl}
          utmSource={competitorUtmSource}
          utmMedium={competitorUtmMedium}
          utmCampaign={competitorUtmCampaign}
          setWebsiteUtmUrl={setCompetitorUtmUrl}
          setUtmSource={setCompetitorUtmSource}
          setUtmMedium={setCompetitorUtmMedium}
          setUtmCampaign={setCompetitorUtmCampaign}
          website={
            form.onboardingInfo?.personalInfo?.companyWebsite ||
            form.onboardingInfo?.clientAccess?.websiteUrl ||
            ''
          }
          setShowViewDetails={setShowViewDetails}
          addKeyWords={addKeyWords}
          addHeadline={addHeadline}
          addDescription={addDescription}
          deleteKeyWord={deleteKeyWord}
          deleteHeadline={deleteHeadline}
          deleteDescription={deleteDescription}
          editKeyWord={editKeyWord}
          editHeadline={editHeadline}
          editDescription={editDescription}
          isDisabled={isAdGroupLevelDisabled}
        />
      );
    } else {
      return (
        <ServicesCampaignCreation
          adsets={serviceAdsets}
          confirmHandler={confirmHandler}
          finalUrl={serviceFinalUrl}
          setFinalUrl={setServiceFinalUrl}
          websiteUtmUrl={serviceUtmUrl}
          utmSource={serviceUtmSource}
          utmMedium={serviceUtmMedium}
          utmCampaign={serviceUtmCampaign}
          setWebsiteUtmUrl={setServiceUtmUrl}
          setUtmSource={setServiceUtmSource}
          setUtmMedium={setServiceUtmMedium}
          setUtmCampaign={setServiceUtmCampaign}
          website={
            form.onboardingInfo?.personalInfo?.companyWebsite ||
            form.onboardingInfo?.clientAccess?.websiteUrl ||
            ''
          }
          setShowViewDetails={setShowViewDetails}
          addKeyWords={addKeyWords}
          addHeadline={addHeadline}
          addDescription={addDescription}
          deleteKeyWord={deleteKeyWord}
          deleteHeadline={deleteHeadline}
          deleteDescription={deleteDescription}
          editKeyWord={editKeyWord}
          editHeadline={editHeadline}
          editDescription={editDescription}
          isDisabled={isAdGroupLevelDisabled}
        />
      );
    }
  };

  /**
   * @param {*} campType 
   * @param {*} mode view | edit
   */
  const viewDetailsClickHandler = (campType) => (isAdGroupLevelDisabled) => {
    persistData();
    persistIsDirty();
    setViewDetailsType(campType);
    setShowViewDetails(true);
    setIsAdGroupLevelDisabled(isAdGroupLevelDisabled);
  };

  const confirmAndPublish = (campaignType) => (values) => {
    let data = {};
    
    switch(campaignType) {
      case "brandCampaign": {
        data = {
          ...(form.googleSearchAdsCampaignInfo?.brandCampaign || {}),
          adsets: brandAdsets,
          finalUrl: brandFinalUrl,
          trackingTemplate: `${brandUtmUrl}?utm_source=${brandUtmSource}&utm_medium=${brandUtmMedium}&utm_campaign=${brandUtmCampaign}`, 
        };
        break;
      }
      case "competitorCampaign":{
        data = {
          ...(form.googleSearchAdsCampaignInfo?.competitorCampaign || {}),
          adsets: competitorAdsets,
          finalUrl: competitorFinalUrl,
          trackingTemplate: `${competitorUtmUrl}?utm_source=${competitorUtmSource}&utm_medium=${competitorUtmMedium}&utm_campaign=${competitorUtmCampaign}`,
        };
        break;
      } 
      case "serviceCampaign": {
        data = {
          ...(form.googleSearchAdsCampaignInfo?.serviceCampaign || {}),
          adsets: serviceAdsets,
          finalUrl: serviceFinalUrl,
          trackingTemplate: `${serviceUtmUrl}?utm_source=${serviceUtmSource}&utm_medium=${serviceUtmMedium}&utm_campaign=${serviceUtmCampaign}`,
        } 
        break;
      }
      default: break;
    }

    dispatch(updateFormForCampaignReducer({
      googleSearchAdsCampaignInfo: {
        status: BACKEND_STATUS.ONGOING,
        [campaignType]: {
          ...data,
          status: BACKEND_STATUS.COMPLETED,
          name: values.campaignName,
          locations: values.locations,
          languages: values.languages,
          budget: values.budget,
          bidding: values.bidding,
          maxCpcBidLimit: values.maxCpcBidLimit,
          targetCpa: values.targetCpa,
          selectTargetRoas: values.selectTargetRoas,
          conversionGoals: values.conversionGoals,
          networks: values.networks,
        },
      },
      // campaignConfirmModal: !!campaignConfirmModal,
      callback: (error, _data) => {
        if(error) return;
        const formRef = {
          brandCampaign: brandFormRef,
          competitorCampaign: competitorFormRef,
          serviceCampaign: serviceFormRef,
        }[campaignType];
        formRef.current?.reset();
        setIsPublishingForced(prev => ({...prev, [campaignType]: true}));
        dispatch(publishSpecificCampaignTypeReducer({
          campaignType,
          callback(error, _data) {
            if(error) {
              if(error instanceof AxiosError) {
                let apiErrorDetail =  error.response.data?.detail;
                if (typeof apiErrorDetail === "string") {
                  try {
                    apiErrorDetail = JSON.parse(apiErrorDetail);
                  } catch (error) {
                    console.error("Error while parcing API Error", error);
                  }
                }
                if(apiErrorDetail?.data?.status === "PERMISSION_DENIED") {
                  const {customerId, customerName, loginCustomerId, loginCustomerName} = form.clientGoogleAdsInfo || {};
                  console.log("permission denied", form.clientGoogleAdsInfo)
                  setPublishApiErrors(prev => ({
                    ...prev,
                    [campaignType]: <span>
                      It seems you do not have permission to publish campaigns on this account.<br />
                      <b>Account ID</b>: {customerId}<br />
                      <b>Account name</b>: {customerName}<br />
                      <b>MCC ID</b>: {loginCustomerId}<br />
                      <b>MCC name</b>: {loginCustomerName}<br />
                      Please verify access of this account on your Google Ads MCC and try again. If this issue persists, please contact the Pixis Internal Team
                    </span>
                  }))
                }
              }
              return;
            }
            setPublishApiErrors(prev => ({...prev, [campaignType]: ""}))
          }
        }))
      }
    }))
  }

  const confirmHandler = (campaignConfirmModal, campaignType) => {
    const {brandCampaign, competitorCampaign,serviceCampaign} = nestedFormsPersistance.current
    dispatch(
      updateFormForCampaignReducer({
        googleSearchAdsCampaignInfo: {
          status: BACKEND_STATUS.ONGOING,
          brandCampaign: {
            ...(form.googleSearchAdsCampaignInfo?.brandCampaign || {}),
            status:
              campaignType === 'brandCampaign'
                ? BACKEND_STATUS.COMPLETED
                : form.googleSearchAdsCampaignInfo?.brandCampaign?.status ||
                  BACKEND_STATUS.ONGOING,
            name: brandCampaign.campaignName,
            locations: brandCampaign.locations,
            languages: brandCampaign.languages,
            budget: brandCampaign.budget,
            bidding: brandCampaign.bidding,
            maxCpcBidLimit: brandCampaign.maxCpcBidLimit,
            targetCpa: brandCampaign.targetCpa,
            selectTargetRoas: brandCampaign.selectTargetRoas,
            conversionGoals: brandCampaign.conversionGoals,
            networks: brandCampaign.networks,
            adsets: brandAdsets,
            finalUrl: brandFinalUrl,
            trackingTemplate: `${brandUtmUrl}?utm_source=${brandUtmSource}&utm_medium=${brandUtmMedium}&utm_campaign=${brandUtmCampaign}`,
          },
          competitorCampaign: {
            ...(form.googleSearchAdsCampaignInfo?.competitorCampaign || {}),
            status:
              campaignType === 'competitorCampaign'
                ? BACKEND_STATUS.COMPLETED
                : form.googleSearchAdsCampaignInfo?.competitorCampaign
                    ?.status || BACKEND_STATUS.ONGOING,
            name: competitorCampaign.campaignName,
            locations: competitorCampaign.locations,
            languages: competitorCampaign.languages,
            budget: competitorCampaign.budget,
            bidding: competitorCampaign.bidding,
            maxCpcBidLimit: competitorCampaign.maxCpcBidLimit,
            targetCpa: competitorCampaign.targetCpa,
            selectTargetRoas: competitorCampaign.selectTargetRoas,
            conversionGoals: competitorCampaign.conversionGoals,
            networks: competitorCampaign.networks,
            adsets: competitorAdsets,
            finalUrl: competitorFinalUrl,
            trackingTemplate: `${competitorUtmUrl}?utm_source=${competitorUtmSource}&utm_medium=${competitorUtmMedium}&utm_campaign=${competitorUtmCampaign}`,
          },
          serviceCampaign: {
            ...(form.googleSearchAdsCampaignInfo?.serviceCampaign || {}),
            status:
              campaignType === 'serviceCampaign'
                ? BACKEND_STATUS.COMPLETED
                : form.googleSearchAdsCampaignInfo?.serviceCampaign?.status ||
                  BACKEND_STATUS.ONGOING,
            name: serviceCampaign.campaignName,
            locations: serviceCampaign.locations,
            languages: serviceCampaign.languages,
            budget: serviceCampaign.budget,
            bidding: serviceCampaign.bidding,
            maxCpcBidLimit: serviceCampaign.maxCpcBidLimit,
            targetCpa: serviceCampaign.targetCpa,
            selectTargetRoas: serviceCampaign.selectTargetRoas,
            conversionGoals: serviceCampaign.conversionGoals,
            networks: serviceCampaign.networks,
            adsets: serviceAdsets,
            finalUrl: serviceFinalUrl,
            trackingTemplate: `${serviceUtmUrl}?utm_source=${serviceUtmSource}&utm_medium=${serviceUtmMedium}&utm_campaign=${serviceUtmCampaign}`,
          },
        },
        campaignConfirmModal: !!campaignConfirmModal,
      })
    );
    if (campaignType === 'brandCampaign') {
      setForBrandChange(true);
    } else if (campaignType === 'competitorCampaign') {
      setForCompetitorChange(true);
    } else if (campaignType === 'serviceCampaign') {
      setForServiceChange(true);
    }
  };

  const generatedCampaignClickHandler = () => {
    if (agencyType === 'HDM') {
      dispatch(
        goToSpecificCompoHDMAM(
          componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_PREVIEW_PAGE]
        )
      );
    } else {
      dispatch(
        goToSpecificCompoClient(
          leftSidebarAMWithCurrentCompIds[listOfIds.CAMPAIGN_PREVIEW]
        )
      );
    }
  };

  const publishHandler = () => {
    if (!isCampPubHit) {
      setIsCampPubHit(true);
      dispatch(publishCampaignReducer());
    }
  };

  const onBackClickHandler = () => {
    if(agencyType === 'LD'){
      dispatch(goToSpecificCompoClient(ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE]));
    }

    if(agencyType === "HDM"){
      dispatch(goToSpecificCompoHDMAM(componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE]))
    }
  }

  // return isCampaignGenerated ? (
  //   <PublishedCampaignPage onClickHandler={generatedCampaignClickHandler} />
  // ) : showViewDetails ? (
    return showViewDetails ? (
    getComponent(viewDetailsType)
    ) : (
      <div className="campaign-creation-container campaign-types-container">
        <div
          style={{
            position: "relative",
            left: 40,
            top: 32,
            cursor: "pointer",
          }}
          onClick={onBackClickHandler}
        >
          {/* <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "16px",
              color: "#0869FB",
            }}
          >
            BACK
          </Typography> */}
          <div
            className="back-button-container"
          >
            <img src={backArrow} alt="" />
          </div>
        </div>
        <div className="campaign-creation-header">
          <Typography className="campaign-creation-header__title">
            Campaign Creation
          </Typography>
          <Typography className="campaign-creation-header__description">
            Create campaigns here and publish them to be run
          </Typography>
        </div>
        <CampaignTypeNew
          title="Brand Campaign"
          campaignId={campaignIds["brandCampaign"]}
          campaignType="brandCampaign"
          onViewDetails={viewDetailsClickHandler("brandCampaign")}
          defaultValues={brandDefaultValues}
          agencyType={agencyType}
          confirmAndPublish={confirmAndPublish("brandCampaign")}
          ref={brandFormRef}
          isConfirmed={form.googleSearchAdsCampaignInfo?.brandCampaign?.status === BACKEND_STATUS.COMPLETED}
          apiError={publishApiErrors["brandCampaign"]}
          isPublishingForced={isPublishingForced["brandCampaign"]}
          isFormDirty={isDirtyForms["brandCampaign"]}
        />
        <CampaignTypeNew
          title="Competitor Campaign"
          campaignId={campaignIds["competitorCampaign"]}
          campaignType="competitorCampaign"
          onViewDetails={viewDetailsClickHandler("competitorCampaign")}
          defaultValues={competitorDefaultValues}
          agencyType={agencyType}
          confirmAndPublish={confirmAndPublish("competitorCampaign")}
          ref={competitorFormRef}
          isConfirmed={form.googleSearchAdsCampaignInfo?.competitorCampaign?.status === BACKEND_STATUS.COMPLETED}
          apiError={publishApiErrors["competitorCampaign"]}
          isPublishingForced={isPublishingForced["competitorCampaign"]}
          isFormDirty={isDirtyForms["competitorCampaign"]}
        />
        <CampaignTypeNew
          title="Services Campaign"
          campaignId={campaignIds["serviceCampaign"]}
          campaignType="serviceCampaign"
          onViewDetails={viewDetailsClickHandler("serviceCampaign")}
          defaultValues={serviceDefaultValues}
          agencyType={agencyType}
          confirmAndPublish={confirmAndPublish("serviceCampaign")}
          ref={serviceFormRef}
          isConfirmed={form.googleSearchAdsCampaignInfo?.serviceCampaign?.status === BACKEND_STATUS.COMPLETED}
          apiError={publishApiErrors["serviceCampaign"]}
          isPublishingForced={isPublishingForced["serviceCampaign"]}
          isFormDirty={isDirtyForms["serviceCampaign"]}
        />
        <div style={{height: "16px", width: "100%"}} />
        {isPublishCampaign && (
          <PublishCampaignModal
            formSubm={isPublishCampaign}
            setFormSubm={setIsPublishCampaign}
            publishHandler={publishHandler}
          />
        )}
      </div>
    );
};

export default CampaignCreation;
