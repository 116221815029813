import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHDMClientDetailsByFormId } from '../../api/HDMClient/action';
import { getLocalStorage } from '../../api/localStorage/localStorage';
import { mappedComponentWithIndex } from '../../utils/HDMClientDashboardIdsUtil';
import { HDMCLientLeftSidebar, HDMHeaderNavbar } from '../../utils/navbarUtil';
import './HDMClientDashboard.css';
import HDMHeaderNavSteps from './HDMHeaderNavSteps';
import HDMNavigationSteps from './HDMNavigationSteps';
import HDMRenderCurrentComponent from './HDMRenderCurrentComponent';

const HDMClientDashboard = () => {
  const clientCurrentCompoToRender = useSelector(
    (state) => state.hdmClientCurrentComp.currentComp
  );
  const currentLeftSidebar = useSelector(
    (state) => state.hdmClientCurrentComp.currentLeftSidebar
  );
  const visitedLastTime = useSelector(
    (state) => state.hdmClientCurrentComp.visitedLastTime
  );
  const currentHeaderTab = useSelector(
    (state) => state.hdmClientCurrentComp.currentHeaderTab
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getLocalStorage('formId') && dispatch(getHDMClientDetailsByFormId());
    const clientDefaultQuest = window.location.href.split('/client/');
    clientDefaultQuest.length === 2 &&
      clientDefaultQuest[1] === 'renderQuestion' &&
      dispatch({ type: 'EDIT_CLIENT_RESPO_CLIENT' });
  }, []);

  console.log('current client left nav bar', currentLeftSidebar);
  return (
    <main
      class={`${mappedComponentWithIndex.get(
        clientCurrentCompoToRender
      )} main_layout`}
    >
      <section className='nav_section'>
        <HDMNavigationSteps
          navigateSteps={HDMCLientLeftSidebar}
          active={currentLeftSidebar}
        />
      </section>
      <section
        className={`${
          currentLeftSidebar &&
          Object.values(HDMHeaderNavbar[currentLeftSidebar]).length
            ? 'top_nav_item'
            : ''
        } right_layout`}
        style={{
          paddingBottom: `${clientCurrentCompoToRender == 61 && '0'}`,
        }}
      >
        <div className={`right_layout_inner`}>
          {currentLeftSidebar &&
            currentHeaderTab &&
            Object.values(HDMHeaderNavbar[currentLeftSidebar]).length !== 0 && (
              <div className='top_nav_section'>
                <div className='top_nav_inner'>
                  <div
                    style={{
                      width: '100%',
                      height: '64px',
                      display: 'flex',
                    }}
                  >
                    <HDMHeaderNavSteps
                      headerNavList={HDMHeaderNavbar[currentLeftSidebar]}
                      active={currentHeaderTab}
                      visitedLastTime={visitedLastTime}
                    />
                  </div>
                </div>
              </div>
            )}
          <div
            className={`${
              clientCurrentCompoToRender <= 59 ? 'hdm-questionnaire' : ''
            } right_main_section`}
          >
            <HDMRenderCurrentComponent
              currentCompoent={mappedComponentWithIndex.get(
                clientCurrentCompoToRender
              )}
            />
          </div>
        </div>
      </section>
    </main>
  );
};
export default HDMClientDashboard;
